import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
//import { initFlowbite } from "flowbite";
import { Modal } from 'react-daisyui';

import { SetDocumentTitle } from "../_reactHelpers/ReactHelpers";
import { SetFocusById, NotEmpty } from '../../js/formHelpers.js';
import { SetPageTitle, GetValueById } from '../../js/helpers.js';
import { get } from '../../services/apiService.js';

import { ReceivingModal } from './ReceivingModal';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import '../../css/ag-theme-lumina.css'; // local ag-theme-alpine.css

export const ReceivingSearch = (props) => {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Receiving");

    const loadSearchTermsFromPage = "ReceivingSearch";
    const storedSearchTermsKey = "storedReceivingSearchTerms";
    const tbSearchId = "tbReceivingSearch";
    const tbSearchGeneralId = "tbSearchGeneralId";

    const serverSide = false;
    const minSearchChars = 2;
    const pageRows = 50;

    const orderModalRef = useRef();
    const [modalClass, setModalClass] = useState("");
    const [modalContent, setModalContent] = useState(null);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    const [receievedOrderLines, setReceievedOrderLines] = useState([]);

    const [rowData, setRowData] = useState([]);
    const [pendingTags, setPendingTags] = useState([]);
    const [pendingTagsCount, setPendingTagsCount] = useState(0);

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const receiveSuccessMsg = "Line(s) received successfully.";

    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const [gridHeight, setGridHeight] = useState("750px");
    const olGridRef = useRef();
    const columnDefs = [
        {
            headerName: '', field: 'orderLineId', maxWidth: 35, //cellClass: ["text-left"],
            headerCheckboxSelection: true,
            checkboxSelection: true,
            showDisabledCheckboxes: true,
            //cellRenderer: params => {
            //    var olId = params.data.orderLineId;                
            //    return <input type="hidden" className="cb-ol" value={olId}></input>;
            //}
        },
        {
            headerName: 'PO Number', field: 'poNumberStr', maxWidth: 110, //autoHeight: true, cellClass: ["pt-[11px]"],
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px] min-w-[110px]">{params.data.poNumberStr}</div>;
            },
        },
        {
            headerName: 'Style', field: 'style', resizable: true, autoHeight: true, cellClass: ["text-wrap"],
            width: 400,
            cellRenderer: params => {
                var ol = params.data;
                return <div className="leading-[20px] pt-[11px] min-w-[300px]">{ol.style}</div>;
            },
        },
        {
            headerName: "Color", field: 'color', maxWidth: 200, autoHeight: true, cellClass: ["text-wrap"],
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px] min-w-[200px]">{params.data.color}</div>;
            },
        },
        {
            headerName: 'PO Qty', field: 'poQuantity', maxWidth: 100, cellClass: ["text-primary"],
        },
        {
            headerName: 'Qty', field: 'quantity', maxWidth: 100, cellClass: ["text-primary"],
        },
        {
            headerName: 'Qty Rcvd', field: 'quantityReceived', maxWidth: 100, cellClass: ["text-primary"],
        },
        {
            headerName: 'Units', field: 'unitsStr', maxWidth: 70, //cellClass: ["text-wrap"],
        },
        //{
        //    headerName: 'Price', field: 'price', maxWidth: 120, //cellClass: ["text-left"],
        //    cellRenderer: params => {
        //        var price = params.data.price;
        //        return <span className="text-primary">{formatCurrencyDecStr(price)}</span>;
        //    }
        //},
        //{
        //    headerName: 'Total', field: 'total', maxWidth: 120, //cellClass: ["text-wrap"],
        //    cellRenderer: params => {
        //        var total = params.data.total;
        //        return <span className="text-primary">{formatCurrencyDecStr(total)}</span>;
        //    }
        //},
        {
            headerName: 'Status', field: 'statusStr', maxWidth: 110, cellClass: ["text-primary"],
        },
        {
            headerName: 'Date', field: 'statusDateStr', maxWidth: 140, cellClass: ["text-primary"],
        },        
        //{
        //    headerName: 'Bin', field: 'bin', maxWidth: 70, //cellClass: ["text-wrap"],
        //},
        {
            headerName: 'Warehouse', field: 'warehouseName', maxWidth: 120, //cellClass: ["text-wrap"],            
        },
        {
            headerName: 'Vendor', field: 'vendorName', maxWidth: 150, cellClass: ["text-wrap"], autoHeight: true,
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px] min-w-[150px]">{params.data.vendorName}</div>;
            },
        },
        {
            headerName: 'Category', field: 'productCategoryName', maxWidth: 180, cellClass: ["text-wrap"], autoHeight: true,
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px] min-w-[180]">{params.data.productCategoryName}</div>;
            },
        },
    ];

    const gridOptions = {
        defaultColDef: {
            //flex: 1,
            sortable: false,
            resizable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true,
            showDisabledCheckboxes: true,
            cellClass: ["no-border"],            
        }
    };

    function getRowNodesWithSort() {
        var orderLines = [];

        olGridRef.current.api.forEachNodeAfterFilterAndSort((node) => {
            if (node.selected) {
                var ol = node.data;
                if (ol) {
                    orderLines.push(ol);
                }
            }
        });
        return orderLines;
    }

    //const refreshCache = useCallback((e) => {
    //    //save search terms in session
    //    //var searchTerms = GetValueById(tbSearchId);
    //    //localStorage.setItem(storedSearchTermsKey, JSON.stringify(searchTerms));

    //    //if (searchTerms && searchTerms.length >= minSearchChars) {
    //    //    olGridRef.current.api.refreshServerSide({ purge: true });
    //    //}        
    //    olGridRef.current.api.refreshServerSide({ purge: true });
    //}, []);


    /****** Methods ******/

    function clearAllMessages() {
        setMessage("");
        setMessageClass("");
    }

    function updateMessage(msg, cssClass, errorMsg, logMsg) {
        clearAllMessages();

        if (errorMsg) {
            console.error(errorMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setMessage(msg);
        setMessageClass(cssClass);
    }

    function voidClick(e) {
        if (e) {
            e.preventDefault();
        }
    }

    function cbAllSetClick(checked) {
        if (checked === true) {
            olGridRef.current.api.selectAll();
        }
        else {
            olGridRef.current.api.deselectAll();
        }
    }

    /****** Modal Methods ******/

    // Receiving Modal --------------------------------------

    function returnAddAccountModalComponent(ols) {
        return <ReceivingModal orderLines={ols} onCloseModal={onCloseReceiveLinesModal}></ReceivingModal>;
    }

    async function onOpenReceiveLinesModal(e) {
        //e.preventDefault();
        setModalContent(null);

        var ols = getRowNodesWithSort();
        if (ols && ols.length > 0) {
                        
            //var receivedRows = await loadReceivedRowsClient(ols);
            setModalContent(returnAddAccountModalComponent(ols));
            onOpenModal();
        }
        else {
            updateMessage("No line(s) selected.", "text-primary");
        }
    }

    function onCloseReceiveLinesModal(e, linesReceived) {
        if (e) {
            e.preventDefault();
        }
        clearAllMessages();

        if (linesReceived === true) {
            updateMessage(receiveSuccessMsg, "text-green");
        }
        onCloseModal();
    }

    // base modal methods  --------------------------------------

    function onOpenModal() {
        handleOrderModalShow();
    }

    function onCloseModal() {
        //setModalClass("");
        handleOrderModalHide();
        setModalContent(null);

        //uncheck all boxes after action
        cbAllSetClick(false);
        loadReceivingDataClient();
        loadPendingWarehouseTags();
    }

    const handleOrderModalShow = useCallback(() => {
        orderModalRef.current?.showModal();
    }, [orderModalRef]);

    const handleOrderModalHide = useCallback(() => {
        orderModalRef.current?.close();
    }, [orderModalRef]);


    /****** db methods ******/

    const onGridReady = useCallback((params) => {        
        
        if (serverSide) {
            params.api.setServerSideDatasource(serverSideDatasource());
        } else {
            loadReceivingDataClient();
        }
    }, []);

    const serverSideDatasource = () => {
        return {
            // called by the grid when more rows are required
            getRows: async params => {

                var searchStr = GetValueById(tbSearchId);
                if (searchStr && searchStr.length >= minSearchChars) {

                    var pageNum = params.request.endRow / pageRows;
                    var page = pageNum - 1;

                    //var apiUrl = process.env.REACT_APP_API_URL + 'receiving/GetReceivingLinesSearch' +
                    //    '?numRows=' + pageRows + '&page=' + page + '&sText=' + searchStr ;

                    //console.log("server side apiUrl: " + apiUrl);
                    //fetch(apiUrl)
                    //    .then((response) => response.json())
                    //    .then((rowData) => {
                    //        if (NotEmpty(rowData.dataRows)) {
                    //            //debugger;
                    //            params.success({ rowData: rowData.dataRows });
                    //            hideOverlay();
                    //            console.log("GetReceivingLinesSearch rows: " + rowData.dataRows.length);
                    //        }
                    //        else {
                    //            params.success({ rowData: [] });
                    //            showNoRows();
                    //            console.log("GetReceivingLinesSearch rows: no data");
                    //        }
                    //        console.log(rowData.message);
                    //    })
                    //    .catch((error) => {

                    const endpoint = 'receiving/GetReceivingLinesSearch?numRows=' + pageRows + '&page=' + page + '&sText=' + searchStr;
                    console.log("server side apiUrl: " + endpoint);
                    try {
                        const response = await get(endpoint);
                        //console.log('Response: ' + JSON.stringify(response));

                        if (NotEmpty(response.dataRows)) {
                            //debugger;

                            params.success({ rowData: response.dataRows });
                            hideOverlay();
                            console.log("GetReceivingLinesSearch rows: " + response.dataRows.length);
                        }
                        else {
                            params.success({ rowData: [] });
                            showNoRows();
                            console.log("GetReceivingLinesSearch rows: no data");
                        }
                        console.log(rowData.message);
                    } catch (error) {
                        params.success({ rowData: [] });
                        showNoRows();
                        setError(error);
                        console.error('GetReceivingLinesSearch error:', error);
                    }
                }

            }
        };
    }

    /****** Data Methods ******/
    
    async function loadReceivingDataClient() {
        showLoading();

        const endpoint = 'receiving/GetReceivingLinesSearch';     
        //console.log("apiUrl: " + apiUrl);

        try {
            const response = await get(endpoint);
            //console.log('Response: ' + JSON.stringify(response));

            if (NotEmpty(response.dataRows)) {
                setRowData(response.dataRows);
                hideOverlay();
                console.log(response.message);
            }
            else {
                setRowData([]);
                showNoRows();
            }
            console.log(response.message);
        } catch (error) {
            setError(error);
            console.error('GetReceivingLinesSearch Error:', error);
        }
    };

    async function loadPendingWarehouseTags() {

        const endpoint = 'receiving/GetPendingWarehouseTags';
        try {
            const response = await get(endpoint);
            //console.log('Response: ' + JSON.stringify(response));

            if (response) {
                setPendingTagsCount(response);
            }
        } catch (error) {
            setError(error);
            console.error('loadPendingWarehouseTags Error:', error);
        }
    };

    function handleSearch(e) {
        console.log('handleSearch Start');

        var api = olGridRef.current.api;
        const value = e.target.value.toLowerCase();
        api.setQuickFilter(value);
    };

    function showLoading() {
        olGridRef.current.api.showLoadingOverlay();
    }

    function showNoRows() {
        olGridRef.current.api.showNoRowsOverlay();
    }

    function hideOverlay() {
        olGridRef.current.api.hideOverlay();
    }

    const isRowSelectable = useMemo(() => {
        return (params) => {
            return params.data && (params.data.statusId === 2 || params.data.statusId === 8);
        };
    }, []);

    function onRowDoubleClicked(e) {        
        if (e && e.node) {
            e.node.setSelected(true, true);
            onOpenReceiveLinesModal();
        }
    }

    // ON LOAD
    useEffect(() => {
        SetPageTitle("Receiving");
        setLoading(false);        
                
        loadPendingWarehouseTags();
        //loadSavedSearchTerms();

    }, []);

    /*** Content ***/

    if (loading === true) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }
    if (error === true)
        return <div className="text-red-500 text-center mt-[25%] text-xl">{defaultErrMsg}</div>;

    return (
        <div>
            <div className="p-2">

                <div className="clear">
                    <div className="float-right text-lg text-primary font-bold text-right">                                                
                        <div className="table-cell">
                            <span>Pending Tags: </span>
                            <span>{pendingTagsCount}</span>                            
                        </div>
                        <div className="table-cell pl-2">
                            <button onClick={(e) => alert("Printing " + pendingTagsCount + " tags.")}
                                className="btn-load-orders">Print Tags</button>
                        </div>
                    </div>
                    <div className="pb-2 float-left">
                        <div className="table-cell">
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input type="search" id={tbSearchId} placeholder="Filter..." 
                                    className="orders-filter-search @apply block p-2 pl-10 text-sm text-gray-900 
                                            border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500;"
                                    autoFocus
                                    //onKeyDown={(e) => CheckEnterKeyPress(e, refreshCache)}
                                    onChange={handleSearch}></input>
                                <div id="selectedRows" />
                            </div>
                        </div>
                        <div className="mb-2 pointer-events-hand pl-3 table-cell">
                            <button id="btnReceivingSearch" onClick={(e) => loadReceivingDataClient()}
                                type="button" className="btn-search !m-0">
                                Reload
                            </button>
                        </div>
                        <div className="table-cell pl-3">
                            <button id="btnLoadReceivedModal"
                                onClick={(e) => onOpenReceiveLinesModal(e)}                                
                                className="btn-load-orders">Receive Selected</button>
                        </div>
                    </div>
                </div>

                <div className="clear">
                    <div style={containerStyle} className="">
                        <div className="ag-theme-alpine" style={{ height: gridHeight, width: '100%', }}>
                            <div className={"text-left text-sm " + messageClass}>
                                {message}
                            </div>

                            <AgGridReact
                                headerHeight={32}
                                rowData={rowData}
                                ref={olGridRef}
                                gridOptions={gridOptions}
                                columnDefs={columnDefs}
                                onGridReady={onGridReady}
                                onRowDoubleClicked={(e) => onRowDoubleClicked(e)}                                
                                rowSelection='multiple'
                                isRowSelectable={isRowSelectable}
                                //server side if needed
                                //cacheBlockSize={pageRows}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={"order-detail-modal p-2 !pt-0  " + modalClass}>
                <Modal ref={orderModalRef} className="order-detail-modal-content min-w-[1500px]">
                    <Modal.Body>
                        {modalContent}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
}