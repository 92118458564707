import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { GetStoreSelectList, GetEnumSelectList, StandardIdAndResponseGET } from '../../js/dataHelpers.js';
import { SetDocumentTitle, GetLocationStateId } from "../_reactHelpers/ReactHelpers";
import { SetPageTitle, FormatDateLongInput, ClickById, GetSelectItemValueById } from '../../js/helpers.js';
import { get, post } from '../../services/apiService.js';
import {
    handleFieldChange, isSelectedDropdown, isValidDate, IsEmail, NotEmpty, IsEmpty,
    AddInputChangeListener, enforcePhoneFormat, formatToPhone, enforceZipFormat,
    enforceZipLength, ParseIntSafe, IdExists, IsTrue, IsPhone, PreventDefault, Exists,
    formatValToPhone, KeyHandlerDatePicker, GetDateNow, NotExists, NotStringEmpty,
    StringEmpty, NotIsValidDate, IsFalse
} from '../../js/formHelpers.js';


import { createAccountModel } from './_accountHelpers.js';
/*import { InputValidator } from '../_reactHelpers/InputValidator.js';*/

import { Equals } from '../../js/calculationHelpers.js';

export const AccountAddEdit = (props) => {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina Account");
    const location = useLocation();
    const navigate = useNavigate();

    const [propsActiveTab, setPropsActiveTab] = useState(props.activeTab ?? "");

    const [accountTypeList, setAccountTypeList] = useState([]);
    const [storeList, setStoreList] = useState([]);

    const [accountId, setAccountId] = useState(GetLocationStateId("accountId"));
    const [accountData, setAccountData] = useState(createAccountModel());

    const [resellerPermitExpDate, setResellerPermitExpDate] = useState(null);
    //const taxTypeRef = useRef();

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");

    const [redirectSubmit, setRedirectSubmit] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState("");

    const [storeRequiredHidden, setStoreRequiredHidden] = useState(" hidden ");
    const [taxValidDateHidden, setTaxValidDateHidden] = useState(" hidden ");
    const [validEmail1Hidden, setValidEmail1Hidden] = useState(" hidden ");
    const [validEmail2Hidden, setValidEmail2Hidden] = useState(" hidden ");

    const [validEmailStmntHidden, setValidEmailStmntHidden] = useState(" hidden ");
    const [validPhoneMatsHidden, setValidPhoneMatsHidden] = useState(" hidden ");
    const [validEmailMatsHidden, setValidEmailMatsHidden] = useState(" hidden ");

    const [validPhone1Hidden, setValidPhone1Hidden] = useState(" hidden ");
    const [validPhone2Hidden, setValidPhone2Hidden] = useState(" hidden ");

    const createMsg = " created successfully.";
    const updateMsg = " updated successfully.";
    const createErrMsg = "There was an issue creating the account. Please try again or contact support.";
    const updateErrMsg = "There was an issue updating the account. Please try again or contact support.";
    const defaultErrMsg = "An error occurred. Please try again or contact support.";


    /** Methods **/
    function backToClick(accountStatus, accountMessage) {
        navigate('/Accounts', {
            replace: true,
            state: { useSavedFiltersAccount: true, accountStatus, accountMessage }
        });
    }

    function createOrderClick() {
        if (accountId !== 0) {
            navigate('/Order', { replace: true, state: { accountId } });
        }
    }

    // TODO: remove: unused - using <a>
    //function createQuoteClick() {
    //    if (accountId !== 0) {
    //        var orderType = 2;
    //        navigate('/Quote', { replace: true, state: { accountId, orderType } });
    //        //{ "/Redirect?route=CreateQuote&orderType=2&status=create&accountId=" + propsAccountId }
    //    }
    //}   

    function EnterSave(e) {
        if (e.key === "Enter") {
            btnSubmitClick(e, true);
        }
    }

    async function createAccountTypeList() {
        var list = [];

        var data = await GetEnumSelectList("AccountType");
        data.forEach((item, index) => {
            list.push(
                <option key={index} value={item.value}>{item.text}</option>
            );
        });
        setAccountTypeList(list);
    }

    async function createStoreList() {
        var list = [];
        list.push(
            <option key="0" value="0">None</option>
        );

        var dataList = await GetStoreSelectList(0);
        dataList.forEach((item, index) => {
            list.push(
                <option key={index + 1} value={item.value}>{item.text}</option>
            );
        });
        setStoreList(list);
    }

    function loadDataLists() {
        createAccountTypeList();
        createStoreList();
    }


    //---------------------------------------------

    function updateMessage(msg, cssClass, errorMsg, logMsg) {
        if (errorMsg) {
            console.error(errorMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setMessage(msg);
        setMessageClass(cssClass);
    }

    const handleChange = (e) => {

        var data = handleFieldChange(e);
        if (data) {
            //updateMessage("", "");

            if (data.name === "zip") {
                data.value = enforceZipLength(data.value, 50);
            }
            if (data.name === "phone1") {
                data.value = formatToPhone(e);
            }
            if (data.name === "phone2") {
                data.value = formatToPhone(e);
            }
            if (data.name === "materialsPhone") {
                data.value = formatToPhone(e);
            }

            setAccountData((accountData) => ({
                ...accountData,
                [data.name]: data.value
            }));
        }
        else {
            updateMessage(defaultErrMsg, "text-red", "null handleChange data");
        }
    };


    function setTodaysDate() {
        //var dateInp = "";
        //if (date && date != null) {
        //dateInp = FormatDateLongInput(Date.now());
        //}
        //else {
        //    var data = handleFieldChange(e);
        //    if (data) {
        //        dateInp = data.value;
        //    }
        //}
        var dateInp = GetDateNow();
        setResellerPermitExpDate(dateInp);
    };


    //function KeyHandlerDate(e) {

    //    var keyPress = HandleKeyPress(e, 'd');
    //    if (keyPress === true) {
    //        PreventDefault(e);
    //        handleDatePickerChange(null, Date.now());
    //    }
    //    else if (e.key === "ArrowDown") {
    //        // allow to open calendar
    //    }
    //    //else if (e.key === "ArrowRight") {
    //    //    PreventDefault(e);
    //    //}
    //    //else if (e.key === "ArrowRight") {
    //    //    PreventDefault(e);

    //    //    //document.dispatchEvent(
    //    //    //    new KeyboardEvent("keydown", {
    //    //    //        key: "e",
    //    //    //        keyCode: 40, // example values.
    //    //    //        code: "ArrowDown", // put everything you need in this object.
    //    //    //        which: 40,
    //    //    //        shiftKey: false, // you don't need to include values
    //    //    //        ctrlKey: false,  // if you aren't going to use them.
    //    //    //        metaKey: false   // these are here for example's sake.
    //    //    //    })
    //    //    //);
    //    //}
    //    //else if (e.key === "Enter") {
    //    //    PreventDefault(e);
    //    //}        
    //    else if (e.key === "Tab") {
    //        PreventDefault(e);
    //        taxTypeRef.current.focus();
    //    }
    //}

    //function OpenDateCalendar(e) {
    //    PreventDefault(e);
    //    resPermExDateRef.current.showPicker();
    //}


    //function HandleKeyPress(e, char) {        
    //    if (e.key === char) {
    //        return true;
    //    }
    //    return false;
    //}

    //function handleCalendarOpen(e) {
    //}

    //function handleCalendarClose(e) {
    //    //if (e.key === "Enter") {
    //        //PreventDefault(e);
    //        taxTypeRef.current.focus();
    //    //}
    //}

    function validateData() {
        var valid = true;
        var msg = "";

        if (IsEmpty(accountData.name)) {
            valid = false;
        }
        if (!isSelectedDropdown(accountData.storeId)) {
            setStoreRequiredHidden("");
            valid = false;
        }

        if (StringEmpty(accountData.resellerPermitExpiration)) {
            //non-required field set to null if empty for POST
            accountData.resellerPermitExpirationStr = null;
            accountData.resellerPermitExpirationStrDtPckr = null;
            accountData.resellerPermitExpiration = null;
        }
        else {
            if (NotIsValidDate(accountData.resellerPermitExpiration)) {
                setTaxValidDateHidden("");
                valid = false;
            }
        }

        if (!IsEmail(accountData.email1)) {
            //only required email
            setValidEmail1Hidden("");
            valid = false;
        }

        if (NotEmpty(accountData.email2) && !IsEmail(accountData.email2)) {
            setValidEmail2Hidden("");
            valid = false;
        }
        if (NotEmpty(accountData.statementEmail) && !IsEmail(accountData.statementEmail)) {
            setValidEmailStmntHidden("");
            valid = false;
        }

        if (NotEmpty(accountData.materialsEmail) && !IsEmail(accountData.materialsEmail)) {
            setValidEmailMatsHidden("");
            valid = false;
        }
        if (NotEmpty(accountData.materialsPhone) && !IsPhone(accountData.materialsPhone)) {
            setValidPhoneMatsHidden("");
            valid = false;
        }

        if (!IsPhone(accountData.phone1)) {
            setValidPhone1Hidden("");
            valid = false;
        }
        if (NotEmpty(accountData.phone2) && !IsPhone(accountData.phone2)) {
            setValidPhone2Hidden("");
            valid = false;
        }

        if (Equals(propsActiveTab, "SalesNotes")) {

            var dfPriceLevelId = GetSelectItemValueById("defaultPriceLevelId");
            if (NotExists(dfPriceLevelId)) {
                valid = false;
                msg = "Standard Account Pricing is required";
            }
        }

        if (IsFalse(valid)) {
            updateMessage(msg, "text-red");
        }

        return valid;
    }

    function hideValidationMessages() {
        updateMessage("", "");

        setStoreRequiredHidden(" hidden ");
        setTaxValidDateHidden(" hidden ");

        setValidEmail1Hidden(" hidden ");
        setValidEmail2Hidden(" hidden ");

        setValidEmailStmntHidden(" hidden ");
        setValidEmailMatsHidden(" hidden ");
        setValidPhoneMatsHidden(" hidden ");

        setValidPhone1Hidden(" hidden ");
        setValidPhone2Hidden(" hidden ");
    }

    function btnSubmitClick(e, redirectSubmit) {
        setRedirectSubmit(redirectSubmit);
    }

    const handleSubmitEdit = async (e) => {
        PreventDefault(e);
        hideValidationMessages();

        //set POST fields
        accountData.resellerPermitExpiration = FormatDateLongInput(resellerPermitExpDate);
        var valid = validateData();
        if (IsTrue(valid)) {
            try {
                accountData.saveTabType = propsActiveTab;
                if (Equals(accountId, 0)) {
                    accountData.saveTabType = "createAccount";
                }
                else if (Equals(propsActiveTab, "SalesNotes")) {
                    var dfPriceLevelId = GetSelectItemValueById("defaultPriceLevelId");
                    accountData.defaultPriceLevelId = dfPriceLevelId;                    
                }

                setAccountData(accountData);
                var model = createAccountModel(accountData);
                //console.log(JSON.stringify(model));

                if (accountId === 0) {
                    handlePost(model, true);
                }
                else if (accountId > 0) {
                    handlePost(model, false);
                }
                else {
                    updateMessage(defaultErrMsg, "text-red", "null/invalid accountId");
                }
            } catch (error) {
                updateMessage(createErrMsg, "text-red", error);
            }
        }
    }

    async function handlePost(model, create) {

        var accountStatus = "update";
        var accountMessage = model.name + updateMsg;
        if (create === true) {
            accountStatus = "create";
            accountMessage = model.name + createMsg;
        }

        var endpoint = "";
        if (create === true) {
            endpoint = 'accounts/AccountAdd';
        }
        else if (create === false) {
            endpoint = 'accounts/AccountUpdate';
        }

        if (endpoint && endpoint.length > 0) {
            try {
                const result = await post(endpoint, JSON.stringify(model));
                // Success - return Id from post
                const accountId = ParseIntSafe(result);
                if (IdExists(accountId)) {
                    if (redirectSubmit) {
                        // Redirect to Search on Update & Back
                        backToClick(accountStatus, accountMessage);
                    }
                    else {
                        if (create === true) {
                            // Redirect to Update page on successful Create
                            updateMessage(accountMessage, "text-green");
                            setSubmitDisabled("true");
                            navigate('/EditAccount',
                                {
                                    replace: true,
                                    state: { accountId, accountStatus, accountMessage }
                                });
                        }
                        else {
                            updateMessage(accountMessage, "text-green");
                        }
                    }
                }
            } catch (error) {
                console.error('Error: ' + error);
                var resMsg = error.status + ": " + error.statusText;
                if (create === true) {
                    updateMessage(createErrMsg, 'text-red', resMsg);
                } else {
                    updateMessage(updateErrMsg, "text-red", resMsg);
                }
            }
        }
        else {
            updateMessage(defaultErrMsg, "text-red", "null/empty apiUrl");
        }
    }

    function showAccountMessage() {
        if (location.state && location.state.accountStatus) {
            var status = location.state.accountStatus;
            if (status === "create") {
                updateMessage(createMsg, "text-green");
            }
        }
    }

    function addListener(baseId, changeId) {
        AddInputChangeListener(baseId, function () {
            setAccountData((vendorData) => ({
                ...vendorData,
                [changeId]: this.value
            }));
        });
    }

    function addAutofillListeners() {
        if (accountId === 0) {
            addListener("email1", "statementEmail");
            addListener("email1", "materialsEmail");
            addListener("phone1", "materialsPhone");
        }
    };

    function setDefaultValue(fieldId, fieldValue) {
        setAccountData((accountData) => ({
            ...accountData,
            [fieldId]: fieldValue
        }));
    }

    function setDefaultValues() {
        if (accountId === 0) {
            setDefaultValue("termsId", "1");
            setDefaultValue("taxTypeId", "1");
            setDefaultValue("statementTypeId", "1");

            //TODO: remove - when users are added
            setDefaultValue("storeId", "2");
        }
    }

    const handleAccountAddEditLoad = async () => {

        // edit Account
        if (IdExists(accountId)) {

            var data = await StandardIdAndResponseGET(accountId, "accounts/GetAccount/", "handleAccountAddEditLoad()");
            if (Exists(data)) {
                var model = createAccountModel(data);
                model.phone1 = formatValToPhone(model.phone1);
                model.phone2 = formatValToPhone(model.phone2);
                model.materialsPhone = formatValToPhone(model.materialsPhone);
                
                if (NotStringEmpty(data.resellerPermitExpirationStrDtPckr)) {
                    var expDate = new Date(data.resellerPermitExpirationStrDtPckr);
                    setResellerPermitExpDate(expDate);
                }
                setAccountData(model);

                setDocTitle("Lumina Account - " + data.name);
                SetPageTitle("Account - ", data.name);
            }
            else {
                updateMessage(defaultErrMsg, "text-red");
            }

            //var apiUrl = process.env.REACT_APP_API_URL + 'accounts/GetAccount/' + accountId;
            ////console.log('Account handleLoadDetails() api url: ' + apiUrl);

            //fetch(apiUrl)
            //    .then((response) => response.json())
            //    .then((data) => {
            //        var model = createAccountModel(data);
            //        setResellerPermitExpDate(model.resellerPermitExpirationStr);
            //        setAccountData(model);
            //        //console.log('Data: ' + JSON.stringify(data));
            //        //console.log('Model: ' + JSON.stringify(accountData));

            //        setDocTitle("Lumina Account - " + data.name);
            //        SetPageTitle("Account - ", data.name);
            //    })
            //    .catch((error) => {
            //        console.error('Error fetching data:', error);
            //});
        }
        else {
            // create Account
            setDocTitle("Lumina Create Account");
            SetPageTitle("Create Account");
        }
    };


    // ON LOAD
    useEffect(() => {
        loadDataLists();

        showAccountMessage();
        setDefaultValues();
        handleAccountAddEditLoad();

        addAutofillListeners();

        //from CFM - dont show red border on change
        //setTimeout(() => {
        //    AddMarkChangedListener("mark", "markch");
        //    AddMarkChangedListener("markta", "markch");
        //}, 500);
    }, []);

    return (
        <div className="p-4 pt-0">
            <form onSubmit={handleSubmitEdit} className="act-form">
                <div className="flex-wrap forms-page clear">

                    <div>
                        <div className="form-break pb-2">
                            <hr className="form-break-line" />
                            <span className="form-break-title">
                                Account Info
                            </span>
                        </div>
                        <div className="table-cell w-3/5">

                            <div className="details-form-field w-[98%]">
                                <label for="name" className="lbl-text1 !w-3/7">Business Name:</label>
                                <input id="name" name="name" type="text" placeholder=""
                                    className="inp-text table-cell mark" required
                                    value={accountData.name} onChange={handleChange}
                                />
                                <span className="text-sm text-red-600 hidden">*Required</span>
                            </div>

                            <div className="details-form-field w-[98%]">
                                <label for="address1" className="lbl-text1">Address:</label>
                                <input id="address1" name="address1" type="text"
                                    className="inp-text mark" required placeholder=""
                                    value={accountData.address1} onChange={handleChange}
                                />
                                <label for="address1" className="lbl-text">Address 1</label>
                            </div>

                            <div className="details-form-field w-[98%]">
                                <label for="address2" className="lbl-text1"></label>
                                <input id="address2" name="address2" type="text"
                                    className="inp-text mark" placeholder=""
                                    value={accountData.address2} onChange={handleChange}
                                />
                            </div>

                            <div className="details-form-field w-[98%]">
                                <div className="details-form-field !w-[65%] float-left">
                                    <label for="address2" className="lbl-text1">City, State, Zip:</label>
                                    <input id="city" name="city" type="text"
                                        className="inp-text mark w-[90%]" required placeholder=""
                                        value={accountData.city} onChange={handleChange}
                                    />
                                </div>
                                <div className="details-form-field !w-[15%] float-left">
                                    <label for="state" className="lbl-text1 lbl-state-zip"></label>
                                    <input id="state" name="state" type="text"
                                        className="inp-text mark" required placeholder=""
                                        value={accountData.state} onChange={handleChange}
                                    />
                                </div>
                                <div className="details-form-field !w-[20%] float-left">
                                    <label for="zip" className="lbl-text1 lbl-state-zip"></label>
                                    <input className="inp-text mark" required placeholder=""
                                        id="zip" name="zip" type="text"
                                        onKeyDown={e => enforceZipFormat(e)}
                                        value={accountData.zip}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="details-form-field-sel1 w-[98%]">
                                    <label for="storeId" className="lbl-text1">Store:</label>
                                    <select id="storeId" name="storeId"
                                        className="sel-dd mark"
                                        value={accountData.storeId} onChange={handleChange}
                                    >
                                        {storeList}
                                    </select>
                                    <span className={"text-sm text-red-600 " + storeRequiredHidden}>*Store is required</span>
                                </div>
                            </div>
                        </div>

                        <div className="table-cell w-2/5">
                            <div className="details-form-field !w-full">
                                {accountId > 0 &&
                                    <div className="table">
                                        <label for="accountNumber" className="lbl-text1 !w-2/5">Account #:</label>
                                        <input disabled id="accountNumber" name="accountNumber" type="text"
                                            className="inp-text" placeholder=""
                                            value={accountData.accountNumber} onChange={handleChange}
                                        />
                                    </div>
                                }
                                {accountId === 0 &&
                                    <div className="dff-ph">&nbsp;</div>
                                }
                            </div>
                            <div className="details-form-field !w-full">
                                {accountId > 0 &&
                                    <div className="table">
                                        <label for="enteredByName" className="lbl-text1 !w-2/5">Entered By:</label>
                                        <input disabled id="enteredByName" name="enteredByName" type="text"
                                            className="inp-text" placeholder=""
                                            value={accountData.enteredByName} onChange={handleChange}
                                        />
                                    </div>
                                }
                                {accountId === 0 &&
                                    <div className="dff-ph">&nbsp;</div>
                                }
                            </div>
                            <div className="details-form-field !w-full">
                                {accountId > 0 &&
                                    <div className="table">
                                        <label for="lastOrderDateStr" className="lbl-text1 !w-2/5">Last Order Date:</label>
                                        <input disabled id="lastOrderDateStr" name="lastOrderDateStr" type="text"
                                            className="inp-text" placeholder=""
                                            value={accountData.lastOrderDateStr}
                                        />
                                    </div>
                                }
                                {accountId === 0 &&
                                    <div className="dff-ph">&nbsp;</div>
                                }
                            </div>
                            <div className="details-form-field"><div className="dff-ph">&nbsp;</div></div>

                            <div className="details-form-field-sel1 w-full">
                                <label for="accountTypeId" className="lbl-text1">Account Type:</label>
                                <select id="accountTypeId" name="accountTypeId"
                                    className="sel-dd"
                                    value={accountData.accountTypeId} onChange={handleChange}
                                >
                                    {accountTypeList}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="form-break pb-2">
                            <hr className="form-break-line" />
                            <span className="form-break-title">
                                Contacts
                            </span>
                        </div>
                        <div className="form-col-1 table-cell w-full">                            

                            <div className="details-form-field w-[98%]">
                                <label for="ownerName" className="lbl-text1">Owner Name:</label>
                                <input id="ownerName" name="ownerName" type="text" placeholder=""
                                    className="inp-text mark" required
                                    value={accountData.ownerName} onChange={handleChange}
                                />
                            </div>
                            <div className="details-form-field w-[98%]">
                                <label for="phone1" className="lbl-text1">Owner Phone:</label>
                                <div className={"input-invalid-msg " + validPhone1Hidden}>
                                    *Invalid Phone
                                </div>
                                <input type="text" className="inp-text mark" required placeholder=""
                                    id="phone1" name="phone1"
                                    onKeyDown={e => enforcePhoneFormat(e)}
                                    value={accountData.phone1}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="details-form-field w-[98%]">
                                {/*<InputValidator label="Email:" id="email" name="email"*/}
                                {/*    type="email" placeholder=""*/}
                                {/*    labelClassNames={"lbl-text1 !w-1/5"}*/}
                                {/*    inputClassNames={"inp-text"}*/}
                                {/*    onKeyDown={(e) => EnterSave(e)}*/}
                                {/*    validation={{*/}
                                {/*        required: {*/}
                                {/*            value:*/}
                                {/*                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,*/}
                                {/*            message: 'Invalid Email',*/}
                                {/*        },*/}
                                {/*    }}></InputValidator>*/}

                                <label for="email1" className="lbl-text1">Owner Email:</label>
                                <div className={"input-invalid-msg " + validEmail1Hidden}>
                                    *Invalid Email
                                </div>
                                <input id="email1" name="email1" type="email" required
                                    className="inp-text mark"
                                    onChange={handleChange}
                                    value={accountData.email1}
                                />
                            </div>

                            <div className="details-form-field w-[98%]">
                                <label for="statementEmail" className="lbl-text1">
                                    Statement Email:
                                </label>
                                <div className={"input-invalid-msg " + validEmailStmntHidden}>
                                    *Invalid Email
                                </div>
                                <input id="statementEmail" name="statementEmail" type="email"
                                    className="inp-text mark"
                                    value={accountData.statementEmail} onChange={handleChange}
                                />
                            </div>

                            <div className="details-form-field w-[98%]">
                                <label for="materialsPhone" className="lbl-text1">
                                    Arrival Notice Phone:
                                </label>
                                <div className={"input-invalid-msg " + validPhoneMatsHidden}>
                                    *Invalid Phone
                                </div>
                                <input type="text" className="inp-text mark" placeholder=""
                                    id="materialsPhone" name="materialsPhone"
                                    onKeyDown={e => enforcePhoneFormat(e)}
                                    value={accountData.materialsPhone}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="form-col-2 table-cell">

                            <div className="details-form-field w-[98%]">
                                <label for="ownerName2" className="lbl-text1">Owner Name 2:</label>
                                <input id="ownerName2" name="ownerName2" type="text"
                                    className="inp-text" placeholder=""
                                    value={accountData.ownerName2} onChange={handleChange}
                                />
                            </div>

                            <div className="details-form-field w-[98%]">
                                <label for="phone2" className="lbl-text1">Owner Phone 2:</label>
                                <div className={"input-invalid-msg " + validPhone2Hidden}>
                                    *Invalid Phone
                                </div>
                                <input id="phone2" name="phone2" type="text" className="inp-text mark"
                                    onKeyDown={e => enforcePhoneFormat(e)}
                                    value={accountData.phone2} onChange={handleChange}
                                />
                            </div>

                            <div className="details-form-field w-[98%]">
                                <label for="email2" className="lbl-text1">Owner Email 2:</label>
                                <div className={"input-invalid-msg " + validEmail2Hidden}>
                                    *Invalid Email
                                </div>
                                <input id="email2" name="email2" type="email" className="inp-text mark"
                                    onChange={handleChange}
                                    value={accountData.email2}
                                />
                            </div>

                            <div className="details-form-field w-[98%]">
                                <label for="materialsEmail" className="lbl-text1">
                                    Arrival Notice Email:
                                </label>
                                <div className={"input-invalid-msg " + validEmailMatsHidden}>
                                    *Invalid Email
                                </div>
                                <input id="materialsEmail" name="materialsEmail" type="email"
                                    className="inp-text"
                                    value={accountData.materialsEmail} onChange={handleChange}
                                />
                            </div>

                            <div className="details-form-field"><div className="dff-ph">&nbsp;</div></div>
                        </div>
                    </div>

                    {(Equals(accountId, 0) || Equals(propsActiveTab, 'CreditNotes')) &&
                        <div>
                            <div className="form-break">
                                <hr className="form-break-line" />
                                <span className="form-break-title">
                                    Additional Info
                                </span>
                            </div>

                            <div className="table-cell w-[50%]">
                                <div className="details-form-field w-[98%]">
                                    <label for="businessLicense" className="lbl-text2">Business License:</label>
                                    <input id="businessLicense" name="businessLicense" type="text"
                                        className="inp-text mark" placeholder=""
                                        value={accountData.businessLicense} onChange={handleChange}
                                    />
                                </div>
                                <div className="details-form-field w-[98%]">
                                    <label for="taxIdNumber" className="lbl-text2">Tax ID #:</label>
                                    <input id="taxIdNumber" name="taxIdNumber" type="text"
                                        className="inp-text mark" placeholder=""
                                        value={accountData.taxIdNumber} onChange={handleChange}
                                    />
                                </div>

                                <div className="details-form-field w-[98%]">
                                    <label for="resellerPermitExpDate" className="lbl-text2">Reseller Permit Exp Date:</label>
                                    <DatePicker id="resellerPermitExpDate" name="resellerPermitExpDate" className="inp-date"
                                        showIcon toggleCalendarOnIconClick
                                        dateFormat="MM/dd/yyyy"
                                        selected={resellerPermitExpDate}
                                        onKeyDown={(e) => KeyHandlerDatePicker(e, handleSubmitEdit, setTodaysDate, null)}
                                        //onCalendarClose={(e) => handleCalendarClose(e)}
                                        //onCalendarOpen={(e) => handleCalendarOpen(e)}
                                        onChange={(date) => setResellerPermitExpDate(date)}
                                    />

                                    {/*<button id="openDatePicker" onClick={e => OpenDateCalendar(e)}></button>*/}
                                    {/*<input id="resellerPermitExpirationStr" name="resellerPermitExpirationStr" type="date"*/}
                                    {/*    className="inp-text mark" placeholder=""*/}
                                    {/*    //ref={resPermExDateRef}*/}
                                    {/*    //onFocus={resPermExDateRef.current.showPicker()}*/}
                                    {/*    //onChange={handleChange}*/}
                                    {/*    //value={accountData.resellerPermitExpirationStr}   */}
                                    {/*    onFocus={e => ClickById("openDatePicker")}*/}
                                    {/*    onClick={e => ClickById("openDatePicker")}*/}
                                    {/*    onKeyDown={(e) => KeyHandlerDate(e)}*/}
                                    {/*    onChange={handleDatePickerChange}*/}
                                    {/*    value={resellerPermitExpDate}                                */}
                                    {/*/>                            */}
                                    <span className={"text-sm text-red-600 " + taxValidDateHidden}>*Date must be valid</span>
                                </div>

                                <div className="details-form-field-sel1 w-[98%]">
                                    <label for="taxTypeId" className="lbl-text2">Tax Type:</label>
                                    <select id="taxTypeId" name="taxTypeId"
                                        className="sel-dd mark"
                                        value={accountData.taxTypeId} onChange={handleChange}
                                    >
                                        <option value="0">None</option>
                                        <option value="1">Taxable</option>
                                        <option value="2">Exempt</option>
                                        <option value="3">Resale</option>
                                    </select>
                                </div>                                
                            </div>

                            <div className="table-cell w-[50%]">
                                <div className="details-form-field-sel1 w-[98%]">
                                    <label for="termsId" className="lbl-text2">Payment Terms:</label>
                                    <select id="termsId" name="termsId"
                                        className="sel-dd mark"
                                        value={accountData.termsId} onChange={handleChange}
                                    >
                                        <option value="0">None</option>
                                        <option value="1">Cash</option>
                                        <option value="2">Credit</option>
                                    </select>
                                </div>

                                <div className="details-form-field-sel1 w-[98%]">
                                    <label for="statementTypeId" className="lbl-text2">Statement/Invoices:</label>
                                    <select id="statementTypeId" name="statementTypeId"
                                        className="sel-dd mark"
                                        value={accountData.statementTypeId} onChange={handleChange}
                                    >
                                        <option value="0">None</option>
                                        <option value="1">Statements</option>
                                        <option value="2">Invoices</option>
                                    </select>
                                </div>

                                <div className="details-form-field-sel1 w-[98%]">
                                    <label for="termsDaysId" className="lbl-text2">Statement Delivery:</label>
                                    <select id="termsDaysId" name="termsDaysId"
                                        className="sel-dd mark"
                                        value={accountData.termsDaysId} onChange={handleChange}
                                    >
                                        <option value="5">None</option>
                                        <option value="0">Review/Not Sent</option>
                                        <option value="1">Email</option>
                                        <option value="2">N/A</option>
                                        <option value="3">Mailed</option>
                                    </select>
                                </div>

                                <div className="details-form-field-sel1 w-[98%]">
                                    <label for="creditRatingId" className="lbl-text2">Credit Rating:</label>
                                    <select id="creditRatingId" name="creditRatingId"
                                        className="sel-dd mark"
                                        value={accountData.creditRatingId} onChange={handleChange}
                                    >
                                        <option value="0">None</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </select>
                                </div>
                            </div>
                            <div className="details-form-field w-[60%]">
                                <div className="!mt-[4px]">
                                    <label className="lbl-cb text-left !w-[170px]">
                                        Samples Account
                                        <input id="samplesAccount" name="samplesAccount"
                                            className="input-cb ml-1 mt-[-2px]"
                                            type="checkbox"
                                            checked={accountData.samplesAccount} onChange={handleChange}
                                        />
                                    </label>
                                    <label className="lbl-cb text-left !w-[130px]">
                                        Credit Hold
                                        <input id="creditHold" name="creditHold"
                                            className="input-cb ml-1 mt-[-2px]"
                                            type="checkbox"
                                            checked={accountData.creditHold} onChange={handleChange}
                                        />
                                    </label>
                                    <label className="lbl-cb text-left !w-[80px]">
                                        Inactive
                                        <input id="inactive" name="inactive"
                                            className="input-cb ml-1 mt-[-2px]"
                                            type="checkbox"
                                            checked={accountData.inactive} onChange={handleChange}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                    }

                    {/*<div className="w-full mb-4">*/}
                    {/*    */}{/*<InputValidator label="Import Notes:" type="textarea" placeholder=""*/}
                    {/*    */}{/*    id="notes" name="notes"                            */}
                    {/*    */}{/*    labelClassNames={"lbl-textarea"}*/}
                    {/*    */}{/*    inputClassNames={"inp-textarea"}*/}
                    {/*    */}{/*    onKeyDown={(e) => EnterSave(e)}*/}
                    {/*    */}{/*    validation={{*/}
                    {/*    */}{/*        required: {*/}
                    {/*    */}{/*            value: true,*/}
                    {/*    */}{/*            message: 'Required',*/}
                    {/*    */}{/*        },*/}
                    {/*    */}{/*    }}></InputValidator>*/}

                    {/*    <label for="notes" className="lbl-textarea">Import Notes:</label>*/}
                    {/*    <div className="border-textarea">*/}
                    {/*        <textarea id="notes" name="notes" rows="3"*/}
                    {/*            className="inp-textarea markta" placeholder=""*/}
                    {/*            value={accountData.notes} onChange={handleChange}*/}
                    {/*        >*/}
                    {/*        </textarea>*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                    <div className="w-full text-right pt-8">
                        <div className={"inline-block mr-1 " + messageClass}>
                            {message}
                        </div>
                        <div className="inline-block">
                            {//hide buttons clicked from parent page
                                <div>
                                    <button id="btnNewOrderAccount" onClick={createOrderClick}
                                        className="hidden" type="button">
                                        Create Order
                                    </button>
                                    <a id="lnkNewQuoteAccount" className="hidden"
                                        href={"/Redirect?route=CreateQuote&orderType=2&status=create&accountId=" + accountId}
                                    >Create Quote</a>
                                </div>
                            }
                            <button onClick={e => backToClick('')} type="button" className="btn-cancel">
                                Cancel
                            </button>
                            <button type="submit" className="btn-submit" disabled={submitDisabled}
                                onClick={(e) => btnSubmitClick(e, true)}>
                                Save & Close
                            </button>                            
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}