import React, { useState, useEffect, useRef, useCallback } from 'react';

import { ChartAccountForm } from "./ChartAccountForm.js";
import { EditChartAccountForm } from "./EditChartAccountForm";
//import { ChartAccountsList } from "./ChartAccountsList.js";

import { SetDocumentTitle } from "../../_reactHelpers/ReactHelpers";
import { SetPageTitle } from '../../../js/helpers';
import { get } from '../../../services/apiService';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

export function ChartOfAccounts() {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Chart of Accounts");

    const [chartAccounts, setChartAccounts] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    
    const [currentChartAccount, setCurrentChartAccount] = useState(null);
    const [modalShow, setModalShow] = useState(false);   

    const gridRef = useRef();
    const [gridApi, setGridApi] = useState(null);
    const [gridHeight, setGridHeight] = useState('500px');
    const columnDefs = [
        {
            headerName: 'Account Id', field: 'chartAccountId', maxWidth: 120, hide: true,
        },
        {
            headerName: 'Account Number', field: 'accountNumber',
        },
        {
            headerName: 'Account Name', field: 'accountName',
        },
        {
            headerName: 'Account Type', field: 'accountTypeDesc',
        },
        {
            headerName: 'Sub Account', field: 'subAccount',
            valueFormatter: params => params.value || ""
        },
        {
            headerName: 'Notes', field: 'notes',
            valueFormatter: params => params.value || ""
        },
        {
            headerName: '', maxWidth: 100,
            cellRenderer: params => (
                <div className="text-center">
                    <button onClick={() => handleEdit(params.data)} className="btn-grid">Edit</button>
                </div>),
        }
    ];

    const gridOptions = {
        defaultColDef: {
            minWidth: 100,
            flex: 1,
            sortable: true,
            resizable: true,
            filter: true,
            suppressMovable: true,
            suppressMenu: false,
            cellClass: ["no-border"],
            menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab",],
        }
    };

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
    }, []);

    const onFilterTextBoxChanged = useCallback(() => {
        gridApi.setQuickFilter(
            document.getElementById('filter-text-box').value
        );
    }, [gridApi]);

    const handleChartAccountUpdate = () => {
        fetchChartAccounts();
    };       

    const fetchChartAccounts = async () => {
        try {

            const response = await get("chartaccount/GetChartAccounts");
            const data = response.map((item) => ({
                ...item,
                id: item.chartAccountId,
            }));

            setChartAccounts(data);
        } catch (error) {
            console.error("Error fetching chart account:", error);
            setChartAccounts([]);
        }
    }

    const handleEdit = (chartAccount) => {
        setCurrentChartAccount(chartAccount);
        setModalShow(true);
    };

    const closeModalShow = () => setModalShow(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);


    useEffect(() => {
        SetPageTitle("Chart of Accounts");
        fetchChartAccounts();
    }, []);

    return (
        <div className="page-wrapper">
            <div>                
                <div className="flex-wrapper mb-2">
                    <div className="flex-1">
                        <input
                            type="text"
                            id="filter-text-box"
                            placeholder="Filter..."
                            onInput={onFilterTextBoxChanged}
                            className="grid-filter"
                        />
                    </div>
                    <button onClick={openModal} className="btn-small">
                        Add Chart Account
                    </button>
                </div>

                <div className="ag-theme-alpine w-full" style={{ height: gridHeight }}>
                    <AgGridReact
                        ref={gridRef}
                        columnDefs={columnDefs}
                        gridOptions={gridOptions}
                        onGridReady={onGridReady}
                        rowData={chartAccounts}                        
                        pagination={true}
                        paginationPageSize={30}
                    />                    
                </div>

                {isModalOpen &&
                    <div className="modal-wrapper">
                        <div className="modal-content">
                            <ChartAccountForm
                                onChartAccountAdded={() => {
                                    closeModal();
                                    handleChartAccountUpdate();
                                }}
                                closeModal={closeModal}></ChartAccountForm>
                        </div>
                    </div>
                }

                {modalShow && currentChartAccount &&
                    <div className="modal-wrapper">
                        <div className="modal-content">
                            <EditChartAccountForm
                                chartAccountData={currentChartAccount}
                                closeModal={closeModalShow}
                                onChartAccountUpdate={() => {
                                    closeModalShow();
                                    handleChartAccountUpdate();
                                }}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}