import React, { useState } from 'react';
import { Component } from 'react';
import { UsersGrid } from './UsersGrid';

export class UsersSearch extends Component {
    static displayName = UsersSearch.name;

    //constructor() {
    //  super();
    //  this.state = { data: [], loading: true };
    //}

    render() {
        return (
            <div>
                <UsersGrid></UsersGrid>
            </div>
        );
    }
}
