import React, { useState, useEffect } from 'react';
import { GetInputDate } from '../../js/formHelpers';
import { GetValueById } from '../../js/helpers';
import { get, post } from '../../services/apiService'; 

export default function EditBankRegisterForm({ bankRegisterData, onBankRegisterUpdated, onClose }) {
    const [formData, setFormData] = useState({
        registerId: 0,
        checkNumber: '',
        date: '',
        payee: '',
        description: '',
        status: '',
        amount: 0,
        transactionType: 'deposit',
        balance: 0
    });

    const [loading, setLoading] = useState(true);
    const [statusMessage, setStatusMessage] = useState('');
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (bankRegisterData) {
            setFormData({
                registerId: bankRegisterData.registerId,
                checkNumber: bankRegisterData.checkNumber || '',
                date: bankRegisterData.date ? GetInputDate(bankRegisterData.date) : '',
                payee: bankRegisterData.payee || '',
                description: bankRegisterData.description || '',
                status: bankRegisterData.status || '',
                amount: bankRegisterData.amount || 0,
                transactionType: bankRegisterData.transactionType || 'deposit',
                balance: bankRegisterData.balance || 0
            });
            setLoading(false);
        }
    }, [bankRegisterData]);

    const handleChange = (e) => {
        let { name, value } = e.target;
        //if (name === 'date') {
        //    value += ':00Z';
        //}
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!formData.registerId) {
            setStatusMessage('Invalid Bank Register ID');
            setIsError(true);
            return;
        }

        try {
            var dt = GetValueById("date");
            formData.date = dt;
            setFormData(formData);

            //console.log('Posting:', formData);
            await post('bankregister/UpdateBankRegister', formData);

            setStatusMessage('Bank Register Updated Successfully');
            setIsError(false);
            onBankRegisterUpdated();

        } catch (error) {
            console.error('Error updating Bank Register:', error);
            setStatusMessage('Error updating Bank Register');
            setIsError(true);
        }
    };

    if (loading) {
        return <div className="text-center">Loading...</div>;
    }

    return (
        <div className="">
            <h2 className="text-2xl font-bold mb-4 text-center">Edit Bank Register</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="checkNumber" className="block text-sm font-medium text-gray-700">Check Number</label>
                    <input
                        type="number"
                        id="checkNumber"
                        name="checkNumber"
                        value={formData.checkNumber}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
                    <input type="date" id="date" name="date"
                        defaultValue={formData.date}
                        //value={formData.date}
                        //onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="payee" className="block text-sm font-medium text-gray-700">Payee</label>
                    <input
                        type="text"
                        id="payee"
                        name="payee"
                        value={formData.payee}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                    <input
                        type="text"
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="status" className="block text-sm font-medium text-gray-700">Status</label>
                    <input
                        type="text"
                        id="status"
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="amount" className="block text-sm font-medium text-gray-700">Amount</label>
                    <input
                        type="number"
                        id="amount"
                        name="amount"
                        value={formData.amount}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="transactionType" className="block text-sm font-medium text-gray-700">Transaction Type</label>
                    <select
                        id="transactionType"
                        name="transactionType"
                        value={formData.transactionType}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    >
                        <option value="deposit">Deposit</option>
                        <option value="withdrawal">Withdrawal</option>
                    </select>
                </div>
                {statusMessage && (
                    <div className={`p-4 rounded-md ${isError ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'}`}>
                        {statusMessage}
                    </div>
                )}
                <div className="flex justify-end space-x-2">
                    <button
                        type="button"
                        onClick={onClose}
                        className="btn-cancel"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn-submit"
                    >
                        Update Bank Register
                    </button>
                </div>
            </form>
        </div>
    );
}
