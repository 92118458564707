import React, { useState, useEffect } from 'react';

import { SetDocumentTitle } from '../_reactHelpers/ReactHelpers.js';
import { get } from '../../services/apiService.js';
import { ProductCodeList } from './ProductCodeList.js';
import { SetPageTitle } from '../../js/helpers.js';

export function ProductCodes() {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Product Categories");

    const [productCodes, setProductCodes] = useState([]);
    const [loading, setLoading] = useState(true);    

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    const fetchProductCodes = async () => {
        try {
            const data = await get('productcode/GetProductCodes');
            //console.log("Codes: ", data);

            setProductCodes(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching Product codes:', error);

            setProductCodes([]);
            setLoading(false);
            updateMessage(defaultErrMsg, 'text-red-600');
        }
    };

    const handleAddProductCode = () => {        
        fetchProductCodes();
    };

    const handleProductCodeUpdated = () => {
        fetchProductCodes();
    };

    const updateMessage = (msg, cssClass) => {
        setMessage(msg);
        setMessageClass(cssClass);
    };

    useEffect(() => {
        SetPageTitle("Product Categories");
        fetchProductCodes();
    }, []);

    return (
        <div className="p-4">            
            {message && <div className={`text-center ${messageClass}`}>{message}</div>}
            <div>
                <ProductCodeList
                    productCodes={productCodes}
                    loading={loading}
                    onProductCodeUpdated={handleProductCodeUpdated}
                    handleAddProductCode={handleAddProductCode}
                />
            </div>            
        </div>
    );
}