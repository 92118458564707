import React, { Component } from 'react';
import { initFlowbite } from "flowbite";

import { DisableEscKey } from '../../js/helpers';

import { LuminaMenu } from './LuminaMenu';
import './Header.css';

export class Header extends Component {
    static displayName = Header.name;

    constructor(props) {
        super(props);
        this.DisableEscFunction = this.DisableEscFunction.bind(this);
    }

    DisableEscFunction(e) {
        DisableEscKey(e);
    }

    componentDidMount() {
        initFlowbite();
        document.addEventListener("keydown", this.DisableEscFunction, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.DisableEscFunction, false);
    }

  render() {
    return (
      <header>
            <LuminaMenu></LuminaMenu>
      </header>
    );
  }
}
