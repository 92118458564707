import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

//import { VendorAutocomplete } from '../_reactHelpers/VendorAutocomplete';
import { EntityAutocomplete } from '../_reactHelpers/EntityAutocomplete';

import {
    AgCurrencyMxWdSm, AgIntMxWdSm, defaultErrMsg, Exists, formatCurrencyDecimal,
    formatNumberDecPlaces,
    GetDateNow, GetDisplayCurrencyStr, GetDisplayDecimalStr, GetDisplayIntStr, GetInputDate, handleFieldChange,
    IdExists, IsFalse, isSelectedDropdown, IsTrue, NotEmpty, NotExists, ParseIntSafe
} from '../../js/formHelpers';
import { get, post } from '../../services/apiService';
import { NotEquals } from '../../js/calculationHelpers';

export const AddEditNonSupplierBillModal = ({ initialBillData, handleSubmit, stores, onHide }) => {

    const isEditMode = IdExists(initialBillData.billId);
    const [localFormData, setLocalFormData] = useState(initialBillData);

    const storeNames = getStoreNamesList();    
    const [chartAccounts, setChartAccounts] = useState([]);
    const [glAccountNames, setGlAccountNames] = useState([]);
        
    const [vendors, setVendors] = useState([]);
    const [vendorChartAccount, setVendorChartAccount] = useState(null);

    const [isManualAllocation, setIsManualAllocation] = useState(true);
    const [allocationGroups, setAllocationGroups] = useState([]);    
    const [groupAllocations, setGroupAllocations] = useState([]);

    const [billNumberUsed, setBillNumberUsed] = useState(false);

    const [gridApi, setGridApi] = useState(null);
    const defaultColDef = useMemo(() => ({
        flex: 1,
        minWidth: 100,
        editable: true,
        singleClickEdit: true,
        stopEditingWhenCellsLoseFocus: true,
        sortable: false,
        resizable: true,
        filter: false,
        suppressMenu: true,
        suppressMovable: true,
        cellClass: ["no-border"]
    }), []);

    function getChartAccountNameById(id, data) {
        if (NotExists(data)) {
            data = chartAccounts ?? [];
        }
        id = ParseIntSafe(id);

        const chart = data.find(c => c.chartAccountId === id);
        if (Exists(chart)) {
            return chart.accountNumber + " - " + chart.accountName;
        }
        return "";
    }


    const getChartIdByName = useCallback((chartName) => {
        var data = chartAccounts ?? [];
        const chart = data.find(chart => (chart.accountNumber + " - " + chart.accountName) === chartName);
        if (Exists(chart)) {
            return chart.chartAccountId;
        }
        return 0;
    }, [chartAccounts]);

    function getStoreNamesList() {
        var list = [];
        stores.forEach((store, index) => {
            var name = getStoreNameById(store.storeId);
            list.push(name);
        });
        return list;
    }

    function getStoreNameById(storeId) {
        storeId = ParseIntSafe(storeId);
        const store = stores.find(store => store.storeId === storeId);
        if (Exists(store)) {
            return "#" + store.storeNumber + " - " + store.name;
        }
        return "";
    }

    const getStoreIdByName = useCallback((storeName) => {
        const store = stores.find(store => ("#" + store.storeNumber + " - " + store.name) === storeName);
        if (Exists(store)) {
            return store.storeId;
        }
        return 0;
    }, [stores]);

    const handleDeleteRow = useCallback((params) => {
        const updatedLineItems = localFormData.billLineItems.filter((item, index) => index !== params.node.rowIndex);

        setLocalFormData(prevData => ({
            ...prevData,
            billLineItems: updatedLineItems
        }));
    }, [localFormData.billLineItems]);

    //const GlCodeCellRenderer = (props) => {
    //    const handleSelect = (selectedOption) => {
    //        props.setValue(selectedOption.chartAccountId);

    //        const updatedLineItems = [...localFormData.billLineItems];
    //        const updatedRow = { ...updatedLineItems[props.rowIndex] };
    //        updatedRow.chartAccountId = selectedOption.chartAccountId;
    //        updatedRow.chartAccountId = selectedOption.chartAccountId;

    //        updatedLineItems[props.rowIndex] = updatedRow;
    //        setLocalFormData(prevData => ({
    //            ...prevData,
    //            billLineItems: updatedLineItems
    //        }));


    //        //if (gridApi) {
    //        //    gridApi.forEachNode(node => {
    //        //        node.setDataValue('glCode', allocationGlCode);
    //        //    });
    //        //    gridApi.refreshCells({ force: true, columns: ['glCode'] });
    //        //}
    //    };

    //    return (
    //        <div style={{ position: 'relative', zIndex: 1000 }}>
    //            <EntityAutocomplete
    //                name="glCode"
    //                id={`glCode-${props.rowIndex}`}
    //                onSelect={handleSelect}
    //                onChange={() => { }}
    //                initialEntityId={props.value}
    //                entityType="GL Account"
    //            />
    //        </div>
    //    );
    //};

    const columnDefs = [
        {
            field: 'lineNumber', headerName: 'Line #', maxWidth: AgIntMxWdSm(), editable: false, hide: true,
            valueFormatter: params => GetDisplayIntStr(params.value)
        },
        {
            field: 'storeName', headerName: 'Store',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: storeNames,
            },
            //valueFormatter: params => {
            //    var storeName = params.value;
            //    if (Exists(params.data)) {
            //       storeName = getStoreNameById(params.data.storeId);
            //    }
            //    return storeName;
            //},
            //cellEditor: StoreCellEditor,
            //cellEditorPopup: false,            
        },
        {
            field: 'glAccountCode', headerName: 'G/L Code',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: glAccountNames,
            },
            //field: 'chartAccountId',
            //headerName: 'G/L Code',
            //valueFormatter: params => params.data.glAccountNumber + " - " + params.data.glAccountName
            //cellRenderer: GlCodeCellRenderer,
            //cellRendererParams: {
            //    getValue: (params) => params.data.chartAccountId,
            //    setValue: (params, value) => {
            //        params.data.glCode = value;
            //        params.api.refreshCells({ rowNodes: [params.node], columns: ['chartAccountId'] });
            //    }
            //},
        },
        {
            field: 'allocationPercent', headerName: 'Percent', maxWidth: AgCurrencyMxWdSm(),
            hide: IsTrue(isManualAllocation),
            valueFormatter: params => GetDisplayDecimalStr(params.value) + "%"
        },
        {
            field: 'total', headerName: 'Amount', maxWidth: AgCurrencyMxWdSm(),
            editable: IsTrue(isManualAllocation),
            valueFormatter: params => GetDisplayCurrencyStr(params.value)
        },
        {
            headerName: '', field: '',
            maxWidth: 80,
            editable: false,
            cellRenderer: (params) => {
                return (
                    <button onClick={() => handleDeleteRow(params)}
                        className="btn-grid-red" tabIndex="-1" // Prevent tab focus?
                    >Delete</button>
                );
            },
            suppressNavigable: true,
        }
    ];

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    }, []);

    const onCellValueChanged = useCallback((event) => {
        const updatedLineItems = [...localFormData.billLineItems];
        const updatedRow = { ...updatedLineItems[event.rowIndex] };

        if (event.colDef.field === "storeName") {
            const storeId = getStoreIdByName(event.newValue);
            updatedRow["storeId"] = storeId;
            updatedRow[event.colDef.field] = event.newValue;
        }
        else if (event.colDef.field === "glAccountCode") {
            const chartAccountId = getChartIdByName(event.newValue);
            updatedRow["chartAccountId"] = chartAccountId;
            updatedRow[event.colDef.field] = event.newValue;
        }
        else {
            updatedRow[event.colDef.field] = event.newValue;
        }

        updatedLineItems[event.rowIndex] = updatedRow;
        setLocalFormData(prevData => ({
            ...prevData,
            billLineItems: updatedLineItems
        }));
    }, [localFormData.billLineItems, setLocalFormData, getStoreIdByName, getChartIdByName]);


    const addNewRow = () => {
        const storeName = getStoreNameById(localFormData.storeId);

        var vendorChartAccountId = vendorChartAccount?.chartAccountId ?? 0;
        var glAccountCode = getChartAccountNameById(vendorChartAccountId);

        setLocalFormData(prevData => {
            const newLineNumber = (prevData.billLineItems?.length || 0) + 1;
            const newRow = {
                lineNumber: newLineNumber,
                storeId: localFormData.storeId,
                storeName: storeName,
                chartAccountId: vendorChartAccountId,
                allocationPercent: 0,
                total: 0,
                glAccountCode: glAccountCode
            };

            return {
                ...prevData,
                billLineItems: [
                    ...(prevData.billLineItems || []),
                    newRow
                ]
            };
        });
    };

    const handleChange = (e) => {
        var data = handleFieldChange(e);
        if (data) {

            var allocationGroupId = localFormData.allocationGroupId
            var billLineItems = localFormData.billLineItems;
            var adjustedTotal = localFormData.adjustedTotal;
            var discAmt = localFormData.discAmt;
            var name = data.name;
            var value = data.value;
            
            if (name === 'billTotal' || name === 'nonDiscAmt' || name === 'discRate') {
                const billTotal = Number(name === 'billTotal' ? value : localFormData.billTotal) || 0;
                const nonDiscAmt = Number(name === 'nonDiscAmt' ? value : localFormData.nonDiscAmt) || 0;
                const discRate = Number(name === 'discRate' ? value : localFormData.discRate) || 0;
                discAmt = ((billTotal - nonDiscAmt) * discRate / 100);
                adjustedTotal = (nonDiscAmt + discAmt).toFixed(2);
                discAmt = discAmt.toFixed(2);
            }

            if (data.name === "storeId") {
                setIsManualAllocation(true);
                value = parseInt(value, 10);
                data.value = value;

                const storeName = getStoreNameById(value);
                var vendorChartAccountId = vendorChartAccount?.chartAccountId ?? 0;
                var glAccountCode = getChartAccountNameById(vendorChartAccountId);
                //var glAccountCode = "";
                //if (Exists(vendorChartAccount)) {
                //    glAccountCode = vendorChartAccount.glAccountNumber + " - " + vendorChartAccount.glAccountName;
                //}
                
                billLineItems = [{
                    lineNumber: 1,
                    storeId: value,
                    storeName: storeName,
                    chartAccountId: vendorChartAccountId,
                    glAccountCode: glAccountCode,
                    allocationPercent: 0,
                    total: 0
                }];
            }

            setLocalFormData((prevData) => ({
                ...prevData,
                [data.name]: data.value,
                discAmt: discAmt,
                adjustedTotal: adjustedTotal,
                billLineItems: billLineItems
            }));
                        
            if (data.name === "allocationGroupId") {
                createAllocationRows(value);                
            }
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {
            if (IsTrue(billNumberUsed)) {
                alert("Bill Number has already been used.")
                return;
            }
            if (!isSelectedDropdown(localFormData.billStatus)) {
                alert("Bill Status is required");
                return;
            }
            if (localFormData.billLineItems.length === 0) {
                alert('Please add at least one line item.');
                return;
            }

            var lineSum = 0;
            localFormData.billLineItems.forEach(line => {
                lineSum += parseFloat(line.total) ?? parseFloat(0);
            });

            var totalsEqual = parseFloat(localFormData.billTotal) === parseFloat(lineSum);
            if (IsFalse(totalsEqual)) {
                alert('Bill Total and Line Items Total must be equal.');
                return;
            }

            const formDataToSubmit = { ...localFormData };
            formDataToSubmit.vendorId = parseInt(formDataToSubmit.vendorId, 10);            
            formDataToSubmit.billStatus = parseInt(formDataToSubmit.billStatus, 10);

            ['billTotal', 'discRate', 'discAmt', 'nonDiscAmt', 'adjustedTotal'].forEach(field => {
                formDataToSubmit[field] = parseFloat(formDataToSubmit[field]);
            });

            formDataToSubmit.billLineItems = formDataToSubmit.billLineItems.map(item => ({
                ...item,
                storeId: getStoreIdByName(item.storeName),
                chartAccountId: getChartIdByName(item.glAccountCode)
            }));

            var storeId = parseInt(formDataToSubmit.storeId, 10);
            if (IdExists(storeId)) {
                formDataToSubmit.storeId = storeId;
            }
            else {
                formDataToSubmit.storeId = formDataToSubmit.billLineItems[0].storeId;
            }
            //console.log('Submitting form data:', formDataToSubmit);

            var response = null;
            if (isEditMode) {
                response = await post('bills/UpdateBill', formDataToSubmit);
            }
            else {
                response = await post('bills/CreateBill', formDataToSubmit);
            }

            if (IdExists(response)) {
                handleSubmit(true);
            }
        } catch (error) {
            console.error('Error creating bill:', error);

            if (error.response) {
                console.error('Error response:', error.response.data);
                alert(defaultErrMsg());
            } else {
                console.error('Error response:', error.message);
                alert(defaultErrMsg());
            }
        }
    };

    const handleSelectVendor = (selectedOption) => {

        var dueDate = GetInputDate(null, 0, 0, selectedOption.termsDays);
        setVendorChartAccount(selectedOption.chartAccount);

        //var glAccountCode = getChartAccountNameById(selectedOption.chartAccount?.chartAccountId ?? 0);
        //if (Exists(selectedOption)) {
            //glAccountCode = selectedOption.glAccountNumber + " - " + selectedOption.glAccountName;
        //}

        //var billLineItems = localFormData.billLineItems.map(item => ({
        //    ...item,
        //    chartAccountId: selectedOption.chartAccountId,
        //    glAccountCode: glAccountCode
        //}));

        setLocalFormData(prevData => ({
            ...prevData,
            vendorId: selectedOption.vendorId,
            discRate: selectedOption.discountPercent,
            dueDate: dueDate,
            //billLineItems: billLineItems
        }));
    };

    async function billNumberExists(billNumber) {
        setBillNumberUsed(false);
        try {
            const billNumberExists = await get('/bills/BillNumberExists/' + billNumber);
            if (IsTrue(billNumberExists)) {
                setBillNumberUsed(true);
                alert("Bill Number has already been used.")
            }
        } catch (error) {
            console.error('billNumberExists() error - ', error);
        } finally {
        }
    }

    function createAllocationRows(groupId) {
        setIsManualAllocation(false);
        var allocations = [];
        groupId = ParseIntSafe(groupId);

        if (NotEmpty(allocationGroups) && IdExists(groupId)) {    
            
            const selectedGroup = allocationGroups.find(group => group.groupId === groupId);
            if (selectedGroup && selectedGroup.allocationItems)
            {
                var vendorChartAccountId = vendorChartAccount?.chartAccountId ?? 0;
                var glAccountCode = getChartAccountNameById(vendorChartAccountId);
                //var glAccountCode = "";
                //if (Exists(vendorChartAccount)) {
                //    glAccountCode = vendorChartAccount.glAccountNumber + " - " + vendorChartAccount.glAccountName;
                //}

                allocations = selectedGroup.allocationItems.map(item => ({
                    storeId: item.storeId,
                    storeName: getStoreNameById(item.storeId),
                    allocationPercent: parseFloat(item.allocationPercent),
                    total: (item.allocationPercent / 100) * localFormData.billTotal,
                    chartAccountId: vendorChartAccountId,
                    glAccountCode: glAccountCode
                }));
                console.log('Group Allocations: ' + JSON.stringify(allocations));
                
            } else {
                console.error('Selected group not found or has no allocation items');
                setGroupAllocations([]);
            }            
        }

        setLocalFormData(prevData => ({
            ...prevData,
            billLineItems: allocations
        }));
    }

    const fetchAllocationGroups = async () => {
        try {
            const response = await get('storeallocation/GetStoreAllocationGroups');
            console.log('Fetched allocation groups:', response);
            setAllocationGroups(response);
        } catch (error) {
            console.error('Error fetching allocation groups:', error);
        }
    };

    const fetchChartAccounts = async () => {
        try {

            const response = await get("chartaccount/GetChartAccounts");
            if (NotEmpty(response)) {
                setChartAccounts(response);

                var list = [];
                response.forEach((chart, index) => {
                    var name = getChartAccountNameById(chart.chartAccountId, response);
                    list.push(name);
                });
                setGlAccountNames(list);
            }
            else {
                setChartAccounts([]);
                setGlAccountNames([]);
            }

        } catch (error) {
            console.error("Error fetching chart account:", error);
            setChartAccounts([]);
        }
    }
    
    useEffect(() => {
        fetchAllocationGroups();
        fetchChartAccounts();

        //var updatedLineItems = [];
        //if (initialBillData) {
        //    if (NotEmpty(initialBillData.billLineItems)) {
        //        updatedLineItems = initialBillData.billLineItems.map(item => {
        //            const allocationPercent = formatNumberDecPlaces(item.allocationPercent, 2) || 0;
        //            return {
        //                ...item,
        //                glAccountCode = getChartAccountNameById(item.chartAccountId ?? 0),
        //                storeName = getStoreNameById(item.storeId ?? 0);
        //            };
        //        });
        //    }
        //}
    }, []);

    useEffect(() => {
        var updatedLineItems = [];

        if (IsFalse(isManualAllocation)) {
            if (NotEmpty(localFormData.billLineItems)) {

                updatedLineItems = localFormData.billLineItems.map(item => {
                    const allocationPercent = formatNumberDecPlaces(item.allocationPercent, 2) || 0;
                    return {
                        ...item,
                        allocationPercent: allocationPercent.toFixed(2),
                        total: ((allocationPercent / 100) * localFormData.billTotal).toFixed(2)
                    };
                });
            }

            if (JSON.stringify(updatedLineItems) !== JSON.stringify(localFormData.billLineItems)) {
                setLocalFormData(prevData => ({
                    ...prevData,
                    billLineItems: updatedLineItems
                }));
            }
        }
    }, [localFormData.billLineItems, localFormData.billTotal, isManualAllocation]);

    useEffect(() => {
        if (initialBillData) {
            //console.log('Setting localFormData:', initialBillData);


            setLocalFormData(prevData => ({
                ...prevData,
                ...initialBillData,
                billType: 2,
                billStatus: IdExists(initialBillData.billId) ? initialBillData.billStatus : 1,
                billDate: GetInputDate(initialBillData.billDate),
                dueDate: IdExists(initialBillData.billId) ? GetInputDate(initialBillData.dueDate) : "",
                billTotal: initialBillData.billTotal.toFixed(2),
                nonDiscAmt: initialBillData.nonDiscAmt.toFixed(2),
                discRate: initialBillData.discRate.toFixed(2),
                discAmt: initialBillData.discAmt.toFixed(2),
                adjustedTotal: initialBillData.adjustedTotal.toFixed(2),
            }));
        }
    }, [initialBillData]);

    return (
        <div className="">
            <div className="max-h-[90vh]">

                <h2 className="text-2xl font-bold text-center underline">{isEditMode ? 'Update None-Supplier Bill' : 'Create Non-Supplier Bill'}</h2>
                <div className="">

                    <form onSubmit={handleFormSubmit} className="">
                        <h4 className="text-xl font-bold underline">Bill Details</h4>

                        <div className="p-2">
                            <div className="grid grid-cols-1 grid-cols-5 gap-5 pr-2">
                                <div className="form-control">
                                    <label className="label !pb-[2px]">
                                        <span className="label-text">Vendor</span>
                                        <span className="">
                                            <button>
                                                <svg class="w-5 h-5 text-gray-800" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                        stroke-width="2" d="M12 7.757v8.486M7.757 12h8.486M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                    </svg>
                                            </button>
                                        </span>
                                    </label>                                    
                                    <EntityAutocomplete name="vendorId" id="vendorId"
                                        onSelect={handleSelectVendor}
                                        onChange={handleChange}
                                        initialEntityId={localFormData.vendorId}
                                        entityType="Vendor"
                                        required
                                    />
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Bill Number</span>
                                    </label>
                                    <input type="text" placeholder="" name="billNumber"
                                        value={localFormData.billNumber}
                                        onChange={handleChange}
                                        className="input input-bordered"
                                        required
                                        onBlur={(e) => { billNumberExists(e.target.value) }}
                                    />
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Bill Date</span>
                                    </label>
                                    <input
                                        type="date"
                                        name="billDate"
                                        value={localFormData.billDate}
                                        onChange={handleChange}
                                        className="input input-bordered"
                                        required
                                    />
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Due Date</span>
                                    </label>
                                    <input
                                        type="date"
                                        name="dueDate"
                                        value={localFormData.dueDate}
                                        onChange={handleChange}
                                        className="input input-bordered"
                                        required
                                    />
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Status</span>
                                    </label>
                                    <select name="billStatus"
                                        value={localFormData.billStatus}
                                        onChange={handleChange}
                                        className="select select-bordered"
                                    >
                                        <option value={0}>-- Select --</option>
                                        <option value={1}>Ready for Payment</option>
                                        <option value={2}>Payment Pending</option>
                                        <option value={3}>Paid</option>
                                        <option value={4}>Hold</option>
                                    </select>
                                </div>
                            </div>

                            <div className="grid grid-cols-1 grid-cols-5 gap-5 pt-2">
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Bill Total</span>
                                    </label>
                                    <label className="input-group">
                                        <span>$</span>
                                        <input name="billTotal" type="number" step="0.01" placeholder=""
                                            value={localFormData.billTotal}
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                                const formattedValue = Number(e.target.value).toFixed(2);
                                                setLocalFormData(prev => ({
                                                    ...prev,
                                                    billTotal: formattedValue
                                                }));
                                            }}
                                            className="input input-bordered"
                                            required
                                        />
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Non-Discount Amount</span>
                                    </label>
                                    <label className="input-group">
                                        <span>$</span>
                                        <input name="nonDiscAmt" type="number" step="0.01" placeholder=""
                                            value={localFormData.nonDiscAmt}
                                            onChange={handleChange}
                                            className="input input-bordered"
                                            required
                                            onBlur={(e) => {
                                                const formattedValue = Number(e.target.value).toFixed(2);
                                                setLocalFormData(prev => ({
                                                    ...prev,
                                                    nonDiscAmt: formattedValue
                                                }));
                                            }}
                                        />
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Discount Rate</span>
                                    </label>
                                    <label className="input-group">
                                        <input name="discRate" type="number" step="0.01" placeholder=""
                                            value={localFormData.discRate}
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                                const formattedValue = Number(e.target.value).toFixed(2);
                                                setLocalFormData(prev => ({
                                                    ...prev,
                                                    discRate: formattedValue
                                                }));
                                            }}
                                            className="input input-bordered"
                                            required
                                        />
                                        <span>%</span>
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Discount Total</span>
                                    </label>
                                    <label className="input-group">
                                        <span>$</span>
                                        <input name="discAmt" type="number" step="0.01" placeholder=""
                                            value={localFormData.discAmt}
                                            className="input input-bordered"
                                            readOnly
                                        />
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Adjusted Bill Total</span>
                                    </label>
                                    <label className="input-group">
                                        <span>$</span>
                                        <input name="adjustedTotal" type="number" step="0.01" placeholder=""
                                            value={localFormData.adjustedTotal}
                                            className="input input-bordered"
                                            readOnly
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className="grid grid-cols-1 grid-cols-4 gap-4 pt-2">

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Store</span>
                                    </label>
                                    <select
                                        name="storeId"
                                        value={localFormData.storeId}
                                        onChange={handleChange}
                                        className="select select-bordered"
                                        required
                                    >
                                        <option value={0}>-- Select --</option>
                                        {NotEmpty(stores) && stores.map((store) => (
                                            <option key={store.storeId} value={store.storeId}>
                                                {"#" + store.storeNumber + " - " + store.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Store Allocations</span>
                                    </label>
                                    <select
                                        name="allocationGroupId"
                                        value={localFormData.allocationGroupId}
                                        onChange={handleChange}
                                        className="select select-bordered"
                                        required
                                    >
                                        <option value={0}>-- Select --</option>
                                        {NotEmpty(allocationGroups) && allocationGroups.map((ag) => (
                                            <option key={ag.groupId} value={ag.groupId}>
                                                {ag.groupName}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                            </div>

                            <div className="mt-8">
                                <h4 className="text-xl font-bold underline float-left">Line Item Details</h4>
                                <div className="float-right">
                                    <button
                                        type="button"
                                        onClick={addNewRow}
                                        className="btn-submit"
                                    >
                                        Add New Line Item
                                    </button>
                                </div>
                            </div>

                            <div className="ag-theme-alpine w-full ag-grid-act clear pt-2" style={{ height: '250px' }}>
                                <AgGridReact
                                    rowData={localFormData.billLineItems || []}
                                    defaultColDef={defaultColDef}
                                    columnDefs={columnDefs}
                                    onGridReady={onGridReady}
                                    onCellValueChanged={onCellValueChanged}
                                    editType="fullRow"
                                    enterMovesDownAfterEdit={true}
                                    stopEditingWhenCellsLoseFocus={true}
                                    //components={{
                                        //glCodeCellRenderer: GlCodeCellRenderer,
                                    //}}
                                />
                            </div>
                        </div>

                        <div className="modal-action mt-6">
                            <button type="button" onClick={onHide} className="btn-cancel">Close</button>
                            <button type="submit" className="btn-submit">
                                {isEditMode ? 'Update Bill' : 'Create Bill'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}