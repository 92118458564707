import React, { useState, useEffect, useMemo, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import {
    GetDisplayDateStr, GetDisplayDecimalStr, IsTrue,
    NotEmpty, AgDateMxWdSm, NotStringEmpty, Exists, StringEmpty, IdExists
} from '../../../js/formHelpers';

import { SetPageTitle } from '../../../js/helpers';
import { SetDocumentTitle } from '../../_reactHelpers/ReactHelpers';
import { get } from '../../../services/apiService';
 
export function ProcessContentCentralPayables() {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Process CC Payables");

    const [rowData, setRowData] = useState([]);

    const [gridApi, setGridApi] = useState(null);
    const [searchText, setSearchText] = useState("");
    const gridRef = useRef();

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const columnDefs = [
        {
            headerName: 'Supplier', field: 'supplier',
        },
        {
            headerName: 'Invoice #', field: 'supplierInvoiceNumber',
        },
        {
            headerName: 'CC PO #', field: 'luminaOrderNumber',
            cellRenderer: params => (
                <div>
                    {IdExists(params.data.orderId) &&
                        <a href={"/ViewFile/" + encodeURIComponent(params.data.documentPath)}
                            target="_blank" rel="noreferrer"
                            className="text-primary underline">
                            {params.value + " - View"}
                        </a>
                    }
                    {!IdExists(params.data.orderId) &&
                        <span>{params.value}</span>
                    }
                </div>
            )
        },        
        {
            headerName: 'Invoice Date', field: 'invoiceDate', maxWidth: AgDateMxWdSm(),
            valueFormatter: params => GetDisplayDateStr(params.value)
        },
        {
            headerName: 'Due Date', field: 'dueDate', maxWidth: AgDateMxWdSm(),
        },
        {
            headerName: 'Status', field: 'status', maxWidth: 130,
        },        
        {
            headerName: 'Order #', field: 'orderNumber', maxWidth: 100,
            cellRenderer: params => (
                <div>
                    {IdExists(params.data.orderId) &&
                        <a href={"/Redirect?route=EditOrder&orderId=" + params.data.orderId}
                            target="_blank" rel="noreferrer"
                            className="text-primary underline">
                            {params.value}
                        </a>
                    }
                    {!IdExists(params.data.orderId) &&
                        <span>{params.value}</span>
                    }
                </div>
            )
        },
        {
            headerName: 'Order Status', field: 'orderStatusStr', maxWidth: 120,
        },
        {
            headerName: '', maxWidth: 70, filter: false, sortable: false,
            cellRenderer: params => (
                //onClick = {() => handleEditClick(params.data)}
                <button type="button" className="btn-grid" disabled>Edit</button>
            )
        },
    ];

    const defaultColDef = useMemo(() => ({
        minWidth: 100,
        flex: 1,
        sortable: true,
        resizable: true,
        filter: true,
        suppressMovable: true,
        suppressMenu: false,
        cellClass: ["no-border"],
        menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab",],
    }), []);

    const onFilterTextBoxChanged = (event) => {
        const newSearchText = event.target.value;
        setSearchText(newSearchText);
        gridApi.setQuickFilter(newSearchText);
    };

    const fetchContentCentral = async () => {
        setRowData([]);

        try {
            const data = await get('contentcentral/GetDocumentOrders');
            if (Exists(data) && NotEmpty(data.scannedDocuments)) {
                setRowData(data.scannedDocuments);
            }
        } catch (error) {
            console.error("fetchContentCentral() error: ", error);
        }
    };


    useEffect(() => {
        SetPageTitle("Process CC Payables");
        fetchContentCentral();
    }, []);

    return (
        <div className="page-wrapper">

            <input type="text" className="grid-filter !w-[400px]" placeholder="Filter..."
                value={searchText}
                onChange={onFilterTextBoxChanged}
            />

            <div className="ag-theme-alpine ag-grid-act w-full pt-2" style={{ height: '600px' }}>
                <AgGridReact
                    ref={gridRef}
                    onGridReady={onGridReady}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    pagination={true}
                    paginationPageSize={30}
                    rowSelection={'single'}
                />
            </div>
        </div>
    );
}