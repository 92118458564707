import React, { useState, useRef, useEffect } from 'react';
import { Modal, Button } from 'react-daisyui';
import { post, get } from '../../../services/apiService.js';
import { formatCurrencyDecimal } from '../../../js/formHelpers.js';

import '../../../css/custom.css';

export const TaxForm = ({ onTaxAdded, closeAddModal, taxes, isOpen }) => {
    const [formData, setFormData] = useState({
        id: '',
        city: '',
        state: '',
        county: '',
        zip: '',
        taxMethod: 'Sales Tax',
        salesTaxBase: 'Material',
        salesTaxPercent: '',
        cityTaxPercent: '',
        payTaxTime: 'Delivered',
        taxCode: ''
    });

    const [taxCodes, setTaxCodes] = useState([]);

    const formRef = useRef(null);

    const [showToast, setShowToast] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    const states = [
        { name: 'Alabama', abbreviation: 'AL' },
        { name: 'Alaska', abbreviation: 'AK' },
        { name: 'Arizona', abbreviation: 'AZ' },
        { name: 'Arkansas', abbreviation: 'AR' },
        { name: 'California', abbreviation: 'CA' },
        { name: 'Colorado', abbreviation: 'CO' },
        { name: 'Connecticut', abbreviation: 'CT' },
        { name: 'Delaware', abbreviation: 'DE' },
        { name: 'Florida', abbreviation: 'FL' },
        { name: 'Georgia', abbreviation: 'GA' },
        { name: 'Hawaii', abbreviation: 'HI' },
        { name: 'Idaho', abbreviation: 'ID' },
        { name: 'Illinois', abbreviation: 'IL' },
        { name: 'Indiana', abbreviation: 'IN' },
        { name: 'Iowa', abbreviation: 'IA' },
        { name: 'Kansas', abbreviation: 'KS' },
        { name: 'Kentucky', abbreviation: 'KY' },
        { name: 'Louisiana', abbreviation: 'LA' },
        { name: 'Maine', abbreviation: 'ME' },
        { name: 'Maryland', abbreviation: 'MD' },
        { name: 'Massachusetts', abbreviation: 'MA' },
        { name: 'Michigan', abbreviation: 'MI' },
        { name: 'Minnesota', abbreviation: 'MN' },
        { name: 'Mississippi', abbreviation: 'MS' },
        { name: 'Missouri', abbreviation: 'MO' },
        { name: 'Montana', abbreviation: 'MT' },
        { name: 'Nebraska', abbreviation: 'NE' },
        { name: 'Nevada', abbreviation: 'NV' },
        { name: 'New Hampshire', abbreviation: 'NH' },
        { name: 'New Jersey', abbreviation: 'NJ' },
        { name: 'New Mexico', abbreviation: 'NM' },
        { name: 'New York', abbreviation: 'NY' },
        { name: 'North Carolina', abbreviation: 'NC' },
        { name: 'North Dakota', abbreviation: 'ND' },
        { name: 'Ohio', abbreviation: 'OH' },
        { name: 'Oklahoma', abbreviation: 'OK' },
        { name: 'Oregon', abbreviation: 'OR' },
        { name: 'Pennsylvania', abbreviation: 'PA' },
        { name: 'Rhode Island', abbreviation: 'RI' },
        { name: 'South Carolina', abbreviation: 'SC' },
        { name: 'South Dakota', abbreviation: 'SD' },
        { name: 'Tennessee', abbreviation: 'TN' },
        { name: 'Texas', abbreviation: 'TX' },
        { name: 'Utah', abbreviation: 'UT' },
        { name: 'Vermont', abbreviation: 'VT' },
        { name: 'Virginia', abbreviation: 'VA' },
        { name: 'Washington', abbreviation: 'WA' },
        { name: 'West Virginia', abbreviation: 'WV' },
        { name: 'Wisconsin', abbreviation: 'WI' },
        { name: 'Wyoming', abbreviation: 'WY' }
    ];

    const initialFormState = {
        id: '',
        city: '',
        state: '',
        county: '',
        zip: '',
        taxMethod: 'Sales Tax',
        salesTaxBase: 'Material',
        salesTaxPercent: '',
        cityTaxPercent: '',
        payTaxTime: 'Delivered',
        taxCode: ''
    };

    useEffect(() => {
        setFormData(initialFormState);
        fetchTaxCodes();
    }, [isOpen]);

    const fetchTaxCodes = async () => {
        try {
            console.log("Fetching tax codes...");
            const response = await get('/citysalestax/GetCitySalesTaxes');
            console.log("Response received:", response);
            const codes = response.data.map(item => ({
                value: item.taxCode,
                label: `${item.city} - ${item.taxCode}`
            }));
            console.log("Tax codes:" + codes);
            setTaxCodes(codes);
        } catch (error) {
            console.error('Error fetching tax codes:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'taxCode') {
            const selectedOption = taxes.find(option => option.taxCode === value);
            console.log('Tax change: ' + JSON.stringify(selectedOption));
            if (selectedOption) {
                setFormData(prevData => ({
                    ...prevData,
                    id: selectedOption.id,
                    taxCode: value,
                    salesTaxPercent: selectedOption.salesTaxPercent,
                    city: selectedOption.city,
                    state: selectedOption.state,
                    zip: selectedOption.zip,
                    county: selectedOption.county,
                    cityTaxPercent: selectedOption.cityTaxPercent
                }));
            }
        } else {
            setFormData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await post('citysalestax/AddCitySalesTax', formData);
            setSuccess('Tax Added Successfully');
            onTaxAdded();
            setFormData(initialFormState); // Reset fields
        } catch (error) {
            setError('Error adding tax: ' + error.message);
        }
    };

    return (
        <div className="w-full max-w-4xl mx-auto">
            <div className="flex border-b">
                <div className="p-4 flex-grow">
                    <form ref={formRef} className="space-y-4 md:space-y-6" action="#">
                        <div className="flex space-x-4">
                            <div className="form-control flex-1">
                                <label className="label">
                                    <span className="label-text">City</span>
                                </label>
                                <input type="text" name="city"
                                    value={formData.city} onChange={handleChange}
                                    className="input input-bordered w-full" required />
                            </div>

                            <div className="form-control flex-1">
                                <label className="label">
                                    <span className="label-text">County</span>
                                </label>
                                <input type="text" name="county"
                                    value={formData.county} onChange={handleChange}
                                    className="input input-bordered w-full" />
                            </div>

                            <div className="form-control flex-1">
                                <label className="label">
                                    <span className="label-text">State</span>
                                </label>
                                <select
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                    className="select select-bordered w-full"
                                    required
                                >
                                    <option value="">Select State</option>
                                    {states.map((state) => (
                                        <option key={state.abbreviation} value={state.abbreviation}>
                                            {state.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="form-control flex-1">
                                <label className="label">
                                    <span className="label-text">Zip</span>
                                </label>
                                <input type="text" name="zip"
                                    value={formData.zip} onChange={handleChange}
                                    className="input input-bordered w-full" />
                            </div>
                        </div>

                        <div className="flex space-x-4">
                            <div className="form-control flex-1">
                                <label className="label">
                                    <span className="label-text">Tax Method</span>
                                </label>
                                <select name="taxMethod" className="select select-bordered w-full" value={formData.taxMethod} onChange={handleChange}>
                                    <option disabled value="">Select Tax Method</option>
                                    <option>Sales Tax</option>
                                </select>
                            </div>

                            <div className="form-control flex-1">
                                <label className="label">
                                    <span className="label-text">Tax Base</span>
                                </label>
                                <select name="salesTaxBase" className="select select-bordered w-full"
                                    value={formData.salesTaxBase} onChange={handleChange}>
                                    <option disabled value="">Select Tax Base</option>
                                    <option>Material</option>
                                </select>
                            </div>

                            <div className="form-control flex-1">
                                <label className="label">
                                    <span className="label-text">Pay Tax Time</span>
                                </label>
                                <select name="payTaxTime" className="select select-bordered w-full"
                                    value={formData.payTaxTime} onChange={handleChange}>
                                    <option disabled value="">Select Pay Tax Time</option>
                                    <option>Delivered</option>
                                </select>
                            </div>
                        </div>

                        <div className="flex space-x-2">
                            <div className="form-control flex-1">
                                <label className="label">
                                    <span className="label-text">Sales Tax Percent</span>
                                </label>
                                <input name="salesTaxPercent" type="number"
                                    value={formData.salesTaxPercent} onChange={handleChange}
                                    className="input input-bordered w-full" required />
                            </div>

                            <div className="form-control flex-1">
                                <label className="label">
                                    <span className="label-text">City Tax Percent</span>
                                </label>
                                <input type="number" name="cityTaxPercent"
                                    className="input input-bordered w-full" required step="0.01"
                                    value={formData.cityTaxPercent} onChange={handleChange}
                                />
                            </div>

                            <div className="form-control flex-1">
                                <label className="label">
                                    <span className="label-text">Tax Code</span>
                                </label>
                                <select
                                    name="taxCode" className="select select-bordered w-full"
                                    value={formData.taxCode}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select Tax Code</option>
                                    {taxes.map(option => (
                                        <option key={option.taxCode} value={option.taxCode}>
                                            {option.taxCode} - {option.city}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="flex justify-end">
                            <Button
                                type="button"
                                className="btn-cancel"
                                onClick={closeAddModal}
                                color="primary"
                            >
                                Cancel
                            </Button>
                            <Button
                                className="btn-submit"
                                onClick={handleSubmit}
                            >
                                Add Tax
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="text-red-500 p-4">{error}</div>
            <div className="text-green-500 font-bold p-4">{success}</div>
        </div>
    );
}