import React, { useState, useEffect, useRef } from 'react';

import { Equals } from '../../../js/calculationHelpers';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { GetDisplayDateStr } from '../../../js/formHelpers';

export function BillsList({ bills, handleSelectedRowsChange }) {

    const gridRef = useRef();
    const columnDefs = [
        {
            headerName: '', maxWidth: 50, headerCheckboxSelection: true, checkboxSelection: true,
        },
        {
            headerName: 'Payee', field: 'payee',
        },
        {
            headerName: 'Bill Type', field: 'billTypeDesc', maxWidth: 200,
        },
        {
            headerName: 'Bill #', field: 'billNumber', maxWidth: 200,
        },
        {
            headerName: 'Date', field: 'billDate', maxWidth: 150,
            valueFormatter: params => GetDisplayDateStr(params.value)
        },
        {
            headerName: 'Due Date', field: 'dueDate', maxWidth: 150,
            valueFormatter: params => GetDisplayDateStr(params.value)
        },
        {
            headerName: 'Bill Amount', field: 'billTotal', maxWidth: 200,
            valueFormatter: (params) => {
                if (params.value != null) {
                    return new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }).format(params.value);
                }
                return '';
            }
        },
        {
            headerName: 'Status', field: 'billStatusDesc', maxWidth: 200,
        },
    ];

    const gridOptions = {
        defaultColDef: {
            minWidth: 100,
            flex: 1,
            sortable: true,
            resizable: true,
            filter: true,
            suppressMovable: true,
            suppressMenu: false,
            cellClass: ["no-border"],
            menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab",],
        }
    };

    function getVendorTypeDesc(vendorTypeId) {
        if (Equals(vendorTypeId, 1)) {
            return "Supplier";
        }
        else if (Equals(vendorTypeId, 2)) {
            return "Account Commissions";
        }
        else if (Equals(vendorTypeId, 3)) {
            return "Non-Material Supplier";
        }
        else {
            return "";
        }
    }

    useEffect(() => {
        //const updateGridHeight = () => {
        //    const windowHeight = window.innerHeight;
        //    const offsetTop = document.querySelector('.ag-theme-alpine')?.offsetTop || 0;
        //    const newHeight = windowHeight - offsetTop - 20; // 20px for some bottom margin
        //    setGridHeight(`${newHeight}px`);
        //};

        //updateGridHeight();
        //window.addEventListener('resize', updateGridHeight);
        //return () => window.removeEventListener('resize', updateGridHeight);

    }, []);


    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        handleSelectedRowsChange(selectedRows);
    }

    return (
        <div className="ag-theme-alpine w-full" style={{ height: '600px' }}>
            <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={bills}
                gridOptions={gridOptions}          
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                pagination={true}
                paginationPageSize={20}
            />
        </div>
    );
}