import React, { useState, useCallback, useMemo, useEffect } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

//import { VendorAutocomplete } from '../_reactHelpers/VendorAutocomplete';
import { EntityAutocomplete } from '../_reactHelpers/EntityAutocomplete';

import {
    AgCurrencyMxWdSm, AgIntMxWdSm, defaultErrMsg, Exists, formatCurrencyDecimal, GetDisplayCurrencyStr, GetDisplayDecimalStr,
    GetDisplayIntStr, GetInputDate, handleFieldChange, IdExists, isSelectedDropdown, IsTrue, NotEmpty, NotExists
} from '../../js/formHelpers';
import { get, post } from '../../services/apiService';

export const AddEditSupplierBillModal = ({ initialBillData, handleSubmit, stores, onHide }) => {

    const isEditMode = IdExists(initialBillData.billId);
    const [localFormData, setLocalFormData] = useState(initialBillData);

    const [vendors, setVendors] = useState([]);
    const [billNumberUsed, setBillNumberUsed] = useState(false);

    const [gridApi, setGridApi] = useState(null);
    const defaultColDef = useMemo(() => ({
        flex: 1,
        minWidth: 100,
        editable: true,
        singleClickEdit: true,
        stopEditingWhenCellsLoseFocus: true,
        sortable: false,
        resizable: true,
        filter: false,
        suppressMenu: true,
        suppressMovable: true,
        cellClass: ["no-border"]
    }), []);

    const handleDeleteRow = useCallback((params) => {
        const updatedLineItems = localFormData.billLineItems.filter((item, index) => index !== params.node.rowIndex);

        setLocalFormData(prevData => ({
            ...prevData,
            billLineItems: updatedLineItems
        }));
    }, [localFormData.billLineItems]);

    const columnDefs = useMemo(() => [
        {
            field: 'lineNumber', headerName: 'Line #', maxWidth: AgIntMxWdSm(), editable: false,
            valueFormatter: params => GetDisplayIntStr(params.value)
        },
        {
            field: 'description', headerName: 'Style',
        },
        {
            field: 'details', headerName: 'Color',
        },
        {
            field: 'quantity', headerName: 'Quantity', maxWidth: AgCurrencyMxWdSm(),
            valueFormatter: params => GetDisplayDecimalStr(params.value)
        },
        {
            field: 'cost', headerName: 'Cost', maxWidth: AgCurrencyMxWdSm(),
            valueFormatter: params => GetDisplayCurrencyStr(params.value)
        },
        {
            field: 'freight', headerName: 'Freight', maxWidth: AgCurrencyMxWdSm(),
            valueFormatter: params => GetDisplayCurrencyStr(params.value)
        },
        {
            field: 'total', headerName: 'Total', maxWidth: AgCurrencyMxWdSm(),
            editable: false,
            valueFormatter: params => GetDisplayCurrencyStr(params.value)
        },
        {
            headerName: '', field: '',
            maxWidth: 80,
            editable: false,
            cellRenderer: (params) => {
                return (
                    <button onClick={() => handleDeleteRow(params)}
                        className="btn-grid-red" tabIndex="-1" // Prevent tab focus?
                    >Delete</button>
                );
            },
            suppressNavigable: true,
        }
    ], [handleDeleteRow]);


    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    }, []);

    const onCellValueChanged = useCallback((event) => {
        const updatedLineItems = [...localFormData.billLineItems];
        const updatedRow = { ...updatedLineItems[event.rowIndex] };

        updatedRow[event.colDef.field] = event.newValue;

        // Calculate total
        const quantity = Number(updatedRow.quantity) || 0;
        const cost = Number(updatedRow.cost) || 0;
        const freight = Number(updatedRow.freight) || 0;
        updatedRow.total = (quantity * cost + freight).toFixed(2);

        updatedLineItems[event.rowIndex] = updatedRow;

        setLocalFormData(prevData => ({
            ...prevData,
            billLineItems: updatedLineItems
        }));
    }, [localFormData.billLineItems, setLocalFormData]);


    const addNewRow = useCallback(() => {
        setLocalFormData(prevData => {
            const newLineNumber = (prevData.billLineItems?.length || 0) + 1;
            const newRow = {
                lineNumber: newLineNumber,
                description: '',
                details: '',
                quantity: 0,
                cost: 0,
                freight: 0,
                total: 0
            };

            return {
                ...prevData,
                billLineItems: [
                    ...(prevData.billLineItems || []),
                    newRow
                ]
            };
        });
    }, []);

    const handleChange = (e) => {
        var data = handleFieldChange(e);
        if (data) {

            var adjustedTotal = localFormData.adjustedTotal;            
            var discAmt = localFormData.discAmt;
            var name = data.name;
            var value = data.value;

            if (name === 'billTotal' || name === 'nonDiscAmt' || name === 'discRate') {
                const billTotal = Number(name === 'billTotal' ? value : localFormData.billTotal) || 0;
                const nonDiscAmt = Number(name === 'nonDiscAmt' ? value : localFormData.nonDiscAmt) || 0;
                const discRate = Number(name === 'discRate' ? value : localFormData.discRate) || 0;
                discAmt = ((billTotal - nonDiscAmt) * discRate / 100);
                adjustedTotal = (nonDiscAmt + discAmt).toFixed(2);
                discAmt = discAmt.toFixed(2);
            }

            setLocalFormData((prevData) => ({
                ...prevData,
                [data.name]: data.value,
                discAmt: discAmt,
                adjustedTotal: adjustedTotal,
            }));

            if (data.name === "vendorId") {
                if (IdExists(localFormData.orderId) && IdExists(value)) {
                    createNewSupplierBillFromOrder(localFormData.orderId, value);
                }
            }
        }
    };

    //const handleFormSubmit = async (e) => {
    //    e.preventDefault();

    //    try {

    //        if (IsTrue(billNumberUsed)) {
    //            alert("Bill Number has already been used.")
    //            return;
    //        }
    //        if (!isSelectedDropdown(localFormData.billStatus)) {
    //            alert("Bill Status is required");
    //            return;
    //        }

    //        const formDataToSubmit = { ...localFormData };
    //        formDataToSubmit.vendorId = parseInt(formDataToSubmit.vendorId, 10);
    //        formDataToSubmit.storeId = parseInt(formDataToSubmit.storeId, 10);

    //        ['billTotal', 'discRate', 'discAmt', 'nonDiscAmt'].forEach(field => {
    //            formDataToSubmit[field] = parseFloat(formDataToSubmit[field]);
    //        });

    //        formDataToSubmit.billLineItems = formDataToSubmit.billLineItems.map(item => ({
    //            ...item,
    //            lineNumber: parseInt(item.lineNumber, 10),
    //            quantity: parseFloat(item.quantity),
    //            freight: parseFloat(item.freight),
    //            total: parseFloat(item.total),
    //            vendorId: formDataToSubmit.vendorId
    //        }));
    //        //console.log('Submitting form data:', formDataToSubmit);

    //        // submit to parent page
    //        handleSubmit(formDataToSubmit);            

    //    } catch (error) {
    //        console.error("Error submitting form:", error);
    //    }
    //};


    const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {
            if (IsTrue(billNumberUsed)) {
                alert("Bill Number has already been used.")
                return;
            }
            if (!isSelectedDropdown(localFormData.billStatus)) {
                alert("Bill Status is required");
                return;
            }
            if (!localFormData.storeId || localFormData.billLineItems.length === 0) {
                alert('Please fill in all fields and add at least one line item.');
                return;
            }

            const formDataToSubmit = { ...localFormData };
            formDataToSubmit.vendorId = parseInt(formDataToSubmit.vendorId, 10);
            formDataToSubmit.storeId = parseInt(formDataToSubmit.storeId, 10);
            formDataToSubmit.billStatus = parseInt(formDataToSubmit.billStatus, 10);

            ['billTotal', 'discRate', 'discAmt', 'nonDiscAmt'].forEach(field => {
                formDataToSubmit[field] = parseFloat(formDataToSubmit[field]);
            });

            formDataToSubmit.billLineItems = formDataToSubmit.billLineItems.map(item => ({
                ...item,
                lineNumber: parseInt(item.lineNumber, 10),
                quantity: parseFloat(item.quantity),
                freight: parseFloat(item.freight),
                total: parseFloat(item.total),
                vendorId: formDataToSubmit.vendorId
            }));
            //console.log('Submitting form data:', formDataToSubmit);

            var response = null;
            if (isEditMode) {
                response = await post('bills/UpdateBill', formDataToSubmit);                
            }
            else {
                response = await post('bills/CreateBill', formDataToSubmit);
            }
                        
            if (IdExists(response)) {                
                handleSubmit(true);
            }                
        } catch (error) {
            console.error('Error creating bill:', error);

            if (error.response) {
                console.error('Error response:', error.response.data);
                alert(defaultErrMsg());
            } else {
                console.error('Error response:', error.message);
                alert(defaultErrMsg());
            }
        }
    };

    const handleSelectOrder = (selectedOption) => {

        setLocalFormData(prevData => ({
            ...prevData,
            orderId: selectedOption.orderId,
            poNumber: selectedOption.orderNumber
        }));

        createNewSupplierBillFromOrder(selectedOption.orderId, 0);
    };

    const handleSelectVendor = (selectedOption) => {

        setLocalFormData(prevData => ({
            ...prevData,
            vendorId: selectedOption.vendorId
        }));
    };

    async function billNumberExists(billNumber) {
        setBillNumberUsed(false);
        try {
            const billNumberExists = await get('/bills/BillNumberExists/' + billNumber);
            if (IsTrue(billNumberExists)) {
                setBillNumberUsed(true);
                alert("Bill Number has already been used.")
            }
        } catch (error) {
            console.error('billNumberExists() error - ', error);
        } finally {
        }
    }

    async function createNewSupplierBillFromOrder(orderId, vendorId) {
        try {
            if (IdExists(orderId)) {

                const billData = await get('/bills/CreateNewSupplierBillFromOrder?orderId=' + orderId + '&vendorId=' + vendorId);
                if (Exists(billData)) {

                    if (vendorId === 0) {
                        setVendors(billData.poVendors ?? []);
                    }
                    
                    billData.billTotal = billData.billTotal.toFixed(2);
                    billData.nonDiscAmt = billData.nonDiscAmt.toFixed(2);
                    billData.discAmt = billData.discAmt.toFixed(2);
                    billData.discRate = billData.discRate.toFixed(2);

                    if (Exists(billData.billDate)) {
                        var inpDate = GetInputDate(billData.billDate);
                        billData.billDate = inpDate;
                    }
                    //if (NotStringEmpty(invoiceData.accountName)) {
                    //    SetValueById("inputEntityAutoComplete-Account", invoiceData.accountName);
                    //}

                    setLocalFormData(billData);
                }
                else {
                    alert("Unable to create Bill for selected PO Number. Please try again or contact support.");
                }
            }
        } catch (error) {
            console.error('createNewSupplierBillFromOrder() error - ', error);
        } finally {
        }
    }

    useEffect(() => {
        var updatedLineItems = [];
        if (NotEmpty(localFormData.billLineItems)) {

            updatedLineItems = localFormData.billLineItems.map(item => {
                const quantity = Number(item.quantity) || 0;
                const cost = Number(item.cost) || 0;
                const freight = Number(item.freight) || 0;


                return {
                    ...item,
                    quantity: quantity.toFixed(2),
                    cost: cost.toFixed(2),
                    freight: freight.toFixed(2),
                    total: (quantity * cost + freight).toFixed(2)
                };
            });
        }

        if (JSON.stringify(updatedLineItems) !== JSON.stringify(localFormData.billLineItems)) {
            setLocalFormData(prevData => ({
                ...prevData,
                billLineItems: updatedLineItems
            }));
        }
    }, [localFormData.billLineItems]);

    useEffect(() => {
        if (initialBillData) {
            //console.log('Setting localFormData:', initialBillData);

            setLocalFormData(prevData => ({
                ...prevData,
                ...initialBillData,
                billType: 1,
                billStatus: IdExists(initialBillData.billId) ? initialBillData.billStatus : 1,
                billDate: GetInputDate(initialBillData.billDate),
                dueDate: IdExists(initialBillData.billId) ? GetInputDate(initialBillData.dueDate) : "",
                billTotal: formatCurrencyDecimal(initialBillData.billTotal),
                nonDiscAmt: formatCurrencyDecimal(initialBillData.nonDiscAmt),
                discRate: formatCurrencyDecimal(initialBillData.discRate),
                discAmt: formatCurrencyDecimal(initialBillData.discAmt),
                adjustedTotal: formatCurrencyDecimal(initialBillData.adjustedTotal)
            }));
        }
    }, [initialBillData]);

    return (
        <div className="">
            <div className="max-h-[90vh]">

                <h2 className="text-2xl font-bold text-center underline">{isEditMode ? 'Update Supplier Bill' : 'Create Supplier Bill'}</h2>
                <div className="">

                    <form onSubmit={handleFormSubmit} className="">
                        <h4 className="text-xl font-bold underline">Bill Details</h4>

                        {/*<div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">*/}
                        {/*    <div*/}
                        {/*        className={`h-2.5 rounded-full ${progress === 100 ? 'bg-green-600' : 'bg-blue-600'}`}*/}
                        {/*        style={{ width: `${progress}%` }}*/}
                        {/*    ></div>*/}
                        {/*</div>*/}

                        <div className="p-2">
                            <div className="grid grid-cols-1 grid-cols-4 gap-4 pr-2">

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">PO Number</span>
                                    </label>
                                    {!IdExists(localFormData.billId) &&
                                        <EntityAutocomplete name="poNumber" id="poNumber"
                                            onSelect={handleSelectOrder} onChange={handleChange}
                                            initialEntityId={localFormData.orderId}
                                            entityType="Order"
                                            required
                                        />
                                    }
                                    {IdExists(localFormData.billId) &&
                                        <input type="text" name="poNumber" placeholder=""
                                            value={localFormData.poNumber}
                                            onChange={handleChange}
                                            className="input input-bordered"                                            
                                            required
                                        />
                                    }
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">PO Vendor</span>
                                    </label>
                                    {!IdExists(localFormData.billId) &&
                                        <select
                                            name="vendorId"
                                            value={localFormData.vendorId}
                                            onChange={handleChange}
                                            className={"select select-bordered " +
                                                (IdExists(localFormData.orderId) && !IdExists(localFormData.vendorId) ? " border-red-500" : "")}
                                            required
                                        >
                                            <option value="">-- Select --</option>
                                            {NotEmpty(vendors) && vendors.map((vendor) => (
                                                <option key={vendor.vendorId} value={vendor.vendorId}>
                                                    {vendor.name}
                                                </option>
                                            ))}
                                        </select>
                                    }
                                    {IdExists(localFormData.billId) &&
                                        <EntityAutocomplete name="vendorId" id="vendorId"
                                            onSelect={handleSelectVendor}
                                            onChange={handleChange}
                                            initialEntityId={localFormData.vendorId}
                                            entityType="Vendor"
                                            required
                                        />
                                    }                                    
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Bill Number</span>
                                    </label>
                                    <input type="text" placeholder="" name="billNumber"
                                        value={localFormData.billNumber}
                                        onChange={handleChange}
                                        className="input input-bordered"
                                        required
                                        onBlur={(e) => { billNumberExists(e.target.value) }}
                                    />
                                </div>                                

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Store</span>
                                    </label>
                                    <select
                                        name="storeId"
                                        value={localFormData.storeId}
                                        onChange={handleChange}
                                        className="select select-bordered"
                                        required
                                    >
                                        <option value="">-- Select --</option>
                                        {NotEmpty(stores) && stores.map((store) => (
                                            <option key={store.storeId} value={store.storeId}>
                                                {store.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>                               
                            </div>

                            <div className="grid grid-cols-1 grid-cols-3 gap-4 pt-2">

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Bill Date</span>
                                    </label>
                                    <input
                                        type="date"
                                        name="billDate"
                                        value={localFormData.billDate}
                                        onChange={handleChange}
                                        className="input input-bordered"
                                        required
                                    />
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Due Date</span>
                                    </label>
                                    <input
                                        type="date"
                                        name="dueDate"
                                        value={localFormData.dueDate}
                                        onChange={handleChange}
                                        className="input input-bordered"
                                        required
                                    />
                                </div>
                                                                                                
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Status</span>
                                    </label>
                                    <select name="billStatus"
                                        value={localFormData.billStatus}
                                        onChange={handleChange}
                                        className="select select-bordered"
                                    >
                                        <option value={0}>-- Select --</option>
                                        <option value={1}>Ready for Payment</option>
                                        <option value={2}>Payment Pending</option>
                                        <option value={3}>Paid</option>
                                        <option value={4}>Hold</option>
                                    </select>
                                </div>
                            </div>

                            <div className="grid grid-cols-1 grid-cols-5 gap-5 pt-2">
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Bill Total</span>
                                    </label>
                                    <label className="input-group">
                                        <span>$</span>
                                        <input name="billTotal" type="number" step="0.01" placeholder=""                                            
                                            value={localFormData.billTotal}
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                                const formattedValue = Number(e.target.value).toFixed(2);
                                                setLocalFormData(prev => ({
                                                    ...prev,
                                                    billTotal: formattedValue
                                                }));
                                            }}
                                            className="input input-bordered"
                                            required
                                        />
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Non Discount Amount</span>
                                    </label>
                                    <label className="input-group">
                                        <span>$</span>
                                        <input name="nonDiscAmt" type="number" step="0.01" placeholder=""                                            
                                            value={localFormData.nonDiscAmt}
                                            onChange={handleChange}
                                            className="input input-bordered"
                                            required
                                            onBlur={(e) => {
                                                const formattedValue = Number(e.target.value).toFixed(2);
                                                setLocalFormData(prev => ({
                                                    ...prev,
                                                    nonDiscAmt: formattedValue
                                                }));
                                            }}
                                        />
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Discount Rate</span>
                                    </label>
                                    <label className="input-group">
                                        <input name="discRate" type="number" step="0.01" placeholder=""                                            
                                            value={localFormData.discRate}
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                                const formattedValue = Number(e.target.value).toFixed(2);
                                                setLocalFormData(prev => ({
                                                    ...prev,
                                                    discRate: formattedValue
                                                }));
                                            }}
                                            className="input input-bordered"
                                            required
                                        />
                                        <span>%</span>
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Discount Total</span>
                                    </label>
                                    <label className="input-group">
                                        <span>$</span>
                                        <input name="discAmt" type="number" step="0.01" placeholder=""                                            
                                            value={localFormData.discAmt}
                                            className="input input-bordered"
                                            readOnly
                                        />
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Adjusted Bill Total</span>
                                    </label>
                                    <label className="input-group">
                                        <span>$</span>
                                        <input name="adjustedTotal" type="number" step="0.01" placeholder=""
                                            value={localFormData.adjustedTotal}
                                            className="input input-bordered"
                                            readOnly
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className="mt-8">
                                <h4 className="text-xl font-bold underline float-left">Line Item Details</h4>
                                <div className="float-right">
                                    <button
                                        type="button"
                                        onClick={addNewRow}
                                        className="btn-submit"
                                    >
                                        Add New Line Item
                                    </button>
                                </div>
                            </div>

                            <div className="ag-theme-alpine w-full ag-grid-act clear pt-2" style={{ height: '250px' }}>
                                <AgGridReact
                                    rowData={localFormData.billLineItems || []}
                                    defaultColDef={defaultColDef}
                                    columnDefs={columnDefs}
                                    onGridReady={onGridReady}
                                    onCellValueChanged={onCellValueChanged}
                                    editType="fullRow"
                                    enterMovesDownAfterEdit={true}
                                    stopEditingWhenCellsLoseFocus={false}
                                />
                            </div>
                        </div>

                        <div className="modal-action mt-6">
                            <button type="button" onClick={onHide} className="btn-cancel">Close</button>
                            <button type="submit" className="btn-submit">
                                {isEditMode ? 'Update Bill' : 'Create Bill'}
                            </button>
                        </div>
                    </form>
                </div>                
            </div>
        </div>
    );
}