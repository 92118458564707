import React, { useState, useEffect } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { get, post } from '../../services/apiService';
import { SetDocTitle } from "../_reactHelpers/ReactHelpers";
import { SetPageTitle } from '../../js/helpers.js';
import { GetDateNow, GetDisplayDateStr } from '../../js/formHelpers';

export function DepositRegisterReport() {
    const [stores, setStores] = useState([]);

    const [selectedStore, setSelectedStore] = useState('');
    const [selectedDate, setSelectedDate] = useState(GetDateNow());

    const [report, setReport] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchStores = async () => {
        try {
            const response = await get('store/GetAllStores');
            setStores(response);
        } catch (error) {
            console.error('Error fetching stores:', error);
            setError('Failed to fetch stores');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const response = await post('depositregisters/GetReport', {
                storeId: parseInt(selectedStore),
                date: selectedDate
            });
            setReport(response);
        } catch (error) {
            console.error('Error fetching report:', error);
            setError('Failed to fetch report');
        } finally {
            setLoading(false);
        }
    };

    const columnDefs = [
        {
            headerName: 'Deposit Register', field: 'registerName', flex: 2, hide: true,
        },
        {
            headerName: 'Register Type', field: 'registerType', flex: 2
        },
        {
            headerName: 'Total Collected',
            field: 'totalCollected',
            valueFormatter: params => `$${params.value.toFixed(2)}`,
            cellStyle: { textAlign: 'right' },
            headerClass: 'ag-right-aligned-header',
            flex: 1
        }
    ];

    useEffect(() => {
        SetDocTitle("Lumina - Deposit Register Report");
        SetPageTitle("Deposit Register Report");
        fetchStores();
    }, []);

    return (
        <div className="page-wrapper">
            <div className="max-w-3xl mx-auto"> {/* Center content and limit width */}
                <form onSubmit={handleSubmit} className="mb-4">

                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 items-end">
                        <div>
                            <label htmlFor="store" className="label-form">Store:</label>
                            <select
                                id="store"
                                value={selectedStore}
                                onChange={(e) => setSelectedStore(e.target.value)}
                                className="label-form-input w-full"
                                required
                            >
                                <option value="">Select a store</option>
                                {stores.map((store) => (
                                    <option key={store.storeId} value={store.storeId}>
                                        {store.displayName}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="date" className="label-form">Date:</label>
                            <input
                                type="date"
                                id="date"
                                value={selectedDate}
                                onChange={(e) => setSelectedDate(e.target.value)}
                                className="label-form-input w-full"
                                required
                            />
                        </div>
                        <div>
                            <button type="submit" className="btn-small w-full">
                                Generate Report
                            </button>
                        </div>
                    </div>
                </form>

                {loading && <div className="text-center text-xl mt-[10%]">
                    <span className="pr-1">Loading...</span>
                    <span className="loading loading-spinner text-blue-600"></span>
                </div>}
                {error && <p className="text-red-500">{error}</p>}

                {report && (
                    <div>
                        <h2 className="text-xl font-semibold mb-2">Store {report.storeName}</h2>
                        <p className="mb-4">Date: {GetDisplayDateStr(report.date)}</p>
                        <div className="ag-theme-alpine" style={{ width: '100%' }}>
                            <AgGridReact
                                columnDefs={columnDefs}
                                rowData={report.registerDetails}
                                defaultColDef={{
                                    minWidth: 100,
                                    sortable: true,
                                    resizable: true
                                }}
                                domLayout='autoHeight'
                            />
                        </div>
                        <div className="mt-4 text-right font-bold">
                            Total Deposits: ${report.totalDeposits.toFixed(2)}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}