import React, { Component } from 'react';
import { initFlowbite } from "flowbite";

import { VendorAddEdit } from './VendorAddEdit';

import './_vendorCustom.css';

export class VendorParent extends Component {
    static displayName = VendorParent.name;

    //constructor(props) {
    //    super(props);
    //    this.state = { stores: [] };
    //}

    componentDidMount() {
        initFlowbite();
    }

    render() {
        return (
            <div>
                <VendorAddEdit></VendorAddEdit>
            </div>
        );
    }
}