import React, { useState } from 'react';
import { Component } from 'react';

import { initFlowbite } from "flowbite";

import { ProductAdminGrid } from './ProductAdminGrid';

export class ProductAdminSearch extends Component {
    static displayName = ProductAdminSearch.name;

    constructor() {
        super();
        this.state = { data: [], loading: true };
    }

    componentDidMount() {
        initFlowbite();
    }

    render() {
        return (
            <div className="p-2">
                <ProductAdminGrid></ProductAdminGrid>
            </div>
        );
    }
}
