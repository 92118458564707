import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import { initFlowbite } from "flowbite";
import { Modal, Button } from 'react-daisyui'

import { SetDocumentTitle } from "../_reactHelpers/ReactHelpers";
import { StandardDataAndResponsePOST } from '../../js/dataHelpers';
import {
    SetPageTitle, GetValueById, ClickById, GetCheckedById, StringContains, FormatDateLong, FormatDateStrMMDDYYYY, GetPricingGridDateCellContent
}
from '../../js/helpers.js';
import {
    CheckEnterKeyPress, PreventDefault,
    IsTrue, ParseIntSafe, Exists, IsFalse, IdExists, NotEmpty,
    LengthGreaterEqual, IsEmpty, Timeout, SetFocusById, handleFieldChange,
    defaultErrMsg, NotExists, StringEmpty,
}
from '../../js/formHelpers.js';
import {
    Equals,
}
from '../../js/calculationHelpers';

import "../Products/_productCustom.css";
import { ProductAddEdit } from '../Products/ProductAddEdit';
import { ProductPricingExportGrid } from './ProductPricingExportGrid';
import { SearchFilters } from '../SearchFilters/SearchFilters';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { ModuleRegistry } from '@ag-grid-community/core';
import { MasterDetailModule } from 'ag-grid-enterprise'; //'@ag-grid-enterprise/master-detail';
import { ClearSearchFilters, GetProductPricingEndpoint } from '../../js/searchHelpers';

ModuleRegistry.registerModules([MasterDetailModule]);

export function ProductPricingGrid(props) {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina -");

    const [excelExport, setExcelExport] = useState(IsTrue(props.importExport));
    const exportRef = useRef();

    const propsPageType = "pricing";
    const IsPricingPageType = true;
    const IsAdminPageType = false;
    const IsSalesPageType = false;
    const IsOrderPageType = false;
    const productModalClass = getProductModalClass();

    const minSearchChars = 2;
    const pageRows = 50;
    const rowModelType = 'clientSide'; //'serverSide';

    const [priceType, setPriceType] = useState(IsTrue(props.importExport) ? "ALL" : "PNW"); // default
    
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [selectedProductOptions, setSelectedProductOptions] = useState([]);
    const [addColorSkuDisabled, setAddColorSkuDisabled] = useState(false);

    const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
    const [clearFilterMsg, setClearFilterMsg] = useState("");

    const productEditModalRef = useRef();
    const [productEditModalContent, setProductEditModalContent] = useState(null);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    
    // METHODS

    function getProductModalClass() {

        if (IsPricingPageType) {
            return "product-admin-modal-content";
        }
        else if (IsSalesPageType || IsAdminPageType) {
            return "product-sales-modal-content";
        }
        else if (IsOrderPageType) {
            return "product-sales-modal-content";
        }
    }

    /****** Product grid ******/
    /**************************/
    const [rowData, setRowData] = useState([]);
    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const [gridHeight, setGridHeight] = useState("450px");

    //var filterParams = {
    //    comparator: (filterLocalDateAtMidnight, cellValue) => {
    //        var dateAsString = cellValue;
    //        if (dateAsString == null) return -1;
    //        var dateParts = dateAsString.split("/");
    //        var cellDate = new Date(
    //            Number(dateParts[2]),
    //            Number(dateParts[1]) - 1,
    //            Number(dateParts[0]),
    //        );
    //        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
    //            return 0;
    //        }
    //        if (cellDate < filterLocalDateAtMidnight) {
    //            return -1;
    //        }
    //        if (cellDate > filterLocalDateAtMidnight) {
    //            return 1;
    //        }
    //        return 0;
    //    },
    //};


    const columnDefs = [
        { headerName: 'ProductId', field: 'productId', hide: true, },
        {
            headerName: 'Style', field: 'style', width: 400, //autoHeight: true, cellClass: ["text-wrap"],
            editable: true, 
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px]">{params.data.style}</div>;
            },
        },
        {
            headerName: 'Style #', field: 'styleNum', maxWidth: 180, //autoHeight: true, cellClass: ["text-wrap"],
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px] min-w-[180px]">{params.data.styleNum}</div>;
            },
        },
        {
            headerName: "Inactive Date", field: 'productStyleInfoInactiveDate', maxWidth: 140, editable: true,
            valueFormatter: (params) => { return GetPricingGridDateCellContent(params) },
            cellEditor: 'agDateStringCellEditor',
        },  
        {
            headerName: 'Inactive', field: 'productStyleInfoInactive', cellDataType: 'boolean', editable: true,
            maxWidth: 100, cellClass: ["text-center"]
            //cellRenderer: params => {
            //    return <input type='checkbox' name="cbInactive" defaultChecked={params.value}
            //        className="cursor-pointer" onClick={e => updateProductStyleInfoInactive(e, params)} />;
            //}
        },
        
        /**** PNW Cost ****/
        /******************/        
        {
            headerName: "PNW Cost", field: 'pnwCost', cellDataType: 'number', maxWidth: 120,
            hide: priceType !== "PNW",
            editable: params => params.data.pnwProductPriceId !== 0,
            cellRenderer: params => {
                var editable = params.data.pnwProductPriceId !== 0;
                return <div>
                    {IsTrue(editable) && <div>
                        <span>$</span>{params.value.toFixed(2)}</div>}
                    {IsFalse(editable) && <div>-</div>}
                </div>
            }
        },
        //{
        //    headerName: "Fut. Cost", field: 'pnwFutureCost', cellDataType: 'number', maxWidth: 110,
        //    hide: priceType !== "PNW",
        //    editable: params => params.data.pnwProductPriceId !== 0,
        //    cellRenderer: params => {
        //        var editable = params.data.pnwProductPriceId !== 0;
        //        return <div>
        //            {IsTrue(editable) && <div>
        //                <span>$</span>{params.value.toFixed(2)}</div>}
        //            {IsFalse(editable) && <div>-</div>}
        //        </div>
        //    }
        //},
        {
            headerName: "Eff. Date", field: 'pnwFutureStartDate', maxWidth: 130, 
            hide: priceType !== "PNW",            
            editable: params => params.data.pnwProductPriceId !== 0,
            valueFormatter: (params) => { return GetPricingGridDateCellContent(params) },
            cellEditor: 'agDateStringCellEditor',
        },
        {
            headerName: "Promo Cost", field: 'pnwPromoCost', cellDataType: 'number', maxWidth: 130,
            hide: priceType !== "PNW",            
            editable: params => params.data.pnwProductPriceId !== 0,
            cellRenderer: params => {
                var editable = params.data.pnwProductPriceId !== 0;
                return <div>
                    {IsTrue(editable) && <div>
                        <span>$</span>{params.value.toFixed(2)}</div>}
                    {IsFalse(editable) && <div>-</div>}
                </div>
            }
        },
        {
            headerName: "Promo Start", field: 'pnwPromoStartDate', maxWidth: 130,
            hide: priceType !== "PNW", 
            //filter: "agDateColumnFilter", filterParams: filterParams,
            editable: params => params.data.pnwProductPriceId !== 0,
            valueFormatter: (params) => { return GetPricingGridDateCellContent(params) },
            cellEditor: 'agDateStringCellEditor',
        },
        {
            headerName: "Promo End", field: 'pnwPromoEndDate', maxWidth: 120,
            hide: priceType !== "PNW",
            editable: params => params.data.pnwProductPriceId !== 0,
            valueFormatter: (params) => { return GetPricingGridDateCellContent(params) },
            cellEditor: 'agDateStringCellEditor',
        },

        /**** AZ Cost ****/
        /******************/        
        {
            headerName: "AZ Cost", field: 'azCost', cellDataType: 'number', maxWidth: 100,
            hide: priceType !== "AZ",
            editable: params => params.data.azProductPriceId !== 0,
            cellRenderer: params => {
                var editable = params.data.azProductPriceId !== 0;
                return <div>
                    {IsTrue(editable) && <div>
                        <span>$</span>{params.value.toFixed(2)}
                    </div>}
                    {IsFalse(editable) && <div>-</div>}
                </div>
            }
        },
        //{
        //    headerName: "Fut. Cost", field: 'azFutureCost', cellDataType: 'number', maxWidth: 110,
        //    hide: priceType !== "AZ",
        //    editable: params => params.data.azProductPriceId !== 0,
        //    cellRenderer: params => {
        //        var editable = params.data.azProductPriceId !== 0;
        //        return <div>
        //            {IsTrue(editable) && <div>
        //                <span>$</span>{params.value.toFixed(2)}</div>}
        //            {IsFalse(editable) && <div>-</div>}
        //        </div>
        //    }
        //},
        {
            headerName: "Eff. Date", field: 'azFutureStartDate', maxWidth: 130,
            hide: priceType !== "AZ",
            editable: params => params.data.azProductPriceId !== 0,
            valueFormatter: (params) => { return GetPricingGridDateCellContent(params) },
            cellEditor: 'agDateStringCellEditor',
        },
        {
            headerName: "Promo Cost", field: 'azPromoCost', cellDataType: 'number', maxWidth: 130,
            hide: priceType !== "AZ",
            editable: params => params.data.azProductPriceId !== 0,
            cellRenderer: params => {
                var editable = params.data.azProductPriceId !== 0;
                return <div>
                    {IsTrue(editable) && <div>
                        <span>$</span>{params.value.toFixed(2)}</div>}
                    {IsFalse(editable) && <div>-</div>}
                </div>
            }
        },
        {
            headerName: "Promo Start", field: 'azPromoStartDate', maxWidth: 130,
            hide: priceType !== "AZ",
            editable: params => params.data.azProductPriceId !== 0,
            valueFormatter: (params) => { return GetPricingGridDateCellContent(params) },
            cellEditor: 'agDateStringCellEditor',
        },
        {
            headerName: "Promo End", field: 'azPromoEndDate', maxWidth: 120,
            hide: priceType !== "AZ",
            editable: params => params.data.azProductPriceId !== 0,
            valueFormatter: (params) => { return GetPricingGridDateCellContent(params) },
            cellEditor: 'agDateStringCellEditor',
        },

        /**** WEB Cost ****/
        /******************/        
        {
            headerName: "Web Cost", field: 'webCost', cellDataType: 'number', maxWidth: 120,
            hide: priceType !== "WEB",
            editable: params => params.data.webProductPriceId !== 0,
            cellRenderer: params => {
                var editable = params.data.webProductPriceId !== 0;
                return <div>
                    {IsTrue(editable) && <div>
                        <span>$</span>{params.value.toFixed(2)}
                    </div>}
                    {IsFalse(editable) && <div>-</div>}
                </div>
            }
        },
        //{
        //    headerName: "Fut. Cost", field: 'webFutureCost', cellDataType: 'number', maxWidth: 110,
        //    hide: priceType !== "WEB",
        //    editable: params => params.data.webProductPriceId !== 0,
        //    cellRenderer: params => {
        //        var editable = params.data.webProductPriceId !== 0;
        //        return <div>
        //            {IsTrue(editable) && <div>
        //                <span>$</span>{params.value.toFixed(2)}</div>}
        //            {IsFalse(editable) && <div>-</div>}
        //        </div>
        //    }
        //},
        {
            headerName: "Eff. Date", field: 'webFutureStartDate', maxWidth: 130,
            hide: priceType !== "WEB",
            editable: params => params.data.azProductPriceId !== 0,
            valueFormatter: (params) => { return GetPricingGridDateCellContent(params) },
            cellEditor: 'agDateStringCellEditor',
        },
        {
            headerName: "Promo Cost", field: 'webPromoCost', cellDataType: 'number', maxWidth: 130,
            hide: priceType !== "WEB",
            editable: params => params.data.azProductPriceId !== 0,
            cellRenderer: params => {
                var editable = params.data.azProductPriceId !== 0;
                return <div>
                    {IsTrue(editable) && <div>
                        <span>$</span>{params.value.toFixed(2)}</div>}
                    {IsFalse(editable) && <div>-</div>}
                </div>
            }
        },
        {
            headerName: "Promo Start", field: 'webPromoStartDate', maxWidth: 130,
            hide: priceType !== "WEB",
            editable: params => params.data.azProductPriceId !== 0,
            valueFormatter: (params) => { return GetPricingGridDateCellContent(params) },
            cellEditor: 'agDateStringCellEditor',
        },
        {
            headerName: "Promo End", field: 'webPromoEndDate', maxWidth: 120,
            hide: priceType !== "WEB",
            editable: params => params.data.azProductPriceId !== 0,
            valueFormatter: (params) => { return GetPricingGridDateCellContent(params) },
            cellEditor: 'agDateStringCellEditor',
        },

        /******************/
        {
            headerName: "PC", field: 'productCode', maxWidth: 80,
            editable: true,
            //valueFormatter: params => params.data.productCode,
        },
        //{
        //    headerName: "Category", field: 'categoryName', maxWidth: 200,
        //},
        {
            headerName: "Box Qty", field: 'sellQuantity', cellDataType: 'number', maxWidth: 110,
            editable: true,
            //valueFormatter: params => params.data.sellQuantity,
        },
        {
            headerName: "Units", field: 'units', maxWidth: 90,
            //cellRenderer: params => {
            //    return <div>{params.data.units}
            //        {/*<span className="px-1">/</span>{params.data.sellUnit}*/}
            //    </div>
            //},
        },
        //{
        //    headerName: "Units", field: 'units', maxWidth: 80,
        //},
        //{
        //    headerName: "Buy Unit", field: 'sellUnit', maxWidth: 100,
        //},
        {
            headerName: 'Vendor', field: 'vendorName', width: 220,
            //autoHeight: true, cellClass: ["text-wrap"],
            //cellRenderer: params => {
            //    return <div className="leading-[20px] pt-[11px] min-w-[250px]">{params.data.vendorName}</div>;
            //},
        },
        {
            headerName: 'Manufacturer', field: 'manufacturerName', width: 220,            
            //autoHeight: true, cellClass: ["text-wrap"],
            //cellRenderer: params => {
            //    return <div className="leading-[20px] pt-[11px] min-w-[250px]">{params.data.manufacturerName}</div>;
            //},
        },
        {
            headerName: '', field: 'priceTypeFilter', maxWidth: 80,
            cellRenderer: params => {
                var cssClass = StringContains(params.data.rowMessage, "Saved") ? " text-green " : " text-red ";
                return <div className={"leading-[20px] pt-[11px] min-w-[80px] " + cssClass}>
                    {params.data.rowMessage}
                    <span className="hidden">{params.value}</span>
                </div>;
            },
        },
    ];

    const gridOptions = {
        defaultColDef: {            
            minWidth:100,
            //flex: 1,
            editable: false,
            sortable: true,
            resizable: true,
            filter: true,
            suppressMovable: true,
            suppressMenu: false,
            cellClass: ["no-border"],
            menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab",],            
        },        
        sideBar: {
            toolPanels: [                
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                }
            ],            
            hiddenByDefault: false,
        },
    };

    //const dataTypeDefinitions = useMemo(() => {
    //    return {
    //        // override `date` to handle custom date format `dd/mm/yyyy`
    //        date: {
    //            baseDataType: 'date',
    //            extendsDataType: 'date',
    //            valueParser: params => {
    //                if (params.newValue == null) {
    //                    return null;
    //                }
    //                // convert from `dd/mm/yyyy`
    //                const dateParts = params.newValue.split('/');
    //                return dateParts.length === 3 ? new Date(
    //                    parseInt(dateParts[2]),
    //                    parseInt(dateParts[1]) - 1,
    //                    parseInt(dateParts[0])
    //                ) : null;
    //            },
    //            valueFormatter: params => {
    //                // convert to `dd/mm/yyyy`
    //                return params.value == null
    //                    ? ''
    //                    : `${new Date(params.value).getDate()}/${new Date(params.value).getMonth() + 1}/${new Date(params.value).getFullYear()}`;
    //            },
    //        }
    //    };
    //}, []);

    const clearProdFilters = useCallback(() => {
        gridRef.current.api.setFilterModel(null);
    }, []);

    //async function updateProductStyleInfoInactive(e, params) {
    //    var rowIndex = params.rowIndex;
    //    var data = params.data;

    //    if (Exists(e) && Exists(data)) {
    //        var cbData = handleFieldChange(e);
    //        if (cbData) {
    //            updateProductStyleInfoInactiveDeleted(rowIndex, data.productStyleInfoId, cbData.value, null);
    //        }
    //    }
    //}

    //async function updateProductStyleInfoInactiveDeleted(rowIndex, productStyleInfoId, inactive, deleted) {
    //    var success = false;

    //    var model = {
    //        productStyleInfoId: productStyleInfoId,
    //        // productSku
    //    }

    //    if (Exists(inactive)) {
    //        model.productSkuInactive = inactive;
    //        model.updateColorSkuInactive = true;
    //    }
    //    else if (Exists(deleted)) {
    //        model.productSkuDeleted = deleted;
    //        model.UpdatColorSkuDeleted = true;
    //    }

    //    var resp = await UpdateProductColorAdminPOST(model);
    //    var iProductId = ParseIntSafe(resp);
    //    if (IdExists(iProductId)) {
    //        success = true;
    //    }

    //    // remove Color row if set Inactive or Deleted
    //    if (IsTrue(success)) {

    //        var showInactive = GetCheckedById("filterInactive") ?? false;
    //        if ((IsTrue(inactive) && IsFalse(showInactive)) || IsTrue(deleted)) {

    //            const rn = gridRefOpts.current.api.getRowNode(rowIndex);
    //            if (Exists(rn)) {

    //                var rowData = [];
    //                gridRefOpts.current.api.forEachNode((rowNode) => {
    //                    rowData.push(rowNode.data);
    //                });

    //                if (NotEmpty(rowData)) {
    //                    rowData.splice(rowIndex, 1);
    //                    gridRefOpts.current.api.setRowData(rowData);
    //                }
    //            }
    //        }
    //    }
    //    else {
    //        alert(defaultErrMsg());
    //    }
    //}

    const onProductPriceCellKeyDown = async (e) => {

        // save row data on Enter key
        if (Exists(e) && Exists(e.event) && e.event.key === "Enter") {
            var success = false;
            var data = e.data;

            if (Exists(data)) {
                var model = {
                    // product
                    productId: data.productId,
                    style: data.style,
                    productCode: data.productCode,
                    sellQuantity: data.sellQuantity,
                    // product stytle info
                    productStyleInfoId: data.productStyleInfoId,
                    productStyleInfoInactive: data.productStyleInfoInactive,
                    productStyleInfoInactiveDate: data.productStyleInfoInactiveDate,
                }

                if (Equals(priceType, "PNW")) {
                    // pnwCost
                    model.pnwProductPriceId = data.pnwProductPriceId;
                    model.pnwCost = data.pnwCost;
                    model.pnwFutureCost = data.pnwFutureCost;
                    model.pnwPromoCost = data.pnwPromoCost;
                    model.pnwFutureStartDate = data.pnwFutureStartDate;
                    model.pnwPromoStartDate = data.pnwPromoStartDate;
                    model.pnwPromoEndDate = data.pnwPromoEndDate;
                }
                else if (Equals(priceType, "AZ")) {
                    // pnwCost
                    model.azProductPriceId = data.azProductPriceId;
                    model.azCost = data.azCost;
                    model.azFutureCost = data.azFutureCost;
                    model.azPromoCost = data.azPromoCost;
                    model.azFutureStartDate = data.azFutureStartDate;
                    model.azPromoStartDate = data.azPromoStartDate;
                    model.azPromoEndDate = data.azPromoEndDate;
                }
                else if (Equals(priceType, "WEB")) {
                    // pnwCost
                    model.webProductPriceId = data.webProductPriceId;
                    model.webCost = data.webCost;
                    model.webFutureCost = data.webFutureCost;
                    model.webPromoCost = data.webPromoCost;
                    model.webFutureStartDate = data.webFutureStartDate;
                    model.webPromoStartDate = data.webPromoStartDate;
                    model.webPromoEndDate = data.webPromoEndDate;
                }

                var resp = await UpdateProductPricesAdminPOST(model);
                var iProductId = ParseIntSafe(resp);
                if (IdExists(iProductId)) {
                    success = true;
                }
            }

            if (IsTrue(success)) {
                var colId = "";
                if (Exists(e.column)) {
                    colId = e.column.colId;
                }
                updateProductPriceRows(model, e.rowIndex, colId);
            }
            else {
                alert(defaultErrMsg());
            }
        }
    }

    async function UpdateProductPricesAdminPOST(model) {
        var response = null;
        var postModel = JSON.stringify(model);
        var endpoint = "product/UpdateProductPricesAdmin";
        var typeStr = "UpdateProductPricesAdminPOST()";

        response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);
        return response;
    }

    function updateProductPriceRows(model, rowIndex, colId) {

        var nextRowIndex = null;
        var iRowIndex = ParseIntSafe(rowIndex);
        if (Exists(iRowIndex)) {
            nextRowIndex = iRowIndex + 1;
        }

        gridRef.current.api.forEachNode((rowNode) => {
            var updateRow = false;

            if (IsTrue(error) && Equals(rowNode.rowIndex, rowIndex)) {
                updateRow = true;
                rowNode.data.rowMessage = "Error";
            }
            else {
                if (Equals(rowNode.data.productId, model.productId)) {
                    updateRow = true;
                    rowNode.data.style = model.style;
                    rowNode.data.sellQuantity = model.sellQuantity;
                    rowNode.data.productCode = model.productCode;

                    if (Equals(rowNode.rowIndex, iRowIndex)) {
                        rowNode.data.rowMessage = "Saved";
                    }
                }
            }

            if (IsTrue(updateRow)) {
                refreshRow(rowNode);
            }
        });

        EditNextRow(nextRowIndex, colId);
    }

    function refreshRow(node) {
        var rowNodes = [node]; // params needs an array
        var params = {
            force: false,
            suppressFlash: true,
            rowNodes: rowNodes,
        };
        gridRef.current.api.refreshCells(params);
    }


    function EditNextRow(rowIndex, colId) {
        if (IsEmpty(colId)) {
            colId = "style";
        }
        gridRef.current.api.setFocusedCell(rowIndex, colId);
        gridRef.current.api.startEditingCell({
            rowIndex: rowIndex,
            colKey: colId
        });
    }

    const onCellFocusedProduct = async (e) => {
        // select row on 'style' column focus for tabbing
        if (Exists(e.column) && e.column.colId === 'style') {
            var rowNode = gridRef.current.api.getDisplayedRowAtIndex(e.rowIndex);
            if (Exists(rowNode)) {
                SetSelectedProduct(rowNode, rowNode.data);
            }
        }
    }

    const handleOpenFiltersModal = () => setIsFiltersModalOpen(true);
    function handleCloseFiltersModal(e, loadData) {
        PreventDefault(e);
        setIsFiltersModalOpen(false);

        if (IsTrue(loadData)) {
            //refreshCache();
            loadPriceData();
        }
    }

    function handleCloseFiltersExportModal(e, loadData) {
        PreventDefault(e);
        setIsFiltersModalOpen(false);

        if (IsTrue(loadData)) {
            //refreshCache();
            ClickById("btnExportProductsSearchGrid");
        }
    }

    function clearFilters(e) {
        PreventDefault(e);
        ClearSearchFilters();
        clearProdFilters();
        updatePriceTypeAndFilter(priceType);
        setClearFilterMsg("Filters cleared");        

        // dont clear search str on filter clear
        //setSearchStr("");
    }

    const onGridReady = useCallback((params) => {
        loadPriceData();
        //params.api.setServerSideDatasource(serverSideDatasource());        
    }, []);

    function loadPriceData() {
        var searchStr = GetValueById("tbSearch");
        if (LengthGreaterEqual(searchStr, minSearchChars)) {
            showLoading();            
            clearProdFilters();

            setSelectedProduct([]);
            setSelectedProductOptions([]);
            setProductOptionsData([]);

            // TODO: return message if limit of 10k reached by search?
            // return all found records                 
            var apiPageRows = 10000;
            var page = 0;

            //var apiPageRows = 50;
            //var pageNum = params.request.endRow / pageRows;
            //var page = pageNum - 1;

            var endpoint = GetProductPricingEndpoint(searchStr, apiPageRows, page, false)
            var apiUrl = process.env.REACT_APP_API_URL + endpoint;
            console.log("server side apiUrl: " + apiUrl);

            fetch(apiUrl)
                .then((response) => response.json())
                .then((rowData) => {
                    if (Exists(rowData) && NotEmpty(rowData.dataRows)) {                                                
                        //console.log("total Product rows: " + dataRows.length);
                        setRowData(rowData.dataRows);
                        setTimeout(() => {
                            selectFirstProductRow(page);
                            updatePriceTypeAndFilter(priceType);
                            hideOverlay();
                        }, 200);                        
                    }
                    else {
                        setRowData([]);
                        console.log("total Product rows: no data or end of data");
                    }
                })
                .catch((error) => {
                    setRowData([]);
                    //showNoRows();
                    setError(error);
                    console.error('loadProductsData Error:', error);
                });
        }
        else {
            setRowData([]);
            //showNoRows();
            console.log("total Product rows: load none");
        }
    }

    //const serverSideDatasource = () => {
    //    return {
    //        // called by the grid when more rows are required
    //        getRows: params => {

    //            var searchStr = GetValueById("tbSearch");
    //            if (LengthGreaterEqual(searchStr, minSearchChars)) {

    //                var apiPageRows = 50;
    //                var pageNum = params.request.endRow / pageRows;
    //                var page = pageNum - 1;

    //                var endpoint = GetProductPricingEndpoint(searchStr, apiPageRows, page)
    //                var apiUrl = process.env.REACT_APP_API_URL + endpoint;
    //                console.log("server side apiUrl: " + apiUrl);

    //                fetch(apiUrl)
    //                    .then((response) => response.json())
    //                    .then((rowData) => {
    //                        if (Exists(rowData) && NotEmpty(rowData.dataRows)) {

    //                            params.success({
    //                                rowData: rowData.dataRows
    //                            });
    //                            selectFirstProductRow(page);
    //                            hideOverlay();
    //                            //console.log("total Product rows: " + dataRows.length);
    //                        }
    //                        else {
    //                            params.success({ rowData: [] });
    //                            console.log("total Product rows: no data or end of data");
    //                        }
    //                    })
    //                    .catch((error) => {
    //                        params.success({ rowData: [] });
    //                        showNoRows();
    //                        setError(error);
    //                        console.error('loadProductsData Error:', error);
    //                    });
    //            }
    //            else {
    //                params.success({ rowData: [] });
    //                showNoRows();
    //                console.log("total Product rows: load none");
    //            }
    //        }
    //    }
    //}

    //const refreshCache = useCallback((e) => {
    //    var searchTerms = GetValueById("tbSearch");
    //    if (searchTerms && searchTerms.length >= minSearchChars) {

    //        setSelectedProduct([]);
    //        setSelectedProductOptions([]);
    //        setProductOptionsData([]);
    //        gridRef.current.api.refreshServerSide({ purge: true });
    //    }
    //}, []);

    function onRowDoubleClicked(data) {
        if (data) {
            var productId = data.productId;
            onOpenProductEditModal(productId, 0);
        }
    }

    const cellClickedProduct = useCallback((params) => {
        SetSelectedProduct(params.node, params.data);

        if (Exists(params.node) && Exists(params.data) && Exists(params.colDef)) {
            var editable = Exists(params.colDef.editable);
            if (IsFalse(editable)) {
                var productId = params.data.productId;
                onOpenProductEditModal(productId, 0);
            }
        }
    }, []);

    function selectFirstProductRow(page) {
        //autoselect first result after search
        setSelectedProduct([]);
        setSelectedProductOptions([]);
        setProductOptionsData([]);

        if (page === 0) {
            var nodes = gridRef.current.api.getRenderedNodes();
            SetSelectedProduct(nodes[0], nodes[0] && nodes[0].data);
        }
    }

    function SetSelectedProduct(node, data) {
        if (Exists(node) && Exists(data)) {
            node.setSelected(true);
            setSelectedProduct(data);
            //options

            if (IdExists(data.productStyleInfoId)) {
                setAddColorSkuDisabled(false);
            }
            else {
                setAddColorSkuDisabled(true);
            }

            setProductOptionsData(data.productSkus);
            //loadProductOptions(data.productId);
        } else {
            setSelectedProduct([]);
            setSelectedProductOptions([]);
        }
    }

    function showLoading() {
        gridRef.current.api.showLoadingOverlay();
    }
    function showNoRows() {
        gridRef.current.api.showNoRowsOverlay();
    }
    function hideOverlay() {
        gridRef.current.api.hideOverlay();
    }


    /****** Color grid ******/
    /************************/

    const gridRefOpts = useRef();
    const [productOptionsData, setProductOptionsData] = useState([]);

    //const containerStyle2 = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const [gridHeight2, setGridHeight2] = useState("300px");
    const columnDefsOpt = [ 
        { headerName: "ProductSkuId", field: 'productSkuId', hide: true },        
        {
            headerName: "Color", field: 'color', resizable: true, maxWidth: 300,
            editable: true
        },
        {
            headerName: "Color #", field: 'colorNum', resizable: true, maxWidth: 200,
            editable: true
        },
        //{
        //    headerName: "Sku", field: 'sku', resizable: true, maxWidth: 200,
        //    editable: true
        //},
        //{
        //    headerName: 'Style #', field: 'styleNum', maxWidth: 150,
        //},
        {
            headerName: "Inactive Date", field: 'productSkuInactiveDate', maxWidth: 140, editable: true,
            valueFormatter: (params) => { return GetPricingGridDateCellContent(params) },
            cellEditor: 'agDateStringCellEditor',
        },
        {
            headerName: 'Inactive', field: 'productSkuInactive', cellDataType: 'boolean', maxWidth: 90,
            cellClass: ["text-center"],
            cellRenderer: params => {
                return <input type='checkbox' name="cbInactive" defaultChecked={params.value}
                    className="cursor-pointer" onClick={e => updateColorInactive(e, params)} />;
            }
        },        
        {
            headerName: 'Deleted', field: 'productSkuDeleted', cellDataType: 'boolean',
            maxWidth: 90, cellClass: ["text-center"],
            cellRenderer: params => {
                return <input type='checkbox' name="cbDeleted" defaultChecked={params.value}
                    className="cursor-pointer" onClick={e => updateColorDeleted(e, params)} />;
            }
        },
        {
            headerName: '', field: 'rowMessage', width: 150, cellClass: ["text-green"],
        },
    ];

    const gridOptions2 = {
        defaultColDef: {
            flex: 1,
            sortable: false,
            resizable: true,
            filter: false,
            suppressMenu: true,
            suppressMovable: true,
            cellClass: ["no-border"]
        }
    };

    async function updateColorInactive(e, params) {
        var rowIndex = params.rowIndex;
        var data = params.data;

        if (Exists(e) && Exists(data)) {
            var cbData = handleFieldChange(e);
            if (cbData) {
                updateColorSkuInactiveDeleted(rowIndex, data.productStyleInfoId, data.productSkuId, cbData.value, null);

            }            
        }
    }

    async function updateColorDeleted(e, params) {
        var rowIndex = params.rowIndex;
        var data = params.data;

        if (Exists(e) && Exists(data)) {
            var cbData = handleFieldChange(e);
            if (cbData) {
                updateColorSkuInactiveDeleted(rowIndex, data.productStyleInfoId, data.productSkuId, null, cbData.value);
            }
        }
    }

    async function updateColorSkuInactiveDeleted(rowIndex, productStyleInfoId, productSkuId, inactive, deleted) {
        var success = false;

        var model = {
            productStyleInfoId: productStyleInfoId,
            // productSku
            productSkuId: productSkuId,            
        }

        if (Exists(inactive)) {
            model.productSkuInactive = inactive;
            model.updateColorSkuInactive = true;
        }
        else if (Exists(deleted)) {
            model.productSkuDeleted = deleted;
            model.UpdatColorSkuDeleted = true;
        }

        var resp = await UpdateProductColorAdminPOST(model);
        var iProductId = ParseIntSafe(resp);
        if (IdExists(iProductId)) {
            success = true;
        }

        // remove Color row if set Inactive or Deleted
        if (IsTrue(success)) {

            var showInactive = GetCheckedById("filterInactive") ?? false;
            if ((IsTrue(inactive) && IsFalse(showInactive)) || IsTrue(deleted)) {

                const rn = gridRefOpts.current.api.getRowNode(rowIndex);
                if (Exists(rn)) {     
                    
                    var rowData = [];
                    gridRefOpts.current.api.forEachNode((rowNode) => {
                        rowData.push(rowNode.data);
                    });

                    if (NotEmpty(rowData)) {
                        rowData.splice(rowIndex, 1);
                        gridRefOpts.current.api.setRowData(rowData);
                    }
                }
            }
        }
        else {
            alert(defaultErrMsg());
        }
    }

    const onColorCellKeyDown = async (e) => {
        var success = false;
        var iProductSkuId = 0;

        // save row data on Enter key
        if (Exists(e) && Exists(e.event) && e.event.key === "Enter") {

            var data = e.data;
            if (Exists(data)) {
                var model = {
                    // productSku
                    productSkuId: data.productSkuId,                    
                    color: data.color,
                    colorNum: data.colorNum,
                    sku: data.sku,
                    productSkuInactive: data.productSkuInactive,
                    productSkuInactiveDate: data.productSkuInactiveDate,                    
                    productSkuDeleted: data.productSkuDeleted,

                    // new ColorSku fields
                    productStyleInfoId: data.productStyleInfoId,
                    //isPnw: data.isPnw,
                    //isAz: data.isAz,
                    //isWeb: data.isWeb,
                }
                var resp = await UpdateProductColorAdminPOST(model);
                iProductSkuId = ParseIntSafe(resp);
                if (IdExists(iProductSkuId)) {
                    success = true;
                }
            }

            if (IsTrue(success)) {

                var colId = "";
                if (Exists(e.column)) {
                    colId = e.column.colId;
                }
                updateColorRows(e.rowIndex, colId, iProductSkuId);
            }
            else {
                alert(defaultErrMsg());
            }
        }
    }

    async function UpdateProductColorAdminPOST(model) {
        var response = null;
        var postModel = JSON.stringify(model);
        var endpoint = "product/UpdateProductSkuColorAdmin";
        var typeStr = "UpdateProductColorAdminPOST()";

        response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);
        return response;
    }

    function updateColorRows(rowIndex, colId, productSkuId) {

        var nextRowIndex = null;
        var iRowIndex = ParseIntSafe(rowIndex);
        if (Exists(iRowIndex)) {
            nextRowIndex = iRowIndex + 1;
        }

        gridRefOpts.current.api.forEachNode((rowNode) => {

            if (Equals(rowNode.rowIndex, iRowIndex)) {
                var msg = "";
                if (IsTrue(error)) {
                    msg = "Error";
                }
                else {
                    msg = "Saved";
                }
                rowNode.setDataValue("productSkuId", productSkuId);
                rowNode.setDataValue("rowMessage", msg);
            }
        });

        EditNextColorRow(nextRowIndex, colId);
    }

    function EditNextColorRow(rowIndex, colId) {
        if (IsEmpty(colId)) {
            colId = "color";
        }        

        gridRefOpts.current.api.startEditingCell({
            rowIndex: rowIndex,
            colKey: colId
        });        
    }


    // Add/Edit Modal

    function returnProductAddEdit(productId, productPriceId) {
        return <ProductAddEdit
            pageType={propsPageType} productId={productId} productPriceId={productPriceId}
            isPricingPageType={IsPricingPageType} isAdminPageType={IsAdminPageType}
            isSalesPageType={IsSalesPageType} isOrderPageType={IsOrderPageType}
            onCloseModal={onCloseProductEditModal}>
        </ProductAddEdit>;
    }

    function onOpenProductEditModal(productId, productPriceId) {
        setProductEditModalContent(returnProductAddEdit(productId, productPriceId));
        productEditModalShow();
    }

    function onCloseProductEditModal(msg) {
        //PreventDefault(e);
        updateMessage(msg, "text-green");
        setProductEditModalContent(null);
        productEditModalHide();
    }

    const productEditModalShow = useCallback(() => {
        productEditModalRef.current?.showModal();
    }, [productEditModalRef]);

    const productEditModalHide = useCallback(() => {
        productEditModalRef.current?.close();
    }, [productEditModalRef]);


    function handleSearchOpts(e) {
        var api = gridRefOpts.current.api;
        const value = e.target.value.toLowerCase();
        api.setQuickFilter(value);
    };

    //const cellClickedColor = useCallback((params) => {
    //    SetSelectedProductOptions(params.node, params.data);
    //}, []);

    //function selectFirstColorRow() {
    //    //autoselect first result after search
    //    var nodesOpts = gridRefOpts.current.api.getRenderedNodes();
    //    SetSelectedProductOptions(nodesOpts[0], nodesOpts[0] && nodesOpts[0].data);
    //}

    //function SetSelectedProductOptions(node, data) {

    //    if (node && data) {
    //        //productId = data.productId;
    //        //productPriceId = data.productPriceId;
    //        node.setSelected(true);
    //        setSelectedProductOptions(data.productSkus);
    //        gridRefOpts.current.api.refreshServerSide({ purge: true });
    //    } else {
    //        setSelectedProductOptions([]);
    //    }
    //}

    //function onOptsRowDoubleClicked(data) {
    //    if (data) {
    //        var productId = data.productId;
    //        var productPriceId = data.productPriceId;
    //        onOpenProductEditModal(productId, productPriceId, productOptionsData);
    //    }
    //}

    function btnAddColorSkuClick() {

        //newColorSku.productStyleInfoId = selectedProduct.productStyleInfoId;
        //newColorSku.styleNum = selectedProduct.styleNum;
        //newColorSku.productSkuId = 0;
        //newColorSku.color = "";
        //newColorSku.sku = "";
        //newColorSku.productSkuInactive = false;
        //newColorSku.rowMessage = "";

        //// TODO: SET THESE HERE?
        //newColorSku.isPnw = selectedProduct.isPnw;
        //newColorSku.isAz = selectedProduct.isAz;
        //newColorSku.isWeb = selectedProduct.isWeb;

        var newColorSku = {};
        newColorSku.productStyleInfoId = selectedProduct.productStyleInfoId;
        newColorSku.styleNum = selectedProduct.styleNum;
        newColorSku.productSkuId = 0;
        newColorSku.color = "";
        newColorSku.colorNum = "";
        newColorSku.sku = "";
        newColorSku.productSkuInactive = false;
        newColorSku.productSkuDeleted = false;
        newColorSku.rowMessage = "";

        var data = productOptionsData;
        data.splice(0, 0, newColorSku);

        setProductOptionsData(data);
        gridRefOpts.current.api.setRowData(data);

        Timeout(200, EditNextColorRow(1, "color"));
    }

    function EnterSearchProducts() {
        if (IsFalse(excelExport)) {
            //refreshCache(null);
            loadPriceData();
        }
        else {
            ClickById("btnExportProductsSearchGrid");
        }
    }

    function getExportData(e, loadData) {
        PreventDefault(e);
        //setExportGridContent(returnExportGridComponent);

        if (IsTrue(loadData)) {
            ClickById("btnExportProductsSearchGrid");
        }
    }

    //function onChangeExcelExport(e) {
    //    //PreventDefault(e);

    //    var checked = false;
    //    if (Exists(e.target) && Exists(e.target.checked)) {
    //        checked = IsTrue(e.target.checked);
    //    }
    //    else {
    //        alert(defaultErrMsg());
    //    }
    //    setExcelExport(checked);
    //}

    function onChangePriceType(e) {
        if (Exists(e.target) && Exists(e.target.value)) {
            updatePriceTypeAndFilter(e.target.value);
        }
    }

    function updatePriceTypeAndFilter(priceTypeVal) {
        if (Exists(priceTypeVal) && Exists(priceTypeVal)) {
            setPriceType(priceTypeVal);

            var filterValue = "";
            if (priceTypeVal === "PNW") {
                filterValue = "filterPnwPrice";

                if (IsTrue(excelExport)) {
                    ClickById("btnUpdateExportFilterPnw");
                }
            }
            else if (priceTypeVal === "AZ") {
                filterValue = "filterAzPrice";

                if (IsTrue(excelExport)) {
                    ClickById("btnUpdateExportFilterAz");
                }
            }
            else if (priceTypeVal === "WEB") {
                filterValue = "filterWebPrice";

                if (IsTrue(excelExport)) {
                    ClickById("btnUpdateExportFilterWeb");
                }
            }
            else {
                if (IsTrue(excelExport)) {
                    ClickById("btnUpdateExportFilterAll");
                }
            }

            if (IsFalse(excelExport)) {
                gridRef.current.api.setQuickFilter(filterValue);
                selectFirstProductRow(0);
            }
        }
        else {
            alert(defaultErrMsg());
        }
    }

    function updateMessage(msg, cssClass, errorMsg, logMsg) {
        if (errorMsg) {
            console.error(errorMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setMessage(msg);
        setMessageClass(cssClass);
    }

    /** Page Load **/
    useEffect(() => {
        if (IsFalse(excelExport)) {
            setDocTitle("Lumina - Product Pricing Admin")
            SetPageTitle("Product Pricing Admin");
        }
        else {
            setDocTitle("Lumina - Product Import/Export");
            SetPageTitle("Product Import / Export");
        }

        initFlowbite();
        setLoading(false);
    }, []);

    if (loading === true) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }
    if (error === true)
        return <div className="text-red-500 text-center mt-[25%] text-xl">{defaultErrMsg()}</div>;

    return (
        <div className="w-100">
            <div>
                <div>
                    <div className="float-left">
                        <div className="table-cell">
                            {IsFalse(excelExport) &&
                                <div className="table-cell pr-2">
                                    <button id="btnAddProduct" onClick={(e) => onOpenProductEditModal(0, 0)}
                                        type="button" className="btn-search !m-0">
                                        Add Product
                                    </button>
                                </div>
                            }
                            <div className="table-cell">
                                <label for="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                        </svg>
                                    </div>
                                    <input autoFocus type="text" id="tbSearch" placeholder="Search"
                                        onKeyDown={(e) => CheckEnterKeyPress(e, EnterSearchProducts)}
                                        className="orders-filter-search block p-2 pl-10 text-sm text-gray-900 
                                             border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500;"></input>
                                    <div id="selectedRows" />
                                </div>
                            </div>
                            <div className="mt-1 mb-1 text-sm">
                                'Enter' to search with 2 or more characters
                            </div>
                        </div>                        

                        {IsFalse(excelExport) &&
                            <div className="mb-2 pointer-events-hand pl-3 table-cell">
                                <button id="btnProductSearch" onClick={(e) => loadPriceData(e)} //onClick={(e) => refreshCache(e)}
                                    type="button" className="btn-search !m-0">
                                    Search Products
                                </button>
                            </div>
                        }
                        {IsTrue(excelExport) &&
                            <div className="mb-2 pointer-events-hand pl-3 table-cell">
                                <button id="btnProductSearch" onClick={(e) => getExportData(e, true)}
                                    type="button" className="btn-search !m-0">
                                    Export Search
                                </button>
                            </div>
                        }
                        {/*<!-- Advanced Filters -->*/}
                        <div className="table-cell pl-3">
                            <button className="btn-close-modal"
                                onClick={(e) => clearFilters(e)}>
                                Clear Filters
                            </button>
                        </div>                        
                        <div onClick={handleOpenFiltersModal} className="div-binocs">
                            <div className="mt-[-25px]">
                                <svg fill="#000000" height="25px" width="25px" version="1.1" id="Capa_1"
                                    xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 488.1 488.1" space="preserve">
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier"><g><g>
                                        <path d="M299.9,86.8c14.2-6.3,30.3-9.8,46.2-9.8c9.2,0,18,1.1,26.3,3.2c3.3,0.8,6.5-2,6-5.4l-1.2-9.6l0,0 c-1-16-19.3-28.9-41.2-28.9c-22.5,0-44,13.5-44,30.2l1,16C293.3,86,296.8,88.2,299.9,86.8z"></path> <path d="M487.3,361.9l-0.5-2.9c0-0.2-0.1-0.4-0.1-0.6l-18.5-94c-2.4-21.7-18.2-40.6-41.2-52.3l-8.8-68.3c0-29-32.8-52.7-72.1-52.7 s-76.7,23.5-76.7,52.6c0,1.4,0.1,2.9,0.3,4.3l0.5,7.4c-7.8-2.8-16.7-4.4-26.1-4.4c-9.5,0-18.4,1.6-26.3,4.5l0.5-7.5 c0.2-1.4,0.3-2.8,0.3-4.3c0-29-37.5-52.6-76.7-52.6c-39.3,0-72.1,23.7-72.1,52.7L61,212.1c-23,11.6-38.8,30.6-41.2,52.3l-18.5,94 c0,0.2-0.1,0.4-0.1,0.6l-0.5,2.9l0,0c-0.4,3-0.7,6-0.7,9.1c0,44.6,46.7,80.8,104.2,80.8s104.2-36.2,104.2-80.8l0,0l0,0v-37 c10.3,4.8,22.6,7.5,35.7,7.5s25.3-2.7,35.6-7.4V371l0,0l0,0c0,44.6,46.7,80.8,104.2,80.8c57.4,0,104.2-36.2,104.2-80.8 C488,367.9,487.7,364.9,487.3,361.9z M104.2,422.7c-40.7,0-75.1-23.7-75.1-51.7c0-28.1,34.4-51.7,75.1-51.7s75.1,23.7,75.1,51.7 C179.3,399,144.9,422.7,104.2,422.7z M244.1,315.9c-17.1,0-31-9.7-31-21.6s13.9-21.6,31-21.6s31,9.7,31,21.6 C275.1,306.2,261.2,315.9,244.1,315.9z M383.8,422.7c-40.7,0-75.1-23.7-75.1-51.7c0-28.1,34.4-51.7,75.1-51.7s75.1,23.7,75.1,51.7 S424.5,422.7,383.8,422.7z"></path> <path d="M115.5,80.2c8.3-2.1,17.2-3.2,26.3-3.2c15.9,0,32.1,3.5,46.2,9.8c3.1,1.4,6.6-0.8,6.8-4.2l1-16c0-16.7-21.5-30.2-44-30.2 c-21.9,0-40.1,12.9-41.2,28.9l0,0l-1.2,9.6C109,78.2,112.1,81.1,115.5,80.2z">
                                        </path></g></g>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div className="table-cell pl-3 color-primary text-[12px] font-bold pr-4 mr-4">{clearFilterMsg}</div>

                        {/*** Using separate pages for admin and export now ***/}
                        {/*<div className="pl-4 table-cell pr-8">*/}
                        {/*    <span className="pr-1 font-bold text-secondary">Export</span>*/}
                        {/*    <input type="checkbox" className="mt-[-5px] cursor-pointer"*/}
                        {/*        checked={excelExport}*/}
                        {/*        onChange={(e) => onChangeExcelExport(e)}*/}
                        {/*    />*/}
                        {/*</div>*/}
                                                
                        <div className="pl-8 table-cell">
                            <div className="table-cell">
                                {IsTrue(excelExport) &&
                                    <span className="">
                                        <span className="pl-8 pr-1 font-bold text-secondary">All</span>
                                        <input type="radio" value="ALL" name="ALL" className="mt-[-3px] cursor-pointer"
                                            checked={priceType === "ALL"}
                                            onChange={(e) => onChangePriceType(e)} />
                                    </span>
                                }
                                <span className="pl-4 pr-1 font-bold text-secondary">PNW</span>
                                <input type="radio" value="PNW" name="PNW" className="mt-[-3px] cursor-pointer"
                                    checked={priceType === "PNW"}
                                    onChange={(e) => onChangePriceType(e)} />

                                <span className="pl-4 pr-1 font-bold text-secondary">AZ</span>
                                <input type="radio" value="AZ" name="AZ" className="mt-[-5px] cursor-pointer"
                                    checked={priceType === "AZ"}
                                    onChange={(e) => onChangePriceType(e)} />

                                <span className="pl-4 pr-1 font-bold text-secondary">Web</span>
                                <input type="radio" value="WEB" name="WEB" className="mt-[-5px] cursor-pointer"
                                    checked={priceType === "WEB"}
                                    onChange={(e) => onChangePriceType(e)} />
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
            <div className="clear">

                {IsFalse(excelExport) && //className={IsTrue(excelExport) ? "hidden" : ""}
                    <div>

                        <div className="">
                            <div style={containerStyle}>
                                <div className="ag-theme-alpine" style={{ height: gridHeight, width: '100%', }}>

                                    <AgGridReact id="gridProducts"
                                        //dataTypeDefinitions={dataTypeDefinitions}
                                        rowData={rowData}
                                        ref={gridRef}
                                        gridOptions={gridOptions}
                                        columnDefs={columnDefs}
                                        onGridReady={onGridReady}
                                        onCellClicked={cellClickedProduct}
                                        rowModelType={rowModelType}
                                        cacheBlockSize={pageRows}
                                        singleClickEdit={true}
                                        enterNavigatesVertically={true}
                                        enterNavigatesVerticallyAfterEdit={true}
                                        onCellKeyDown={(e) => onProductPriceCellKeyDown(e)}
                                        onCellFocused={(e) => onCellFocusedProduct(e)}
                                        onRowDoubleClicked={(e) => onRowDoubleClicked(e.data)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="pt-2">
                            <div className="float-left">
                                <div className="table-cell w-[400px]">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span className="font-bold text-secondary pr-2">Manufacturer:</span>
                                                </td>
                                                <td>{selectedProduct && selectedProduct.manufacturerName}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className="font-bold text-secondary pr-2">Collection:</span>
                                                </td>
                                                <td>{selectedProduct && selectedProduct.collectionName}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-cell w-[150px]">
                                    <button id="btnAddColorSku" onClick={(e) => btnAddColorSkuClick(e)}
                                        type="button" className="btn-search !m-0"
                                        disabled={addColorSkuDisabled}>
                                        Add Color / Sku
                                    </button>
                                </div>
                            </div>
                            <div className="w-full">
                                <div style={containerStyle} className="">
                                    <div className="ag-theme-alpine" style={{ height: gridHeight2, width: '100%', }}>
                                        {/*Quick Filter Search*/}
                                        {/*Per CFM: dont show search for colors grid*/}
                                        {false &&
                                            <div>
                                                <div className="table-cell">
                                                    <label for="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                                                    <div className="relative">
                                                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                            <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                                            </svg>
                                                        </div>
                                                        <input onChange={handleSearchOpts} type="search" id="search" placeholder="Search..."
                                                            className="orders-filter-search @apply block p-2 mb-2 pl-10 text-sm text-gray-900 
                                                            border border-gray-300 rounded-lg !focus:ring-blue-500 !focus:border-blue-500;"></input>
                                                        <div id="selectedRows" />
                                                    </div>
                                                    <div className="mt-1 mb-1">
                                                        &nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <AgGridReact id="gridOptions"
                                            rowData={productOptionsData}
                                            ref={gridRefOpts}
                                            gridOptions={gridOptions2}
                                            columnDefs={columnDefsOpt}
                                            singleClickEdit={true}
                                            enterNavigatesVertically={true}
                                            enterNavigatesVerticallyAfterEdit={true}
                                            onCellKeyDown={(e) => onColorCellKeyDown(e)}
                                        //onCellClicked={cellClickedColor}
                                        //onRowDoubleClicked={(e) => onRowDoubleClicked(e.data)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {IsTrue(excelExport) &&
                    <ProductPricingExportGrid priceType={priceType}></ProductPricingExportGrid>
                }
                {/*<div className={IsFalse(excelExport) ? " hidden " : ""}>*/}
                {/*<ProductPricingExportGrid priceType={priceType}></ProductPricingExportGrid>*/}
                {/*</div>*/}
            </div>
            
            <SearchFilters isOpen={isFiltersModalOpen} isProductPricingType={true}
                clearFilters={clearFilters} onClose={IsFalse(excelExport) ? handleCloseFiltersModal : handleCloseFiltersExportModal} />            

            <Modal ref={productEditModalRef} className={" " + productModalClass}>
                <Button size="sm" color="ghost" shape="circle"
                    className="absolute right-4 top-2 min-h-[1.5rem] h-[1.5rem] w-[1.5rem]"
                    onClick={e => onCloseProductEditModal()}
                >x</Button>
                <Modal.Body>
                    {productEditModalContent}
                </Modal.Body>
            </Modal>
        </div>
    );
}