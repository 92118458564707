import React, { Component } from 'react';
import { initFlowbite } from "flowbite";

import { AccountWrapper } from './AccountWrapper';

//import { AccountSalesNotes } from './AccountSalesNotes';
//import { AccountCreditNotes } from './AccountCreditNotes';
//import { AccountOrders } from './AccountOrders';
//import { AccountQuotes } from './AccountQuotes';
//import { AccountAddEdit } from './AccountAddEdit';

//import './_accountCustom.css';

//import { GetLocationStateId } from "../_reactHelpers/ReactHelpers";
//import { ClickById } from '../../js/helpers.js';
//import { SwitchFbTab } from '../../js/formHelpers';

export class AccountParent extends Component {
    static displayName = AccountParent.name;    

    componentDidMount() {
        initFlowbite();        
    }

    render() {
        return (
            <div>
                <AccountWrapper></AccountWrapper>
            </div>

            //<div> {/*className="fb-tab-btn-sel"*/}"
            //    <div className="details-page">
            //        <div className="details-page-container">
            //            <div className="acct-tabs">
            //                <ul className="fb-tabs-ul border-b border-gray-200" id="default-tab"
            //                    data-tabs-toggle="#default-tab-content" role="tablist">
            //                    <li className="mr-1 text-blue-600 bg-gray-100 rounded-t-lg border border-gray-200 border-b-0"
            //                        role="presentation">
            //                        <button onClick={e =>
            //                            SwitchFbTab(e, "fb-tabs-btns", "prod-tab-content", "SaleNotes-tab", "SaleNotes")}
            //                            className="fb-tab-btn fb-tabs-btns" type="button" role="tab" aria-selected="true"
            //                            data-tabs-target="#SaleNotes" id="SaleNotes-tab" aria-controls="SaleNotes">
            //                            Sales Notes</button>
            //                    </li>
            //                    <li className="mr-1 text-blue-600 bg-gray-100 rounded-t-lg border border-gray-200 border-b-0"
            //                        role="presentation">
            //                        <button onClick={e =>
            //                            SwitchFbTab(e, "fb-tabs-btns", "prod-tab-content", "CreditNotes-tab", "CreditNotes")}
            //                            className="fb-tab-btn fb-tabs-btns" type="button" role="tab" aria-selected="false"
            //                            data-tabs-target="#CreditNotes" id="CreditNotes-tab" aria-controls="CreditNotes">
            //                            Credit Notes</button>
            //                    </li>
            //                    <li className="mr-1 text-blue-600 bg-gray-100 rounded-t-lg border border-gray-200 border-b-0"
            //                        role="presentation">
            //                        <button onClick={e =>
            //                            SwitchFbTab(e, "fb-tabs-btns", "prod-tab-content", "Orders-tab", "Orders")}
            //                            className="fb-tab-btn fb-tabs-btns" type="button" role="tab" aria-selected="false"
            //                            data-tabs-target="#Orders" id="Orders-tab" aria-controls="Orders">
            //                            Orders</button>
            //                    </li>
            //                    <li className="mr-1 text-blue-600 bg-gray-100 rounded-t-lg border border-gray-200 border-b-0"
            //                        role="presentation">
            //                        <button onClick={e =>
            //                            SwitchFbTab(e, "fb-tabs-btns", "prod-tab-content", "Quotes-tab", "Quotes")}
            //                            className="fb-tab-btn fb-tabs-btns" type="button" role="tab" aria-selected="false"
            //                            data-tabs-target="#Quotes" id="Quotes-tab" aria-controls="Quotes">
            //                            Quotes</button>
            //                    </li>
            //                    <li className="mr-1 text-blue-600 bg-gray-100 rounded-t-lg border border-gray-200 border-b-0"
            //                        role="presentation">
            //                        <button onClick={e =>
            //                            SwitchFbTab(e, "fb-tabs-btns", "prod-tab-content", "Details-tab", "Details")}
            //                            className="fb-tab-btn fb-tabs-btns" type="button" role="tab" aria-selected="false"
            //                            data-tabs-target="#Details" id="Details-tab" aria-controls="Details">
            //                            Details</button>
            //                    </li>
            //                </ul>
            //                <div className="float-right mt-[-40px]">
            //                    <div>
            //                        <button id="btnParentNewOrderAccount" type="button"
            //                            className="btn-search table-cell mr-2"
            //                            onClick={e => ClickById("btnNewOrderAccount")}>
            //                            Create Order
            //                        </button>

            //                        <button id="btnParentNewOrderAccount" type="button"
            //                            className="btn-search table-cell !mr-0"
            //                            onClick={e => ClickById("lnkNewQuoteAccount")}>
            //                            Create Quote
            //                        </button>

            //                        {/*<div className="table-cell mt-1">*/}
            //                        {/*    <a href={"/Redirect?route=CreateQuote&orderType=2&status=create&accountId=" + propsAccountId}*/}
            //                        {/*        target="_blank" rel="noreferrer" className="btn-load-orders">*/}
            //                        {/*        Create Quote*/}
            //                        {/*    </a>*/}
            //                        {/*</div>*/}
            //                    </div>
            //                </div>
            //            </div>                          

            //            <div id="default-tab-content" className="act-vh act-tabs rounded border-gray-200 border border-t-0">
            //                <div className="hidden prod-tab-content" role="tabpanel"
            //                    id="SaleNotes" aria-labelledby="SaleNotes-tab">
            //                    <div className="">
            //                        <AccountSalesNotes></AccountSalesNotes>
            //                    </div>
            //                </div>
            //                <div className="hidden prod-tab-content" role="tabpanel"
            //                    id="CreditNotes" aria-labelledby="CreditNotes-tab">
            //                    <div className="">
            //                        <AccountCreditNotes></AccountCreditNotes>
            //                    </div>
            //                </div>
            //                <div className="hidden prod-tab-content" role="tabpanel"
            //                    id="Orders" aria-labelledby="Orders-tab">
            //                    <div className="">
            //                        <AccountOrders></AccountOrders>
            //                    </div>
            //                </div>
            //                <div className="hidden prod-tab-content" role="tabpanel"
            //                    id="Quotes" aria-labelledby="Quotes-tab">
            //                    <div className="">
            //                        <AccountQuotes></AccountQuotes>
            //                    </div>
            //                </div>
            //                <div className="hidden prod-tab-content" role="tabpanel"
            //                    id="Details" aria-labelledby="Details-tab">
            //                    <div className="">
            //                        <AccountAddEdit></AccountAddEdit>
            //                    </div>
            //                </div>
            //            </div>
            //        </div>
            //    </div>
            //</div>            
        );
    }
}