import React, { useState, useEffect, useCallback } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import '../../css/custom.css';

export const TaxList = ({ taxes, loading, onEdit, handleDeleteTax }) => {

    const [gridApi, setGridApi] = useState(null);
    const [gridHeight, setGridHeight] = useState('500px');

    useEffect(() => {
        const updateGridHeight = () => {
            const windowHeight = window.innerHeight;
            const offsetTop = document.querySelector('.ag-theme-alpine')?.offsetTop || 0;
            const newHeight = windowHeight - offsetTop - 20; // 20px for some bottom margin
            setGridHeight(`${newHeight}px`);
        };

        updateGridHeight();
        window.addEventListener('resize', updateGridHeight);

        return () => window.removeEventListener('resize', updateGridHeight);
    }, []);

    const columnDefs = [
        { headerName: 'City', field: 'city', sortable: true, filter: true },
        { headerName: 'County', field: 'county', sortable: true, filter: true },
        { headerName: 'State', field: 'state', sortable: true, filter: true },
        { headerName: 'Zip', field: 'zip', sortable: true, filter: true },
        { headerName: 'Tax Method', field: 'taxMethod', sortable: true, filter: true },
        { headerName: 'Sales Tax Base', field: 'salesTaxBase', sortable: true, filter: true },
        { headerName: 'Tax Code', field: 'taxCode', sortable: true, filter: true },
        {
            headerName: 'Sales Tax Percent',
            field: 'salesTaxPercent',
            sortable: true,
            filter: true,
            valueFormatter: params => `${(params.value * 100)}%`
        },
        {
            headerName: 'City Tax Percent',
            field: 'cityTaxPercent',
            sortable: true,
            filter: true,
            valueFormatter: params => `${params.value}%`
        },
        { headerName: 'Pay Tax Time', field: 'payTaxTime', sortable: true, filter: true },
        {
            headerName: '', maxWidth: 130,
            cellRenderer: params => (
                <div>
                    <button className="btn-grid" onClick={() => onEdit(params.data)}>
                        Edit
                    </button>
                    <button className="btn-grid !bg-red-500 ml-2 " onClick={() => handleDeleteTax(params.data)}>
                        Delete
                    </button>
                </div>
            )
        }
    ];

    const defaultColDef = {
        flex: 1,
        minWidth: 100,
        sortable: true,
        resizable: false,
        filter: true,
        suppressMovable: true,
        suppressMenu: false,
        cellClass: ["no-border"],
        menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab",],
    };

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);        
    }, []);

    const onFilterTextBoxChanged = useCallback(() => {
        gridApi.setQuickFilter(
            document.getElementById('filter-text-box').value
        );
    }, [gridApi]);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen pt-[10%]">
                <span className="loading loading-spinner loading-lg"></span>
            </div>
        );
    }

    return (
        <div>
            <div className="flex mb-2">
                <div className="flex-grow">
                    <input
                        type="text"
                        id="filter-text-box"
                        placeholder="Filter..."
                        onInput={onFilterTextBoxChanged}
                        className="grid-filter"
                    />
                </div>
            </div>

            <div className="ag-theme-alpine w-full" style={{ height: gridHeight }}>
                <AgGridReact
                    defaultColDef={defaultColDef}
                    columnDefs={columnDefs}
                    rowData={taxes}                    
                    onGridReady={onGridReady}                    
                    pagination={true}
                    paginationPageSize={15}
                />
            </div>
        </div>
    );
}