import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IdExists, IsFalse, NotExists, ParseIntSafe } from '../../js/formHelpers';

export function GetLocationStateId(type) {
    const location = useLocation();

    var intId = 0;
    try {
        if (location.state) {
            var objId;
            if (type === "accountId") {
                objId = location.state.accountId;
            }            
            else if (type === "billingGroupId") {
                objId = location.state.billingGroupId;
            }
            else if (type === "orderId") {
                objId = location.state.orderId;
            }
            else if (type === "orderType") {
                objId = location.state.orderType;
            }
            else if (type === "storeId") {
                objId = location.state.storeId;
            }
            else if (type === "userId") {
                objId = location.state.userId;
            }
            else if (type === "vendorId") {
                objId = location.state.vendorId;
            }

            intId = ParseIntSafe(objId);
            if (NotExists(intId)) {
                intId = 0;
            }
        }
    } catch (error) {
        intId = 0;
        console.error("GetLocationStateId: ", type + " - " + intId);
    }
    return intId;
}

/** 
 * @param {any} title
 * use to set dynamic title after fetch
 */
export const SetDocumentTitle = title => {
    const [document_title, setDocumentTitle] = useState(title);
    useEffect(() => {
        document.title = document_title;
    }, [document_title]);

    return [document_title, setDocumentTitle];
};

/** 
 * @param {any} title
 * use to set static title
 */
export function SetDocTitle(title) {    
    const prevTitle = document.title
    document.title = title
    return () => {
        document.title = prevTitle
    }
}

//export function withRouter(Component) {
//    function ComponentWithRouterProp(props) {
//        let location = useLocation();
//        let navigate = useNavigate();
//        let params = useParams();
//        return (
//            <Component {...props} router={{ location, navigate, params }}
//            />
//        );
//    }
//    return ComponentWithRouterProp;
//}