import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Accordion } from "flowbite-react";

import { Exists, IsFalse, IsTrue, NotEmpty, NotExists, PreventDefault }
from '../../js/formHelpers';
import { ClickById, SetHrefById, SetValueById, StringContains } from '../../js/helpers';

import authService from '../../services/authService';
import LogoTitle from '../../images/logo_title.png';

export const LuminaMenu = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [homeLinkUrl, setHomeLinkUrl] = useState("/Login");

    /**** TODO - remove ****/
    /******/

    // 99 - All, 3 - Products, 0 - None
    var rfmsImportId = 0;
    var rfmsImportUrl = "";
    if (rfmsImportId !== 0) {
        rfmsImportUrl = "/import?runId=" + rfmsImportId;
    }

    //test order url    
    var orderUrl = "/";
    //orderUrl = "/Redirect?route=EditOrder&orderId=" + 1979;

    /******/
    /******/

    async function handleLoggedInUser() {

        setIsAdmin(false);
        var userData = await authService.getCurrentUser();

        if (Exists(userData) && Exists(userData.user)) {
            //console.log('UserData: ' + JSON.stringify(userData));            

            setUser(userData);
            setIsAdmin(IsTrue(userData.user.isAdmin));

            // TODO: confirm default pages
            var hlUrl = authService.GetDefaultUserLink(userData);
            setHomeLinkUrl(hlUrl);
        }
        else {
            console.log('No user data found');
            handleLogout();

            const pathname = location.pathname.toLowerCase();

            // TODO: remove - dev for testing without login
            const isUsersPage = pathname.includes("users");
            console.log('isUserPage: ' + isUsersPage);

            const isLoginPage = pathname.includes("login");
            //console.log('isLogin: ' + isLoginPage);

            if (!isLoginPage && !isUsersPage) {
                ClickById("lnkMenuLogin");
            }
        }
    }

    const handleLogout = () => {
        setUser(null);
        setIsAdmin(false);
        setHomeLinkUrl("/Login");

        try {
            localStorage.removeItem('token');
            localStorage.removeItem('userData');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    /**** ON LOAD ****/
    useEffect(() => {
        handleLoggedInUser();
    }, []);

    return (
        <div className="header bg-primary">
            <a href="/Login" id="lnkMenuLogin" className="hidden">Login Link</a>
            <div className="hdr-tbl">
                <div className="hdr-logo-cell">
                    <a id="homeImageLink" href={homeLinkUrl} className="logo-link">
                        <img className="logo-img" src={LogoTitle} alt="logo" />
                    </a>
                </div>
                <div className="hdr-title-cell">
                    <div className="hdr-page-title">
                        <span id="hdrPageTitle1"></span>
                        <span id="hdrPageTitle2" className="font-bold"></span>
                        <span id="hdrPageTitle3"></span>
                    </div>
                </div>

                {NotEmpty(rfmsImportUrl) &&
                    <div className="text-white">
                        <a href={rfmsImportUrl} className="pl-2"
                            onClick={() => { window.confirm('Are you sure you want to run data import?') }}>
                            ImportRfmsData
                        </a>
                    </div>
                }

                <div className="hdr-nav-cell">
                    <div className="header-bar clear">
                        <div className="header-bar-row">

                            <div className="pl-2 table-cell h-full w-full text-left">
                                {Exists(user) &&
                                    <div>
                                        <div className="table-cell pr-8">
                                            <a href={orderUrl} className="link-white"
                                                rel="noreferrer">
                                                Hello, {user.name}
                                            </a>
                                        </div>
                                        <div className="table-cell pl-8">
                                            <span>Current Store: </span>
                                            {/*{user.currentStoreName}*/}
                                            <a href="/" className="underline">
                                                Tigard
                                            </a>
                                        </div>
                                    </div>
                                }
                                {NotExists(user) &&
                                    <div className="link-white">
                                        Welcome, Guest
                                    </div>
                                }
                            </div>
                            <div className="pl-2 table-cell h-full w-1/2 text-right px-5">
                                {user ?
                                    <a id="lnkLogoutClick" href="/" className="underline"
                                        onClick={(e) => handleLogout(e)}>Logout</a>
                                    :
                                    <a id="lnkLoginClick" href='/Login' className="underline">Login</a>
                                }
                            </div>
                            {/*<div className="pr-2 table-cell h-full w-1/2 text-right">*/}
                            {/*    <a href="/">*/}
                            {/*        Administration*/}
                            {/*    </a>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    <div className="clear w-full block pt-[4px]">
                        <div className={Exists(user) ? "" : "menu-disabled"}>
                            <div className="items-stretch inline-block">
                                <div className="nav-item-container dropdown dropdown-end dropdown-hover">
                                    <label tabIndex={0} className="nav-item">Sales</label>
                                    <ul tabIndex={0} className="nav-dropdown shadow-lg">
                                        <li><a href="/Orders" className="nav-link">Orders</a></li>
                                        <li><a href="/Quotes" className="nav-link">Quotes</a></li>
                                        <li><a href="/ProductPricing" className="nav-link">Product Pricing</a></li>
                                        <li><a href="/Accounts" className="nav-link">Accounts</a></li>
                                        <li><a href="/SampleCheckout" className="nav-link">Sample Checkout</a></li>
                                        <li><a href="/Claims" className="nav-link">Claims</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="inline-block items-stretch">
                                <div className="nav-item-container dropdown dropdown-end dropdown-hover">
                                    <label tabIndex={0} className="nav-item">Warehouse</label>
                                    <ul tabIndex={0} className="nav-dropdown shadow-lg">
                                        <li><a href="/Receiving" className="nav-link">Receiving</a></li>
                                        {/*<li><a href="/MaterialPickup" className="nav-link">Material Pickup</a></li>*/}
                                        {/*<li><a href="/" className="nav-link">Inventory</a></li>*/}
                                        <li>
                                            <a href="/Redirect?route=CreateInventoryOrder&orderType=5&status=create"
                                                className="nav-link">New Inventory Order</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="inline-block items-stretch">
                                <div className="nav-item-container dropdown dropdown-end dropdown-hover">
                                    <label tabIndex={0} className="nav-item">Products</label>
                                    <ul tabIndex={0} className="nav-dropdown shadow-lg">
                                        <li><a href="/ProductSearch" className="nav-link" target="_blank">Product Search</a></li>
                                        <li><a href="/ProductPricingAdmin" className="nav-link" target="_blank">Product Pricing Admin</a></li>
                                        <li><a href="/ProductImportExport" className="nav-link" target="_blank">Product Import/Export</a></li>
                                        {/*<li className=""><a href="/EdiImports" className="nav-link" target="_blank">EDI Import</a></li>*/}
                                        <li><a href="/VendorCategoryRates" className="nav-link" target="_blank">Vendor Category Rates</a></li>
                                        <li><a href="/ProductCategories" className="nav-link" target="_blank">Product Categories</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="inline-block items-stretch">
                                <div className="nav-item-container dropdown dropdown-end dropdown-hover">
                                    <label tabIndex={0} className="nav-item">Accounting</label>
                                    <ul tabIndex={0} className="nav-dropdown">
                                        <Accordion collapseAll>
                                            <Accordion.Panel>
                                                <Accordion.Title>
                                                    <li className="nav-top-item">Data Entry</li>
                                                </Accordion.Title>
                                                <Accordion.Content>
                                                    <li><a href="/" className="nav-link-sub">Process EDI Payables</a></li>
                                                    <li><a href="/ContentCentralPayables" className="nav-link-sub">Process Content Central Payables</a></li>
                                                </Accordion.Content>
                                            </Accordion.Panel>

                                            <Accordion.Panel>
                                                <Accordion.Title>
                                                    <li className="nav-top-item">Accounts Payable</li>
                                                </Accordion.Title>
                                                <Accordion.Content>
                                                    <li><a href="/Payables" className="nav-link-sub">Search Payables</a></li>
                                                    <li><a href="/ReadyForPayment" className="nav-link-sub">Ready for Payment</a></li>
                                                    <li><a href="/PayablesAgingReport" className="nav-link-sub">Aging Report</a></li>                                                    
                                                    <li><a href="/CommissionsPending" className="nav-link-sub">Commissions Pending</a></li>
                                                    <li><a href="/PendingPayments" className="nav-link-sub">Payments Pending</a></li>
                                                    <li><a href="/AchPendingPayments" className="nav-link-sub">ACH Payments Pending</a></li>
                                                    <li><a href="/PrintChecks" className="nav-link-sub">Print Checks</a></li> 
                                                </Accordion.Content>
                                            </Accordion.Panel>
                                            <Accordion.Panel>
                                                <Accordion.Title>
                                                    <li className="nav-top-item">Accounts Receivable</li>
                                                </Accordion.Title>
                                                <Accordion.Content>
                                                    <li><a href="/Receivables" className="nav-link-sub">Search Receivables</a></li>
                                                    <li><a href="/PostPayments" className="nav-link-sub">Post Payments</a></li>
                                                    <li><a href="/SearchReceipts" className="nav-link-sub">Search Receipts</a></li>
                                                    <li><a href="/AgingInvoiceReport" className="nav-link-sub">Aging Report</a></li>
                                                    <li><a href="/DepositRegisterReport" className="nav-link-sub">Deposit Register Report</a></li>

                                                </Accordion.Content>
                                            </Accordion.Panel>
                                            <Accordion.Panel>
                                                <Accordion.Title>
                                                    <li className="nav-top-item">Banking</li>
                                                </Accordion.Title>
                                                <Accordion.Content>
                                                    <li><a href="/BankAccounts" className="nav-link-sub">Bank Accounts</a></li>
                                                    <li><a href="/" className="nav-link-sub">Post Deposits</a></li>
                                                    {/*PostDeposits*/}
                                                    <li><a href="/" className="nav-link-sub">Reconcile Accounts</a></li>
                                                </Accordion.Content>
                                            </Accordion.Panel>
                                            {/*<Accordion.Panel>*/}
                                            {/*    <Accordion.Title>*/}
                                            {/*        <li className="nav-top-item">Reports</li>*/}
                                            {/*    </Accordion.Title>*/}
                                            {/*    <Accordion.Content>*/}
                                            {/*        <li><a href="/" className="nav-link-sub">Deposit Register Report</a></li>*/}
                                            {/*    </Accordion.Content>*/}
                                            {/*</Accordion.Panel>*/}
                                        </Accordion>
                                        <li><a href="/Vendors" className="nav-link">Vendors</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="inline-block items-stretch">
                                <div className="nav-item-container dropdown dropdown-end dropdown-hover">
                                    <label tabIndex={0} className="nav-item">Accounting Admin</label>
                                    <ul tabIndex={0} className="nav-dropdown shadow-lg">
                                        <li><a href="/" className="nav-link">View G/L</a></li>
                                        <li><a href="/" className="nav-link">View Trial Balance</a></li>
                                        <li><a href="/JournalEntries" className="nav-link">Journal Entries</a></li>
                                        <li><a href="/" className="nav-link">Month End / Year End close</a></li>
                                        <li><a href="/StoreAllocations" className="nav-link">Store Allocations</a></li>
                                        <li><a href="/Tax" className="nav-link">City Sales Tax</a></li>
                                        <li><a href="/DepositRegisters" className="nav-link">Deposit Registers</a></li>
                                        <li><a href="/ChartOfAccounts" className="nav-link">Chart of Accounts</a></li> 
                                    </ul>
                                </div>
                            </div>
                            {true && //isAdmin &&
                                <div className="inline-block items-stretch">
                                    <div className="nav-item-container dropdown dropdown-end dropdown-hover">
                                        <label tabIndex={0} className="nav-item">Admin</label>
                                        <ul tabIndex={0} className="nav-dropdown shadow-lg">
                                            <li><a href="/Users" className="nav-link">Users</a></li>
                                            <li><a href="/Stores" className="nav-link">Stores</a></li>
                                        </ul>
                                    </div>
                                </div>
                            }
                            <div className="inline-block items-stretch">
                                <div className="nav-item-container dropdown dropdown-end dropdown-hover">
                                    <label tabIndex={0} className="nav-item">Reports</label>
                                    <ul tabIndex={0} className="nav-dropdown shadow-lg">
                                        {/*<li><a href="/" className="nav-link">Sample Report</a></li>*/}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}