import React, { useState } from 'react';
import { TopazSignature } from './TopazSignature';

export const SignatureTest = () => {
    const [signatureData, setSignatureData] = useState(null);

    const handleSignatureCapture = (data) => {
        console.log('Captured signature:', data);
        setSignatureData(data);
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-8 text-center">Signature Test</h1>
            <TopazSignature onSignatureComplete={handleSignatureCapture} />
            {signatureData && (
                <div className="mt-8">
                    <h2 className="text-xl font-semibold mb-4">Captured Signature:</h2>
                    <img src={`data:image/png;base64,${signatureData}`} alt="Captured Signature" className="border-2 border-gray-300 rounded" />
                    <textarea className="w-full mt-4 p-2 border-2 border-gray-300 rounded" value={signatureData} readOnly />
                </div>
            )}
        </div>
    );
};