import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { get } from '../../services/apiService';

import { JournalEntriesList } from './JournalEntriesList';

import { SetPageTitle } from '../../js/helpers';
import { SetDocumentTitle } from '../_reactHelpers/ReactHelpers';

export function JournalEntryLines() {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Journal Batch Entries");
    const { batchId } = useParams();

    const [journalEntryBatch, setJournalEntryBatch] = useState({});
    const [journalEntryLines, setJournalEntryLines] = useState([]);

    const [loading, setLoading] = useState(true);

    const fetchJournalEntryBatch = async () => {
        setLoading(true);
        //console.log('Loading journalEntryBatch:', batchId);

        try {
            const response = await get(`journalentrybatch/GetJournalEntryBatch/${batchId}`);
            setJournalEntryBatch(response);

            SetPageTitle(response?.batchName + " Batch Entries");
            setLoading(false);

        } catch (error) {
            console.error('Error fetching journalEntryBatch:', error);
            setJournalEntryBatch({});
            setLoading(false);
        }
    };

    const fetchJournalEntryLines = async () => {
        setLoading(true);

        try {
            const response = await get(`/journalentrylines/GetPostedJournalEntryLines?batchId=${batchId}`);
            //console.log('Fetched journalEntryLines:', response);
            const data = response.map(item => ({
                ...item
            }));

            setJournalEntryLines(data);
            setLoading(false);

        } catch (error) {
            console.error('Error fetching journalEntryLines:', error);
            setJournalEntryLines([]);
            setLoading(false);
        }
    };

    //const toggleAddForm = () => setShowAddForm(!showAddForm);

    //const handleAddJournalEntryLine = () => {
    //    setShowAddForm(false);
    //    fetchJournalEntryLines();
    //};

    useEffect(() => {
        fetchJournalEntryBatch();
        fetchJournalEntryLines();
    }, []);

    return (
        <div className="page-wrapper">
            <div className="flex-wrapper pb-2">
                <a href="\JournalEntries" className='btn-search !ml-0'>
                    Go Back
                </a>
            </div>


            <JournalEntriesList
                journalEntries={journalEntryLines}
                onJournalEntryUpdated={fetchJournalEntryLines}
                loading={loading}
                isParent={false}
            />
        </div>
    );
}

