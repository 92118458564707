import axios from 'axios';
import { get, post } from './apiService.js';

// Make simple payment
export const makePayment = async (paymentRequest) => {
    // Set terminal Identifiers
    // Override for testing
    // Move to config, use location.  Each store has different info
    paymentRequest.GMID = "1110102975";
    paymentRequest.GTID = "GT1120044415";
    paymentRequest.GMPW = "GMPW3010022428";

    const paymentApiUrl = 'https://testlink.c9pg.com:11911/Payment/Sale#Sale?TerminalType=None&NeedSwipeCard=N&GMID=1110102975';
    console.log('Payment API Url: ' + paymentApiUrl);
    const response = await axios.post(paymentApiUrl, paymentRequest);

    // TODO: Handle errors
    return response.data;
}

// Get Token for Card
export const getCardToken = async (tokenRequst) => {
    console.log('Getting card token');
    // Set terminal Identifiers
    // Override for testing
    // Move to config, use location.  Each store has different info
    tokenRequst.GMID = "1110102975";
    tokenRequst.GTID = "GT1120044415";
    tokenRequst.GMPW = "GMPW3010022428";

    const tokenApiUrl = 'https://testlink.c9pg.com:11911/Payment/Sale#Tokenization?TerminalType=None&NeedSwipeCard=N&GMID=1110102975';
    const response = await axios.post(tokenApiUrl, tokenRequst);

    return response.data;
}

// Post Payment to DB
export const postPaymentToDatabase = async (paymentInfo) => {
    //console.log('Posting to DB: ' + + paymentInfo);
    try {
        const response = await post('/payments/SavePayment', paymentInfo);
        //console.log('SavePayment response: ' + JSON.stringify(response));
        return response;
    } catch (err) {
        console.log('Error posting payment: ' + err);
    }
}

// Post Transaction to DB
export const postTransactionToDatabase = async (transactionInfo) => {
    //console.log("Posting Transaction to DB: " + JSON.stringify(transactionInfo));
    const response = await post('/payments/SaveTransaction', transactionInfo);
    //console.log('DB Response: ' + JSON.stringify(response));
    return response;
}

export const getPaymentsForOrder = async (orderId) => {
    //console.log('Getting Payments for ' + orderId);
    const response = await get('/payments/GetPaymentsForOrder/' + orderId);
    //console.log('Payments: ' + JSON.stringify(response));
    return response;
}

// Find all Payments by last four digits
export const getPaymentsByLastFour = async (lastFour) => {
    console.log('Getting by last 4: ' + lastFour);
    const response = await get('/payments/GetPaymentsByLastFour?lastFour=' + lastFour);
    return response;
}

// Payment by CardToken
export const makePaymentByToken = async (token, amount) => {
    console.log('Looking up Token: ' + token);

    // TESTING, remove for Prod
    amount = 1.00;
    const tokenRequest = {
        // Set terminal Identifiers
        // Override for testing
        // Move to config, use location.  Each store has different info
        "GMID" : "1110102975",
        "GTID" : "GT1120044415",
        "GMPW" : "GMPW3010022428",
        "TransType" : "Sale",
        "Medium" : "Credit",
        "MainAmt" : amount,
        "CardToken" : token
    };

    //console.log('Token Sale Request: ' + JSON.stringify(tokenRequest));
    const tokenApiUrl = 'https://testlink.c9pg.com:11911/Payment/Sale#Tokenization?TerminalType=None&NeedSwipeCard=N&GMID=1110102975';
    const response = await axios.post(tokenApiUrl, tokenRequest);
    return response;
}

export const getAchToken = async (achInfo) => {
    const tokenRequest = {
        "GMID": "1110102975",
        "GTID": "GT1120044415",
        "GMPW": "GMPW3010022428",
        "TransType": "CreateCardToken",
        "Medium": "ElectronicCheck",
        "EntryModel": "Manual",
        // Test numbers
        "EleCheckRoutingNumber": "123456780",
        "EleCheckAccountNumber": "1071642307",
        "AccountType": achInfo.accountType
    }

    //console.log('ACH Token Request: ' + JSON.stringify(tokenRequest));
    const tokenApiUrl = 'https://testlink.c9pg.com:11911/Payment/Sale?TerminalType=None&NeedSwipeCard=N&GMID=1110102975&PaymentType=ACHCredit';
    const response = await axios.post(tokenApiUrl, tokenRequest);
    //console.log('Token: ' + response.data.CardToken);
    return response.data;
}

export const makeAchPayment = async (achInfo) => {
    achInfo.GMID = "1110102975";
    achInfo.GTID = "GT1120044415";
    achInfo.GMPW = "GMPW3010022428";
    achInfo.Medium = "ElectronicCheck";
    achInfo.TransType = "Sale";
    achInfo.EntryModel = "Manual";
    const paymentApiUrl = 'https://testlink.c9pg.com:11911/Payment/Sale#ACHCredit?TerminalType=None&NeedSwipeCard=N&GMID=1110220413';
    const response = await axios.post(paymentApiUrl, achInfo);

    //console.log('ACH Payment response: ' + JSON.stringify(response));
    return response.data;
}

    
