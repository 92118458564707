import React, { useState, useEffect } from 'react';
import { get, post } from '../../services/apiService';

export default function StoreAllocationGroupForm({ groupData, onGroupSaved, onClose }) {
    const [formData, setFormData] = useState({
        groupId: 0,
        groupName: "",
        allocationItems: []
    });
    const [stores, setStores] = useState([]);
    const [statusMessage, setStatusMessage] = useState('');
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        fetchStores();
        if (groupData) {
            setFormData(groupData);
        }
    }, [groupData]);

    const fetchStores = async () => {
        try {
            const response = await get("/store/GetAllStores");
            setStores(response || []);
        } catch (error) {
            console.error("Error fetching stores:", error);
            setStatusMessage("Error fetching stores");
            setIsError(true);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleItemChange = (index, field, value) => {
        const newItems = [...formData.allocationItems];
        newItems[index][field] = field === 'allocationPercent' ? parseFloat(value) : parseInt(value, 10);
        setFormData(prev => ({ ...prev, allocationItems: newItems }));
    };

    const addAllocationItem = () => {
        setFormData(prev => ({
            ...prev,
            allocationItems: [...prev.allocationItems, { storeId: '', allocationPercent: 0 }]
        }));
    };

    const removeAllocationItem = (index) => {
        const newItems = formData.allocationItems.filter((_, i) => i !== index);
        setFormData(prev => ({ ...prev, allocationItems: newItems }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const submissionData = {
                ...formData,
                allocationItems: formData.allocationItems.map(item => ({
                    ...item,
                    storeId: parseInt(item.storeId, 10)
                }))
            };

            if (formData.groupId) {
                await post(`/storeallocation/UpdateStoreAllocationGroup`, submissionData);
                setStatusMessage("Store Allocation Group Updated Successfully");
            } else {
                await post("/storeallocation/CreateStoreAllocationGroup", submissionData);
                setStatusMessage("Store Allocation Group Added Successfully");
            }

            setIsError(false);
            onGroupSaved();
        } catch (error) {
            console.error("Error saving store allocation group:", error);
            setStatusMessage("Error saving Store Allocation Group");
            setIsError(true);
        }
    };

    return (
        <div>
            <h2 className="text-2xl font-bold mb-4 text-center">
                {formData.groupId ? 'Edit' : 'Add'} Allocation Group
            </h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="groupName" className="label-form">Allocation Group Name:</label>
                    <input
                        type="text"
                        id="groupName"
                        name="groupName"
                        value={formData.groupName}
                        onChange={handleChange}
                        className="label-form-input w-full"
                        required
                    />
                </div>
                <div>
                    <h3 className="text-lg font-semibold mb-2 underline">Store Allocations</h3>
                    {formData.allocationItems.map((item, index) => (
                        <div key={index} className="flex space-x-2 mb-2">
                            <select
                                value={item.storeId}
                                onChange={(e) => handleItemChange(index, 'storeId', e.target.value)}
                                className="label-form-input flex-grow"
                                required
                            >
                                <option value="">Select store</option>
                                {stores.map((store) => (
                                    <option key={store.storeId} value={store.storeId}>
                                        {store.displayName}
                                    </option>
                                ))}
                            </select>
                            <div className="relative flex-shrink-0 w-32">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <span className="text-gray-500 sm:text-sm">%</span>
                                </div>
                                <input
                                    type="number"
                                    value={item.allocationPercent}
                                    onChange={(e) => handleItemChange(index, 'allocationPercent', e.target.value)}
                                    className="label-form-input pl-7 w-full"
                                    placeholder="Percent"
                                    required
                                    min="0"
                                    max="100"
                                    step="0.01"
                                />
                            </div>
                            <button type="button" onClick={() => removeAllocationItem(index)} className="btn btn-small btn-cancel">
                                Remove
                            </button>
                        </div>
                    ))}
                    <button type="button" onClick={addAllocationItem} className="btn btn-small mt-2">
                        Add Store
                    </button>
                </div>
                {statusMessage && (
                    <div className={`p-4 rounded-md ${isError ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'}`}>
                        {statusMessage}
                    </div>
                )}
                <div className="flex justify-end space-x-2">
                    <button type="button" onClick={onClose} className="btn-small btn btn-cancel">
                        Cancel
                    </button>
                    <button type="submit" className="btn btn-small btn-submit">
                        {formData.groupId ? 'Update' : 'Add'} Allocation Group
                    </button>
                </div>
            </form>
        </div>
    );
}