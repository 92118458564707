import React, { Component } from 'react';

import { initFlowbite } from "flowbite";
import { AccountAddEdit } from './AccountAddEdit';

//import { createAccountModel } from './_accountHelpers.js';
//import { handleFieldChange, isSelectedDropdown, isValidDate }
//from '../../js/formHelpers.js';

import './_accountCustom.css';

export class AccountParentCreate extends Component {
    static displayName = AccountParentCreate.name;    

    //constructor(props) {
    //    super(props);
    //}

    componentDidMount() {
        initFlowbite();
    }

    //AccountsRedirect = () => {
    //    return <Navigate to="/Accounts" replace={true} />
    //}

    render() {
        return (
            <div>
                {/*<button onClick={() => this.AccountsRedirect()} type="button"*/}
                {/*    className="float-left pt-[20px] link-primary underline font-bold">*/}
                {/*    Back to Accounts*/}
                {/*</button>*/}
                <div className="details-page">
                    <div className="details-page-container">
                        <AccountAddEdit></AccountAddEdit>
                    </div>
                </div>
            </div>            
        );
    }
}