import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Modal } from 'react-daisyui';

import { GetStoreSelectList, GetProductCategorySelectList, GetVendorSelectList, GetUnitsSelectList }
    from '../../../js/dataHelpers.js';
import { NotStringContains, StringContains } from '../../../js/helpers.js';
import {
    formatCurrencyDecimal, SetFocusById, handleFieldChange, Exists,
    isSelectedDropdown, FormatStrLength, PreventDefault, NotEmpty, IsEmpty, ParseIntSafe, IdExists, IsTrue, IsFalse, EnforceNumDecimalPlaces, NotStringEmpty
} from '../../../js/formHelpers.js';
import {
    GetQuantityFromWidthLength, GetLengthFromWidthQuantity, GetNewQuantityForMin,
    GetRemainder, GetMultDec, GreaterThan, LessThanEq, Equals
} from '../../../js/calculationHelpers.js';

import { DoMinQtyCheck, GetBoxQtyStr, createAddOlModel } from '../_js/_orderHelpers.js';
import { MinQtyModal } from './MinQtyModal';
import { get, post } from '../../../services/apiService.js';

export const CreateOrderLineContent = (props) => {
    const qtyModal = useRef();
    const loadModalContent = props.loadModalContent;
    const onAddBundles = props.onAddBundles;
    const onCloseModal = props.onCloseModal;

    //TODO: remove when use token
    const [user, setUser] = useState(null);
    const [userId, setUserId] = useState(null);

    const propsOrderId = props.orderId ?? 0;
    const propsOrderTypeStr = props.orderTypeStr ?? "";
    const propsStoreId = props.storeId ?? 0;
    const propsPriceLevelStr = props.priceLevelStr ?? "";
    const propsBcMultiplier = props.bcMultiplier ?? 0;
    const propsIsSamplesOrder = props.isSamplesOrder ?? false;
    
    const propsProductId = props.productId ?? 0;
    const propsProductSkuId = props.productSkuId ?? 0;
    const propsProductPriceId = props.productPriceId ?? 0;
    const propsProductStockId = props.productStockId ?? 0;    
    const propsProductType = props.productType ?? "";
    const propsProductHasBundles = props.productHasBundles ?? false;

    const IsProduct = propsProductType === "Products";
    const IsCustomProduct = propsProductType === "Custom";

    const [modalTitle, setModalTitle] = useState(false);
    const [disableFields, setDisableFields] = useState(false);
    const [showAddLineForm, setShowAddLineForm] = useState(false);
        
    //const [linesUpdated, setLinesUpdated] = useState(false);

    const [storeList, setStoreList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [unitsList, setUnitsList] = useState([]);

    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [closeDisabled, setCloseDisabled] = useState(false);
    const [priceDisabled, setPriceDisabled] = useState(false);
    const [unitsDisabled, setUnitsDisabled] = useState(false);
    const [quantityDisabled, setQuantityDisabled] = useState(false);

    //const [selectedProduct, setSelectedProduct] = useState([]);
    //const [selectedProductPrice, setSelectedProductPrice] = useState([]);
    //const [lengthDisabled, setLengthDisabled] = useState(false);

    const [isRoll, setIsRoll] = useState(false);
    const [boxQtyStr, setBoxQtyStr] = useState(false);
    const [stdLength, setStdLength] = useState(0);            
    const [units, setUnits] = useState("");
    const [width, setWidth] = useState(0);
    const [length, setLength] = useState(0);
    const [quantity, setQuantity] = useState(0);

    //used for min qty adjustment
    const [qtyModalContent, setQtyModalContent] = useState(null);
    const [allowQtyOverride, setAllowQtyOverride] = useState(false);       
        
    const [lineTotal, setLineTotal] = useState(0);
    const [linePrice, setLinePrice] = useState(0);
    const [olData, setOlData] = useState(createAddOlModel());

    /** CFM 4-16-24: dont show Referral **/
    //const [referral, setReferral] = useState();

    const [requiredClass, setRequiredClass] = useState(" hidden ");
    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");

    const createMsg = "Order Line created successfully.";
    const updateMsg = "Order Line updated successfully.";
    const createErrMsg = "There was an issue creating the line. Please try again or contact support.";


    // Min Qty Modal
    const handleQtyModalShow = useCallback(() => {
        qtyModal.current?.showModal();
    }, [qtyModal]);

    const handleQtyModalHide = useCallback(() => {
        qtyModal.current?.close();
        SetFocusById("linePrice");
    }, [qtyModal]);


    //Helper Methods

    function updateMessage(msg, cssClass) {
        setMessage(msg);
        setMessageClass(cssClass);
    }

    async function createStoreList() {
        var list = [];
        //list.push(<option key="0" value="0">None</option>);

        var dataList = await GetStoreSelectList(2);
        dataList.forEach((item, index) => {
            list.push(<option key={index + 1} value={item.value}>{item.text}</option>);
        });
        setStoreList(list);
    }

    async function createProductCategoryList() {
        var list = [];
        list.push(<option key="0" value="0">None</option>);

        var dataList = await GetProductCategorySelectList("order");
        dataList.forEach((item, index) => {
            list.push(<option key={index + 1} value={item.value}>{item.text}</option>);
        });
        setCategoryList(list);        
    }

    async function createVendorSupplierList() {
        var list = [];
        list.push(<option key="0" value="0">None</option>);

        var dataList = await GetVendorSelectList(1);
        dataList.forEach((item, index) => {
            list.push(<option key={index + 1} value={item.value}>{item.text}</option>);
        });
        //set page fields
        setVendorList(list);
        setShowAddLineForm(true);
    }

    async function createUnitsList(units) {
        var list = [];
        list.push(<option key="0" value="">None</option>);

        var createForValue = NotStringEmpty(units);
        var dataList = await GetUnitsSelectList();

        var cnt = 0;
        dataList.forEach((item, index) => {
            list.push(<option key={cnt + 1} value={item.value}>{item.text}</option>);
            cnt++;

            if (IsTrue(createForValue)) {
                createForValue = NotStringContains(item.text, units);
            }
        });

        if (IsTrue(createForValue)) {            
            list.push(<option key={cnt + 1} value={units}>{units}</option>);
        }

        setUnitsList(list);
    }

    function updateBoxQtyStr(model) {

        var sellQty = olData.sellQuantity;
        var sellUnit = olData.sellUnit;
        if (Exists(model)) {
            sellQty = model.sellQuantity;
            sellUnit = model.sellUnit;
        }

        var bxQtyStr = GetBoxQtyStr(sellQty, sellUnit, model.units)
        setBoxQtyStr(bxQtyStr);
    }

    //Methods

    function returnMinQtyModalComponent(minQuantity, newQuantity, onCloseQtyModal) {
        return <MinQtyModal minQuantity={minQuantity} newQuantity={newQuantity}
            onCloseModal={onCloseQtyModal}></MinQtyModal>;         
    }

    function onOpenQtyModal(minQuantity, newQuantity) {
        //updateMessage("", "");

        setQtyModalContent(returnMinQtyModalComponent(minQuantity, newQuantity, onCloseQtyModal));        
        handleQtyModalShow();
    }

    function onCloseQtyModal(e, allowAdj, newQuantity) {
        PreventDefault(e);

        if (allowAdj) {
            //set adjusted qty for min sell qty
            setQuantity(newQuantity);
            UpdatePricesAndTotals(newQuantity, null, null, null);
            updateMessage("Quantity and totals have been adjusted.", "text-red");
        }
        else {
            //set allow qty override for submit
            setAllowQtyOverride(true);
        }
        handleQtyModalHide();
    }

    function DisableSubmit(e, cQuantity, cAllowQtyOverride) {
        PreventDefault(e);

        var minQtyRem = DoMinQtyCheck(cQuantity, cAllowQtyOverride, allowQtyOverride, quantity, olData.sellQuantity, olData.sellUnit, isRoll);
        if (minQtyRem !== 0) {
            setSubmitDisabled(true);
            setCloseDisabled(true);
        }
        else {
            setSubmitDisabled(false);
            setCloseDisabled(false);
        }
    }

    function CheckMinQuantity(e) {
        PreventDefault(e);
        setSubmitDisabled(false);
        setCloseDisabled(false);

        if (IsFalse(olData.isCustom) || IsTrue(propsIsSamplesOrder)) {
            var minQtyRem = DoMinQtyCheck(null, null, allowQtyOverride, quantity, olData.sellQuantity, olData.sellUnit, isRoll);
            if (minQtyRem !== 0) {
                var newQuantity = GetNewQuantityForMin(olData.sellQuantity, quantity);
                //open min qty notification modal
                onOpenQtyModal(olData.sellQuantity, newQuantity);
                return true;
            }
        }
        return false;
    }

    function validateSave() {
        updateMessage();
        var valid = true;
        var msg = "";

        if (LessThanEq(quantity, 0)) {
            valid = false;
        }

        if (IsTrue(olData.isCustom)) {
            if (isSelectedDropdown(olData.productCategoryId) !== true) {
                valid = false;
            }
            if (isSelectedDropdown(olData.vendorId) !== true) {
                valid = false;
            }
            if (IsEmpty(olData.style)) {
                valid = false;
            }
            if (IsEmpty(olData.color)) {
                valid = false;
            }

            //if (isRoll) {
            //}
        }

        if (IsFalse(valid)) {
            setRequiredClass("");
            updateMessage("*Required fields", "text-red");
        }
        return valid;
    }

    async function addLineAndNext(e, addNext) {
        PreventDefault(e);
        setRequiredClass(" hidden ");

        if (IsFalse(submitDisabled)) {

            // only validate data if saving
            // always save for create line
            var save = true;
            var valid = true;
            if (save === true) {
                valid = validateSave();
            }

            // if not valid stop all actions
            // error messages displayed
            if (valid === true) {

                //setLinesUpdated(true);
                var success = await addOrderLine();
                if (success === true) {

                    if (IsTrue(propsProductHasBundles) && IsFalse(olData.isCustom)) {
                        onAddBundles(addNext, propsProductId, propsProductPriceId, propsProductSkuId);
                    }
                    else {
                        if (addNext) {
                            //add another
                            loadModalContent(true, "products");
                        }
                        else {
                            //close modal
                            onCloseModal(e, true);
                        }
                    }
                }
                else {
                    updateMessage(createErrMsg, "text-red");
                }
            }
        }
    }
        
    const handleChange = (e) => {
        setMessage("");
        var data = handleFieldChange(e);
        if (data) {
            // individual fields
            if (data.name === "units") {
                setUnits(data.value);
            }
            else if (data.name === "width") {
                UpdateForWidthLengthQuantity(data.value, null, null);
            }
            else if (data.name === "length") {
                UpdateForWidthLengthQuantity(null, data.value, null);
            }
            else if (data.name === "quantity") {
                setSubmitDisabled(true);
                DisableSubmit(e, data.value, false);

                //reset allow override for submit on qty change                
                setAllowQtyOverride(false);
                UpdateForWidthLengthQuantity(null, null, data.value);                
            }
            else if (data.name === "linePrice") {
                setLinePrice(data.value);
                UpdatePricesAndTotals(quantity, data.value, null, null);
            }
            else {
                // cost
                if (data.name === "cost") {
                    UpdatePricesAndTotals(null, null, data.value, null);
                }
                // multiplier
                if (data.name === "multiplier") {
                    data.value = EnforceNumDecimalPlaces(data.value, 3);
                    UpdatePricesAndTotals(null, null, null, data.value);
                }
                // comments
                if (data.name === "comments") {
                    data.value = FormatStrLength(data.value, 50);
                }
                // productCategoryId
                if (data.name === "productCategoryId") {
                    if (data.value === "1" || data.value === "2") {
                        setUnits("SY");
                        setIsRoll(true);
                        setUnitsDisabled(true);
                    }
                    else {
                        setIsRoll(false);
                        setUnitsDisabled(false);
                        if (units === "SY") {
                            setUnits("");
                        }
                        else {
                            //dont clear units if not switching from Rolls
                        }
                    }
                }

                //updateMessage("", "");
                setOlData((olData) => ({
                    ...olData,
                    [data.name]: data.value
                }));
            }
        }
        else {
            //updateMessage(defaultErrMsg, "text-red", "null handleChange data");
        }
    }

    function UpdateForWidthLengthQuantity(cWidth, cLength, cQuantity) {

        //update roll fields based on changed
        if (isRoll === true) {            
            //quantity always set here for rolls

            if (cWidth !== null) {
                //set changed value
                setWidth(cWidth);
                //set calc values for non-changed fields
                cLength = length ?? 0;                

                cQuantity = GetQuantityFromWidthLength(cWidth, cLength, "SY")
                cQuantity = formatCurrencyDecimal(cQuantity);
            }
            else if (cLength !== null) {
                //set changed value
                setLength(cLength);
                //set calc values for non-changed fields
                cWidth = width ?? 0;                

                cQuantity = GetQuantityFromWidthLength(cWidth, cLength, "SY")
                cQuantity = formatCurrencyDecimal(cQuantity);
            }
            else if (cQuantity !== null) {                
                //set calc values for non-changed fields
                cWidth = width ?? 0;

                cLength = GetLengthFromWidthQuantity(cWidth, cQuantity, "SY")
                setLength(formatCurrencyDecimal(cLength));
            }
        }

        //quantity always updated        
        setQuantity(cQuantity);
        //update pricing and totals
        UpdatePricesAndTotals(cQuantity, null, null, null);
    }

    function UpdatePricesAndTotals(cQuantity, cLinePrice, cCost, cMultiplier) {
        //re-calc line price when cost/multiplier changed
        var reCalcLinePrice = false;

        if (cQuantity === null) {
            cQuantity = quantity;
        }
        if (cLinePrice === null) {
            cLinePrice = linePrice;
        }
        //re-calc line price when cost changed
        if (cCost === null) {
            cCost = olData.cost;
        }
        else {
            reCalcLinePrice = true;
        }
        //re-calc line price when multiplier changed
        if (cMultiplier === null) {
            cMultiplier = olData.multiplier;
        }
        else {
            reCalcLinePrice = true;
        }

        // TODO: make 2 decimal places
        //cMultiplier = formatCurrencyDecimal(cMultiplier);        
        //cCost = formatCurrencyDecimal(cCost);

        olData.cost = cCost;
        olData.multiplier = cMultiplier;
        setOlData(olData);

        //update for changed fields
        if (reCalcLinePrice === true) {
            const bcPrice = GetMultDec(cCost, cMultiplier);
            cLinePrice = GetMultDec(bcPrice, propsBcMultiplier);
            setLinePrice(formatCurrencyDecimal(cLinePrice));
        }

        //always update LineTotal
        const lt = GetMultDec(cQuantity, cLinePrice);
        setLineTotal(formatCurrencyDecimal(lt));

        /** CFM 4-16-24: dont show Referral **
        //only calculate bc and referral for custom items
        if (IsTrue(olData.isCustom)) {
            const bcPrice = GetMultDec(cCost, cMultiplier);
            const priceReferral = cLinePrice - bcPrice;
            const referral = GetMultDec(priceReferral, cQuantity);
            setReferral(referral);
        }*/
    }

    function createOrderLineModel(productData) {
        var model = null;

        //productData can be sent for Product or Custom types
        //set from selected Product if exists for type        
        if (productData) {
            if (productData.productPrices && productData.productPrices[0]) {
                var productPriceData = productData.productPrices[0];
                model = createAddOlModel(propsProductType, propsStoreId, propsOrderId,
                    productData, productPriceData);

                var bcPrice = model.price;
                if (propsBcMultiplier > 0) {
                    bcPrice = bcPrice * propsBcMultiplier;
                }
                setLinePrice(formatCurrencyDecimal(bcPrice));
            }
        }
        else {
            model = createAddOlModel(propsProductType, propsStoreId, propsOrderId);
        }

        //set page fields and data model
        updateBoxQtyStr(model);
        setIsRoll(model.isRoll);

        setUnits(model.units);
        createUnitsList(model.units)

        setWidth(model.width);
        setStdLength(model.length);
        setQuantity(model.quantity);

        if (IsProduct) {
            //set 'Stock Item' id and status if Stock Product
            model.productStockId = propsProductStockId;
            if (IdExists(propsProductStockId)) {
                model.statusId = 15;

                setModalTitle("Add Stock Line");
                setDisableFields(true);
            }
            else {
                setModalTitle("Add Product Line");
                setDisableFields(true);
            }
        }
        else if (IsCustomProduct) {
            //set default Units for Rolls
            if (model.isRoll === true) {
                setUnits("SY");
                setUnitsDisabled(true);
            }
            else {
                //TODO: set default units for Items?
                //setUnits("EA");
            }

            model.isCustom = true;
            setModalTitle("Add Custom Line");
            setPriceDisabled(true);
            setDisableFields(false);                        
        }
        else {
            //TODO: show error message - should not occur
            debugger;
        }

        //set Samples Order line values
        if (IsTrue(propsIsSamplesOrder)) {

            setModalTitle("Add Samples Line");
                        
            model.cost = 0;
            setQuantity(1);
            setQuantityDisabled(true);
            setLinePrice(0);
            setPriceDisabled(true);
            setSubmitDisabled(false);
        }

        //set page model
        setOlData(model);
    }

    function setIndividualFields(model) {        
        model.price = linePrice;
        model.units = units;
        model.width = width;
        model.length = length;
        model.quantity = quantity;

        model.productSkuId = propsProductSkuId;
        model.productStockId = propsProductStockId;
        
        if (!model.cost || model.cost === "") {
            model.cost = 0;
        }
        return model;
    }

    async function addOrderLine() {
        var resp = null;
        var model = null;

        if (olData && olData.orderId !== 0) {
            //validate and set model for type
            if (IsProduct) {

                //TODO: show error messages for missing Ids
                //TODO: remove olData.productId lookup by only productPriceId
                if (olData.productPriceId && olData.productId) {
                    model = olData;
                    setIndividualFields(model);
                }
            }
            else if (IsTrue(olData.isCustom)) {
                model = olData;
                model.isCustom = true;
                setIndividualFields(model);
            }
            //else if (propsProductType === "Stock") {
            //    //model = olData;
            //}
            else {
                //TODO: show error messages for missing type
                //should not occur
                debugger;
            }

            //post model for create
            if (model !== null) {

                var apiUrl = process.env.REACT_APP_API_URL + 'orders/CreateOrderLine';
                const endpoint = 'orders/CreateOrderLine';
                model.userId = userId;
                var postModel = JSON.stringify(model);
                //console.log("addOrderLine apiUrl: " + apiUrl + " \r\n postModel: " + postModel);

                try {
                    const response = await post(endpoint, postModel);
                    if (response.value) {
                        var respData = response.value;

                        if (IsTrue(respData.success)) {
                            const orderLineId = ParseIntSafe(respData.data);
                            if (IdExists(orderLineId)) {
                                resp = true;
                            }
                        }
                        else {
                            console.error("addOrderLine api error: " + respData.error);
                            console.error("apiUrl: " + apiUrl);
                            resp = respData;
                        }
                    }
                    else {
                        console.error("addOrderLine api error no response.value: " + response);
                        console.error("apiUrl: " + apiUrl);
                        resp = response;
                    }

                } catch (error) {
                    console.log('Error: ' + error);
                }
            }
            else {
                //TODO: should not occur
                console.error("addOrderLine: null model - orderId: " + olData.orderId);
            }
        }
        else {
            //TODO: should not occur
            console.error("addOrderLine: no olData.orderId - propsOrderId: " + propsOrderId);
        }
        return resp;
    }

    async function getProductLineData() {

        //if ProductPrice and ProductPriceId passed in
        if (IdExists(propsProductPriceId) && IdExists(propsProductSkuId)) {
            //remove if lookup by only productPriceId
            if (IdExists(propsProductId)) {

                const endpoint = 'product/GetProductOptions/' +
                    propsProductId + '/' + propsProductPriceId + '/' + propsProductSkuId + '/' + propsProductStockId ?? 0;

                try {
                    const response = await get(endpoint);
                    //console.log('Response: ' + JSON.stringify(response));

                    if (response) {
                        createOrderLineModel(response);
                    }
                    else {
                        console.error("no Product OrderLine data");
                    }
                } catch (error) {
                    console.error('Error fetching Product OrderLine data:', error);
                }
            }
        }        
        else {
            //create empty model
            createOrderLineModel();
        }
    }

    function EnterSave(e) {
        if (e.key === "Enter") {
            PreventDefault(e);
            addLineAndNext(e, true);            
        }
    }

    function setUserFields() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        var cUserId = 0;
        if (userData) {
            cUserId = userData.userId;
            setUserId(userData.userId);
            setUser(userData);
        }
        return cUserId;
    }

    function customizeLine(e) {
        PreventDefault(e);

        setModalTitle("Add Custom Line");
        createProductCategoryList();
        createVendorSupplierList();

        olData.isCustom = true;
        setOlData(olData);
    }

    // ON LOAD
    useEffect(() => {

        setUserFields();
        createStoreList();

        if (IsCustomProduct) {            
            createProductCategoryList();
            createVendorSupplierList();
        }
        else {
            setShowAddLineForm(true);
        }

        getProductLineData();

    }, []);

    return (
        <div className="w-[650px] pb-4">
            {olData &&
                <div>
                    <div className="text-center">
                        <div className="font-bold mb-1 text-xl">
                            {modalTitle}
                            <input id="productId" type="hidden" value={olData.productId}></input>
                            <input id="productSkuId" type="hidden" value={olData.productSkuId}></input>
                            <input id="productPriceId" type="hidden" value={olData.productPriceId}></input>
                            <input id="productStockId" type="hidden" value={olData.productStockId}></input>
                            <input id="productHasBundles" type="hidden" value={olData.productHasBundles}></input>
                        </div>
                        {IsFalse(olData.isCustom) &&
                            <div>
                                <div>{olData.vendorName}</div>
                                <div>{olData.style}</div>
                                <div>{olData.color}</div>
                            </div>
                        }
                        <div className="float-right h-[35px]">                        
                            {IsFalse(olData.isCustom) && (olData.statusId === 0 || olData.statusId === 1) &&
                                <button type="button" id="" className="btn-submit-modal"
                                    onClick={(e) => customizeLine(e)}>
                                    Customize
                                </button>
                            }
                        </div>    
                    </div>
                    {/*Product fields*/}
                    {!showAddLineForm &&
                        <div className="text-center text-xl py-3 font-small">
                            <span className="pr-1">Loading...</span>
                            <span className="loading loading-spinner text-blue-600"></span>
                        </div>
                    }
                    {showAddLineForm &&
                        <div className="table clear">
                            {IsTrue(olData.isCustom) &&
                                <div>
                                    <div>
                                        <div className="po-col-1">
                                            Category
                                        </div>
                                        <div className="po-col-2">
                                            <select className="sp-sel mark"
                                                id="productCategoryId" name="productCategoryId"
                                                onKeyDown={(e) => EnterSave(e)}
                                                autoFocus={IsTrue(olData.isCustom)}
                                                onChange={handleChange}
                                                value={olData.productCategoryId}
                                            >
                                                {categoryList}
                                            </select>
                                            <span className={"text-red pl-1 " + requiredClass}>*</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="po-col-1">
                                            Supplier
                                        </div>
                                        <div className="po-col-2">
                                            <select className="sp-sel mark"
                                                id="vendorId" name="vendorId"
                                                onKeyDown={(e) => EnterSave(e)}
                                                value={olData.vendorId}
                                                onChange={handleChange}
                                            >
                                                {vendorList}
                                            </select>
                                            <span className={"text-red pl-1 " + requiredClass}>*</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="po-col-1">
                                            Style
                                        </div>
                                        <div className="po-col-2">
                                            <input type="text" className="mark inp-text-modal"
                                                id="style" name="style"
                                                onKeyUp={(e) => EnterSave(e)}
                                                onChange={handleChange}
                                                value={olData.style}
                                            />
                                            <span className={"text-red pl-1 " + requiredClass}>*</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="po-col-1">
                                            Color
                                        </div>
                                        <div className="po-col-2">
                                            <input type="text" className="mark inp-text-modal"
                                                id="color" name="color"
                                                onKeyUp={(e) => EnterSave(e)}
                                                onChange={handleChange}
                                                value={olData.color}
                                            />
                                            <span className={"text-red pl-1 " + requiredClass}>*</span>
                                        </div>
                                    </div>
                                </div>
                            }

                            {isRoll && 
                                <div>
                                    <div className="table-cell">
                                        <div className="po-col-1">
                                            Width
                                        </div>
                                        <div className="po-col-2">
                                            <input type="number" className="mark inp-quantity-modal w-[70px] pl-3 pr-1"
                                                id="width" name="width" disabled={IsFalse(olData.isCustom)}
                                                onKeyUp={(e) => EnterSave(e)}
                                                onChange={handleChange}
                                                value={width}
                                            />
                                        </div>
                                    </div>
                                    <div className="table-cell">
                                        <div className="po-col-1 !w-[70px] pl-5 pr-1">
                                            Length
                                        </div>
                                        <div className="po-col-2">
                                            <input type="number" className="mark inp-quantity-modal !w-[70px]"
                                                id="length" name="length"
                                                onKeyUp={(e) => EnterSave(e)}
                                                onChange={handleChange}
                                                value={length}
                                            />
                                            {IsFalse(olData.isCustom) && stdLength && stdLength !== "" &&
                                                <span className="pl-2 text-primary">
                                                    Std Length: {formatCurrencyDecimal(stdLength)} LF
                                                </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                            <div>
                                <div className="po-col-1">
                                    Quantity
                                </div>
                                <div className="po-col-2">
                                    <input type="number" className="inp-quantity-modal mark w-[70px] pl-3 pr-1"
                                        id="quantity" name="quantity"
                                        disabled={quantityDisabled}
                                        autoFocus={IsFalse(olData.isCustom)}
                                        onKeyUp={(e) => EnterSave(e)}
                                        onBlur={(e) => CheckMinQuantity(e)}
                                        onChange={handleChange}
                                        value={quantity}
                                    />
                                    <span className={"text-red pl-1 " + requiredClass}>*</span>

                                    {IsFalse(olData.isCustom) &&
                                        <span className="pl-1 text-primary">
                                            {NotStringEmpty(boxQtyStr) ? boxQtyStr : units}
                                        </span>
                                    }
                                    {IsTrue(olData.isStock) && //Equals(olData.statusId, 15) &&
                                        <span className="pl-8 ml-[3px] pb-[0px] text-primary">
                                            <span>Stock Qty: </span>
                                            {olData.quantityAvailable}
                                        </span>
                                    }
                                </div>

                                {IsTrue(olData.isCustom) &&
                                    <div className="table-cell">
                                        <div className="table-cell">
                                            <div className="po-col-1 !w-[50px] pl-5 pr-2">
                                                Units
                                            </div>
                                            <div className="po-col-2">
                                                <select id="units" name="units"
                                                    className="sp-sel !w-[60px]"
                                                    onKeyDown={(e) => EnterSave(e)}
                                                    value={units}
                                                    onChange={handleChange}
                                                >
                                                    {unitsList}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="table-cell">
                                            <div className="table-cell">
                                                <div className="po-col-1 !w-[50px] pl-6 pr-1">
                                                    Cost
                                                </div>
                                                <div className="po-col-2 pr-2">
                                                    <span className="sp-cost-pad p-0">$</span>
                                                    <input type="number" className="inp-quantity-modal mark !w-[70px]"
                                                        id="cost" name="cost"
                                                        onKeyUp={(e) => EnterSave(e)}
                                                        onChange={handleChange}
                                                        value={olData.cost}
                                                    />
                                                </div>
                                            </div>
                                            <div className="table-cell">
                                                <div className="po-col-1 !w-[50px] pl-4 pr-1">
                                                    Multiplier
                                                </div>
                                                <div className="po-col-2">
                                                    <input type="number" className="inp-quantity-modal mark !w-[60px]"
                                                        id="multiplier" name="multiplier"
                                                        onKeyUp={(e) => EnterSave(e)}
                                                        onChange={handleChange}
                                                        value={olData.multiplier}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div>
                                <div className="table-cell">
                                    <div className="po-col-1">
                                        Price
                                    </div>
                                    <div className="po-col-2">
                                        <span className="sp-cost-pad p-0 ml-[-8px]">$</span>
                                        <input type="number" className="inp-quantity-modal mark w-[70px] pl-3 pr-1"
                                            id="linePrice" name="linePrice" disabled={priceDisabled}
                                            onKeyUp={(e) => EnterSave(e)}
                                            onChange={handleChange}
                                            value={linePrice}
                                        />
                                        <span className="pl-1 font-bold text-primary">{propsPriceLevelStr}</span>
                                    </div>
                                </div>

                                <div className="table-cell">
                                    <div className="po-col-1 !w-[80px] pl-8 pr-0">
                                        Total
                                    </div>
                                    <div className="po-col-2">
                                        <span className="sp-cost-pad ml-[-12px] p-0">$</span>
                                        <input type="number" className="inp-quantity-modal mark !w-[70px]"                                            
                                            id="total" name="total" disabled
                                            value={formatCurrencyDecimal(lineTotal)}
                                        />
                                    </div>
                                </div>                                

                                {/** CFM 4-16-24: dont show Referral **/}
                                {/*{IsTrue(olData.isCustom) &&*/}
                                {/*    <div className="table-cell">*/}
                                {/*        <div className="po-col-1 !w-[70px] pl-3 pr-1">*/}
                                {/*            Referral*/}
                                {/*        </div>*/}
                                {/*        <div className="po-col-2">*/}
                                {/*            <span className="sp-cost-pad ml-[-4px]">$</span>*/}
                                {/*            <input type="number" className="inp-quantity-modal mark !w-[70px]"*/}
                                {/*                id="referral" name="referral" disabled*/}
                                {/*                value={formatCurrencyDecimal(referral)}*/}
                                {/*            />*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*}*/}
                            </div>

                            {IsTrue(olData.isCustom) && NotEmpty(boxQtyStr) &&
                                <div>
                                    <div className="po-col-1">
                                        Box Qty
                                    </div>
                                    <div className="po-col-2">
                                        <span>
                                            {/*<span className="pl-3">{olData.boxQty}</span>*/}
                                            <span className="text-primary">{boxQtyStr}</span>
                                        </span>
                                    </div>
                                </div>
                            }

                            <div>
                                <div className="po-col-1">
                                    Comments
                                </div>
                                <div className="po-col-2">
                                    <input type="text" className="mark inp-text-modal"
                                        id="comments" name="comments" maxLength="50"
                                        onKeyDown={(e) => EnterSave(e)}
                                        onChange={handleChange}
                                        value={olData.comments}
                                    />
                                </div>
                            </div>

                            <div>
                                <div className="po-col-1">
                                    Ship To Location
                                </div>
                                <div className="po-col-2">
                                    <select id="warehouseId" name="warehouseId"
                                        className="sp-sel mark"
                                        onKeyDown={(e) => EnterSave(e)}
                                        value={olData.warehouseId}
                                        onChange={handleChange}
                                    >
                                        {storeList}
                                    </select>
                                    {/*<span className={"text-sm text-red-600 " + storeRequiredHidden}>*/}
                                    {/*    *Store is required*/}
                                    {/*</span>*/}
                                </div>
                            </div>

                            <div>
                                <div className="po-col-1">
                                    Status
                                </div>
                                <div className="po-col-2">
                                    <select className="sp-sel mark"
                                        id="statusId" name="statusId"
                                        onKeyDown={(e) => EnterSave(e)}
                                        onChange={handleChange}
                                        value={olData.statusId}
                                    >
                                        <option value="0">None</option>

                                        {propsOrderTypeStr !== "Quote" &&
                                            <option value="1">To Be Ordered</option>
                                        }

                                        {/*{propsOrderTypeStr === "Quote" &&*/}
                                        {/*    <option value="13">Quote</option>*/}
                                        {/*}*/}

                                        {propsOrderTypeStr !== "Quote" && IdExists(propsProductStockId) &&
                                            <option value="15">Stock Item</option>
                                        }
                                    </select>
                                </div>
                            </div>

                            {GreaterThan(olData.pcWeight, 0) &&
                                <div>
                                    <div className="table-cell">
                                        <div className="po-col-1">
                                            Weight
                                        </div>
                                        <div className="po-col-2">
                                            <span className="text-primary">
                                                {formatCurrencyDecimal(olData.pcWeight * quantity)}
                                                <span> lbs</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            }

            <div className="text-center text-red text-[16px] py-3">
                <div className="h-[22px]">{message}</div>
            </div>
            <div className="align-bottom text-right pt-[10px]">
                <button type="button" className="btn-submit-modal mr-2"
                    disabled={submitDisabled}
                    onClick={(e) => addLineAndNext(e, true)}>
                    Add & Next
                </button>
                <button type="button" className="btn-submit-modal mr-2"
                    disabled={submitDisabled}
                    onClick={(e) => addLineAndNext(e, false)}>
                    Add & Close
                </button>
                <button type="button" className="btn-cancel-modal"
                    disabled={closeDisabled}
                    onClick={(e) => onCloseModal(e, false)}>
                    Cancel
                </button>
            </div>

            {/**** Min Quantity Modal ****/}
            <div className="">
                <Modal ref={qtyModal} className="px-3">
                    <Modal.Body>
                        {qtyModalContent}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
}
