import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { get } from '../../services/apiService';
import { SetPageTitle } from '../../js/helpers';
import { SetDocumentTitle } from '../_reactHelpers/ReactHelpers';
import StoreAllocationGroupForm from './StoreAllocationGroupForm';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

export function StoreAllocations() {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Store Allocations");
    const [allocationGroups, setAllocationGroups] = useState([]);
    const [currentGroup, setCurrentGroup] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [gridApi, setGridApi] = useState(null);
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
    }, []);

    const onFilterTextBoxChanged = useCallback(() => {
        gridApi.setQuickFilter(
            document.getElementById('filter-text-box').value
        );
    }, [gridApi]);

    const openModal = (group = null) => {
        setCurrentGroup(group);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setCurrentGroup(null);
        setIsModalOpen(false);
    };

    const handleGroupSaved = () => {
        closeModal();
        fetchAllocationGroups();
    };

    const defaultColDef = useMemo(() => ({
        minWidth: 100,
        flex: 1,
        sortable: true,
        resizable: true,
        filter: true,
        suppressMovable: true,
        suppressMenu: false,
        cellClass: ["no-border"],
        menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab",],
    }), []);

    const columnDefs = useMemo(() => [
        { field: 'groupId', headerName: 'Group ID', hide: true },
        { field: 'groupName', headerName: 'Allocation Group' },
        {
            field: 'allocationItems', hide: true,
            headerName: 'Stores',
            valueFormatter: params => params.value.map(item => `${item.storeName} (${item.allocationPercent}%)`).join(', ')
        },
        {
            headerName: '',
            maxWidth: 100,
            cellRenderer: params => (
                <button onClick={() => openModal(params.data)} className="btn btn-small">Edit</button>
            ),
        },
    ], []);

    async function fetchAllocationGroups() {
        try {
            const response = await get("/storeallocation/GetStoreAllocationGroups");
            setAllocationGroups(response);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching store allocation groups:", error);
            setAllocationGroups([]);
            setLoading(false);
        }
    }

    useEffect(() => {
        SetPageTitle("Store Allocations");
        fetchAllocationGroups();
    }, []);

    if (loading) {
        return <div className="text-center text-xl mt">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }

    return (
        <div className="page-wrapper">
            <div className="flex-wrapper my-2">
                <div className="flex-1">
                    <input type="text" id="filter-text-box" placeholder="Filter..."
                        className="grid-filter"
                        onInput={onFilterTextBoxChanged}
                    />
                </div>
                <div className="flex-end">
                    <button onClick={() => openModal()} className="btn-small">
                        Add Allocation Group
                    </button>
                </div>
            </div>

            <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
                <AgGridReact
                    rowData={allocationGroups}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    pagination={true}
                    paginationPageSize={30}
                />
            </div>

            {isModalOpen && (
                <div className="modal-wrapper">
                    <div className="modal-content">
                        <StoreAllocationGroupForm
                            groupData={currentGroup}
                            onGroupSaved={handleGroupSaved}
                            onClose={closeModal}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}