import React, { useState, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetDateNow, GetInputDate } from '../../js/formHelpers';
import { GetValueById } from '../../js/helpers';
import { EntityAutocomplete } from '../_reactHelpers/EntityAutocomplete';
import { post, get } from '../../services/apiService';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

export function PostDeposits() {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        bankId: '',
        date: GetInputDate(),
        description: '',
        status: 'Open',
        transactionType: 'deposit',
        isSalesIncome: true,
        amount: 0,
        registerId: '',
        selectedPayments: [],
        allocationType: 'Manual',
        storeId: '',
        allocationGroupId: '',
        comment: '',
        allocations: [],
        depositRegisterId: ''
    });
    const [statusMessage, setStatusMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [depositRegisters, setDepositRegisters] = useState([]);
    const [payments, setPayments] = useState([]);
    const [banks, setBanks] = useState([]);
    const [displayAmount, setDisplayAmount] = useState('');
    const [isAnyRowSelected, setIsAnyRowSelected] = useState(false);
    const [stores, setStores] = useState([]);
    const [allocationGroups, setAllocationGroups] = useState([]);
    const [manualAllocations, setManualAllocations] = useState([]);
    const [groupAllocations, setGroupAllocations] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [allocations, setAllocations] = useState([]);
    const [allocationGlCode, setAllocationGlCode] = useState('');
    const [glCodes, setGlCodes] = useState([]);
    const [gridApi, setGridApi] = useState(null);

    const fetchGlCodes = async () => {
        try {
            const response = await get("/depositregisters/GetGLCodes");
            setGlCodes(response || []);
        } catch (error) {
            console.error('Error fetching GL Codes:', error);
            setStatusMessage('Error fetching GL Codes');
        }
    };

    const fetchGroupAllocations = useCallback((groupId) => {
        const selectedGroup = allocationGroups.find(group => group.groupId.toString() === groupId);
        if (selectedGroup && selectedGroup.allocationItems) {
            const allocations = selectedGroup.allocationItems.map(item => ({
                storeId: item.storeId,
                storeName: item.storeName,
                allocationPercent: parseFloat(item.allocationPercent),
                amount: (item.allocationPercent / 100) * formData.amount,
                isSelected: true
            }));
            setGroupAllocations(allocations);
        } else {
            console.error('Selected group not found or has no allocation items');
            setStatusMessage('Error: Selected group not found or has no allocation items');
            setGroupAllocations([]);
        }
    }, [allocationGroups, formData.amount]);

    useEffect(() => {
        fetchBanks();
        fetchDepositRegisters();
        fetchStores();
        fetchAllocationGroups();
        fetchGlCodes();
    }, []);

    useEffect(() => {
        if (formData.allocationType === 'Allocations' && formData.allocationGroupId) {
            fetchGroupAllocations(formData.allocationGroupId);
        }
    }, [formData.amount, formData.allocationType, formData.allocationGroupId, fetchGroupAllocations]);

    useEffect(() => {
        if (formData.allocationType === 'Allocations' && groupAllocations.length > 0) {
            const updatedAllocations = groupAllocations.map(alloc => ({
                ...alloc,
                amount: (alloc.allocationPercent / 100) * formData.amount
            }));
            setGroupAllocations(updatedAllocations);
        }
    }, [formData.amount, formData.allocationType, groupAllocations]);

    const fetchBanks = async () => {
        try {
            const response = await get('bankaccount/GetAllBankAccounts');
            setBanks(response || []);
            setIsError(false);
        } catch (error) {
            setStatusMessage('Error fetching banks');
            setIsError(true);
        }
    }

    const fetchStores = async () => {
        try {
            const response = await get("/store/GetAllStores");
            setStores(response || []);
            setIsError(false);
        } catch (error) {
            setStatusMessage("Error fetching stores");
            setIsError(true);
        }
    };

    const fetchAllocationGroups = async () => {
        try {
            const response = await get('storeallocation/GetStoreAllocationGroups');
            setAllocationGroups(response);
        } catch (error) {
            console.error('Error fetching allocation groups:', error);
            setStatusMessage('Error fetching allocation groups');
        }
    };

    const fetchDepositRegisters = async () => {
        try {
            const response = await get(`depositregisters/GetDepositRegisters`);
            setDepositRegisters(response);
        } catch (error) {
            console.error('Error fetching deposit registers:', error);
            setStatusMessage('Error fetching deposit registers');
        }
    };

    const fetchPayments = useCallback(async (depositRegisterId) => {
        try {
            const response = await get(`/payments/GetUnassignedPayments/${depositRegisterId}`);
            const paymentsWithSelection = response.map(payment => ({ ...payment, isSelected: true }));
            setPayments(paymentsWithSelection);
            setFormData(prevState => ({
                ...prevState,
                selectedPayments: paymentsWithSelection.map(p => p.paymentId)
            }));
        } catch (error) {
            setStatusMessage('Error fetching payments');
            console.error('Error fetching payments:', error);
        }
    }, []);

    const handleChange = useCallback((e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;

        setFormData(prevState => ({ ...prevState, [name]: newValue }));

        if (name === 'depositRegisterId') {
            fetchPayments(value);
        }

        if (name === 'allocationGroupId') {
            fetchGroupAllocations(value);
        }
    }, [fetchPayments, fetchGroupAllocations]);

    const handleContinue = (e) => {
        e.preventDefault();
        if (step === 1) {
            if (formData.isSalesIncome) {
                setStep(2);
            } else {
                setStep(3);
            }
        } else if (step === 2) {
            handleSubmit(e);
        }
    };

    const handleBack = () => {
        if (step === 2 || step === 3) {
            setStep(1);
        }
    };

    const handleManualAllocationChange = (params) => {
        const { data, colDef, newValue } = params;
        const updatedAllocations = manualAllocations.map(allocation =>
            allocation === data ? { ...allocation, [colDef.field]: newValue } : allocation
        );
        setManualAllocations(updatedAllocations);
    };

    const updateAllocations = useCallback((newTotalAmount) => {
        setAllocations(prevAllocations =>
            prevAllocations.map(allocation => ({
                ...allocation,
                amount: (allocation.allocationPercent / 100) * newTotalAmount
            }))
        );
    }, []);

    const handleAmountChange = (e) => {
        const value = e.target.value.replace(/[^0-9.]/g, '');
        const parts = value.split('.');
        if (parts.length > 1) {
            parts[1] = parts[1].slice(0, 2);
        }
        const formattedValue = parts.join('.');
        setDisplayAmount(formattedValue);
        const newAmount = parseFloat(formattedValue) || 0;
        setFormData(prevState => ({ ...prevState, amount: newAmount }));
        setTotalAmount(newAmount);
        updateAllocations(newAmount);
    };

    const handleAllocationChange = useCallback((params) => {
        const { data, colDef, newValue } = params;
        setAllocations(prevAllocations => {
            return prevAllocations.map(allocation =>
                allocation.id === data.id
                    ? { ...allocation, [colDef.field]: colDef.field === 'glCode' ? newValue : colDef.field === 'displayName' ? parseInt(newValue, 10) : newValue }
                    : allocation
            );
        });
    }, []);

    const handleAllocationGlCodeChange = (selectedItem) => {
        const newGlCode = selectedItem.glAccountCode;
        setAllocationGlCode(newGlCode);
        if (gridApi) {
            gridApi.forEachNode(node => {
                node.setDataValue('glCode', newGlCode);
            });
            gridApi.refreshCells({ force: true, columns: ['glCode'] });
        }
    };

    const handleGroupAllocationChange = useCallback((params) => {
        const { data, colDef, newValue } = params;
        setGroupAllocations(prevAllocations => {
            const updatedAllocations = prevAllocations.map(allocation =>
                allocation.storeId === data.storeId ? { ...allocation, [colDef.field]: newValue } : allocation
            );

            const totalPercent = updatedAllocations.reduce((sum, alloc) => sum + parseFloat(alloc.allocationPercent), 0);
            return updatedAllocations.map(alloc => ({
                ...alloc,
                allocationPercent: parseFloat(alloc.allocationPercent),
                amount: (parseFloat(alloc.allocationPercent) / totalPercent) * formData.amount
            }));
        });
    }, [formData.amount]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const selectedAllocations = formData.allocationType === 'Manual'
                ? allocations
                : groupAllocations.filter(alloc => alloc.isSelected);

            const selectedGroup = allocationGroups.find(group => group.groupId.toString() === formData.allocationGroupId);
            const allocationName = selectedGroup ? selectedGroup.groupName : '';

            const submitData = {
                bankId: Number(formData.bankId),
                date: formData.date,
                description: formData.description,
                status: formData.status,
                transactionType: formData.transactionType,
                isSalesIncome: formData.isSalesIncome,
                amount: parseFloat(formData.amount),
                selectedPayments: formData.selectedPayments,
                allocationType: formData.allocationType,
                allocationGroupId: formData.allocationGroupId,
                allocationName: allocationName,
                comment: formData.comment,
                allocations: selectedAllocations.map(alloc => ({
                    storeId: parseInt(alloc.storeId),
                    glCode: formData.allocationType === 'Allocations' ? allocationGlCode : alloc.glCode,
                    amount: parseFloat(alloc.amount)
                })),
                allocationGlCode: formData.allocationType === 'Allocations' ? allocationGlCode : null
            };

            console.log('Submitting data:', submitData);
            const response = await post('/bankregister/CreateBankRegisterAndDeposits', submitData);
            console.log('Server response:', response);
            setStatusMessage('Bank Register Added Successfully');
            setIsError(false);
            window.location.reload();
        } catch (error) {
            console.error('Error adding Bank Register:', error);
            setStatusMessage('Error adding Bank Register: ' + (error.response?.data || error.message));
            setIsError(true);
        }
    };

    const isRowSelectable = useCallback(() => true, []);

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
    }, []);

    const columnDefs = useMemo(() => [
        {
            headerName: '',
            checkboxSelection: true,
            headerCheckboxSelection: true,
            width: 40,
            pinned: 'left',
        },
        { field: 'paymentId', headerName: 'Payment ID', width: 120 },
        { field: 'depositRegisterId', headerName: 'Deposit Register ID', width: 180, hide: true },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 120,
            valueFormatter: params => `$${params.value.toFixed(2)}`
        },
        {
            field: 'paymentDate',
            headerName: 'Payment Date',
            width: 150,
            valueFormatter: params => new Date(params.value).toLocaleDateString()
        }
    ], []);

    const handleRemoveManualAllocation = useCallback((event, id) => {
        event.preventDefault();
        event.stopPropagation();

        setAllocations(prevAllocations => {
            const updatedAllocations = prevAllocations.filter(allocation => allocation.id !== id);
            return updatedAllocations;
        });
    }, []);

    const AmountCellRenderer = (props) => {
        const [amount, setAmount] = useState(props.getValue(props.node).toFixed(2));

        const handleChange = (e) => {
            let value = e.target.value.replace(/[^0-9.]/g, '');
            setAmount(value);
        };

        const handleBlur = () => {
            const formattedAmount = parseFloat(amount).toFixed(2);
            setAmount(formattedAmount);
            props.setValue(props, parseFloat(formattedAmount));
        };

        return (
            <div className="flex items-center">
                <span className="text-gray-500 mr-1">$</span>
                <input
                    type="text"
                    value={amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full p-1 border-none focus:outline-none focus:ring-0"
                />
            </div>
        );
    };

    const GlCodeCellRenderer = (props) => {
        const handleSelect = (selectedOption) => {
            props.setValue(selectedOption.glAccountCode);
            setFormData(prevData => ({
                ...prevData,
                glCode: selectedOption.glAccountCode,
            }));
        };

        return (
            <div style={{ position: 'relative', zIndex: 1000 }}>
                <EntityAutocomplete
                    name="glCode"
                    id={`glCode-${props.rowIndex}`}
                    onSelect={handleSelect}
                    onChange={() => { }}
                    initialEntityId={props.value}
                    entityType="GlCode"
                />
            </div>
        );
    };

    const StoreCellEditor = forwardRef((props, ref) => {
        const [value, setValue] = useState(props.value);

        useImperativeHandle(ref, () => ({
            getValue() {
                return value;
            }
        }));

        const handleChange = (event) => {
            setValue(event.target.value);
        };

        return (
            <select
                value={value}
                onChange={handleChange}
                style={{ width: '100%' }}
            >
                <option value="">Select Store</option>
                {stores.map(store => (
                    <option key={store.storeId} value={store.storeId.toString()}>
                        {store.displayName}
                    </option>
                ))}
            </select>
        );
    });

    const manualAllocationColumnDefs = useMemo(() => [
        {
            field: 'storeId',
            headerName: 'Store',
            editable: true,
            cellEditor: StoreCellEditor,
            cellEditorPopup: false,
            valueFormatter: params => {
                const store = stores.find(store => store.storeId.toString() === params.value);
                return store ? store.displayName : '';
            },
        },
        {
            field: 'glCode',
            headerName: 'G/L Code',
            cellRenderer: GlCodeCellRenderer,
            cellRendererParams: {
                getValue: (params) => params.data.glCode,
                setValue: (params, value) => {
                    params.data.glCode = value;
                    params.api.refreshCells({ rowNodes: [params.node], columns: ['glCode'] });
                }
            },
        },
        {
            field: 'amount',
            headerName: 'Amount',
            editable: true,
            cellRenderer: AmountCellRenderer,
            cellRendererParams: {
                getValue: (params) => params.data.amount,
                setValue: (params, value) => {
                    params.data.amount = value;
                    params.api.refreshCells({ rowNodes: [params.node], columns: ['amount'] });
                }
            },
            valueSetter: (params) => {
                const newValue = params.newValue;
                params.data.amount = newValue;
                return true;
            },
            valueFormatter: params => `$${parseFloat(params.value).toFixed(2)}`,
        },
        {
            headerName: '',
            field: 'remove',
            editable: false,
            cellRenderer: params => (
                <button
                    onClick={(event) => handleRemoveManualAllocation(event, params.data.id)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                    type="button"
                >
                    Remove
                </button>
            )
        }
    ], [stores, handleRemoveManualAllocation]);

    const handleAddManualAllocation = useCallback(() => {
        const newAllocation = {
            id: Date.now(),
            storeId: '',
            glCode: '',
            amount: 0
        };
        setAllocations(prevAllocations => {
            const updatedAllocations = [...prevAllocations, newAllocation];
            setTimeout(() => {
                if (gridApi) {
                    const rowIndex = updatedAllocations.length - 1;
                    const rowNode = gridApi.getDisplayedRowAtIndex(rowIndex);
                    gridApi.setFocusedCell(rowIndex, 'storeId');
                    gridApi.startEditingCell({
                        rowIndex: rowIndex,
                        colKey: 'storeId'
                    });
                }
            }, 0);
            return updatedAllocations;
        });
    }, [gridApi]);

    const groupAllocationColumnDefs = useMemo(() => [
        { field: 'storeName', headerName: 'Store' },
        {
            field: 'allocationPercent',
            headerName: 'Allocation %',
            editable: true,
            valueFormatter: params => `${params.value.toFixed(4)}%`
        },
        {
            field: 'glCode',
            headerName: 'G/L Code',
            valueGetter: () => allocationGlCode,
            valueFormatter: params => {
                const code = glCodes.find(code => code.glAccountCode === params.value);
                return code ? code.glAccountCode : '';
            }
        },
        {
            field: 'amount',
            headerName: 'Amount',
            valueFormatter: params => `$${params.value.toFixed(2)}`,
            valueGetter: params => (params.data.allocationPercent / 100) * formData.amount
        },
    ], [formData.amount, allocationGlCode, glCodes]);

    const onSelectionChanged = useCallback((event) => {
        const selectedRows = event.api.getSelectedRows();
        const selectedPaymentIds = selectedRows.map(row => row.paymentId);
        setFormData(prevState => ({ ...prevState, selectedPayments: selectedPaymentIds }));
        setIsAnyRowSelected(selectedRows.length > 0);
    }, []);

    const onAllocationSelectionChanged = useCallback((event) => {
        const updatedAllocations = event.api.getModel().rowsToDisplay.map(row => ({
            ...row.data,
            isSelected: row.selected
        }));
        setGroupAllocations(updatedAllocations);
    }, []);

    const renderStep1 = () => (
        <div className="max-w-2xl mx-auto p-4 bg-white">
            <h2 className="text-2xl font-bold mb-4 text-center">Post Deposit</h2>
            <form onSubmit={handleContinue} className="space-y-4">
                <div className="flex space-x-4">
                    <div className="flex-1">
                        <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
                        <input
                            type="date"
                            id="date"
                            name="date"
                            value={formData.date}
                            onChange={handleChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            required
                        />
                    </div>
                    <div className="flex-1">
                        <label htmlFor="bankId" className="block text-sm font-medium text-gray-700">Bank Account</label>
                        <select
                            id="bankId"
                            name="bankId"
                            value={formData.bankId || ''}
                            onChange={handleChange}
                            required
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        >
                            <option value="">Select</option>
                            {banks && banks.map(bank => (
                                <option key={bank.bankId} value={bank.bankId.toString()}>
                                    {`${bank.bankName}`}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div>
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                    <input
                        type="text"
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        required
                    />
                </div>
                <div className="flex space-x-4">
                    <div className="flex-1">
                        <label htmlFor="status" className="block text-sm font-medium text-gray-700">Status</label>
                        <select
                            id="status"
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        >
                            <option value="Open">Open</option>
                            <option value="Cleared">Cleared</option>
                            <option value="Voided">Voided</option>
                        </select>
                    </div>
                    <div className="flex-1">
                        <label htmlFor="transactionType" className="block text-sm font-medium text-gray-700">Transaction Type</label>
                        <select
                            id="transactionType"
                            name="transactionType"
                            value={formData.transactionType}
                            onChange={handleChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            disabled
                        >
                            <option value="deposit">Deposit</option>
                        </select>
                    </div>
                </div>
                <div>
                    <label htmlFor="isSalesIncome" className="flex items-center">
                        <input
                            type="checkbox"
                            id="isSalesIncome"
                            name="isSalesIncome"
                            checked={formData.isSalesIncome}
                            onChange={handleChange}
                            className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 mr-2"
                        />
                        <span className="text-sm font-medium text-gray-700">Is Sales Income</span>
                    </label>
                </div>
                <div className="flex justify-end space-x-2">
                    <button type="button" onClick={() => navigate(-1)} className="btn-cancel">Cancel</button>
                    <button type="submit" className="btn-submit">Continue</button>
                </div>
            </form>
        </div>
    );

    const renderStep2 = () => (
        <div className="max-w-2xl mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4 text-center">Post Deposit - Sales Income Details</h2>
            <form onSubmit={handleContinue} className="space-y-4">
                <div className="flex space-x-4">
                    <div className="flex-1">
                        <label htmlFor="amount" className="block text-sm font-medium text-gray-700">Amount</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm">$</span>
                            </div>
                            <input
                                type="text"
                                id="amount"
                                name="amount"
                                value={displayAmount}
                                onChange={handleAmountChange}
                                onBlur={() => setDisplayAmount(parseFloat(displayAmount || 0).toFixed(2))}
                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                                placeholder="0.00"
                                aria-describedby="price-currency"
                            />
                        </div>
                    </div>
                    <div className="flex-1">
                        <label htmlFor="depositRegisterId" className="block text-sm font-medium text-gray-700">Deposit Register</label>
                        <select
                            id="depositRegisterId"
                            name="depositRegisterId"
                            value={formData.depositRegisterId || ''}
                            onChange={handleChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        >
                            <option value="">Select</option>
                            {depositRegisters && depositRegisters.map(register => (
                                <option key={register.depositRegisterId} value={register.depositRegisterId.toString()}>
                                    {`${register.registerName}`}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div>
                    <label htmlFor="comment" className="block text-sm font-medium text-gray-700">Comment</label>
                    <textarea
                        id="comment"
                        name="comment"
                        value={formData.comment}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        rows="3"
                    ></textarea>
                </div>

                {payments.length > 0 && (
                    <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                        <AgGridReact
                            rowData={payments}
                            columnDefs={columnDefs}
                            defaultColDef={{
                                flex: 1,
                                minWidth: 100,
                                resizable: true
                            }}
                            onSelectionChanged={onSelectionChanged}
                            rowSelection="multiple"
                            suppressRowClickSelection={true}
                            onGridReady={onGridReady}
                            getRowId={params => params.data.paymentId.toString()}
                            isRowSelectable={() => true}
                            rowMultiSelectWithClick={true}
                            suppressRowDeselection={true}
                        />
                    </div>
                )}

                <div className="flex justify-end space-x-2">
                    <button type="button" onClick={handleBack} className="btn-cancel">Back</button>
                    <button
                        type="submit"
                        className={`btn-submit ${!isAnyRowSelected ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={!isAnyRowSelected}
                    >
                        Add Deposit
                    </button>
                </div>
            </form>
        </div>
    );

    const renderStep3 = () => (
        <div className="max-w-2xl mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4 text-center">Post Deposit - Non-Sales Details</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block mb-2">Allocation Type</label>
                    <div>
                        <label className="inline-flex items-center mr-4">
                            <input
                                type="radio"
                                name="allocationType"
                                value="Manual"
                                checked={formData.allocationType === 'Manual'}
                                onChange={handleChange}
                                className="form-radio"
                            />
                            <span className="ml-2">Manual</span>
                        </label>
                        <label className="inline-flex items-center">
                            <input
                                type="radio"
                                name="allocationType"
                                value="Allocations"
                                checked={formData.allocationType === 'Allocations'}
                                onChange={handleChange}
                                className="form-radio"
                            />
                            <span className="ml-2">Allocations</span>
                        </label>
                    </div>
                </div>

                <div className="flex space-x-4">
                    <div className="flex-1">
                        <label htmlFor="amount" className="block text-sm font-medium text-gray-700">Amount</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm">$</span>
                            </div>
                            <input
                                type="text"
                                id="amount"
                                name="amount"
                                value={displayAmount}
                                onChange={handleAmountChange}
                                onBlur={() => setDisplayAmount(parseFloat(displayAmount || 0).toFixed(2))}
                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                                placeholder="0.00"
                                aria-describedby="price-currency"
                            />
                        </div>
                    </div>
                    {formData.allocationType === 'Allocations' && (
                        <div className="flex-1">
                            <label htmlFor="allocationGlCode" className="block text-sm font-medium text-gray-700">G/L Account</label>
                            <EntityAutocomplete
                                name="allocationGlCode"
                                id="allocationGlCode"
                                onSelect={(selectedItem) => setAllocationGlCode(selectedItem.glAccountCode)}
                                onChange={handleAllocationGlCodeChange}
                                initialEntityId={allocationGlCode}
                                entityType="GlCode"
                            />
                        </div>
                    )}
                </div>

                <div>
                    <label htmlFor="comment" className="block text-sm font-medium text-gray-700">Comment</label>
                    <textarea
                        id="comment"
                        name="comment"
                        value={formData.comment}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        rows="3"
                    ></textarea>
                </div>

                {formData.allocationType === 'Manual' ? (
                    <>
                        <button
                            type="button"
                            onClick={handleAddManualAllocation}
                            className="mt-4 bg-blue-500 text-white p-2 rounded"
                        >
                            Add Allocation
                        </button>

                        <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                            {/* MANUAL ALLOCATION */}
                            <AgGridReact
                                rowData={allocations}
                                columnDefs={manualAllocationColumnDefs}
                                defaultColDef={{
                                    flex: 1,
                                    minWidth: 100,
                                    resizable: true,
                                    editable: true
                                }}
                                onCellValueChanged={handleAllocationChange}
                                onGridReady={params => {
                                    params.api.sizeColumnsToFit();
                                }}
                                getRowId={params => params.data.id.toString()}
                                components={{
                                    glCodeCellRenderer: GlCodeCellRenderer,
                                }}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div>
                            <label htmlFor="allocationGroupId" className="block mb-2">Allocation Group</label>
                            <select
                                id="allocationGroupId"
                                name="allocationGroupId"
                                value={formData.allocationGroupId}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            >
                                <option value="">Select Allocation Group</option>
                                {allocationGroups.map(group => (
                                    <option key={group.groupId} value={group.groupId.toString()}>{group.groupName}</option>
                                ))}
                            </select>
                            </div>
                        {groupAllocations.length > 0 && (
                                <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                                    {/* GROUP ALLOCATION */}
                                    <AgGridReact
                                        rowData={groupAllocations}
                                        columnDefs={groupAllocationColumnDefs}
                                        defaultColDef={{
                                            flex: 1,
                                            minWidth: 100,
                                            editable: false,
                                            resizable: true
                                        }}
                                        onCellValueChanged={handleGroupAllocationChange}
                                        suppressRowTransform={true}
                                        getRowId={params => params.data.storeId}
                                        onSelectionChanged={onAllocationSelectionChanged}
                                        suppressRowClickSelection={true}
                                        onGridReady={params => {
                                            params.api.sizeColumnsToFit();
                                            params.api.forEachNode(node => node.setSelected(true));
                                        }}
                                        rowSelection="multiple"
                                        isRowSelectable={() => true}
                                        rowMultiSelectWithClick={true}
                                        suppressRowDeselection={true}
                                    />
                            </div>
                        )}
                    </>
                )}

                <div className="flex justify-end space-x-2">
                    <button type="button" onClick={handleBack} className="btn-cancel">Back</button>
                    <button type="submit" className="btn-submit">Add Deposit</button>
                </div>
            </form>
        </div>
    );

    return (
        <div className="container mx-auto p-4">
            {step === 1 && renderStep1()}
            {step === 2 && renderStep2()}
            {step === 3 && renderStep3()}

            {statusMessage && (
                <div className={`p-4 rounded-md ${isError ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'}`}>
                    {statusMessage}
                </div>
            )}
        </div>
    );
}
