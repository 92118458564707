import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Modal, Button } from 'react-daisyui'

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import {
    AgCurrencyMxWd, AgDateMxWdSm, defaultErrMsg, GetDateNow, GetDisplayCurrencyStr,
    GetDisplayDateStr,
    IsFalse,
    IsTrue
} from '../../js/formHelpers';

import { get, post } from '../../services/apiService';
import { SetDocumentTitle } from '../_reactHelpers/ReactHelpers';
import { SetPageTitle } from '../../js/helpers';
import { IdExists, NotStringEmpty, StringEmpty } from '../../js/formHelpers';

import { DateFilter } from '../_reactHelpers/DateFilter';
import RadioButtonList from '../_reactHelpers/RadioButtonList';
import { AddEditSupplierBillModal } from './AddEditSupplierBillModal';
import { AddEditNonSupplierBillModal } from './AddEditNonSupplierBillModal';

export const SearchPayables = (props) => {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina -");
    const isPayables = props.pageType === "Payables"
    const isCommissions = props.pageType === "Commissions"

    const [isModalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const [stores, setStores] = useState([]);

    const [bills, setBills] = useState([]);
    const [billIdToDelete, setBillIdToDelete] = useState(null);

    const rblClassName = "rblDateType";
    const dateTypes = setDateTypes();
    const [dateType, setDateType] = useState("BillDate");

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [messageClass, setMessageClass] = useState('');
        
    const initialFormData = {
        storeId: 0,
        vendorId: 0,
        billType: 0,
        billDate: GetDateNow(),
        billStatus: 0,
        dueDate: '',
        poNumber: '',
        billNumber: '',
        billTotal: 0,
        discRate: 0,
        discAmt: 0,
        nonDiscAmt: 0,
        adjustedTotal: 0,
        allocationGroupId: 0,
        billLineItems: []
    };

    function setDateTypes() {
        var types = ["Bill Date", "Due Date", "Created Date"];
        if (IsFalse(isCommissions)) {
            types.push("Paid Date");
        }
        return types;
    }

    const [gridApi, setGridApi] = useState(null);
    const [searchText, setSearchText] = useState("");
    const gridRef = useRef();

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const handleEditClick = async (bill) => {
        try {
            const response = await get(`bills/GetBill/${bill.billId}`);
            const fullBill = response;
            console.log('Full bill:', fullBill);

            //fullBill.vendorId = fullBill.vendor ? fullBill.vendor.vendorId : fullBill.vendorId;
            //fullBill.billDate = fullBill.billDate ? new Date(fullBill.billDate).toISOString().split('T')[0] : '';
            //fullBill.dueDate = fullBill.dueDate ? new Date(fullBill.dueDate).toISOString().split('T')[0] : '';

            if (fullBill.billType === 2) {
                openModal(
                    <AddEditNonSupplierBillModal
                        initialBillData={fullBill}
                        handleSubmit={handleSubmit}
                        stores={stores}
                        onHide={closeModal}

                    />
                );
            }
            else
            {
                openModal(
                    <AddEditSupplierBillModal
                        initialBillData={fullBill}
                        handleSubmit={handleSubmit}
                        stores={stores}
                        onHide={closeModal}
                    />
                );
            }

        } catch (error) {
            console.error('Error fetching bill details:', error);
            updateMessage(defaultErrMsg(), 'text-red');
        }
    };

    const onFilterTextBoxChanged = (event) => {
        const newSearchText = event.target.value;
        setSearchText(newSearchText);
        gridApi.setQuickFilter(newSearchText);
    };

    const columnDefs = [
        { field: 'billId', headerName: 'Bill Id', maxWidth: 100, hide: true },
        { field: 'storeName', headerName: 'Store', hide: true },
        //{ field: 'vendorName', headerName: 'Vendor', hide: !isPayables },
        { field: 'payee', headerName: 'Payee', },        
        { field: 'billStatusDesc', headerName: 'Status', maxWidth: 160, },
        { field: 'billTypeDesc', headerName: 'Type', maxWidth: 140, },
        { field: 'poNumber', headerName: 'PO #', maxWidth: 120, },
        { field: 'billNumber', headerName: 'Bill #', width: 200, },
        {
            field: 'billTotal', headerName: 'Bill Total', maxWidth: AgCurrencyMxWd(),
            valueFormatter: (params) => GetDisplayCurrencyStr(params.value)
        },
        { field: 'billDate', headerName: 'Bill Date', maxWidth: AgDateMxWdSm(), valueFormatter: params => GetDisplayDateStr(params.value) },
        { field: 'dueDate', headerName: 'Due Date', maxWidth: AgDateMxWdSm(), valueFormatter: params => GetDisplayDateStr(params.value) },
        { field: 'paidDate', headerName: 'Paid Date', maxWidth: AgDateMxWdSm(), valueFormatter: params => GetDisplayDateStr(params.value) },
        { field: 'checkNumber', headerName: 'Check #', maxWidth: 150, },
        { field: 'createdDateTime', headerName: 'Created Date', maxWidth: AgDateMxWdSm(), valueFormatter: params => GetDisplayDateStr(params.value) },
        {
            headerName: '', maxWidth: 60,
            cellRenderer: (params) => (
                <button className="btn-grid" onClick={() => handleEditClick(params.data)}>Edit</button>
            ),
        }
    ];

    const defaultColDef = {
        minWidth: 100,
        flex: 1,
        sortable: true,
        resizable: true,
        filter: true,
        suppressMovable: true,
        suppressMenu: false,
        cellClass: ["no-border"],
        menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab",],
    };

    //const handleDelete = useCallback((billId) => {
    //    setBillIdToDelete(billId);
    //    openModal(
    //        <ConfirmModal
    //            confirmDelete={confirmDelete}
    //            onHide={closeModal}
    //            typeStr={"Bill"}
    //        />
    //    );
    //}, []);

    //const confirmDelete = useCallback(async () => {
    //    try {
    //        await axios.delete(`bills/delete/${billIdToDelete}`);
    //        fetchBills();
    //        updateMessage('Bill deleted successfully', 'text-green-600');
    //    } catch (error) {
    //        console.error('Error deleting the bill:', error);
    //        updateMessage('Error deleting the bill', 'text-red-600');
    //    } finally {
    //        closeModal();
    //        setBillIdToDelete(null);
    //    }
    //}, []);

    //const handleAddLineItem = () => {
    //    const newLineItem = {
    //        billLineId: '',
    //        billId: '',
    //        lineNumber: '',
    //        description: '',
    //        quantity: '',
    //        freight: '',
    //        total: ''
    //    };
    //    console.log('Add line item');
    //    setFormData(prevFormData => ({
    //        ...prevFormData,
    //        billLineItems: [...prevFormData.billLineItems, newLineItem]
    //    }));
    //};


    const handleSubmit = async (create) => {
        if (IsTrue(create)) {
            updateMessage('Bill created successfully', 'text-green');
        }
        else {
            updateMessage('Bill updated successfully', 'text-green');
        }
        closeModal();
        fetchBills();
    }

    //const handleCreateSubmit = async (formData) => {
    //    try {
    //        //console.log('Received form data:', formData);
    //        //formData.billTotal = parseFloat(formData.billTotal) || 0;
    //        //formData.discRate = parseFloat(formData.discRate) || 0;
    //        //formData.discAmt = parseFloat(formData.discAmt) || 0;
    //        //formData.nonDiscAmt = parseFloat(formData.nonDiscAmt) || 0;
    //        //formData.poNumber = formData.poNumber.toString() || '';
    //        //formData.billNumber = formData.billNumber.toString() || '';

    //        const response = await post('bills/CreateBill', formData);
    //        if (IdExists(response)) {
    //            updateMessage('Bill created successfully', 'text-green');
    //            closeModal();
    //            //fetchBills();
    //        }

    //        //console.log('Bill created:', response.data);
    //    } catch (error) {
    //        console.error('Error creating bill:', error);

    //        if (error.response) {
    //            console.error('Error response:', error.response.data);
    //            setMessage('Error creating bill: ' + JSON.stringify(error.response.data));
    //        } else {
    //            setMessage('Error creating bill: ' + error.message);
    //        }
    //    }
    //};

    //const handleEditSubmit = async (formData) => {
    //    if (!formData.storeId || formData.billLineItems.length === 0) {
    //        alert('Please fill in all fields and add at least one line item.');
    //        return;
    //    }

    //    try {
    //        const response = await post('bills/UpdateBill', formData);
    //        if (IdExists(response)) {
    //            updateMessage('Bill updated successfully', 'text-green');
    //            closeModal();
    //            fetchBills();
    //        }

    //    } catch (error) {
    //        console.error('Error updating the bill:', error);
    //        updateMessage('Error updating the bill', 'text-red');
    //    }
    //};

    //const handleLineItemChange = (index, event) => {
    //    const { name, value } = event.target;

    //    const updatedLineItems = formData.billLineItems
    //        .map((item, idx) => idx === index ? { ...item, [name]: value } : item);

    //    setFormData(prevFormData => ({
    //        ...prevFormData,
    //        billLineItems: updatedLineItems
    //    }));
    //};

    const openModal = (content) => {
        setModalContent(content);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalContent(null);
    };

    const updateMessage = (msg, cssClass) => {
        setMessage(msg);
        setMessageClass(cssClass);
    };

    //function EnterSearch(e) {
    //    if (e.key === "Enter") {
    //        fetchBills();
    //    }
    //}

    const fetchStores = async () => {
        try {
            const response = await get('store/GetAllStores');
            setStores(response);
        } catch (error) {
            console.error('Error fetching stores:', error);
            updateMessage('Error fetching stores', 'text-red-600');
        }
    };

    async function fetchBills(defFromDate, defTodate) {
        updateMessage();

        var pFromDate = fromDate;
        var pToDate = toDate;

        if (NotStringEmpty(defFromDate) && NotStringEmpty(defTodate)) {
            pFromDate = defFromDate;
            pToDate = defTodate;
        }

        try {
            if (StringEmpty(dateType) || StringEmpty(pFromDate) || StringEmpty(pToDate)) {
                updateMessage('Dates and Date Type are required.', 'text-red');
                return;
            }

            let response = null;
            if (IsTrue(isPayables)) {
                response = await get('bills/GetBills', {
                    params: { pFromDate, pToDate, dateType }
                });
            }
            if (IsTrue(isCommissions)) {
                response = await get('bills/GetCommissionPendingBills', {
                    params: { pFromDate, pToDate, dateType }
                });
            }
            console.log('Bills response:', response.bills);

            var billsData = [];
            if (response && typeof response === 'object' && !Array.isArray(response)) {
                billsData = response.bills || [];
            }

            if (!Array.isArray(billsData)) {
                console.error('Unexpected response format:', response);
                throw new Error('Unexpected response format from server');
            }

            const transformedData = billsData.map(bill => ({
                ...bill,
                dueDate: bill.dueDate ? new Date(bill.dueDate).toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric'
                }) : '',
                billDate: bill.billDate ? new Date(bill.billDate).toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric'
                }) : '',
                billLineItems: Array.isArray(bill.billLineItems) ? bill.billLineItems.map(item => ({
                    ...item,
                    date: item.date ? new Date(item.date).toLocaleDateString('en-US', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric'
                    }) : ''
                })) : []
            }));
            setBills(transformedData);

            /*updateMessage('Bills fetched successfully', 'text-green-600');*/
        } catch (error) {
            console.error('Error fetching bills:', error);
            updateMessage('Error fetching bills: ' + (error.message || 'Unknown error'), 'text-red-600');
        } finally {
            //setLoading(false);
        }
    }

    useEffect(() => {
        if (isCommissions) {
            setDocTitle("Lumina - Commissions Pending");
            SetPageTitle("Commissions Pending");
        }
        else if (isPayables) {
            setDocTitle("Lumina - Search Payables");
            SetPageTitle("Search Payables")
        }

        fetchStores();
    }, []);

    if (loading) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }

    return (
        <div className="page-wrapper">
            <div className="flex-wrapper">
                <div className="">
                    <DateFilter fromDate={fromDate} setFromDate={setFromDate} fromDateStr="Start Date:"
                        toDate={toDate} setToDate={setToDate} toDateStr="End Date:" enterFunction={fetchBills} />
                </div>
                <div>
                    <button className="btn-date-submit" onClick={e => fetchBills()}>
                        {IsTrue(isPayables) &&
                            <span>Search Payables</span>
                        }
                        {IsTrue(isCommissions) &&
                            <span>Search Commissions</span>
                        }
                    </button>
                </div>
                <div>
                    <RadioButtonList rblClassName={rblClassName} valuesList={dateTypes} initialValue={dateType} setValue={setDateType} />
                </div>
            </div>

            <div className="clear pt-2">
                <div className="flex-wrapper">
                    <div className="flex-1">
                        <input
                            type="text"
                            value={searchText}
                            onChange={onFilterTextBoxChanged}
                            placeholder="Filter..."
                            className="grid-filter !w-[300px]"
                        />
                        {message && <span className={`pl-2 text-sm ${messageClass}`}>{message}</span>}
                    </div>

                    {IsTrue(isPayables) &&
                        <div className="">
                            <button className="btn-small"
                                onClick={() => openModal(
                                <AddEditNonSupplierBillModal
                                    initialBillData={initialFormData}
                                    handleSubmit={handleSubmit}
                                    stores={stores}
                                    onHide={closeModal}

                                    //formData={formData}
                                    //setFormData={setFormData}
                                    //handleSubmit={handleSubmit}
                                    //handleLineItemChange={handleLineItemChange}
                                    //handleAddLineItem={handleAddLineItem}
                                    //stores={stores}
                                    //onHide={closeModal}
                                    //initialFormData={initialFormData}
                                />
                            )}
                            >Add Non-Supplier Bill</button>

                            <button className="btn-small ml-2" onClick={() => openModal(
                                <AddEditSupplierBillModal
                                    initialBillData={initialFormData}
                                    handleSubmit={handleSubmit}
                                    stores={stores}
                                    onHide={closeModal}

                                    //formData={formData}
                                    //setFormData={setFormData}
                                    //handleSubmit={handleSubmit}
                                    //handleLineItemChange={handleLineItemChange}
                                    //handleAddLineItem={handleAddLineItem}
                                    //stores={stores}
                                    //onHide={closeModal}
                                    //initialFormData={initialFormData}
                                />
                            )}>Add Supplier Bill</button>
                        </div>
                    }
                </div>

                <div className="ag-theme-alpine bills-table ag-grid-act pt-2" style={{ height: 700, width: '100%' }}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={bills}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        pagination={true}
                        paginationPageSize={30}
                        onGridReady={onGridReady}
                        quickFilterText={searchText}
                    />
                </div>


                {/*<BillTable*/}
                {/*    bills={bills}*/}
                {/*    handleEditClick={handleEditClick}*/}
                {/*    handleDelete={handleDelete}*/}
                {/*    loading={loading}*/}
                {/*/>*/}

                {isModalOpen && modalContent &&
                    <div className="modal-wrapper">
                        <div className="modal-content !w-[1500px] !max-w-[1500px]">
                            {modalContent}
                        </div>
                    </div>
                }

                {/*<Modal open={isModalOpen} className="overflow-y-auto w-full w-[1700px] max-w-[1700px] p-2">*/}
                {/*    <Button onClick={(e) => closeModal()} size="sm" color="ghost" shape="circle"*/}
                {/*        className="absolute right-2 min-h-[1.5rem] h-[1.5rem] w-[1.5rem]">x</Button>*/}
                {/*    <Modal.Body>*/}
                {/*        {modalContent}*/}
                {/*    </Modal.Body>*/}
                {/*</Modal>*/}

            </div>
        </div>
    );
}