import React, { useCallback, useEffect, useRef, useState } from 'react';

import { GetValueById } from '../../../js/helpers.js';
import { IsFalse, IsTrue, ParseIntSafe, PreventDefault, ToBool } from '../../../js/formHelpers.js';

import { CreateOrderLineContent } from './CreateOrderLineContent';
import { ProductSalesGrid } from '../../ProductSalesSearch/ProductSalesGrid';

import "../../Products/_productCustom.css";
import { ProductAddEdit } from '../../Products/ProductAddEdit';

export const AddOrderLineModal = (props) => {
    const onCloseModal = props.onCloseModal;
    const propsOrderId = props.orderId ?? 0;
    const propsStoreId = props.storeId ?? 0;
    const propsPriceLevelStr = props.priceLevelStr ?? "";
    const propsBcMultiplier = props.bcMultiplier ?? 0;
    const propsOrderTypeStr = props.orderTypeStr ?? "";
    const propsIsSamplesOrder = props.isSamplesOrder ?? 0;
    const propsIsInventoryOrder = props.isInventoryOrder ?? false;
    
    const [modalClass, setModalClass] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
        
    const [linesUpdated, setLinesUpdated] = useState(false);

    const [showAddBundleConfirm, setShowAddBundleConfirm] = useState(false);
    const [bAddNext, setBAddNext] = useState(false);
    const [bProductId, setBProductId] = useState(0);
    const [bProductPriceId, setBProductPriceId] = useState(0);
    const [bProductSkuId, setBProductSkuId] = useState(0);

    const [addLineButtons, setAddLineButtons] = useState(false);
    const [chooseProductButtons, setChooseProductButtons] = useState(false);
    const [selectBtnText, setSelectBtnText] = useState("Select Product");

    
    //Methods
    function onCloseOlModal(e) {
        PreventDefault(e);
        onCloseModal(e, linesUpdated);
    }

    function selectProduct(e) {
        PreventDefault(e);

        //selected product id
        var selectedProdId = GetValueById("hfSelectProductId");
        var intProdId = ParseIntSafe(selectedProdId);     
        //selected product price id
        var selectedProdPriceId = GetValueById("hfSelectProductPriceId");
        var intProdPriceId = ParseIntSafe(selectedProdPriceId);
        //selected product stock id
        var selectedStockId = GetValueById("hfSelectProductStockId");
        var intStockId = ParseIntSafe(selectedStockId);
        //selected product sku id
        var selectProductSkuId = GetValueById("hfSelectProductSkuId");
        var intSkuId = ParseIntSafe(selectProductSkuId);   
        // product has bundles
        var hasBundles = GetValueById("hfSelectProductHasBundles");
        var boolHasBundles = ToBool(hasBundles);

        loadModalContent(null, "addLine", intProdId, intProdPriceId, "Products", intStockId, intSkuId, boolHasBundles);
    }

    function createCustomItem(e, useProductData) {
        PreventDefault(e);

        if (useProductData === true) {
            //selected product id
            var selectedProdId = GetValueById("hfSelectProductId");
            var intProdId = ParseIntSafe(selectedProdId);
            //selected product price id
            var selectedProdPriceId = GetValueById("hfSelectProductPriceId");
            var intProdPriceId = ParseIntSafe(selectedProdPriceId);
            //selected product stock id
            var selectedStockId = GetValueById("hfSelectProductStockId");
            var intStockId = ParseIntSafe(selectedStockId);
            //selected product sku id
            var selectProductSkuId = GetValueById("hfSelectProductSkuId");
            var intSkuId = ParseIntSafe(selectProductSkuId);            
            // product has bundles
            var hasBundles = GetValueById("hfSelectProductHasBundles");
            var boolHasBundles = ToBool(hasBundles);

            loadModalContent(null, "addLine", intProdId, intProdPriceId, "Custom", intStockId, intSkuId, boolHasBundles);
        }
        else {
            loadModalContent(null, "addLine", 0, 0, "Custom", 0, 0);
        }
    }

    function loadModalContent(olsUpdated, type, productId, productPriceId,
        productType, productStockId, productSkuId, boolHasBundles) {

        if (olsUpdated && IsFalse(linesUpdated)) {
            setLinesUpdated(olsUpdated);
        }

        if (type === "products") {
            setModalClass("products-grid-content");            
            setModalContent(returnProductsGridComponent());
        }
        else if (type === "addLine") {
            //set from add line component
            //send in selected product
            setModalClass("order-line-content");
            setModalContent(returnAddLineComponent(
                productId, productPriceId, productType, productStockId, productSkuId, boolHasBundles));
        }
        else {
            //clear modal content
            setModalContent(null);
        }
    }

    function returnProductsGridComponent() {
        setChooseProductButtons(true);
        setAddLineButtons(false);        
        return <ProductSalesGrid pageType="order" orderId={propsOrderId}
            orderStoreId={propsStoreId} isInventoryOrder={propsIsInventoryOrder}></ProductSalesGrid>;
    }

    function returnAddLineComponent(productId, productPriceId, productType,
        productStockId, productSkuId, boolHasBundles) {
        setChooseProductButtons(false);
        setAddLineButtons(true);

        return <CreateOrderLineContent
            orderId={propsOrderId} storeId={propsStoreId} priceLevelStr={propsPriceLevelStr}
            bcMultiplier={propsBcMultiplier} productType={productType} orderTypeStr={propsOrderTypeStr}
            productId={productId} productPriceId={productPriceId} productStockId={productStockId}
            productSkuId={productSkuId} isSamplesOrder={propsIsSamplesOrder} productHasBundles={boolHasBundles}
            loadModalContent={loadModalContent} onCloseModal={onCloseModal} onAddBundles={onOpenBundleModal}>
        </CreateOrderLineContent>;        
    }

    // Bundles

    function returnProductAddEdit(addNext, productId, productPriceId, productSkuId) {
        return <ProductAddEdit pageType="order" orderAddNext={addNext}
            isPricingPageType={false} isAdminPageType={false} isSalesPageType={false} isOrderPageType={true}
            productId={productId} productPriceId={productPriceId} orderId={propsOrderId}
            productSkuId={productSkuId} onCloseModal={onCloseBundleModal}>
        </ProductAddEdit>;
    }


    function onOpenBundleModal(addNext, productId, productPriceId, productSkuId) {
        setBProductId(productId);
        setBProductPriceId(productPriceId);
        setBProductSkuId(productSkuId);
        setBAddNext(addNext);

        setModalContent(null);
        setModalClass("");                
        setShowAddBundleConfirm(true);
    }

    function onConfirmAddBundle(e, addRelatedProducts) {
        PreventDefault(e);

        setShowAddBundleConfirm(false);
        if (IsTrue(addRelatedProducts)) {
            setModalClass("product-order-modal-content");
            setModalContent(returnProductAddEdit(bAddNext, bProductId, bProductPriceId, bProductSkuId));
        } else {
            onCloseBundleModal(bAddNext, '');
        }        
    }

    function onCloseBundleModal(addNext, msg) {
        //PreventDefault(e);
        //updateMessage(msg, "text-green");

        setModalContent(null);

        if (IsTrue(addNext)) {
            //add another
            loadModalContent(true, "products");
        }
        else {
            //close modal
            onCloseModal(null, true);
        }
    }


    // ON LOAD
    useEffect(() => {
        loadModalContent(null, "products");
    }, []);

    return (
        <div className={"max-w-[100%] " + modalClass}>
            <input id="hfSelectProductId" type="hidden" value=""></input>
            <input id="hfSelectProductSkuId" type="hidden" value=""></input>
            <input id="hfSelectProductPriceId" type="hidden" value=""></input>
            <input id="hfSelectProductStockId" type="hidden" value=""></input>
            <input id="hfSelectProductHasBundles" type="hidden" value=""></input>
            
            <div className="text-center">
                <div className="font-bold mb-1 text-xl">
                    {modalTitle}
                </div>
            </div>
            <div className="p-1">
                <div className="clear">
                    {modalContent}

                    {chooseProductButtons &&
                        <div className="btnsAddProductsOrder">
                            <button type="button" id="btnSelectProduct" className="hidden"
                                //className="btn-submit-modal mr-2"
                                onClick={(e) => selectProduct(e)}>
                                Select Product
                            </button>
                            <button type="button" className="btn-close-modal"
                                onClick={(e) => onCloseOlModal(e)}>
                                Close
                            </button>
                            <button type="button" id="btnCreateCustom" className="hidden"
                                onClick={(e) => createCustomItem(e, false)}>
                                Create Custom Item
                            </button>
                            <button type="button" id="btnCreateCustomProduct" className="hidden"
                                onClick={(e) => createCustomItem(e, true)}>
                                Create Custom Item From Product
                            </button>
                        </div>
                    }        
                </div>
            </div>


            {/**** Add Bundle Products confirm ****/}
            {showAddBundleConfirm &&
                <div className="text-center px-4 pb-4">
                    <div className="text-secondary text-[16px] pb-4 font-bold">
                        Add Related Products for line?
                    </div>
                    <div className="">
                        <button type="button" className="btn-submit-modal mr-2"
                            autoFocus
                            onClick={(e) => onConfirmAddBundle(e, true)}>
                            Yes
                        </button>
                        <button type="button" className="btn-close-modal"
                            onClick={(e) => onConfirmAddBundle(e, false)}>
                            No
                        </button>
                    </div>
                </div>
            }
        </div>
    );
}
