import React, { useState, useEffect, useRef } from 'react';

import { Equals } from '../../../js/calculationHelpers';
import { defaultErrMsg, IdExists, isSelectedDropdown, ParseIntSafe } from '../../../js/formHelpers';

import { post } from '../../../services/apiService';

export function ChartAccountForm({ onChartAccountAdded, closeModal }) {

    const [formData, setFormData] = useState({
        chartAccountId: 0,
        accountNumber: "",
        accountName: "",
        accountType: 0,
        subAccount: "",
        notes: "",
    });

    // Methods

    const clearForm = () => {
        setFormData({
            chartAccountId: 0,
            accountNumber: "",
            accountName: "",
            accountType: 0,
            subAccount: "",
            notes: "",
        });
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            if (!isSelectedDropdown(formData.accountType)) {
                alert("Account Type is required");
            }
            else {
                var iAccountType = ParseIntSafe(formData.accountType);
                formData.accountType = iAccountType;

                var resp = await post("chartaccount/CreateChartAccount", formData);
                var iChartAcct = ParseIntSafe(resp.value.data);

                if (IdExists(iChartAcct)) {                    
                    onChartAccountAdded();
                    clearForm();
                }
                else {
                    alert(defaultErrMsg());
                }
            }
        } catch (error) {
            alert(defaultErrMsg());
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <h2 className="text-2xl font-bold mb-4 text-center">Add New Chart Account</h2>
                <div className="mb-3">
                    <label className="label-form">Account Name:</label>
                    <input type="text" name="accountName" className="label-form-input"
                        value={formData.accountName}
                        onChange={handleChange}
                        placeholder=""
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="label-form">Account Number:</label>
                    <input type="number" name="accountNumber" className="label-form-input"
                        value={formData.accountNumber}
                        onChange={handleChange}
                        placeholder=""                        
                        required
                    />
                </div>                
                <div className="mb-3">
                    <label className="label-form">Account Type:</label>
                    <select name="accountType" className="label-form-input"
                        value={formData.accountType}
                        onChange={handleChange}
                        required
                    >
                        <option value="0">-- Select --</option>
                        <option value="1">Current Assets</option>
                        <option value="2">Fixed Assets</option>
                        <option value="3">Other Assets</option>
                        <option value="4">Current Liabilities</option>
                        <option value="5">Long Term Liabilities</option>
                        <option value="6">Other Liabilities</option>
                        <option value="7">Capital Accounts</option>
                        <option value="8">Sales Income</option>
                        <option value="9">Cost of Sales</option>
                        <option value="10">Expense Account</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label className="label-form">Sub Account:</label>
                    <input type="text" name="subAccount" className="label-form-input"
                        value={formData.subAccount}
                        onChange={handleChange}
                        placeholder=""
                    />
                </div>
                <div className="mb-3">
                    <label className="label-form">Notes:</label>
                    <textarea name="notes" className="label-form-input"
                        rows={4}                        
                        value={formData.notes}
                        onChange={handleChange}
                        placeholder=""
                    />
                </div>
                <div className="text-right">
                    <button className="btn-cancel" type="button" onClick={closeModal}>
                        Close
                    </button>
                    <button className="btn-submit" type="submit">
                        Add
                    </button>
                </div>
            </form>
        </div>
    );
}