import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Checkbox, Table, Dropdown } from 'flowbite-react';
import { Modal, Button } from 'react-daisyui';

import { SetDocumentTitle, GetLocationStateId } from "../_reactHelpers/ReactHelpers";
import {
    handleFieldChange, formatCurrencyDecStr, NotEmpty, IsEmail, IdExists,
    formatCurrencyDecimal, enforcePhoneFormat, formatToPhone, PreventDefault,
    Exists, IsInt, IsTrue, IsFalse, ParseIntSafe, NotExists, NotStringEmpty, IsEmpty, StringEmpty, Timeout
} from '../../js/formHelpers.js';
import { get } from '../../services/apiService.js';

import {
    SetPageTitle, CloseTab, GetSelectItemTextById, GetSelectItemValueById,
    SetValueById, GetValueById, ClickById, SetHrefById, StringContains
} from '../../js/helpers.js';

import { Equals, GreaterThan, NotEquals } from '../../js/calculationHelpers.js';
import { GetUserSelectList, IsSuccessStandardResponseAndDataPOST } from '../../js/dataHelpers.js';
import {
    SaveOlDisplayOrder, CreateClaimPost, CreateOrderForQuoteLinesPost,
    AddQuoteLinesToOrderPost, CreateBillingGroupDb, DeleteOrderLinesPost,
    SaveOrderPost, ConvertOrderToQuotePost
} from './_js/_orderDataHelpers.js';

import { createOrderModel, createAccountModel, createJobModel } from './_js/_orderHelpers.js';
import './_order.css';

import { AddAccountModal } from './_modals/AddAccountModal';
import { AddVendorModal } from './_modals/AddVendorModal';
import { OrderJobModal } from './_modals/OrderJobModal';
import { PurchaseOrderModal } from './_modals/PurchaseOrderModal';
import { AddOrderLineModal } from './_modals/AddOrderLineModal';
import { MaterialPickupModal } from './_modals/MaterialPickupModal';
import { EditOrderLineModal } from './_modals/EditOrderLineModal';
import { ReceiptsModal } from './_modals/ReceiptsModal';
import { BillingGroupModal } from '../BillingGroup/BillingGroupModal.js';
import { PaymentModal } from '../Payment/_modals/PaymentModal.js';
import { AuditEntriesModal } from './_modals/AuditEntriesModal.js';

import { AccountVendorInfo } from './_accountVendorInfo.js';
import { JobInfo } from './_jobInfo.js';
import { OrdersGrid } from '../OrderSearch/OrdersGrid';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
//import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../css/ag-theme-lumina.css'; // local ag-theme-alpine.css


export const OrderDetail = (props) => {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina");
    const location = useLocation();
    const navigate = useNavigate();

    //TODO: remove when use token
    const [user, setUser] = useState(null);
    const [userId, setUserId] = useState(null);

    const [isQuoteOrderSearch, setIsQuoteOrderSearch] = useState(false);

    const locOrderType = GetLocationStateId("orderType");
    const locBillGroupId = GetLocationStateId("billingGroupId");

    const billGroupModalRef = useRef();
    const [billGroupModalContent, setBillGroupModalContent] = useState(null);

    const [activityLogModalContent, setActivityLogModalContent] = useState(null);

    const [isPaymentsModalOpen, setIsPaymentsModalOpen] = useState(false);
    const [paymentModalContent, setPaymentModalContent] = useState(false);
    const [paymentResult, setPaymentResult] = useState(null);

    const [isReceiptsModalOpen, setIsReceiptsModalOpen] = useState(false);
    const [receiptsResult, setReceiptsResult] = useState(null);

    const orderModalRef = useRef();
    const [modalClass, setModalClass] = useState("");
    const [modalContent, setModalContent] = useState(null);

    //const [formDirty, setFormDirty] = useState();
    //var formDirty = false;

    const [userList, setUserList] = useState([]);
    const [accountId, setAccountId] = useState(GetLocationStateId("accountId"));

    const [orderId, setOrderId] = useState(GetLocationStateId("orderId"));
    const [orderData, setOrderData] = useState(null);
    const [orderLines, setOrderLines] = useState([]);

    const [orderLinesMessage, setOrderLinesMessage] = useState("");
    const [orderLinesMessageClass, setOrderLinesMessageClass] = useState("");

    const [addLinesDisabled, setAddLinesDisabled] = useState(true);
    const [canEmailSubmittal, setCanEmailSubmittal] = useState(false);
    const [canCreatePo, setCanCreatePo] = useState(false);
    const [canEditPo, setCanEditPo] = useState(false);

    const [orderLinesTotal, setOrderLinesTotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [salesTax, setSalesTax] = useState(0);
    const [payments, setPayments] = useState(0);
    const [orderTotal, setOrderTotal] = useState(0);
    const [balance, setBalance] = useState(0);
    const [referral, setReferral] = useState(0);

    const [billingGroupId, setBillingGroupId] = useState(0);
    const [accountData, setAccountData] = useState(null);
    const [claimVendorData, setClaimVendorData] = useState(null);

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");

    const [jobBoxClass, setJobBoxClass] = useState("");
    const [jobMessage, setJobMessage] = useState("");
    const [jobMessageClass, setJobMessageClass] = useState("");

    const [accountMessage, setAccountMessage] = useState("");
    const [accountMessageClass, setAccountMessageClass] = useState("");

    const [claimVendorMsg, setClaimVendorMsg] = useState("");
    const [claimVendorMsgClass, setClaimVendorMsgClass] = useState("");

    const [submitDisabled, setSubmitDisabled] = useState("");
    const [reloadOrderUrl, setReloadOrderUrl] = useState("");

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    const typeOlIds = "get-olIds";
    const poCreate = "po-create";
    const poEdit = "po-edit";
    const materialPickup = "material-pickup";
    const typeEdit = "edit-lines";
    const typeDelete = "delete-lines";

    //const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    //const [gridHeight, setGridHeight] = useState("400px");

    const olGridRef = useRef();
    const columnDefs = [
        {
            headerName: '', field: 'orderLineId', maxWidth: 35, //cellClass: ["text-left"],
            headerCheckboxSelection: true, checkboxSelection: true,
        },
        //{ headerName: '#', field: 'lineNumberStr', maxWidth: 50, cellClass: ["text-primary"], hide: true, },
        {
            headerName: 'Style', field: 'style', cellClass: ["text-wrap"], autoHeight: true, 
            width: 350, resizable: true,
            cellRenderer: params => {
                var ol = params.data;
                return <div className="leading-[20px] pt-[11px] min-w-[350px]">
                    <span>{ol.style}</span>
                    {/*<div>*/}
                    {/*    <span className="pr-4px">Width: {ol.width}</span>*/}
                    {/*    <span>Height: {ol.height}</span>*/}
                    {/*</div>*/}
                </div>;
            },
        },
        {
            headerName: "Color", field: 'color', cellClass: ["text-wrap"], autoHeight: true,
            maxWidth: 200, 
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px] min-w-[200px]">{params.data.color}</div>;
            },
        },
        {
            headerName: "Comments", field: 'comments', cellClass: ["text-wrap"], autoHeight: true,
            maxWidth: 200, 
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px] min-w-[200px]">{params.data.comments}</div>;
            },
        },
        {
            headerName: 'Qty', field: 'quantity', maxWidth: 70, cellClass: ["text-primary"],
        },
        //{
        //    headerName: 'Qty Rcvd', field: 'quantityReceived', maxWidth: 125, cellClass: ["text-primary"],
        //},
        {
            headerName: 'Units', field: 'unitsStr', maxWidth: 60 //cellClass: ["text-wrap"],
        },
        {
            headerName: 'Cost', field: 'cost', maxWidth: 100, //cellClass: ["text-left"],
            hide: IsFalse(isInventory()),
            cellRenderer: params => {
                var cost = formatCurrencyDecStr(params.data.cost);
                return <span className="text-primary">{cost}</span>;
            }
        },
        {
            headerName: 'Price', field: 'price', maxWidth: 100, //cellClass: ["text-left"],
            cellRenderer: params => {
                var price = formatCurrencyDecStr(params.data.price);
                return <span className="text-primary">{price}</span>;
            }
        },
        {
            headerName: 'Total', field: 'total', maxWidth: 100, //cellClass: ["text-wrap"],
            cellRenderer: params => {
                var total = formatCurrencyDecStr(params.data.total);
                return <span className="text-primary">{total}</span>;
            }
        },
        {
            headerName: 'Status', field: 'statusStr', maxWidth: 100, cellClass: ["text-primary"],
            hide: isQuote(),
        },
        {
            headerName: 'Date', field: 'statusDateStr', maxWidth: 120, cellClass: ["text-primary"],
            hide: isQuote(),
        },
        {
            headerName: 'Warehouse', field: 'orderLineId', maxWidth: 105, //cellClass: ["text-wrap"],
            cellRenderer: params => {
                var ol = params.data;
                return <div className="text-primary"> {ol.showLocationData === true &&
                    <div>
                        {ol.showReceivedStore !== true && <span>{ol.warehouseName}</span>}
                        {ol.showReceivedStore === true && <span>{ol.receivedStoreName}</span>}
                    </div>} </div>;
            }
        },
        {
            headerName: 'Bin', field: 'bin', maxWidth: 70, //cellClass: ["text-wrap"],
            hide: isQuote(),
            cellRenderer: params => {
                return <span className="">{showBinCol(params.data)}</span>;
            }
        },
        {
            headerName: 'Vendor', field: 'vendorName', maxWidth: 150, cellClass: ["text-wrap"], autoHeight: true,
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px] min-w-[150px]">{params.data.vendorName}</div>;
            },
        },
        {
            headerName: 'Category', field: 'productCategoryName', maxWidth: 150, cellClass: ["text-wrap"], autoHeight: true,
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px]">{params.data.productCategoryName}</div>;
            },
        },
    ];

    const gridOptions = {
        defaultColDef: {
            //flex: 1,
            sortable: false,
            resizable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true,
            cellClass: ["no-border"],
        }
    };

    function showBinCol(ol) {
        if (ol) {
            if (ol.statusId === 1 || ol.statusId === 2 || ol.statusId === 3 ||
                ol.statusId === 4 || ol.statusId === 5 || ol.statusId === 6) {
                return <span>{ol.bin}</span>;
            }
        }
    };

    function onRowDoubleClicked(ol) {
        if (ol) {
            onOpenEditLinesModal(ol);
        }
    }

    const onRowDragEnd = useCallback((e) => {
        var orderLines = getRowNodesWithSort();
        if (NotEmpty(orderLines)) {
            var response = SaveOlDisplayOrder(orderLines);
            //logged in data method
            //if (response !== true) {
            //    console.error("SaveOlDisplayOrder error: " + response);
            //}
        }
    }, []);

    function getOrderTypeStr(isOrderType, isQuoteType, isClaimType, isInventoryType) {
        //get correct type for create/update/refresh paths

        if (isQuote(isQuoteType)) {
            return "Quote";
        } else if (isClaim(isClaimType)) {
            return "Claim";
        }
        else if (isInventory(isInventoryType)) {
            return "Inventory Order";
        }
        // Samples behaves as Order type
        //else if (isSample(isSamplesType)) {
        //    return "Samples";
        //}
        else {
            return "Order";
        }
    }

    function createMsg(isOrderType, isQuoteType, isClaimType, isInventoryType) {
        return getOrderTypeStr(isOrderType, isQuoteType, isClaimType, isInventoryType) + " created successfully.";
    }
    function updateMsg(isOrderType, isQuoteType, isClaimType, isInventoryType) {
        return getOrderTypeStr(isOrderType, isQuoteType, isClaimType, isInventoryType) + " saved successfully.";
    }
    function closedMsg(isOrderType, isQuoteType, isClaimType, isInventoryType) {
        return getOrderTypeStr(isOrderType, isQuoteType, isClaimType, isInventoryType) + " closed successfully.";
    }

    function createMsgSearch(orderNumber) {
        return orderNumber + " created successfully.";
    }
    function updateMsgSearch(orderNumber) {
        return orderNumber + " saved successfully.";
    }
    function closedMsgSearch(orderNumber) {
        return orderNumber + " closed successfully.";
    }

    //function createErrMsg() {
    //    return "There was an issue creating the " + getOrderTypeStr() + ". Please try again or contact support.";
    //}
    //function updateErrMsg() {
    //    return "There was an issue saving the " + getOrderTypeStr() + ". Please try again or contact support.";
    //}
    //function updateErrMsg() {
    //    return "There was an issue saving the " + getOrderTypeStr() + ". Please try again or contact support.";
    //}

    function checkLinesNotEmpty(objArr, msg, msgClass) {

        if (!objArr) {
            objArr = olGridRef.current.api.getSelectedNodes();
        }
        if (NotEmpty(objArr)) {
            return true;
        }
        if (!msg || !msgClass) {
            msg = "No lines selected.";
            msgClass = "text-primary";
        }
        updateOrderLinesMessage(msg, msgClass);
        return false;
    }

    function getRowNodesWithSort(type, getSelected) {
        var olIds = [];
        var orderLines = [];

        olGridRef.current.api.forEachNodeAfterFilterAndSort((node) => {
            var ol = node.data;
            if (ol) {
                //use selected cbs
                if (getSelected === true) {
                    if (node.selected === true) {
                        //PO
                        if (type === poEdit || type === poCreate) {
                            //get all selected orderLines for create statuses
                            if (type === poCreate &&
                                ((ol.statusId === 0 || ol.statusId === 1 || ol.statusId === 10) ||                                
                                // only add POs for Stock Item for Inventory Order
                                (isInventory() && ol.statusId === 15)))
                            {
                                orderLines.push(ol);
                            }
                            //get all lines that have a PO set for them and allow edit
                            else if (type === poEdit && IdExists(ol.purchaseOrderLineId)) {
                                //get all selected orderLines for edit statuses w/ PO
                                //(ol.statusId !== 0 && ol.statusId !== 1 && ol.statusId !== 10)
                                orderLines.push(ol);
                            }
                        }
                        //get all selected
                        else {
                            orderLines.push(ol);
                        }
                    }
                }
                //use all lines
                else {
                    //MaterialPickup
                    if (type === materialPickup) {
                        //'Received' and 'Stock Item' statuses
                        if (ol.statusId === 3 || ol.statusId === 15) {
                            orderLines.push(ol);
                        }
                    }
                    //get all orderLines
                    else {
                        orderLines.push(ol)
                    }
                }
            }

        });

        //return OrderLineIds for these types
        if (type === typeOlIds || type === poCreate || type === poEdit) {
            if (orderLines) {
                orderLines.forEach((ol, index) => {
                    olIds.push(ol.orderLineId);
                });
            }
            return olIds;
        }
        else {
            return orderLines;
        }
    }

    // Billing Group Modal
    const billGroupModalShow = useCallback(() => {
        billGroupModalRef.current?.showModal();
    }, [billGroupModalRef]);

    const billGroupModalHide = useCallback(() => {
        billGroupModalRef.current?.close();
    }, [billGroupModalRef]);

    function getBillGroupModalContent(billingGroupId) {
        return <BillingGroupModal billingGroupId={billingGroupId} orderId={orderData.orderId}
            onCloseModal={onCloseBillGroupModal}></BillingGroupModal>;
    }

    function onOpenBillGroupModal(e, billingGroupId) {
        PreventDefault(e);
        setBillGroupModalContent(getBillGroupModalContent(billingGroupId));
        billGroupModalShow();
    }

    function onCloseBillGroupModal(e) {
        PreventDefault(e);
        setBillGroupModalContent(null);
        billGroupModalHide();
    }

    async function createBillingGroup(e) {
        PreventDefault(e);
        var success = false;
        var resp = await CreateBillingGroupDb(orderData.orderId);

        var bgId = ParseIntSafe(resp)
        if (IdExists(bgId)) {
            success = true;
            setBillingGroupId(bgId);
            onOpenBillGroupModal(null, bgId);
        }

        if (success !== true) {
            console.error("createBillingGroup(): ", JSON.stringify(resp));
            alert(defaultErrMsg);
        }
    }


    /****** Data Methods ******/

    async function createUserList() {
        var list = [];
        list.push(
            <option key="0" value="0">None</option>
        );

        var dataList = await GetUserSelectList("sales");
        dataList.forEach((item, index) => {
            list.push(
                <option key={index + 1} value={item.value}>{item.text}</option>
            );
        });
        setUserList(list);
    }

    function loadDataLists() {
        createUserList();
    }


    /****** Methods ******/

    function clearAllMessages() {
        if (orderId && orderId !== 0) {
            //account
            setAccountMessage("");
            setAccountMessageClass("");
            //job
            setJobMessage("");
            setJobMessageClass("");
            //orderlines
            setOrderLinesMessage("");
            setOrderLinesMessageClass("");

            // TODO: remove - moved to job modal
            //material notification emails
            //setMatNotEmailsMsg("");
            //setMatNotEmailsMsgClass("");

            //general
            setMessage("");
            setMessageClass("");
        }
    }

    function updateMessage(msg, cssClass, errorMsg, logMsg) {
        clearAllMessages();

        if (errorMsg) {
            console.error(errorMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setMessage(msg);
        setMessageClass(cssClass);
    }

    function updateOrderLinesMessage(msg, cssClass, errorMsg, logMsg) {
        clearAllMessages();

        if (errorMsg) {
            console.error(errorMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setOrderLinesMessage(msg);
        setOrderLinesMessageClass(cssClass);
    }

    // TODO: remove - moved to job modal
    //function updateMatEmailMessage(msg, cssClass, changedCssClass, errorMsg, logMsg) {
    //    setMatNotEmailsMsg(msg);
    //    setMatNotEmailsMsgClass(cssClass);
    //    if (changedCssClass) {
    //        setMatNotChangedClass(changedCssClass);
    //    }
    //}

    function loadPage(e, route) {
        PreventDefault(e);
        SetHrefById("lnkLoadPage", route);
        ClickById("lnkLoadPage");
    }

    function openNewPage(e, route) {
        PreventDefault(e);
        SetHrefById("lnkOpenNewPage", route);
        ClickById("lnkOpenNewPage");
    }

    function voidClick(e) {
        PreventDefault(e);
    }

    function isOrder() {
        if (isClaim() || isQuote() || isInventory()) {
            return false;
        }
        //isSample()
        //Order and Sample type behave the same
        return true;
    }

    function isQuote(isQuoteType) {
        var isQuote = false;
        if (Exists(isQuoteType)) {
            isQuote = IsTrue(isQuoteType);
        }
        else if (locOrderType === 2) {
            isQuote = true;
        }
        else if (orderData) {
            isQuote = IsTrue(orderData.isQuoteType);
        }
        return isQuote;
    }

    function isClaim(isClaimType) {
        var isClaim = false;
        if (Exists(isClaimType)) {
            isClaim = IsTrue(isClaimType);
        }
        else if (locOrderType === 3) {
            isClaim = true;
        }
        else if (orderData) {
            isClaim = IsTrue(orderData.isClaimType);
        }
        return isClaim;
    }

    function isInventory(isInventoryType) {
        var isInventory = false;
        if (Exists(isInventoryType)) {
            isInventory = IsTrue(isInventoryType);
        }
        else if (locOrderType === 5) {
            isInventory = true;
        }
        else if (orderData) {
            isInventory = IsTrue(orderData.isInventoryType);
        }
        return isInventory;
    }

    //function isSample(isSamplesType) {
    //    var isSample = false;
    //    if (Exists(isSamplesType)) {
    //        isSample = IsTrue(isSamplesType);
    //    }
    //    else if (locOrderType === 4) {
    //        isSample = true;
    //    }
    //    else if (orderData) {
    //        isSample = IsTrue(orderData.isSamplesType);
    //    }
    //    return isSample;
    //}

    function showOnlyOrderClass() {
        var hiddenClass = " hidden ";
        if (isOrder()) {
            hiddenClass = "";
        }
        return hiddenClass;
    }
    function hiddenForOrderClass() {
        var hiddenClass = "";
        if (isOrder()) {
            hiddenClass = " hidden ";
        }
        return hiddenClass;
    }


    function showOnlyQuoteClass() {
        var hiddenClass = " hidden ";
        if (isQuote()) {
            hiddenClass = "";
        }
        return hiddenClass;
    }
    function hiddenForQuoteClass() {
        var hiddenClass = "";
        if (isQuote()) {
            hiddenClass = " hidden ";
        }
        return hiddenClass;
    }

    function showOnlyClaimClass() {
        var hiddenClass = " hidden ";
        if (isClaim()) {
            hiddenClass = "";
        }
        return hiddenClass;
    }
    function hiddenForClaimClass() {
        var hiddenClass = "";
        if (isClaim()) {
            hiddenClass = " hidden ";
        }
        return hiddenClass;
    }

    function showOnlyInventoryClass() {
        var hiddenClass = "";
        if (isInventory()) {
            hiddenClass = " hidden ";
        }
        return hiddenClass;
    }
    function hiddenForInventoryClass() {
        var hiddenClass = "";
        if (isInventory()) {
            hiddenClass = " hidden ";
        }
        return hiddenClass;
    }

    function backToClick(orderStatus, orderMessage) {
        var rldlnk = "/Orders";
        if (isQuote()) {
            rldlnk = "/Quotes";
        }
        else if (isClaim()) {
            rldlnk = "/Claims";
        }
        navigate(rldlnk, {
            replace: true,
            state: { useSavedFiltersOrder: true, orderStatus, orderMessage }
        });
    }

    function cbAllSetClick(checked) {
        if (checked === true) {
            olGridRef.current.api.selectAll();
        }
        else {
            olGridRef.current.api.deselectAll();
        }
    }

    function createNewOrderLoad() {
        if (IsTrue(isInventory())) {
            SaveOrder();
        }
        else if (!accountId || accountId === 0) {
            ClickById("btnOpenAccountClaimVendorModal");
        }
    }

    /****** Modal Methods ******/

    // Quote Create Order / Add to Order Modal --------------------------------------

    async function moveOrderToQuote(e) {
        PreventDefault(e);
        var response = null;
        var canMoveToQuote = true;

        //cannot move if payments on order
        canMoveToQuote = Equals(orderData.payments, 0);

        //cannot move if all lines not in "None" or "To Be Ordered"
        olGridRef.current.api.forEachNodeAfterFilterAndSort((node) => {
            if (canMoveToQuote) {
                var ol = node.data;
                if (ol) {
                    if (ol.statusId === 0 || ol.statusId === 1) {
                        canMoveToQuote = true;
                    }
                    else {
                        canMoveToQuote = false;
                    }
                }
            }
        });

        if (canMoveToQuote) {
            if (window.confirm("Are you sure you want to move this Order to a Quote? This will delete the Order.")) {
                response = await ConvertOrderToQuotePost(userId, orderData.orderId);

                var newQuoteId = ParseIntSafe(response);
                if (IdExists(newQuoteId)) {
                    loadPage(null, "/Redirect?route=EditQuote&orderId=" + newQuoteId);
                }
                else {
                    updateMessage(defaultErrMsg, "text-red");
                    alert(defaultErrMsg);
                }
            }
        }
        else {
            updateMessage("Order cannot be moved to quote.", "text-primary");
        }
    }


    function returnQuoteOrderSearchComponent() {
        setIsQuoteOrderSearch(true);
        return <div>
            <OrdersGrid pageType="QuoteOrders"
                selectOrderForQuoteLines={selectOrderForQuoteLines}
                onCloseModal={onCloseModal}>
            </OrdersGrid>
        </div>
    }

    function onOpenQuoteOrderSearchModal(e) {
        PreventDefault(e);
        clearAllMessages();

        var selOlIds = getRowNodesWithSort(typeOlIds, true);
        if (checkLinesNotEmpty(selOlIds)) {
            setModalClass("quotes-order-search");
            setModalContent(returnQuoteOrderSearchComponent);
            onOpenModal();
        }
    }

    function selectOrderForQuoteLines(e, orderId) {
        PreventDefault(e);
        AddQuoteLinesToOrder(orderId);
    }

    async function AddQuoteLinesToOrder(orderId) {
        var success = false;

        if (IdExists(orderId)) {

            var selOlIds = getRowNodesWithSort(typeOlIds, true);
            if (checkLinesNotEmpty(selOlIds)) {
                var resp = await AddQuoteLinesToOrderPost(userId, orderId, selOlIds);

                var addToOrderId = ParseIntSafe(resp);
                if (IdExists(addToOrderId)) {
                    success = true;
                    //openNewPage(null,
                    loadPage(null, "/Redirect?route=EditOrder&orderId=" + addToOrderId);
                }
                else {
                    onCloseModal(false, false, false);
                    console.error("AddQuoteLinesToOrder(): ", JSON.stringify(resp));
                    alert(defaultErrMsg);
                }
            }
        }

        if (!success) {
            onCloseModal(false, false, false);
        }
    }

    async function QuoteCreateNewOrder(e) {
        PreventDefault(e);

        var selOlIds = getRowNodesWithSort(typeOlIds, true);
        if (checkLinesNotEmpty(selOlIds)) {
            var resp = await CreateOrderForQuoteLinesPost(selOlIds);

            var newOrderId = ParseIntSafe(resp);
            if (IdExists(newOrderId)) {
                //openNewPage(null,
                loadPage(null, "/Redirect?route=EditOrder&orderId=" + newOrderId);
            }
            else {
                console.error("QuoteCreateNewOrder(): ", JSON.stringify(resp));
                alert(defaultErrMsg);
            }
        }
    }

    // Claim Vendor / Account --------------------------------------

    function openAccountOrClaimVendor(e) {
        PreventDefault(e);
        if (isClaim()) {
            onOpenAddClaimVendorModal(e);
        }        
        else {
            onOpenAddAccountModal(e);
        }
    }

    function openJobOrClaimAccount(e) {
        PreventDefault(e);
        if (isClaim()) {
            onOpenAddAccountModal(e);
        }
        else {
            onOpenJobModal(e);
        }
    }

    function returnAddClaimVendorModalComponent() {
        return <AddVendorModal orderId={orderId} isClaim={isClaim()}
            onCloseModal={onCloseAddClaimVendorModal}></AddVendorModal>;
    }

    function onOpenAddClaimVendorModal(e) {
        PreventDefault(e);

        setModalClass("select-vendor-modal");
        setModalContent(returnAddClaimVendorModalComponent);
        onOpenModal();
    }

    function onCloseAddClaimVendorModal(e, selectedVendorId) {
        PreventDefault(e);
        clearAllMessages();

        if (selectedVendorId) {
            saveClaimVendorAndSetVendorData(selectedVendorId);
        }
        else {
            //close button clicked (account not changed)
            onCloseModal(false, false, false);
        }
    }

    async function saveClaimVendorAndSetVendorData(vendorId) {
        //should only ever be used for Claim OrderType
        //return vendor model for display on change        
        if (vendorId && vendorId > 0) {
            //will save vendorId to Order if OrderId != 0
            const endpoint = 'orders/SaveAndGetVendorModel/' + vendorId + '/' + orderId;
            try {
                const response = await get(endpoint);
                //console.log('Response: ' + JSON.stringify(response));

                setClaimVendorData(response);

                orderData.claimAgainstVendorId = vendorId;
                setOrderData(orderData);

                if (orderId === 0) {
                    onCloseModal(false, false, true);
                }
                else {
                    setClaimVendorMsg("Claim Against - Vendor saved successfully.");
                    onCloseModal(false, false, false);
                }
            } catch (error) {
                console.log('Error: ' + error);
            }
        }
    };

    // Account Modal --------------------------------------

    function returnAddAccountModalComponent() {
        return <AddAccountModal orderId={orderId}
            isClaim={isClaim()} isQuote={isQuote()}
            onCloseModal={onCloseAddAccountModal}></AddAccountModal>;
    }

    function onOpenAddAccountModal(e) {
        PreventDefault(e);
        setModalClass("select-account-modal");
        setModalContent(returnAddAccountModalComponent);
        onOpenModal();
    }

    function onCloseAddAccountModal(e, selectedAccountId) {
        PreventDefault(e);
        clearAllMessages();

        if (selectedAccountId) {
            if (orderId === 0 && isClaim()) {
                //create new claim after Claim For Account selected
                //set fields and save new Order with ClickById("btnOrderSubmit")                
                orderData.accountId = selectedAccountId;
                setOrderData(orderData);
                onCloseModal(false, true, false);

            }
            else {
                //save account for order,claim
                saveOrderAccountId(selectedAccountId, null);
            }
        }
        else {
            //close button clicked (account not changed)
            var openJobInfo = orderId === 0;
            onCloseModal(openJobInfo, false, false);
        }
    }

    function saveOrderAccountId(selectedAccountId, orderDataModel) {
        if (selectedAccountId) {
            saveAccountAndSetAccountData(selectedAccountId, orderDataModel);
        }
    }

    async function saveAccountAndSetAccountData(accountId, orderDataModel) {
        if (IdExists(accountId)) {
            const endpoint = 'orders/SaveAndGetAccountModel/' + accountId + '/' + orderId;
            try {
                const data = await get(endpoint);
                if (IdExists(data.accountId)) {
                    var acctModel = createAccountModel(null, data);
                    setAccountData(acctModel);

                    if (orderId === 0) {
                        // used for create new Order from Account
                        // return OrderModel with Account data
                        var orderModel = orderData;
                        if (!orderModel && orderDataModel) {
                            orderModel = orderDataModel;
                        }

                        orderModel.accountId = acctModel.accountId;
                        orderModel.samplesAccount = acctModel.samplesAccount;
                        // Terms - default to Cash                        
                        orderModel.termsId = 1;
                        if (IdExists(acctModel.termsId)) {
                            orderModel.termsId = acctModel.termsId;
                        }
                        // BC PriceLevel
                        if (IdExists(acctModel.defaultPriceLevelId)) {
                            orderModel.priceLevelId = acctModel.defaultPriceLevelId;
                        }
                        // fields not needed for claim
                        if (IsFalse(isClaim())) {
                            //set autofill values from Account on new Order
                            orderModel.materialNotificationEmail = acctModel.materialNotificationEmail;
                            orderModel.materialNotificationPhone = acctModel.materialNotificationPhone;
                        }

                        // open Job modal on new Order
                        // unless creating from Billing Group
                        var openJobModal = true;
                        var createNewOrder = false;
                        if (IdExists(locBillGroupId)) {
                            openJobModal = false;
                            createNewOrder = true;
                        }

                        //set page model data
                        setOrderData(orderModel);

                        onCloseModal(openJobModal, createNewOrder, false);
                    }
                    else {
                        // update accountId only for existing order                        
                        orderData.accountId = accountId;
                        setOrderData(orderData);

                        // show OrderType message
                        if (isClaim()) {
                            setAccountMessage("Claim For - Account saved successfully.");
                        }
                        else {
                            setAccountMessage("Account saved successfully.");
                        }
                        onCloseModal(false, false, false);
                    }

                } else {
                    alert("An error occurred updating Account");
                }
            } catch (error) {
                console.log('Error: ' + error);
            }
        }
    };


    // Job Modal --------------------------------------

    function returnJobModalComponent() {
        var jobData = createJobModel(orderData, null);
        var orderType = getOrderTypeStr();
        //samplesAccount={orderData.samplesAccount}
        return <OrderJobModal orderId={orderId} orderType={orderType}
            jobData={jobData} onCloseModal={onCloseJobModal}>
        </OrderJobModal>;
    }

    function onOpenJobModal() {
        if (orderId !== 0) {
            setJobBoxClass("");
        }
        setModalClass("order-job-modal");
        setModalContent(returnJobModalComponent());
        onOpenModal();
    }

    function onCloseJobModal(e, modalJobData) {
        PreventDefault(e);
        clearAllMessages();

        if (Exists(modalJobData)) {

            orderData.jobModel = modalJobData;
            if (Exists(modalJobData)) {
                orderData.jobNotes = modalJobData.jobNotes;
            }

            if (orderId === 0) {
                orderData.storeId = modalJobData.jobStoreId;
                setOrderData(orderData);

                // saves new Order with ClickById("btnOrderSubmit")                                
                onCloseModal(false, true, false);
            }
            else {
                setOrderData(orderData);

                // if here job save success
                // show message and update Order display fields                
                setJobMessage("Job saved successfully.");
                onCloseModal(false, false, false);
            }
        }
        else {
            //close button clicked (job not saved)
            onCloseModal(false, false, false);
        }
    }

    // Add Order Line Modal --------------------------------------

    function returnAddOrderLineModalComponent() {
        var priceLevelStr = GetSelectItemTextById("priceLevelId");
        var orderTypeStr = getOrderTypeStr();
        var isInventoryOrder = isInventory();

        return <AddOrderLineModal orderId={orderId} storeId={orderData.storeId}
            priceLevelStr={priceLevelStr} bcMultiplier={orderData.bcMultiplier}
            orderTypeStr={orderTypeStr} isSamplesOrder={orderData.isSamplesOrder}
            isInventoryOrder={isInventoryOrder} onCloseModal={onCloseAddOlModal}>
        </AddOrderLineModal>;
    }

    function onOpenAddOlModal(e) {
        PreventDefault(e);
        clearAllMessages();

        setModalClass("add-line-modal");
        setModalContent(returnAddOrderLineModalComponent);
        onOpenModal();
    }

    function onCloseAddOlModal(e, linesUpdated) {
        PreventDefault(e);

        //reload to be sure any changes reflected
        var message = "";
        var messageClass = "";
        if (linesUpdated === true) {
            message = "Line(s) saved successfully.";
            messageClass = "text-green";
        }
        handleReloadOrderLines(message, messageClass);

        onCloseModal(false, false, false);
    }

    // Edit Order Line Modal --------------------------------------

    function returnEditLinesModalComponent(ols) {
        var priceLevelStr = GetSelectItemTextById("priceLevelId");
        return <EditOrderLineModal type={typeEdit} orderLines={ols}
            priceLevelStr={priceLevelStr} bcMultiplier={orderData.bcMultiplier}
            isSamplesOrder={orderData.isSamplesOrder} onCloseModal={onCloseEditLinesModal}>
        </EditOrderLineModal>;
    }

    function onOpenEditLinesModal(ol) {
        clearAllMessages();

        var ols = [];
        if (ol) {
            ols.push(ol);
        }
        else {
            ols = getRowNodesWithSort(null, true);
        }

        if (checkLinesNotEmpty(ols)) {
            setModalClass("add-line-modal");
            setModalContent(returnEditLinesModalComponent(ols));
            onOpenModal();
        }
    }

    async function onCloseEditLinesModal(e, linesEdited) {
        PreventDefault(e);
        var message = "";
        var messageClass = "";

        if (IsTrue(linesEdited)) {
            message = "Line(s) saved successfully.";
            messageClass = "text-green";
            updateOrderLinesMessage(message, messageClass);
        }
        else {
            //updateOrderLinesMessage("An error occurred deleting line(s). Please try again or contact support.", "text-red");
        }

        handleReloadOrderLines(message, messageClass);
        onCloseModal(false, false, false);
    }

    // Delete Order Line Modal --------------------------------------

    function returnDeleteLinesModalComponent() {
        return <EditOrderLineModal type={typeDelete} onCloseModal={onCloseDeleteLinesModal}></EditOrderLineModal>;
    }

    function onOpenDeleteLinesModal() {
        clearAllMessages();

        if (checkLinesNotEmpty()) {
            setModalClass("add-line-modal");
            setModalContent(returnDeleteLinesModalComponent());
            onOpenModal();
        }
    }

    async function onCloseDeleteLinesModal(e, deleteLines) {
        PreventDefault(e);

        if (deleteLines === true) {
            //set selected lines deleted and reload
            var olIds = getRowNodesWithSort(typeOlIds, true);
            if (checkLinesNotEmpty(olIds)) {

                var response = await DeleteOrderLinesPost(olIds);
                if (IsTrue(response)) {
                    updateOrderLinesMessage("Line(s) deleted successfully.", "text-green");
                }
                else {
                    console.error(JSON.stringify(response));
                    updateOrderLinesMessage("An error occurred deleting line(s). Please try again or contact support.", "text-red");
                }
            }
        }
        handleReloadOrderLines("", "");
        onCloseModal(false, false, false);
    }


    // PO Modal --------------------------------------

    function returnPurchaseOrderModalComponent(olIds) {
        //orderData={orderData}
        return <PurchaseOrderModal
            checkedOrderLineIds={olIds} orderData={orderData} orderLines={orderLines}
            isSamplesOrder={orderData.isSamplesOrder} onCloseModal={onClosePoModal}>
        </PurchaseOrderModal>;
    }

    function onOpenPoModal(create) {

        if (checkLinesNotEmpty()) {

            var poType = poEdit;
            if (create === true) {
                poType = poCreate;
            }

            var olIds = getRowNodesWithSort(poType, true);
            if (checkLinesNotEmpty(olIds)) {
                setModalClass("pur-order-modal");
                setModalContent(returnPurchaseOrderModalComponent(olIds));
                onOpenModal();
            }
            else {
                if (create) {
                    updateOrderLinesMessage("No selected lines can create PO(s).", "text-primary");
                }
                else {
                    updateOrderLinesMessage("No selected lines have PO(s).", "text-primary");
                }
            }
        }
    }

    function onClosePoModal(e, posSaved) {
        PreventDefault(e);

        //reload to be sure any changes reflected
        var message;
        var messageClass;
        if (posSaved === true) {
            message = "PO(s) saved successfully.";
            messageClass = "text-green";
        }

        handleReloadOrderLines(message, messageClass);
        onCloseModal(false, false, false);
    }


    // Material Pickup Modal --------------------------------------

    function returnMaterialPickupModalComponent(olIds, ols) {
        return <MaterialPickupModal orderLineIds={olIds} orderLines={ols}
            onCloseModal={onCloseMaterialPickupModal}></MaterialPickupModal>;
    }

    function onOpenMaterialPickupModal(e) {
        PreventDefault(e);
        clearAllMessages();

        var ols = getRowNodesWithSort(materialPickup);
        if (NotEmpty(ols)) {
            var olIds = [];
            setModalClass("add-line-modal");
            setModalContent(returnMaterialPickupModalComponent(olIds, ols));
            onOpenModal();
        }
        else {
            updateMessage("No lines are available for pickup.", "text-primary");
        }
    }

    function onCloseMaterialPickupModal(e, linesDelivered) {
        PreventDefault(e);

        //reload to be sure any changes reflected
        var message;
        var messageClass;
        if (linesDelivered === true) {
            message = "Line(s) delivered successfully.";
            messageClass = "text-green";
        }
        handleReloadOrderLines(message, messageClass);
        onCloseModal(false, false, false);
    }

    // Add Payment Modal --------------------------------------

    function onOpenPaymentModal(e) {
        PreventDefault(e);
        clearAllMessages();
        setPaymentModalContent(<PaymentModal orderId={orderId} onClose={onClosePaymentModal} />);
        setIsPaymentsModalOpen(true);
    }

    function onClosePaymentModal(result) {
        if (result && result.success) {
            setPaymentResult(result.message);
            setMessage(result.message);
            setMessageClass("text-green");
            // reloads totals
            handleReloadOrderLines(result.message, "text-green");
        }
        setPaymentModalContent(null);
        setIsPaymentsModalOpen(false);
    }

    // Activity Log Modal --------------------------------------

    function onOpenActivityLogModal(e) {
        PreventDefault(e);
        clearAllMessages();
        setActivityLogModalContent(<AuditEntriesModal orderId={orderId}
            orderNum={orderData.orderNumber} onClose={onCloseActivityLogModal} />);
    }

    function onCloseActivityLogModal(e) {
        PreventDefault(e);
        setActivityLogModalContent(null);
    }

    // Receipts Modal --------------------------------------

    const handleOpenRecieptsModal = () => setIsReceiptsModalOpen(true);
    function handleCloseReceiptsModal(result) {
        setIsReceiptsModalOpen(false);
        if (result && result.success) {
            setReceiptsResult(result.message);
            setMessage(result.message);
        }
    }

    // base modal methods  --------------------------------------

    function onOpenModal() {
        handleOrderModalShow();
    }

    function onCloseModal(openJobInfo, createNewOrder, openClaimAccount) {
        setModalClass("");
        handleOrderModalHide();
        setModalContent(null);
        //reset modal type bool
        setIsQuoteOrderSearch(false);
        //uncheck all boxes after action
        cbAllSetClick(false);

        if (IsTrue(openJobInfo) || IsTrue(openClaimAccount)) {
            ClickById("btnOpenJobOrClaimAccountModal");
        }
        else if (IsTrue(createNewOrder)) {
            SaveOrder();
        }
    }

    const handleOrderModalShow = useCallback(() => {
        orderModalRef.current?.showModal();
    }, [orderModalRef]);

    const handleOrderModalHide = useCallback(() => {
        orderModalRef.current?.close();
    }, [orderModalRef]);


    /****** db methods ******/

    // Claim ----------------------------------------------------

    function openClaimOrder(e) {
        PreventDefault(e);

        if (IdExists(orderData.claimForOrderId)) {
            openNewPage(e, "/Redirect?route=EditOrder&orderId=" + orderData.claimForOrderId);
        }
        else {
            console.error("openClaimOrder() claimForOrderId: " + orderData.claimForOrderId);
            alert("No Order found for Claim.");
        }
    }

    async function createClaim(e) {
        PreventDefault(e);

        var selOlIds = getRowNodesWithSort(typeOlIds, true);
        if (checkLinesNotEmpty(selOlIds)) {
            var resp = await CreateClaimPost(userId, selOlIds);

            var billGroupId = ParseIntSafe(resp);
            if (IdExists(billGroupId)) {
                setBillingGroupId(billGroupId);
                onOpenBillGroupModal(e, billGroupId);
            }
            else {
                //resp has error info
                var error = resp;
            }
        }
    }

    const handleReloadOrderLines = async (message, cssClass) => {
        var success = false;
        const endpoint = 'orders/GetOrder/' + orderId + '/0/0';
        try {
            const response = await get(endpoint);
            //console.log('Response: ' + JSON.stringify(response));

            setOrderTotals(response);
            setOrderLines(response.orderLines);

            setPoMenuItemsView(response.orderLines);
            updateOrderLinesMessage(message, cssClass);
            success = true;
        } catch (error) {
            console.log('Error: ' + error);
        }

    }

    const handlePriceLevelChange = async (e) => {
        var data = handleFieldChange(e);
        onOrderDataChange(data);
        //save and reload order to apply new pricing onchange
        SaveOrder();
    };

    const handleChange = (e) => {
        var data = handleFieldChange(e);
        onOrderDataChange(data);
    };

    async function onOrderDataChange(data) {
        var success = false;
        hideValidationMessages();

        SetValueById("hfFormDirty", "1");
        //setFormDirty(true);        
        //formDirty = true;

        if (data) {
            setOrderData((orderData) => ({
                ...orderData,
                [data.name]: data.value
            }));
            success = true;
        }
        else {
            //updateMessage(defaultErrMsg, "text-red", "null handleChange data");
        }
        return success;
    }

    function setOrderTotals(orderData) {
        setOrderLinesTotal(orderData.orderLinesTotal);
        setTotal(orderData.orderTotalStr);
        setSalesTax(orderData.salesTax);
        setPayments(orderData.payments);
        setOrderTotal(orderData.orderTotal);
        setBalance(orderData.balance);
        setReferral(orderData.referral);
    }

    function getQuoteOrderLinks(exportedQuoteOrders) {
        var links = [];

        if (NotEmpty(exportedQuoteOrders)) {
            exportedQuoteOrders.forEach((o) => {
                links.push(
                    <span className="mr-1">
                        <a href={"/Redirect?route=EditQuote&orderId=" + o.orderId} target="_blank" rel="noreferrer"
                            className="underline text-primary text-[14px]">
                            {o.orderNumber}
                        </a>
                    </span>
                );
            });
        }
        return links;
    }

    function setPoMenuItemsView(ols, isInventoryType) {
        setCanEmailSubmittal(false);
        setCanCreatePo(false);
        setCanEditPo(false);

        if (IsEmpty(ols)) {
            ols = orderLines;
        }

        if (NotEmpty(ols)) {
            ols.forEach((ol, index) => {
                // poCreate
                if (((ol.statusId === 0 || ol.statusId === 1 || ol.statusId === 10) ||
                    // only add POs for Stock Item for Inventory Order
                    (isInventory(isInventoryType) && ol.statusId === 15))) {
                    setCanEmailSubmittal(true);
                    setCanCreatePo(true);
                    return;
                }
                // poEdit
                else if (IdExists(ol.purchaseOrderLineId)) {
                    setCanEditPo(true);
                    return;
                }
            });
        }
    }

    async function createOrder(data) {
        if (Exists(data)) {

            //set page fields 
            setOrderTotals(data);

            if (locBillGroupId !== 0) {
                setBillingGroupId(locBillGroupId);
            }
            else {
                setBillingGroupId(data.billingGroupId);
            }

            //init AccountData model
            setAccountData(createAccountModel(null, null));
            if (data.accountId !== 0) {
                //set AccountData model for existing order accountId
                setAccountData(createAccountModel(data, null));
            }
            else if (data.orderId === 0 && accountId !== 0) {
                //create new order from Account
                data.accountId = accountId;
                saveOrderAccountId(accountId, data);
            }

            //set Claim Against Vendor for Claim order
            setClaimVendorData(data.claimAgainstVendor);

            setOrderData(data);
            setOrderLines(data.orderLines);

            setPoMenuItemsView(data.orderLines, data.isInventoryType)

            //success
            setError(false);
        }
        else {
            //should return empty model for new order
            //should not occur
            setError(true);
        }
        setLoading(false);
    }

    const handleLoadDetails = async (cUserId) => {

        console.log('cUserId: ' + cUserId);
        const endpoint = 'orders/GetOrder/' + orderId + '/' + cUserId + '/' + locBillGroupId;
        console.log('API URL: ' + endpoint);

        try {
            const response = await get(endpoint);
            //console.log('Response: ' + JSON.stringify(response));

            createOrder(response);
            
            var newOrderDisabled = response.orderId === 0;
            setAddLinesDisabled(newOrderDisabled);
            if (newOrderDisabled) {
                updateOrderLinesMessage("Save Order to Add POs and Lines", "text-primary");
            }

            var dt = "";
            var pt1 = "";
            var pt2 = "";
            var pt3 = "";            
            if (Equals(response.orderId, 0)) {
                dt = "Lumina New " + getOrderTypeStr();
                pt1 = "New " + getOrderTypeStr();
            }
            else {

                //data.orderTypeStr
                var ots = getOrderTypeStr(response.isOrderType, response.isQuoteType, response.isClaimType, response.isInventoryType);
                dt = "Lumina " + ots + " - " + response.orderNumber;
                pt1 = ots + " - ";
                pt2 = response.orderNumber;
                // show "Closed" for Closed Orders
                if (response.orderStatus === 2) {
                    pt3 = " (Closed)"
                }
                //show created,saved,etc...msg
                showOrderMessage(response.isOrderType, response.isQuoteType, response.isClaimType, response.isInventoryType);
            }
            setDocTitle(dt);
            SetPageTitle(pt1, pt2, pt3);
        } catch (error) {
            console.log('Error: ' + error);
            setLoading(false);
            setError(true);
        }
    }

    const handleBeforeUnload = (event) => {
        //TODO: working on change only? browser specific?
        //if (formDirty === true)
        //if (true)

        var formDirtyVal = GetValueById("hfFormDirty");
        if (formDirtyVal === "1") {
            event.preventDefault();
            event.returnValue = '';
        }
    };

    function hideValidationMessages() {
        clearAllMessages();
    }

    function validateData(model) {
        hideValidationMessages();
        var message = "";

        var valid = true;

        // Account and Job Name not required for Inventory Orders
        if (IsFalse(isInventory())) {

            if (!model.accountId || model.accountId === 0) {
                message += "Account is required.  ";
                valid = false;
            }
            if (!model.jobModel || !model.jobModel.jobName || model.jobModel.jobName === "") {
                message += "Job Name is required.  ";
                valid = false;
            }

            //Per CFM: not required
            //if (!model.salesperson1Id || model.salesperson1Id === 0) {
            //    updateMessage("Salesperson 1 is required", "text-red");
            //    return false;
            //}
        }

        if (isClaim()) {
            if (!IdExists(model.claimAgainstVendorId)) {
                message += "Claim Against - Vendor is required.  ";
                valid = false;
            }
        }

        if (valid !== true) {
            updateMessage(message, "text-red");
            return false;
        }
        else {
            return true;
        }
    }

    function closeReopenOrder(close, reopen) {
        if (IsTrue(close)) {
            SetValueById("hfSubmitType", "submitCloseOrder");
            SaveOrderHidden();
        }
        else if (IsTrue(reopen)) {            
            SetValueById("hfSubmitType", "submitReOpenOrder");
            SaveOrderHidden();
        }
        else {
            SetValueById("hfSubmitType", "");
            alert(defaultErrMsg());
        }
    }

    function deleteOrder(e) {
        SetValueById("hfSubmitType", "submitDelete");
        SaveOrderHidden();
    };

    function btnSubmitAndCloseClick(e, deleteOrder) {        
        if (IsTrue(deleteOrder)) {
            SetValueById("hfSubmitType", "submitDelete");
        }
        else {            
            SetValueById("hfSubmitType", "submitCloseSave");
        }
    };

    function SaveOrderHidden() {
        // do not clear out any special buttons on real button click or "Enter" key save
        ClickById("btnOrderHiddenSubmit");
    }

    function SaveOrder() {
        ClickById("btnOrderSubmit");
    }

    function btnSubmitClick(e) {
        // "btnOrderSubmit" button
        // clear out any special submit types on real button click or "Enter" key save
        SetValueById("hfSubmitType", "");
    };

    function EnterSave(e) {
        if (e.key === "Enter") {
            PreventDefault(e);
            SaveOrder();
        }
    }

    const handleSubmit = async (e) => {
        PreventDefault(e)
        clearAllMessages();

        var model = orderData;
        //console.log(JSON.stringify(model));

        if (isClaim()) {
            if (orderId === 0) {
                //set for validation
                model.jobModel.jobName = "Claim";
            }
        }

        var valid = validateData(model);
        if (IsTrue(valid)) {

            var bcReload = false;
            if (!isClaim()) {

                // BC n/a for Claims
                // if PriceLevelId has changed, save and reload order to update pricing
                const currPriceLevelVal = GetSelectItemValueById("priceLevelId");
                const currPriceLevelId = ParseIntSafe(currPriceLevelVal);

                // reload to update prices if BC changed
                bcReload = model.priceLevelId !== currPriceLevelId;
                // set Order data BC to new BC
                model.priceLevelId = currPriceLevelId;
            }

            // set order type for new order
            model.orderType = locOrderType;

            model.billingGroupId = billingGroupId ?? 0;
            model.userId = userId ?? 0;

            // submit types
            var submitType = GetValueById("hfSubmitType");

            // delete order hidden button
            var submitCloseSave = StringContains(submitType, "submitCloseSave");
            // delete order hidden button
            model.submitDelete = StringContains(submitType, "submitDelete");
            // close order hidden button
            model.submitCloseOrder = StringContains(submitType, "submitCloseOrder");
            // reopen order hidden button
            model.submitReOpenOrder = StringContains(submitType, "submitReOpenOrder");
            // clear out special submit types once used
            SetValueById("hfSubmitType", "");

            // set up POST                 
            var create = false;
            if (Equals(orderId, 0)) {
                create = true;
            }

            // always reload page for these types
            var reload = create || bcReload || model.submitCloseOrder || model.submitReOpenOrder;            

            // POST data
            var response = await SaveOrderPost(model);

            // POST response
            var success = false;
            //if (IsSuccessStandardResponseAndDataPOST(response)) {
            if (Exists(response)) {

                // response returns orderId and orderNumber if success
                var pOrderId = ParseIntSafe(response.orderId);
                if (IdExists(pOrderId)) {
                    success = true;
                    var rOrderNumber = response.orderNumber;

                    var orderStatus = "update";
                    var orderMessage = updateMsgSearch(rOrderNumber);
                    if (IsTrue(create)) {
                        orderStatus = "create";
                        orderMessage = createMsgSearch(rOrderNumber);
                    }
                    else if (IsTrue(model.submitCloseOrder)) {
                        orderStatus = "closed";
                        orderMessage = closedMsgSearch(rOrderNumber);
                    }

                    // if (redirectSubmit) {
                    if (IsTrue(submitCloseSave)) {
                        // redirect to Search on 'Close' link click
                        backToClick(orderStatus, orderMessage);
                    }
                    else {
                        clearAllMessages();

                        var rldlnk = "";
                        if (isQuote()) {
                            rldlnk = "/Redirect?route=EditQuote&orderId=" + pOrderId;
                        }
                        else if (isClaim()) {
                            rldlnk = "/Redirect?route=EditClaim&orderId=" + pOrderId;
                        }
                        else if (isInventory()) {
                            rldlnk = "/Redirect?route=EditInventoryOrder&orderId=" + pOrderId;
                        }
                        else {
                            rldlnk = "/Redirect?route=EditOrder&orderId=" + pOrderId;
                        }

                        if (IsTrue(reload)) {
                            var status = "update";
                            if (IsTrue(create)) {
                                status = "create";
                            }
                            else if (IsTrue(model.submitCloseOrder)) {
                                status = "closed";
                            }

                            loadPage(null, rldlnk + "&status=" + status);
                        }                        
                        else {
                            updateMessage(updateMsg(), "text-green");
                        }

                        // TODO: remove - opening Order in same tab as Search and redirecting back
                        //else if (IsTrue(submitCloseSave)) {
                        //    updateMessage(updateMsg(), "text-green");
                        //    var tabClosed = false;

                        //    // TODO: sometimes closes only open tab
                        //    //tabClosed = CloseTab();

                        //    // redirect to orders search if browser cant close                            
                        //    if (isQuote()) {
                        //        rldlnk = "/Redirect?route=Quotes&message=" + orderMessage;
                        //    }
                        //    else if (isClaim()) {
                        //        rldlnk = "/Redirect?route=Claims&message=" + orderMessage;
                        //    }
                        //    else {
                        //        rldlnk = "/Redirect?route=Orders&message=" + orderMessage;
                        //    }
                        //    if (IsFalse(tabClosed)) {
                        //        loadPage(null, rldlnk);
                        //    }
                        //}                        
                    }
                }
            }

            if (success === false) {
                console.error("handleSubmit(): ", JSON.stringify(response));
                alert(defaultErrMsg);
            }
        }

    }

    function convertIdsToQueryString(ids) {
        // Map each integer to a query string 'idN=value'
        const queryString = ids.map((id, index) => `id${index + 1}=${id}`).join('&');
        return queryString;
    }

    const printOrder = async (e) => {
        PreventDefault(e);
        clearAllMessages();

        const apiUrl = process.env.REACT_APP_API_URL + 'orders/PrintOrder/' + orderData.orderId;
        window.location.href = apiUrl;
    }

    const generateOrderPdfEmail = async (e) => {
        PreventDefault(e);
        clearAllMessages();

        try {
            // open email link from response
            const endpoint = 'orders/CreateEmailOrderLink/' + orderData.orderId;
            const responseLink = await get(endpoint);
            window.open(responseLink);
            //console.log('Response: ' + JSON.stringify(responseLink));

            // download Order pdf to browser
            const pdfApiUrl = process.env.REACT_APP_API_URL + 'orders/PrintOrder/' + orderData.orderId;
            window.location.href = pdfApiUrl;
            return true;

        } catch (error) {
            console.log('Error: ' + error);
        }
    }

    async function generatePoEmail() {
        //generate Po/Invoice email
        var pdfApiUrl = "";
        var selOlIds = getRowNodesWithSort(poCreate, true);
        if (checkLinesNotEmpty(selOlIds, "No selected lines can create a submittal email", "text-primary")) {
            handleCreatePoEmail(selOlIds);            
        }

        //if (NotStringEmpty(pdfApiUrl)) {
        //    // generate and download pdf
        //    setTimeout(() => {
        //        window.location.href = pdfApiUrl;
        //    }, 500);
        //}
    }

    const handleCreatePoEmail = async (olIds) => {
        clearAllMessages();

        var idsQuery = convertIdsToQueryString(olIds);
        if (NotEmpty(olIds)) {
            try {
                // open email link from response
                const endpoint = 'orders/CreatePoEmailLink?' + idsQuery;
                const openEmailLink = await get(endpoint);
                //console.log('Response: ' + JSON.stringify(openEmailLink));

                if (NotStringEmpty(openEmailLink)) {

                    // show updated for line statuses set to Emailed
                    const resp = await handleReloadOrderLines("Lines updated", "text-green");

                    setTimeout(() => {
                        // open email application
                        window.open(openEmailLink);

                        // generate and download pdf
                        const pdfApiUrl = process.env.REACT_APP_API_URL + 'orders/PrintPo?' + idsQuery;
                        window.location.href = pdfApiUrl;
                    }, 500);
                }
            } catch (error) {
                console.log('Error: ' + error);
            }
        }
        return "";
    }

    function showOrderMessage(isOrderType, isQuoteType, isClaimType, isInventoryType) {
        if (location.state && location.state.orderStatus) {
            var status = location.state.orderStatus;
            if (status === "create") {
                updateMessage(createMsg(isOrderType, isQuoteType, isClaimType, isInventoryType), "text-green");
            }
            else if (status === "update") {
                updateMessage(updateMsg(isOrderType, isQuoteType, isClaimType, isInventoryType), "text-green");
            }
            else if (status === "closed") {
                updateMessage(closedMsg(isOrderType, isQuoteType, isClaimType, isInventoryType), "text-green");
            }
        }
    }

    function setUserFields() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        var cUserId = 0;
        //console.log('User userData: ' + JSON.stringify(userData));
        //console.log('User userData - UserId: ' + userData.userId);
        if (userData) {
            cUserId = userData.userId;
            setUserId(userData.userId);
            setUser(userData);
        }
        return cUserId;
    }

    // ON LOAD
    useEffect(() => {
        //todo: get working but not on order create
        //window.addEventListener('beforeunload', handleBeforeUnload);

        var cUserId = setUserFields();
        if (cUserId === 0) {
            ClickById("lnkMenuLogin");
        }
        else {
            loadDataLists();
            handleLoadDetails(cUserId);

            setTimeout(() => {
                if (orderId === 0) {
                    createNewOrderLoad();
                }
            }, 1000);
        }
        //return () => {
        //    window.removeEventListener('beforeunload', handleBeforeUnload);
        //};
    }, []);

    if (loading === true) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }
    if (error === true)
        return <div className="text-red-500 text-center mt-[25%] text-xl">{defaultErrMsg}</div>;

    return (
        <div className="order-details-page">
            <div className="order-details-page-container !block pt-0">
                <div className="p-2 pt-0">
                    <div className="">

                        <form className="order-form" onSubmit={handleSubmit}>

                            <div className="float-left pt-2">
                                <input id="hfFormDirty" type="hidden" value="0"></input>
                                {/*<a href="#" onclick={e => CloseTab(e)} //href="/Orders"*/}
                                {/*    className="link-primary underline font-bold">*/}
                                {/*    Back to Orders*/}
                                {/*</a>*/}
                                <button type="submit" className="link-primary underline font-bold"
                                    onClick={(e) => btnSubmitAndCloseClick(e)}>
                                    Close
                                </button>
                            </div>

                            <div className="order-content m-auto">
                                <div className="table-cell-div pr-[60px]">
                                    <div className="fourth-block">
                                        {/*** Account ***/}
                                        <div className={"act-job bc-block "}>
                                            <div className="orh-bold">
                                                <div className="float-left act-label">
                                                    {!isClaim() &&
                                                        <span>Account Name:</span>
                                                    }
                                                    {isClaim() &&
                                                        <span>Claim Against:</span>
                                                    }
                                                </div>
                                                {(IsFalse(orderData.isSamplesOrder) && IsFalse(isInventory())) &&
                                                    <div className="float-right">
                                                        <button id="btnOpenAccountClaimVendorModal" type="button"
                                                            onClick={e => openAccountOrClaimVendor(e)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                            {!isClaim() &&
                                                <AccountVendorInfo accountData={accountData}></AccountVendorInfo>
                                            }
                                            {isClaim() &&
                                                <AccountVendorInfo vendorData={claimVendorData}></AccountVendorInfo>
                                            }
                                        </div>
                                        {!isClaim() &&
                                            <div className={"text-green text-center h-[14px] text-sm " + accountMessageClass}>
                                                {accountMessage}
                                            </div>
                                        }
                                        {isClaim() &&
                                            <div className={"text-green text-center h-[14px] text-sm " + claimVendorMsgClass}>
                                                {claimVendorMsg}
                                            </div>
                                        }

                                        <div className={"pt-2 " + hiddenForInventoryClass()}>
                                            <div className={hiddenForClaimClass()}>
                                                <div>
                                                    <div className="label-block-1">Price Level</div>
                                                    <div className="tbl-spcr"></div>
                                                    <div className="label-block-2">
                                                        <select className="sp-sel"
                                                            id="priceLevelId" name="priceLevelId"
                                                            onKeyDown={(e) => EnterSave(e)}
                                                            onChange={handlePriceLevelChange}
                                                            value={orderData.priceLevelId}
                                                        >
                                                            <option value="0">BC</option>
                                                            <option value="1">BC1</option>
                                                            <option value="2">BC2</option>
                                                            <option value="3">BC3</option>
                                                            <option value="5">BC5</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="tbl-spcr"></div>
                                                <div>
                                                    <div className="label-block-1">PO Number</div>
                                                    <div className="tbl-spcr"></div>
                                                    <div className="label-block-2">
                                                        <input id="poNumber" name="poNumber" type="text"
                                                            className="inp-text-order mark"
                                                            onKeyDown={(e) => EnterSave(e)}
                                                            onChange={handleChange}
                                                            value={orderData.poNumber}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="table-cell-div pr-[60px]">
                                    <div className="fourth-block">
                                        {/*** Job ***/}
                                        <div className={"act-job bc-block " + jobBoxClass}>
                                            <div className="orh-bold">
                                                <div className="float-left act-label">
                                                    {!isClaim() &&
                                                        <span>Job Name:</span>
                                                    }
                                                    {isClaim() &&
                                                        <span>Claim For:</span>
                                                    }
                                                </div>
                                                {IsFalse(isInventory()) &&
                                                    <div className="float-right">
                                                        <button id="btnOpenJobOrClaimAccountModal" type="button"
                                                            onClick={e => openJobOrClaimAccount(e)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                            {!isClaim() &&
                                                <JobInfo orderData={orderData}></JobInfo>
                                            }
                                            {isClaim() &&
                                                <AccountVendorInfo accountData={accountData}></AccountVendorInfo>
                                            }
                                        </div>
                                        {!isClaim() &&
                                            <div className={"text-green text-center h-[14px] text-sm " + jobMessageClass}>
                                                {jobMessage}
                                            </div>
                                        }
                                        {isClaim() &&
                                            <div className={"text-green text-center h-[14px] text-sm " + accountMessageClass}>
                                                {accountMessage}
                                            </div>
                                        }

                                        <div className={"pt-2 " + hiddenForInventoryClass()}>
                                            <div className={hiddenForClaimClass()}>
                                                <div>
                                                    <div className="label-block-1">Terms</div>
                                                    <div className="tbl-spcr"></div>
                                                    <div className="label-block-2">
                                                        <select id="termsId" name="termsId"
                                                            className="sp-sel1 w-[202px]"
                                                            onKeyDown={(e) => EnterSave(e)}
                                                            onChange={handleChange}
                                                            value={orderData.termsId}
                                                        >
                                                            <option value="0">None</option>
                                                            <option value="1">Cash</option>
                                                            <option value="2">Credit</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="tbl-spcr"></div>
                                            </div>

                                            <div>
                                                <div className="tbl-spcr"></div>
                                                <div>
                                                    <div className="label-block-1">Job Detail</div>
                                                    <div className="tbl-spcr"></div>
                                                    <div className="label-block-2">
                                                        <input id="jobNotes" name="jobNotes" type="text"
                                                            className="inp-text-order mark"
                                                            onKeyDown={(e) => EnterSave(e)}
                                                            onChange={handleChange}
                                                            value={orderData.jobNotes}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {isClaim() && IdExists(orderData.claimForOrderId) &&
                                                <div>
                                                    <div>
                                                        <div className="label-block-1">Claim for Order</div>
                                                        <div className="tbl-spcr"></div>
                                                        <div className="label-block-2">
                                                            <span onClick={openClaimOrder}
                                                                className="text-primary underline cursor-pointer">
                                                                {orderData.claimForOrder.orderNumber}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="tbl-spcr"></div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="table-cell-div pr-[60px]">
                                    {/*** Dates ***/}
                                    <div className="fourth-block">
                                        <div className="table-cell align-top">
                                            <div>
                                                <div className="label-block-1">
                                                    {getOrderTypeStr() + " Date"}
                                                </div>
                                                <div className="tbl-spcr"></div>
                                                <div className="label-block-2">
                                                    <input type="date" className="order-date1"
                                                        disabled="disabled" placeholder=""
                                                        id="orderDateInputStr" name="orderDateInputStr"
                                                        value={orderData.orderDateInputStr}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="tbl-spcr"></div>
                                            <div className={hiddenForQuoteClass() + hiddenForInventoryClass()}>
                                                <div className="label-block-1">Order Close Date</div>
                                                <div className="tbl-spcr"></div>
                                                <div className="label-block-2">
                                                    <input type="date" className="order-date1" placeholder=""
                                                        id="closedDateInputStr" name="closedDateInputStr"
                                                        disabled="disabled"
                                                        value={orderData.closedDateInputStr}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="tbl-spcr"></div>
                                            <div>
                                                <div className="label-block-1">Salesperson 1</div>
                                                <div className="tbl-spcr"></div>
                                                <div className="label-block-2">
                                                    <select id="salesperson1Id" name="salesperson1Id"
                                                        className="sp-sel1"
                                                        onKeyDown={(e) => EnterSave(e)}
                                                        onChange={handleChange}
                                                        value={orderData.salesperson1Id}
                                                    >
                                                        {userList}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="tbl-spcr"></div>

                                            <div className={hiddenForInventoryClass()}>
                                                <div>
                                                    <div className="label-block-1">Salesperson 2</div>
                                                    <div className="tbl-spcr"></div>
                                                    <div className="label-block-2">
                                                        <select id="salesperson2Id" name="salesperson2Id"
                                                            className="sp-sel1"
                                                            onKeyDown={(e) => EnterSave(e)}
                                                            onChange={handleChange}
                                                            value={orderData.salesperson2Id}
                                                        >
                                                            {userList}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="tbl-spcr"></div>

                                                <div className={hiddenForClaimClass()}>
                                                    <div className="label-block-1">Tax Status</div>
                                                    <div className="tbl-spcr"></div>
                                                    <div className="label-block-2">
                                                        <select id="taxTypeId" name="taxTypeId"
                                                            className="sp-sel1"
                                                            onKeyDown={(e) => EnterSave(e)}
                                                            onChange={handleChange}
                                                            value={orderData.taxTypeId}
                                                        >
                                                            {/*<option value="0">None</option>*/}
                                                            <option value="1">Taxable</option>
                                                            {/*<option value="2">Exempt</option>*/}
                                                            <option value="3">Retail</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="tbl-spcr"></div>

                                                <div className={showOnlyOrderClass()}>
                                                    <div className="label-block-1">Orignal Quote</div>
                                                    <div className="tbl-spcr"></div>
                                                    <div className="label-block-2">
                                                        {IdExists(orderData.quoteFromOrderId) && NotStringEmpty(orderData.quoteFromOrderNumber) &&
                                                            <a href={"/Redirect?route=EditQuote&orderId=" + orderData.quoteFromOrderId}
                                                                target="_blank" rel="noreferrer" className="underline text-primary text-[14px]">
                                                                {orderData.quoteFromOrderNumber}
                                                            </a>
                                                        }
                                                    </div>
                                                </div>

                                                <div className={showOnlyQuoteClass()}>
                                                    <div className="label-block-1">Order Number</div>
                                                    <div className="tbl-spcr"></div>
                                                    <div className="label-block-2">
                                                        {getQuoteOrderLinks(orderData.exportedQuoteOrders)}
                                                    </div>
                                                </div>
                                                <div className="tbl-spcr"></div>

                                                <div className="">
                                                    <div className="label-block-1"></div>
                                                    <div className="tbl-spcr"></div>
                                                    <div className="label-block-2"></div>
                                                </div>
                                                <div className="tbl-spcr"></div>

                                                <div className={showOnlyOrderClass()}>
                                                    <div className="label-block-1">RFMS Order Number</div>
                                                    <div className="tbl-spcr"></div>
                                                    <div className="label-block-2">
                                                        <input id="rfmsOrderNumber" name="rfmsOrderNumber"
                                                            type="text" className="inp-text-order !w-[172px]"
                                                            maxLength="8"
                                                            onKeyDown={(e) => EnterSave(e)}
                                                            onChange={handleChange}
                                                            value={orderData.rfmsOrderNumber}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"table-cell-div " + hiddenForInventoryClass()}>
                                    <div className="fourth-block w-[225px]">
                                        {/*** Costs ***/}
                                        <div>
                                            <div className="label-block-4">Total</div>
                                            <div className="tbl-spcr"></div>
                                            <div className="label-block-2">
                                                <span className="sp-cost-pad">$</span>
                                                <input type="number" className="order-cost"
                                                    id="orderLinesTotal" name="orderLinesTotal" disabled={true}
                                                    value={formatCurrencyDecimal(orderLinesTotal)}
                                                />
                                            </div>
                                        </div>
                                        <div className="tbl-spcr"></div>

                                        <div>
                                            <div className="label-block-4">Sales Tax</div>
                                            <div className="tbl-spcr"></div>
                                            <div className="label-block-2">
                                                <span className="sp-cost-pad">$</span>
                                                <input type="number" className="order-cost mark"
                                                    id="salesTax" name="salesTax" disabled={true}
                                                    value={formatCurrencyDecimal(salesTax)}
                                                />
                                            </div>
                                        </div>
                                        <div className="tbl-spcr"></div>

                                        <div>
                                            <div className="label-block-4">
                                                {getOrderTypeStr() + " Total"}
                                            </div>
                                            <div className="tbl-spcr"></div>
                                            <div className="label-block-2">
                                                <span className="sp-cost-pad">$</span>
                                                <input type="number" className="order-cost !font-bold"
                                                    id="orderTotal" name="orderTotal" disabled={true}
                                                    value={formatCurrencyDecimal(orderTotal)}
                                                />
                                            </div>
                                        </div>
                                        <div className="tbl-spcr"></div>

                                        <div>
                                            <div className="label-block-4">Balance</div>
                                            <div className="tbl-spcr"></div>
                                            <div className="label-block-2">
                                                <span className="sp-cost-pad">$</span>
                                                <input type="number" className="order-cost text-red font-bold"
                                                    id="balance" name="balance" disabled={true}
                                                    value={formatCurrencyDecimal(balance)}
                                                />
                                            </div>
                                        </div>
                                        <div className="tbl-spcr"></div>

                                        {IsFalse(isClaim()) &&
                                            <div>
                                                <div className="label-block-4">Referral</div>
                                                <div className="tbl-spcr"></div>
                                                <div className="label-block-2">
                                                    <span className="sp-cost-pad">$</span>
                                                    <input type="number" className="order-cost"
                                                        id="referral" name="referral" disabled={true}
                                                        value={formatCurrencyDecimal(referral)}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            {/*** Order Buttons ***/}
                            <div className="clear pt-5 text-left text-center">
                                <div>
                                    {isQuote() &&

                                        <div className="align-left order-btn-block">
                                            <Dropdown label="" className="order-pos-menu" dismissOnClick={true}
                                                renderTrigger={() =>
                                                    <button type="button" disabled={addLinesDisabled}
                                                        className="order-btn py-[5px] px-6 font-bold">
                                                        Convert to Order
                                                    </button>
                                                }>
                                                <Dropdown.Item onClick={e => QuoteCreateNewOrder()}>Create Order</Dropdown.Item>
                                                <Dropdown.Item onClick={e => onOpenQuoteOrderSearchModal()}>Add to Order</Dropdown.Item>
                                            </Dropdown>
                                        </div>
                                    }

                                    {(isOrder() || isClaim() || isInventory()) &&
                                        <div className="align-left order-btn-block">
                                            <Dropdown label="" className="order-pos-menu" dismissOnClick={true}
                                                renderTrigger={() =>
                                                    <button type="button" disabled={addLinesDisabled}
                                                        className="order-btn py-[5px] px-6 font-bold">
                                                        PO
                                                    </button>
                                                }>
                                                {canEmailSubmittal &&
                                                    <Dropdown.Item onClick={e => generatePoEmail()}>Email Submittal</Dropdown.Item>
                                                }
                                                {canCreatePo &&
                                                    <Dropdown.Item onClick={e => onOpenPoModal(true)}>Create PO</Dropdown.Item>
                                                }
                                                {canEditPo &&
                                                    <Dropdown.Item onClick={e => onOpenPoModal(false)}>View PO</Dropdown.Item>
                                                }
                                            </Dropdown>
                                        </div>
                                    }

                                    <div className="align-left order-btn-block">
                                        <button type="button" onClick={e => onOpenAddOlModal(e)}
                                            className="order-btn py-[5px] px-4 font-bold"
                                            disabled={addLinesDisabled} >
                                            Add Line
                                        </button>
                                        <Dropdown label="" className="order-pos-menu" dismissOnClick={true}
                                            renderTrigger={() =>
                                                <button type="button" disabled={addLinesDisabled}
                                                    className="order-btn py-[5px] px-4 font-bold">
                                                    Edit Line
                                                </button>
                                            }>
                                            <Dropdown.Item onClick={e => onOpenEditLinesModal(e)}>Edit Line</Dropdown.Item>
                                            {isOrder() &&
                                                <Dropdown.Item onClick={e => createClaim(e)}>Create Claim</Dropdown.Item>
                                            }
                                            {/*<Dropdown.Item onClick={voidClick()}>Notes</Dropdown.Item>*/}
                                            <Dropdown.Item onClick={e => onOpenDeleteLinesModal(e)}>Delete Line</Dropdown.Item>
                                        </Dropdown>
                                    </div>

                                    <div className="order-btn-block">
                                        <button type="button" onClick={e => printOrder(e)}
                                            className="order-btn py-[5px] px-4 font-bold">
                                            {"Print " + getOrderTypeStr()}
                                        </button>
                                        <button type="button" onClick={e => generateOrderPdfEmail(e)}
                                            className="order-btn py-[5px] px-4 font-bold">
                                            {"Email " + getOrderTypeStr()}
                                        </button>
                                    </div>

                                    <div className="order-btn-block">

                                        {(isOrder() || isClaim()) &&
                                            <Dropdown label="" className="order-pos-menu" dismissOnClick={true}
                                                renderTrigger={() =>
                                                    <button type="button" disabled={addLinesDisabled}
                                                        className="order-btn py-[5px] px-4 font-bold">
                                                        Payment
                                                    </button>
                                                }>

                                                <Dropdown.Item onClick={e => onOpenPaymentModal(e)}>Add Payment</Dropdown.Item>
                                                <Dropdown.Item onClick={e => handleOpenRecieptsModal()}>Receipts</Dropdown.Item>
                                            </Dropdown>
                                        }

                                        <Dropdown label="" className="order-pos-menu" dismissOnClick={true}
                                            renderTrigger={() =>
                                                <button type="button" disabled={addLinesDisabled}
                                                    className="order-btn py-[5px] px-4 font-bold">
                                                    Other
                                                </button>
                                            }>
                                            {isOrder() && IsTrue(orderData.canCloseOrder) &&
                                                <Dropdown.Item onClick={e => closeReopenOrder(true, false)}>Close Order</Dropdown.Item>
                                            }
                                            {isOrder() && IsTrue(orderData.canReOpenOrder) &&
                                                <Dropdown.Item onClick={e => closeReopenOrder(false, true)}>Open Order</Dropdown.Item>
                                            }
                                            {billingGroupId !== 0 && (isOrder() || isClaim()) &&
                                                <Dropdown.Item onClick={e => onOpenBillGroupModal(e, billingGroupId)}>View Billing Group</Dropdown.Item>
                                            }
                                            {billingGroupId === 0 && (isOrder() || isClaim()) &&
                                                <Dropdown.Item onClick={e => createBillingGroup(e)}>Create Billing Group</Dropdown.Item>
                                            }
                                            {/*<Dropdown.Item onClick={e => voidClick(e)}>Invoice Notes</Dropdown.Item>*/}
                                            {/*<Dropdown.Item onClick={e => voidClick(e)}>Internal Notes</Dropdown.Item>*/}
                                            {/*<Dropdown.Item onClick={e => voidClick(e)}>Attachments</Dropdown.Item>*/}
                                            <Dropdown.Item onClick={e => onOpenActivityLogModal(e)}>Activity Log</Dropdown.Item>
                                            {isOrder() &&
                                                <Dropdown.Item onClick={e => moveOrderToQuote(e)}>Move to Quote</Dropdown.Item>
                                            }
                                        </Dropdown>
                                    </div>

                                    {(isOrder() || isClaim()) &&
                                        <div className="order-btn-block">
                                            <button type="button" onClick={e => onOpenMaterialPickupModal(e)}
                                                className="order-btn py-[5px] px-4 font-bold"
                                                disabled={addLinesDisabled}>
                                                Material Pickup
                                            </button>
                                        </div>
                                    }

                                    <div className="order-btn-block pr-[1%]">
                                        <input type="hidden" value={orderData.orderId}></input>
                                        <button id="btnOrderSubmit" type="submit" className="order-btn py-[5px] px-4 font-bold"
                                            onClick={(e) => btnSubmitClick(e)}
                                        >
                                            <span>Save {getOrderTypeStr()}</span>
                                        </button>
                                        <button id="btnOrderHiddenSubmit" type="submit" className="hidden order-btn py-[5px] px-4 font-bold">
                                            <span>Hidden - Save {getOrderTypeStr()}</span>
                                        </button>

                                        <button id="btnDeleteSubmit" type="button" className="hidden order-btn-cancel py-[5px] px-4 font-bold"
                                            onClick={(e) => deleteOrder(e)}>
                                            <span>Delete {getOrderTypeStr()}</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="h-[24px]">
                                    <div className={"inline-block text-sm float-left pl-[2px] " + orderLinesMessageClass}>
                                        {orderLinesMessage}
                                    </div>
                                    <div className={"inline-block text-sm float-right pr-[2px] " + messageClass}>
                                        {message}
                                    </div>
                                </div>
                            </div>
                        </form>

                        {/*** Order Lines ***/}
                        <div className="ag-order-lines-div">
                            <div className="ag-theme-alpine ag-order-lines"
                            //style={{ height: gridHeight, width: '100%', }}
                            >
                                <AgGridReact
                                    headerHeight={32}
                                    rowData={orderLines}
                                    ref={olGridRef}
                                    gridOptions={gridOptions}
                                    columnDefs={columnDefs}
                                    onRowDoubleClicked={(e) => onRowDoubleClicked(e.data)}
                                    onRowDragEnd={onRowDragEnd}
                                    rowDragEntireRow={true}
                                    rowDragManaged={true}
                                    rowDragMultiRow={true}
                                    rowSelection='multiple'
                                    domLayout='autoHeight'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"order-detail-modal " + modalClass}>
                <Modal ref={orderModalRef} className="order-detail-modal-content">
                    {
                        isQuoteOrderSearch &&
                        <Button size="sm" color="ghost" shape="circle"
                            className="absolute right-0 mr-[35px] min-h-[1.5rem] h-[1.5rem] w-[1.5rem]"
                            onClick={(e) => onCloseModal()}
                        >x</Button>
                    }
                    <Modal.Body>
                        {modalContent}
                    </Modal.Body>
                </Modal>
            </div>

            {/* BillingGroup */}
            <div className="">
                <Modal ref={billGroupModalRef} className="order-bg-modal min-h-[700px] min-w-[1350px]">
                    <Modal.Body>
                        {billGroupModalContent}
                    </Modal.Body>
                </Modal>
            </div>

            {/* ActivityLog */}
            <div>
                {activityLogModalContent}
            </div>
            {/* Payments */}
            <div>
                <Modal open={isPaymentsModalOpen} className="max-w-4xl w-11/12 px-4 py-0 h-[850px]">
                    {/*<Modal.Actions>*/}
                    {/*    <Button onClick={onClose}>Close</Button>*/}
                    {/*</Modal.Actions>*/}
                    <Modal.Body>
                        {paymentModalContent}
                    </Modal.Body>
                </Modal>
            </div>

            {/* Receipts */}
            <div>
                <ReceiptsModal orderId={orderId} orderNum={orderData.orderNumber}
                    isOpen={isReceiptsModalOpen} onClose={handleCloseReceiptsModal} />
            </div>

            <input id="hfSubmitType" type="hidden" value=""></input>
            <a href="/" id="lnkLoadPage" className="hidden">Reload</a>
            <a href="/" id="lnkOpenNewPage" target="_blank" className="hidden" rel="noreferrer">Open</a>
        </div>
    );
}