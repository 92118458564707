import React, { Component } from 'react';
import { SearchReceivables } from './SearchReceivables';

export class Receivables extends Component {
    static displayName = Receivables.name;

    //constructor(props) {
    //    super(props);
    //}

    render() {
        return (
            <div>
                <SearchReceivables isPostPayments={false}></SearchReceivables>
            </div>
        );
    }
}
