import React, { useEffect, useRef, useState } from 'react';

export const TopazSignature = ({ onSignatureComplete }) => {
    const canvasRef = useRef(null);
    const [isScanning, setIsScanning] = useState(false);
    const [sigString, setSigString] = useState('');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://www.sigplusweb.com/SigWebTablet.js";
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            if (window.IsSigWebInstalled()) {
                console.log('SigWeb is installed and ready');

            } else {
                console.log('SigWeb is not installed');
            }
        };

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const startScan = () => {
        if (window.IsSigWebInstalled()) {
            const ctx = canvasRef.current.getContext('2d');
            window.SetDisplayXSize(500);
            window.SetDisplayYSize(100);
            window.SetTabletState(0, null);
            window.SetJustifyMode(0);
            window.ClearTablet();
            window.SetTabletState(1, ctx, 50);

            // Display "Please sign here" on the canvas
            //ctx.font = '16px Arial';
            //ctx.fillStyle = 'rgba(156, 163, 175, 0.5)'; // Tailwind gray-400 with 50% opacity
            //ctx.textAlign = 'center';
            //ctx.fillText('Please sign here', 250, 80);

            setIsScanning(true);
        } else {
            console.log('SigWeb is not installed');
        }
    };

    const stopScan = () => {
        if (window.NumberOfTabletPoints() === 0) {
            console.log('Please sign before stopping');
            return;
        }

        window.SetTabletState(0, null);
        window.SetSigCompressionMode(1);
        const sigString = window.GetSigString();
        setSigString(sigString);

        window.SetImageXSize(500);
        window.SetImageYSize(100);
        window.GetSigImageB64(handleSignatureImage);

        setIsScanning(false);
        clearSignature();
    };

    const handleSignatureImage = (imageData) => {
        onSignatureComplete(imageData);
    };

    const clearSignature = () => {
        if (window.IsSigWebInstalled()) {
            window.ClearTablet();
            setSigString('');
            startScan(); // Restart the signature process
        }
    };

    return (
        <div className="topaz-signature max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">Signature Capture</h2>
            <div className="mb-4">
                <canvas
                    ref={canvasRef}
                    width={500}
                    height={100}
                    className="border-2 border-gray-300 rounded-md"
                />
            </div>
            <div className="flex space-x-4">
                {!isScanning ? (
                    <button
                        onClick={startScan}
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition"
                    >
                        Start Signature
                    </button>
                ) : (
                    <>
                        <button
                            onClick={stopScan}
                            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition"
                        >
                            Done
                        </button>
                        <button
                            onClick={clearSignature}
                            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition"
                        >
                            Clear
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};