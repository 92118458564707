import React from 'react';

//import { LoadOrderDrawerData } from './../_orderSearchHelpers.js';

//import BillGroupIconSvg from '../../../images/bill_group_icon.svg';
import BillGroupIconPng from '../../../images/bill_group_icon.png';
import ClaimIconSvg from '../../../images/claim_icon.svg';

export const OrderIconsCellRenderer = (props) => {

    const order = props.data ? props.data : null;
    const orderId = order ? order.orderId : 0;

    const isOrder = order.isOrderType ?? false;
    const isQuote = order.isQuoteType ?? false;
    const isClaim = order.isClaimType ?? false;

    const hasBillGroup = order.hasBillingGroup ?? false;
    const hasClaim = order.hasClaim ?? false;
    const hasNotes = order.hasNotes ?? false;
    const hasAttachments = order.hasAttachments ?? false;

    //per CFM: remove payment icon
    //const hasPayment = order.hasPayment;

    // TODO: remove
    //function LoadOrderDrawer(type) {                
    //    //only send in orderId and pull order from DB?
    //    LoadOrderDrawerData(order, orderId, type);
    //}

    return (
        <div className="text-left">
            {/*** Remove order drawer and info icon ***/}
            {/*<div className="orders-grid-icon">*/}
            {/*    <button type="button" className="text-gray-500"*/}
            {/*        onClick={(e) => LoadOrderDrawer("details")}>*/}
            {/*        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">*/}
            {/*            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />*/}
            {/*        </svg>*/}
            {/*    </button>*/}
            {/*</div>*/}

            {isOrder && hasBillGroup &&
                <div className="orders-grid-icon">
                    <button type="button" className="text-black">
                        <img src={BillGroupIconPng} width="16" alt="" className="" />
                    </button>
                </div>
            }
            {isOrder && hasClaim &&
                <div className="orders-grid-icon">
                    <button type="button" className="text-black">
                        <img src={ClaimIconSvg} width="11" alt="" className="claims-icon" />
                    </button>
                </div>
            }
            {hasNotes &&
                <div className="orders-grid-icon p-[3px]">
                    <button type="button" className="text-black">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                            className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                        </svg>
                    </button>
                </div>
            }

            {/*** Remove payments icon ***/}
            {/*    hasPayment &&*/}
            {/*        <div className="orders-grid-icon">*/}
            {/*            <button type="button" className="text-gray-500"*/}
            {/*                //onClick={(e) => LoadOrderDrawer("payments")}*/}
            {/*            >*/}
            {/*<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"*/}
            {/*    className="w-4 h-4">*/}
            {/*    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />*/}
            {/*</svg>*/}
            {/*            </button>*/}
            {/*        </div>*/}
            {/*}*/}
            {hasAttachments &&
                <div className="orders-grid-icon !p-[0px]">
                    <button type="button" className="text-black"
                    //onClick={(e) => LoadOrderDrawer("attachments")}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                            className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                        </svg>
                    </button>
                </div>
            }
        </div>
    );
}


