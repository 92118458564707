import React from 'react';

export const VendorAddressRenderer = (props) => {

    //debugger;
    const vendor = props.data ? props.data : null;
    //var addr = account ? account.address : null;
    //var addr = vendor;

    var payToName = vendor.payToName ? vendor.payToName : null;

    //var address1 = addr.payToAddress1 ? addr.payToAddress1 : null;
    //var address2 = addr.payToAddress2 ? addr.payToAddress2 : null;
    //var city1 = addr.payToCity ? addr.payToCity : null;
    //var state1 = addr.payToState ? addr.payToState : null;
    //var zipCode1 = addr.payToZip ? addr.payToZip : null;

    return (
        <div className="ag-cell-div">
            <div className="ag-cell-title">
                {
                    payToName && <span>{payToName}</span>
                }                
            </div>            
            {/*<div className="ag-cell-content">                */}
            {/*    {*/}
            {/*        address1 && <span>{address1}</span>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        address1 && address2 && <span>, </span>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        address2 && <span>{address2}</span>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        city1 && <span>, {city1}</span>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        state1 && <span>, {state1}</span>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        zipCode1 && <span> {zipCode1}</span>*/}
            {/*    }*/}
            {/*</div>*/}
        </div>
    );
};
