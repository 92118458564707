import React, { Component } from 'react';
import { initFlowbite } from "flowbite";

import { OrderDetail } from './OrderDetail';

export class OrderParent extends Component {
    static displayName = OrderParent.name;

    //constructor(props) {
    //    super(props);        
    //}

    render() {
        return (
            <OrderDetail></OrderDetail>
        );
    }
}
