import React from 'react';

import { ClickById } from '../../../js/helpers.js';
//import { ToggleAllOrderLines, OpenCloseAllOrderLines } from './../_orderSearchHelpers.js';

export const OrderLinesHeaderRenderer = () => {

    function toggleAllOrderLinesHeader() {

        //disabled because only applies to viewport/
        //or overrides individual show/hide
        //ToggleAllOrderLines();
    }

    function openCloseOrderLinesHeader(open) {

        //disabled because only applies to viewport/
        //or overrides individual show/hide
        //OpenCloseAllOrderLines(open);

        //click correct button in OrdersGrid.js
        if (open === true) {
            ClickById("btnHdrOpenDetails");
        }
        else {
            ClickById("btnHdrCloseDetails");
        }
    }

    return (
        //div className="table-cell header-cell-details">Details</div>
        //onClick={() => toggleAllOrderLinesHeader()}>
        //onClick={() => openCloseOrderLinesHeader(false)}>
        <div>
            <input id="hfToggleOrderLines" type="hidden" value="false"></input>
            <div className="table-cell header-cell-details underline cursor-pointer"
                onClick={() => openCloseOrderLinesHeader(true)}>
                Open All
            </div>
            <div className="table-cell px-2">/</div>
            <div className="table-cell header-cell-details underline cursor-pointer"
                onClick={() => openCloseOrderLinesHeader(false)}>
                Close All
            </div>
            {/*<div className="line-icon-expand pl-4">*/}
            {/*    <div className="cursor-pointer"*/}
            {/*        onClick={() => openCloseOrderLinesHeader(false)}>*/}
            {/*        <svg className="line-icon-expand-svg1 w-3 h-3 shrink-0" data-accordion-icon aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">*/}
            {/*            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />*/}
            {/*        </svg>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="line-icon-expand pl-4">*/}
            {/*    <div className="cursor-pointer"*/}
            {/*        onClick={() => openCloseOrderLinesHeader(true)}>*/}
            {/*        <svg className="line-icon-expand-svg2 w-3 h-3 rotate-180 shrink-0" data-accordion-icon aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">*/}
            {/*            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />*/}
            {/*        </svg>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};