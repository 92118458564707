import React, { useState } from 'react';

// TABPANEL
export const TabPanel = ({ children, value, index, icon, alt }) => {
  return value === index ? (
    <div>
      {children}
    </div>
  ) : null;
};

// TABS
export const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  const changeTab = (index) => {
    setActiveTab(index);
  };

  return (
    <div>
      <div className="tab-buttons">
        {React.Children.map(children, (child, index) => (
          <button onClick={() => changeTab(index)} disabled={child.props.disabled}>
              <p style={{ display: "inline-flex" }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
                  <path stroke-linecap="round" stroke-linejoin="round" d={child.props.svgPath} />
                </svg>
                  {child.props.label}
              </p>
          </button>
        ))}
      </div>
      <div className="tab-content">
        {React.Children.map(children, (child, index) => (
          React.cloneElement(child, { value: activeTab, index })
        ))}
      </div>
    </div>
  );
};
