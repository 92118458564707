import React, { useState } from 'react';
import { Component } from 'react';
import { initFlowbite } from "flowbite";

import { ProductPricingGrid } from './ProductPricingGrid';

export class ProductPricingSearch extends Component {
    static displayName = ProductPricingSearch.name;

    constructor() {
        super();
        this.state = { data: [], loading: true };
    }

    componentDidMount() {
        initFlowbite();
    }

    render() {
        return (
            <div className="p-2">
                <ProductPricingGrid importExport={false}></ProductPricingGrid>
            </div>
        );
    }
}
