import React, { useState, useEffect, forwardRef } from 'react';
import { Table, Button } from 'flowbite-react';

import { Exists, IsEmail, IsEmpty, IsFalse, IsTrue, NotEmpty } from '../../js/formHelpers.js';

export function ListData({ dataLines, type, deleteFunc }) {

    const isAccountNotes = type === "account-notes";
    const isAccountAttachments = type === "account-attachments";
        
    return (
        <div className="clear">
            <Table className=" w-full">
                <Table.Body className="">
                    {
                        isAccountNotes && getAccountNotes()
                    }
                    {
                        isAccountAttachments && getAccountAttachments()
                    }
                </Table.Body>
            </Table>
        </div>
    );

    function getAccountNotes() {
        var lineList = [];

        if (dataLines) {
            dataLines.forEach((line, index) => {

                var rowDisabled = false;
                var rowCssClass = "";
                var cellCssClass = " p-2 text-black border-b";
                
                //set up row actions                   
                rowCssClass = rowDisabled === false ? "bg-white" : "bg-gray-400/20";                

                lineList.push(
                    <Table.Row key={index} className={rowCssClass}>

                        <Table.Cell className={cellCssClass + " w-[15px]"}>
                            {IsTrue(line.pinned) &&
                                <div className="table-cell">
                                    <svg className="w-5 h-5 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                            strokeWidth="2" d="M12 15a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm0 0v6M9.5 9A2.5 2.5 0 0 1 12 6.5" />
                                    </svg>
                                </div>
                            }
                            {IsTrue(line.important) &&
                                <div className="table-cell">
                                    <svg className="w-5 h-5 text-red" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                        <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0V8Zm-1 7a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z"
                                            clip-rule="evenodd" />
                                    </svg>
                                </div>
                            }
                        </Table.Cell>                       
                        <Table.Cell className={cellCssClass + " "}>
                            <div className="text-wrap">
                                {line.note}
                            </div>
                        </Table.Cell>                       
                        <Table.Cell className={cellCssClass + " text-right w-[200px]"}>
                            <div className="text-wrap">
                                {line.updatedStr}
                            </div>
                        </Table.Cell>                       
                        <Table.Cell className={cellCssClass + " w-[10px]"}>
                            <Button onClick={(e) => deleteFunc(e, line.accountNoteId)} size="lg" color="ghost" shape="circle"
                                className="min-h-[1.5rem] h-[1.5rem] w-[1.5rem] border-none">x</Button>
                        </Table.Cell>                       
                    </Table.Row>
                );
            });
        }
        return lineList;
    }

    function openAttachment(attId) {
        const pdfApiUrl = process.env.REACT_APP_API_URL + 'data/DownloadAccountAttachment/' + attId;
        window.location.href = pdfApiUrl;
    }

    function getAccountAttachments() {
        var lineList = [];

        if (dataLines) {
            dataLines.forEach((line, index) => {

                var rowDisabled = false;
                var rowCssClass = "";
                var cellCssClass = " p-2 text-black border-b ";

                //set up row actions                   
                rowCssClass = rowDisabled === false ? "bg-white" : "bg-gray-400/20";

                lineList.push(
                    <Table.Row key={index} className={rowCssClass}>                        
                        <Table.Cell className={cellCssClass + " "}>
                            <div className="text-wrap">
                                <span onClick={e => openAttachment(line.accountAttachmentId)} className="underline text-primary cursor-pointer">
                                    {line.name}
                                </span>
                                {/*<a href={} target="_blank" className="underline text-primary">{line.name}</a>*/}
                            </div>
                        </Table.Cell>
                        <Table.Cell className={cellCssClass + " "}>
                            <div className="text-wrap">
                                {line.description}
                            </div>
                        </Table.Cell>
                        <Table.Cell className={cellCssClass + " text-right w-[200px]"}>
                            <div className="text-wrap">
                                {line.createdStr}
                            </div>
                        </Table.Cell>
                        <Table.Cell className={cellCssClass + " w-[10px]"}>
                            <Button onClick={(e) => deleteFunc(e, line.accountAttachmentId)} size="lg" color="ghost" shape="circle"
                                className="min-h-[1.5rem] h-[1.5rem] w-[1.5rem] border-none">x</Button>
                        </Table.Cell>
                    </Table.Row>
                );
            });
        }
        return lineList;
    }
}