import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { get, post } from '../../services/apiService';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { defaultErrMsg, StringEmpty } from '../../js/formHelpers';

export function EditJournalEntryForm({ journalEntryData, onJournalEntryUpdated, isParent = true }) {

    const [chartAccounts, setChartAccounts] = useState([]);
    const [submitType, setSubmitType] = useState(null);

    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');

    const [formData, setFormData] = useState({
        journalEntryBatchId: 0,
        name: '',
        journalEntryLines: []
    });

    const gridRef = useRef();
    const columnDefs = useMemo(() => [
        {
            headerName: 'Chart Account', cellClass: ["p-0"], field: 'chartAccountNumber',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: chartAccounts.map(account => account.accountNumber)
            },
            onCellValueChanged: (params) => {
                const chartAccount = chartAccounts.find(account => account.accountNumber === params.newValue);
                params.data.chartAccountName = chartAccount?.accountName || '';
                params.data.chartAccountId = chartAccount?.chartAccountId || '';
                gridRef.current.api.refreshCells({ rowNodes: [params.node], columns: ['chartAccountName'] });
            }
        },
        { headerName: 'Account Name', field: 'chartAccountName', editable: false },
        { headerName: 'Debit', field: 'debit', },
        { headerName: 'Credit', field: 'credit', },
        { headerName: 'Description', field: 'description', },
        { headerName: 'Comment', field: 'comment', },

    ], []);

    const defaultColDef = useMemo(() => ({
        minWidth: 100,
        flex: 1,
        editable: true,
        sortable: true,
        resizable: true,
        filter: true,
        suppressMovable: true,
        suppressMenu: false,
        cellClass: ["no-border"],
        menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab",],
    }), []);

    const onCellValueChanged = useCallback((params) => {

        const updatedLines = [...formData.journalEntryLines];
        updatedLines[params.rowIndex] = { ...params.data };

        setFormData(prevState => ({ ...prevState, journalEntryLines: updatedLines }));
    }, []);

    const onAddRow = useCallback(() => {

        const newLine = {
            journalEntryLineId: 0,
            chartAccountNumber: '',
            chartAccountName: '',
            chartAccountId: '',
            journalEntryBatchId: formData.journalEntryBatchId,
            debit: 0,
            credit: 0,
            description: '',
            comment: '',
        };

        const res = gridRef.current.api.applyTransaction({
            add: [newLine],
            addIndex: 0
        });

        setTimeout(() => {
            gridRef.current.api.startEditingCell({
                rowIndex: res.add[0].rowIndex,
                colKey: 'chartAccountNumber'
            });
        }, 0);

    }, []);

    const onRemoveSelected = useCallback(() => {
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        const selectedData = selectedNodes.map(node => node.data);
        const remainingLines = formData.journalEntryLines.filter(
            line => !selectedData.includes(line)
        );

        setFormData(prevState => ({ ...prevState, journalEntryLines: remainingLines }));
    }, []);

    const handleNameChange = (e) => {
        setFormData({ ...formData, name: e.target.value });
    };

    function submitTypeClick(type) {
        setSubmitType(type);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        //console.log('Form Data:', formData);

        const { debitTotal, creditTotal } = calcDebitCreditTotals();
        if (debitTotal !== creditTotal) {
            setMessage('Error! Debit and credit do not balance, correct these fields to continue');
            setMessageType('error');
            return;
        }

        try {

            if (StringEmpty(submitType)) {
                setMessage(defaultErrMsg());
                setMessageType('error');
                return;
            }

            // can only edit Journal Batches, so isParent should always be true
            formData.submitType = submitType;
            if (isParent) {
                formData.batchName = formData.name;
                //console.log('Updating Journal Entry Batch:', formData);
                var result = await post(`/journalentrybatch/UpdateJournalEntryBatch`, formData);
            }
            //else {
            //    formData.lineName = formData.name;
            //    //console.log('Updating Journal Entry Line:', formData);
            //    var result = await post(`/journalentrylines/UpdateJournalEntryLine`, formData);
            //}

            setMessage('Journal Entry Updated Successfully');
            setMessageType('success');
            onJournalEntryUpdated();

        } catch (error) {
            setMessage('Error updating Journal Entry');
            setMessageType('error');
        }
    };

    const calcDebitCreditTotals = () => {
        const debitTotal = formData.journalEntryLines.reduce((amount, entry) => amount + (Number(entry.debit) || 0), 0);
        const creditTotal = formData.journalEntryLines.reduce((amount, entry) => amount + (Number(entry.credit) || 0), 0);
        return { debitTotal, creditTotal };
    };

    function setupBatchData() {
        if (journalEntryData) {

            let entryLines = journalEntryData.journalEntryLines.map(item => ({
                journalEntryLineId: item.journalEntryLineId,
                chartAccountNumber: item.chartAccount.accountNumber,
                chartAccountName: item.chartAccount.accountName,
                chartAccountId: item.chartAccount.chartAccountId,
                journalEntryBatchId: item.journalEntryBatchId,
                debit: item.debit,
                credit: item.credit,
                description: item.description,
                comment: item.comment,
            }));

            setFormData({
                journalEntryBatchId: journalEntryData.journalEntryBatchId,
                name: isParent ? journalEntryData.batchName : journalEntryData.lineName,
                journalEntryLines: entryLines,
            });
            setLoading(false);
        }
    }

    const fetchChartAccounts = async () => {
        try {
            const response = await get(`/chartaccount/GetChartAccounts`);
            setChartAccounts(response);
        } catch (error) {
            console.error('Error fetching chart accounts:', error);
            setChartAccounts([]);
        }
    };

    
    useEffect(() => {
        //console.log('Journal Entry Data:', journalEntryData);

        fetchChartAccounts();
        setupBatchData();
    }, []);

    if (loading) {
        return <div className="text-center">Loading...</div>;
    }

    return (
        <div className="page-wrapper !p-0">
            <h3 className="text-xl text-gray-900 text-center font-bold">Edit Journal Batch</h3>

            <form onSubmit={handleSubmit} className="">

                <div className="">
                    <div className="table-cell">
                        <label htmlFor="name" className="label-form pr-2">Journal Batch Name</label>
                    </div>
                    <div className="table-cell">
                        <input type="text" id="name" name="name" className="label-form-input !w-[300px]"
                            value={formData.name}
                            onChange={handleNameChange}
                            placeholder=""
                            required
                        />
                    </div>
                    <div className="table-cell">
                        <span className={`pl-2 ${messageType === 'success' ? ' text-green ' : ' text-red '}`}>
                            {message}
                        </span>
                    </div>
                </div>

                <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                    <AgGridReact
                        ref={gridRef}
                        columnDefs={columnDefs}
                        rowData={formData.journalEntryLines}
                        defaultColDef={defaultColDef}
                        onCellValueChanged={onCellValueChanged}
                    />
                </div>

                <div className="flex-wrapper pt-2">
                    <div className="flex-grow">
                        <button type="button" onClick={onRemoveSelected}
                            className="btn-small !bg-red-500">
                            Remove Selected
                        </button>
                    </div>
                    <div className="flex-grow text-right">
                        <button type="button" className="btn-submit" onClick={onAddRow} >
                            Add New Entry
                        </button>

                        <button type="submit" className="btn-submit"
                            onClick={e => submitTypeClick("save")}>
                            Save Journal Batch
                        </button>

                        <button type="submit" className="btn-submit !mr-0"
                            onClick={e => submitTypeClick("post")}>
                            Post Journal Batch
                        </button>
                    </div>
                </div>

                {/*<div className="flex pt-2">*/}
                {/*    <button type="button" onClick={onAddRow} className="btn-submit">*/}
                {/*        Add New Entry*/}
                {/*    </button>*/}

                {/*    <button type="submit" className="btn-submit">*/}
                {/*        Update Journal Batch*/}
                {/*    </button>*/}

                {/*    <button type="submit" className="btn-submit !mr-0">*/}
                {/*        Update Journal Batch*/}
                {/*    </button>*/}

                {/*    <button type="submit" className="btn-small ml-2"*/}
                {/*        onClick={e => submitTypeClick("save")}>*/}
                {/*        Save Journal {isParent ? 'Batch' : 'Entry'}*/}
                {/*    </button>*/}

                {/*    <button type="submit" className="btn-small ml-2"*/}
                {/*        onClick={e => submitTypeClick("post")}>*/}
                {/*        Post Journal {isParent ? 'Batch' : 'Entry'}*/}
                {/*    </button>*/}
                {/*</div>*/}

                <div>
                    <span className={`text-2xl 
                        ${calcDebitCreditTotals().debitTotal === calcDebitCreditTotals().creditTotal ? 'text-green-500' : 'text-red-500'}`}>
                        ◉
                    </span>
                    <span className="font-semibold pl-2 pr-2">Debit Total:</span>${calcDebitCreditTotals().debitTotal.toFixed(2)}
                    <span className="font-semibold pl-6 pr-2">Credit Total:</span>${calcDebitCreditTotals().creditTotal.toFixed(2)}
                </div>
            </form>
        </div>
    );
}