import React, { useEffect, useState } from 'react'; 
import { defaultErrMsg, Exists, NotEmpty, NotStringEmpty, RemoveSpacesStr, Timeout } from '../../js/formHelpers';
import { SetInputCheckedById, SetInputsCheckedByClass } from '../../js/helpers';

export default function RadioButtonList({ rblClassName, valuesList, initialValue, setValue }) {

    const [radioButtons, setRadioButtons] = useState(null);
    //const [selectedValue, setSelectedValue] = useState("");

    function onRadioChange(e) {
        if (Exists(e.target) && Exists(e.target.value)) {

            SetInputsCheckedByClass(rblClassName, false);
            SetInputCheckedById(e.target.id, true);
            //setSelectedValue(e.target.value);
            setValue(e.target.value);
        }
        else
        {
            alert(defaultErrMsg());
        }
    }

    function createRadioButtons() {
        var rbList = [];

        if (NotEmpty(valuesList)) {
            valuesList.forEach((rblValue, index) => {

                if (NotStringEmpty(rblValue)) {
                    var strVal = RemoveSpacesStr(rblValue);
                    var id = "rbc-" + strVal;

                    if (strVal === initialValue) {
                        rbList.push(
                            <span key={index + 1}>
                                <span className="pl-4 pr-1 font-bold text-secondary">{rblValue}</span>
                                <input type="radio" className={"mt-[-3px] cursor-pointer " + rblClassName}
                                    id={id} name={id}
                                    value={strVal}
                                    checked={true}
                                    onChange={e => onRadioChange(e)} />
                            </span>
                        );
                    }
                    else {
                        rbList.push(
                            <span key={index + 1}>
                                <span className="pl-4 pr-1 font-bold text-secondary">{rblValue}</span>
                                <input type="radio" className={"mt-[-3px] cursor-pointer " + rblClassName}
                                    id={id} name={id}
                                    value={strVal}
                                    onChange={e => onRadioChange(e)} />
                            </span>
                        );
                    }
                }
            });
        }                
        setRadioButtons(rbList);        
    }

    useEffect(() => {
        createRadioButtons();        
    }, []);

    return (
        <div className="">
            {Exists(radioButtons) &&
                radioButtons
            }
        </div> 
    );
}