// FORMATTERS
export const DecimalFormatter = (params) => {
  return params.value.toFixed(2);
};


export const DollarDecimalFormatter = (params) => {
  return `$${params.value.toFixed(2)}`;
};


export const DateFormatter = (dateString) => {
  if (dateString && dateString.length === 8) {
    return `${dateString.substring(4, 6)}-${dateString.substring(6, 8)}-${dateString.substring(0, 4)}`;
  }
  return dateString;
};

