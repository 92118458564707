import React, { useEffect } from 'react';

import { PreventDefault, formatCurrencyDecimal } from '../../../js/formHelpers.js';

export const MinQtyModal = (props) => {
    const onCloseModal = props.onCloseModal;

    const propsMinQuantity = props.minQuantity ?? 0;    
    const propsNewQuantity = props.newQuantity ?? 0;


    //Methods
    function onCloseQtyModal(e, allowOverride) {
        PreventDefault(e);
        onCloseModal(e, allowOverride, propsNewQuantity);        
    }

    function EnterSave(e) {
        if (e.key === "Enter") {
            onCloseQtyModal(e, true);
        }
    }

    // ON LOAD
    useEffect(() => {    
    }, []);

    return (
        <div className="w-[400px] pb-2">
            <div>
                <div className="text-center py-4">
                    <div className="font-bold mb-1 text-xl">
                        Minimum Quantity Adjustment
                    </div>
                </div>
                <div>
                    Selling Quantities in Multiples of:
                    <span className="pl-1 text-secondary font-bold">{formatCurrencyDecimal(propsMinQuantity)}</span>
                    <br />
                    Quantity will be adjusted to:
                    <span className="pl-1 text-secondary font-bold">{formatCurrencyDecimal(propsNewQuantity)}</span>
                    <br />
                </div>
            </div>
            <div className="align-bottom text-right pt-[20px]">
                <button type="button" className="btn-submit-modal px-1 mr-2"
                    autoFocus
                    onKeyUp={(e) => EnterSave(e)}
                    onClick={(e) => onCloseQtyModal(e, true)}>
                    Ok
                </button>
                <button type="button" className="btn-close-modal !text-white !bg-red-500"
                    onClick={(e) => onCloseQtyModal(e, false)}>
                    Override
                </button>
            </div>
        </div>
    );
}
