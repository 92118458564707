import React, { Component } from 'react';
import { initFlowbite } from "flowbite";

import { OrdersGrid } from './OrdersGrid';
import { PreventDefault } from '../../js/formHelpers';
//import { OrderDrawer } from './OrderDrawer';

export class OrderSearch extends Component {
    static displayName = OrderSearch.name;

    //constructor(props) {
    //    super(props);
    //}

    // TODO: remove
    //componentDidMount() {
    //    initFlowbite();
    //}
    //componentWillUnmount() {
    //}

    render() {
        return (
            <div>
                <OrdersGrid></OrdersGrid>
                {/*<OrderDrawer pageType="OrderSearch"></OrderDrawer>*/}
            </div>
        );
    }
}
