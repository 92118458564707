import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';

import { Checkbox } from 'flowbite-react';

import { GetValueById } from '../../../js/helpers';
import {
    CheckEnterKeyPress, Exists,  handleFieldChange, IsFalse,
    IsTrue, NotEmpty, NotExists, PreventDefault, 
}
from '../../../js/formHelpers';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { ModuleRegistry } from '@ag-grid-community/core';
import { MasterDetailModule } from 'ag-grid-enterprise'; //'@ag-grid-enterprise/master-detail';
import { get } from '../../../services/apiService';

ModuleRegistry.registerModules([MasterDetailModule]);


export const ProductSearchGrid = (props) => {
    //const [docTitle, setDocTitle] = SetDocumentTitle("");
    //const location = useLocation();
    //const navigate = useNavigate();

    const propsIsBundleAddType = props.isBundleAddType ?? false;
    const propsOnBundleAddClose = props.onBundleAddClose ?? null;

    const [bundleAddProductSkuIds, setBundleAddProductSkuIds] = useState([]);
    const [addProductSkusCount, setAddProductSkusCount] = useState(0);

    const [redirectSubmit, setRedirectSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const defaultErrMsg = "An error occurred. Please try again or contact support.";


    // Product grid

    const minSearchChars = 2;
    const pageRows = 50;
    var rowModelType = 'serverSide';
    const tbSearchId = "tbProdSearchGrid";

    const prodSearchGridRef = useRef();
    const [prodSearchRowData, setProdSearchRowData] = useState([]);

    const prodSearchCs = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const [prodSearchGh, setProdSearchGh] = useState("650px");
    const prodSearchColDefs = [
        {
            headerName: '', field: 'productId', maxWidth: 100, hide: true,
        },        
        //{
        //    headerName: 'Store', field: 'currentStoreName', maxWidth: 200, cellClass: ["text-wrap"],
        //},        
        {
            headerName: 'Style', field: 'style', width: 300, cellClass: ["text-wrap"],
        },
        {
            headerName: "Category", field: 'categoryName', width: 200,
        },
        {
            headerName: 'Vendor', field: 'vendorName', width: 250, cellClass: ["text-wrap"],
        },
    ];

    const prodSearchGridOpts = {
        defaultColDef: {
            flex: 1,
            sortable: false,
            resizable: true,
            filter: true,
            suppressMenu: true,
            suppressMovable: true,
            cellClass: ["no-border"]
        }
    };

    const onGridReady = useCallback((params) => {
        params.api.setServerSideDatasource(serverSideDatasource());
    }, []);

    const serverSideDatasource = () => {
        return {
            // called by the grid when more rows are required
            getRows: params => {

                var searchStr = GetValueById(tbSearchId);
                if (NotEmpty(searchStr) && searchStr.length >= minSearchChars) {

                    var pageNum = params.request.endRow / pageRows;
                    var page = pageNum - 1;
                    var apiUrl = process.env.REACT_APP_API_URL + 'product/GetSalesProductsPaged' +
                        '?numRows=' + pageRows + '&page=' + page + '&sText=' + searchStr;

                    //console.log("server side apiUrl: " + apiUrl);
                    fetch(apiUrl)
                        .then((response) => response.json())
                        .then((rowData) => {
                            if (rowData && rowData.dataRows && rowData.dataRows.length > 0) {
                                //debugger;

                                var dataRows = rowData.dataRows;
                                params.success({
                                    rowData: dataRows
                                });
                                selectFirstProductRow(page);

                                hideOverlay();
                                //console.log("total Product rows: " + dataRows.length);
                            }
                            else {
                                //params.successCallback([], 0);
                                params.success({ rowData: [] });
                                showNoRows();
                                console.log("total Product rows: no data");
                            }
                        })
                        .catch((error) => {
                            params.success({ rowData: [] });
                            showNoRows();
                            //log error
                            setError(error);
                            console.error('loadProductsData Error:', error);
                        });
                }
                else {
                    params.success({ rowData: [] });
                    showNoRows();
                    console.log("total Product rows: load none");
                }
            }
        };
    }

    const refreshCache = useCallback((e) => {
        var searchTerms = GetValueById("tbSearch");
        if (searchTerms && searchTerms.length >= minSearchChars) {
            prodSearchGridRef.current.api.refreshServerSide({ purge: true });
        }
    }, []);

    function selectFirstProductRow(page) {
        //autoselect first result after search
        if (page === 0) {            
            SetSelectedProduct();
        }
    }

    const cellClicked = useCallback((params) => {
        SetSelectedProductColors(params.node)
    }, []);

    function SetSelectedProduct() {
        var nodes = prodSearchGridRef.current.api.getRenderedNodes();
        if (NotEmpty(nodes) && Exists(nodes[0].data)) {
            var node = nodes[0];
            node.setSelected(true);
            loadProductColors(node.data.productId);
        }
        else {
            setColorRowData([]);
        }
    }

    function SetSelectedProductColors(node) {

        if (Exists(node) && Exists(node.data)) {
            node.setSelected(true);
            loadProductColors(node.data.productId);
        } else {
            setColorRowData([]);
        }
    }    

    function showLoading() {
        prodSearchGridRef.current.api.showLoadingOverlay();
    }
    function showNoRows() {
        prodSearchGridRef.current.api.showNoRowsOverlay();
    }
    function hideOverlay() {
        prodSearchGridRef.current.api.hideOverlay();
    }

    //Color grid
    const colorGridRef = useRef();
    const [colorRowData, setColorRowData] = useState([]);

    //const containerStyle2 = useMemo(() => ({ width: '100%', height: '100%' }), []);
    //const [gridHeight2, setGridHeight2] = useState("650px");
    const colorGridClumnDefs = [
        {
            field: 'productId', headerName: "ProductId", hide: true
        },
        {
            field: 'productPriceId', headerName: "PriceId", hide: true
        },
        {
            field: 'productSkuId', headerName: "PriceId", hide: true
        },
        {
            headerName: 'Add', field: '', maxWidth: 70, hide: !propsIsBundleAddType,
            cellRenderer: params => {
                var productSkuId = params.data.productSkuId;
                return <div> <Checkbox className={"cursor-pointer "} defaultChecked={false}
                    onClick={e => addRemoveBundleProductId(e, productSkuId)} />
                </div>;
            }
        },
        {
            headerName: "Color", field: 'color', resizable: true, width: 300,
        },
        {
            headerName: "Sku", field: 'sku', resizable: true, maxWidth: 180,
        },
        {
            headerName: "Style #", field: 'styleNum', resizable: true, maxWidth: 120,
        },
        {
            headerName: "PNW", field: 'isPnw', maxWidth: 70, hide: true //true IsOrderPageType
        },
        {
            headerName: "AZ", field: 'isAz', maxWidth: 70, hide: true //true IsOrderPageType
        },
        {
            headerName: "Web", field: 'isWeb', maxWidth: 70, hide: true //true IsOrderPageType
        }
    ];

    const colorGridOptions = {
        defaultColDef: {
            flex: 1,
            sortable: false,
            resizable: true,
            filter: false,
            suppressMenu: true,
            suppressMovable: true,
            cellClass: ["no-border"]
        }
    };

    // Product Colors
    async function loadProductColors(productId) {
        var success = false;
        setColorRowData([]);

        if (productId && productId > 0) {

            const endpoint = 'product/GetProductOptions/' + productId + '/0/0/0';
            try {
                const response = await get(endpoint);
                if (Exists(response)) {
                    setColorRowData(response.productPrices);
                    success = true;

                    //wait for data load                    
                    setTimeout(() => {
                        selectFirstColorRow();
                    }, 500);
                }
            } catch (error) {
                console.log('Error: ' + error);
            }
        }

        if (IsFalse(success)) {
            setError(true);
        }
        else {
            setError(false);
        }
    };

    function selectFirstColorRow() {
        //autoselect first result after search
        var nodes = colorGridRef.current.api.getRenderedNodes();
        if (NotEmpty(nodes)) {
            var node = nodes[0];
            node.setSelected(true);
        }
    }

    // Methods

    function updateMessage(msg, cssClass, errorMsg, logMsg) {
        if (errorMsg) {
            console.error(errorMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setMessage(msg);
        setMessageClass(cssClass);
    }

    //function saveMsg() {
    //    if (IdExists(productId)) {
    //        return productData.style + " updated successfully.";
    //    }
    //    else {
    //        return productData.style + " created successfully.";
    //    }
    //}

    function addRemoveBundleProductId(e, productSkuId) {
        var data = handleFieldChange(e);

        var psIds = bundleAddProductSkuIds;
        if (NotExists(psIds)) {
            psIds = [];
        }

        if (IsTrue(data.value)) {
            psIds.push(productSkuId);
        }
        else {
            psIds = psIds.filter(function (psId) {
                return psId !== productSkuId;
            });
        }

        var count = psIds.length;
        setAddProductSkusCount(count);
        setBundleAddProductSkuIds(psIds);
    }

    function onButtonClick(e) {
        PreventDefault(e);

        if (propsIsBundleAddType) {
            propsOnBundleAddClose(true, bundleAddProductSkuIds);
        }
    }

    function onCancelButtonClick(e) {
        PreventDefault(e);

        if (propsIsBundleAddType) {
            propsOnBundleAddClose(false, []);
        }
    }

    useEffect(() => {
        // showMessage();
    }, []);

    if (IsTrue(loading)) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }
    if (IsTrue(error))
        return <div className="text-red-500 text-center mt-[25%] text-xl">{defaultErrMsg}</div>;

    return (
        <div>
            {/* product-bundle-modal-vh */}
            <div className="">
                {/*{propsIsBundleAddType &&*/}
                {/*    <div className="text-secondary font-bold text-l p-2">Add Products to Bundle</div>*/}
                {/*}*/}

                <div className="table-cell">
                    <label for="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
                        Search
                    </label>
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg className="w-4 h-4 text-gray-500" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                        </div>
                        <input autoFocus type="text" id={tbSearchId} placeholder="Search"
                            onKeyDown={(e) => CheckEnterKeyPress(e, refreshCache)}
                            className="orders-filter-search block p-2 pl-10 text-sm text-gray-900 
                                             border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500;"></input>
                        <div id="selectedRows" />
                    </div>
                    <div className="mt-1 mb-1 text-sm">
                        'Enter' to search with 2 or more characters
                    </div>
                </div>
                <div className="mb-2 pointer-events-hand pl-3 table-cell">
                    <button id="btnProductSearch" onClick={(e) => refreshCache(e)}
                        type="button" className="btn-search !m-0">
                        Search Products
                    </button>
                </div>
                <div>

                    {/*** Product Search ***/}
                    <div className="inline-block w-[55%] pr-2">                        
                        <div style={prodSearchCs}>
                            <div className="ag-theme-alpine" style={{ height: prodSearchGh, width: '100%', }}>

                                <AgGridReact id="prodSearchGrid"
                                    rowData={prodSearchRowData}
                                    ref={prodSearchGridRef}
                                    gridOptions={prodSearchGridOpts}
                                    columnDefs={prodSearchColDefs}
                                    onGridReady={onGridReady}                                    
                                    onCellClicked={cellClicked}
                                    rowModelType={rowModelType}
                                    cacheBlockSize={pageRows}
                                    //suppressRowClickSelection="true"
                                    //rowSelection='multiple'
                                    //onRowDoubleClicked={(e) => onProductRowDoubleClicked(e.data)}
                                    //includeHiddenColumnsInQuickFilter={true}
                                />
                            </div>
                        </div>
                    </div>

                    {/*** Color List ***/}
                    <div className="inline-block w-[45%]">
                        <div style={prodSearchCs} className="">
                            <div className="ag-theme-alpine" style={{ height: prodSearchGh, width: '100%', }}>
                                {/*Quick Filter Search*/}
                                {/*Per CFM: dont show search for colors grid*/}
                                {/*{false &&*/}
                                {/*    <div>*/}
                                {/*        <div className="table-cell">*/}
                                {/*            <label for="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>*/}
                                {/*            <div className="relative">*/}
                                {/*                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">*/}
                                {/*                    <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">*/}
                                {/*                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />*/}
                                {/*                    </svg>*/}
                                {/*                </div>*/}

                                {/*                <input onChange={handleSearchOpts} type="search"*/}
                                {/*                    id="search" placeholder="Search"*/}
                                {/*                    className="orders-filter-search @apply block p-2 mb-2 pl-10 text-sm text-gray-900 */}
                                {/*                        border border-gray-300 rounded-lg !focus:ring-blue-500 !focus:border-blue-500;"></input>*/}
                                {/*                <div id="selectedRows" />*/}
                                {/*            </div>*/}
                                {/*            <div className="mt-1 mb-1">*/}
                                {/*                &nbsp;*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*}*/}

                                <AgGridReact id="gridOptions"
                                    rowData={colorRowData}
                                    ref={colorGridRef}
                                    gridOptions={colorGridOptions}
                                    columnDefs={colorGridClumnDefs}
                                    //onCellClicked={cellClickedProd}
                                    //onRowDoubleClicked={(e) => onButtonClick(e)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full text-right pt-4">
                <div className={"inline-block mr-1 " + messageClass}>
                    {message}
                </div>
                <div className="inline-block">

                    {propsIsBundleAddType &&
                        <div className="table-cell">
                            <button onClick={e => onButtonClick(e)} type="button" className="btn-submit">
                                <span>{"Add (" + addProductSkusCount + ") to Bundle"}</span>
                            </button>
                        </div>
                    }
                    <div className="table-cell">
                        <button onClick={e => onCancelButtonClick(e)} type="button" className="btn-cancel">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}