import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import { Table } from 'flowbite-react';
import { Modal } from 'react-daisyui';

import { GetLocationStateId, SetDocumentTitle } from '../../_reactHelpers/ReactHelpers';
import { ClickById, RemoveClassById, SetPageTitle, SetValueById } from '../../../js/helpers';
import {
    Exists, handleFieldChange, IdExists, IsEmpty,
    IsFalse,
    IsTrue, NotEmpty, NotStringEmpty, ParseIntSafe, PreventDefault, SetAndClickById, StringEmpty
}
from '../../../js/formHelpers';
import {
    StandardDataAndResponsePOST, StandardIdAndResponseGET,
    GetProductCategorySelectList, GetStoreSelectList, GetVendorSelectList
}
from '../../../js/dataHelpers';
import { formatNumberDecPlaces } from '../../../js/formHelpers';
import { GreaterThan } from '../../../js/calculationHelpers';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { ModuleRegistry } from '@ag-grid-community/core';
import { MasterDetailModule } from 'ag-grid-enterprise'; //'@ag-grid-enterprise/master-detail';

ModuleRegistry.registerModules([MasterDetailModule]);


export const ProductStyleNums = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    const propsOnCloseModal = props.onCloseModal ?? null;
    const propsProductData = props.productData ?? null;
    const propsMessage = props.message ?? "";
    

    const propsPageType = props.pageType ?? "";
    const propsIsPricingPageType = props.isPricingPageType ?? false;;
    const propsIsAdminPageType = props.isAdminPageType ?? false;
    const propsIsSalesPageType = props.isSalesPageType ?? false;
    const propsIsOrderPageType = props.isOrderPageType ?? false;

    const showFormulas = false;

    const [productId, setProductId] = useState(0);
    const [productData, setProductData] = useState(null);
    const [priceData, setPriceData] = useState(null);
    const [priceType, setPriceType] = useState("");
    const [rateModels, setRateModels] = useState([]);

    const [showSkuGrid, setShowSkuGrid] = useState(true);
    const [showProductPricing, setShowProductPricing] = useState(false);

    const [redirectSubmit, setRedirectSubmit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [requiredClass, setRequiredClass] = useState(" hidden ");
    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    // Grids

    const [selectedProduct, setSelectedProduct] = useState([]);
    const [selectedProductOptions, setSelectedProductOptions] = useState([]);

    const productStockModalRef = useRef();
    const [productStockModalContent, setProductStockModalContent] = useState(null);

    const gridRefOpts = useRef();
    const [productOptionsData, setProductOptionsData] = useState([]);

    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const [gridHeight, setGridHeight] = useState("700px");
    const columnDefsOpt = [
        { headerName: "ProductId", field: 'productId', hide: true },
        { headerName: "ProductStyleInfoId", field: 'productSkuId', hide: true },
        //{ headerName: "ProductSkuId", field: 'productSkuId', hide: true },
        //{ headerName: "ProductPriceId", field: 'productPriceId', hide: true },        
        //{
        //    headerName: "Color", field: 'color', minWidth: 220, cellClass: ["text-secondary"],
        //},
        //{
        //    headerName: "Sku", field: 'sku', minWidth: 150, cellClass: ["text-secondary"],
        //},
        {
            headerName: "Style #", field: 'styleNum', width: 200, cellClass: ["text-secondary", "font-bold"]
        },
        {
            headerName: "Cost", field: 'cost', width: 50,
            valueFormatter: params => params.data.cost.toFixed(2),
        },
        {
            headerName: "Bc", field: 'priceBc', width: 50,
            valueFormatter: params => params.data.priceBc.toFixed(2),
        },
        {
            headerName: "Bc 1", field: 'bc1', width: 50,
            valueFormatter: params => params.data.bc1.toFixed(2),
        },
        {
            headerName: "Bc 2", field: 'bc2', width: 50,
            valueFormatter: params => params.data.bc2.toFixed(2),
        },
        {
            headerName: "Bc 3", field: 'bc3', width: 50,
            valueFormatter: params => params.data.bc3.toFixed(2),
        },
        {
            headerName: "Bc 5", field: 'bc5', width: 50,
            valueFormatter: params => params.data.bc5.toFixed(2),
        },
        {
            headerName: "MSRP", field: 'msrp', width: 50,
            valueFormatter: params => params.data.msrp.toFixed(2),
        },
        {
            headerName: "PNW", field: 'colorIsPnw', maxWidth: 70, hide: false //true IsOrderPageType
        },
        {
            headerName: "AZ", field: 'colorIsAz', maxWidth: 70, hide: false //true IsOrderPageType
        },
        {
            headerName: "Web", field: 'colorIsWeb', maxWidth: 70, hide: false //true IsOrderPageType
        },
    ];

    const gridOptions2 = {
        defaultColDef: {
            flex: 1,
            sortable: false,
            resizable: true,
            filter: false,
            suppressMenu: true,
            suppressMovable: true,
            cellClass: ["no-border"]
        }
    };

    function onOptsRowDoubleClicked(data) {
        if (data) {
            hideShowProductPricingSearch(true, data);
        }
        else {
            alert(defaultErrMsg);
        }
    }

    // Product Pricing

    async function addNewStyleNumPricing() {

        var newPriceData = await loadStyleNumPriceData();

        if (Exists(newPriceData) && IdExists(newPriceData.productId)) {
            hideShowProductPricingSearch(true, newPriceData);
        }
        else {
            setError(true);
        }
    }

    const loadStyleNumPriceData = async () => {

        if (IdExists(productId)) {

            var data = await StandardIdAndResponseGET(productId, "product/GetNewProductStyleNumPrice/", "GetNewProductStyleNumPrice()");
            //console.log('data:', data);
            if (Exists(data)) {
                setLoading(false);
                return data;
            }
            else {
                setError(true);
            }
        }
        return null;
    };

    function hideShowProductPricingSearch(showPricing, plmData, msg) {
        if (IsTrue(showPricing)) {

            if (plmData.colorIsPnw) {
                setPriceType("PNW");
            }
            else if (plmData.colorIsAz) {
                setPriceType("AZ");
            }
            else if (plmData.colorIsWeb) {
                setPriceType("WEB");
            }

            // load prices for prodPriceId
            setPriceData(plmData);
            setRateModels(plmData.rateModels ?? [])

            setShowSkuGrid(false);
            setShowProductPricing(true);
        }
        else {
            onCloseClick(msg, productId, "skuColor");

            setPriceData(null);
            setShowSkuGrid(true);
            setShowProductPricing(false);
        }
    }

    const handleChange = (e) => {
        //updateMessage("", "");

        var data = handleFieldChange(e);
        if (data) {
            setPriceData((priceData) => ({
                ...priceData,
                [data.name]: data.value
            }));
        }
        else {
            updateMessage(defaultErrMsg, "text-red", "null handleChange data");
        }
    };


    // Helpers

    function onCloseClick(msg, reloadId, loadTab) {
        //PreventDefault(e);
        propsOnCloseModal(msg, reloadId, loadTab);
    }

    function updateMessage(msg, cssClass, errorMsg, logMsg) {
        if (errorMsg) {
            console.error(errorMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setMessage(msg);
        setMessageClass(cssClass);
    }


    // Validate and Post Data

    function saveMsg() {
        return "Pricing saved successfully.";
        //if (IdExists(productId)) {
        //    return "Pricing updated successfully.";
        //}
        //else {
        //    return "Pricing created successfully.";
        //}
    }

    const validateData = () => {
        var valid = true;
        setRequiredClass(" hidden ");
        var msg = "";

        if (StringEmpty(priceData.styleNum)) {
            msg = "*Required Fields";
        }

        if (NotEmpty(msg)) {
            setRequiredClass("");
            updateMessage(msg, "text-red");
        }
        return valid;
    };

    async function btnSubmitClick(e, reload) {
        PreventDefault(e);
        var s = false;

        var valid = validateData();
        if (IsTrue(valid)) {

            var resp = await SavePriceDataPOST();
            if (Exists(resp)) {

                var ppId = ParseIntSafe(resp.productPriceId);
                if (IdExists(ppId)) {
                    var msg = saveMsg();
                    updateMessage(msg, "text-green");

                    // update display
                    SetValueById("priceBc", resp.priceBc);
                    SetValueById("bc1", resp.bc1);
                    SetValueById("bc2", resp.bc2);
                    SetValueById("bc3", resp.bc3);
                    SetValueById("bc5", resp.bc5);
                    SetValueById("msrp", resp.msrp);

                    s = true;
                    hideShowProductPricingSearch(false, null, msg);

                    // dont close so can see prices
                    //onCloseClick(saveMsg());
                }                
            }

            if (IsFalse(s)) {
                console.error("SavePriceDataPOST(): ", JSON.stringify(resp));
                updateMessage(defaultErrMsg, "text-red");
            }
        }
    }

    async function SavePriceDataPOST() {
        var response = null;

        priceData.colorIsPnw = false;
        priceData.colorIsAz = false;
        priceData.colorIsWeb = false;

        if (priceType === "PNW") {
            priceData.colorIsPnw = true;
        }
        else if (priceType === "AZ") {
            priceData.colorIsAz = true;
        }
        else if (priceType === "WEB") {
            priceData.colorIsWeb = true;
        }
        setPriceData(priceData);

        var postModel = JSON.stringify(priceData);
        var endpoint = "product/SaveProductStyleNumPrice";
        var typeStr = "SavePriceDataPOST()";

        response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);
        return response;
    }


    // Load Data

    const loadProductData = async () => {

        if (Exists(propsProductData) && Exists(propsProductData.product)) {
            setProductId(propsProductData.productId);
            setProductData(propsProductData.product)

            var styleNums = propsProductData.product.productStyleNumPrices ?? [];

            setProductOptionsData(styleNums);

            if (NotStringEmpty(propsMessage)) {
                updateMessage(propsMessage, "text-green");
            }

            setLoading(false);
        }
        else {
            console.error("loadProductData - propsProductData is null")
            setError(true);
        }
    };

    function onChangePriceType(e) {
        if (Exists(e.target) && Exists(e.target.value)) {
            setPriceType(e.target.value);

            if (!IdExists(priceData.productPriceId) && NotEmpty(rateModels)) {
                var pt = e.target.value;
                var updated = false;

                rateModels.forEach((rm, index) => {
                    if (IsTrue(rm.isPnw) && pt === "PNW") {
                        updatePriceData(rm);
                        updated = true;
                    }
                    else if (IsTrue(rm.isAz) && pt === "AZ") {
                        updatePriceData(rm);
                        updated = true;
                    }
                    else if (IsTrue(rm.isWeb) && pt === "WEB") {
                        updatePriceData(rm);
                        updated = true;
                    }
                });

                if (IsFalse(updated)) {
                    updatePriceData(null);
                }
            }
        }
        else {
            alert(defaultErrMsg);
        }
    }

    function updatePriceData(rm) {
        if (Exists(rm)) {
            priceData.priceBcMultiplier = rm.priceBcMultiplier;
            priceData.msrpMultiplier = rm.msrpMultiplier;
            priceData.freight = rm.freight;
            priceData.load = rm.load;
        }
        else {
            priceData.priceBcMultiplier = 0;
            priceData.msrpMultiplier = 0;
            priceData.freight = 0;
            priceData.load = 0;
        }
        setPriceData(priceData);
    }

    function EnterSave(e) {
        if (e.key === "Enter") {
            btnSubmitClick(e, true);
        }
    }

    useEffect(() => {

        loadProductData();
    }, []);

    if (IsTrue(loading)) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }
    if (IsTrue(error))
        return <div className="text-red-500 text-center mt-[25%] text-xl">{defaultErrMsg}</div>;

    return (
        <div className="details-page">
            <div class="details-page-container">
                <div className="forms-page clear"
                //product-colorSku-modal-vh
                >

                    {IsTrue(showSkuGrid) &&
                        <div style={containerStyle} className="">
                            <div className="ag-theme-alpine" style={{ height: gridHeight, width: '100%', }}>

                                <AgGridReact id="gridOptions"
                                    rowData={productOptionsData}
                                    ref={gridRefOpts}
                                    gridOptions={gridOptions2}
                                    columnDefs={columnDefsOpt}
                                    //onCellClicked={cellClickedProd}
                                    onRowDoubleClicked={(e) => onOptsRowDoubleClicked(e.data)}
                                />
                            </div>
                        </div>
                    }

                    {IsTrue(showProductPricing) && priceData &&
                        <div className="p-0">
                            {/*<div className="pt-1 pb-3 text-center text-secondary text-[20px] font-bold">*/}
                            {/*    */}{/*<div>{productData.style}</div>*/}
                            {/*    <span>Style # - </span>*/}
                            {/*    <span>{priceData.styleNum}</span>*/}
                            {/*</div>*/}
                            <div>

                                {/** Price Type **/}
                                <div className="!w-[full] text-left pt-4 pb-4">
                                    <span className="pr-1 font-bold text-secondary">PNW</span>
                                    <input type="radio" value="PNW" name="priceType" className="mt-[-3px] cursor-pointer"
                                        checked={priceType === "PNW"}
                                        onChange={(e) => onChangePriceType(e)} />

                                    <span className="pl-4 pr-1 font-bold text-secondary">AZ</span>
                                    <input type="radio" value="AZ" name="priceType" className="mt-[-5px] cursor-pointer"
                                        checked={priceType === "AZ"}
                                        onChange={(e) => onChangePriceType(e)} />

                                    <span className="pl-4 pr-1 font-bold text-secondary">Web</span>
                                    <input type="radio" value="WEB" name="priceType" className="mt-[-5px] cursor-pointer"
                                        checked={priceType === "WEB"}
                                        onChange={(e) => onChangePriceType(e)} />
                                </div>

                                <div className="forms-page clear product-edit-modal-vh">

                                    <div className="form-col-1 inline-block !w-[60%] align-top pr-4">

                                        {/*{showFormulas &&*/}
                                        {/*    <div className="details-form-field">*/}
                                        {/*        <label for="bcFormula" className="lbl-text1 w-[180px]">BC Formula</label>*/}
                                        {/*        <input id="bcFormula" name="bcFormula" type="text"*/}
                                        {/*            className="inp-text"*/}
                                        {/*            //onKeyDown={(e) => EnterSave(e)}*/}
                                        {/*            value={priceData.bcFormula}*/}
                                        {/*            //onChange={handleChange}*/}
                                        {/*            disabled*/}
                                        {/*        />*/}
                                        {/*    </div>*/}
                                        {/*}*/}
                                        <div className="details-form-field pr-2">
                                            <label for="styleNum" className="lbl-text1 w-[180px]">Style Num</label>
                                            <input id="styleNum" name="styleNum" type="text"
                                                className="inp-text"
                                                onKeyDown={(e) => EnterSave(e)}
                                                value={priceData.styleNum}
                                                onChange={handleChange}
                                            />
                                            <span className={"text-red pl-1 table-cell " + requiredClass}>*</span>
                                        </div>
                                        <div className="details-form-field">
                                            <label for="cost" className="lbl-text1 w-[180px]">Cost</label>
                                            <input id="cost" name="cost" type="number"
                                                className="inp-text"
                                                onKeyDown={(e) => EnterSave(e)}
                                                value={priceData.cost}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="details-form-field">
                                            <label for="priceBcMultiplier" className="lbl-text1 w-[180px]">BC Multiplier</label>
                                            <input id="priceBcMultiplier" name="priceBcMultiplier" type="number"
                                                className="inp-text"
                                                onKeyDown={(e) => EnterSave(e)}
                                                value={priceData.priceBcMultiplier}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="details-form-field !pb-1">
                                            <label for="priceBc" className="lbl-text1 w-[180px]">BC</label>
                                            <input id="priceBc" name="priceBc" type="number"
                                                className="inp-text"
                                                //onKeyDown={(e) => EnterSave(e)}
                                                defaultValue={priceData.priceBc.toFixed(2)}
                                                //onChange={handleChange}
                                                disabled
                                            />
                                        </div>
                                        {/*{showFormulas &&*/}
                                        {/*    <div className="details-form-field">*/}
                                        {/*        <label for="bc1Formula" className="lbl-text1 w-[180px]">BC1 Formula</label>*/}
                                        {/*        <input id="bc1Formula" name="bc1Formula" type="text"*/}
                                        {/*            className="inp-text"*/}
                                        {/*            //onKeyDown={(e) => EnterSave(e)}*/}
                                        {/*            value={priceData.bc1Formula}*/}
                                        {/*        //onChange={handleChange}*/}
                                        {/*        />*/}
                                        {/*    </div>*/}
                                        {/*}*/}
                                        <div className="details-form-field !pb-1">
                                            <label for="bc1" className="lbl-text1 w-[180px]">BC 1</label>
                                            <input id="bc1" name="bc1" type="number"
                                                className="inp-text"
                                                //onKeyDown={(e) => EnterSave(e)}
                                                defaultValue={priceData.bc1.toFixed(2)}
                                                //onChange={handleChange}
                                                disabled
                                            />
                                        </div>
                                        {/*{showFormulas &&*/}
                                        {/*    <div className="details-form-field">*/}
                                        {/*        <label for="bcFormula" className="lbl-text1 w-[180px]">BC 2 Formula</label>*/}
                                        {/*        <input id="bcFormula" name="bcFormula" type="text"*/}
                                        {/*            className="inp-text"*/}
                                        {/*            //onKeyDown={(e) => EnterSave(e)}*/}
                                        {/*            defaultValue={priceData.bc2Formula}*/}
                                        {/*            //onChange={handleChange}*/}
                                        {/*        />*/}
                                        {/*    </div>*/}
                                        {/*}*/}
                                        <div className="details-form-field !pb-1">
                                            <label for="bcFormula" className="lbl-text1 w-[180px]">BC 2</label>
                                            <input id="bc2" name="bc2" type="number"
                                                className="inp-text"
                                                //onKeyDown={(e) => EnterSave(e)}
                                                defaultValue={priceData.bc2.toFixed(2)}
                                                //onChange={handleChange}
                                                disabled
                                            />
                                        </div>
                                        {/*{showFormulas &&*/}
                                        {/*    <div className="details-form-field">*/}
                                        {/*        <label for="bc3Formula" className="lbl-text1 w-[180px]">BC 3 Formula</label>*/}
                                        {/*        <input id="bc3Formula" name="bc3Formula" type="text"*/}
                                        {/*            className="inp-text"*/}
                                        {/*            //onKeyDown={(e) => EnterSave(e)}*/}
                                        {/*            defaultValue={priceData.bc3Formula}*/}
                                        {/*            //onChange={handleChange}*/}
                                        {/*        />*/}
                                        {/*    </div>*/}
                                        {/*}*/}
                                        <div className="details-form-field !pb-1">
                                            <label for="bc3" className="lbl-text1 w-[180px]">BC 3</label>
                                            <input id="bc3" name="bc3" type="number"
                                                className="inp-text"
                                                //onKeyDown={(e) => EnterSave(e)}
                                                defaultValue={priceData.bc3.toFixed(2)}
                                                //onChange={handleChange}
                                                disabled
                                            />
                                        </div>
                                        {/*{showFormulas &&*/}
                                        {/*    <div className="details-form-field">*/}
                                        {/*        <label for="bc5Formula" className="lbl-text1 w-[180px]">BC 5 Formula</label>*/}
                                        {/*        <input id="bc5Formula" name="bc5Formula" type="text"*/}
                                        {/*            className="inp-text"*/}
                                        {/*            //onKeyDown={(e) => EnterSave(e)}*/}
                                        {/*            defaultValue={priceData.bc5Formula}*/}
                                        {/*        //onChange={handleChange}*/}
                                        {/*        />*/}
                                        {/*    </div>*/}
                                        {/*}*/}
                                        <div className="details-form-field !pb-2">
                                            <label for="bc5" className="lbl-text1 w-[180px]">BC 5</label>
                                            <input id="bc5" name="bc5" type="number"
                                                className="inp-text"
                                                //onKeyDown={(e) => EnterSave(e)}
                                                defaultValue={priceData.bc5.toFixed(2)}
                                                //onChange={handleChange}
                                                disabled
                                            />
                                        </div>
                                        {/*{showFormulas &&*/}
                                        {/*    <div className="details-form-field">*/}
                                        {/*        <label for="srpFormula" className="lbl-text1 w-[180px]">MSRP Formula</label>*/}
                                        {/*        <input id="srpFormula" name="srpFormula" type="text"*/}
                                        {/*            className="inp-text"*/}
                                        {/*            //onKeyDown={(e) => EnterSave(e)}*/}
                                        {/*            defaultValue={priceData.srpFormula}*/}
                                        {/*            //onChange={handleChange}*/}
                                        {/*            disabled*/}
                                        {/*        />*/}
                                        {/*    </div>*/}
                                        {/*}*/}
                                        <div className="details-form-field">
                                            <label for="msrpMultiplier" className="lbl-text1 w-[180px]">MSRP Multiplier</label>
                                            <input id="msrpMultiplier" name="msrpMultiplier" type="number"
                                                className="inp-text"
                                                onKeyDown={(e) => EnterSave(e)}
                                                value={priceData.msrpMultiplier}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="details-form-field !pb-2">
                                            <label for="msrp" className="lbl-text1 w-[180px]">MSRP</label>
                                            <input id="msrp" name="msrp" type="number"
                                                className="inp-text"
                                                //onKeyDown={(e) => EnterSave(e)}
                                                defaultValue={priceData.msrp.toFixed(2)}                                                
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    {/** column2 **/}
                                    <div className="form-col-1 inline-block !w-[40%] align-top">
                                        
                                        <div className="details-form-field">
                                            <label for="freight" className="lbl-text1 w-[180px]">Freight</label>
                                            <input id="freight" name="freight" type="number"
                                                className="inp-text"
                                                onKeyDown={(e) => EnterSave(e)}
                                                value={priceData.freight}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="details-form-field">
                                            <label for="load" className="lbl-text1 w-[180px]">Load</label>
                                            <input id="load" name="load" type="number"
                                                className="inp-text"
                                                onKeyDown={(e) => EnterSave(e)}
                                                value={priceData.load}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="details-form-field">
                                            <label for="loadPerc" className="lbl-text1 w-[180px]">Load %</label>
                                            <input id="loadPerc" name="loadPerc" type="number"
                                                className="inp-text"
                                                onKeyDown={(e) => EnterSave(e)}
                                                value={priceData.loadPerc}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="w-full text-right pt-4">
                        <div className={"inline-block mr-1 " + messageClass}>
                            {message}
                        </div>
                        <div className="inline-block">
                            {showSkuGrid &&
                                <div>
                                    {/*{IsProductType &&*/}
                                    {/*    <button type="submit" className="btn-submit" //disabled={submitDisabled}*/}
                                    {/*        onClick={(e) => showAddProductsSearch(e)}>*/}
                                    {/*        Add Products to Bundle*/}
                                    {/*    </button>*/}
                                    {/*}*/}
                                    {/*{productId > 0 &&                                        */}
                                    {/*    <button type="submit" className="btn-submit" //disabled={submitDisabled}*/}
                                    {/*        //onClick={(e) => btnSubmitClick(e, true)}*/}
                                    {/*        onClick={e => onCloseClick(e)}>*/}
                                    {/*        Exit*/}
                                    {/*    </button>*/}
                                    {/*}*/}
                                    <button onClick={(e) => addNewStyleNumPricing()} type="button" className="btn-search">
                                        Add Style Num Pricing
                                    </button>
                                    <button onClick={e => onCloseClick()} type="button" className="btn-cancel">
                                        Cancel
                                    </button>
                                </div>
                            }
                            {showProductPricing &&
                                <div>
                                    <button type="button" className="btn-cancel"
                                        onClick={e => hideShowProductPricingSearch(false)}>
                                        Cancel
                                    </button>

                                    {/*{IsProductType &&*/}
                                    {/*    <button type="submit" className="btn-submit" //disabled={submitDisabled}*/}
                                    {/*        onClick={(e) => showAddProductsSearch(e)}>*/}
                                    {/*        Add Products to Bundle*/}
                                    {/*    </button>*/}
                                    {/*}*/}
                                    {/*{productId > 0 &&                                        */}
                                    <button type="submit" className="btn-submit" //disabled={submitDisabled}
                                        onClick={(e) => btnSubmitClick(e, false)}>                                        
                                        Save & Close
                                    </button>
                                    {/*}*/}

                                    {/*<button type="button" className="btn-submit"*/}
                                    {/*    onClick={e => hideShowProductPricingSearch(e, false)}>*/}
                                    {/*    Save*/}
                                    {/*</button>*/}
                                </div>
                            }
                        </div>
                    </div>
                    <a href="/" id="lnkReloadPage" className="hidden">Reload</a>
                </div>
            </div>
        </div>
    );
}