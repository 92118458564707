import validator from "validator";

import {
    CheckAndLogElementById, GetElementsByClass, SetValueById, ClickById, SetHrefById, RemoveClassByClass, AddClassByClass, RemoveClassById, AddClassById
} from './helpers.js';
import {
    Equals,
    GreaterThan,
    NotEquals
} from './calculationHelpers.js';

export const handleFieldChange = (e) => {
    //handles text,number,date,checkbox,select
    //test for use on other types
    var data = {};
    const { name, type } = e.target;
    var value = e.target[type === "checkbox" ? "checked" : "value"]
        
    data.name = name;
    data.value = value;
    //console.log('selected - field: ' + data.name + ' data.value: ' + value);
    return data;
}

export function AddMarkChangedListener(className, markClassName) {
    var els = GetElementsByClass(className);
    els.forEach((el, index) => {
        if (el) {
            if (className === "markta") {
                el.addEventListener("change", function () {
                    this.closest(".markta").classList.add(markClassName);
                    SetValueById("hfFormDirty", "1");
                });
            }
            else {
                el.addEventListener("change", function () {
                    this.classList.add(markClassName);
                    SetValueById("hfFormDirty", "1");
                });
            }
        }
    });
}

export const RemoveSpacesStr = (str) => {
    if (NotStringEmpty(str)) {
        str = str.replace(/ /g, '')
        return str;
    }
    return "";
}

export const StringEmpty = (obj) => {
    if (NotExists(obj) || obj === "") {
        return true;
    }
    return false;
}

export const NotStringEmpty = (obj) => {
    var isEmpty = !StringEmpty(obj);
    return isEmpty;
}

export const NotEmpty = (obj) => {
    //doesnt work on numbers, dont have length
    //use Exists()
    if (Exists(obj) && obj.length > 0) {
        return true;
    }
    return false;
}

export const IsEmpty = (obj) => {    
    var isEmpty = !NotEmpty(obj);
    return isEmpty;
}

export const Exists = (obj) => {
    if (obj == null || obj === undefined || typeof obj === "undefined") {
        return false;
    }
    return true;
}

export const NotExists = (obj) => {
    if (Exists(obj) === true) {
        return false;
    }
    return true;
}

export const IdExists = (id) => {
    if (GreaterThan(id, 0)) {
        return true;
    }
    return false;
}

export const IsTrue = (val) => {
    //return true, only if val === true
    if (Exists(val) && val === true) {
        return true;
    }
    else {
        return false;
    }
}

export const IsFalse = (val) => {
    //return true, for any val !== true
    if (IsTrue(val) === true) {
        return false;
    }
    else {
        return true;
    }
}

export const IsInt = (val) => {
    try {
        var vInt = parseInt(val);
    } catch (e) {
        return false;
    }
    return true;
}

export const ParseIntSafe = (val) => {
    if (Exists(val)) {
        try {
            var vInt = parseInt(val);
            return vInt;
        } catch (e) {
            return null;
        }
    }
    return null;
}

export function isSelectedDropdown(ddValue) {
    if (!Exists(ddValue) || ddValue === '0' || ddValue === 0) {
        return false;
    }
    return true;
}

export function isValidDate(obj) {

    if (NotStringEmpty(obj)) {
        var testDate = new Date(obj);
        var validDate = !isNaN(testDate);

        if (IsTrue(validDate)) {
            return true;
        }
    }
    return false;
}
export const NotIsValidDate = (obj) => {
    var notValid = !isValidDate(obj);
    return notValid;
}

export function FormatStrLength(str, length) {
    if (NotEmpty(str) && str.length > length) {
        str = str.substring(0, length);
    }
    return str;
}

export function formatCurrencyDecimal(val) {
    var number = 0;
    if (Exists(val)) {
        number = val;
    }

    var currDecimal = formatNumberDecPlaces(number, 2);
    return currDecimal;
}

export function formatCurrencyDecStr(val) {    
    //var number = 0;
    //if (NotExists(val)) {
    //    number = val;
    //}

    //var dNum = new Intl.NumberFormat(
    //    "en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
    //var strNum = "$" + dNum;

    var number = formatNumberDecPlaces(val, 2);
    var strCurr = GetDisplayCurrencyStr(number);
    return strCurr;
}

export function formatNumberDecPlaces(val, places) {
    var num = 0;    
    if (Exists(val)) {
        num = val;
    }
    if (NotExists(places)) {
        places = 0;
    }

    //number = new Intl.NumberFormat("en-US",
    //    {
    //        minimumFractionDigits: places,
    //        maximumFractionDigits: places
    //    }).format(number);

    var number = Number(Number(num).toFixed(places));
    return number;
}

export function formatPhoneToNumber(str) {
    return str.replace(' ', '').replace('(', '').replace(')', '').replace('-', '');
}

export function TrimString(str, length) {
    if (NotEmpty(str) && str.length > length) {
        return str.substring(0, length);
    }
    else {
        return str;
    }
}

export function SetFocusById(baseId) {
    var el = CheckAndLogElementById(baseId);
    if (el) {
        el.focus();
        el.blur();
        el.focus();
    }
}

export function BlurById(baseId) {
    var el = CheckAndLogElementById(baseId);
    if (el) {
        el.blur();
    }
}

export function FireOnChangeEvent(baseId) {
    var el = CheckAndLogElementById(baseId);
    if (el) {
        var event = new Event('change');
        el.dispatchEvent(event);
    }
}

// Event Listeners ----------

//Enter Key Listener
export function CheckEnterKeyPress(e, func) {        
    if (e.key === "Enter") {
        PreventDefault(e);
        func();        
    }
}

export function HandleDoubleKeyPress(e, char) {
    //var doublePress = false;
    //const delta = 500;

    //if (e.key === char) {
    //    var thisKeypressTime = new Date();
    //    if (thisKeypressTime - lastKeypressTime <= delta) {
    //        // optional - if we'd rather not detect a triple-press
    //        // as a second double-press, reset the timestamp
    //        thisKeypressTime = 0;
    //        doublePress = true;
    //    }
    //    setLastKeypressTime(thisKeypressTime);
    //}
    //return doublePress;
}

export function AddSearchEnterKeyListener(baseId, numChars, func) {
    var elInput = CheckAndLogElementById(baseId);
    if (elInput) {
        elInput.addEventListener("keydown", (e) => {            
            if (e.key === "Enter") {
                e.preventDefault();
                const val = e.target.value;            
                if (val.length === 0 || val.length >= numChars) {
                    //console.log('Enter key pressed');
                    func();
                }
            }
        });
    }
}

export function AddEnterKeyListenerClass(className, func) {
    var els = GetElementsByClass(className);
    if (els && els.length > 0) {
        els.forEach((el, index) => {
            if (el) {
                el.addEventListener("keydown", (e) => {
                    if (e.key === "Enter") {                        
                        //console.log('Enter key pressed');
                        func();                       
                    }
                });
            }
        });
    }
    else {
        console.error("Elements not found for className: " + className);
    }
}

export function AddEnterSubmitByClass(className, clickId) {
    var els = GetElementsByClass(className);
    if (els && els.length > 0) {
        els.forEach((el, index) => {
            if (el) {
                el.addEventListener("keydown", (e) => {
                    if (e.key === "Enter") {
                        //console.log('Enter key pressed');
                        ClickById(clickId);
                    }
                });
            }
        });
    }
    else {
        console.error("Elements not found for className: " + className);
    }
}

// Input Function Listener
export function AddInputChangeListener(baseId, func) {
    var elInput = CheckAndLogElementById(baseId);
    if (elInput) {
        elInput.addEventListener("change", func);
    }
}

export const AddInputChangeListenersByName = (name, func) => {    
    var els = document.querySelector('input[name=' + name + ']');
    if (els && els.length > 0) {
        els.forEach((el, index) => {
            if (el) {
                el.addEventListener("change", func);
            }
        });
    } else {
        console.error("Elements not found for name: " + name);
    }
}

// Formatters
const isNumericInput = (event) => {
    const key = event.keyCode;
    return ((key >= 48 && key <= 57) || // Allow number line
        (key >= 96 && key <= 105) // Allow number pad
    );
};

const isDashInput = (event) => {
    const key = event.keyCode;
    return (key === 189 || // Allow number line '-'
        key === 109); // Allow number pad '-'
};

const isModifierKey = (event) => {
    const key = event.keyCode;
    return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
        (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
        (key > 36 && key < 41) || // Allow left, up, right, down
        (
            // Allow Ctrl/Command + A,C,V,X,Z
            (event.ctrlKey === true || event.metaKey === true) &&
            (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
        )
};

export const enforcePhoneFormat = (event) => {
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if (!isNumericInput(event) && !isModifierKey(event)) {
        event.preventDefault();
    }
};

export const formatToPhone = (event) => {
    if (isModifierKey(event)) {
        return;
    }

    const input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
    const areaCode = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
        event.target.value = `(${areaCode}) ${middle}-${last}`;
    }
    else if (input.length > 3) {
        event.target.value = `(${areaCode}) ${middle}`;
    }
    else if (input.length > 0) {
        event.target.value = `(${areaCode}`;
    }
    return event.target.value;
};

export const formatValToPhone = (val) => {
    if (IsEmpty(val)) {
        return "";
    }

    const input = val.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
    const areaCode = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
        val = `(${areaCode}) ${middle}-${last}`;
    }
    else if (input.length > 3) {
        val = `(${areaCode}) ${middle}`;
    }
    else if (input.length > 0) {
        val = `(${areaCode}`;
    }
    return val;
};

export function formatPhoneNumber(val) {
    if (Exists(val)) {
        const input = val.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
        const areaCode = input.substring(0, 3);
        const middle = input.substring(3, 6);
        const last = input.substring(6, 10);

        if (input.length > 6) { val = `(${areaCode}) ${middle}-${last}`; }
        else if (input.length > 3) { val = `(${areaCode}) ${middle}`; }
        else if (input.length > 0) { val = `(${areaCode}`; }
    }
    return val;
}

export const enforceZipLength = (value) => {
    // Input must be of a valid number format and not longer than ten digits
    value = FormatStrLength(value, 10);
    return value;
};

export const enforceZipFormat = (event) => {
    // Input must be of a valid number format or a modifier key, and not longer than ten digits    
    if (!isNumericInput(event) && !isModifierKey(event) && !isDashInput(event)) {
        event.preventDefault();
    }
};

export function EnforceNumDecimalPlaces(val, decPlaces) {
    if (Exists(val)) {
        var decimals = val.split('.')[1];
        if (NotEmpty(decimals) && decimals.length > decPlaces) {
            val = Number(val).toFixed(decPlaces);
            //var num = val.split('.')[0];
            //var subDec = decimals.substring(0, decPlaces);
            //val = num + "." + subDec;
        }
    }
    return val;
}

export function IsEmail(email) {
    var isEmail = false;
    if (Exists(email)) {
        isEmail = validator.isEmail(email);
    }
    return isEmail;
}

export function IsPhone(phone) {
    if (Exists(phone) && Equals(phone.length, 14)) {
        return true;
        // TODO: fix better
        //var fPhone = formatPhoneNumber(phone);
        //if (Exists(fPhone) && Equals(fPhone.length, 14)) {
        //    return true;
        //}
    }
    return false;
}

export function PreventDefault(e) {
    if (e) {
        e.preventDefault();
    }
}

export function SetAndClickById(id, route) {
    if (NotEmpty(id)) {
        SetHrefById(id, route);
        ClickById(id);
    }
}

export function SwitchFbTab(e, tabsClass, tabsContentClass, newTabId, newContentId) {
    PreventDefault(e);
    // for flowbite tab html
    if (NotEmpty(tabsClass) && NotEmpty(newTabId)) {

        // reset style and hidden classes to hide all tabs
        RemoveClassByClass(tabsClass, "fb-tab-btn");
        AddClassByClass(tabsClass, "fb-tab-btn");
        RemoveClassByClass(tabsContentClass, "hidden");
        AddClassByClass(tabsContentClass, "hidden");        

        // set selected tab style and show
        AddClassById(newTabId, "fb-tab-btn-sel");
        RemoveClassById(newTabId, "fb-tab-btn");
        RemoveClassById(newContentId, "hidden");
    }
}

export function GetCount(arrObj) {
    var count = 0;
    if (NotEmpty(arrObj)) {
        count = arrObj.length;
    }
    return count;
}

export function KeyHandlerDatePicker(e, formSubmit, funcTodaysDate, tabRef) {

    var keyPress = HandleKeyPress(e, 'd');
    if (keyPress === true) {
        PreventDefault(e);
        if (Exists(funcTodaysDate)) {
            funcTodaysDate();
        }
    }
    //else if (e.key === "Tab") {
    //    PreventDefault(e);
    //    if (Exists(tabRef)) {
    //        tabRef.current.focus()
    //    }
    //}
    //else if (e.key === "Enter") {
    //    PreventDefault(e);
    //    if (Exists(formSubmit)) {
    //        formSubmit();
    //    }        
    //}
    //else if (e.key === "ArrowDown") {
    //    // allow to open calendar
    //}
    //else if (e.key === "ArrowRight") {
    //    PreventDefault(e);
    //}
    //else if (e.key === "ArrowRight") {
    //    PreventDefault(e);
    //    //document.dispatchEvent(
    //    //    new KeyboardEvent("keydown", {
    //    //        key: "e",
    //    //        keyCode: 40, // example values.
    //    //        code: "ArrowDown", // put everything you need in this object.
    //    //        which: 40,
    //    //        shiftKey: false, // you don't need to include values
    //    //        ctrlKey: false,  // if you aren't going to use them.
    //    //        metaKey: false   // these are here for example's sake.
    //    //    })
    //    //);
    //}    
}

export function HandleKeyPress(e, char) {
    if (e.key === char) {
        return true;
    }
    return false;
}

export function GetDateNow(addYears, addMonths, addDays, inpDate) {
    var date = GetDate(addYears, addMonths, addDays, inpDate);

    let yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // month starts at 0
    let dd = date.getDate();

    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    var dateInp = mm + '/' + dd + '/' + yyyy;

    var localDateStr = new Date(date).toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    });

    return dateInp;
}

export function GetInputDate(inpDate, addYears, addMonths, addDays) {
    var date = GetDate(inpDate, addYears, addMonths, addDays);
    var dateStr = new Date(date).toISOString().split('T')[0];
    return dateStr;
}

function GetDate(inpDate, addYears, addMonths, addDays) {
    // date midnight
    var date = new Date(new Date().toDateString());

    //set to input date if passed in
    if (Exists(inpDate) && NotStringEmpty(inpDate)) {
        date = new Date(new Date(inpDate).toDateString());
    }    

    var iAddYears = ParseIntSafe(addYears);
    if (Exists(iAddYears) && NotEquals(iAddYears, 0)) {
        let day = date.getDate();        
        date.setFullYear(date.getFullYear() + iAddYears);

        // handle leap years with month rollover
        // if the day of the month changes when applying setFullYear,
        // use setDate(0) to move back to the last day of the previous month.
        if (date.getDate() !== day) {
            date.setDate(0);
        }
    }

    var iAddMonths = ParseIntSafe(addMonths);
    if (Exists(iAddMonths) && NotEquals(iAddMonths, 0)) {
        let day = date.getDate();
        date.setMonth(date.getMonth() + iAddMonths);

        // if the day of the month changes when applying setMonth,
        // then we know we have overflowed into the following month due to a difference in month length.
        // use setDate(0) to move back to the last day of the previous month.
        if (date.getDate() !== day) {
            date.setDate(0);
        }
    }

    var iAddDays = ParseIntSafe(addDays);
    if (Exists(iAddDays) && NotEquals(iAddDays, 0)) {
        var d = new Date(date);
        date.setDate(d.getDate() + iAddDays);
    }
    return date;    
}

export function GetDateTimeNow() {
    const strDt = new Date();
    var localDT = new Date(strDt).toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    });
    return localDT;
}

export function GetDisplayDateStr(dateString) {
    var dtStr = "";
    if (!dateString || dateString === '0001-01-01T00:00:00' || new Date(dateString).getFullYear() === 1) {
        return '';
    }
    if (NotStringEmpty(dateString)) {
        dtStr = GetDateNow((null, null, null, dateString));
    }
    return dtStr;
}

export function GetDisplayDateTimeStr(dateString) {
    var dtStr = "";
    if (NotStringEmpty(dateString)) {
        dtStr = new Date(dateString).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
    }
    return dtStr;
}

export function GetDisplayCurrencyStr(value) {
    if (NotExists(value)) {
        value = 0;
    }
    var currStr = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value);

    return currStr;
}

export function GetDisplayDecimalStr(value, places) {
    if (NotExists(places)) {
        places = 2;
    }

    var decStr = GetDisplayNumberStr(value, places)
    return decStr;
}

export function GetDisplayIntStr(value) {    
    var intStr = GetDisplayNumberStr(value)
    return intStr;
}

function GetDisplayNumberStr(value, places) {
    if (NotExists(value)) {
        value = 0;
    }
    if (NotExists(places)) {
        places = 0;
    }

    var decStr = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: places,
        maximumFractionDigits: places
    }).format(value);
    return decStr;
}

export function ToBool(val) {
    var isTrue = false;
    if (Exists(val)) {
        isTrue = (val === 'true');
    }
    return isTrue;
}

export function LengthGreaterEqual(str, length) {
    var grEq = false;
    if (NotEmpty(str) && str.length >= length) {
        grEq = true;
    }
    return grEq;
}

export function Timeout(ms, func) {
    if (Exists(ms) && Exists(func)) {
        setTimeout(() => {
            func();
        }, ms);
    }
}

export const defaultErrMsg = () => {
    return "An error occurred. Please try again or contact support.";
}
export const AgDateMxWd = () => {
    return 150;
}
export const AgCurrencyMxWd = () => {
    return 120;
}
export const AgIntMxWd = () => {
    return 80;
}
export const AgDateMxWdSm = () => {
    return AgDateMxWd() - 20;
}
export const AgCurrencyMxWdSm = () => {
    return AgCurrencyMxWd() - 20;
}
export const AgIntMxWdSm = () => {
    return AgIntMxWd() - 10;
}