import React, { Component } from 'react';
import { initFlowbite } from "flowbite";

import { SampleCheckoutGrid } from './SampleCheckoutGrid';

export class SampleCheckoutSearch extends Component {

    //constructor(props) {
    //    super(props);
    //}

    componentDidMount() {    
        initFlowbite();
    }

    render() {
        return (
            <div>
                <SampleCheckoutGrid></SampleCheckoutGrid>
            </div>
        );
    }
}
