import React, { useEffect, useState } from 'react';

import { initFlowbite } from "flowbite";
import { Table, Accordion } from 'flowbite-react';
import { Button } from 'react-daisyui';

import { get, post } from '../../services/apiService.js';

import {
    AddClassById, ClickById, RemoveClassByClass, SetHrefById
} from '../../js/helpers.js';
import {
    formatCurrencyDecimal, formatCurrencyDecStr, NotEmpty, Exists, PreventDefault, IdExists, IsTrue
} from '../../js/formHelpers.js';

import { OrdersGrid } from '../OrderSearch/OrdersGrid';
import { Equals } from '../../js/calculationHelpers.js';
/*import { OrderLinesTable } from '../_reactHelpers/ReactHtmlHelpers.js';*/

export const BillingGroupModal = (props) => {
    const onCloseModal = props.onCloseModal;
    const propsBillingGroupId = props.billingGroupId ?? 0;
    const propsOrderId = props.orderId ?? 0;

    const [accountUrl, setAccountUrl] = useState("");
    const [billingGroupData, setBillingGroupData] = useState(null);
    const [orderData, setOrderData] = useState(null);

    const [lastPanelId, setLastPanelId] = useState("");
    const [showSearch, setShowSearch] = useState(false);
    const [orderAdded, setOrderAdded] = useState(false);
    
    const [modalClass, setModalClass] = useState("");
    const [modalTitle, setModalTitle] = useState("");

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");

    //Methods 

    function onClose(e) {
        PreventDefault(e);
        onCloseModal(e);
    }

    function UpdateOrderData(bgOrders, orderId) {
        var success = false;
        if (bgOrders && bgOrders.length > 0) {
            var orders = bgOrders.filter((element) => {
                return element.orderId === orderId;
            });

            if (orders && orders.length === 1) {
                var order = orders[0];
                if (Exists(order)) {
                    setAccountUrl("/Redirect?route=EditAccount&accountId=" + order.account.accountId);
                    setOrderData(order);
                    success = true;
                }
            }
        }
        return success;
    }

    //Methods

    async function GetBillingGroup() {
        var resp = null;
        if (propsBillingGroupId !== 0) {
            var endpoint = 'orders/GetBillingGroupModel/' + propsBillingGroupId;
            try {
                const response = await get(endpoint);
                //console.log('Response: ' + JSON.stringify(response));

                if (response.value) {
                    var respData = response.value;
                    if (respData.success === true) {
                        var bg = respData.data;
                        if (Exists(bg)) {
                            setBillingGroupData(bg);
                            UpdateOrderData(bg.orderModels, propsOrderId);
                            resp = true;
                        }
                    }
                    else {
                        console.error("getBillingGroup api error: " + respData.error);
                        console.error("apiUrl: " + endpoint);
                        resp = respData;
                    }
                }
                else {
                    console.error("getBillingGroup api error, no response.value: " + response);
                    console.error("apiUrl: " + endpoint);
                    resp = response;
                }
            } catch (error) {
                console.error("error fetching getBillingGroup: ", error);
                console.error("apiUrl: " + endpoint);
                resp = error;
            };
        }
        else {
            alert("Order is not part of a Billing Group.");
        }
        return resp;
    }

    async function AddOrderToBillingGroup(orderId) {
        var resp = null;
        if (orderId) {

            var endpoint = 'orders/AddOrderToBillingGroup/' + billingGroupData.billingGroupId + '/' + orderId;
            try {
                const response = await post(endpoint);
                //console.log('Response: ' + JSON.stringify(response));

                if (response.value) {
                    var respData = response.value;
                    if (respData.success === true) {
                        var data = respData.data;
                        if (Exists(data)) {
                            if (data === -1) {
                                resp = -1;
                            }
                            else {
                                setBillingGroupData(data);
                                UpdateOrderData(data.orderModels, orderId);
                                resp = orderId;
                            }
                        }
                    }
                    else {
                        console.error("AddOrderToBillingGroup api error: " + respData.error);
                        console.error("apiUrl: " + endpoint);
                        resp = respData;
                    }
                }
                else {
                    console.error("AddOrderToBillingGroup api error, no response.value: " + response);
                    console.error("apiUrl: " + endpoint);
                    resp = response;
                }
            } catch (error) {
                console.error("error fetching AddOrderToBillingGroup: ", error);
                console.error("apiUrl: " + endpoint);
                resp = error;
            }
        }
        else {
            alert("An error occurred. Please try again or contact support.")
        }
        return resp;
    }

    async function AddOrderToBillGroup(e, orderId) {
        PreventDefault(e);
        setOrderAdded(false);

        var success = false;
        if (orderId) {
            var resp = await AddOrderToBillingGroup(orderId);
            if (IdExists(resp)) {
                success = true;
                setOrderAdded(true);
                setShowSearch(false);
            }
            else if (resp === -1) {
                success = true;
                alert("Order has already been added to a billing group.");
            }
        }
        if (success !== true) {
            alert("An error occurred. Please try again or contact support.")
        }
    }

    function BillingGroupContent() {
        return <div>
            {orderData &&
                <div className="pt-1">
                    <div className="w-[980px] mr-auto ml-auto">
                        {/*** Account ***/}
                        <div className="table-cell p-1">
                            <div className={"act-job bc-block p-2 pt-1 h-[120px]"}>
                                <div className="orh-bold">
                                    <div className="act-label pb-1">Account Name:</div>
                                </div>
                                {
                                    orderData.accountId > 0 && orderData.accountAddressId > 0 &&
                                    <div className="clear">
                                        <div>
                                        <a href={accountUrl} //target="_blank" rel="noreferrer"
                                            className="link-primary underline">
                                                {orderData.account.name}
                                            </a>
                                        </div>
                                        <div>{orderData.accountAddress.address1}</div>
                                        {orderData.accountAddress.address2 &&
                                            <div>{orderData.accountAddress.address2}</div>
                                        }
                                        <div>
                                            <span>
                                                {orderData.accountAddress.city}
                                            </span>
                                            {orderData.accountAddress.city && orderData.accountAddress.state &&
                                                <span>,</span>
                                            }
                                            <span className="pl-[5px]">{orderData.accountAddress.state}</span>
                                            <span className="pl-[5px]">{orderData.accountAddress.zip}</span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        {/*** Job ***/}
                        <div className="table-cell p-1">
                            <div className={"act-job bc-block p-2 pt-1 h-[120px]"}>
                                <div className="orh-bold">
                                    <div className="act-label pb-1">Job Name:</div>
                                </div>
                                <div className="clear">
                                    {orderData && orderData.jobModel &&
                                        <div>
                                            <div>{orderData.jobModel.jobName}</div>
                                            <div>{orderData.jobModel.jobAddress1}</div>
                                            {
                                                orderData.jobModel.jobAddress2 &&
                                                <div>{orderData.jobModel.jobAddress2}</div>
                                            }
                                            <div>
                                                <span>
                                                    {orderData.jobModel.jobCity}
                                                </span>
                                                {
                                                    orderData.jobModel.jobCity &&
                                                    orderData.jobModel.jobState &&
                                                    <span>,</span>
                                                }
                                                <span className="pl-[5px]">{orderData.jobModel.jobState}</span>
                                                <span className="pl-[5px]">{orderData.jobModel.jobZip}</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {/*** Totals ***/}
                        <div className="table-cell p-1 w-[220px]">
                            <div className={"act-job bc-block p-2 pt-1 pr-0 h-[120px]"}>
                                <div className="orh-bold">
                                    <div className="act-label pb-1">Totals:</div>
                                </div>
                                <div className="clear">
                                    <div className="table-cell">
                                        <div className="fourth-block w-[220px]">
                                            <div>
                                                <div className="label-block-4">Grand Total</div>
                                                <div className="tbl-spcr"></div>
                                                <div className="label-block-2">
                                                    <span>
                                                        {formatCurrencyDecStr(billingGroupData.grandTotal)}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="tbl-spcr"></div>
                                            <div>
                                                <div className="label-block-4">Payments</div>
                                                <div className="tbl-spcr"></div>
                                                <div className="label-block-2">
                                                    <span>
                                                        {formatCurrencyDecStr(billingGroupData.payments)}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="tbl-spcr"></div>
                                            <div>
                                                <div className="label-block-4">Balance</div>
                                                <div className="tbl-spcr"></div>
                                                <div className="label-block-2">
                                                    <span className="text-red">
                                                        {formatCurrencyDecStr(billingGroupData.balance)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*** Orders Grid ***/}
                    <div className="pt-8">
                        <div className="clear tbl-order-lines-div min-h-[450px] h-[450px] rounded border-gray-200 border">
                            <Table className="tbl-order-lines">
                                <Table.Head>
                                    <Table.HeadCell className="w-[60px]"></Table.HeadCell>
                                    <Table.HeadCell>Order #</Table.HeadCell>
                                    <Table.HeadCell>Order Date</Table.HeadCell>
                                    <Table.HeadCell>Delivered Date</Table.HeadCell>
                                    <Table.HeadCell>Total</Table.HeadCell>
                                    <Table.HeadCell>Payments</Table.HeadCell>
                                    <Table.HeadCell>Balance</Table.HeadCell>                                    
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    {getOrders()}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
            }
        </div>
    }

    function OpenPanel(e, orderId, bgRowId, detailsId) {
        PreventDefault(e);
        setOrderAdded(false);

        //prevent re-click on panel for better UI behavior
        if (detailsId !== lastPanelId) {

            //select order
            SelectOrder(e, orderId, bgRowId);
            //close last open panel            
            ClickById(lastPanelId);            
            //open order panel
            setLastPanelId(detailsId);
            ClickById(detailsId);
        }
        else {
            //close re-clicked panel
            setLastPanelId("");
            ClickById(detailsId);
        }
    }

    function OpenOrder(e, orderLinkId) {
        PreventDefault(e);
        setOrderAdded(false);
        ClickById(orderLinkId);
    }

    function SelectOrder(e, orderId, bgRowId) {
        PreventDefault(e);
        setOrderAdded(false);

        var success = UpdateOrderData(billingGroupData.orderModels, orderId);
        if (success === false) {
            alert("Order not found for Billing Group.");
        }
        else {
            //higlight row
            RemoveClassByClass("bill-group-row", "ag-row-selected-bg");
            AddClassById(bgRowId, "ag-row-selected-bg");
        }
    }

    function getOrders() {
        var lineList = [];

        var oms = billingGroupData.orderModels ?? null;
        if (oms) {
            var i = 0;
            oms.forEach((om, index) => {
                var rowCssClass = "";
                i += 1;

                var orderUrl = "/Redirect?route=EditOrder&orderId=" + om.orderId;
                if (IsTrue(om.isClaimType)) {
                    orderUrl = "/Redirect?route=EditClaim&orderId=" + om.orderId;
                }
                else if (IsTrue(om.isInventoryType)) {
                    orderUrl = "/Redirect?route=EditInventoryOrder&orderId=" + om.orderId;
                }

                var orderLinkId = "lnkBgOpenOrder-" + om.orderId;
                var detailsId = "detailsId-" + i;
                var bgRowId = "bgRowId-" + i;
                var selectedRow = Equals(propsOrderId, om.orderId) ? " ag-row-selected-bg " : "";

                lineList.push(
                    <Table.Row key={i} id={bgRowId} className={"bill-group-row cursor-pointer " + selectedRow + " " + rowCssClass}>
                        <Table.Cell className="w-[85px]" onClick={e => OpenPanel(e, om.orderId, bgRowId, detailsId)}>
                            <span className="bg-pnl-close text-primary underline cursor-pointer">
                                View Lines
                            </span>
                        </Table.Cell>
                        <Table.Cell onDoubleClick={e => OpenOrder(e, orderLinkId)}>
                            <span className="text-secondary">
                                {om.orderNumber}
                            </span>
                            <a id={orderLinkId} href={orderUrl}
                                //target="_blank" rel="noreferrer"
                                className="text-primary underline hidden">
                                Open Order
                            </a>
                        </Table.Cell>
                        <Table.Cell onDoubleClick={e => OpenOrder(e, orderLinkId)}>
                            <span className="">
                                {om.orderDateStr}
                            </span>
                        </Table.Cell>
                        <Table.Cell onDoubleClick={e => OpenOrder(e, orderLinkId)}>
                            <span className="">
                                {om.deliveredDateStr}
                            </span>
                        </Table.Cell>
                        <Table.Cell onDoubleClick={e => OpenOrder(e, orderLinkId)}>
                            {IsTrue(om.isOrderType) &&
                                <span className="">{formatCurrencyDecStr(om.orderTotal)}</span>
                            }
                        </Table.Cell>
                        <Table.Cell onDoubleClick={e => OpenOrder(e, orderLinkId)}>
                            {IsTrue(om.isOrderType) &&
                                <span className="">{formatCurrencyDecStr(om.payments)}</span>
                            }
                        </Table.Cell>
                        <Table.Cell onDoubleClick={e => OpenOrder(e, orderLinkId)}>
                            {IsTrue(om.isOrderType) &&
                                <span className="text-red">{formatCurrencyDecStr(om.balance)}</span>
                            }
                        </Table.Cell>                        
                    </Table.Row>
                );

                var ols = om.orderLines ?? null;
                i += 1;
                lineList.push(
                    <Table.Row key={i} className={"!p-0 !border-0 " + rowCssClass}>
                        <Table.Cell colSpan="8" className="!p-0">
                            <Accordion collapseAll className="!p-0 !border-0 bg-all-panels">
                                <Accordion.Panel>
                                    <Accordion.Title id={detailsId} className="hidden">View Lines</Accordion.Title>
                                    <Accordion.Content className="!p-0 !border-0">
                                        <div className="border-t">
                                            <div className="pl-4 pr-2" style={{ borderBottom: "1px solid lightgrey" }}>                                                
                                                <table className="w-full">
                                                    <tr className="">
                                                        <th className="bg-gray-50">#</th>
                                                        <th className="bg-gray-50">Style / Color</th>
                                                        <th className="bg-gray-50">Category</th>
                                                        <th className="bg-gray-50">Qty / Units</th>
                                                        <th className="bg-gray-50">Status</th>
                                                    </tr>
                                                    {ols && ols.map(ol =>
                                                        <tr className="orders-orderLine-content cursor-pointer"
                                                            onDoubleClick={e => OpenOrder(e, orderLinkId)}>
                                                            <td className="border-t pt-[2px] pr-[4px]">
                                                                <div className="text-primary">
                                                                    {ol.lineNumberStr}
                                                                    {/*<span>.</span>*/}
                                                                </div>
                                                            </td>
                                                            <td className="border-t pt-[2px] pr-[4px]">
                                                                <div className="min-w-[250px]">
                                                                    {ol.style}
                                                                    {ol.color !== "" &&
                                                                        <span> - {ol.color}</span>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td className="border-t pt-[2px] pr-[4px]">
                                                                <div>
                                                                    <div className="status-cell">
                                                                        <span>{ol.productCategoryName}</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="border-t pt-[2px] pr-[4px]">
                                                                <div className="font-normal">
                                                                    {ol.quantity !== "" &&
                                                                        <span className="pr-1">
                                                                            <span>Qty: </span>
                                                                            {ol.quantity}
                                                                        </span>
                                                                    }
                                                                    {ol.unitsStr !== "" &&
                                                                        <span>{ol.unitsStr}</span>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td className="border-t pt-[2px] pr-[4px]">
                                                                <div>
                                                                    {ol.statusId !== 0 &&
                                                                        <div>
                                                                            <span>{ol.statusStr}</span>
                                                                            {ol.statusDateStr !== "" &&
                                                                                <span>
                                                                                    {ol.statusId !== 2 &&
                                                                                        <span>: </span>
                                                                                    }
                                                                                    {ol.statusId === 2 &&
                                                                                        <span> </span>
                                                                                    }
                                                                                    <span className="date-cell">
                                                                                        <span>{ol.statusDateStr}</span>
                                                                                    </span>
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </table>
                                            </div>
                                        </div>
                                    </Accordion.Content>
                                </Accordion.Panel>
                            </Accordion>
                        </Table.Cell>
                    </Table.Row>
                );
            });
        }
        return lineList;
    }

    function OrderSearchContent() {
        return <div>
            {billingGroupData && billingGroupData.primaryOrderModel &&
                <div className="!max-w-none">
                    <div className="!block">
                        <div className="p-2">
                            <div className="forms-page clear">
                                <div className="">
                                    <OrdersGrid pageType="BillingGroupOrders"
                                        searchStr={billingGroupData.primaryOrderModel.account.name}
                                        addOrderToBillGroup={AddOrderToBillGroup}>
                                    </OrdersGrid>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    }

    function createOrderClick(e) {
        PreventDefault(e);
        var accountId = billingGroupData.primaryOrderModel.account.accountId;
        var billingGroupId = billingGroupData.billingGroupId;
        var href = "/Redirect?route=CreateOrder&accountId=" +
            accountId + "&billingGroupId=" + billingGroupId + "& status=create";

        SetHrefById("lnkBgNewOrder", href);
        ClickById("lnkBgNewOrder");
    }

    function showHideOrderSearch(e) {
        PreventDefault(e);
        setShowSearch(!showSearch);
    }

    // ON LOAD
    useEffect(() => {
        initFlowbite();
        GetBillingGroup();
    }, []);

    return (
        <div className={"billgroup-grid-content " + modalClass}>
            <Button size="sm" color="ghost" shape="circle"
                className="absolute right-0 mr-[15px] min-h-[1.5rem] h-[1.5rem] w-[1.5rem]"
                onClick={(e) => onClose(e)}
            >x</Button>
            <div className="text-center">
                <div className="font-bold mb-1 text-xl">
                    {modalTitle}
                </div>
                <div className="pt-[5px]">
                    <div className={messageClass}>
                        {message}
                    </div>
                </div>
            </div>
            <div className="">
                <div className="pt-1">
                    <div>
                        {showSearch === false &&
                            <div>
                                <button type="button" className="btn-load-orders mb-1 mr-2 float-left"
                                    onClick={e => createOrderClick(e)}>
                                    Create Order
                                </button>
                                <button type="button" className="btn-load-orders mb-1 float-left"
                                    onClick={e => showHideOrderSearch(e)}>
                                    Add Existing Order
                                </button>
                                <div className="float-left pl-[20%]">
                                    <span className="text-[22px] text-secondary font-bold">
                                        Billing Group
                                        <span> - </span>
                                        {orderData && orderData.orderNumber}
                                    </span>
                                    <div className="h-[24px] text-center">
                                        {orderAdded &&
                                            <span className="text-green">
                                                Order Added to Billing Group
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {showSearch === true &&
                            <div className="pl-2">
                                <button type="button" className="btn-cancel float-left"
                                    onClick={e => showHideOrderSearch(e)}>
                                        Cancel
                                </button>
                                <div className="text-[22px] text-secondary font-bold text-center">
                                    Add Order to Billing Group
                                </div>
                            </div>
                        }
                    </div>
                    <div className="clear">
                        {showSearch === false &&
                            <div>
                                {BillingGroupContent()}
                            </div>
                        }
                        {showSearch === true &&
                            <div>
                                {OrderSearchContent()}
                            </div>
                        }
                    </div>
                </div>
            </div>
            <a href="\" id="lnkBgNewOrder" //target="_blank" rel="noreferrer"
                className="hidden">New Order</a>
        </div>
    );
}