import React, { useState } from 'react';
import { Component } from 'react';
import { StoresGrid } from './StoresGrid';

export class StoresSearch extends Component {
    static displayName = StoresSearch.name;

    //constructor() {
    //  super();
    //  this.state = { data: [], loading: true };
    //}

    render() {
        return (
            <div>
                <StoresGrid></StoresGrid>
            </div>
        );
    }
}
