import React, { useState, useEffect } from 'react';
import { Button } from 'react-daisyui';
import { post } from '../../../services/apiService.js';
import '../../../css/custom.css';

export const EditTaxForm = ({ tax, onTaxUpdated, closeEditModal, taxes }) => {
    const [formData, setFormData] = useState({ ...tax });
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        setFormData({ ...tax });
    }, [tax]);

    const states = [
        { name: 'Alabama', abbreviation: 'AL' },
        { name: 'Alaska', abbreviation: 'AK' },
        { name: 'Arizona', abbreviation: 'AZ' },
        { name: 'Arkansas', abbreviation: 'AR' },
        { name: 'California', abbreviation: 'CA' },
        { name: 'Colorado', abbreviation: 'CO' },
        { name: 'Connecticut', abbreviation: 'CT' },
        { name: 'Delaware', abbreviation: 'DE' },
        { name: 'Florida', abbreviation: 'FL' },
        { name: 'Georgia', abbreviation: 'GA' },
        { name: 'Hawaii', abbreviation: 'HI' },
        { name: 'Idaho', abbreviation: 'ID' },
        { name: 'Illinois', abbreviation: 'IL' },
        { name: 'Indiana', abbreviation: 'IN' },
        { name: 'Iowa', abbreviation: 'IA' },
        { name: 'Kansas', abbreviation: 'KS' },
        { name: 'Kentucky', abbreviation: 'KY' },
        { name: 'Louisiana', abbreviation: 'LA' },
        { name: 'Maine', abbreviation: 'ME' },
        { name: 'Maryland', abbreviation: 'MD' },
        { name: 'Massachusetts', abbreviation: 'MA' },
        { name: 'Michigan', abbreviation: 'MI' },
        { name: 'Minnesota', abbreviation: 'MN' },
        { name: 'Mississippi', abbreviation: 'MS' },
        { name: 'Missouri', abbreviation: 'MO' },
        { name: 'Montana', abbreviation: 'MT' },
        { name: 'Nebraska', abbreviation: 'NE' },
        { name: 'Nevada', abbreviation: 'NV' },
        { name: 'New Hampshire', abbreviation: 'NH' },
        { name: 'New Jersey', abbreviation: 'NJ' },
        { name: 'New Mexico', abbreviation: 'NM' },
        { name: 'New York', abbreviation: 'NY' },
        { name: 'North Carolina', abbreviation: 'NC' },
        { name: 'North Dakota', abbreviation: 'ND' },
        { name: 'Ohio', abbreviation: 'OH' },
        { name: 'Oklahoma', abbreviation: 'OK' },
        { name: 'Oregon', abbreviation: 'OR' },
        { name: 'Pennsylvania', abbreviation: 'PA' },
        { name: 'Rhode Island', abbreviation: 'RI' },
        { name: 'South Carolina', abbreviation: 'SC' },
        { name: 'South Dakota', abbreviation: 'SD' },
        { name: 'Tennessee', abbreviation: 'TN' },
        { name: 'Texas', abbreviation: 'TX' },
        { name: 'Utah', abbreviation: 'UT' },
        { name: 'Vermont', abbreviation: 'VT' },
        { name: 'Virginia', abbreviation: 'VA' },
        { name: 'Washington', abbreviation: 'WA' },
        { name: 'West Virginia', abbreviation: 'WV' },
        { name: 'Wisconsin', abbreviation: 'WI' },
        { name: 'Wyoming', abbreviation: 'WY' }
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'taxCode') {
            const selectedOption = taxes.find(option => option.taxCode === value);
            console.log('Tax change: ' + JSON.stringify(selectedOption));
            if (selectedOption) {
                setFormData(prevData => ({
                    ...prevData,
                    taxCode: value,
                    salesTaxPercent: selectedOption.salesTaxPercent,
                    city: selectedOption.city,
                    state: selectedOption.state,
                    zip: selectedOption.zip,
                    county: selectedOption.county,
                    cityTaxPercent: selectedOption.cityTaxPercent
                }));
            }
        } else {
            setFormData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccess('');
        setError('');
        try {
            var update = await post(`citysalestax/UpdateCitySalesTax/${tax.salesTaxId}`, formData);
            console.log('Updated tax: ' + JSON.stringify(update));
            setSuccess('Tax Updated Successfully');
            onTaxUpdated();
        } catch (error) {
            setError('Error updating tax: ' + error.message);
        }
    };

    return (
        <div className="w-full max-w-4xl mx-auto">
            <div className="flex border-b">
                <div className="p-4 flex-grow">
                    <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                        <div className="flex space-x-4">
                            <div className="form-control flex-1">
                                <label className="label">
                                    <span className="label-text">City</span>
                                </label>
                                <input type="text" name="city" value={formData.city} onChange={handleChange} placeholder="City" className="input input-bordered w-full" required />
                            </div>

                            <div className="form-control flex-1">
                                <label className="label">
                                    <span className="label-text">County</span>
                                </label>
                                <input type="text" name="county"
                                    value={formData.county} onChange={handleChange}
                                    className="input input-bordered w-full" />
                            </div>

                            <div className="form-control flex-1">
                                <label className="label">
                                    <span className="label-text">State</span>
                                </label>
                                <select
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                    className="select select-bordered w-full"
                                    required
                                >
                                    <option value="">Select a State</option>
                                    {states.map((state) => (
                                        <option key={state.abbreviation} value={state.abbreviation}>
                                            {state.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="form-control flex-1">
                                <label className="label">
                                    <span className="label-text">ZIP</span>
                                </label>
                                <input type="text" name="zip"
                                    className="input input-bordered w-full"
                                    value={formData.zip} onChange={handleChange} />
                            </div>
                        </div>

                        <div className="flex space-x-4">
                            <div className="form-control flex-1">
                                <label className="label">
                                    <span className="label-text">Tax Method</span>
                                </label>
                                <select className="select select-bordered w-full" name="taxMethod"
                                    value={formData.taxMethod} onChange={handleChange}>
                                    <option value="Sales Tax">Sales Tax</option>
                                </select>
                            </div>

                            <div className="form-control flex-1">
                                <label className="label">
                                    <span className="label-text">Tax Code</span>
                                </label>
                                <select
                                    name="taxCode" className="select select-bordered w-full"
                                    value={formData.taxCode}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select Tax Code</option>
                                    {taxes.map(option => (
                                        <option key={option.taxCode} value={option.taxCode}>
                                            {option.taxCode} - {option.city}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="form-control flex-1">
                                <label className="label">
                                    <span className="label-text">Sales Tax Base</span>
                                </label>
                                <select className="select select-bordered w-full"
                                    name="salesTaxBase" value={formData.salesTaxBase} onChange={handleChange}>
                                    <option value="Material">Material</option>
                                </select>
                            </div>

                            <div className="form-control flex-1">
                                <label className="label">
                                    <span className="label-text">Pay Tax Time</span>
                                </label>
                                <select className="select select-bordered w-full" name="payTaxTime"
                                    value={formData.payTaxTime} onChange={handleChange}>
                                    <option value="Delivered">Delivered</option>
                                </select>
                            </div>
                        </div>

                        <div className="flex space-x-2">
                            <div className="form-control flex-1">
                                <label className="label">
                                    <span className="label-text">Sales Tax Percent</span>
                                </label>
                                <input type="number" name="salesTaxPercent"
                                    className="input input-bordered w-full" required step="0.01"
                                    value={formData.salesTaxPercent} onChange={handleChange}
                                    placeholder="" />
                            </div>

                            <div className="form-control flex-1">
                                <label className="label">
                                    <span className="label-text">City Tax Percent</span>
                                </label>
                                <input type="number" name="cityTaxPercent"
                                    className="input input-bordered w-full" required step="0.01"
                                    value={formData.cityTaxPercent} onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="flex justify-end">
                            <Button
                                type="button"
                                className="btn-cancel"
                                onClick={closeEditModal}
                            >
                                Cancel
                            </Button>

                            <Button
                                className="btn-submit"
                                onClick={handleSubmit}
                            >
                                Update Tax
                            </Button>
                        </div>
                    </form>
                </div>
            </div>

            <div className="text-red-500 p-4">{error}</div>
            <div className="text-green-500 font-bold p-4">{success}</div>
        </div>
    );
}