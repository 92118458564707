import React, { useState } from 'react';
import { Component } from 'react';

import { initFlowbite } from "flowbite";

import { ProductSalesGrid } from './ProductSalesGrid';

export class ProductSalesSearch extends Component {
    static displayName = ProductSalesSearch.name;

    constructor() {
        super();
        this.state = { data: [], loading: true };
    }

    componentDidMount() {
        initFlowbite();
    }

    render() {
        return (
            <div className="p-2">
                <ProductSalesGrid pageType="sales"></ProductSalesGrid>
            </div>
        );
    }
}
