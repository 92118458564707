import React, { useState } from 'react';
import { post } from '../../services/apiService';

export default function BankAccountForm ({ onBankAccountAdded, onClose }) {
    const [formData, setFormData] = useState({
        bankName: '',
        bankAccountName: '',
        bankAccountNumber: '',
        bankRoutingNumber: '',
        bankOpeningBalance: 0
    });

    const [statusMessage, setStatusMessage] = useState('');
    const [isError, setIsError] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await post('bankaccount/CreateBankAccount', formData);
            setStatusMessage('Bank Account Added Successfully');
            setIsError(false);
            onBankAccountAdded();
            clearForm();
        } catch (error) {
            setStatusMessage('Error adding Bank Account');
            setIsError(true);
        }
    };

    const clearForm = () => {
        setFormData({
            bankName: '',
            bankAccountName: '',
            bankAccountNumber: '',
            bankRoutingNumber: '',
            bankOpeningBalance: 0
        });
    };

    return (
        <div className="">
            <h2 className="text-2xl font-bold mb-4 text-center">Add New Bank Account</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="bankName" className="label-form">Bank Name</label>
                    <input
                        type="text"
                        id="bankName"
                        name="bankName"
                        value={formData.bankName}
                        onChange={handleChange}
                        className="label-form-input"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="bankAccountName" className="label-form">Account Name</label>
                    <input
                        type="text"
                        id="bankAccountName"
                        name="bankAccountName"
                        value={formData.bankAccountName}
                        onChange={handleChange}
                        className="label-form-input"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="bankAccountNumber" className="label-form">Account Number</label>
                    <input
                        type="text"
                        id="bankAccountNumber"
                        name="bankAccountNumber"
                        value={formData.bankAccountNumber}
                        onChange={handleChange}
                        className="label-form-input"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="bankRoutingNumber" className="label-form">Routing Number</label>
                    <input
                        type="text"
                        id="bankRoutingNumber"
                        name="bankRoutingNumber"
                        value={formData.bankRoutingNumber}
                        onChange={handleChange}
                        className="label-form-input"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="bankOpeningBalance" className="label-form">Opening Bank Balance</label>
                    <input
                        type="number"
                        id="bankOpeningBalance"
                        name="bankOpeningBalance"
                        value={formData.bankOpeningBalance}
                        onChange={handleChange}
                        className="label-form-input"
                        required
                    />
                </div>
                {statusMessage && (
                    <div className={`p-4 rounded-md ${isError ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'}`}>
                        {statusMessage}
                    </div>
                )}
                <div className="flex justify-end space-x-2">
                    <button type="button" onClick={onClose} className="btn-cancel">Cancel</button>
                    <button type="submit" className="btn-submit">Add Bank Account</button>
                </div>
            </form>
        </div>
    );
}

