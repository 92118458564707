import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Modal, Button } from 'react-daisyui';

import { get } from '../../../services/apiService.js';
import { PreventDefault } from '../../../js/formHelpers.js';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

export function AuditEntriesModal({ orderId, onClose, orderNum }) {

    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    //const [userId, setUserId] = useState('');
    //const [userData, setUserData] = useState(null);
    //const [orderData, setOrderData] = useState(null);
    //const [quickFilterText, setQuickFilterText] = useState('');
    //const [gridApi, setGridApi] = useState(null);

    const gridRef = useRef();

    const dateFormatter = (params) => {
        if (params.value) {
            const date = new Date(params.value);
            return date.toLocaleString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            }).replace(',', '');
        }
        return '';
    };

    const columnDefs = [
        { headerName: 'ID', field: 'id', hide: true },
        { headerName: '', field: 'tableName', maxWidth: 140 },
        { headerName: 'Field', field: 'propertyName', maxWidth: 200, dataType: 'string' },
        { headerName: 'New Value', field: 'newValue', dataType: 'string' },
        { headerName: 'Old Value', field: 'oldValue', dataType: 'string' },        
        { headerName: 'Changed By', field: 'username', maxWidth: 180, dataType: 'string' },
        {
            headerName: 'Changed On', field: 'dateTimeStr', maxWidth: 200,
        },
    ];

    const defaultColDef = {
        flex: 1,
        sortable: false,
        resizable: true,
        filter: true,
        suppressMenu: true,
        suppressMovable: true,
        cellClass: ["no-border"]
    };

    function handleQuickFilter(e) {
        var api = gridRef.current.api;
        const value = e.target.value.toLowerCase();
        api.setQuickFilter(value);
    };


    const getAuditEntries = async (id) => {
        const response = await get('/orders/GetAuditEntriesForOrder/' + id);
        setRowData(response);
    }

    const onGridReady = useCallback((params) => {
        setLoading(true);
        setError(null);

        try {
            getAuditEntries(orderId);
            params.api.sizeColumnsToFit();
        } catch (error) {
            setError('Error fetching order activity');
        } finally {
            setLoading(false);
        }
    }, [orderId]);


    function onCloseModal(e) {
        PreventDefault(e);
        setIsOpen(false);
        onClose();
    }

    useEffect(() => {
        setIsOpen(true);
    }, []);

    return (
        <Modal open={isOpen} className="max-w-[1400px] w-11/12 py-2 px-4">
            <div className="flex justify-between items-center pb-2">
                <Modal.Header className="font-bold m-0 text-center">
                    Activity Log - Order #{orderNum}
                </Modal.Header>
                <Button size="sm" color="ghost" shape="circle" onClick={e => onCloseModal(e)}
                    className="absolute right-4 top-2 min-h-[1.5rem] h-[1.5rem] w-[1.5rem]"
                >x</Button>
            </div>

            <Modal.Body>
                {loading && <div className="loading loading-spinner loading-lg"></div>}
                {error && <div className="alert alert-error">{error}</div>}

                <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
                    <div>
                        <label for="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input autoFocus onChange={handleQuickFilter} type="search" id="search" placeholder="Filter..."
                                className="orders-filter-search block p-2 mb-2 pl-10 text-sm text-gray-900 
                                        border border-gray-300 rounded-lg !focus:ring-blue-500 !focus:border-blue-500;"></input>
                            <div id="selectedRows" />
                        </div>
                    </div>

                    <AgGridReact
                        ref={gridRef}
                        columnDefs={columnDefs}
                        rowData={rowData}
                        defaultColDef={defaultColDef}
                        onGridReady={onGridReady}
                        pagination={true}
                        paginationPageSize={15}
                        domLayout='autoHeight'
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}