import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Button } from 'react-daisyui';

import { getPaymentsForOrder } from '../../../services/paymentService.js';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';


export function ReceiptsModal({ isOpen, onClose, orderId, orderNum }) {
    const [rowData, setRowData] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [quickFilterText, setQuickFilterText] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const dateFormatter = (params) => {
        if (params.value) {
            const date = new Date(params.value);
            return date.toLocaleString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            }).replace(',', '');
        }
        return '--';
    };

    const valueFormatter = (params) => {
        return params.value != null && params.value !== '' ? params.value : '--';
    };

    const amountFormatter = (params) => {
        return params.value != null ? `$${params.value.toFixed(2)}` : '--';
    };

    const columnDefs = [
        { headerName: 'Payment Type', field: 'paymentType', flex: 1, valueFormatter: valueFormatter },
        { headerName: 'Full Name', field: 'fullName', flex: 1.5, valueFormatter: valueFormatter },
        { headerName: 'Amount', field: 'amount', flex: 1, valueFormatter: amountFormatter },
        { headerName: 'Last 4', field: 'identifier', flex: 0.8, valueFormatter: valueFormatter },
        { headerName: 'Check #', field: 'checkNumber', flex: 1, valueFormatter: valueFormatter },
        { headerName: 'Status', field: 'status', flex: 1, valueFormatter: valueFormatter },
        { headerName: 'Transaction Date', field: 'lastUsed', flex: 1.5, valueFormatter: dateFormatter },
        { headerName: 'Created By', field: 'createdBy', flex: 1.2, valueFormatter: valueFormatter },
        {
            headerName: 'Print',
            cellRenderer: params => {
                return <Button size="sm" className="primary btn-submit" onClick={() => downloadReceiptPDF(params.data.paymentId)}>Print</Button>
            },
            flex: 0.8
        }
    ];

    const defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true,
        tooltipComponent: 'customTooltip',
        tooltipValueGetter: (params) => params.value,
    };

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    }, []);

    const onQuickFilterChanged = useCallback((event) => {
        setQuickFilterText(event.target.value);
        gridApi?.setQuickFilter(event.target.value);
    }, [gridApi]);

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getPaymentsForOrder(orderId);
                setRowData(response);
                console.log(response);
            } catch (error) {
                console.error('Error fetching payment receipts:', error);
            }
            setIsLoading(false);
        }

        if (isOpen) {
            fetchData();
        }
    }, [orderId, isOpen]);

    const CustomTooltip = (props) => {
        return (
            <div className="custom-tooltip bg-white p-2 shadow-lg rounded">
                {props.value && props.value !== '--' ? props.value : 'No data available'}
            </div>
        );
    };

    const downloadReceiptPDF = async (paymentId) => {
        try {
            setIsLoading(true);
            const apiUrl = process.env.REACT_APP_API_URL + 'payments/GetPaymentReceipt/' + paymentId;
            console.log('API URL: ' + apiUrl);

            const response = await fetch(apiUrl);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `Receipt-${paymentId}.pdf`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error downloading PDF:', error);
        }
    };

    const onRowDoubleClicked = (params) => {
        downloadReceiptPDF(params.data.paymentId);
    };

    return (
        <Modal open={isOpen} className="w-11/12 max-w-[1400px] p-4">
            {isLoading && (
                <div className="loading loading-spinner loading-lg text-primary"></div>
            )}
            <div className="flex justify-between items-center pb-2">
                <Modal.Header className="font-bold m-0 text-center">
                    Receipts - Order #{orderNum}
                </Modal.Header>
                <Button size="sm" color="ghost" shape="circle" onClick={e => onClose(e)}
                    className="absolute right-4 top-2 min-h-[1.5rem] h-[1.5rem] w-[1.5rem]"
                >x</Button>
            </div>
            <Modal.Body className="p-0">
                <div>
                    <label htmlFor="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                        </div>
                        <input autoFocus onChange={onQuickFilterChanged} type="search" id="search" placeholder="Filter..."
                            className="orders-filter-search block p-2 mb-2 pl-10 text-sm text-gray-900 
                                border border-gray-300 rounded-lg !focus:ring-blue-500 !focus:border-blue-500;" />
                        <div id="selectedRows" />
                    </div>
                </div>
                <div className="ag-theme-alpine" style={{ height: 'calc(80vh - 200px)', width: '100%' }}>
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowData={rowData}
                        defaultColDef={defaultColDef}
                        onGridReady={onGridReady}
                        quickFilterText={quickFilterText}
                        pagination={true}
                        paginationPageSize={30}
                        domLayout='autoHeight'
                        tooltipShowDelay={0}
                        tooltipHideDelay={2000}
                        components={{
                            customTooltip: CustomTooltip,
                        }}
                        onRowDoubleClicked={onRowDoubleClicked}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}