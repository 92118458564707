import React from 'react';

export const VendorNameRenderer = (props) => {

    const vendor = props.data ? props.data : null;
    const vendorId = vendor ? vendor.vendorId : 0;
    const vendorName = vendor ? vendor.name : "";
        
    return (
        <div className="ag-cell-div">
            <div className="ag-cell-title">
                <span className="text-secondary text-left">
                    {vendorName}
                </span>
                <input type="hidden" value={vendorId}></input>
            </div>
        </div>
    );
};
