//import React, { useEffect } from 'react';

import { NotStringEmpty } from "../../js/formHelpers";

export const AccountVendorInfo = (props) => {
    const accountData = props.accountData;    
    const vendorData = props.vendorData;

    // ON LOAD
    //useEffect(() => {
    //}, []);

    return (
        <div className="">
            {accountData && accountData.accountId > 0 && accountData.accountAddressId > 0 &&
                <div className="clear">
                    <div>
                        <a href={"/Redirect?route=EditAccount&accountId=" + accountData.accountId}
                            className="link-primary underline"
                            target="_blank" rel="noreferrer">
                            {accountData.accountName}
                        </a>
                    </div>
                    <div>{accountData.address1}</div>
                    {accountData.address2 &&
                        <div>{accountData.address2}</div>
                    }
                    <div>
                        <span>
                            {accountData.city}
                        </span>
                        {accountData.city && accountData.state &&
                            <span>,</span>
                        }
                        <span className="pl-[5px]">{accountData.state}</span>
                        <span className="pl-[5px]">{accountData.zip}</span>
                    </div>
                    <div>
                        {NotStringEmpty(accountData.accountEmail) &&
                            <a href={"mailto:" + accountData.accountEmail} className="underline link-primary">
                                {accountData.accountEmail}
                            </a>
                        }
                    </div>
                    <div>{accountData.accountPhone}</div>
                </div>
            }
            {vendorData && vendorData.vendorId > 0 && vendorData.vendorAddressId > 0 &&
                <div className="clear">
                    <div>
                        <a href={"/Redirect?route=EditVendor&vendorId=" + vendorData.vendorId} 
                            className="link-primary underline" target="_blank" rel="noreferrer">
                            {vendorData.name}
                        </a>
                    </div>
                    <div>{vendorData.address1}</div>
                    {vendorData.address2 &&
                        <div>{vendorData.address2}</div>
                    }
                    <div>
                        <span>
                            {vendorData.city}
                        </span>
                        {vendorData.city && vendorData.state &&
                            <span>,</span>
                        }
                        <span className="pl-[5px]">{vendorData.state}</span>
                        <span className="pl-[5px]">{vendorData.zip}</span>
                    </div>
                </div>
            }
        </div>

    );
}
