import React from 'react';
import { Component } from 'react';

import { VendorsGrid } from './VendorsGrid';
import './_vendorCustom.css';

export class VendorsSearch extends Component {
    static displayName = VendorsSearch.name;

    //constructor() {
    //  super();
    //  this.state = { data: [], loading: true };
    //}

    render() {
        return (
            <div>
                <VendorsGrid></VendorsGrid>
            </div>
        );
    }
}
