import { NotEmpty, NotStringEmpty } from "../../js/formHelpers";

export const JobInfo = (props) => {
    const orderData = props.orderData;    

    // ON LOAD
    //useEffect(() => {    
    //}, []);

    return (
        <div className="clear">
            {orderData && orderData.jobModel &&
                <div>
                    <div>{orderData.jobModel.jobName}</div>
                    <div>{orderData.jobModel.jobAddress1}</div>
                    {
                        NotEmpty(orderData.jobModel.jobAddress2) &&
                        <div>{orderData.jobModel.jobAddress2}</div>
                    }
                    <div>
                        <span>
                            {orderData.jobModel.jobCity}
                        </span>
                        {
                            orderData.jobModel.jobCity &&
                            orderData.jobModel.jobState &&
                            <span>,</span>
                        }
                        <span className="pl-[5px]">{orderData.jobModel.jobState}</span>
                        <span className="pl-[5px]">{orderData.jobModel.jobZip}</span>
                    </div>
                    <div>{orderData.jobModel.jobPlacedByName}</div>
                    <div>
                        {NotStringEmpty(orderData.jobModel.jobPlacedByEmail) &&
                            <a href={"mailto:" + orderData.jobModel.jobPlacedByEmail} className="underline link-primary">
                                {orderData.jobModel.jobPlacedByEmail}
                            </a>
                        }
                    </div>
                    <div>{orderData.jobModel.jobPlacedByPhone}</div>
                </div>
            }
        </div>
    );
}
