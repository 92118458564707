import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

//import { initFlowbite } from "flowbite";

import { GetLocationStateId, SetDocumentTitle }from '../_reactHelpers/ReactHelpers';
import { StandardDataAndResponsePOST, StandardIdAndResponseGET } from '../../js/dataHelpers';
import { Equals } from '../../js/calculationHelpers';
import {
    AddClassByClass, RemoveClassByClass, RemoveClassById, RemoveClassByName, SetPageTitle
}
from '../../js/helpers';
import {
    Exists, handleFieldChange, IdExists, IsEmpty,
    IsFalse, IsTrue, NotEmpty, NotExists, ParseIntSafe,
    PreventDefault, SetAndClickById, SwitchFbTab
}
from '../../js/formHelpers';

import { ProductDetails } from './_tabs/_productDetails.js';
import { ProductStyleNums } from './_tabs/_productStyleNums.js';
import { ProductBundles } from './_tabs/_productBundles.js';

export const ProductAddEdit = (props) => {
    //const [docTitle, setDocTitle] = SetDocumentTitle("Lumina Product");
    const location = useLocation();
    const navigate = useNavigate();

    const propsOnCloseModal = props.onCloseModal ?? null;
    const propsProductId = props.productId ?? 0;
    const propsProductSkuId = props.productSkuId ?? 0;
    const propsProductPriceId = props.productPriceId ?? 0;
    const propsOrderId = ParseIntSafe(props.orderId) ?? 0;
    
    const propsPageType = props.pageType ?? "";
    const propsIsPricingPageType = props.isPricingPageType ?? false;;
    const propsIsAdminPageType = props.isAdminPageType ?? false;
    const propsIsSalesPageType = props.isSalesPageType ?? false;
    const propsIsOrderPageType = props.isOrderPageType ?? false;

    const propsOrderAddNext = props.orderAddNext ?? false;
    const propsOnCloseAddToOrderModal = props.onCloseAddToOrderModal ?? null;

    const [productId, setProductId] = useState(0);
    const [productData, setProductData] = useState(null);
    const [productMessage, setProductMessage] = useState("");
    
    const [detailsTab, setDetailsTab] = useState(null);
    const [colorSkuTab, setColorSkuTab] = useState(null);
    const [bundlesTab, setBundlesTab] = useState(null);

    const [redirectSubmit, setRedirectSubmit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    // Helpers

    function onCloseClick(msg, reloadProductId, loadTab) {
        //PreventDefault(e);

        if (propsIsOrderPageType) {
            propsOnCloseModal(propsOrderAddNext, msg);
        }
        else if (IdExists(reloadProductId))
        {
            setDetailsTab(null);
            setBundlesTab(null);
            setColorSkuTab(null);

            setProductMessage(msg);
            loadProductData(reloadProductId, msg, loadTab);
        }
        else {
            propsOnCloseModal(msg);
        }
    }

    function updateMessage(msg, cssClass, errorMsg, logMsg) {
        if (errorMsg) {
            console.error(errorMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setMessage(msg);
        setMessageClass(cssClass);
    }

    // Details tab --------------------------------------

    function returnDetailsTab(data, msg, loadTab) {

        return <ProductDetails pageType={propsPageType} productData={data}
            isPricingPageType={propsIsPricingPageType} isAdminPageType={propsIsAdminPageType}
            isSalesPageType={propsIsSalesPageType} isOrderPageType={propsIsOrderPageType}
            productMessage={msg} loadTab={loadTab} onCloseModal={onCloseClick}>
        </ProductDetails>;
    }

    function loadProductDetailsTab(data, msg, loadTab) {
        setDetailsTab(returnDetailsTab(data, msg, loadTab));
    }

    // Bundles tab --------------------------------------

    function returnBundlesTab(data) {

        return <ProductBundles pageType={propsPageType} productData={data} orderId={propsOrderId}
            isPricingPageType={propsIsPricingPageType} isAdminPageType={propsIsAdminPageType}
            isSalesPageType={propsIsSalesPageType} isOrderPageType={propsIsOrderPageType}
            orderAddNext={propsOrderAddNext} onCloseModal={onCloseClick} 
            >
        </ProductBundles>;
    }

    function loadProductBundlesTab(data) {
        setBundlesTab(returnBundlesTab(data));
    }

    // StyleNums tab --------------------------------------

    function returnColorSkuTab(data, msg,) {

        return <ProductStyleNums pageType={propsPageType} productData={data} orderId={propsOrderId}
            isPricingPageType={propsIsPricingPageType} isAdminPageType={propsIsAdminPageType}
            isSalesPageType={propsIsSalesPageType} isOrderPageType={propsIsOrderPageType}
            message={msg} onCloseModal={onCloseClick}>
        </ProductStyleNums>;
    }

    function loadColorSkuTab(data, msg) {
        setColorSkuTab(returnColorSkuTab(data, msg));
    }


    // Load Data --------------------------------------

    async function loadProductData(reloadProductId, msg, loadTab) {
        var success = false;

        var qProductId = propsProductId;
        if (IdExists(reloadProductId)) {
            qProductId = reloadProductId;
        }

        var idStr = qProductId + "/" + propsProductSkuId + "/" + propsPageType;
        var data = await StandardIdAndResponseGET(idStr, "product/GetProductDetails/", "loadProductData()");

        if (Exists(data)) {

            var iRespId = ParseIntSafe(data.productId);
            if (IdExists(iRespId) || Equals(iRespId, 0)) {
                success = true;

                setProductId(iRespId);
                if (Exists(data.product)) {
                    setProductData(data.product);
                }

                reloadProductId = ParseIntSafe(reloadProductId) ?? 0;

                var dtMsg = "";
                var csMsg = "";
                if (loadTab === "skuColor") {
                    csMsg = msg;
                }
                else {
                    dtMsg = msg;
                }

                loadProductDetailsTab(data, dtMsg, loadTab);
                loadColorSkuTab(data, csMsg);
                loadProductBundlesTab(data);                
            }
        }

        if (IsTrue(success)) {
            setLoading(false);
        }
        else {
            setError(true);
        }
    };

    // ON LOAD

    useEffect(() => {
        loadProductData();
    }, []);

    if (IsTrue(loading)) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }
    if (IsTrue(error))
        return <div className="text-red-500 text-center mt-[25%] text-xl">{defaultErrMsg}</div>;

    return (
        <div className="py-2 px-4">
            <div className="acct-tabs float-left">
                <ul className="fb-tabs-ul border-b border-gray-200" id="default-tab"
                    data-tabs-toggle="#default-tab-content" role="tablist">

                    {(propsIsPricingPageType || propsIsAdminPageType) &&
                        <li className="mr-1 text-blue-600 bg-gray-100 rounded-t-lg border border-gray-200 border-b-0"
                            role="presentation">
                            <button onClick={e =>
                                SwitchFbTab(e, "fb-tabs-btns", "prod-tab-content", "ProductDetails-tab", "ProductDetails")}
                                className="fb-tab-btn fb-tabs-btns" type="button" role="tab" aria-selected="true"
                                data-tabs-target="#ProductDetails" id="ProductDetails-tab"
                                aria-controls="ProductDetails">
                                Details
                            </button>
                        </li>
                    }
                    {(propsIsPricingPageType || propsIsAdminPageType) &&
                        <li className="mr-1 text-blue-600 bg-gray-100 rounded-t-lg border border-gray-200 border-b-0"
                            role="presentation">
                            <button onClick={e =>
                                SwitchFbTab(e, "fb-tabs-btns", "prod-tab-content", "ProductSkus-tab", "ProductSkus")}
                                className="fb-tab-btn fb-tabs-btns" type="button" role="tab" aria-selected="false"
                                data-tabs-target="#ProductSkus" id="ProductSkus-tab" aria-controls="ProductSkus">
                                Pricing
                            </button>
                        </li>
                    }
                    {(propsIsPricingPageType || propsIsAdminPageType || propsIsSalesPageType || propsIsOrderPageType) &&
                        <li className="mr-1 text-blue-600 bg-gray-100 rounded-t-lg border border-gray-200 border-b-0"
                            role="presentation">
                            <button onClick={e =>
                                SwitchFbTab(e, "fb-tabs-btns", "prod-tab-content", "ProductBundles-tab", "ProductBundles")}
                                className="fb-tab-btn fb-tabs-btns" type="button" role="tab" aria-selected="false"
                                data-tabs-target="#ProductBundles" id="ProductBundles-tab" aria-controls="ProductBundles">
                                Related Products
                            </button>
                        </li>
                    }
                    {/*<li className="mr-2 text-blue-600 bg-gray-100 rounded-t-lg border border-gray-200 border-b-0"*/}
                    {/*    role="presentation">*/}
                    {/*    <button className="fb-tab-btn" type="button" role="tab" aria-selected="false"*/}
                    {/*        data-tabs-target="#Quotes" id="Quotes-tab" aria-controls="Quotes">*/}
                    {/*        Quotes</button>*/}
                    {/*</li>*/}
                    {/*<li className="mr-2 text-blue-600 bg-gray-100 rounded-t-lg border border-gray-200 border-b-0"*/}
                    {/*    role="presentation">*/}
                    {/*    <button className="fb-tab-btn" type="button" role="tab" aria-selected="false"*/}
                    {/*        data-tabs-target="#Details" id="Details-tab" aria-controls="Details">*/}
                    {/*        Details</button>*/}
                    {/*</li>*/}
                </ul>
            </div>            
            <div className="text-secondary txt-lg mr-[45%] float-right">
                <div className="text-center">                    
                    <div className="font-bold text-[22px]">{productData.style}</div>
                    {(propsIsSalesPageType || propsIsOrderPageType) &&
                        <span className="font-bold">Current Store: Tigard</span>
                        //{user.currentStoreName}
                    }
                </div>
            </div>            

            <div className="clear">
                <div id="default-tab-content" className="act-tabs rounded border-gray-200 border">
                    {(propsIsPricingPageType || propsIsAdminPageType) &&
                        <div className="hidden prod-tab-content" role="tabpanel"
                            id="ProductDetails" aria-labelledby="ProductDetails-tab">
                            {detailsTab}
                        </div>
                    }
                    {(propsIsPricingPageType || propsIsAdminPageType) &&
                        <div className="hidden prod-tab-content" role="tabpanel"
                            id="ProductSkus" aria-labelledby="ProductSkus-tab">
                            {colorSkuTab}
                        </div>
                    }
                    {(propsIsPricingPageType || propsIsAdminPageType || propsIsSalesPageType || propsIsOrderPageType) &&
                        <div className="hidden prod-tab-content" role="tabpanel"
                            id="ProductBundles" aria-labelledby="ProductBundles-tab">
                            {bundlesTab}
                        </div>
                    }
                    {/*<div className="hidden" role="tabpanel" id="Quotes" aria-labelledby="Quotes-tab">*/}
                    {/*    <div className="">*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="hidden" role="tabpanel" id="Details" aria-labelledby="Details-tab">*/}
                    {/*    <div className="">*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
}