import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';
//import { useNavigate, useLocation } from 'react-router-dom';

import { SetDocTitle } from "../_reactHelpers/ReactHelpers";
import { SetPageTitle } from '../../js/helpers.js';
import { IdExists, NotEmpty, NotStringEmpty } from '../../js/formHelpers.js';
import { get } from '../../services/apiService.js';


import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
//import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../css/ag-theme-lumina.css'; // local ag-theme-alpine.css
import { PcvpAddEdit } from './PcvpAddEdit';

export function PcvpGrid() {

    const [modalContent, setModalContent] = useState(null);

    const [error, setError] = useState(false);    
    const [message, setMessage] = useState("");
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const [rowData, setRowData] = useState([]);

    const columnDefs = [
        { headerName: "Id", field: "productCategoryVendorRateId", hide: true },
        {
            headerName: 'Vendor', field: 'vendorName', cellClass: ["text-wrap"], //width: 300,
        },
        {
            headerName: 'Category', field: 'productCategoryName', cellClass: ["text-wrap"], //width: 280, 
        },
        {
            headerName: "BC Mult", field: "priceBcMultiplier", maxWidth: 110,
        },
        {
            headerName: "MSRP Mult", field: "msrpMultiplier", maxWidth: 140,
        },
        {
            headerName: "MSRP From", field: "msrpFrom", maxWidth: 140,
        },
        {
            headerName: "Freight", field: "freight", maxWidth: 100,
        },
        {
            headerName: "Load", field: "load", maxWidth: 90,
        },
        {
            headerName: "PNW", field: 'isPnw', maxWidth: 90, //hide: true 
        },
        {
            headerName: "AZ", field: 'isAz', maxWidth: 90, //hide: true 
        },
        {
            headerName: "Web", field: 'isWeb', maxWidth: 90, //hide: true 
        }
    ];

    const gridOptions = {
        defaultColDef: {
            flex: 1,
            sortable: true,
            resizable: true,
            filter: true,
            suppressMovable: true,
            suppressMenu: true,
            cellClass: ["no-border"]
        }
    };

    async function loadData() {
        showLoading();
        var resp = null;

        try {
            const endpoint = 'product/GetCategoryVendorRatesPaged';
            //console.log("apiUrl: " + endpoint);

            const response = await get(endpoint);
            //console.log('GetCategoryVendorPricingPaged Response: ' + JSON.stringify(response));

            if (response.value) {
                var respData = response.value;
                if (respData && respData.success === true) {
                    //return true for success response
                    resp = true;
                    if (NotEmpty(respData.data)) {
                        setRowData(respData.data);
                        hideOverlay();
                    }
                    else {
                        setRowData([]);
                    }
                }
                else {
                    console.error("GetCategoryVendorPricingPaged api error: " + respData.error);
                    console.error("apiUrl: " + endpoint);
                    resp = respData;
                }
            }
            else {
                console.error("GetCategoryVendorPricingPaged api error no response.value: " + response);
                console.error("apiUrl: " + endpoint);
                resp = response;
            }

        } catch (error) {
            console.log('Error: ' + error);
            resp = error;
        }
        return resp;
    };

    const onGridReady = useCallback((params) => {
        loadData();
    }, []);

    function handleSearch(e) {        
        var api = gridRef.current.api;
        const value = e.target.value.toLowerCase();
        api.setQuickFilter(value);
    };
    function showLoading() {
        gridRef.current.api.showLoadingOverlay();
    }
    function hideOverlay() {
        gridRef.current.api.hideOverlay();
    }

    function onRowDoubleClicked(data) {
        if (data) {            
            openRateModal(data.productCategoryVendorRateId);
        }
    }

    function returnModalComponent(productCategoryVendorRateId) {
        return <PcvpAddEdit onClose={handleCloseModal} id={productCategoryVendorRateId}></PcvpAddEdit>;
    }

    function openRateModal(productCategoryVendorRateId) {
        setModalContent(returnModalComponent(productCategoryVendorRateId));
    }

    function handleCloseModal(msg) {
        setModalContent(null);
        setMessage(msg ?? "");
        // always reload to get updated data
        loadData();
    }

    // ON LOAD
    useEffect(() => {
        SetDocTitle("Lumina - Vendor Category Rate Admin");
        SetPageTitle("Vendor Category Rate Admin");
    }, []);

    if (error === true)
        return <div className="text-red-500 text-center mt-[15%] text-xl">{defaultErrMsg}</div>;

    return (
        <div style={containerStyle} className="p-2 pt-0">
            <div className="ag-theme-alpine w-[1600px] mx-auto" style={{ height: '750px' }}>

                <div className="pt-2">
                    <div className="float-left">
                        <div className="table-cell pr-2">
                            <button type="button" className="btn-load-orders mr-2" onClick={e => openRateModal(0)}>
                                Create Rate
                            </button>
                        </div>
                        <div className="table-cell">
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <div>
                                    <input id="search" type="search" placeholder="Filter..." 
                                        className=" w-[400px] block p-2 mb-4 pl-10 text-sm text-gray-900 
                                            border border-gray-300 rounded-lg
                                            focus:ring-blue-500 focus:border-blue-500"
                                        onChange={handleSearch}
                                        autoFocus
                                    />
                                </div>
                            </div>
                            <div id="selectedRows" />
                        </div>
                        <div className="text-green text-[1rem] pl-4 table-cell">{message}</div>
                    </div>                    
                </div>
                <div className="clear"></div>

                <AgGridReact
                    masterDetail={true}
                    rowData={rowData}
                    ref={gridRef}
                    gridOptions={gridOptions}
                    columnDefs={columnDefs}
                    onGridReady={onGridReady}
                    onRowDoubleClicked={(e) => onRowDoubleClicked(e.data)}
                />
            </div>

            <div>
                {modalContent}
            </div>
        </div>
    );
}