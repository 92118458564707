import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import { Modal } from 'react-daisyui';

import { GetLocationStateId, SetDocumentTitle } from '../../_reactHelpers/ReactHelpers';
import { ClickById, GetValueById, RemoveClassById, SetPageTitle } from '../../../js/helpers';
import {
    Exists, formatCurrencyDecimal, formatCurrencyDecStr, handleFieldChange, IdExists, IsEmpty,
    IsFalse,
    IsTrue, NotEmpty, NotExists, ParseIntSafe, PreventDefault, SetAndClickById, SetFocusById, SwitchFbTab
}
from '../../../js/formHelpers';
import {
    StandardDataAndResponsePOST, StandardIdAndResponseGET,
    GetProductCategorySelectList, GetStoreSelectList, GetVendorSelectList
}
from '../../../js/dataHelpers';
import { formatNumberDecPlaces } from '../../../js/formHelpers';
import { GreaterThan } from '../../../js/calculationHelpers';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { ModuleRegistry } from '@ag-grid-community/core';
import { MasterDetailModule } from 'ag-grid-enterprise'; //'@ag-grid-enterprise/master-detail';

import { ProductSearchGrid } from '../../_reactHelpers/Product/ProductSearchGrid.js';

ModuleRegistry.registerModules([MasterDetailModule]);

export const ProductBundles = (props) => {    
    const location = useLocation();
    const navigate = useNavigate();

    const propsOnCloseModal = props.onCloseModal ?? null;
    const propsProductData = props.productData ?? null;
    const propsOrderId = ParseIntSafe(props.orderId);

    const propsPageType = props.pageType ?? "";
    const propsIsPricingPageType = props.isPricingPageType ?? false;;
    const propsIsAdminPageType = props.isAdminPageType ?? false;
    const propsIsSalesPageType = props.isSalesPageType ?? false;
    const propsIsOrderPageType = props.isOrderPageType ?? false;

    //const propsOrderAddNext = props.orderAddNext ?? false;
    //const propsOnCloseAddToOrderModal = props.onCloseAddToOrderModal ?? null;

    const [productId, setProductId] = useState(0);
    const [productData, setProductData] = useState(null);
    const [bundlesData, setBundlesData] = useState([]);
    const [selectedBundleProducts, setSelectedBundleProducts] = useState([]);
    const [addBundlesCount, setAddBundlesCount] = useState(0);

    const [boxQtyStr, setBoxQtyStr] = useState("");    
    const [childCssClass, setChildCssClass] = useState("");
    const [btnCssClass, setBtnCssClass] = useState("");

    const [showBundlesGrids, setShowBundlesGrids] = useState(true);
    const [showAddProductsGrid, setShowAddProductsGrid] = useState(false);    
    const [addProductsSearchGrid, setAddProductsSearchGrid] = useState(null);

    const [redirectSubmit, setRedirectSubmit] = useState(false);
    const [saveRelatedProducts, setSaveRelatedProducts] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const addTbId = "addBundleProductInp-";
    const [bundleMessage, setBundleMessage] = useState("");
    const [bundleMessageClass, setBundleMessageClass] = useState("");
    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const defaultErrMsg = "An error occurred. Please try again or contact support.";


    // Parent Grid

    const parentGridRef = useRef();
    const [parentGridData, setParentGridData] = useState([]);
    const [parentProduct, setParentProduct] = useState([]);

    const containerStyle1 = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const [gridHeight1, setGridHeight1] = useState(getParentGridHeight());
    const parentColumnDefs = [
        {
            headerName: 'ProductId', field: 'productId', hide: true,
        },
        {
            headerName: "Category", field: 'categoryName', resizable: true,
            maxWidth: 200,
        },
        {
            headerName: 'Style', field: 'style', width: 400,
            //autoHeight: true, cellClass: ["text-wrap"],
        },
        {
            headerName: 'Style #', field: 'styleNum', maxWidth: 200, //cellClass: ["text-wrap"],
        },
        {
            headerName: 'Color', field: 'color', maxWidth: 200, //cellClass: ["text-wrap"],
        },
        {
            headerName: 'Vendor', field: 'vendorName', maxWidth: 300, //cellClass: ["text-wrap"],
        },
    ];

    const parentGridOptions = {
        defaultColDef: {
            flex: 1,
            sortable: false,
            resizable: true,
            filter: true,
            suppressMenu: true,
            suppressMovable: true,
            cellClass: ["no-border"]
        }
    };

    function getParentGridHeight() {
        if (propsIsPricingPageType || propsIsAdminPageType) {
            return "300px";
        }
        else if (propsIsSalesPageType) {
            return "300px";
        }
        else if (propsIsOrderPageType) {
            return "200px";
        }
        else {
            return "200px";
        }
    }

    function handleSearchParents(e) {
        var api = parentGridRef.current.api;
        const value = e.target.value.toLowerCase();
        api.setQuickFilter(value);
    };

    const onGridReadyParent = useCallback((params) => {
        var success = false;

        var pNodes = parentGridRef.current.api.getRenderedNodes();
        if (NotEmpty(pNodes)) {
            var pNode = pNodes[0];
            pNode.setSelected(true);

            var productId = pNode.data.productId;
            var productSkuId = pNode.data.productSkuId;
            success = GetSetParentChildBundles(productId, productSkuId);
        }
    }, []);


    const cellClicked = useCallback((params) => {
        var success = false;

        setBundleMessage("");
        setBundleMessageClass("");

        // give warning on added bundle products?
        if (NotEmpty(selectedBundleProducts)) {
            // save currently selected bundle products
            addBundleProductsQtys();
        }

        if (params && params.data) {
            var parentProductId = params.data.productId;
            var productSkuId = params.data.productSkuId;

            if (IdExists(parentProductId) && IdExists(productSkuId)) {
                //load child bundle products
                success = GetSetParentChildBundles(parentProductId, productSkuId);
            }
        }
    }, []);

    async function GetSetParentChildBundles(productId, productSkuId) {
        var success = false;
        var parentProduct = null
        var childBundles = [];

        // show loading for child grid
        showLoadingChild();

        var data = await ProductChildBundlesGET(productId, productSkuId);
        if (Exists(data)) {
            success = true;

            //set parent for pricing info
            parentProduct = data.product;

            //set children for grid
            if (NotEmpty(data.productBundles)) {

                var childRps = data.productBundles[0].childBundleProducts ?? [];
                childRps.forEach((cbm, index) => {
                    childBundles.push(cbm);
                });
            }
        }

        if (IsTrue(success)) {
            setParentProduct(parentProduct);
            setChildGridData(childBundles);

            setTimeout(() => {
                SetFocusById(addTbId + "0");                
            }, 200);
            
        }
        else {
            showNoRowsChild();
            setParentProduct(null);
            setChildGridData([]);
        }
        hideOverlayChild();

        return success;
    }

    async function ProductChildBundlesGET(productId, productSkuId) {
        var idStr = productId + "/" + productSkuId + "/" + propsPageType;
        var data = await StandardIdAndResponseGET(
            idStr, "product/GetProductChildBundles/", "GetProductChildBundles()");
        return data;
    }

    // Child grid

    const childGridRef = useRef();
    const [childGridData, setChildGridData] = useState([]);

    const bundlesTabModalRef = useRef();
    const [selectedSkuData, setSelectedSkuData] = useState(null);

    const containerStyle2 = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const [gridHeight2, setGridHeight2] = useState(getChildGridHeight());
    const childColumnDefs = [
        {
            headerName: 'RelatedProductId', field: 'relatedProductId', hide: true,
        },
        {
            headerName: 'ProductId', field: 'productId', hide: true,
        },
        {
            headerName: 'Qty', field: 'addProductQuantity', cellDataType: 'number', maxWidth: 100,
            hide: !propsIsOrderPageType, //editable: true,
            cellRenderer: params => {
                var tbId = addTbId + params.rowIndex;
                return <input type="number" className="addBundleProductInp" id={tbId}></input>;
            }
        },
        {
            headerName: "Category", field: 'categoryName', resizable: true,
            hide: true, maxWidth: 250, 
        },
        {
            headerName: 'Style', field: 'style', width: 300, cellClass: ["text-wrap"],
        },
        {
            headerName: 'Style #', field: 'styleNum', maxWidth: 120, //cellClass: ["text-wrap"],
        },        
        {
            headerName: 'Color', field: 'color', width: 300, //cellClass: ["text-wrap"],
            //hide: !propsIsOrderPageType
        },
        {
            headerName: 'Color #', field: 'colorNum', width: 120, //cellClass: ["text-wrap"],
            //hide: !propsIsOrderPageType
        },
        {
            headerName: 'Sku', field: 'sku', maxWidth: 180, //cellClass: ["text-wrap"],
            //hide: !propsIsOrderPageType
        },        
        {
            headerName: 'Vendor', field: 'vendorName', maxWidth: 300, hide: true,
            //autoHeight: true, cellClass: ["text-wrap"],
        },
        {
            headerName: 'Pricing', field: 'relatedProductId', maxWidth: 80,
            hide: (propsIsPricingPageType || propsIsAdminPageType),
            cellRenderer: params => {
                return <div className="underline cursor-pointer text-primary"
                    onClick={e => viewProductPricing(params.data)}>View</div>;
            }
        },
        {
            headerName: '', field: 'relatedProductId', maxWidth: 80,
            hide: !(propsIsPricingPageType || propsIsAdminPageType),
            cellRenderer: params => {
                return <div className="underline cursor-pointer text-primary"
                    onClick={e => deleteRelatedProduct(params.data.relatedProductId)}>
                    Remove
                </div>;
            }
        },
    ];

    const childGridOptions = {
        defaultColDef: {
            flex: 1,
            sortable: false,
            resizable: true,
            filter: true,
            suppressMenu: true,
            suppressMovable: true,
            cellClass: ["no-border"]
        }
    };

    function showLoadingChild() {
        childGridRef.current.api.showLoadingOverlay();
    }
    function showNoRowsChild() {
        childGridRef.current.api.showNoRowsOverlay();
    }
    function hideOverlayChild() {
        childGridRef.current.api.hideOverlay();
    }

    function handleSearchChildren(e) {
        var api = childGridRef.current.api;
        const value = e.target.value.toLowerCase();
        api.setQuickFilter(value);
    };

    function getChildGridHeight() {
        if (propsIsAdminPageType) {
            return "400px";
        }
        else if (propsIsSalesPageType) {
            return "400px";
        }
        else if (propsIsOrderPageType) {
            return "400px";
        }
        else {
            return "400px";
        }
    }

    const onGridReadyChild = useCallback((params) => {

        // load on parent grid ready
        //var nodes = childGridRef.current.api.getRenderedNodes();
        //if (NotEmpty(nodes)) {
        //    var node = nodes[0];            
        //    EditNextRow(0, node);
        //}        

    }, []);

    const onChildCellKeyDown = async (e) => {

        if (propsIsOrderPageType) {
            if (Exists(e) && Exists(e.event)) {

                if (e.event.key === "Tab") {
                    var iRowIndex = ParseIntSafe(e.rowIndex);
                    if (Exists(iRowIndex)) {
                        var nextRowIndex = iRowIndex + 1;
                        EditNextRow(nextRowIndex, e.node);
                    }
                }
                else if (e.event.key === "Enter") {
                    ClickById("btnAddBundlesToOrder");
                }
            }
        }
    }

    function EditNextRow(rowIndex, node) {

        if (propsIsOrderPageType) {
            if (node) {
                node.setSelected(true);
            }

            var tbId = addTbId + rowIndex;
            SetFocusById(tbId);

            //childGridRef.current.api.setFocusedCell(rowIndex, "addProductQuantity");
            //childGridRef.current.api.startEditingCell({
            //    rowIndex: rowIndex,
            //    colKey: "addProductQuantity"
            //});
        }
    }

    //const childCellClicked = useCallback((params) => {
    //    //params.node, params.data        
    //    if (Exists(params.node)) {
    //        params.node.setSelected(true);            
    //    }
    //    viewProductPricing(params.data);
    //}, []);

    function viewProductPricing(data) {
        if (Exists(data) && Exists(data.productPriceModel)) {

            setBoxQtyStr(data.productPriceModel.boxQtyStr);
            setSelectedSkuData(data.productPriceModel);

            //setShowBundlesGrids(false);
            //setShowAddProductsGrid(false);
            //setShowProductPricing(true);
            onOpenBundlesTabModal();
        }
        else {
            alert(defaultErrMsg);
        }
    }


    function onOpenBundlesTabModal(e, msg) {
        PreventDefault(e);
        bundlesTabModalShow();
    }

    function onCloseBundlesTabModal(e, msg) {
        PreventDefault(e);
        setSelectedSkuData(null);
        bundlesTabModalHide();
    }

    const bundlesTabModalShow = useCallback(() => {
        bundlesTabModalRef.current?.showModal();
    }, [bundlesTabModalRef]);

    const bundlesTabModalHide = useCallback(() => {
        bundlesTabModalRef.current?.close();
    }, [bundlesTabModalRef]);


    // Methods

    function addBundleProductsQtys() {
        var bundleProds = [];

        if (propsIsOrderPageType) {

            bundleProds = selectedBundleProducts;
            if (NotExists(bundleProds)) {
                bundleProds = [];
            }

            var userId = getUserId();
            childGridRef.current.api.forEachNode((rowNode) => {

                var tbId = addTbId + rowNode.rowIndex;
                var addProductQuantity = GetValueById(tbId);
                var iQuantity = ParseIntSafe(addProductQuantity);                
                if (GreaterThan(iQuantity, 0)) {

                    var newBundleProd = {
                        orderId: propsOrderId,
                        productId: rowNode.data.productId,
                        productSkuId: rowNode.data.productSkuId,
                        productPriceId: rowNode.data.productPriceId,
                        quantity: iQuantity,
                        userId: userId
                    };
                    bundleProds.push(newBundleProd);
                }
            });

            setAddBundlesCount(bundleProds.length);
            setSelectedBundleProducts(bundleProds);
        }
        return bundleProds;
    }

    async function onAddBundlesToOrderClick(e) {
        PreventDefault(e);
        var success = true;

        var bundleProds = addBundleProductsQtys();
        if (NotEmpty(bundleProds)) {            
            setSaveRelatedProducts(true);

            var resp = await AddBundleProductsToOrder(bundleProds);

            var iRespId = ParseIntSafe(resp);
            if (IdExists(iRespId)) {
                success = true;
            }

            if (IsTrue(success)) {
                // close modal and open add line or go back to order
                propsOnCloseModal("Added to Order");

                //childGridRef.current.api.forEachNode((rowNode) => {
                //    rowNode.setDataValue("addProductQuantity", 0);
                //});

                //setSelectedBundleProducts([]);
                //setBundleMessage("Added to Order");
                //setBundleMessageClass("text-green");                
            }
            else {
                alert(defaultErrMsg);
                setLoading(false);
            }
            setSaveRelatedProducts(false);
        }
        else {
            alert("No bundles selected.")
        }
    }

    async function AddBundleProductsToOrder(modelList) {
        var data = {
            bundleProductsModels: modelList
        };
        var response = null;
        var postModel = JSON.stringify(data);
        var endpoint = "orders/AddBundleProductsToOrder";
        var typeStr = "AddBundleProductsToOrder()";

        response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);
        return response;
    }

    //function onContinueToAddProduct() {
    //    ClickById("btnSelectProduct");
    //}

    function deleteRelatedProduct(rpId) {
        if (window.confirm("Are you sure you want to remove this Product Color from the bundle?")) {

            // TODO: delete in db and reset childData
            var childRows = childGridData.filter(function (rp) {
                return rp.relatedProductId !== rpId;
            });

            childRows = childRows ?? [];
            setChildGridData(childRows);
        }
    }

    function returnAddProductsSearch() {
        return <ProductSearchGrid isBundleAddType={true}
            onBundleAddClose={bundleAddProductSearchClose}>
        </ProductSearchGrid>
    }

    function showAddProductsSearch() {
        setAddProductsSearchGrid(returnAddProductsSearch);

        setShowBundlesGrids(false);
        setShowAddProductsGrid(true);
    }

    function bundleAddProductSearchClose(addProductSkus, addProductSkuIds) {
        setAddProductsSearchGrid(null);

        if (IsTrue(addProductSkus)) {

            if (Exists(addProductSkuIds)) {
                if (NotEmpty(addProductSkuIds)) {
                    // save selected ProductIds and reload
                    // setChildGridData();
                }
                else {
                    alert("No Products selected to add.")
                }
            }
            else {
                alert(defaultErrMsg);
            }
        }
        setShowBundlesGrids(true);
        setShowAddProductsGrid(false);
    }


    // Load Data

    function initGridData(productBundles) {
        var pBundles = [];

        if (NotEmpty(productBundles)) {
            productBundles.forEach((pbm, index) => {
                pBundles.push(pbm.parentProduct);
            });
        }
        setParentGridData(pBundles);
    }

    const loadProductData = async () => {

        if (propsIsPricingPageType || propsIsAdminPageType) {
            setChildCssClass("mt-[-10px]")
            setBtnCssClass("pt-[40px]")
        }
        else if (propsIsSalesPageType) {
            setChildCssClass("pt-8")
        }
        else if (propsIsOrderPageType) {
            setChildCssClass("pt-8")
            setBtnCssClass("pt-[40px]")
        }

        if (Exists(propsProductData)) {

            setProductId(propsProductData.productId);
            setProductData(propsProductData.product);
            setParentProduct(propsProductData.product);
            setBundlesData(propsProductData.productBundles);
            initGridData(propsProductData.productBundles);

            if (propsIsSalesPageType || propsIsOrderPageType) {
                SwitchFbTab(null, "fb-tabs-btns", "prod-tab-content", "ProductBundles-tab", "ProductBundles");
            }
            setLoading(false);
        }
        else {
            console.error("loadProductData - propsProductData is null")
            setError(true);
        }
    };

    // Helpers

    function onCloseClick(msg) {
        //PreventDefault(e);
        propsOnCloseModal(msg);
    }

    function updateMessage(msg, cssClass, errorMsg, logMsg) {
        if (errorMsg) {
            console.error(errorMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setMessage(msg);
        setMessageClass(cssClass);
    }

    function showMessage() {
        if (location.state && NotEmpty(location.state.productMessage)) {
            updateMessage(location.state.productMessage, "text-green");
        }
    }

    function getUserId() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        var cUserId = 0;
        if (userData) {
            cUserId = userData.userId;
        }
        return cUserId;
    }


    /*** ON LOAD ***/
    useEffect(() => {

        showMessage();
        loadProductData();
    }, []);

    if (IsTrue(saveRelatedProducts)) {
        return <div className="text-center text-xl mt-[10%] mb-[10%]">
            <span className="pr-1">Adding all Related Products</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }
    if (IsTrue(loading)) {
        return <div className="text-center text-xl mt-[10%] mb-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }
    if (IsTrue(error))
        return <div className="text-red-500 text-center mt-[10%] text-xl">{defaultErrMsg}</div>;

    return (
        <div className="product-page">
            <div class="details-page-container">
                <div className="forms-page clear"
                //product-bundle-modal-vh
                >
                    {IsTrue(showBundlesGrids) &&
                        <div>
                            {/*** Parent Products ***/}
                            <div className="pb-6">
                                <div style={containerStyle1}>
                                    <div className="ag-theme-alpine" style={{ height: gridHeight1, width: '100%', }}>

                                        {(propsIsSalesPageType || propsIsOrderPageType) &&
                                            <div>
                                                <label for="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                                                <div className="relative">
                                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                        <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                                        </svg>
                                                    </div>
                                                    <input onChange={handleSearchParents} type="search" id="search" placeholder="Filter..."
                                                        className="orders-filter-search @apply block p-2 mb-2 pl-10 text-sm text-gray-900 
                                                            border border-gray-300 rounded-lg !focus:ring-blue-500 !focus:border-blue-500;"></input>
                                                    <div id="selectedRows" />
                                                </div>
                                            </div>
                                        }

                                        <AgGridReact id="parentProducts"
                                            rowData={parentGridData}
                                            ref={parentGridRef}
                                            gridOptions={parentGridOptions}
                                            columnDefs={parentColumnDefs}
                                            onGridReady={onGridReadyParent}
                                            rowSelection='single'
                                            onCellClicked={cellClicked}

                                        //onGridReady={onGridReady}                                            
                                        //rowModelType={rowModelType}
                                        //cacheBlockSize={pageRows}
                                        //onRowDoubleClicked={(e) => onProductRowDoubleClicked(e.data)}
                                        //includeHiddenColumnsInQuickFilter={true}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/*** Child Products ***/}
                            <div className={"clear " + childCssClass}>
                                <div style={containerStyle2}>
                                    <div className="ag-theme-alpine" style={{ height: gridHeight2, width: '100%', }}>
                                        <div className="table-cell">
                                            <label for="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                                            <div className="relative">
                                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                    <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                                    </svg>
                                                </div>
                                                <input onChange={handleSearchChildren} type="search" id="search" placeholder="Filter..."
                                                    className="orders-filter-search @apply block p-2 mb-2 pl-10 text-sm text-gray-900 
                                                    border border-gray-300 rounded-lg !focus:ring-blue-500 !focus:border-blue-500;"></input>
                                                <div id="selectedRows" />
                                            </div>
                                        </div>
                                        <div className="table-cell pl-2 text-[16px]">
                                            <span className={bundleMessageClass}>{bundleMessage}</span>
                                        </div>

                                        <AgGridReact id="childProducts"
                                            rowData={childGridData}
                                            ref={childGridRef}
                                            gridOptions={childGridOptions}
                                            columnDefs={childColumnDefs}
                                            onGridReady={onGridReadyChild}
                                            singleClickEdit={true}
                                            enterNavigatesVertically={true}
                                            enterNavigatesVerticallyAfterEdit={true}
                                            onCellKeyDown={(e) => onChildCellKeyDown(e)}

                                        //onCellClicked={childCellClicked}
                                        //suppressRowClickSelection="true"
                                        //rowSelection='multiple'
                                        //rowMultiSelectWithClick={false}
                                        //onCellClicked={childCellClicked}
                                        //onSelectionChanged={onSelectionChanged}
                                        //rowModelType={rowModelType}
                                        //cacheBlockSize={pageRows}
                                        //onRowDoubleClicked={(e) => onProductRowDoubleClicked(e.data)}
                                        //includeHiddenColumnsInQuickFilter={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {/*** Add Bundle Products Search ***/}
                    {IsTrue(showAddProductsGrid) && (propsIsPricingPageType || propsIsAdminPageType) &&
                        <div className="">
                            {addProductsSearchGrid}
                        </div>
                    }

                    {showBundlesGrids &&
                        <div className="w-full text-right pt-4">
                            <div className={"inline-block mr-1 " + messageClass}>
                                {message}
                            </div>
                            <div className={"inline-block " + btnCssClass}>
                                <div>
                                    {/*{(propsIsPricingPageType || propsIsAdminPageType) &&*/}
                                    {/*    <button type="button" className="btn-submit" //disabled={submitDisabled}*/}
                                    {/*        autoFocus*/}
                                    {/*        onClick={(e) => showAddProductsSearch(e)}>*/}
                                    {/*        Add to Bundle*/}
                                    {/*    </button>*/}
                                    {/*}*/}
                                    {propsIsOrderPageType &&
                                        <div className="inline-block">
                                            <button id="btnAddBundlesToOrder" type="button" className="btn-submit"
                                                autoFocus //disabled={submitDisabled}
                                                onClick={(e) => onAddBundlesToOrderClick(e)}>
                                                Add Selected to Order
                                            </button>
                                            {/*<span>{"Add all to Order (" + addBundlesCount + ")"}</span>*/}

                                            {/*<button type="button" className="btn-submit" //disabled={submitDisabled}*/}
                                            {/*    onClick={(e) => onContinueToAddProduct(e)}>*/}
                                            {/*    Continue to Add Product*/}
                                            {/*</button>*/}
                                        </div>
                                    }
                                    {(propsIsPricingPageType || propsIsAdminPageType || propsIsOrderPageType) &&
                                        <button onClick={e => onCloseClick()} type="button" className="btn-cancel">
                                            Cancel
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    <a href="/" id="lnkReloadPage" className="hidden">Reload</a>
                </div>
            </div>

            <Modal ref={bundlesTabModalRef} className="!w-[600px] p-0">
                <Modal.Body>
                    {Exists(selectedSkuData) &&
                        <div>
                            <div className="text-secondary w-full text-center py-4">
                                <div className="font-bold">
                                    {parentProduct.style}
                                </div>
                                <div>
                                    {selectedSkuData.color}
                                    <span> - </span>
                                    {selectedSkuData.sku}
                                </div>
                            </div>
                            <div className="table-cell w-[200px] pl-[20px]">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span className="font-bold pr-2 text-secondary">MSRP:</span>
                                            </td>
                                            <td>
                                                {formatCurrencyDecStr(selectedSkuData.msrp)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="font-bold pr-2 text-secondary">Box Qty:</span>
                                            </td>
                                            <td>
                                                {boxQtyStr}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="pl-4 table-cell w-[200px]">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span className="font-bold pr-2 text-secondary">BC:</span>
                                            </td>
                                            <td>
                                                <span>
                                                    {formatCurrencyDecStr(selectedSkuData.priceBc)}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="font-bold pr-2 text-secondary">BC1:</span>
                                            </td>
                                            <td>
                                                <span>
                                                    {formatCurrencyDecStr(selectedSkuData.bc1)}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="font-bold pr-2 text-secondary">BC2:</span>
                                            </td>
                                            <td>
                                                <span>
                                                    {formatCurrencyDecStr(selectedSkuData.bc2)}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="pl-2 table-cell w-[200px]">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span className="font-bold pr-2 text-secondary">BC3:</span>
                                            </td>
                                            <td>
                                                <span>
                                                    {formatCurrencyDecStr(selectedSkuData.bc3)}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="font-bold pr-2 text-secondary">BC5:</span>
                                            </td>
                                            <td>
                                                <span>
                                                    {formatCurrencyDecStr(selectedSkuData.bc5)}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="font-bold pr-2 text-secondary">Cost:</span>
                                            </td>
                                            <td>
                                                <span>
                                                    {formatCurrencyDecStr(selectedSkuData.cost)}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    <div className="w-full text-right pt-4 pb-2">
                        <button onClick={e => onCloseBundlesTabModal(e)} type="button" className="btn-cancel">
                            Close
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}