import React, { useState, useEffect } from 'react';

import { DepositTable } from './DepositTable';
import { DepositForm } from './DepositForm';

import { get } from '../../services/apiService';
import { SetDocumentTitle } from '../_reactHelpers/ReactHelpers';
import { SetPageTitle } from '../../js/helpers';
import { ShowHideError } from '../_reactHelpers/ReactHtmlHelpers';

export function Deposit() {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Deposit Registers");

    const [deposits, setDeposits] = useState([]);
    const [showFormModal, setShowFormModal] = useState(false);

    const [error, setError] = useState(null);

    const handleAddDeposit = (newDeposit) => {
        setShowFormModal(false);
        fetchDeposits();
    };

    const fetchDeposits = async () => {
        try {
            const response = await get('/depositregisters/GetDepositRegisters');
            setDeposits(response);
        } catch (err) {
            setError(err.message);
        }
        finally {
        }
    };

    useEffect(() => {
        SetPageTitle("Deposit Registers");
        fetchDeposits();
    }, []);

    if (error) {
        <ShowHideError error={error}></ShowHideError>
    }

    return (
        <div className="mx-auto py-2 px-6">
            <DepositTable
                deposits={deposits}
                fetchDeposits={() => fetchDeposits()}
                openAddModal={() => setShowFormModal(true)}
            />

            {showFormModal &&
                <div className="modal-wrapper">
                    <div className="modal-content">
                        <DepositForm onAddDeposit={handleAddDeposit} onClose={() => setShowFormModal(false)} />
                    </div>
                </div>
            }
        </div>
    );
}