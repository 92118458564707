import React from 'react';

import { ClickById, GetValueById } from '../../../js/helpers.js';
//import { ToggleAllOrderLines, OpenCloseAllOrderLines } from './../_orderSearchHelpers.js';

export const VendorTypeHeaderRenderer = (props) => {
    //const vendor = props.data ? props.data : null;

    var pageType = GetValueById("hfVendorsGridPageType");
    const isClaimPageType = pageType === "claim";
    var defSupplierChecked = isClaimPageType;

    return (
        //div className="table-cell header-cell-details">Details</div>
        //onClick={() => toggleAllOrderLinesHeader()}>
        //onClick={() => openCloseOrderLinesHeader(false)}>
        <div className="vend-head-cell">
            <div className="head-lbl">Type</div>
            <div className="vend-head-cell-cb">
                <div className="cursor-pointer">                    
                    <label className="lbl-cb" for="cbSearchSupplier">
                        Supplier
                        <input
                            type="checkbox"
                            id="cbSearchSupplier"
                            name="cbSearchSupplier"
                            className="inp-cb vend-search-type"
                            value="1"
                            defaultChecked={defSupplierChecked}
                        />                        
                    </label>
                </div>
                <div className="cursor-pointer !mt-[2px]">
                    <label className="lbl-cb" for="cbSearchCommissions">
                        Commissions
                        <input
                            type="checkbox"
                            id="cbSearchCommissions"
                            name="cbSearchCommissions"
                            className="inp-cb vend-search-type"
                            value="2"
                        />                        
                    </label>
                </div>
            </div>
        </div>
    );
}