import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const RedirectHelper = (props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    function navigateFromQuerystring() {
        var route = "/";
        var id = 0;
        var status = "";

        var accountId = 0;
        var billingGroupId = 0;
        var orderId = 0;
        var orderType = 0;
        var vendorId = 0;        
        var message = "";
        
        var entries = searchParams.entries();
        for (const entry of entries) {
            const [param, value] = entry;

            if (param === "route") {
                route = value;
            }
            else if (param === "status") {
                status = value;
            }
            else if (param === "id") {
                id = value;
            }
            else if (param === "accountId") {
                accountId = value;
            }
            else if (param === "billingGroupId") {
                billingGroupId = value;
            }
            else if (param === "orderId") {
                orderId = value;
            }
            else if (param === "orderType") {
                orderType = value;
            }
            else if (param === "vendorId") {
                vendorId = value;
            } 
            else if (param === "message") {
                message = value;
            }  
            //console.log(param, value);
        }

        // Edit Account
        if (route === "EditAccount")
        {
            navigate("/" + route, { replace: true, state: { accountId } });
        }
        // Edit Vendor
        else if (route === "EditVendor")
        {
            navigate("/" + route, { replace: true, state: { vendorId } });
        }
        // Add Order.cs entity
        else if (route === "CreateOrder" || route === "CreateQuote" || route === "CreateClaim" || route === "CreateInventoryOrder")
        {
            navigate("/" + route, { replace: true, state: { accountId, billingGroupId, orderType } });
        }
        // Edit Order.cs entity
        else if (route === "EditOrder" || route === "EditQuote" || route === "EditClaim" || route === "EditInventoryOrder")
        {
            navigate("/" + route, { replace: true, state: { orderId, orderStatus: status } });
        }
        // Add/Edit User
        else if (route === "User")
        {
            navigate("/User", { replace: true, state: { userId: id, userMessage: message } });
        }
        else if (route === "Users") {
            navigate("/Users", { replace: true, state: { userMessage: message } });
        } 
        // Add/Edit Store
        else if (route === "Store")
        {
            navigate("/Store", { replace: true, state: { storeId: id, storeMessage: message } });
        }
        // OrdersGrid.js
        else if (route === "Orders")
        {
            navigate("/Orders", { replace: true, state: { orderType: "", orderMessage: message } });
        }        
        // OrdersGrid.js
        else if (route === "Quotes")
        {
            navigate("/Quotes", { replace: true, state: { orderType: "Quote", orderMessage: message } });
        }
        // ClaimsGrid.js
        else if (route === "Claims")
        {
            navigate("/Claims", { replace: true, state: { orderType: "Claim", orderMessage: message } });
        }
        // generic
        else if (route && route !== null && route !== "")
        {
            navigate("/" + route, { replace: true, state: { id } });
        }
        // fallback
        else {
            navigate("/");
        }
    }

    // ON LOAD
    useEffect(() => {
        navigateFromQuerystring();
    }, []);

    return (
        <div></div>
    )
}

