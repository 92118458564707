import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { defaultErrMsg, Exists, GetDisplayCurrencyStr, GetDisplayDecimalStr, GetDisplayIntStr, GetInputDate, handleFieldChange, IdExists, IsFalse, IsTrue, NotStringEmpty, }
from '../../js/formHelpers';

import { EntityAutocomplete } from '../_reactHelpers/EntityAutocomplete';

import { get } from '../../services/apiService';
import { SetValueById } from '../../js/helpers';

export const AddEditInvoiceModal = ({ initialInvoiceData, handleSubmit, stores, onHide }) => {

    const [isOrderInvoice, setIsOrderInvoice] = useState(false);

    const [gridApi, setGridApi] = useState(null);
    const gridRef = useRef(null);

    const [localFormData, setLocalFormData] = useState(initialInvoiceData || {
        invoiceId: 0,
        invoiceDate: '',
        dueDate: '',
        invoiceNumber: '',
        invoiceTotal: '',
        status: 1,

        accountId: 0,
        accountName: '',      
        
        orderId: 0,
        orderNumber: '',

        storeId: 0,
        storeName: '',

        invoiceLineItems: [],
        customerPoNumber: '',   //unused
    });

    const addNewRow = useCallback(() => {

        setLocalFormData(prevData => {

            const newLineNumber = (prevData.invoiceLineItems?.length || 1) + 1;
            const newRow = {
                lineNumber: newLineNumber,
                orderLineId: 0,
                description: '',
                color: '',
                quantity: 0,
                units: '',
                price: 0,
                //freight: 0,
                vendorName: '',
                storeName: '',
                productCode: 0,
                total: 0
            };

            return {
                ...prevData,
                invoiceLineItems: [
                    ...(prevData.invoiceLineItems || []),
                    newRow
                ]
            };
        });
    }, []);
    
    const handleDeleteRow = useCallback((params) => {

        const updatedLineItems = localFormData.invoiceLineItems.filter((item, index) => index !== params.node.rowIndex);
        setLocalFormData(prevData => ({
            ...prevData,
            invoiceLineItems: updatedLineItems
        }));

    }, [localFormData.invoiceLineItems]);

    const columnDefs = useMemo(() => [
        {
            headerName: '#', maxWidth: 60, valueGetter: 'node.rowIndex + 1',
            editable: false, sortable: false, filter: false, suppressMenu: true,
        },
        {
            field: 'orderLineId', headerName: 'Line Id', maxWidth: 90, 
            editable: false, sortable: false, filter: false, suppressMenu: true,
            valueFormatter: (params) => GetDisplayIntStr(params.value)
        },
        {
            field: 'description', headerName: 'Style',
        },
        {
            field: 'color', headerName: 'Color', maxWidth: 220, 
        },
        {
            field: 'quantity', headerName: 'Qty', maxWidth: 70, 
            valueFormatter: (params) => GetDisplayDecimalStr(params.value)
        },
        {
            field: 'units', headerName: 'Units', maxWidth: 70, 
        },        
        {
            field: 'price', headerName: 'Price', maxWidth: 70,
            valueFormatter: (params) => GetDisplayCurrencyStr(params.value)
        },
        //{
        //    field: 'freight', headerName: 'Freight', maxWidth: 70,
        //    valueFormatter: (params) => GetDisplayCurrencyStr(params.value)
        //},        
        {
            field: 'vendorName', headerName: 'Vendor', maxWidth: 180, 
        },
        {
            field: 'storeName', headerName: 'Warehouse', maxWidth: 110, 
        },
        {
            field: 'productCode', headerName: 'PC', maxWidth: 60, 
            cellEditor: "agNumberCellEditor",
            cellEditorParams: { step: 0, min: 0 }
        },
        {
            field: 'total', headerName: 'Total', maxWidth: 100, editable: false,
            valueFormatter: (params) => GetDisplayCurrencyStr(params.value)
        },
        {
            headerName: '', field: '', maxWidth: 95, editable: false,
            cellRenderer: (params) => {
                return (
                    <button onClick={() => handleDeleteRow(params)}
                        className="btn-grid-red" tabIndex="-1">Delete</button>
                );
            },
            suppressNavigable: true,
        }
    ], [handleDeleteRow]);

    const defaultColDef = useMemo(() => ({
        flex: 1,
        minWidth: 100,
        editable: true,
        sortable: false,
        resizable: false,
        filter: false,
        suppressMovable: true,
        suppressMenu: true,
        cellClass: ["no-border"],
        cellStyle: { fontSize: '14px' },
        singleClickEdit: true,
        stopEditingWhenCellsLoseFocus: true,
    }), []);


    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    }, []);


    const onCellValueChanged = useCallback((event) => {

        const updatedLineItems = [...localFormData.invoiceLineItems];
        const updatedRow = { ...updatedLineItems[event.rowIndex] };

        updatedRow[event.colDef.field] = event.newValue;

        // Calculate total
        const quantity = Number(updatedRow.quantity) || 0;
        const price = Number(updatedRow.price) || 0;
        const freight = Number(updatedRow.freight) || 0;
        updatedRow.total = (quantity * price + freight).toFixed(2);

        updatedLineItems[event.rowIndex] = updatedRow;

        setLocalFormData(prevData => ({
            ...prevData,
            invoiceLineItems: updatedLineItems
        }));

    }, [localFormData.invoiceLineItems, setLocalFormData]);

    async function createNewInvoiceFromOrder(orderId) {
        try {
            if (IdExists(orderId)) {

                const invoiceData = await get('/invoice/CreateNewInvoiceFromOrder/' + orderId);
                if (Exists(invoiceData)) {

                    if (Exists(invoiceData.invoiceDate)) {
                        var inpDate = GetInputDate(invoiceData.invoiceDate);
                        invoiceData.invoiceDate = inpDate;
                    }
                    //if (NotStringEmpty(invoiceData.accountName)) {
                    //    SetValueById("inputEntityAutoComplete-Account", invoiceData.accountName);
                    //}

                    setIsOrderInvoice(true);
                    setLocalFormData(invoiceData);
                }
                else {
                    alert("Unable to create Invoice for selecred Order. Please try again or contact support.")
                }
            }
        } catch (error) {
            console.error('createNewInvoiceFromOrder() error - ', error);
        } finally {
        }
    }

    const handleSelectOrder = (selectedOption) => {

        setLocalFormData(prevData => ({
            ...prevData,
            orderId: selectedOption.orderId,
            orderNumber: selectedOption.orderNumber
        }));       

        createNewInvoiceFromOrder(selectedOption.orderId);
    };

    const handleSelect = (selectedOption) => {
        
        setLocalFormData(prevData => ({
            ...prevData,
            accountId: selectedOption.accountId,
            accountName: selectedOption.name
        }));
    };

    const handleChange = (e) => {
        //console.log('Event:', event);

        var data = handleFieldChange(e);
        if (data) {

            setLocalFormData(prevData => ({
                ...prevData,
                [data.name]: data.value
            }));
        }
        else {
            alert(defaultErrMsg())
        }
    };

    //const progress = calculateProgress();

    //const calculateProgress = () => {
    //    const requiredFields = ['storeId', 'accountName', 'invoiceDate', 'dueDate', 'customerPoNumber', 'invoiceTotal', 'invoiceNumber'];
    //    const filledRequiredFields = requiredFields.filter(field => localFormData[field] && localFormData[field].toString().trim() !== '').length;

    //    const lineItemFieldsCount = localFormData.invoiceLineItems.length * 7;
    //    const filledLineItemFields = localFormData.invoiceLineItems.reduce((count, item) => {
    //        return count + ['lineNumber', 'description', 'quantity', 'priceLevel', 'price', 'freight', 'total'].filter(field => item[field] && item[field].toString().trim() !== '').length;
    //    }, 0);

    //    const totalFields = requiredFields.length + lineItemFieldsCount;
    //    const filledFields = filledRequiredFields + filledLineItemFields;

    //    return (filledFields / totalFields) * 100;
    //};

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        if (!localFormData.storeId || !localFormData.accountId || localFormData.invoiceLineItems?.length === 0) {
            alert('Please fill in all fields and add at least one line item.');
            return;
        }

        try {

            //const formDataToSubmit = { ...localFormData };
            //formDataToSubmit.storeId = parseInt(formDataToSubmit.storeId, 10);

            //['invoiceTotal'].forEach(field => {
            //    formDataToSubmit[field] = parseFloat(formDataToSubmit[field]);
            //});

            //formDataToSubmit.invoiceLineItems = formDataToSubmit.invoiceLineItems.map(item => ({
            //    ...item,
            //    lineNumber: parseInt(item.lineNumber, 10),
            //    quantity: parseFloat(item.quantity),
            //    priceLevel: parseInt(item.priceLevel, 10),
            //    price: parseFloat(item.price),
            //    freight: parseFloat(item.freight),
            //    total: parseFloat(item.total)
            //}));

            //const selectedStore = stores.find(store => store.storeId === formDataToSubmit.storeId);
            //formDataToSubmit.store = selectedStore;
            //console.log('Submitting form data:', formDataToSubmit);


            localFormData.invoiceLineItems = localFormData.invoiceLineItems.map(item => ({
                ...item,
                productCode: item.productCode.toString()
            }));

            const response = await handleSubmit(localFormData);

        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    useEffect(() => {

        if (initialInvoiceData) {
            //console.log('Setting localFormData:', initialInvoiceData);

            initialInvoiceData.invoiceDate = initialInvoiceData.invoiceDate ? GetInputDate(initialInvoiceData.invoiceDate) : '';
            initialInvoiceData.dueDate = initialInvoiceData.dueDate ? GetInputDate(initialInvoiceData.dueDate) : '';
            setLocalFormData(initialInvoiceData);
        }
    }, []);

    useEffect(() => {

        const updatedLineItems = localFormData.invoiceLineItems.map(item => {

            const quantity = Number(item.quantity) || 0;
            const price = Number(item.price) || 0;
            const freight = Number(item.freight) || 0;

            return {
                ...item,
                total: (quantity * price + freight).toFixed(2)
            };
        });

        if (JSON.stringify(updatedLineItems) !== JSON.stringify(localFormData.invoiceLineItems)) {
            setLocalFormData(prevData => ({
                ...prevData,
                invoiceLineItems: updatedLineItems
            }));
        }

    }, [localFormData.invoiceLineItems]);

    return (
        <div className="modal-wrapper">
            <div className="modal-content !max-w-[1600px] !w-full">
                <h2 className="text-2xl font-bold text-center underline">
                    {localFormData.invoiceId ? 'Edit Invoice - ' + localFormData.invoiceId : 'Create Invoice'}
                </h2>
                <div className="overflow-y-auto flex-grow px-2">

                    <form onSubmit={handleFormSubmit} className="">
                        <h4 className="text-xl font-bold">Details</h4>

                        <div className="grid grid-cols-4 gap-4">

                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Order Number</span>
                                </label>
                                {!IdExists(localFormData.invoiceId) &&
                                    <EntityAutocomplete name="orderNumber" id="orderNumber"
                                        onSelect={handleSelectOrder}
                                        onChange={handleChange}
                                        initialEntityId={localFormData.orderId}
                                        entityType="Order"
                                        required
                                    />
                                }
                                {IdExists(localFormData.invoiceId) &&
                                    <input
                                        type="text"
                                        name="orderNumber"
                                        value={localFormData.orderNumber}
                                        onChange={handleChange}
                                        className="input input-bordered"
                                        placeholder=""
                                    />
                                }
                            </div>

                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Store</span>
                                </label>
                                <select
                                    name="storeId"
                                    value={localFormData.storeId}
                                    onChange={handleChange}
                                    className="select select-bordered"
                                >
                                    <option value={0}>-- Select --</option>
                                    {stores.map((store) => (
                                        <option key={store.storeId} value={store.storeId.toString()}>
                                            #{store.storeNumber} - {store.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Account</span>
                                </label>

                                {IsFalse(isOrderInvoice) &&
                                    <EntityAutocomplete name="accountId" id="accountId"
                                        onSelect={handleSelect}
                                        onChange={handleChange}
                                        initialEntityId={localFormData.accountId}
                                        entityType="Account"
                                        required
                                    />
                                }
                                {IsTrue(isOrderInvoice) &&
                                    <input
                                        type="text"
                                        value={localFormData.accountName}
                                        //onChange={handleChange}
                                        className="input input-bordered"
                                        disabled
                                    />
                                }

                            </div>

                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Invoice Number</span>
                                </label>
                                <input
                                    type="text"
                                    placeholder=""
                                    name="invoiceNumber"
                                    value={localFormData.invoiceNumber}
                                    onChange={handleChange}
                                    className="input input-bordered"
                                />
                            </div>

                        </div>
                        <div className="grid grid-cols-5 gap-5 pt-4">

                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Invoice Date</span>
                                </label>
                                <input
                                    type="date"
                                    name="invoiceDate"
                                    value={localFormData.invoiceDate}
                                    onChange={handleChange}
                                    className="input input-bordered"
                                />
                            </div>

                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Due Date</span>
                                </label>
                                <input
                                    type="date"
                                    name="dueDate"
                                    value={localFormData.dueDate}
                                    onChange={handleChange}
                                    className="input input-bordered"
                                />
                            </div>

                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Invoice Total</span>
                                </label>
                                <label className="input-group">
                                    <span>$</span>
                                    <input name="invoiceTotal" type="number" step="0.01" placeholder="" 
                                        className="input input-bordered"
                                        value={localFormData.invoiceTotal}
                                        onChange={handleChange}
                                        onBlur={(e) => {
                                            const formattedValue = Number(e.target.value).toFixed(2);
                                            setLocalFormData(prev => ({
                                                ...prev,
                                                invoiceTotal: formattedValue
                                            }));
                                        }}                                        
                                    />
                                </label>
                            </div>

                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Status</span>
                                </label>
                                <select
                                    name="status"
                                    value={localFormData.status}
                                    onChange={handleChange}
                                    className="select select-bordered"
                                >
                                    <option value={0}>-- Select --</option>
                                    <option value={1}>Unpaid</option>
                                    <option value={2}>Paid</option>
                                </select>
                            </div>

                            {/*<div className="form-control">*/}
                            {/*    <label className="label">*/}
                            {/*        <span className="label-text">Customer PO Number</span>*/}
                            {/*    </label>*/}
                            {/*    <input*/}
                            {/*        type="text"*/}
                            {/*        placeholder=""*/}
                            {/*        name="customerPoNumber"*/}
                            {/*        value={localFormData.customerPoNumber}*/}
                            {/*        onChange={handleChange}*/}
                            {/*        className="input input-bordered"*/}
                            {/*    />*/}
                            {/*</div>*/}
                            
                            {/*<div className="form-control">*/}
                            {/*    <label className="label">*/}
                            {/*        <span className="label-text">Order ID</span>*/}
                            {/*    </label>*/}
                            {/*    <input*/}
                            {/*        type="text"*/}
                            {/*        placeholder=""*/}
                            {/*        name="orderId"*/}
                            {/*        value={localFormData.orderId}*/}
                            {/*        onChange={handleChange}*/}
                            {/*        className="input input-bordered"*/}
                            {/*    />*/}
                            {/*</div>*/}                            
                        </div>
                        

                        <div className="flex-wrapper mt-6 mb-2">
                            <h4 className="text-xl font-bold flex-1">Line Items</h4>                        
                            <button type="button" onClick={addNewRow} className="btn-small">
                                Add New Line Item
                            </button>
                        </div>
                        <div className="ag-theme-alpine w-full ag-invoice-line-items" style={{ height: '300px' }}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={localFormData.invoiceLineItems || []}
                                columnDefs={columnDefs}
                                defaultColDef={{
                                    ...defaultColDef,
                                    editable: true,
                                    singleClickEdit: true,
                                }}
                                onGridReady={onGridReady}
                                onCellValueChanged={onCellValueChanged}
                                editType="fullRow"
                                singleClickEdit={true}
                                enterNavigatesVertically={true}
                                enterNavigatesVerticallyAfterEdit={true}
                                rowSelection="none"
                                //enterMovesDownAfterEdit={true}
                                //stopEditingWhenCellsLoseFocus={true}
                            />
                        </div>
                    </form>
                </div>
                <div className="modal-action mt-6">
                    <button type="button" onClick={onHide} className="btn-cancel">Close</button>
                    <button type="submit" onClick={handleFormSubmit} className="btn-submit">
                        {localFormData.invoiceId ? 'Update Invoice' : 'Create Invoice'}
                    </button>
                </div>
            </div>
        </div>
    );
}