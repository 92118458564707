import React from 'react';

export const AccountNameRenderer = (props) => {

    const account = props.data ? props.data : null;
    const accountId = account ? account.accountId : 0;
    const accountName = account ? account.name : "";

    return (
        <div className="ag-cell-div">
            <div className="ag-cell-title">
                <span className="text-secondary text-left">
                    {accountName}
                </span>
                <input type="hidden" value={accountId}></input>
            </div>
        </div>
    );
};
