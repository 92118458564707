import React, { Component } from 'react';

import authService from '../services/authService';
import { Exists } from '../js/formHelpers';
import { ClickById, SetHrefById, StringContains } from '../js/helpers';


export class Home extends Component {
    static displayName = Home.name;

    componentDidMount() {

        var userData = JSON.parse(localStorage.getItem('userData'));        
        if (Exists(userData) && Exists(userData.user)) {
            //console.log('UserData: ' + JSON.stringify(userData));

            var hlUrl = authService.GetDefaultUserLink(userData);
            // link in LuminaMenu.js
            SetHrefById("homeImageLink", hlUrl);
            ClickById("homeImageLink");
        }
        else {
            console.log('No user data found');
            // link in LuminaMenu.js
            ClickById("lnkMenuLogin");           
        }       
    }

    render() {
        return (
            <div>
                <h1 className="text-2xl text-secondary font-bold text-center pt-20">
                    Hello, Welcome to Lumina!
                </h1>
                <a href="/Orders" id="lnkHomeOrders" className="hidden">Orders Link</a>
                <a href="/Login" id="lnkHomeLogin" className="hidden">Login Link</a>
            </div>
        );
    }
}
