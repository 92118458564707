import React, { useState, useMemo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { OrdersGrid } from '../OrderSearch/OrdersGrid';
//import { OrderDrawer } from '../OrderSearch/OrderDrawer';

export const AccountOrders = (props) => {    
    const location = useLocation();
    const navigate = useNavigate();

    const [accountId, setAccountId] = useState(getAccountId());

    function getAccountId() {
        var accountId = 0;
        try {
            if (location && location.state) {
                accountId = location.state.accountId;
                if (!accountId) {
                    accountId = 0;
                }
            }
        } catch (error) {
            accountId = 0;
        }
        //console.log("getAccountId: ", accountId);
        return accountId;
    }

    return (
        <div className="!max-w-none">
            <div className="!block">
                <div className="p-2">
                    <div className="forms-page clear">
                        <div className="pb-[60px]">
                            <OrdersGrid accountPageType="AccountOrders" accountId={accountId}></OrdersGrid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

