import {
    Exists, formatCurrencyDecimal, IsFalse, NotEmpty
} from './formHelpers.js';
import {
    GetCheckedById,
    GetInputValuesCheckedByClass, GetSelectItemValueById, GetValueById, SetInputCheckedById, SetInputsCheckedByClass,
    SetInputsValueByClass,
    SetValueById
} from './helpers.js';

export function GetOrderEndpoint(sType, searchTerms, pageType, pageRows, page, accountId)
{
    var filtersStr = ""
    if (sType === "order") {
        filtersStr = GetOrderSearchFiltersStr(sType, searchTerms, pageType, pageRows, page, accountId);
    }
    else if (sType === "quote") {
        filtersStr = GetQuoteSearchFiltersStr(sType, searchTerms, pageType, pageRows, page, accountId);
    }
    else if (sType === "claim") {
        filtersStr = GetClaimSearchFiltersStr(sType, searchTerms, pageType, pageRows, page, accountId);
    }

    // advanced filters
    //var stores = getSelectedStores();
    //var sp1Id = GetValueById("filterSp1Id") ?? "";
    //var statusId = ""; //GetValueById("filterStatusId");
    //var orderFrom = GetValueById("filterOrderDateFrom") ?? "";
    //var orderTo = GetValueById("filterOrderDateTo") ?? "";
    //var closedFrom = GetValueById("filterClosedFrom") ?? "";
    //var closedTo = GetValueById("filterClosedTo") ?? "";

    //var quoteExportedFrom = GetValueById("filterQuoteExportedDateFrom") ?? "";
    //var quoteExportedTo = GetValueById("filterQuoteExportedDateTo") ?? "";
    //var quoteExportedBool = GetValueById("filterQuoteExportedCb") ?? "";

    //var endpointOLD = 'orders/SearchOrders?sType=' + sType + "&stores=" + stores + "&accountId=" + propsAccountId +
    //    "&sp1Id=" + sp1Id + "&statusId=" + statusId +
    //    "&orderFrom=" + orderFrom + "&orderTo=" + orderTo +
    //    "&closedFrom=" + closedFrom + "&closedTo=" + closedTo +
    //    //quote
    //    "&quoteExportedFrom=" + quoteExportedFrom + "&quoteExportedTo=" +
    //    quoteExportedTo + "&quoteExportedBool=" + quoteExportedBool +
    //    //paging, search
    //    "&pageType=" + propsPageType + "&sText=" + searchTerms +
    //    "&numRows=" + pageRows + "&page=" + page;

    return filtersStr;
}

function GetOrderSearchFiltersStr(sType, searchTerms, pageType, pageRows, page, accountId) {
        
    var stores = GetSelectedStores();

    var sp1Id = GetSelectItemValueById("filterSp1Id") ?? "0";
    //var statusId = ""; //GetValueById("filterStatusId");

    var orderFrom = GetValueById("filterOrderDateFrom") ?? "";
    var orderTo = GetValueById("filterOrderDateTo") ?? "";
    var closedFrom = GetValueById("filterClosedFrom") ?? "";
    var closedTo = GetValueById("filterClosedTo") ?? "";

    //var filtersModel = {
    //    stores, sp1Id,
    //    orderFrom, orderTo, closedFrom, closedTo,
    //    sType, searchTerms, pageType, pageRows, page, accountId
    //};

    var filtersStr = "orders/SearchOrders?" +
        "sType=" + sType + "&stores=" + stores + "&accountId=" + accountId +
        // order
        "&sp1Id=" + sp1Id + //"&statusId=" + statusId +
        "&orderFrom=" + orderFrom + "&orderTo=" + orderTo +
        "&closedFrom=" + closedFrom + "&closedTo=" + closedTo +
        //quote
        //"&quoteExportedFrom=" + quoteExportedFrom + "&quoteExportedTo=" +
        //quoteExportedTo + "&quoteExportedBool=" + quoteExportedBool +
        //paging, search
        "&pageType=" + pageType + "&sText=" + searchTerms +
        "&numRows=" + pageRows + "&page=" + page;

    //return filtersModel;
    return filtersStr;
}

function GetQuoteSearchFiltersStr(sType, searchTerms, pageType, pageRows, page, accountId) {

    var stores = GetSelectedStores();

    var sp1Id = GetSelectItemValueById("filterSp1Id") ?? "0";
    //var statusId = ""; //GetValueById("filterStatusId");

    var orderFrom = GetValueById("filterOrderDateFrom") ?? "";
    var orderTo = GetValueById("filterOrderDateTo") ?? "";
    var closedFrom = GetValueById("filterClosedFrom") ?? "";
    var closedTo = GetValueById("filterClosedTo") ?? "";

    //var quoteExportedFrom = GetValueById("filterQuoteExportedDateFrom") ?? "";
    //var quoteExportedTo = GetValueById("filterQuoteExportedDateTo") ?? "";
    var quoteExportedBool = GetValueById("filterQuoteExportedCb") ?? "";

    //var filtersModel = {
    //    stores, sp1Id,
    //    orderFrom, orderTo, closedFrom, closedTo,
    //    quoteExportedBool, //quoteExportedFrom, quoteExportedTo, 
    //    sType, searchTerms, pageType, pageRows, page, accountId
    //};

    var filtersStr = "orders/SearchOrders?" +
        "sType=" + sType + "&stores=" + stores + "&accountId=" + accountId +
        // order
        "&sp1Id=" + sp1Id + //"&statusId=" + statusId +
        "&orderFrom=" + orderFrom + "&orderTo=" + orderTo +
        "&closedFrom=" + closedFrom + "&closedTo=" + closedTo +
        // quote
        "&quoteExportedBool=" + quoteExportedBool +
        //"&quoteExportedFrom=" + quoteExportedFrom + "&quoteExportedTo=" + quoteExportedTo + 
        // paging, search
        "&pageType=" + pageType + "&sText=" + searchTerms +
        "&numRows=" + pageRows + "&page=" + page;

    //return filtersModel;
    return filtersStr;
}

function GetClaimSearchFiltersStr(sType, searchTerms, pageType, pageRows, page, accountId) {
    return GetOrderSearchFiltersStr(sType, searchTerms, pageType, pageRows, page, accountId);
}

export function GetAccountSearchEndpoint(searchTerms, pageRows, page) {

    var stores = GetSelectedStores();

    var active = GetCheckedById("filterActive") ?? false;
    var inactive = GetCheckedById("filterInactive") ?? false;

    var filtersStr = "accounts/SearchAccounts?&sText=" + searchTerms + "&active=" + active + "&inactive=" + inactive
        + "&numRows=" + pageRows + "&page=" + page + "&stores=" + stores;

    return filtersStr;
}

export function GetVendorSearchEndpoint(searchTerms, pageRows, page) {

    var typesVals = GetSelectedVendorTypesFilter();

    var active = GetCheckedById("filterActive") ?? false;
    var inactive = GetCheckedById("filterInactive") ?? false;

    var filtersStr = "vendors/SearchVendors?&sText=" + searchTerms + "&sTypes=" + typesVals + "&active=" + active + "&inactive=" + inactive
        + "&numRows=" + pageRows + "&page=" + page;

    return filtersStr;
}

export function GetProductPricingEndpoint(searchTerms, pageRows, page, exportFlag) {

    var active = GetCheckedById("filterActive") ?? false;
    var inactive = GetCheckedById("filterInactive") ?? false;
    
    //var filtersStr = "vendors/SearchVendors?&sText=" + searchTerms + "&sTypes=" + typesVals + "&active=" + active + "&inactive=" + inactive
    //    + "&numRows=" + pageRows + "&page=" + page;

    var filtersStr = 'product/GetPriceAdminProductsPaged' +
        '?numRows=' + pageRows + '&page=' + page + "&active=" + active + "&inactive=" + inactive + '&export=' + exportFlag + '&sText=' + searchTerms;

    return filtersStr;
}

export function ClearSearchFilters(storedSearchTermsKey) {
    // saved search terms
    localStorage.setItem(storedSearchTermsKey, "");

    // Order,Quote,Claim,Account
    SetInputsCheckedByClass("cb-search-stores", false);

    // Order,Quote,Claim
    SetValueById("filterSp1Id", "0");
    SetInputsValueByClass("advFilterDate", "");    
    
    // Account,Vendor
    // default values
    SetInputCheckedById("filterActive", true);
    SetInputCheckedById("filterInactive", false);

    // Vendor
    SetInputCheckedById("cbSearchSupplier", false);
    SetInputCheckedById("cbSearchCommissions", false);
}

export function GetSelectedStores() {
    var strList = "";

    var vals = GetInputValuesCheckedByClass("cb-search-stores");
    if (vals) {
        strList = vals.join(", ");
    }
    return strList;
}

export function GetSelectedVendorTypesFilter() {
    var typesVals = "";
    var vals = GetInputValuesCheckedByClass("vend-search-type");
    if (vals) {
        typesVals += vals.join(", ");
    }
    return typesVals;
}
