
export function vendorModel() {
    var vendorModel = {};
    vendorModel.vendorId = 0;
    vendorModel.name = '';
    vendorModel.address1 = '';
    vendorModel.address2 = '';
    vendorModel.city = '';
    vendorModel.state = '';
    vendorModel.zip = '';
    vendorModel.phone1 = '';
    vendorModel.email1 = '';
    vendorModel.payToName = '';
    vendorModel.payToAddress1 = '';
    vendorModel.payToAddress2 = '';
    vendorModel.payToCity = '';
    vendorModel.payToState = '';
    vendorModel.payToZip = '';
    vendorModel.accountNumber = '';
    vendorModel.taxIdNumber = '';
    vendorModel.remittanceEmail = '';
    vendorModel.notes = '';
    vendorModel.termsDays = 0;
    vendorModel.discountPercent = 0;
    vendorModel.vendorTypeId = 0;
    vendorModel.payStatusId = 0;
    vendorModel.isW9 = false;
    vendorModel.is1099 = false;
    vendorModel.isElectronic1099 = false;
    vendorModel.isACH = false;
    vendorModel.isElectronicRemittance = false;
    vendorModel.isElectronicPrintRemittance = false;
    vendorModel.chartAccountId = 0;
    vendorModel.inactive = false;
    //form only
    vendorModel.loadPage = false;
    return vendorModel;
}

export function createVendorModel(vendorData, loadPage) {
    var model = new vendorModel();

    if (vendorData) {
        model.vendorId = vendorData.vendorId ?? 0;
        model.name = vendorData.name ?? '';
        model.address1 = vendorData.address1 ?? '';
        model.address2 = vendorData.address2 ?? '';
        model.city = vendorData.city ?? '';
        model.state = vendorData.state ?? '';
        model.zip = vendorData.zip ?? '';
        model.phone1 = vendorData.phone1 ?? '';
        model.email1 = vendorData.email1 ?? '';
        model.payToName = vendorData.payToName ?? '';
        model.payToAddress1 = vendorData.payToAddress1 ?? '';
        model.payToAddress2 = vendorData.payToAddress2 ?? '';
        model.payToCity = vendorData.payToCity ?? '';
        model.payToState = vendorData.payToState ?? '';
        model.payToZip = vendorData.payToZip ?? '';

        //accountNumber
        if (!vendorData.accountNumber) {
            model.accountNumber = '';
        }
        else {
            model.accountNumber = vendorData.accountNumber;
        }

        //taxIdNumber
        if (!vendorData.taxIdNumber) {
            model.taxIdNumber = '';
        }
        else {
            model.taxIdNumber = vendorData.taxIdNumber;
        }

        //remittanceEmail
        if (!vendorData.remittanceEmail) {
            model.remittanceEmail = '';
        }
        else {
            model.remittanceEmail = vendorData.remittanceEmail;
        }

        //notes
        if (!vendorData.notes) {
            model.notes = '';
        }
        else {
            model.notes = vendorData.notes;
        }

        //termsDays
        if (!vendorData.termsDays || vendorData.termsDays === '') {
            model.termsDays = 0;
        }
        else {
            model.termsDays = vendorData.termsDays;
        }

        //discountPercent
        if (!vendorData.discountPercent || vendorData.discountPercent === '') {
            model.discountPercent = 0;
        }
        else {
            model.discountPercent = vendorData.discountPercent;
        }

        model.vendorTypeId = vendorData.vendorTypeId ?? 0;
        model.payStatusId = vendorData.payStatusId ?? 0;
        model.isW9 = vendorData.isW9 ?? false;
        model.is1099 = vendorData.is1099 ?? false;
        model.isElectronic1099 = vendorData.isElectronic1099 ?? false;
        model.isACH = vendorData.isACH ?? false;
        model.isElectronicRemittance = vendorData.isElectronicRemittance ?? false;
        model.isElectronicPrintRemittance = vendorData.isElectronicPrintRemittance ?? false;
        model.inactive = vendorData.inactive ?? false;
        model.chartAccountId = vendorData.chartAccountId ?? 0;
    }

    model.loadPage = loadPage ?? false;
    return model;
}

