import React from 'react';

import { FormatDate } from '../../../js/helpers.js';

export const OrderDateCellRenderer = (props) => {

    //debugger;
    const order = props.data ? props.data : null;

    return (
        <div className="order-cell-div">
            <div>
                {/*{order.orderDateStr}*/}
                {FormatDate(order.orderDate)}
            </div>            
        </div>
    );
}
