import React, { useEffect, useState, useRef } from 'react';

import { MultiSelectList } from '../../_reactHelpers/ReactHtmlHelpers.js';
import { GetStoreSelectList } from '../../../js/dataHelpers.js';
import {
    handleFieldChange, SetFocusById, NotEmpty, AddEnterSubmitByClass, IsEmail,
    enforcePhoneFormat, formatToPhone, enforceZipFormat, enforceZipLength, ParseIntSafe, IdExists, IsFalse, IsEmpty, IsTrue, Exists, IsPhone
} from '../../../js/formHelpers.js';
import { } from '../../../js/helpers.js';

import { createJobModel } from '../_js/_orderHelpers.js';
import { Equals } from '../../../js/calculationHelpers.js';
import { post } from '../../../services/apiService.js';

export const OrderJobModal = (props) => {
    const multiListRef = useRef();
    const onCloseModal = props.onCloseModal;
    const propsOrderId = props.orderId ?? 0;
    const propsOrderType = props.orderType ?? "";
    const propsJobData = props.jobData ?? null;
    const searchLink = "/" + propsOrderType + "s";
    // not currently used in Job modal
    //const propsSamplesAccount = props.samplesAccount ?? false;

    const [storeList, setStoreList] = useState([]);
    const [jobData, setJobData] = useState(null);    

    const [modalTitle, setModalTitle] = useState("Job Information");    
    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
        
    const [accountMatEmail, setAccountMatEmail] = useState([]);
    const [materialNotEmails, setMaterialNotEmails] = useState([]);
    
    const [matNotEmailsMsg, setMatNotEmailsMsg] = useState("");
    const [matNotEmailsMsgClass, setMatNotEmailsMsgClass] = useState("");
    const [matNotChangedClass, setMatNotChangedClass] = useState("");

    const saveMsg = "Job info saved successfully.";
    const saveErrMsg = "There was an issue saving the job information. Please try again or contact support.";    
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    // Methods

    function updateMatEmailMessage(msg, cssClass, changedCssClass, errorMsg, logMsg) {
        setMatNotEmailsMsg(msg);
        setMatNotEmailsMsgClass(cssClass);
        if (changedCssClass) {
            setMatNotChangedClass(changedCssClass);
        }
    }

    function saveMatEmails(emailOptions) {
        setMaterialNotEmails(emailOptions);
    }

    async function createStoreList() {
        var list = [];

        var dataList = await GetStoreSelectList(1);
        dataList.forEach((item, index) => {
            list.push(
                <option key={index + 1} value={item.value}>{item.text}</option>
            );
        });
        setStoreList(list);
    }

    function validateData() {
        var valid = true;
        var message = "";

        if (IsEmpty(jobData.jobName)) {
            valid = false;
            message += "Job Name is required. "
        }
        if (NotEmpty(jobData.jobPlacedByEmail) && !IsEmail(jobData.jobPlacedByEmail)) {
            message += "OPB email must be valid.  ";
            valid = false;
        }
        if (NotEmpty(jobData.jobPlacedByPhone) && !IsPhone(jobData.jobPlacedByPhone)) {
            message += "OPB phone must be valid.  ";
            valid = false;
        }
        if (NotEmpty(jobData.jobMaterialNotificationPhone) && !IsPhone(jobData.jobMaterialNotificationPhone)) {
            message += "Material Text must be valid.  ";
            valid = false;
        }
        
        if (IsFalse(valid)) {
            setMessageClass("text-red");
            setMessage(message);
        }
        return valid;
    }

    async function saveAndCloseModal(e) {
        e.preventDefault();
        setMessage("");
        setMessageClass("");
        
        var valid = validateData();
        if (valid === true) {      

            // set jobMaterialNotificationEmails POST field from page field
            // used in both create Order and Job info update
            var matEmailsList = [];
            if (NotEmpty(materialNotEmails)) {
                materialNotEmails.forEach((email, index) => {
                    matEmailsList.push(email.value);
                });
            }
            jobData.jobMaterialNotificationEmails = matEmailsList;
            setJobData(jobData);

            var closeModal = await saveJobData();
            if (IsTrue(closeModal)) {
                onCloseModal(e, jobData);
            }
        }
    }

    async function saveJobData() {
        //todo: closeModal = false and add error handling
        var closeModal = true;

        if (Exists(jobData) && IdExists(jobData.orderId)) {

            var postModel = JSON.stringify(jobData);
            const endpoint = 'orders/SaveOrderJob';

            try {
                const response = await post(endpoint, postModel);
                //console.log('Response: ' + JSON.stringify(response));

                const orderId = ParseIntSafe(response);
                if (IdExists(orderId)) {
                    closeModal = true;
                }
                else {
                    //TODO: log error and display message
                }
            } catch (error) {
                console.log('Error: ' + error);
            }
        }
        else {
            //create new Order - save Job data when order is created
        }
        return closeModal;
    }

    function setupJobModal() {        
        if (propsJobData) {
            setAccountMatEmail(propsJobData.accountJobMaterialNotificationEmail);
            setJobData(propsJobData);
        }
        else {
            //should not occur          
            console.error("OrderJobModal error: setupJobModal() no propsJobData");
        }
    }

    const handleChange = (e) => {
        setMessage("");

        var data = handleFieldChange(e);
        if (data) {
            //updateMessage("", "");

            if (data.name === "jobZip") {
                data.value = enforceZipLength(data.value, 50);
            }
            if (data.name === "jobPlacedByPhone") {
                data.value = formatToPhone(e);
            }
            if (data.name === "jobMaterialNotificationPhone") {
                data.value = formatToPhone(e);
            }

            setJobData((jobData) => ({
                ...jobData,
                [data.name]: data.value
            }));
        }
        else {
            //updateMessage(defaultErrMsg, "text-red", "null handleChange data");
        }
    };

    function addEmailToMultiList(e) {
        if (e && e.target) {
            var email = e.target.value;

            if (NotEmpty(email)) {
                if (IsEmail(email)) {

                    if (multiListRef && multiListRef.handleCreate) {
                        var actEmail = accountMatEmail;
                        if (NotEmpty(actEmail)) {
                            setAccountMatEmail("");
                        }
                        multiListRef.handleCreate(email, actEmail);
                    }
                }
                else {
                    setMessageClass("text-red");
                    setMessage((prev) => [...prev, "OPB email must be valid. "]);
                }
            }
        }
    }

    // ON LOAD
    useEffect(() => {        
        if (propsOrderId === 0) {
            createStoreList();
        }
        setupJobModal();

        setTimeout(() => {
            //if (multiListRef && multiListRef.selectByIndex) {
            //    multiListRef.selectByIndex(0);
            //}
            AddEnterSubmitByClass("enter-list", "btnSaveJobModal");
        }, 500);
    }, []);

    return (
        <div className="w-[550px]">
            <div className="min-h-[200px] p-1">
                {jobData &&
                    <div>
                        <div className="text-center">
                            <div className="font-bold mb-1 text-xl">
                                {modalTitle}
                                <span className="pl-[8px] hidden">
                                    - {jobData.addressId}
                                </span>
                            </div>
                        </div>
                        <div className="table pt-4">
                            <div>
                                <div className="job-col-1">Job Name</div>
                                <div className="job-col-2">
                                    <input type="text" className="enter-list mark inp-text-modal w-[321px]"
                                        id="jobName" name="jobName" autoFocus required
                                        onChange={handleChange}
                                        value={jobData.jobName}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="job-col-1">Address</div>
                                <div className="job-col-2">
                                    <input type="text" className="enter-list mark inp-text-modal w-[321px]"
                                        id="jobAddress1" name="jobAddress1" //required
                                        onChange={handleChange}
                                        value={jobData.jobAddress1}
                                    />
                                </div>
                            </div>
                            {/** 6/4/24 - Hide per CFM **/}
                            <div className="hidden">
                                <div className="job-col-1">Address 2</div>
                                <div className="job-col-2">
                                    <input type="text" className="enter-list mark inp-text-modal w-[321px]"
                                        id="jobAddress2" name="jobAddress2"
                                        onChange={handleChange}
                                        value={jobData.jobAddress2}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="table-cell job-col-1">City</div>
                                <div className="table-cell pr-2 pb-1">
                                    <input type="text" className="enter-list mark inp-text-modal w-[120px]"
                                        id="jobCity" name="jobCity" //required
                                        onChange={handleChange}
                                        value={jobData.jobCity}
                                    />
                                </div>
                                <div className="table-cell pr-2 text-secondary font-bold">State</div>
                                <div className="table-cell pr-2">
                                    <input type="text" className="enter-list mark inp-text-modal w-[45px] pr-0"
                                        id="jobState" name="jobState" //required
                                        onChange={handleChange}
                                        value={jobData.jobState}
                                    />
                                </div>
                                <div className="table-cell pr-2 text-secondary font-bold">Zip</div>
                                <div className="table-cell pr-2">
                                    <input type="text" className="enter-list mark inp-text-modal w-[70px]"
                                        id="jobZip" name="jobZip"
                                        onKeyDown={e => enforceZipFormat(e)}
                                        onChange={handleChange}
                                        value={jobData.jobZip}
                                    />
                                </div>
                                <div>
                                    <div className="job-col-1">Job Detail</div>
                                    <div className="job-col-2">
                                        <input type="text" className="enter-list mark inp-text-modal w-[321px]"
                                            id="jobNotes" name="jobNotes"
                                            onChange={handleChange}
                                            value={jobData.jobNotes}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div>
                                {/*Order Placed By*/}
                                <div>
                                    <div className="job-col-1">{propsOrderType + " Placed By Name"}</div>
                                    <div className="job-col-2">
                                        <input type="text" className="enter-list mark inp-text-modal w-[321px]"
                                            id="jobPlacedByName" name="jobPlacedByName"
                                            onChange={handleChange} //required
                                            value={jobData.jobPlacedByName}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="job-col-1">{propsOrderType + " Placed By Email"}</div>
                                    <div className="job-col-2">
                                        <input type="email" className="enter-list mark inp-text-modal w-[321px]"
                                            id="jobPlacedByEmail" name="jobPlacedByEmail" //required
                                            onBlur={e => addEmailToMultiList(e)}
                                            onChange={handleChange}
                                            value={jobData.jobPlacedByEmail}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="job-col-1">{propsOrderType + " Placed By Phone"}</div>
                                    <div className="job-col-2">
                                        <input type="text" className="enter-list mark inp-text-modal w-[321px]"
                                            id="jobPlacedByPhone" name="jobPlacedByPhone"
                                            onKeyDown={e => enforcePhoneFormat(e)}                                            
                                            onChange={handleChange}
                                            value={jobData.jobPlacedByPhone}
                                        />
                                    </div>
                                </div>
                                {/* Material Notification - autofill from Account and add OPB Email on create Order */}
                                <div>
                                    <div className="job-col-1">Material Notification Email</div>
                                    <div className="job-col-2">
                                        <div className={"job-ml inline-block cursor-text " + matNotChangedClass}>
                                            <MultiSelectList
                                                options={jobData.jobMaterialNotificationEmailItems}
                                                setDataFunc={saveMatEmails}
                                                updateMsgFunc={updateMatEmailMessage}
                                                multiListRef={multiListRef}
                                            >
                                            </MultiSelectList>
                                        </div>
                                        <div className={"inline-block pl-2 " + matNotEmailsMsgClass}>
                                            {matNotEmailsMsg}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="job-col-1">Material Notification Text</div>
                                    <div className="job-col-2">
                                        <input type="text" className="enter-list mark inp-text-modal w-[321px]"
                                            id="jobMaterialNotificationPhone" name="jobMaterialNotificationPhone"
                                            onKeyDown={e => enforcePhoneFormat(e)}
                                            onChange={handleChange}
                                            value={jobData.jobMaterialNotificationPhone}
                                        />
                                    </div>
                                </div>
                                                               
                                <div>
                                    <div className="job-col-1">Lien Rights</div>
                                    <div className="job-col-2">
                                        <input type="text" className="enter-list inp-text-order1 w-[321px]"
                                            id="jobLienRights" name="jobLienRights"                                            
                                            value={jobData.jobLienRights}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                {Equals(jobData.orderId, 0) &&
                                    <div>
                                        <div className="job-col-1">Ship to Store</div>
                                        <div className="job-col-2">
                                            <select id="jobStoreId" name="jobStoreId"
                                                className="sp-sel mark enter-list"
                                                value={jobData.jobStoreId}
                                                onChange={handleChange}
                                            >
                                                {storeList}
                                            </select>
                                        </div>
                                    </div>
                                }
                                
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="align-bottom text-right pt-[30px] pb-[10px]">
                <div className={"inline-block mr-2 " + messageClass}>
                    {message}
                </div>                
                {propsOrderId !== 0 &&
                    <button type="button" className="btn-close-modal"
                        onClick={(e) => onCloseModal(e, null)}>
                        Close
                    </button>
                }
                {propsOrderId === 0 && //propsIsClaim !== true &&
                    <span>
                        <a href={searchLink} id="lnkLoadPage" className="btn-close-modal">
                            <span>Cancel New {propsOrderType}</span>
                        </a>
                    </span>
                }
                <button id="btnSaveJobModal" type="button" className="btn-submit-modal ml-2"
                    onClick={(e) => saveAndCloseModal(e)}>
                    Save
                </button>
            </div>
        </div>
    );
}
