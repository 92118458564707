import React, { useState, useCallback, useEffect, forwardRef, useImperativeHandle } from 'react';

import debounce from 'lodash/debounce';

import { get } from '../../services/apiService';
import { Exists, IdExists, RemoveSpacesStr } from '../../js/formHelpers';

export const EntityAutocomplete = //forwardRef(
    ({ ref, onSelect, initialEntityId = null, onChange, entityType }) => {

    const entityTypeId = RemoveSpacesStr(entityType);
    const isVendor = entityTypeId === "Vendor";
    const isSupplier = entityTypeId === "Supplier";
    const isAccount = entityTypeId === "Account";
    const isOrder = entityTypeId === "Order";
    const isGlAccount = entityTypeId === "GLAccount";
    const isGlCode = entityTypeId === "GlCode";

    const [inputValue, setInputValue] = useState('');
    const [entities, setEntities] = useState([]);

    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchEntities = useCallback(
        debounce(async (query) => {

            if (query.length < 2) {
                setEntities([]);
                return;
            }

            setLoading(true);
            try {
                let response = [];

                if (isVendor) {
                    response = await get(`vendors/search?query=${encodeURIComponent(query)}`);
                }
                else if (isSupplier) {
                    response = await get(`vendors/search?query=${encodeURIComponent(query)}&vendorType=1`);
                }
                else if (isAccount) {
                    response = await get(`accounts/search?query=${encodeURIComponent(query)}`);
                }
                else if (isOrder) {
                    response = await get(`invoice/searchOrdersReadyForInvoice?query=${encodeURIComponent(query)}`);
                }
                else if (isGlAccount) {
                    response = await get(`chartaccount/SearchGlCodes?query=${encodeURIComponent(query)}`);
                }
                else if (isGlCode) {
                    response = await get(`/depositregisters/getGLCodes?query=${encodeURIComponent(query)}`);
                }

                setEntities(response);
                setIsOpen(true);

            } catch (error) {
                console.error('Error fetching entties:', error);
                setEntities([]);
            } finally {
                setLoading(false);
            }
        }, 300),
        []
    );

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        console.log('newValue:', newValue);

        setInputValue(newValue);
        fetchEntities(newValue);
        if (Exists(onChange)) {
            onChange({ target: { name: 'entityId', value: '' } });
        }
    };

    const handleSelectEntity = (entity) => {
        //console.log('New entity selected:', entity);
        let entityName = "";
        let entityId = 0;

        if (isVendor || isSupplier) {
            entityName = entity.name;
            entityId = entity.vendorId;
        }
        else if (isAccount) {
            entityName = entity.name;
            entityId = entity.accountId;
        }
        else if (isOrder) {
            entityName = entity.orderNumber;
            entityId = entity.orderId;
        }
        else if (isGlAccount) {
            entityName = entity.accountNumber + " - " + entity.accountName;
            entityId = entity.chartAccountId;
        }
        else if (isGlCode) {
            entityName = entity.glAccountCode
        }

        setInputValue(entityName);
        setIsOpen(false);
        onSelect(entity);
        if (Exists(onChange)) {
            onChange({ target: { name: 'entityId', value: entityId } });
        }
    };

    const fetchEntityById = useCallback(async (id) => {
        try {
            if (IdExists(id)) {
                let entity = null;
                let entityName = "";
                let entityId = 0;

                if (isVendor || isSupplier) {
                    let resp = await get(`vendors/GetVendor/${id}`);
                    entity = resp;
                    entityName = entity.name;
                    entityId = entity.vendorId;

                }
                else if (isAccount) {
                    let resp = await get(`accounts/GetAccount/${id}`);
                    entity = resp.value.data;
                    entityName = entity.name;
                    entityId = entity.accountId;
                }
                else if (isOrder) {
                    let resp = await get(`orders/GetOrder/${id}/0/0`);
                    entity = resp;
                    entityName = entity.orderNumber;
                    entityId = entity.orderId;
                }
                else if (isGlAccount) {
                    let resp = await get(`chartaccount/GetChartAccountById?id=${id}`);
                    entity = resp;
                    entityName = entity.accountNumber + " - " + entity.accountName;
                    entityId = entity.chartAccountId;
                }
                else if (isGlCode) {
                    let resp = await get(`depositregisters/GetGlCodes?query=${id}`);
                    console.log('Autocomplete: ' + JSON.stringify(resp));
                    entity = resp.value.data;
                    entityName = entity.name;
                    entityId = entity.registerId;
                }

                //console.log('entity by ID:', entity);
                setInputValue(entityName);
                onSelect(entity);
                if (Exists(onChange)) {
                    onChange({ target: { name: 'entityId', value: entityId } });
                }
            }
        } catch (error) {
            console.error('Error fetching entity by id:', error);
        }
    }, []);

    function getListItem(entity) {
        var label = "";
        var key = "";

        if (isVendor || isSupplier) {
            label = entity.name;
            key = entity.vendorId;
        }
        else if (isAccount) {
            label = entity.name;
            key = entity.accountId;
        }
        else if (isOrder) {
            label = entity.orderNumber;
            key = entity.orderId;
        }
        else if (isGlAccount) {
            label = entity.accountNumber + " - " + entity.accountName;
            key = entity.chartAccountId;
        }
        else if (isGlCode) {
            console.log('entity: ' + JSON.stringify(entity));
            label = entity.glAccountCode + " - " + entity.accountName;
            key = entity.id;
        }

        return <li className="px-4 py-1 hover:bg-gray-100 cursor-pointer text-sm"
            key={key}
            onClick={() => handleSelectEntity(entity)}>{label}</li>;
    }

    function inputOnFocus(e) {
        e.target.setAttribute('autocomplete', 'off');
        console.log(e.target.autocomplete);
        setIsOpen(true);
    }

    //useImperativeHandle(ref, () => ({
    //    loadEntity: (entityId) => {
    //        fetchEntityById(entityId);
    //    }
    //}));

    useEffect(() => {
        if (IdExists(initialEntityId)) {
            fetchEntityById(initialEntityId);
        }
    }, []);

    return (
        <div className="relative">
            <input id={"inputEntityAutoComplete-" + entityTypeId} type="text"
                className="select select-bordered focus:outline-none focus:border-blue-500 text-gray-700 w-full"
                value={inputValue}
                onChange={handleInputChange}
                onFocus={(e) => inputOnFocus(e)}
                //autoComplete="off"
                placeholder={"Search " + entityType + "s..."}
            />

            {isOpen && (
                <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
                    {loading &&
                        (
                            <li className="px-4 py-2 text-gray-500 text-sm">Loading...</li>
                        )}
                    {!loading && entities.length === 0 &&
                        (
                            <li className="px-4 py-2 text-gray-500 text-sm">No {entityType} found</li>
                        )}
                    {entities.map((entity) =>
                    (
                        getListItem(entity)
                    ))}
                </ul>
            )}
            <input id="hfEntityAcId" type="hidden" name="entityId" value={0} />
        </div>
    );
}
//);