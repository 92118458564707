import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-daisyui';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { EditJournalEntryForm } from './EditJournalEntryForm';

import { GetDisplayCurrencyStr, GetDisplayDateStr } from '../../js/formHelpers';

export const JournalEntriesList = ({ journalEntries, loading, onJournalEntryUpdated, isParent, onDelete }) => {    

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [currentJournalEntry, setCurrentJournalEntry] = useState(null);
    
    const ActionCellRenderer = (params) => (
        <div className=''>
            <Link to={`/journalentrylines/${params.data.journalEntryBatchId}`}>
                <button className="btn-grid mr-2">View Entries</button>
            </Link>
            <button onClick={() => handleEdit(params.data)} className="btn-grid mr-2">Edit Batch</button>
            <button onClick={() => onDelete(params.data.journalEntryBatchId)} className="btn-grid-red">Delete</button>
        </div>
    );

    const columnDefs = useMemo(() => isParent ?
        [
            {
                field: 'journalEntryBatchId', headerName: 'Batch Id', hide: true
            },
            {
                headerName: '#', maxWidth: 100, valueGetter: 'node.rowIndex + 1'
            },
            {
                field: 'batchName', headerName: 'Name',
            },
            {
                field: 'createdDateTime', headerName: 'Created On', maxWidth: 150,
                valueFormatter: (params) => GetDisplayDateStr(params.value)
            },
            { headerName: '', cellRenderer: ActionCellRenderer, maxWidth: 280 }
        ]
        :
        [
            {
                headerName: '#', maxWidth: 100, valueGetter: 'node.rowIndex + 1'
            },
            {
                field: 'chartAccount.accountName', headerName: 'Chart Account',
            },
            {
                field: 'debit', headerName: 'Debit', maxWidth: 150,
                valueFormatter: (params) => GetDisplayCurrencyStr(params.value)
            },
            {
                field: 'credit', headerName: 'Credit', maxWidth: 150,
                valueFormatter: (params) => GetDisplayCurrencyStr(params.value)
            },            
            { field: 'description', headerName: 'Description', },
            { field: 'comment', headerName: 'Comment', },             
            {
                field: 'createdDateTime', headerName: 'Created On', maxWidth: 150,
                valueFormatter: (params) => GetDisplayDateStr(params.value)
            }
        ], []);

    const defaultColDef = useMemo(() => ({
        minWidth: 100,
        flex: 1,
        sortable: true,
        resizable: true,
        filter: true,
        suppressMovable: true,
        suppressMenu: false,
        cellClass: ["no-border"],
        menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab",],
    }), []);


    const handleEdit = (journalEntry) => {
        //console.log('Editing journal entry:', journalEntry);
        setCurrentJournalEntry(journalEntry);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setCurrentJournalEntry(null);
    };

    if (loading) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }

    return (
        <div>
            <div className="ag-theme-alpine h-[calc(100vh-100px)] w-full">
                <AgGridReact
                    defaultColDef={defaultColDef}
                    columnDefs={columnDefs}
                    rowData={journalEntries}
                    pagination={true}
                    paginationPageSize={30}
                    //domLayout='autoHeight'
                />
            </div>

            {isModalOpen &&
                <div className="modal-wrapper" id="my-modal">
                    <div className="modal-content !max-w-[1470px] !w-[1470px]">
                        <Button size="sm" color="ghost" shape="circle"
                            className="absolute right-4 top-2 min-h-[1.5rem] h-[1.5rem] w-[1.5rem]"
                            onClick={e => closeModal()}>x</Button>

                        {currentJournalEntry && (
                            <EditJournalEntryForm
                                journalEntryData={currentJournalEntry}
                                isParent={isParent}
                                onJournalEntryUpdated={() => {
                                    closeModal();
                                    onJournalEntryUpdated();
                                }}
                            />
                        )}
                    </div>
                </div>
            }
        </div>
    );
}