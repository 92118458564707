import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';

import { initFlowbite } from "flowbite";
import { Table } from 'flowbite-react';
import { Modal, Button } from 'react-daisyui';

import { SetDocumentTitle } from "../_reactHelpers/ReactHelpers";
import { StandardIdAndResponseGET } from '../../js/dataHelpers';
import {
    SetPageTitle, GetValueById, SetValueById, ClickById,
    AddClassById, RemoveClassByClass, GetSelectedRadioValueByName
}
from '../../js/helpers.js';
import {
    formatCurrencyDecStr, CheckEnterKeyPress, PreventDefault,
    IsTrue, ParseIntSafe, Exists, IsFalse, IdExists, NotExists, NotEmpty
}
from '../../js/formHelpers.js';
import {
    Equals, GreaterThan
}
from '../../js/calculationHelpers';

import "../Products/_productCustom.css";
import { ProductAddEdit } from '../Products/ProductAddEdit';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { ModuleRegistry } from '@ag-grid-community/core';
import { MasterDetailModule } from 'ag-grid-enterprise'; //'@ag-grid-enterprise/master-detail';
import { get } from '../../services/apiService';

ModuleRegistry.registerModules([MasterDetailModule]);

export function ProductAdminGrid(props) {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Product Management");

    const propsPageType = "admin";
    const propsOrderStoreId = ParseIntSafe(props.orderStoreId);
    const propsOrderId = ParseIntSafe(props.orderId);

    const IsAdminPageType = true;
    const IsSalesPageType = false;
    const IsOrderPageType = false;
    const productModalClass = getProductModalClass();

    const minSearchChars = 2;
    const pageRows = 50;
    var rowModelType = 'serverSide';

    const productEditModalRef = useRef();
    const [productEditModalContent, setProductEditModalContent] = useState(null);

    const [priceType, setPriceType] = useState("PNW"); // default

    const [productId, setProductId] = useState(0);
    const [detailsTab, setDetailsTab] = useState(null);
    const [productLoading, setProductLoading] = useState(true);
    const [productError, setProductError] = useState(false);

    const productStockModalRef = useRef();
    const [productStockModalContent, setProductStockModalContent] = useState(null);

    const [selectedProduct, setSelectedProduct] = useState([]);
    const [selectedProductOptions, setSelectedProductOptions] = useState([]);
    const [selectedHasBundles, setSelectedHasBundles] = useState([]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const defaultErrMsg = "An error occurred. Please try again or contact support.";


    // Methods

    function getProductModalClass() {

        if (IsAdminPageType) {
            return "product-admin-modal-content";
        }
        else if (IsSalesPageType) {
            return "product-sales-modal-content";
        }
        else if (IsOrderPageType) {
            return "product-sales-modal-content";
        }
    }


    // Product grid
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);

    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const [gridHeight, setGridHeight] = useState("650px");
    const columnDefs = [
        {
            headerName: 'ProductId', field: 'productId', hide: true,
        },
        {
            headerName: 'Style', field: 'style', width: 300, cellClass: ["text-wrap"],
        },
        {
            headerName: "Category", field: 'categoryName', maxWidth: 250,
        },
        {
            headerName: 'Vendor', field: 'vendorName', maxWidth: 300, cellClass: ["text-wrap"],
        },
    ];

    const gridOptions = {
        defaultColDef: {
            flex: 1,
            sortable: false,
            resizable: true,
            filter: true,
            suppressMenu: true,
            suppressMovable: true,
            cellClass: ["no-border"]
        }
    };

    //Color grid
    const gridRefOpts = useRef();
    const [productOptionsData, setProductOptionsData] = useState([]);

    //const containerStyle2 = useMemo(() => ({ width: '100%', height: '100%' }), []);
    //const [gridHeight2, setGridHeight2] = useState("650px");
    const columnDefsOpt = [
        {
            field: 'productPriceId', headerName: "PriceId", hide: true
        },
        {
            field: 'productId', headerName: "ProductId", hide: true
        },
        {
            headerName: "Color", field: 'color', resizable: true, width: 300,
        },
        {
            headerName: "Sku", field: 'sku', resizable: true, maxWidth: 180,
        },
        {
            headerName: "Style #", field: 'styleNum', resizable: true, maxWidth: 120,
        },
        //{
        //    headerName: "Stock", field: 'productPriceId', maxWidth: 70,
        //    cellRenderer: params => {
        //        var hasStock = params.data.productStockModels && params.data.productStockModels.length > 0;
        //        return <div> {hasStock &&
        //            <span onClick={e => onOpenStockModal(e, params.data)}
        //                className="underline">View</span>}
        //        </div>
        //    }
        //},
        {
            headerName: "PNW", field: 'isPnw', maxWidth: 70, hide: true //true IsOrderPageType
        },
        {
            headerName: "AZ", field: 'isAz', maxWidth: 70, hide: true //true IsOrderPageType
        },
        {
            headerName: "Web", field: 'isWeb', maxWidth: 70, hide: true //true IsOrderPageType
        }
    ];

    const gridOptions2 = {
        defaultColDef: {
            flex: 1,
            sortable: false,
            resizable: true,
            filter: false,
            suppressMenu: true,
            suppressMovable: true,
            cellClass: ["no-border"]
        }
    };

    function updateMessage(msg, cssClass, errorMsg, logMsg) {
        if (errorMsg) {
            console.error(errorMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setMessage(msg);
        setMessageClass(cssClass);
    }

    // Add/Edit Modal

    function returnProductAddEdit(productId, productPriceId, productOptionsData, productSkuId) {
        return <ProductAddEdit pageType={propsPageType}
            isAdminPageType={IsAdminPageType} isSalesPageType={IsSalesPageType} isOrderPageType={IsOrderPageType}
            productId={productId} productPriceId={productPriceId} orderId={propsOrderId}
            productSkuId={productSkuId} productOptionsData={productOptionsData}
            onCloseModal={onCloseProductEditModal}>
        </ProductAddEdit>;
    }

    function onOpenProductEditModal(productId, productPriceId, productOptionsData, productSkuId) {
        setProductEditModalContent(returnProductAddEdit(
            productId, productPriceId, productOptionsData, productSkuId));
        productEditModalShow();
    }

    function onCloseProductEditModal(msg) {
        //PreventDefault(e);
        updateMessage(msg, "text-green");
        setProductEditModalContent(null);
        productEditModalHide();
    }

    const productEditModalShow = useCallback(() => {
        productEditModalRef.current?.showModal();
    }, [productEditModalRef]);

    const productEditModalHide = useCallback(() => {
        productEditModalRef.current?.close();
    }, [productEditModalRef]);


    // Stock Modal

    function onOpenStockModal(e, data) {
        PreventDefault(e);
        setProductStockModalContent(getStockModalContent(data));
        productStockModalShow();
    }

    function onCloseStockModal(e, selectStockProduct) {
        PreventDefault(e);
        setProductStockModalContent(null);
        productStockModalHide();

        if (IsTrue(selectStockProduct)) {
            ClickById("btnSelectProduct");
        }
    }

    const productStockModalShow = useCallback(() => {
        productStockModalRef.current?.showModal();
    }, [productStockModalRef]);

    const productStockModalHide = useCallback(() => {
        productStockModalRef.current?.close();
    }, [productStockModalRef]);

    function getStockModalContent(data) {
        return <div>
            <div className="font-bold text-secondary text-center mb-2">
                <div>
                    {selectedProduct && selectedProduct.style}
                </div>
                <div>
                    {data.color}
                </div>
            </div>
            <div className="clear rounded border-gray-200 border">
                <Table className="tbl-order-lines">
                    <Table.Head>
                        <Table.HeadCell>Store</Table.HeadCell>
                        <Table.HeadCell>Qty Available</Table.HeadCell>
                        {/*<Table.HeadCell>Price</Table.HeadCell>*/}
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {getStockLines(data)}
                    </Table.Body>
                </Table>
            </div>
        </div>;
    }

    function getStockLines(data) {
        var lineList = [];
        var defSelected = false;
        if (data.productStockModels && data.productStockModels.length > 0) {
            data.productStockModels.forEach((sm, index) => {
                //only show Stock with Available Qty
                if (GreaterThan(sm.quantityAvailable, 0)) {

                    var rowCssClass = "";
                    if (IsOrderPageType && defSelected === false) {
                        //select stock for current order store
                        if (propsOrderStoreId === sm.storeId) {
                            defSelected = true;
                            rowCssClass = "ag-row-selected-bg cursor-pointer";
                            setHfProductIds(sm.productId, sm.productPriceId, sm.productStockId, sm.productSkuId);
                        }
                    }

                    var i = index + 1;
                    var rowId = "prodStockRowId-" + i;
                    lineList.push(
                        <Table.Row key={i} id={rowId} className={"prod-stock-row " + rowCssClass}
                            onDoubleClick={e =>
                                updateSelectedProductStock(e, rowId, sm.productId, sm.productPriceId, sm.productStockId, sm.productSkuId)}>
                            <Table.Cell>
                                {sm.storeDisplayName}
                            </Table.Cell>
                            <Table.Cell>
                                {sm.quantityAvailable}
                            </Table.Cell>
                            {/*<Table.Cell>*/}
                            {/*    {formatCurrencyDecStr(data.priceBc)}*/}
                            {/*</Table.Cell>*/}
                        </Table.Row>
                    );
                }
                else {
                    var dev = true;
                }
            });
        }
        return lineList;
    }

    function updateSelectedProductStock(e, rowId, productId, productPriceId, productStockId, productSkuId) {
        //set to use when adding line in order
        if (IsOrderPageType) {
            //higlight row
            RemoveClassByClass("prod-stock-row", "ag-row-selected-bg");
            AddClassById(rowId, "ag-row-selected-bg");

            setHfProductIds(productId, productPriceId, productStockId, productSkuId);
            onCloseStockModal(e, true);
        }
    }

    const onGridReady = useCallback((params) => {
        params.api.setServerSideDatasource(serverSideDatasource());
    }, []);

    const serverSideDatasource = () => {
        return {
            // called by the grid when more rows are required
            getRows: params => {

                var searchStr = GetValueById("tbSearch");
                if (searchStr.length >= minSearchChars) {

                    var pageNum = params.request.endRow / pageRows;
                    var page = pageNum - 1;                    
                    var priceTypeStr = GetSelectedRadioValueByName("priceType");

                    //var productTypeStr = "";
                    //if (IsOrderPageType) {
                    //    GetSelectedRadioValueByName("productTypes");
                    //}

                    var apiUrl = process.env.REACT_APP_API_URL + 'product/GetAdminProductsPaged' +
                        '?numRows=' + pageRows + '&page=' + page + '&priceType=' + priceTypeStr + '&sText=' + searchStr;
                    //+ '&sProductType=' + productTypeStr;

                    //console.log("server side apiUrl: " + apiUrl);

                    fetch(apiUrl)
                        .then((response) => response.json())
                        .then((rowData) => {                            
                            if (Exists(rowData) && NotEmpty(rowData.dataRows)) {
                                
                                params.success({
                                    rowData: rowData.dataRows
                                });
                                selectFirstProductRow(page);
                                hideOverlay();
                                //console.log("total Product rows: " + dataRows.length);
                            }
                            else {
                                params.success({ rowData: [] });
                                console.log("total Product rows: no data");
                            }
                        })
                        .catch((error) => {
                            params.success({ rowData: [] });
                            showNoRows();
                            setError(error);
                            console.error('loadProductsData Error:', error);
                        });
                }
                else {
                    params.success({ rowData: [] });
                    showNoRows();
                    console.log("total Product rows: load none");
                }
            }
        };
    }

    const refreshCache = useCallback((e) => {
        var searchTerms = GetValueById("tbSearch");
        if (searchTerms && searchTerms.length >= minSearchChars) {

            setSelectedProduct([]);
            setSelectedProductOptions([]);
            setProductOptionsData([]);
            gridRef.current.api.refreshServerSide({ purge: true });
        }
    }, []);

    const cellClicked = useCallback((params) => {
        SetSelectedProduct(params.node, params.data);
    }, []);

    function selectFirstProductRow(page) {
        //autoselect first result after search
        if (page === 0) {
            var nodes = gridRef.current.api.getRenderedNodes();
            SetSelectedProduct(nodes[0], nodes[0] && nodes[0].data);
        }
    }

    function SetSelectedProduct(node, data) {
        if (node && data) {
            node.setSelected(true);
            setSelectedProduct(data);
            //options            
            loadProductOptions(data.productId);
        } else {
            setSelectedProduct([]);
            setSelectedProductOptions([]);
        }
    }

    function onProductRowDoubleClicked(data) {
        if (data) {
            var productId = data.productId;
            var productPriceId = 0;
            var productSkuId = 0;
            var productStockId = 0;
            //var productPriceId = data.productPriceId;
            //var productStockId = getOrderStoreProductStockId(data);

            if (IsFalse(IsOrderPageType)) {
                //loadProductData(productId);
                onOpenProductEditModal(productId, productPriceId, productOptionsData, productSkuId);
            }
        }
    }

    //function handleSearch(e) {
    //    const value = e.target.value.toLowerCase();
    //    gridRef.current.api.setQuickFilter(value);
    //};

    function showLoading() {
        gridRef.current.api.showLoadingOverlay();
    }

    function showNoRows() {
        gridRef.current.api.showNoRowsOverlay();
    }

    function hideOverlay() {
        gridRef.current.api.hideOverlay();
    }

    //Product Options
    async function loadProductOptions(productId) {
        var success = false;
        setProductOptionsData([]);
        setSelectedProductOptions([]);

        if (productId && productId > 0) {

            var priceTypeStr = GetSelectedRadioValueByName("priceType");
            //priceType
            const endpoint = 'product/GetProductAdminOptions/' + productId + '/0/0/' + priceTypeStr;
            try {
                const response = await get(endpoint);
                if (Exists(response)) {
                    setProductOptionsData(response.productPrices);
                    success = true;

                    //wait for data load                    
                    setTimeout(() => {
                        selectFirstColorRow();
                    }, 500);
                }
            } catch (error) {
                console.log('Error: ' + error);
            }
        }
        if (IsFalse(success)) {
            setError(true);
        }
        else {
            setError(false);
        }
    };

    function handleSearchOpts(e) {
        var api = gridRefOpts.current.api;
        const value = e.target.value.toLowerCase();
        api.setQuickFilter(value);
    };

    const cellClickedProd = useCallback((params) => {
        SetSelectedProductOptions(params.node, params.data);
    }, []);

    function selectFirstColorRow() {
        //autoselect first result after search
        var nodesOpts = gridRefOpts.current.api.getRenderedNodes();
        SetSelectedProductOptions(nodesOpts[0], nodesOpts[0] && nodesOpts[0].data);
    }

    function SetSelectedProductOptions(node, data) {
        var productId = 0;
        var productPriceId = 0;
        var productSkuId = 0;

        if (node && data) {
            productId = data.productId;
            productPriceId = data.productPriceId;
            productSkuId = data.productSkuId;
            node.setSelected(true);
            setSelectedProductOptions(data);
        } else {
            setSelectedProductOptions([]);
        }

        //set to use when adding line in order
        if (IsOrderPageType) {
            var productStockId = getOrderStoreProductStockId(data);
            setHfProductIds(productId, productPriceId, productStockId, productSkuId);
        }
    }

    function onOptsRowDoubleClicked(data) {
        if (data) {
            var productId = data.productId;
            var productPriceId = data.productPriceId;
            var productSkuId = data.productSkuId;
            var productStockId = getOrderStoreProductStockId(data);

            if (IsOrderPageType) {
                setHfProductIds(productId, productPriceId, productStockId, productSkuId);

                var submit = true;
                if (data.hasBundles) {

                    if (window.confirm("Add Products from Bundles?")) {
                        submit = false;
                        onOpenProductEditModal(productId, productPriceId, productOptionsData, productSkuId);
                    }
                }

                if (IsTrue(submit)) {
                    ClickById("btnSelectProduct");
                }
            }
            else {
                //loadProductData(productId);
                onOpenProductEditModal(productId, productPriceId, productOptionsData, productSkuId);
            }
        }
    }

    function addProduct() {
        var submit = true;
        var productId = GetValueById("hfSelectProductId");
        var productPriceId = GetValueById("hfSelectProductPriceId");
        var productSkuId = GetValueById("hfSelectProductSkuId");

        //var productStockId = GetValueById("hfSelectProductStockId");
        //SetValueById("hfSelectBundleProductPriceIds", "");

        var nodes = gridRefOpts.current.api.getRenderedNodes();
        if (NotEmpty(nodes)) {

            var data = nodes[0].data;
            if (Exists(data) && IsTrue(data.hasBundles)) {

                if (window.confirm("Add Products from Bundles?")) {
                    submit = false;
                    onOpenProductEditModal(productId, productPriceId, productOptionsData, productSkuId);
                }
            }
        }

        if (IdExists(productId) && IsTrue(submit)) {
            ClickById("btnSelectProduct");
        }
    }

    function setHfProductIds(productId, productPriceId, productStockId, productSkuId) {
        SetValueById("hfSelectProductId", productId);
        SetValueById("hfSelectProductPriceId", productPriceId);
        SetValueById("hfSelectProductSkuId", productSkuId);
        SetValueById("hfSelectProductStockId", productStockId);
        //SetValueById("hfSelectBundleProductPriceIds", "");
    }

    function getOrderStoreProductStockId(prodPriceData) {
        var productStockId = 0;
        if (propsOrderStoreId !== 0 && prodPriceData && prodPriceData.productStockModels) {
            var psArr = prodPriceData.productStockModels.filter((element) => {
                return element.storeId === propsOrderStoreId;
            });

            if (psArr && psArr.length === 1) {
                var ps = psArr[0];
                if (Exists(ps)) {
                    productStockId = ParseIntSafe(ps.productStockId);
                }
            }
        }
        return productStockId;
    }

    function onChangePriceType(e) {
        if (Exists(e.target) && Exists(e.target.value)) {
            setPriceType(e.target.value);

            refreshCache();
        }
        else {
            alert(defaultErrMsg);
        }
    }


    /** Page Load **/
    useEffect(() => {
        initFlowbite();
        SetPageTitle("Product Management");
        setLoading(false);

    }, []);

    if (loading === true) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }
    if (error === true)
        return <div className="text-red-500 text-center mt-[25%] text-xl">{defaultErrMsg}</div>;

    return (
        <div className="w-full">
            <div>
                <div>
                    <div className="float-left">
                        <div className="table-cell">
                            <label for="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input autoFocus type="text" id="tbSearch" placeholder="Search..."
                                    onKeyDown={(e) => CheckEnterKeyPress(e, refreshCache)}
                                    className="orders-filter-search block p-2 pl-10 text-sm text-gray-900 
                                             border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500;"></input>
                                <div id="selectedRows" />
                            </div>
                            <div className="mt-1 mb-1 text-sm">
                                'Enter' to search with 2 or more characters
                            </div>
                        </div>
                        <div className="mb-2 pointer-events-hand pl-3 table-cell">
                            <button id="btnProductSearch" onClick={(e) => refreshCache(e)}
                                type="button" className="btn-search !m-0">
                                Search Products
                            </button>
                        </div>
                        <div className="pl-8 table-cell">
                            <div className="table-cell">
                                <span className="pl-8 pr-1 font-bold text-secondary">PNW</span>
                                <input type="radio" value="PNW" name="priceType" className="mt-[-3px] cursor-pointer"
                                    checked={priceType === "PNW"}
                                    onChange={(e) => onChangePriceType(e)} />

                                <span className="pl-4 pr-1 font-bold text-secondary">AZ</span>
                                <input type="radio" value="AZ" name="priceType" className="mt-[-5px] cursor-pointer"
                                    checked={priceType === "AZ"}
                                    onChange={(e) => onChangePriceType(e)} />

                                <span className="pl-4 pr-1 font-bold text-secondary">Web</span>
                                <input type="radio" value="WEB" name="priceType" className="mt-[-5px] cursor-pointer"
                                    checked={priceType === "WEB"}
                                    onChange={(e) => onChangePriceType(e)} />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className="clear">
                <div className="inline-block w-[55%] pr-2">
                    <div style={containerStyle}>
                        <div className="ag-theme-alpine" style={{ height: gridHeight, width: '100%', }}>

                            <AgGridReact id="gridProducts"
                                rowData={rowData}
                                ref={gridRef}
                                gridOptions={gridOptions}
                                columnDefs={columnDefs}
                                onGridReady={onGridReady}
                                onCellClicked={cellClicked}
                                rowModelType={rowModelType}
                                cacheBlockSize={pageRows}
                                onRowDoubleClicked={(e) => onProductRowDoubleClicked(e.data)}
                                //includeHiddenColumnsInQuickFilter={true}
                            />
                        </div>
                    </div>
                </div>

                <div className="inline-block w-[45%]">
                    <div style={containerStyle} className="">
                        <div className="ag-theme-alpine" style={{ height: gridHeight, width: '100%', }}>
                            {/*Quick Filter Search*/}
                            {/*Per CFM: dont show search for colors grid*/}
                            {false &&
                                <div>
                                    <div className="table-cell">
                                        <label for="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                                        <div className="relative">
                                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                                </svg>
                                            </div>

                                            <input onChange={handleSearchOpts} type="search" id="search" placeholder="Search..."
                                                className="orders-filter-search @apply block p-2 mb-2 pl-10 text-sm text-gray-900 
                                                    border border-gray-300 rounded-lg !focus:ring-blue-500 !focus:border-blue-500;"></input>
                                            <div id="selectedRows" />
                                        </div>
                                        <div className="mt-1 mb-1">
                                            &nbsp;
                                        </div>
                                    </div>
                                </div>
                            }

                            <AgGridReact id="gridOptions"
                                rowData={productOptionsData}
                                ref={gridRefOpts}
                                gridOptions={gridOptions2}
                                columnDefs={columnDefsOpt}
                                onCellClicked={cellClickedProd}
                                onRowDoubleClicked={(e) => onOptsRowDoubleClicked(e.data)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="pt-2 pb-4 align-top prod-grid-tbls float-left">
                <div className="pr-2 table-cell w-[400px]">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <span className="font-bold text-secondary">Manufacturer:</span>
                                </td>
                                <td>
                                    {
                                        selectedProduct &&
                                        selectedProduct.manufacturerName
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="table-cell">
                                        <span className="font-bold text-secondary">MSRP:</span>
                                    </div>
                                    {/*<div className="table-cell">*/}
                                    {/*    <span className="font-bold">Price:</span>*/}
                                    {/*</div>*/}
                                    {/*<div className="table-cell">*/}
                                    {/*    {selectedProductOptions &&*/}
                                    {/*        formatCurrencyDecStr(selectedProductOptions.priceBc)}*/}
                                    {/*</div>*/}
                                </td>
                                <td>
                                    <div className="table-cell">
                                        {selectedProductOptions &&
                                            formatCurrencyDecStr(selectedProductOptions.msrp)}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="font-bold text-secondary">Box Qty:</span>
                                </td>
                                <td>
                                    {
                                        selectedProductOptions &&
                                        selectedProductOptions.serialNo
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="pr-2 table-cell w-[200px]">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <span className="font-bold text-secondary">BC:</span>
                                </td>
                                <td>
                                    <span>{selectedProductOptions &&
                                        formatCurrencyDecStr(selectedProductOptions.priceBc)}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="font-bold text-secondary">BC1:</span>
                                </td>
                                <td>
                                    <span>{selectedProductOptions &&
                                        formatCurrencyDecStr(selectedProductOptions.bc1)}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="font-bold text-secondary">BC2:</span>
                                </td>
                                <td>
                                    <span>{selectedProductOptions &&
                                        formatCurrencyDecStr(selectedProductOptions.bc2)}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="pr-2 table-cell w-[200px]">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <span className="font-bold text-secondary">BC3:</span>
                                </td>
                                <td>
                                    <span>{selectedProductOptions &&
                                        formatCurrencyDecStr(selectedProductOptions.bc3)}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="font-bold text-secondary">BC5:</span>
                                </td>
                                <td>
                                    <span>{selectedProductOptions &&
                                        formatCurrencyDecStr(selectedProductOptions.bc5)}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="font-bold text-secondary">Cost:</span>
                                </td>
                                <td>
                                    <span>{selectedProductOptions &&
                                        formatCurrencyDecStr(selectedProductOptions.cost)}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/*<div className="pr-2 table-cell w-[300px]">*/}
                {/*    <table>*/}
                {/*        <tbody>*/}
                {/*            <tr>*/}
                {/*                <td>*/}
                {/*                    <span className="font-bold">Field 1:</span>*/}
                {/*                </td>*/}
                {/*                <td>*/}
                {/*                </td>*/}
                {/*            </tr>*/}
                {/*            <tr>*/}
                {/*                <td>*/}
                {/*                    <span className="font-bold">Field 2:</span>*/}
                {/*                </td>*/}
                {/*                <td>*/}
                {/*                </td>*/}
                {/*            </tr>*/}
                {/*            <tr>*/}
                {/*                <td>*/}
                {/*                    <span className="font-bold">Field 3:</span>*/}
                {/*                </td>*/}
                {/*                <td>*/}
                {/*                </td>*/}
                {/*            </tr>*/}
                {/*        </tbody>*/}
                {/*    </table>*/}
                {/*</div>*/}
            </div>
            
            {IsOrderPageType &&
                <div className="btnsAddProductsSalesGrid">
                    <button type="button" id="btnSalesAddProductsBtn" className="btn-submit-modal mr-2"
                        onClick={(e) => addProduct(e)}>
                        Select Product
                    </button>
                </div>
            }

            <Modal ref={productStockModalRef} className="product-modal min-w-[600px] py-3 px-4">
                <Modal.Body>
                    <div className="min-h-[200px]">
                        {productStockModalContent}
                    </div>
                    <div className="align-bottom text-right pt-[10px]">
                        {/*<div className={"inline-block mr-3 " + messageClass}>*/}
                        {/*    {message}*/}
                        {/*</div>*/}
                        <div className="inline-block pb-4">
                            {IsOrderPageType &&
                                <button type="button" id="btnSelectStockProduct" className="btn-submit-modal mr-2"
                                    onClick={(e) => onCloseStockModal(e, true)}>
                                    Select Stock
                                </button>
                            }
                            <button type="button" className="btn-close-modal"
                                onClick={(e) => onCloseStockModal(e)}>
                                Close
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal ref={productEditModalRef} className={productModalClass}>
                <Button size="sm" color="ghost" shape="circle"
                    onClick={e => onCloseProductEditModal("")}
                    className="absolute right-4 top-2 min-h-[1.5rem] h-[1.5rem] w-[1.5rem]"
                >x</Button>
                <Modal.Body>
                    {productEditModalContent}
                </Modal.Body>
            </Modal>
        </div>
    );
}