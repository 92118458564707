import React, { useState } from 'react';
import { Component } from 'react';
import { EdiImportsGrid } from './EdiImportsGrid';

export class EdiImportsSearch extends Component {
    static displayName = EdiImportsSearch.name;

    //constructor() {
    //  super();
    //  this.state = { data: [], loading: true };
    //}

    render() {
        return (
            <div>
                <EdiImportsGrid></EdiImportsGrid>
            </div>
        );
    }
}
