import { FormatDateLongInput, FormatDateLong } from '../../js/helpers.js';

export function receiveModel() {
    var model = {};

    model.orderLineId = 0;    
    model.quantityReceived = 0;
    //model.dateReceived = "";
    model.bin = "";
    model.createTag = false;
    model.isStock = false;

    //non-post fields
    model.poNumberStr = "";
    model.vendorName = "";
    model.style = "";
    model.color = "";
    model.warehouseName = "";    
    model.quantityOrdered = 0;    
    model.statusId = 0;
    model.quantityRemaining = 0;
    model.receivedMessages = [];

    return model;
}

export function createReceiveDataModel(data) {
    var model = new receiveModel();
    if (data) {
        model.orderLineId = data.orderLineId;
        model.createTag = true;
        model.isStock = data.isStock;

        //for partially received use qty "balance" and set bin        
        model.quantityReceived = data.poQuantityLeftToReceive;
        model.quantityRemaining = data.poQuantityLeftToReceive;
        model.bin = data.bin;

        //if qty already has been received but status moved back
        //use previously received qty
        if (data.quantityReceived !== 0) {
            model.quantityReceived = data.quantityReceived;
        }       

        //not shown in form
        //always receive on todays date, non-editable
        //var dateNow = Date.now();
        ////dateNow = FormatDateLongInput(dateNow);
        //dateNow = FormatDateLong(dateNow);
        //model.dateReceived = dateNow;

        //non-post fields
        model.quantityOrdered = data.poQuantity;
        model.statusId = data.statusId;
        model.poNumberStr = data.poNumberStr;
        model.vendorName = data.vendorName;
        model.style = data.style;
        model.color = data.color;
        model.warehouseName = data.warehouseName;        
        model.receivedMessages = data.receivedMessages;
    }
    return model;
}

