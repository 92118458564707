import React, { Component } from 'react';
import { initFlowbite } from "flowbite";

import { OrdersGrid } from './OrdersGrid';

export class QuoteSearch extends Component {
    static displayName = QuoteSearch.name;

    //constructor(props) {
    //    super(props);
    //    //this.state = { stores: [] };
    //}
        
    //componentDidMount() {    
    //    initFlowbite();
    //}

    render() {
        return (
            <div>
                <OrdersGrid pageType="Quote"></OrdersGrid>
            </div>
        );
    }
}
