import React, { useState } from 'react';

import authService from '../../services/authService';
import { Exists, NotEmpty, PreventDefault } from '../../js/formHelpers';
import { ClickById, SetHrefById, StringContains} from '../../js/helpers';

export function UserLogin({ onLogin }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    // Get current user
    const handleLogin = async (e) => {
        PreventDefault(e);
        setError("");

        try {
            var user = await authService.login(username, password);
            if (Exists(user)) {
                                
                if (NotEmpty(user.home)) {                
                    SetHrefById("lnkUserHome", user.home);
                    ClickById("lnkUserHome");
                }
                else {

                    var hlUrl = authService.GetDefaultUserLink(user);
                    // link in LuminaMenu.js
                    SetHrefById("homeImageLink", hlUrl);
                    ClickById("homeImageLink");
                }
            }
            else {
                setError('Invalid credentials');
            }
        } catch (err) {
            setError('Invalid credentials');
        }
    };

    return (
        <div class="flex flex-col items-center justify-center px-4 py-8 mx-auto text-white">
            <div class="p-6 w-[500px] max-w-[500px] bg-white rounded-lg shadow">
                <div class="">
                    <h1 class="text-xl text-secondary font-bold leading-tight tracking-tight text-center pb-4">
                        Lumina Login
                    </h1>
                    <form class="space-y-4 md:space-y-6" action="#" onSubmit={e => handleLogin(e)}>
                        <div class="leading-tight tracking-tight text-gray-900 text-center">
                            <div className="pb-1">We could not log you in to Lumina.</div>
                            <div className="pb-1">Please make sure you have logged in with your Windows User.</div>
                            <div className="pb-1">If you continue to experience issues please contact support.</div>
                        </div>
                        <div>
                            <label for="username" class="block mb-2 text-sm font-medium text-gray-900">
                                Username
                            </label>
                            <input type="username" name="username" id="username"
                                class="bg-gray-50 border border-gray-300 text-gray-900  rounded-lg 
                                        focus:ring-primary-600 focus:border-primary-600
                                        block w-full p-2.5"
                                required
                                value={username}
                                onChange={(e) => setUsername(e.target.value)} />
                        </div>
                        <div>
                            <label for="password" class="block mb-2 text-sm font-medium text-gray-900">
                                Password
                            </label>
                            <input type="password" name="password" id="password"
                                class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg 
                                        focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <button type="submit"
                            class="w-full bg-primary hover:bg-primary bg-blue-600 hover:text-white focus:ring-4 
                                    focus:outline-none focus:text-white-400 focus:ring-primary-300 font-medium rounded-lg
                                    text-sm px-5 py-2.5 text-center">
                            Sign in
                        </button>
                    </form>
                </div>
            </div>
            <div className="text-red p-4">{error}</div>
            <a href="/" id="lnkUserHome" className="hidden">User Home</a>
        </div>        
    );
};