import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';

import { SetDocumentTitle } from "../_reactHelpers/ReactHelpers";
import { SetPageTitle, GetValueById, GetPricingGridDateCellContent } from '../../js/helpers.js';
import {
    IsTrue, Exists, IsFalse, NotEmpty, LengthGreaterEqual, NotExists, NotStringEmpty,
}
from '../../js/formHelpers.js';

import "../Products/_productCustom.css";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { ModuleRegistry } from '@ag-grid-community/core';
import { MasterDetailModule } from 'ag-grid-enterprise'; //'@ag-grid-enterprise/master-detail';
import { GetProductPricingEndpoint } from '../../js/searchHelpers';

ModuleRegistry.registerModules([MasterDetailModule]);

export function ProductPricingExportGrid(props, ref) {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Product Import/Export");

    const priceType = props.priceType ?? "";
    //const [priceType, setPriceType] = useState(props.priceType);

    const IsPricingPageType = true;
    const IsAdminPageType = false;
    const IsSalesPageType = false;
    const IsOrderPageType = false;

    const minSearchChars = 2;
    const apiPageRows = 10000;
    const rowModelType = 'clientSide'; //'serverSide';

    //const [selectedProduct, setSelectedProduct] = useState([]);
    //const [selectedProductOptions, setSelectedProductOptions] = useState([]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    // METHODS

    function getProductModalClass() {

        if (IsPricingPageType) {
            return "product-admin-modal-content";
        }
        else if (IsSalesPageType || IsAdminPageType) {
            return "product-sales-modal-content";
        }
        else if (IsOrderPageType) {
            return "product-sales-modal-content";
        }
    }

    /****** Product grid ******/
    /**************************/
    const [rowData, setRowData] = useState([]);
    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const [gridHeight, setGridHeight] = useState("700px");

    var filterParams = {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            var dateAsString = cellValue;
            if (dateAsString == null) return -1;
            var dateParts = dateAsString.split("/");
            var cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0]),
            );
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        },
    };

    const columnDefs = [
        {
            headerName: 'ProductId', field: 'productId', width: 120, hide: false,
            cellRenderer: params => {
                var showData = params.data.productId !== 0;
                return <div>
                    {IsTrue(showData) && <div>{params.value}</div>}
                    {IsFalse(showData) && ''}
                </div>
            }
        },
        {
            headerName: 'Vendor', field: 'vendorName', width: 220,
            cellRenderer: params => {
                var showData = params.data.productId !== 0;
                return <div>
                    {IsTrue(showData) && <div>{params.value}</div>}
                    {IsFalse(showData) && ''}
                </div>
            }
        },
        {
            headerName: 'Manufacturer', field: 'manufacturerName', width: 180, 
            cellRenderer: params => {
                var showData = params.data.productId !== 0;
                return <div>
                    {IsTrue(showData) && <div>{params.value}</div>}
                    {IsFalse(showData) && ''}
                </div>
            }
        },
        {
            headerName: "PC", field: 'productCode', width: 100, cellDataType: 'number',
            cellRenderer: params => {
                var showData = params.data.productId !== 0;
                return <div>
                    {IsTrue(showData) && <div>{params.value}</div>}
                    {IsFalse(showData) && ''}
                </div>
            }
        },
        {
            headerName: 'Style', field: 'style', width: 400, 
            cellRenderer: params => {
                var showData = params.data.productId !== 0;
                return <div>
                    {IsTrue(showData) && <div>{params.value}</div>}
                    {IsFalse(showData) && ''}
                </div>
            }
        },
        //{
        //    headerName: 'Product Inactive', field: 'productInactive', cellDataType: 'text',
        //    hide: false,
        //    cellRenderer: params => {
        //        var showData = params.data.productId !== 0;
        //        return <div>
        //            {IsTrue(showData) && <div>{params.value.toString()}</div>}
        //            {IsFalse(showData) && ''}
        //        </div>
        //    }
        //},   
        {
            headerName: 'StyleNumId', field: 'productStyleInfoId', width: 140,
            cellRenderer: params => {
                var showData = params.data.productId !== 0;
                return <div>
                    {IsTrue(showData) && <div>{params.value}</div>}
                    {IsFalse(showData) && ''}
                </div>
            }
        },
        {
            headerName: 'Style Num', field: 'styleNum', maxWidth: 180, //autoHeight: true, cellClass: ["text-wrap"],   
            cellRenderer: params => {
                var showData = NotStringEmpty(params.value);
                return <div>
                    {IsTrue(showData) && params.value.toString()}
                    {IsFalse(showData) && ''}
                </div>
            }
        },
        {
            headerName: "Units", field: 'units', width: 100,
            cellRenderer: params => {
                var showData = params.data.productId !== 0;
                return <div>
                    {IsTrue(showData) && <div>{params.value}</div>}
                    {IsFalse(showData) && ''}
                </div>
            }
        },
        {
            headerName: "Box Qty", field: 'sellQuantity', width: 120,
            valueFormatter: params => params.data.sellQuantity,
            cellRenderer: params => {
                var showData = params.data.productId !== 0;
                return <div>
                    {IsTrue(showData) && <div>{params.value}</div>}
                    {IsFalse(showData) && ''}
                </div>
            }
        },
        {
            headerName: "Inactive Date", field: 'productStyleInfoInactiveDate', maxWidth: 140,
            valueFormatter: (params) => { return GetPricingGridDateCellContent(params) },
        },
        //{
        //    headerName: 'Inactive', field: 'productStyleInfoInactive', width: 120, 
        //    cellRenderer: params => {
        //        var showData = params.data.productId !== 0;
        //        return <div>
        //            {IsTrue(showData) && <div className="capitalize">{params.value.toString()}</div>}
        //            {IsFalse(showData) && ''}
        //        </div>
        //    }
        //},
        {
            headerName: "ColorSkuId", field: 'productSkuId', width: 120, hide: false,
            cellRenderer: params => {
                var showData = params.data.productSkuId !== 0;
                return <div>
                    {IsTrue(showData) && <div>{params.value}</div>}
                    {IsFalse(showData) && ''}
                </div>
            }
        },
        {
            headerName: "Color", field: 'color', resizable: true, width: 300,
            cellRenderer: params => {
                var showData = params.data.productSkuId !== 0;
                return <div>
                    {IsTrue(showData) && <div>{params.value}</div>}
                    {IsFalse(showData) && ''}
                </div>
            }
        },
        {
            headerName: "Color Num", field: 'colorNum', resizable: true, width: 150, cellDataType: 'text',
            cellRenderer: params => {
                var showData = params.data.productSkuId !== 0;
                return <div>
                    {IsTrue(showData) && <div>{params.value}</div>}
                    {IsFalse(showData) && ''}
                </div>
            }
        },
        {
            headerName: "Color Inactive  Date", field: 'productSkuInactiveDate', maxWidth: 170,
            valueFormatter: (params) => { return GetPricingGridDateCellContent(params) },
        },
        //{
        //    headerName: 'Color Inactive', field: 'productSkuInactive', width: 180,
        //    cellRenderer: params => {
        //        var showData = params.data.productSkuId !== 0;
        //        return <div>
        //            {IsTrue(showData) && <div className="capitalize">{params.value.toString()}</div>}
        //            {IsFalse(showData) && ''}
        //        </div>
        //    }
        //},

        /**** PNW Cost ****/
        /******************/
        {
            headerName: "PNW Cost", field: 'pnwCost', cellDataType: 'number', width: 120,
            hide: priceType !== "PNW" && priceType !== "ALL",
            //editable: params => params.data.pnwProductPriceId !== 0,
            cellRenderer: params => {
                var editable = params.data.pnwProductPriceId !== 0;
                return <div>
                    {IsTrue(editable) && <div>
                        <span>$</span>{params.value.toFixed(2)}</div>}
                    {/*{IsFalse(editable) && <div>-</div>}*/}
                </div>
            }
        },
        //{
        //    headerName: "PNW Fut. Cost", field: 'pnwFutureCost', cellDataType: 'number', width: 150,
        //    hide: priceType !== "PNW" && priceType !== "ALL",
        //    //editable: params => params.data.pnwProductPriceId !== 0,
        //    cellRenderer: params => {
        //        var editable = params.data.pnwProductPriceId !== 0;
        //        return <div>
        //            {IsTrue(editable) && <div>
        //                <span>$</span>{params.value.toFixed(2)}</div>}
        //            {/*{IsFalse(editable) && <div>-</div>}*/}
        //        </div>
        //    }
        //},
        {
            headerName: "PNW Eff. Date", field: 'pnwFutureStartDate', width: 150,
            hide: priceType !== "PNW" && priceType !== "ALL",
            valueFormatter: (params) => { return GetPricingGridDateCellContent(params) },
        },
        {
            headerName: "PNW Promo Cost", field: 'pnwPromoCost', cellDataType: 'number', width: 160,
            hide: priceType !== "PNW" || priceType === "ALL",
            cellRenderer: params => {
                var editable = params.data.pnwProductPriceId !== 0;
                return <div>
                    {IsTrue(editable) && <div>
                        <span>$</span>{params.value.toFixed(2)}</div>}
                    {/*{IsFalse(editable) && <div>-</div>}*/}
                </div>
            }
        },
        {
            headerName: "PNW Promo Start", field: 'pnwPromoStartDate', width: 170,
            hide: priceType !== "PNW" || priceType === "ALL",
            valueFormatter: (params) => { return GetPricingGridDateCellContent(params) },
        },
        {
            headerName: "PNW Promo End", field: 'pnwPromoEndDate', width: 160,
            hide: priceType !== "PNW" || priceType === "ALL",
            valueFormatter: (params) => { return GetPricingGridDateCellContent(params) },
        },

        /**** AZ Cost ****/
        /******************/
        {
            headerName: "AZ Cost", field: 'azCost', cellDataType: 'number', width: 100,
            hide: priceType !== "AZ" && priceType !== "ALL",
            cellRenderer: params => {
                var editable = params.data.azProductPriceId !== 0;
                return <div>
                    {IsTrue(editable) && <div>
                        <span>$</span>{params.value.toFixed(2)}
                    </div>}
                    {/*{IsFalse(editable) && <div>-</div>}*/}
                </div>
            }
        },
        //{
        //    headerName: "AZ Fut. Cost", field: 'azFutureCost', cellDataType: 'number', width: 150,
        //    hide: priceType !== "AZ" && priceType !== "ALL",
        //    //editable: params => params.data.azProductPriceId !== 0,
        //    cellRenderer: params => {
        //        var editable = params.data.azProductPriceId !== 0;
        //        return <div>
        //            {IsTrue(editable) && <div>
        //                <span>$</span>{params.value.toFixed(2)}</div>}
        //            {/*{IsFalse(editable) && <div>-</div>}*/}
        //        </div>
        //    }
        //},
        {
            headerName: "AZ Eff. Date", field: 'azFutureStartDate', width: 150,
            hide: priceType !== "AZ" && priceType !== "ALL",
            valueFormatter: (params) => { return GetPricingGridDateCellContent(params) },
        },
        {
            headerName: "AZ Promo Cost", field: 'azPromoCost', cellDataType: 'number', width: 150,            
            hide: priceType !== "AZ" || priceType === "ALL",
            cellRenderer: params => {
                var editable = params.data.azProductPriceId !== 0;
                return <div>
                    {IsTrue(editable) && <div>
                        <span>$</span>{params.value.toFixed(2)}</div>}
                    {/*{IsFalse(editable) && <div>-</div>}*/}
                </div>
            }
        },
        {
            headerName: "AZ Promo Start", field: 'azPromoStartDate', width: 150,
            hide: priceType !== "AZ" || priceType === "ALL",
            valueFormatter: (params) => { return GetPricingGridDateCellContent(params) },
        },
        {
            headerName: "AZ Promo End", field: 'azPromoEndDate', width: 150,
            hide: priceType !== "AZ" || priceType === "ALL",
            valueFormatter: (params) => { return GetPricingGridDateCellContent(params) },
        },

        /**** WEB Cost ****/
        /******************/
        {
            headerName: "Web Cost", field: 'webCost', cellDataType: 'number', width: 120,
            hide: priceType !== "WEB" && priceType !== "ALL",         
            cellRenderer: params => {
                var editable = params.data.webProductPriceId !== 0;
                return <div>
                    {IsTrue(editable) && <div>
                        <span>$</span>{params.value.toFixed(2)}</div>}
                    {/*{IsFalse(editable) && <div>-</div>}*/}
                </div>
            }
        },
        //{
        //    headerName: "Web Fut. Cost", field: 'webFutureCost', cellDataType: 'number', width: 150,
        //    hide: priceType !== "WEB" && priceType !== "ALL",
        //    cellRenderer: params => {
        //        var editable = params.data.webProductPriceId !== 0;
        //        return <div>
        //            {IsTrue(editable) && <div>
        //                <span>$</span>{params.value.toFixed(2)}</div>}
        //            {/*{IsFalse(editable) && <div>-</div>}*/}
        //        </div>
        //    }
        //},
        {
            headerName: "Web Eff. Date", field: 'webFutureStartDate', width: 150,
            hide: priceType !== "WEB" && priceType !== "ALL",
            valueFormatter: (params) => { return GetPricingGridDateCellContent(params) },
        },
        {
            headerName: "Web Promo Cost", field: 'webPromoCost', cellDataType: 'number', width: 160,
            hide: priceType !== "WEB" || priceType === "ALL",
            cellRenderer: params => {
                var editable = params.data.azProductPriceId !== 0;
                return <div>
                    {IsTrue(editable) && <div>
                        <span>$</span>{params.value.toFixed(2)}</div>}
                    {/*{IsFalse(editable) && <div>-</div>}*/}
                </div>
            }
        },
        {
            headerName: "Web Promo Start", field: 'webPromoStartDate', width: 160,
            hide: priceType !== "WEB" || priceType === "ALL",
            valueFormatter: (params) => { return GetPricingGridDateCellContent(params) },
        },
        {
            headerName: "Web Promo End", field: 'webPromoEndDate', width: 160,
            hide: priceType !== "WEB" || priceType === "ALL",
            valueFormatter: (params) => { return GetPricingGridDateCellContent(params) },
        },

        /********************/
        {
            headerName: "Units", field: 'units', width: 100,
            cellRenderer: params => {
                var showData = params.data.productId !== 0;
                return <div>
                    {IsTrue(showData) && <div>{params.value}</div>}
                    {IsFalse(showData) && ''}
                </div>
            }
        },
        {
            headerName: "Box Qty", field: 'priceTypeFilter', width: 120, 
            valueFormatter: params => params.data.sellQuantity,
            cellRenderer: params => {
                var showData = params.data.productId !== 0;
                return <div>                    
                    {IsTrue(showData) && <div>{params.data.sellQuantity}</div>}
                    {IsFalse(showData) && ''}
                    <span className="hidden">{params.data.priceTypeFilter}</span>
                </div>
            }
        },
        //{
        //    headerName: "Sku", field: 'sku', resizable: true, maxWidth: 200, cellDataType: 'text',
        //    //editable: true
        //    cellRenderer: params => {
        //        var showData = params.data.productSkuId !== 0;
        //        return <div>
        //            {IsTrue(showData) && <div>{params.value}</div>}
        //            {IsFalse(showData) && <div></div>}
        //        </div>
        //    }
        //},        
    ];

    const gridOptions = {
        defaultColDef: {
            //flex: 1,            
            editable: false,
            sortable: true,
            resizable: true,
            filter: true,
            suppressMovable: true,
            suppressMenu: false,
            cellClass: ["no-border"],
            menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab",],
        },
        sideBar: {
            toolPanels: [
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                }
            ],
            hiddenByDefault: false,
        },
    };

    const clearProdFilters = useCallback(() => {
        gridRef.current.api.setFilterModel(null);
    }, []);

    const onGridReady = useCallback((params) => {        
        loadPriceData();

        //params.api.setServerSideDatasource(serverSideDatasource());
        //setSearchTerms(propsSearchTerms);
    }, []);

    function loadPriceData() {
        var searchStr = GetValueById("tbSearch");
        if (LengthGreaterEqual(searchStr, minSearchChars)) {
            showLoading();            
            clearProdFilters();

            // TODO: return message if limit of 10k reached by search?
            // return all found records                 
            var apiPageRows = 10000;
            var page = 0;

            //var apiPageRows = 50;
            //var pageNum = params.request.endRow / pageRows;
            //var page = pageNum - 1;

            var endpoint = GetProductPricingEndpoint(searchStr, apiPageRows, page, true)
            var apiUrl = process.env.REACT_APP_API_URL + endpoint;
            console.log("server side apiUrl: " + apiUrl);

            fetch(apiUrl)
                .then((response) => response.json())
                .then((rowData) => {
                    if (Exists(rowData) && NotEmpty(rowData.dataRows)) {

                        setRowData(rowData.dataRows);
                        //console.log("total Product rows: " + dataRows.length);
                    }
                    else {
                        setRowData([]);
                        console.log("total Product rows: no data or end of data");
                    }
                })
                .catch((error) => {
                    setRowData([]);
                    //showNoRows();
                    setError(error);
                    console.error('loadProductsData Error:', error);
                });
        }
        else {
            setRowData([]);
            //showNoRows();
            console.log("total Product rows: load none");
        }
    }

    //const serverSideDatasource = () => {
    //    return {
    //        // called by the grid when more rows are required
    //        getRows: params => {

    //            var searchStr = GetValueById("tbSearch");
    //            if (LengthGreaterEqual(searchStr, minSearchChars)) {
    //                // TODO: return message if limit of 10k reached by search?
    //                // return all found records for csv export                    
    //                var page = 0;

    //                var apiUrl = process.env.REACT_APP_API_URL + 'product/GetPriceAdminProductsPaged' +
    //                    '?numRows=' + apiPageRows + '&page=' + page + '&export=' + true + '&sText=' + searchStr;
    //                //console.log("server side apiUrl: " + apiUrl);

    //                fetch(apiUrl)
    //                    .then((response) => response.json())
    //                    .then((rowData) => {
    //                        if (rowData && rowData.dataRows && rowData.dataRows.length > 0) {
    //                            //debugger;

    //                            var dataRows = rowData.dataRows;
    //                            params.success({
    //                                rowData: dataRows
    //                            });
    //                            //selectFirstProductRow(page);

    //                            hideOverlay();
    //                            //console.log("total Product rows: " + dataRows.length);
    //                        }
    //                        else {
    //                            //params.successCallback([], 0);
    //                            params.success({ rowData: [] });
    //                            //showNoRows();
    //                            console.log("total Product rows: no data or end of data");
    //                        }
    //                    })
    //                    .catch((error) => {
    //                        params.success({ rowData: [] });
    //                        showNoRows();
    //                        //log error
    //                        setError(error);
    //                        console.error('loadProductsData Error:', error);
    //                    });
    //            }
    //            else {
    //                params.success({ rowData: [] });
    //                showNoRows();
    //                console.log("total Product rows: load none");
    //            }
    //        }
    //    }
    //}

    //const refreshCache = useCallback((e) => {
    //    var searchStr = GetValueById("tbSearch");
    //    if (LengthGreaterEqual(searchStr, minSearchChars)) {
    //        //setSelectedProduct([]);
    //        //setSelectedProductOptions([]);
    //        //setProductOptionsData([]);
    //        gridRef.current.api.refreshServerSide({ purge: true });
    //    }
    //}, []);

    function showLoading() {
        gridRef.current.api.showLoadingOverlay();
    }
    function showNoRows() {
        gridRef.current.api.showNoRowsOverlay();
    }
    function hideOverlay() {
        gridRef.current.api.hideOverlay();
    }

    function updateExportFilter(filterValue) {
        gridRef.current.api.setQuickFilter(filterValue);
    }

    /** Page Load **/
    useEffect(() => {
        SetPageTitle("Product Import / Export");
        setLoading(false);
    }, []);

    if (loading === true) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }
    if (error === true)
        return <div className="text-red-500 text-center mt-[25%] text-xl">{defaultErrMsg()}</div>;

    return (
        <div className="w-100">
            <div className="clear">
                <div className="">
                    <button id="btnExportProductsSearchGrid" onClick={(e) => loadPriceData()} type="button" className="hidden">
                        Export Search
                    </button>
                    {/* update filter from parent component */}
                    <button id="btnUpdateExportFilterAll" onClick={(e) => updateExportFilter("")} type="button" className="hidden">
                        Filter All
                    </button>
                    <button id="btnUpdateExportFilterPnw" onClick={(e) => updateExportFilter("filterPnwPrice")} type="button" className="hidden">
                        Filter PNW
                    </button>
                    <button id="btnUpdateExportFilterAz" onClick={(e) => updateExportFilter("filterAzPrice")} type="button" className="hidden">
                        Filter AZ
                    </button>
                    <button id="btnUpdateExportFilterWeb" onClick={(e) => updateExportFilter("filterWebPrice")} type="button" className="hidden">
                        Filter WEB
                    </button>

                    <div style={containerStyle}>
                        <div className="ag-theme-alpine" style={{ height: gridHeight, width: '100%', }}>
                            <AgGridReact id="gridProducts"
                                rowData={rowData}
                                ref={gridRef}
                                gridOptions={gridOptions}
                                columnDefs={columnDefs}
                                onGridReady={onGridReady}
                                rowModelType={rowModelType}
                                cacheBlockSize={apiPageRows}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}