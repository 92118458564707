import React, { useState, useEffect } from 'react';
import { get, post } from '../../services/apiService';
import { Exists, handleFieldChange, ParseIntSafe, PreventDefault } from '../../js/formHelpers';
import { IsSuccessStandardResponseAndDataPOST, StandardDataAndResponsePOST } from '../../js/dataHelpers';

export function ProductCodeForm({ onProductCodeAdded, onCancel }) {
    const [formData, setFormData] = useState({
        productCategoryId: 0,
        productCode: '',
        name: '',
        pcWeight: '',
        isRoll: false,
        isItem: false,
        isSample: false
    });

    const [message, setMessage] = useState('');
    const [messageClass, setMessageClass] = useState('');
    const [nextProductCode, setNextProductCode] = useState('');

    const handleChange = (e) => {
        const data = handleFieldChange(e);
        if (data) {
            setFormData(prevState => ({
                ...prevState,
                [data.name]: data.value
            }));
        }
    };

    //const getNextProductCode = async () => {
    //    try {
    //        const data = await get('productCode/GetNextProductCode');
    //        setNextProductCode(data);
    //        formData.productCode = data;
    //    }
    //    catch (error) {
    //        console.error('Error getting next code:', error);
    //    }
    //};

    useEffect(() => {
       //getNextProductCode();
    }, []);

    const handleSubmit = async (e) => {
        PreventDefault(e);

        try {
            //console.log('New Product Code:', JSON.stringify(formData));
            var postModel = JSON.stringify(formData);
            var endpoint = "productCode/AddProductCode";
            var typeStr = "AddProductCodePOST";

            var resp = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);
            if (IsSuccessStandardResponseAndDataPOST(resp)) {
                updateMessage('Product Category Added Successfully', 'text-green-600');
                clearForm();
                onProductCodeAdded();
            }
            else {
                console.log('Error adding Product Code:', resp);
                updateMessage('Error adding Product Category', 'text-red-600');
            }
        } catch (error) {
            console.log('Error adding Product Code:', error);
            updateMessage('Error adding Product Category', 'text-red-600');
        }
    };

    //const handleWeightChange = (value) => {
    //    setFormData(prevData => ({
    //        ...prevData,
    //        pcWeight: value
    //    }));
    //};

    const clearForm = () => {
        setFormData({
            productCode: '',
            name: '',
            pcWeight: 0.00,
            isRoll: false,
            isItem: false,
            isSamples: false
        });
    }

    const updateMessage = (msg, cssClass) => {
        setMessage(msg);
        setMessageClass(cssClass);
        setTimeout(() => {
            setMessage('');
            setMessageClass('');
        }, 3000);
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className="act-form">
                <div className="details-form-field">
                    <label htmlFor="productCode" className="lbl-text1">Code</label>
                    <input
                        type="text"
                        id="productCode"
                        name="productCode"
                        className="inp-text"
                        value={formData.productCode}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="details-form-field">
                    <label htmlFor="name" className="lbl-text1">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        className="inp-text"
                        placeholder="Category Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="details-form-field">
                    <label htmlFor="pcWeight" className="lbl-text1">PC Weight</label>
                    <input
                        type="number"
                        id="pcWeight"
                        name="pcWeight"
                        placeholder="0.00"
                        className="inp-text"
                        value={formData.pcWeight}
                        onChange={handleChange}
                    />
                </div>
                <div className="details-form-field">
                    <label className="lbl-text1">Product Type</label>
                    <div className="flex space-x-4">
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                id="isItem"
                                name="isItem"
                                className="inp-cb mr-2"
                                checked={formData.isItem}
                                onChange={handleChange}
                            />
                            <span className="lbl-cb">Is Item</span>
                        </label>

                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                id="isRoll"
                                name="isRoll"
                                className="inp-cb mr-2"
                                checked={formData.isRoll}
                                onChange={handleChange}
                            />
                            <span className="lbl-cb">Is Roll</span>
                        </label>
                       
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                id="isSamples"
                                name="isSamples"
                                className="inp-cb mr-2"
                                checked={formData.isSamples}
                                onChange={handleChange}
                            />
                            <span className="lbl-cb">Is Samples</span>
                        </label>
                    </div>
                </div>
                <div className="w-full text-right mt-4">
                    <div className={`inline-block mr-1 ${messageClass}`}>
                        {message}
                    </div>
                    <button type="button" className="btn-cancel mr-2" onClick={onCancel}>
                        Cancel
                    </button>
                    <button type="submit" className="btn-submit">
                         Save and Exit
                    </button>
                </div>
            </form>
        </div>
    );
}

