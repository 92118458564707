import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';


import { SetDocTitle } from "../_reactHelpers/ReactHelpers";
import {
    formatCurrencyDecStr, CheckEnterKeyPress, NotEmpty, Exists, NotStringEmpty, handleFieldChange, PreventDefault, IsTrue
} from '../../js/formHelpers.js';
import {
    SetPageTitle, GetValueById, ClickById, SetValueById, StringContains, GetPreviousUrl
} from '../../js/helpers.js';

import { get } from '../../services/apiService.js';
import { ClearSearchFilters, GetAccountSearchEndpoint } from '../../js/searchHelpers.js';
import { SearchFilters } from '../SearchFilters/SearchFilters';

import { AccountNameRenderer } from './cellRenderers/_accountNameCellRenderer.js';
import { AccountAddressRenderer } from './cellRenderers/_accountAddressCellRenderer.js';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

export function AccountsGrid(props) {
    const navigate = useNavigate();
    const loc = useLocation();
    const propsPageType = props.pageType;

    const isAccountPageType = !Exists(propsPageType);
    const isOrderPageType = propsPageType === "order";
    const hfOrderAccount = "hfSelectedAccountId";
    const btnOrderSelectAccount = "btnOrderSelectAccount";

    //const showNotes = pageType === "order";
    const showNotes = true;

    const loadSearchTermsFromPage = "EditAccount";
    const storedSearchTermsKey = "storedAccountSearchTerms";
    const tbSearchId = "tbAccountsSearch";

    const minSearchChars = 2;
    const pageRows = 50;
    var rowModelType = 'serverSide';

    const [searchStr, setSearchStr] = useState("");
    const [clearFilterMsg, setClearFilterMsg] = useState("");
    const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);

    const [message, setMessage] = useState("");
    const [messageCss, setMessageCss] = useState("");

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    const gridRef = useRef();
    const [rowData, setRowData] = useState();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);

    const columnDefs = [
        { headerName: "Id", field: "accountId", hide: true },
        /** 5/4/24 - Hide per CFM **/
        //{
        //    headerName: "", field: "accountId", maxWidth: 70, hide: !showNotes,
        //    cellRenderer: params => {
        //        var accountName = params.data.name;
        //        return <div>
        //            <button onClick={e => voidClick(e, accountName)}
        //                className="link-primary text-underline">Notes</button>
        //        </div>;
        //    }
        //},
        {
            headerName: "Account Name", field: "name", cellClass: ["text-wrap"],
            minWidth: 300,
            cellRenderer: AccountNameRenderer
        },
        {
            headerName: "Act #", field: "accountNumber", maxWidth: 80,
        },
        {
            headerName: 'Owner(s)', field: 'ownerName', cellClass: ["text-wrap"],
            autoHeight: true, //width: 400,
            cellRenderer: params => {
                return RenderOwnerColumn(params.data);
            },
        },
        {
            headerName: 'Address', field: 'address', cellClass: ["text-wrap"],
            autoHeight: true, //width: 400, 
            cellRenderer: AccountAddressRenderer,
        },
        {
            headerName: "Email", field: "address", cellClass: ["text-wrap"],
            cellRenderer: params => {
                var email1 = params.data.email1 ? params.data.email1 : "";
                return <div>{email1}</div>;
            }
        },
        {
            headerName: "Terms", field: "termsStr", maxWidth: 100, //cellClass: ["text-left"],
            //filter: "agTextColumnFilter", 
            cellRenderer: params => {
                return <div>{params.value !== "None" ? params.value : ""}</div>;
            }
        },
        {
            headerName: "Balance", field: "balance", maxWidth: 110,
            //filter: "agNumberColumnFilter",
            //valueFormatter: params => formatCurrencyDecStr(params.value)
            cellRenderer: params => {
                return <div>{params.value !== 0 && formatCurrencyDecStr(params.value)} </div>;
            }
        },
        {
            headerName: "Hold", field: "creditHold", maxWidth: 70, cellClass: ["text-center"], cellDataType: 'boolean',
            //cellRenderer: params => {
            //    return <input type="checkbox" disabled defaultChecked={params.value}></input>;
            //}
        },
        {
            headerName: "Inactive", field: "inactive", maxWidth: 90, cellClass: ["text-center"], cellDataType: 'boolean',
            //cellRenderer: params => {
            //    return <input type="checkbox" disabled defaultChecked={params.value}></input>;
            //}
        }
    ];

    const gridOptions = {
        defaultColDef: {
            flex: 1,
            sortable: false,
            resizable: true,
            filter: false,
            suppressMenu: true,
            suppressMovable: true,
            cellClass: ["no-border"]
        }
    };

    function RenderOwnerColumn(account) {
        if (account != null) {
            var owner1 = account.ownerName ? account.ownerName : null;
            var owner2 = account.ownerName2 ? account.ownerName2 : null;
            var phone1 = account.phone1 ? account.phone1 + " " : null;
            return (
                <div className="ag-cell-div">
                    <div className="ag-cell-title">
                        {
                            owner1 && <span>{owner1}</span>
                        }
                        {
                            owner1 && owner2 && <span>, </span>
                        }
                        {
                            owner2 && <span>{owner2}</span>
                        }
                    </div>
                    <div className="ag-cell-content !pt-[2px]">
                        <div>
                            {
                                phone1 && <span>{phone1}</span>
                            }
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return;
        }
    }

    function voidClick(e, accountName) {
        if (e) {
            e.preventDefault();
        }
        alert("Account Notes - " + accountName);
    }

    function updateMessage(msg, msgCss) {
        setMessage(msg ?? "");
        setMessageCss(msgCss ?? "");
    }

    function saveSearchValues() {
        //save search terms in session
        var searchTerms = GetValueById(tbSearchId);
        localStorage.setItem(storedSearchTermsKey, JSON.stringify(searchTerms));
    }

    function loadSavedSearchTerms() {
        //load stored search terms if coming back from Account Details
        var allowed = true;
        try {
            //var fromURl = GetPreviousUrl();
            //var allowed = StringContains(fromURl, loadSearchTermsFromPage);
            //if (allowed !== true) {
            //    allowed = loc.state && loc.state.useSavedFiltersAccount;
            //}

            // TODO: only load from Account Details page
            if (allowed) {

                // search terms
                const savedSearchTerm = localStorage.getItem(storedSearchTermsKey);
                if (NotStringEmpty(savedSearchTerm)) {

                    const strSearchTerm = JSON.parse(savedSearchTerm);
                    if (NotStringEmpty(strSearchTerm)) {
                        setSearchStr(strSearchTerm);
                    }
                }
            }
            //else {
            //    localStorage.setItem(storedSearchTermsKey, "");
            //}
        }
        catch (error) {
            console.error("localStorage error: " + storedSearchTermsKey);
            //localStorage.setItem(storedSearchTermsKey, "");
        }
        //localStorage.setItem(storedSearchTermsKey, "");
        //clearLocationState();
    }

    function clearLocationState() {
        //this.props.dispatch(replace({
        //    ...this.props.location,
        //    state: undefined
        //}));

        //const { location, history } = props;
        ////use the state via location.state
        ////and replace the state via
        //history.replace();
    }

    const onGridReady = useCallback((params) => {
        params.api.setServerSideDatasource(serverSideDatasource());
    }, []);

    const serverSideDatasource = () => {
        return {
            // called by the grid when more rows are required
            getRows: async params => {
                var searchTerms = GetValueById(tbSearchId);
                var validSearch = NotEmpty(searchTerms) && searchTerms.length >= minSearchChars;
                if (validSearch) {
                    saveSearchValues();

                    var pageNum = params.request.endRow / pageRows;
                    var page = pageNum - 1;

                    // todo: remove
                    const endpointOLD = 'accounts/SearchAccounts?numRows=' + pageRows + '&page=' + page + '&sText=' + searchTerms;

                    var endpoint = GetAccountSearchEndpoint(searchTerms, pageRows, page);
                    console.log("server side apiUrl: " + endpoint);

                    try {
                        const response = await get(endpoint);
                        if (Exists(response) && NotEmpty(response.dataRows)) {
                            params.success({ rowData: response.dataRows });
                            hideOverlay();
                            //console.log(response.message);
                        }
                        else {
                            params.success({ rowData: [] });
                            console.log("loadAccountsData: no data");
                        }
                    } catch (error) {
                        params.success({ rowData: [] });
                        showNoRows();
                        setError(error);
                        console.error('loadAccountsData error:', error);
                    };
                }
                else {
                    params.success({ rowData: [] });
                    showNoRows();
                    console.log("loadAccountsData: load none");
                }
            }
        }
    }

    const refreshCache = useCallback((e) => {
        setMessage("");
        setMessageCss("");
        setClearFilterMsg("");
        gridRef.current.api.refreshServerSide({ purge: true });
    }, []);

    function showLoading() {
        gridRef.current.api.showLoadingOverlay();
    }

    function showNoRows() {
        gridRef.current.api.showNoRowsOverlay();
    }

    function hideOverlay() {
        gridRef.current.api.hideOverlay();
    }

    const handleOnAdd = (event) => {
        navigate('/CreateAccount', { replace: true });
    }

    function getAccountMessage() {
        updateMessage();

        if (isAccountPageType) {
            if (loc.state) {
                var msg = loc.state.accountMessage;
                if (NotEmpty(msg)) {
                    if (StringContains(msg, "created") || StringContains(msg, "updated")) {
                        updateMessage(msg, "text-green");
                    }
                    else {
                        updateMessage(msg, "text-secondary");
                    }
                }
                else {
                    var status = loc.state.accountStatus;
                    if (status === "create") {
                        updateMessage("Account created successfully.", "text-green");
                    }
                    else if (status === "update") {
                        updateMessage("Account updated successfully.", "text-green");
                    }
                }
                return message;
            }
        }
    }

    function onRowDoubleClicked(data) {
        if (data) {
            var accountId = data.accountId;
            if (isOrderPageType) {
                SetValueById(hfOrderAccount, accountId);
                ClickById(btnOrderSelectAccount);
            }
            else {
                navigate('/EditAccount', { replace: true, state: { accountId } });
            }
        }
    }

    const cellClicked = useCallback((params) => {
        params.node.setSelected(true);

        //set to use when adding line in order
        if (isOrderPageType) {
            var accountId = params.data.accountId;
            SetValueById(hfOrderAccount, accountId);
        }
    }, []);

    function getGridHeight() {
        if (isOrderPageType) {
            return "700px";
        }
        else {
            return "750px";
        }
    }

    function UpdateSearchStr(e) {
        var str = "";
        if (e) {
            var data = handleFieldChange(e);
            if (data) {
                str = data.value;
            }
        }
        setSearchStr(str);
    }

    //---------------- Filters  -----------------//

    function clearFilters(e) {
        PreventDefault(e);
        ClearSearchFilters(storedSearchTermsKey);
        setClearFilterMsg("Filters cleared");

        // dont clear search str on filter clear
        //setSearchStr("");
    }

    const handleOpenFiltersModal = () => setIsFiltersModalOpen(true);
    function handleCloseFiltersModal(e, loadData) {
        PreventDefault(e);
        setIsFiltersModalOpen(false);

        if (IsTrue(loadData)) {
            refreshCache();
        }
    }

    // ON LOAD
    useEffect(() => {
        setLoading(false);

        if (isAccountPageType) {
            SetDocTitle("Lumina - Accounts");
            SetPageTitle("Accounts");
            getAccountMessage();

            setTimeout(() => {
                //dont overwrite saved search terms for embedded search
                loadSavedSearchTerms();
            }, 300);
        }
    }, []);

    if (IsTrue(loading)) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }
    if (error === true)
        return <div className="text-red-500 text-center mt-[25%] text-xl">{defaultErrMsg}</div>;

    return (
        <div style={containerStyle} className="p-2 pt-2 account-grid-container">
            <div className="ag-theme-alpine" style={{ height: getGridHeight(), width: '100%', }}>

                <div className="float-left">
                    <div className="table-cell">
                        <div>
                            <div className="table-cell">
                                {isAccountPageType &&
                                    <button onClick={handleOnAdd} type="button"
                                        className="btn-load-orders mr-2 pointer-events-hand">
                                        Create Account
                                    </button>
                                }
                            </div>
                            <div className="table-cell">
                                <label for="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                        </svg>
                                    </div>
                                    <div>
                                        <input autoFocus type="search" id={tbSearchId} placeholder="Search..."
                                            className="orders-filter-search @apply block p-2 pl-10 text-sm text-gray-900 
                                            border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500;"
                                            onKeyDown={(e) => CheckEnterKeyPress(e, refreshCache)}
                                            onChange={UpdateSearchStr}
                                            value={searchStr}                                    >
                                        </input>
                                    </div>
                                    <div id="selectedRows" />
                                </div>
                            </div>
                            <div className="mt-1 mb-1">
                                'Enter' to search with 2 or more characters
                            </div>
                        </div>
                    </div>
                    <div className="mb-2 pointer-events-hand pl-3 table-cell align-middle">
                        <div className="pl-3 table-cell align-middle">
                            <button id="btnAccountsSearch" onClick={(e) => refreshCache(e)}
                                type="button" className="btn-search !m-0">
                                Search Accounts
                            </button>
                        </div>
                        <div className="table-cell pl-3">
                            <button className="btn-close-modal"
                                onClick={(e) => clearFilters(e)}>
                                Clear Filters
                            </button>
                        </div>
                        {/*<!-- Advanced Filters -->*/}
                        <div onClick={handleOpenFiltersModal} className="div-binocs">
                            <svg fill="#000000" height="25px" width="25px" version="1.1" id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 488.1 488.1" space="preserve">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier"><g><g>
                                    <path d="M299.9,86.8c14.2-6.3,30.3-9.8,46.2-9.8c9.2,0,18,1.1,26.3,3.2c3.3,0.8,6.5-2,6-5.4l-1.2-9.6l0,0 c-1-16-19.3-28.9-41.2-28.9c-22.5,0-44,13.5-44,30.2l1,16C293.3,86,296.8,88.2,299.9,86.8z"></path> <path d="M487.3,361.9l-0.5-2.9c0-0.2-0.1-0.4-0.1-0.6l-18.5-94c-2.4-21.7-18.2-40.6-41.2-52.3l-8.8-68.3c0-29-32.8-52.7-72.1-52.7 s-76.7,23.5-76.7,52.6c0,1.4,0.1,2.9,0.3,4.3l0.5,7.4c-7.8-2.8-16.7-4.4-26.1-4.4c-9.5,0-18.4,1.6-26.3,4.5l0.5-7.5 c0.2-1.4,0.3-2.8,0.3-4.3c0-29-37.5-52.6-76.7-52.6c-39.3,0-72.1,23.7-72.1,52.7L61,212.1c-23,11.6-38.8,30.6-41.2,52.3l-18.5,94 c0,0.2-0.1,0.4-0.1,0.6l-0.5,2.9l0,0c-0.4,3-0.7,6-0.7,9.1c0,44.6,46.7,80.8,104.2,80.8s104.2-36.2,104.2-80.8l0,0l0,0v-37 c10.3,4.8,22.6,7.5,35.7,7.5s25.3-2.7,35.6-7.4V371l0,0l0,0c0,44.6,46.7,80.8,104.2,80.8c57.4,0,104.2-36.2,104.2-80.8 C488,367.9,487.7,364.9,487.3,361.9z M104.2,422.7c-40.7,0-75.1-23.7-75.1-51.7c0-28.1,34.4-51.7,75.1-51.7s75.1,23.7,75.1,51.7 C179.3,399,144.9,422.7,104.2,422.7z M244.1,315.9c-17.1,0-31-9.7-31-21.6s13.9-21.6,31-21.6s31,9.7,31,21.6 C275.1,306.2,261.2,315.9,244.1,315.9z M383.8,422.7c-40.7,0-75.1-23.7-75.1-51.7c0-28.1,34.4-51.7,75.1-51.7s75.1,23.7,75.1,51.7 S424.5,422.7,383.8,422.7z"></path> <path d="M115.5,80.2c8.3-2.1,17.2-3.2,26.3-3.2c15.9,0,32.1,3.5,46.2,9.8c3.1,1.4,6.6-0.8,6.8-4.2l1-16c0-16.7-21.5-30.2-44-30.2 c-21.9,0-40.1,12.9-41.2,28.9l0,0l-1.2,9.6C109,78.2,112.1,81.1,115.5,80.2z">
                                    </path></g></g>
                                </g>
                            </svg>
                        </div>
                        <div className="table-cell pl-3 color-primary text-[12px] font-bold">{clearFilterMsg}</div>
                        <div className={"text-[1rem] pl-4 table-cell " + messageCss}>{message}</div>
                    </div>
                </div>
                <div className="clear"></div>

                <AgGridReact
                    rowData={rowData}
                    ref={gridRef}
                    gridOptions={gridOptions}
                    columnDefs={columnDefs}
                    onGridReady={onGridReady}
                    onRowDoubleClicked={(e) => onRowDoubleClicked(e.data)}
                    onCellClicked={cellClicked}
                    rowModelType={rowModelType}
                    cacheBlockSize={pageRows}
                />
            </div>

            <div>
                <SearchFilters isOpen={isFiltersModalOpen} isAccountType={true}
                    clearFilters={clearFilters} onClose={handleCloseFiltersModal} />
            </div>
        </div>
    );
}