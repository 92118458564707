import React from 'react';

export const AccountAddressRenderer = (props) => {
    //debugger;
    const account = props.data ? props.data : null;

    //var owner1 = account.ownerName ? account.ownerName : null;
    //var owner2 = account.ownerName2 ? account.ownerName2 : null;
    //var email1 = account.email1 ? account.email1 : null;
    //var phone1 = account.phone1 ? account.phone1 + " " : null;

    var address1 = account.address1 ? account.address1 : null;
    var address2 = account.address2 ? account.address2 : null;
    var city1 = account.city ? account.city : null;
    var state1 = account.state ? account.state : null;
    var zipCode1 = account.zip ? account.zip : null;

    return (
        <div className="ag-cell-div">
            <div className="ag-cell-content !pt-0">
                {
                    address1 && <div>{address1}</div>
                }
                {
                    address2 && <div>{address2}</div>
                }
                <div className="!pt-[2px]">
                {
                    city1 && <span>{city1}</span>
                }
                {
                    state1 && <span>, {state1}</span>
                }
                {
                    zipCode1 && <span> {zipCode1}</span>
                }
                </div>
            </div>
        </div>
    );
}
