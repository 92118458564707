import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-daisyui'

import { get, post } from '../../../services/apiService';

import { SetDocumentTitle } from '../../_reactHelpers/ReactHelpers';
import { Exists, IdExists, IsEmpty, NotEmpty, NotStringEmpty } from '../../../js/formHelpers';

import { CheckPaymentList } from './CheckPaymentList.js';
import { SetPageTitle } from '../../../js/helpers';

export function PrintCheckQueue({ pBankId, loadCheckPayments }) {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Print Checks");
    //const { bankId } = useParams();
    //const navigate = useNavigate();

    const [bankId, setBankId] = useState(0);
    const [checkPayments, setCheckPayments] = useState([]);

    const [bankName, setBankName] = useState("");
    const [checkNumber, setCheckNumber] = useState(0);

    const [modalOpen, setModalOpen] = useState(false);
    const [disabled, setDisabled] = useState({});
    const [bankAccounts, setBankAccounts] = useState([]);
    const [bankAccount, setBankAccount] = useState({});

    //const [bankMessage, setBankMessage] = useState("");
    //const [bankMessageClass, setBankMessageClass] = useState("");    

    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    function updateMessage(msg, errorMsg, cssClass, logMsg) {
        if (NotStringEmpty(msg)) {
            setMessage(msg);
            setMessageClass("text-green");
        }
        if (NotStringEmpty(errorMsg)) {
            setMessage(msg);
            setMessageClass("text-red");
        }
        if (NotStringEmpty(logMsg)) {
            console.log(logMsg);
        }
        if (NotStringEmpty(cssClass)) {
            setMessageClass(cssClass);
        }
    }

    function getCheckPayments(pBankId) {

        var qBankId = bankId;
        if (IdExists(pBankId))
            qBankId = pBankId;

        if (IdExists(qBankId)) {
            closeModal();
            fetchCheckPayments(qBankId);
        }
        else {
            updateMessage(null, "Bank Account is required");
        }
    }

    const fetchCheckPayments = async (qBankId) => {
        setLoading(true);

        try {
            const response = await get('checkpayment/GetCheckPaymentsByBank/' + qBankId);
            const data = response.map(item => ({ ...item }));

            if (NotEmpty(data)) {
                setBankName(data[0]?.bankAccountName ?? "");
                setCheckNumber(data[data.length - 1].checkNumber)
            }

            setCheckPayments(data);
            setLoading(false);

        } catch (error) {
            console.error('Error fetching check payments:', error);
            updateMessage("", defaultErrMsg);
            setCheckPayments([])
            setLoading(false);
        }
    };


    const handleChangePrint = async (e) => {
        if (IsEmpty(checkPayments)) {
            updateMessage("No checks selected to print.", "", "text-red");
        }
        else {
            try {
                const response = await post('checkpayment/UpdateCheckPaymentsStatus', checkPayments);
                if (IdExists(response)) {
                    updateMessage(response + " check(s) printed successfully");
                }

                fetchCheckPayments();
            }
            catch (error) {
                console.error('Error fetching check payments:', error);
                updateMessage("", defaultErrMsg);
            }
        }
    }

    function getTotal() {
        var total = 0;

        if (NotEmpty(checkPayments)) {
            total =
                checkPayments.reduce((amount, check) => {
                    return amount + check.checkAmount;
                }, 0);
        }

        if (Exists(total)) {
            return "$" + total.toFixed(2);
        }
        else {
            return "$0.00";
        }

    }

    function loadCheckPaymentsOnClick() {
        //onClick={() => navigate(`/check-payments?bank=${bankId}&number=${checkPayments[checkPayments.length - 1].checkNumber + 1}`)}
        loadCheckPayments(bankId, checkNumber + 1);
    }

    const fetchBankAccounts = async () => {
        try {
            const response = await get('bankaccount/GetAllBankAccounts');
            const data = response.map(item => ({
                ...item
            }));

            setBankAccounts(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching Bank Accounts:', error);
            setBankAccounts([]);
            setLoading(false);
        }
    };

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    function openBankAccountsModal() {
        openModal();
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'bankAccount') {
            var ba = bankAccounts.find(bank => bank.bankId === parseInt(value)) ?? null;
            setBankAccount(ba);

            if (Exists(ba)) {
                setBankId(ba.bankId);
                setBankName(ba.bankName);
                setDisabled(false);
            }
            else {
                setBankId(0);
                setDisabled(false);
            }
        }
    };

    useEffect(() => {
        SetPageTitle("Print Checks");

        if (IdExists(pBankId)) {
            setBankId(pBankId);
            getCheckPayments(pBankId);
        }
        else {
            fetchBankAccounts();
            openModal();
        }
    }, []);

    return (
        <div>
            <div className="pt-2 pb-4">
                <div className="flex-wrapper !items-start">
                    <div className="flex">
                        {IdExists(pBankId) &&
                            <button type="button" className='btn-small pr-4' onClick={() => loadCheckPaymentsOnClick()}>
                                Go Back
                            </button>
                        }
                        <button type="button" className='btn-small' onClick={() => openBankAccountsModal()}>
                            Select Bank
                        </button>
                    </div>
                    <div className="flex-1 text-center"
                        //className={"float-left text-center " + (IdExists(pBankId) ? " ml-[25%] " : " ml-[30%] ")}
                    >
                        <div className="text-2xl text-secondary">
                            <span className="text-black">Bank:</span>
                            <span className="ml-2 font-bold underline">{bankName}</span>
                        </div>
                        <div className="mt-4 text-md">
                            <span className="pr-2">Total amount of all checks:</span>
                            <span className="font-bold">{getTotal()}</span>
                        </div>
                        <div className="mt-1 text-md">
                            <span className="pr-2">Number of checks to print:</span>
                            <span className="font-bold">{NotEmpty(checkPayments) ? checkPayments.length : 0}</span>
                        </div>
                        <div className={"text-sm h-[30px] pt-2 " + messageClass}>
                            {message}
                        </div>
                    </div>
                    <div className="flex-end">
                        <button type="button" className='btn-small' onClick={() => { handleChangePrint() }}>
                            Print Checks
                        </button>
                    </div>
                </div>
            </div>

            <div className="clear pt-2">
                <CheckPaymentList checkPayments={checkPayments} loading={loading} />
            </div>

            <div>
                <Modal open={modalOpen} className="pt-2 w-full">
                    <Button onClick={closeModal} size="sm" color="ghost" shape="circle"
                        className="absolute right-2 min-h-[1.5rem] h-[1.5rem] w-[1.5rem]">x</Button>
                    <Modal.Body>
                        <div className="pt-8 flex items-center">
                            <div className="flex-1 label-text text-base font-bold pr-4">Bank Account:</div>
                            <div className="flex-1">
                                <select name="bankAccount" className="input-bordered border rounded-lg"
                                    value={bankAccount.bankId}
                                    onChange={handleChange}>
                                    <option>Select...</option>
                                    {bankAccounts.map(bankAccount =>
                                        <option value={bankAccount.bankId}>{`${bankAccount.bankName} - ${bankAccount.bankAccountName} - ${bankAccount.bankAccountNumber}`}</option>)}
                                </select>
                            </div>
                        </div>
                        {/*<div className={"text-sm h-[30px] pt-2 " + bankMessageClass}>*/}
                        {/*    {bankMessage}*/}
                        {/*</div>*/}
                        <div className="pt-8 text-right">
                            <Button className='btn-submit !mr-0' type="button"
                                disabled={disabled}
                                onClick={e => getCheckPayments()}>Continue</Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
}