import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import { get, post } from '../../services/apiService.js';
import {
    Exists, handleFieldChange, IdExists, IsEmpty,
    IsTrue, NotEmpty, ParseIntSafe, PreventDefault, SetAndClickById
} from '../../js/formHelpers';
import { GetLocationStateId, SetDocumentTitle } from '../_reactHelpers/ReactHelpers';
import { StandardDataAndResponsePOST, StandardIdAndResponseGET } from '../../js/dataHelpers';
import { SetPageTitle } from '../../js/helpers';

export const UserAddEdit = (props) => {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina User");
    const location = useLocation();
    const navigate = useNavigate();

    const [userId, setUserId] = useState(GetLocationStateId("userId"));
    const [userData, setUserData] = useState(null);
    const [roles, setRoles] = useState([]);
    const [rolesLoading, setRolesLoading] = useState(true);

    const [redirectSubmit, setRedirectSubmit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    // Helpers

    function backToClick(userMessage) {
        navigate('/Users', {
            replace: true,
            state: { useSavedFiltersVendor: true, userMessage }
        });
    }

    function updateMessage(msg, cssClass, errorMsg, logMsg) {
        if (errorMsg) {
            console.error(errorMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setMessage(msg);
        setMessageClass(cssClass);
    }

    // Methods

    const handleChange = (e) => {
        const data = handleFieldChange(e);

        if (data) {
            if (data.name === 'roles') {
                const roleId = parseInt(e.target.value, 10);
                const isChecked = e.target.checked;

                setUserData((prevData) => {
                    const updatedUserRoles = isChecked
                        ? [...(prevData.userRoles || []), { roleId, user: prevData, role: { roleId } }]
                        : (prevData.userRoles || []).filter((role) => role.roleId !== roleId);

                    const updatedRoleIds = isChecked
                        ? [...(prevData.roleIds || []), roleId]
                        : (prevData.roleIds || []).filter((id) => id !== roleId);

                    return {
                        ...prevData,
                        userRoles: updatedUserRoles,
                        roleIds: updatedRoleIds,
                    };
                });
            } else {
                setUserData((prevData) => ({
                    ...prevData,
                    [data.name]: data.value,
                }));
            }
        } else {
            updateMessage(defaultErrMsg, 'text-red', 'null handleChange data');
        }
    };

    // Validate and Post Data

    const validateData = () => {
        var valid = true;
        var msg = "";

        if (NotEmpty(msg)) {
            updateMessage(msg, "text-red");
        }
        return valid;
    };

    function saveUserMsg(userId) {
        if (IdExists(userId)) {
            return userData.userName + " updated successfully.";
        }
        else {
            return userData.userName + " created successfully.";
        }
    }

    function btnSubmitClick(e, redirectSubmit) {
        setRedirectSubmit(redirectSubmit);
    };

    const handleUserSubmit = async (e) => {
        PreventDefault(e);

        var valid = validateData();
        if (valid === true) {
            var resp = await UpdateUserPOST();

            var iUserId = ParseIntSafe(resp);
            if (IdExists(iUserId)) {
                var msg = saveUserMsg(userId);
                SetAndClickById("lnkReloadPage", "/Redirect?route=Users&id=" + iUserId + "&message=" + msg);

                //if (redirectSubmit) {
                //    backToClick(msg);
                //}
                //else {
                //    if (IdExists(userId)) {
                //        updateMessage(msg, "text-green");
                //    }
                //    else {
                //        SetAndClickById("lnkReloadPage", "/Redirect?route=User&id=" +
                //            iUserId + "&message=" + msg);
                //    }
                //}
            }
            else {
                console.error("handleUserSubmit(): ", JSON.stringify(resp));
                alert(defaultErrMsg);
            }
        }
    }

    async function UpdateUserPOST() {
        // Get selected RoleIds
        const roleIds = userData.userRoles.map(role => role.roleId) || [];
        const postData = {
            ...userData,
            RoleIds: roleIds
        };

        var postModel = JSON.stringify(postData);
        //console.log('Post Model:', postModel);
        var response = await StandardDataAndResponsePOST(postModel, "user/SaveUser", "UpdateUserPOST()");
        return response;
    }

    // Load Data

    const loadRoles = async () => {

        setRolesLoading(true);
        var data = await StandardIdAndResponseGET("", '/user/getroles', "User - loadRoles()");
        if (NotEmpty(data)) {
            setRoles(data);
        }
        else {
            updateMessage("Failed to load roles. Please try again.", "text-red");
        }
        setRolesLoading(false);
    }

    const loadUserData = async () => {
        var data = await StandardIdAndResponseGET(userId, "user/GetUser/", "loadUserData()");
        console.log('UserData:', data);
        if (Exists(data)) {
            setLoading(false);
            setUserData(data);

            var docT = "Lumina Create User";
            var t = "Create User";

            var iUserId = ParseIntSafe(data.userId);
            if (IdExists(iUserId)) {
                docT = "Lumina User - " + data.userName;
                t = "User - " + data.userName;
            }
            setDocTitle(docT);
            SetPageTitle(t);
        }
        else {
            setError(true);
        }
    }

    function showMessage() {
        if (location.state && NotEmpty(location.state.userMessage)) {
            updateMessage(location.state.userMessage, "text-green");
        }
    }

    useEffect(() => {
        showMessage();
        loadUserData();
        loadRoles();
    }, []);

    if (IsTrue(loading) || rolesLoading) {
        return (
            <div className="text-center text-xl mt-[10%]">
                <span className="pr-1">Loading...</span>
                <span className="loading loading-spinner text-blue-600"></span>
            </div>
        );
    }

    if (IsTrue(error)) {
        return <div className="text-red-500 text-center mt-[25%] text-xl">{defaultErrMsg}</div>;
    }

    return (
        <div className="details-page">
            <div className="details-page-container">
                {userData && (
                    <div>
                        <form onSubmit={handleUserSubmit} className="act-form">
                            <div className="flex flex-wrap forms-page clear">
                                <div className="form-break pb-2">
                                    <hr className="form-break-line" />
                                    <span className="form-break-title">
                                        User Info
                                    </span>
                                </div>

                                {/** column1 **/}
                                <div className="w-full">
                                    <div className="form-col-1 inline-block !w-[50%] align-top pr-4">
                                        <div className="details-form-field">
                                            <label htmlFor="userName" className="lbl-text1">Username</label>
                                            <input type="text" id="userName" name="userName"
                                                className="inp-text"
                                                value={userData.userName}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="details-form-field">
                                            <label htmlFor="firstName" className="lbl-text1">First Name</label>
                                            <input type="text" id="firstName" name="firstName"
                                                className="inp-text"
                                                value={userData.firstName}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="details-form-field">
                                            <label htmlFor="lastName" className="lbl-text1">Last Name</label>
                                            <input type="text" id="lastName" name="lastName"
                                                className="inp-text"
                                                value={userData.lastName}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    {/** column2 **/}
                                    <div className="form-col-1 inline-block !w-[50%] align-top">
                                        <div className="details-form-field">
                                            <label className="lbl-cb">Roles</label>
                                            {roles.length > 0 ? (
                                                <div className="">
                                                    {roles.map(role => (
                                                        <div key={role.roleId} className="table-cell p-1">
                                                            <label htmlFor={`role-${role.roleId}`}
                                                                className="pl-3">
                                                                {role.roleDescription}
                                                            </label>
                                                            <input type="checkbox" id={`role-${role.roleId}`} name="roles"
                                                                value={role.roleId}
                                                                checked={userData.userRoles?.some(userRole => userRole.roleId === role.roleId) || false}
                                                                onChange={handleChange}
                                                                className="inp-cb !mt-[-2px]"
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div>No roles available</div>
                                            )}
                                        </div>


                                        <div className="details-form-field">
                                            <label className="lbl-cb pr-8 !inline">
                                                Registered
                                                <input type="checkbox" id="isRegistered" name="isRegistered"
                                                    className="inp-cb"
                                                    checked={userData.isRegistered}
                                                    onChange={handleChange}
                                                />
                                            </label>

                                            <label className="lbl-cb !inline">
                                                Inactive
                                                <input type="checkbox" id="inactive" name="inactive"
                                                    className="inp-cb"
                                                    checked={userData.inactive}
                                                    onChange={handleChange}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full text-right">
                                <div className={"inline-block mr-1 " + messageClass}>
                                    {message}
                                </div>
                                <div className="inline-block">
                                    <button onClick={e => backToClick()} type="button" className="btn-cancel">
                                        Cancel
                                    </button>
                                    <button type="submit" className="btn-submit"
                                        onClick={(e) => btnSubmitClick(e, true)}>
                                        Save & Close
                                    </button>                                    
                                </div>
                            </div>
                        </form>
                        <a href="/" id="lnkReloadPage" className="hidden">Reload</a>
                    </div>
                )}
            </div>
        </div>
    );
}