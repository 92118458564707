import { StandardDataAndResponsePOST } from '../js/dataHelpers';
import { Exists, IdExists, IsTrue, ParseIntSafe } from '../js/formHelpers';
import { StringContains } from '../js/helpers';

import { get, post } from '../services/apiService.js';
//import axios from 'axios';

function setUserData(userData) {
    localStorage.setItem('userData', JSON.stringify(userData));
};

const login = async (username, password) => {
    var response = null;
    try {
        //localStorage.setItem('userData', null);
        setUserData(null);
        var model = {
            username: username,
            password: password
        };
        var postModel = JSON.stringify(model);
        response = await StandardDataAndResponsePOST(postModel, "auth/Login", "auth/Login");

        if (Exists(response) && IdExists(response.userId)) {
            console.log('Data!: ' + JSON.stringify(response));
            setUserData(response);
            return response;
        }
        else {
            throw new Error(`login() authentication failed: No user returned. Error: ${response}`);
        }
    } catch (error) {
        console.error('Login failed:', error);
        throw error;
    }
};

const logout = async () => {
    try {
        localStorage.removeItem('token');
        localStorage.removeItem('userData');
    } catch (error) {
        console.error('Logout failed:', error);
    }
};

const getADUser = async () => {
    var response = null;    
    try {
        const endpoint = "activedirectory/GetActiveDirectoryUser?run=true";
        
        // use GET without headers since dont have user data if got here
        //var apiUrl = process.env.REACT_APP_API_URL + endpoint;

        //const resp = await axios.get(apiUrl)
        //    .then(result => {
        //        console.log('result: ' + JSON.stringify(result));
        //        response = result;
        //    })
        //    .catch(err => {
        //        console.error('err: ' + JSON.stringify(err));
        //        console.log("err.response: " + JSON.stringify(err.response));
        //        if (err.response) {
        //            console.log("log: " + JSON.stringify(err.response.data));
        //        }
        //    });

        const resp = await get(endpoint);
        //const resp = await post(endpoint);
                
        console.log('AD Resp: ' + JSON.stringify(resp));
        //console.log('Response: ' + JSON.stringify(response));

        if (Exists(resp)) {

            var luminaUserId = ParseIntSafe(resp.userId);
            if (IdExists(luminaUserId)) {
                console.log('Got AD response: ' + JSON.stringify(response));
                setUserData(response);
                return response;
            }
            else 
            {
                console.log('User Id does not exist: ' + JSON.stringify(response));
                return null;
            }
        } else {
            return null;
        }
    } catch (error) {
        console.log('Error: ' + error);
        return null;
    }
};

async function getCurrentUser() {    
    // Check current userData
    var userData = JSON.parse(localStorage.getItem('userData'));
    //console.log('getCurrentUser() existing userData: ' + JSON.stringify(userData));

    if (userData != null) {
        return userData;
    }
    else {
        //console.log('getCurrentUser() AD check');
        // Check AD user
        userData = await getADUser();
        if (userData != null) {
            //console.log("getCurrentUser() AD check - userData: " + JSON.stringify(userData));
            setUserData(userData);
            return userData;
        }
        else {
            console.log('getCurrentUser() AD check - userData: NULL');
            return null;
        }
    }
}

function isInRole(role) {
    const userData = getCurrentUser();
    //console.log('isInRole currentUser: ' + JSON.stringify(userData));
    //console.log('isInRole: ' + userData.role);

    if (!role || !userData.role) {
        return false;
    }
    return userData.role.toLowerCase() === role.toLowerCase();
}

function hasPermission(reqPerm) {
    //const userData = getCurrentUser();
    //if (!reqPerm || !userData || !userData.permissions) return false;
    //return userData.permissions.some(p => p.permission?.toLowerCase() === reqPerm.toLowerCase());
    return true;
}

function isAuthenticated () {
    const userData = getCurrentUser();
    return userData !== null && userData.isRegistered;
}

function GetDefaultUserLink(userData) {
    var hlUrl = "/Login";

    if (Exists(userData) && Exists(userData.user)) {

        // TODO: confirm default pages
        hlUrl = "/Orders";

        if (StringContains(userData.user.roleNames, 'productadmin')) {
            hlUrl = "/ProductPricingAdmin";
        }
        else if (StringContains(userData.user.roleNames, 'accounting')) {
            hlUrl = "/Payables";
        }
        else if (StringContains(userData.user.roleNames, 'sales')) {
            hlUrl = "/Orders";
        }
        else if (StringContains(userData.user.roleNames, 'warehouse')) {
            hlUrl = "/Receiving";
        }
    }
    return hlUrl;
}

const exports = {
    login,
    logout,
    getCurrentUser,
    getADUser,
    isInRole,
    hasPermission,
    isAuthenticated,
    GetDefaultUserLink,
    //isAdmin
};

export default exports;