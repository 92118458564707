import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Modal } from 'react-daisyui';


import { post } from '../../../services/apiService.js';
import {
    GetStoreSelectList, GetProductCategorySelectList, GetVendorSelectList, GetUnitsSelectList
} from '../../../js/dataHelpers.js';
import {
    FormatDateLongInput, FormatDate, NotStringContains
} from '../../../js/helpers.js';
import {
    handleFieldChange, SetFocusById, formatCurrencyDecimal, FormatStrLength,
    PreventDefault, isSelectedDropdown, NotEmpty, IsEmpty, Exists, ParseIntSafe, IdExists, IsTrue, IsFalse, EnforceNumDecimalPlaces,
    KeyHandlerDatePicker, GetDateNow, NotStringEmpty, StringEmpty, isValidDate, NotExists, NotIsValidDate
} from '../../../js/formHelpers.js';
import {
    GetQuantityFromWidthLength, GetLengthFromWidthQuantity, GetRemainder,
    GetNewQuantityForMin, GetMultDec, GreaterThan, Equals
} from '../../../js/calculationHelpers.js';

import { DoMinQtyCheck, GetBoxQtyStr, createEditOlModel } from '../_js/_orderHelpers.js';
import { MinQtyModal } from './MinQtyModal';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const EditOrderLineModal = (props) => {
    const qtyModal = useRef();
    const onCloseModal = props.onCloseModal;

    const propsType = props.type;    
    const propsPriceLevelStr = props.priceLevelStr ?? "";    
    const propsBcMultiplier = props.bcMultiplier ?? 0;
    const propsOrderLines = props.orderLines ?? [];
    const propsIsSamplesOrder = props.isSamplesOrder ?? false;

    const typeEdit = "edit-lines";
    const typeDelete = "delete-lines";

    const [modalTitle, setModalTitle] = useState("Edit Line");
    const [storeList, setStoreList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [unitsList, setUnitsList] = useState([]);
    
    const [statusList, setStatusList] = useState([]);
    const [originalStatusId, setOriginalStatusId] = useState("");
    const [originalQty, setOriginalQty] = useState("");
        
    const [showPoData, setShowPoData] = useState(false);     
    const [showAddLineForm, setShowAddLineForm] = useState(false);
        
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [closeDisabled, setCloseDisabled] = useState(false);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [skipButtonDisabled, setSkipButtonDisabled] = useState(true);

    const [inputsDisabled, setInputsDisabled] = useState(false);
    const [customFieldsDisabled, setCustomFieldsDisabled] = useState(true);    

    const [qtyDisabled, setQtyDisabled] = useState(true);
    const [locationDisabled, setLocationDisabled] = useState(true);
    const [priceDisabled, setPriceDisabled] = useState(false);
    const [unitsDisabled, setUnitsDisabled] = useState(false);

    const [linesEdited, setLinesEdited] = useState(false);
    const [orderLineIds, setOrderLineIds] = useState([]);
    const [olData, setOlData] = useState(null);        

    const [isRoll, setIsRoll] = useState(false);
    const [boxQtyStr, setBoxQtyStr] = useState(false);
    const [stdLength, setStdLength] = useState(0);
    const [promiseDate, setPromiseDate] = useState("");
    const [units, setUnits] = useState("");
    const [width, setWidth] = useState(0);
    const [length, setLength] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [lineTotal, setLineTotal] = useState(0);
    const [linePrice, setLinePrice] = useState(0);

    //used for min qty adjustment
    const [qtyModalContent, setQtyModalContent] = useState(null);
    const [allowQtyOverride, setAllowQtyOverride] = useState(false);

    const [requiredClass, setRequiredClass] = useState(" hidden ");
    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [changeStatusMsg, setChangeStatusMsg] = useState("");
    const [poQtyUpdateMsg, setPoQtyUpdateMsg] = useState("");
                
    const updateMsg = "Line updated successfully.";    
    const updateErrMsg = "There was an issue updating the Line. Please try again or contact support.";
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    const purOrderQtyUpdateMsg = "Editing quantity will update the PO qty for this line.";
    const deleteConfirmMsg = "Are you sure you want to delete the selected line(s)?";
        
    //const [lastKeypressTime, setLastKeypressTime] = useState(null);    

    // Helpers    
    function updateMessage(msg, cssClass) {
        setMessage(msg);
        setMessageClass(cssClass);
    }

    function onCloseDeleteLineModal(e, deleteLines) {
        //allow being called from non-button click
        if (e) {
            e.preventDefault();
        }
        onCloseModal(e, deleteLines);
    }

    // Methods

    async function createProductCategoryList() {
        var list = [];
        list.push(<option key="0" value="0">None</option>);

        var dataList = await GetProductCategorySelectList("order");
        dataList.forEach((item, index) => {
            list.push(<option key={index + 1} value={item.value}>{item.text}</option>);
        });

        setCategoryList(list);
    }

    async function createVendorSupplierList() {
        var list = [];
        list.push(<option key="0" value="0">None</option>);

        var dataList = await GetVendorSelectList(1);
        dataList.forEach((item, index) => {
            list.push(<option key={index + 1} value={item.value}>{item.text}</option>);
        });

        setVendorList(list);
        setShowAddLineForm(true);
    }

    async function createStoreList() {
        var list = [];
        //list.push(
        //    <option key="0" value="0">None</option>
        //);

        var dataList = await GetStoreSelectList(2);
        dataList.forEach((item, index) => {
            list.push(
                <option key={index + 1} value={item.value}>{item.text}</option>
            );
        });
        setStoreList(list);
    }

    async function createUnitsList(units) {
        var list = [];
        list.push(<option key="0" value="0">None</option>);

        var createForValue = true;
        var dataList = await GetUnitsSelectList();

        var cnt = 0;
        dataList.forEach((item, index) => {
            list.push(<option key={cnt + 1} value={item.value}>{item.text}</option>);
            cnt++;
            if (IsTrue(createForValue)) {
                createForValue = NotStringContains(item.text, units);
            }
        });

        // ensure selected value is always in list
        if (IsTrue(createForValue)) {
            list.push(<option key={cnt + 1} value={units}>{units}</option>);
        }

        setUnitsList(list);
    }

    async function createStatusList(currentStatusId) {
        var isQtyDisabled = true;
        var isLocationDisabled = true;
        var isShowPoData = false;

        var index = 0;
        var list = [];
        //None, To Be Ordered
        if (currentStatusId === 0 || currentStatusId === 1) {            
            index++;
            list.push(<option key={index} value={0}>None</option>);
            index++;
            list.push(<option key={index} value={1}>To Be Ordered</option>);

            isQtyDisabled = false;
            isLocationDisabled = false;
        }
        //Stock Item
        if (currentStatusId === 15) {
            //allow None and ToBeOrdered??
            index++;
            list.push(<option key={index} value={0}>None</option>);
            index++;
            list.push(<option key={index} value={1}>To Be Ordered</option>);
            index++;
            list.push(<option key={index} value={15}>Stock Item</option>);

            isQtyDisabled = false;
        }
        //Emailed
        if (currentStatusId === 10) {
            index++;
            list.push(<option key={index} value={0}>None</option>);
            index++;
            list.push(<option key={index} value={1}>To Be Ordered</option>);
            index++;
            list.push(<option key={index} value={10}>Emailed</option>);

            isQtyDisabled = false;
            isLocationDisabled = false;
        }
        //On Order
        if (currentStatusId === 2) {
            setChangeStatusMsg("This line is On Order. Changing the status will remove the PO.");

            index++;
            list.push(<option key={index} value={0}>None</option>);
            index++;
            list.push(<option key={index} value={1}>To Be Ordered</option>);
            index++;
            list.push(<option key={index} value={2}>On Order</option>);

            isQtyDisabled = false;
            isShowPoData = true;
        }
        //Received
        if (currentStatusId === 3) {
            setChangeStatusMsg("Changing the status will un-receive this line and update the PO.");

            index++;
            list.push(<option key={index} value={2}>On Order</option>);
            index++;
            list.push(<option key={index} value={3}>Received</option>);
        }        
        //Delivered
        if (currentStatusId === 4) {
            setChangeStatusMsg("Changing the status will un-deliver this line and set it received.");

            //cant go from On Order to Delivered, dont allow reverse action
            //index++;
            //list.push(<option key={index} value={2}>On Order</option>);
            index++;
            list.push(<option key={index} value={3}>Received</option>);
            index++;
            list.push(<option key={index} value={4}>Delivered</option>);
        }
        //Closed
        if (currentStatusId === 5) {
            //once line Closed no edits
            //lines are Closed once order is Invoiced(JobCosted) and Closed
            index++;
            list.push(<option key={index} value={5}>Closed</option>);
        }
        //Pre-Delivered
        if (currentStatusId === 6) {
            index++;
            list.push(<option key={index} value={2}>On Order</option>);
            index++;
            list.push(<option key={index} value={3}>Received</option>);
            index++;
            list.push(<option key={index} value={6}>Pre-Delivered</option>);
        }
        //'Will Call' status currently not used
        if (currentStatusId === 7) {
            index++;
            list.push(<option key={index} value={7}>Will Call</option>);
        }               

        //TODO: Data driven OrderStatus list
        //w current status and set available statuses for edit

        //var dataList = await GetStoreSelectList(2);
        //dataList.forEach((item, index) => {
        //    list.push(
        //        <option key={index + 1} value={item.value}>{item.text}</option>
        //    );
        //});

        setQtyDisabled(isQtyDisabled);
        setLocationDisabled(isLocationDisabled);
        setShowPoData(isShowPoData);
        setStatusList(list);
    }

    function onCloseEditLineModal(e, edited) {
        //allow being called from non-button click
        if (e) {
            e.preventDefault();
        }

        //if only one line linesReceived not set due to async
        var hasEditLines = linesEdited || edited;
        onCloseModal(e, hasEditLines);
    }
    
    async function setupOrderLines() {
        var hasData = false;
        if (propsOrderLines && propsOrderLines.length > 0) {

            var olIds = [];
            propsOrderLines.forEach((ol, index) => {
                if (ol) {
                    olIds.push(ol.orderLineId);
                }
            });
            setOrderLineIds(olIds);

            var olId = await moveNextOrderLineId(olIds);
            if (olId && olId !== 0) {
                //set line data from ol
                setupOrderLineData(olId);
                hasData = true;
            }
        }

        if (hasData !== true) {
            //close if no orderLine on load
            onCloseEditLineModal(null);
        }
    }

    function setupOrderLineData(olId) {
        if (propsOrderLines && propsOrderLines.length > 0) {
            var olArr = propsOrderLines.filter((element) => {
                return element.orderLineId === olId;
            });

            if (olArr && olArr.length === 1) {
                var ol = olArr[0];
                createStatusList(ol.statusId);

                //set page fields
                var canEditCustom = (ol.statusId === 0 || ol.statusId === 1 || ol.statusId === 15); 
                setCustomFieldsDisabled(!canEditCustom);

                var unitsDisabled = (ol.productCategoryId === 1 || ol.productCategoryId === 2);
                setUnitsDisabled(unitsDisabled);

                setOriginalStatusId(ol.statusId);
                setLinePrice(formatCurrencyDecimal(ol.price))
                setLineTotal(formatCurrencyDecimal(ol.total));
                setOriginalQty(ol.quantity);

                //setPromiseDate(ol.promiseDateInputStr);
                if (NotStringEmpty(ol.promiseDateInputStrDtPckr)) {
                    var date = new Date(ol.promiseDateInputStrDtPckr);
                    setPromiseDate(date);
                }
                else {
                    setPromiseDate(new Date());
                }

                setAllowQtyOverride(true);
                setIsRoll(ol.isRoll);

                setUnits(ol.unitsStr);
                createUnitsList(ol.unitsStr)

                setWidth(ol.width);
                setLength(ol.length);
                setStdLength(ol.length);
                setQuantity(ol.quantity);

                var bxQtyStr = GetBoxQtyStr(ol.sellQuantity, ol.sellUnit, ol.unitsStr);
                setBoxQtyStr(bxQtyStr);

                //set page model
                setOlData(ol);

                if (ol.isCustom) {
                    setModalTitle("Edit Custom Line");
                    createProductCategoryList();
                    createVendorSupplierList();
                }
                else {
                    setModalTitle("Edit Order Line");
                    setShowAddLineForm(true);
                }

                if (IsTrue(propsIsSamplesOrder)) {
                    setModalTitle("Edit Samples Line");
                    setQtyDisabled(true);                    
                    setPriceDisabled(true);
                    setSubmitDisabled(false);
                }
            }
        }
    }

    async function moveNextOrderLineId(olIds) {
        var olId = 0;
        setSkipButtonDisabled(true);
        setShowAddLineForm(false);

        if (!olIds) {
            //get from state if not passed in
            olIds = orderLineIds;
        }

        if (olIds && olIds.length > 0) {
            //get next ID from array
            olId = ParseIntSafe(olIds.slice(0, 1) ?? "0");

            //remove first ID from array
            olIds = olIds.slice(1);

            //show skip button if lines left
            if (olIds && olIds.length > 0) {
                setSkipButtonDisabled(false);
            }
        }

        setOrderLineIds(olIds);
        return olId;
    }

    async function saveOrNextOrderLine(e, save) {
        PreventDefault(e);
        updateMessage();
        setRequiredClass(" hidden ");

        var valid = true;
        if (save === true) {
            valid = validateSave();
        }

        //if not valid stop all actions
        //error messages displayed
        if (valid === true) {            

            var response = null;
            if (IsTrue(save)) {
                response = await saveOrderLine();
                save = false;
            }            

            if (IsFalse(save)) {
                //move to next order line or close modal if last
                var olId = await moveNextOrderLineId();
                if (olId && olId !== 0) {
                    setupOrderLineData(olId);
                }
                else {
                    //close modal on last received
                    onCloseEditLineModal(e, response);
                }
            }
        }
    }
    
    function validateSave() {
        updateMessage();
        var valid = true;
        var msg = "";

        if (!olData.quantity || olData.quantity <= 0) {
            valid = false;
        }

        if (olData.statusId === 2 && NotIsValidDate(promiseDate)) {
            valid = false;
        }

        if (olData.isCustom) {
            if (isSelectedDropdown(olData.productCategoryId) !== true) {
                valid = false;
            }
            if (isSelectedDropdown(olData.vendorId) !== true) {
                valid = false;
            }
            if (IsEmpty(olData.style)) {
                valid = false;
            }
            if (IsEmpty(olData.color)) {
                valid = false;
            }
        }

        //show message if changing status once Received/Delivered/Closed
        //if (olData.statusId !== originalStatusId) {
        //    updateMessage(changeStatusMsg, "text-red");
        //    return false;
        //}

        if (IsFalse(valid)) {
            setRequiredClass("");
            updateMessage("*Required fields", "text-red");
        }
        return valid;
    }

    function showQtyMessage(currStatusId, currQty) {         
        setPoQtyUpdateMsg("");

        if (currStatusId === 2) {
            if (currQty !== originalQty) {
                setMessageClass("text-red");
                setPoQtyUpdateMsg(purOrderQtyUpdateMsg);
            }
        }  
    }

    const handleChange = (e) => {
        var data = handleFieldChange(e);
        if (data) {

            // individual fields
            if (data.name === "units") {
                setUnits(data.value);
            }
            else if (data.name === "width") {
                UpdateForWidthLengthQuantity(data.value, null, null);
            }
            else if (data.name === "length") {
                UpdateForWidthLengthQuantity(null, data.value, null);
            }
            else if (data.name === "quantity") {
                setSubmitDisabled(true);
                DisableSubmit(e, data.value, false);

                // reset allow override for submit on qty change
                setAllowQtyOverride(false);                
                UpdateForWidthLengthQuantity(null, null, data.value);                
            }
            else if (data.name === "linePrice") {
                setLinePrice(data.value);
                UpdatePricesAndTotals(quantity, data.value, null, null);
            }
            else {
                // olData fields

                // Bin always capitalized
                if (data.name === "bin") {
                    data.value = data.value.toUpperCase();
                }

                // show message if changing status once OnOrder/Received/Delivered/Closed
                if (data.name === "statusId") {
                    updateMessage();

                    let currStatusId = ParseIntSafe(data.value);
                    let currQty = ParseIntSafe(olData.quantity);
                    showQtyMessage(currStatusId, currQty);

                    if (currStatusId !== originalStatusId) {
                        updateMessage(changeStatusMsg, "text-red");
                    }
                    if (currStatusId === 2) {
                        setShowPoData(true);
                    }
                    else {
                        setShowPoData(false);
                    }
                }

                // cost
                if (data.name === "cost") {
                    UpdatePricesAndTotals(null, null, data.value, null);
                }
                // multiplier
                if (data.name === "multiplier") {
                    data.value = EnforceNumDecimalPlaces(data.value, 3);
                    UpdatePricesAndTotals(null, null, null, data.value);
                }
                // comments
                if (data.name === "comments") {
                    data.value = FormatStrLength(data.value, 50);
                }
                // productCategoryId
                if (data.name === "productCategoryId") {
                    if (data.value === "1" || data.value === "2") {
                        setUnits("SY");
                        setIsRoll(true);
                        setUnitsDisabled(true);
                    }
                    else {
                        setIsRoll(false);
                        setUnitsDisabled(false);
                        if (units === "SY") {
                            setUnits("");
                        }
                        else {
                            //dont clear units if not switching from Rolls
                        }
                    }
                }

                //updateMessage("", "");
                setOlData((olData) => ({
                    ...olData,
                    [data.name]: data.value
                }));
            }
        }
        else {
            //updateMessage(defaultErrMsg, "text-red", "null handleChange data");
        }
    };

    function UpdateForWidthLengthQuantity(cWidth, cLength, cQuantity) {

        //update roll fields based on changed
        if (isRoll === true) {
            //quantity always set here for rolls

            if (cWidth !== null) {
                //set changed value
                setWidth(cWidth);
                //set calc values for non-changed fields
                cLength = length ?? 0;

                cQuantity = GetQuantityFromWidthLength(cWidth, cLength, "SY")
                cQuantity = formatCurrencyDecimal(cQuantity);
            }
            else if (cLength !== null) {
                //set changed value
                setLength(cLength);
                //set calc values for non-changed fields
                cWidth = width ?? 0;

                cQuantity = GetQuantityFromWidthLength(cWidth, cLength, "SY")
                cQuantity = formatCurrencyDecimal(cQuantity);
            }
            else if (cQuantity !== null) {
                //set calc values for non-changed fields
                cWidth = width ?? 0;

                cLength = GetLengthFromWidthQuantity(cWidth, cQuantity, "SY")
                setLength(formatCurrencyDecimal(cLength));
            }
        }

        //quantity always updated        
        setQuantity(cQuantity);
        //update pricing and totals
        UpdatePricesAndTotals(cQuantity, null, null, null);
    }

    function UpdatePricesAndTotals(cQuantity, cLinePrice, cCost, cMultiplier) {        
        var reCalcLinePrice = false;

        if (cQuantity === null) {
            cQuantity = quantity;
        }
        if (cLinePrice === null) {
            cLinePrice = linePrice;
        }
        //re-calc line price when cost changed
        if (cCost === null) {
            cCost = olData.cost;
        }
        else {
            reCalcLinePrice = true;
        }
        //re-calc line price when multiplier changed
        if (cMultiplier === null) {
            cMultiplier = olData.multiplier;
        }
        else {
            reCalcLinePrice = true;
        }

        // TODO: make 2 decimal places
        //cMultiplier = formatCurrencyDecimal(cMultiplier);
        //cCost = formatCurrencyDecimal(cCost);

        olData.cost = cCost;
        olData.multiplier = cMultiplier;
        setOlData(olData);

        //update for changed fields
        if (reCalcLinePrice === true) {
            const bcPrice = GetMultDec(cCost, cMultiplier);
            cLinePrice = GetMultDec(bcPrice, propsBcMultiplier);
            setLinePrice(cLinePrice);
        }

        //always update LineTotal
        const lt = GetMultDec(cQuantity, cLinePrice);
        setLineTotal(lt);

        /** CFM 4-16-24: dont show Referral **
        //only calculate bc and referral for custom items
        if (IsCustomProduct) {
            const bcPrice = GetMultDec(cCost, cMultiplier);
            const priceReferral = cLinePrice - bcPrice;
            const referral = GetMultDec(priceReferral, cQuantity);
            setReferral(referral);
        }*/
    }

    // Min Qty Modal
    const handleQtyModalShow = useCallback(() => {
        qtyModal.current?.showModal();
    }, [qtyModal]);

    const handleQtyModalHide = useCallback(() => {
        qtyModal.current?.close();
        SetFocusById("linePrice");
    }, [qtyModal]);

    function returnMinQtyModalComponent(minQuantity, newQuantity, onCloseQtyModal) {
        return <MinQtyModal minQuantity={minQuantity} newQuantity={newQuantity}
            onCloseModal={onCloseQtyModal}></MinQtyModal>;
    }

    function onOpenQtyModal(minQuantity, newQuantity) {
        //updateMessage("", "");

        setQtyModalContent(returnMinQtyModalComponent(minQuantity, newQuantity, onCloseQtyModal));
        handleQtyModalShow();
    }

    function onCloseQtyModal(e, allowAdj, newQuantity) {
        PreventDefault(e);

        if (allowAdj) {
            //set adjusted qty for min sell qty
            setQuantity(newQuantity);
            UpdatePricesAndTotals(newQuantity, null, null, null);
            updateMessage("Quantity and Totals have been adjusted.", "text-red");
        }
        else {
            //set allow qty override for submit
            setAllowQtyOverride(true);
        }                
        handleQtyModalHide();
    }

    function DisableSubmit(e, cQuantity, cQuantityOverride) {
        PreventDefault(e);
                
        var minQtyRem = DoMinQtyCheck(cQuantity, cQuantityOverride, allowQtyOverride,
            quantity, olData.sellQuantity, olData.sellUnit, isRoll);
        if (minQtyRem !== 0) {
            setSubmitDisabled(true);
            setCloseDisabled(true);
        }
        else {
            setSubmitDisabled(false);
            setCloseDisabled(false);
        }
    }

    function CheckMinQuantity(e) {
        PreventDefault(e);
        setSubmitDisabled(false);
        setCloseDisabled(false);
                
        var minQtyRem = DoMinQtyCheck(null, null, allowQtyOverride, quantity, olData.sellQuantity, olData.sellUnit, isRoll);
        if (minQtyRem !== 0) {
            var newQuantity = GetNewQuantityForMin(olData.sellQuantity, quantity);
            // open min qty notification modal
            onOpenQtyModal(olData.sellQuantity, newQuantity);
            return true;
        }
        return false;
    }

    async function saveOrderLine() {
        var resp = false;

        var model = createEditOlModel(olData);
        setIndividualFields(model);

        var postModel = JSON.stringify(model);        
        const endpoint = 'orders/EditOrderLine';
        try {
            const response = await post(endpoint, postModel);
            //console.log('Response: ' + JSON.stringify(response));

            if (response.value) {
                var respData = response.value;

                if (IsTrue(respData.success)) {
                    const orderLineId = ParseIntSafe(respData.data);
                    if (IdExists(orderLineId)) {
                        resp = true;
                    }
                }
                else {
                    console.error("saveOrderLine api error: " + respData.error);
                    console.error("apiUrl: " + endpoint);
                    resp = respData;
                }
            }
            else {
                console.error("saveOrderLine api error, no response.value: " + response);
                console.error("apiUrl: " + endpoint);
                resp = response;
            }
        } catch (error) {
            console.error("error fetching saveOrderLine: ", error);
            console.error("apiUrl: " + endpoint);
            resp = error;
        }

        return resp;
    }

    function setIndividualFields(model) {
        model.price = linePrice;
        model.units = units;
        model.width = width;
        model.length = length;
        model.quantity = quantity;

        if (!model.cost || model.cost === "") {
            model.cost = 0;
        }

        if (NotExists(promiseDate)) {
            model.promiseDate = null;
        } else {
            model.promiseDate = FormatDateLongInput(promiseDate);
        }
        return model;
    }

    function EnterSave(e) {
        if (e.key === "Enter") {
            PreventDefault(e);
            saveOrNextOrderLine(e, true);
        }
    }

    function setTodaysDate() {
        var dateInp = GetDateNow();
        setPromiseDate(dateInp);
    };

    function customizeLine(e) {
        PreventDefault(e);

        setModalTitle("Edit Custom Line");
        createProductCategoryList();
        createVendorSupplierList();

        olData.isCustom = true;
        setOlData(olData);
    }


    // ON LOAD
    useEffect(() => {
        if (propsType === typeEdit) {
            createStoreList();
            setupOrderLines();
        }
        //initFlowbite();
    }, []);

    return (
        <div className="w-[670px] pb-4 max-w-[100%]">
            {propsType === typeEdit &&
                <div>
                    <div className="min-h-[300px] p-1 pb-0">
                        {olData &&
                            <div>
                                {/*Supplier & Line Info*/}
                                <div className="text-center">
                                    <div className="font-bold mb-1 text-xl">
                                        {modalTitle}
                                        <input id="productId" type="hidden" value={olData.productId}></input>
                                        <input id="productSkuId" type="hidden" value={olData.productSkuId}></input>
                                        <input id="productPriceId" type="hidden" value={olData.productPriceId}></input>
                                    </div>
                                    <div>
                                        {!olData.isCustom &&
                                            <div>
                                                <div>{olData.vendorName}</div>
                                                <div>{olData.style}</div>
                                                <div>{olData.color}</div>
                                            </div>
                                        }
                                    </div>                                    
                                    <div className="float-right h-[35px]">
                                        {IsFalse(olData.isCustom) && (olData.statusId === 0 || olData.statusId === 1) &&
                                            <button type="button" id="" className="btn-submit-modal"
                                                onClick={(e) => customizeLine(e)}>
                                                Customize
                                            </button>
                                        }
                                    </div>                                    
                                </div>

                                {/*OL fields*/}
                                {!showAddLineForm &&
                                    <div className="text-center text-xl py-3 font-small">
                                        <span className="pr-1">Loading...</span>
                                        <span className="loading loading-spinner text-blue-600"></span>
                                    </div>
                                }
                                {showAddLineForm &&
                                    <div className="table clear">
                                        {olData.isCustom &&
                                            <div>
                                                <div>
                                                    <div className="po-col-1">
                                                        Category
                                                    </div>
                                                    <div className="po-col-2">
                                                        {olData.isCustom &&
                                                            <div>
                                                                <select id="productCategoryId" name="productCategoryId"
                                                                    className="sp-sel mark" disabled={customFieldsDisabled}
                                                                    autoFocus={olData.isCustom}
                                                                    onKeyDown={(e) => EnterSave(e)}
                                                                    onChange={handleChange}
                                                                    value={olData.productCategoryId}
                                                                >
                                                                    {categoryList}
                                                                </select>
                                                                <span className={"text-red pl-1 " + requiredClass}>*</span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="po-col-1">
                                                        Supplier
                                                    </div>
                                                    <div className="po-col-2">
                                                        {olData.isCustom &&
                                                            <div>
                                                                <select id="vendorId" name="vendorId"
                                                                    className="sp-sel mark" disabled={customFieldsDisabled}
                                                                    onKeyDown={(e) => EnterSave(e)}
                                                                    value={olData.vendorId}
                                                                    onChange={handleChange}
                                                                >
                                                                    {vendorList}
                                                                </select>
                                                                <span className={"text-red pl-1 " + requiredClass}>*</span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="po-col-1">
                                                        Style
                                                    </div>
                                                    <div className="po-col-2">
                                                        <input type="text" className="mark inp-text-modal"
                                                            id="style" name="style" disabled={customFieldsDisabled}
                                                            onKeyUp={(e) => EnterSave(e)}
                                                            onChange={handleChange}
                                                            value={olData.style}
                                                        />
                                                        <span className={"text-red pl-1 " + requiredClass}>*</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="po-col-1">
                                                        Color
                                                    </div>
                                                    <div className="po-col-2">
                                                        <input type="text" className="mark inp-text-modal"
                                                            id="color" name="color" disabled={customFieldsDisabled}
                                                            onKeyUp={(e) => EnterSave(e)}
                                                            onChange={handleChange}
                                                            value={olData.color}
                                                        />
                                                        <span className={"text-red pl-1 " + requiredClass}>*</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {isRoll && //((width && width > 0) || (length && length > 0)) &&
                                            <div>
                                                <div className="table-cell">
                                                    <div className="po-col-1">
                                                        Width
                                                    </div>
                                                    <div className="po-col-2">
                                                        <input type="number"
                                                            className="mark inp-quantity-modal w-[70px] pl-3 pr-1"
                                                            id="width" name="width"
                                                            onKeyUp={(e) => EnterSave(e)}
                                                            disabled={!olData.isCustom || customFieldsDisabled}
                                                            onChange={handleChange}
                                                            value={width}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="table-cell">
                                                    <div className="po-col-1 !w-[70px] pl-5 pr-1">
                                                        Length
                                                    </div>
                                                    <div className="po-col-2">
                                                        <input type="number"
                                                            className="mark inp-quantity-modal !w-[70px]"
                                                            id="length" name="length" disabled={qtyDisabled}
                                                            onKeyUp={(e) => EnterSave(e)}
                                                            onChange={handleChange}
                                                            value={length}
                                                        />

                                                        {!olData.isCustom && stdLength && stdLength !== "" &&
                                                            <span className="pl-2 text-primary">
                                                                Std Length: {formatCurrencyDecimal(stdLength)} LF
                                                            </span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div>
                                            <div className="po-col-1">
                                                Quantity
                                            </div>
                                            <div className="po-col-2">
                                                <input type="number"
                                                    className="inp-quantity-modal mark w-[70px] pl-3 pr-1"
                                                    id="quantity" name="quantity"
                                                    autoFocus={!olData.isCustom}                                                                                                        
                                                    disabled={qtyDisabled}
                                                    onKeyUp={(e) => EnterSave(e)}
                                                    onBlur={(e) => CheckMinQuantity(e, false, false)}                                                    
                                                    onChange={handleChange}
                                                    value={quantity}                                                    
                                                />
                                                <span className={"text-red pl-1 " + requiredClass}>*</span>

                                                {IsFalse(olData.isCustom) &&
                                                    <span className="pl-1 text-primary">
                                                        {NotStringEmpty(boxQtyStr) ? boxQtyStr : units }
                                                    </span>
                                                }
                                                {IsTrue(olData.isStock) && //Equals(olData.statusId, 15) &&
                                                    <span className="pl-8 ml-[3px] pb-[0px] text-primary">
                                                        <span>Stock Qty: </span>
                                                        {olData.productStockModel.quantityAvailable}
                                                    </span>
                                                }
                                            </div>
                                            {IsTrue(olData.isCustom) &&
                                                <div className="table-cell">
                                                    <div className="table-cell">
                                                        <div className="po-col-1 !w-[50px] pl-5 pr-1">
                                                            Units
                                                        </div>
                                                        <div className="po-col-2">

                                                            <select id="units" name="units"
                                                                className="sp-sel !w-[60px]"
                                                                onKeyDown={(e) => EnterSave(e)}
                                                                value={units}
                                                                onChange={handleChange}
                                                            >
                                                                {unitsList}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="table-cell">
                                                        <div className="table-cell">
                                                            <div className="po-col-1 !w-[60px] pl-6 pr-0">
                                                                Cost
                                                            </div>
                                                            <div className="po-col-2 px-2">
                                                                <span className="sp-cost-pad ml-[-12px] p-0">$</span>
                                                                <input type="number"
                                                                    className="inp-quantity-modal mark !w-[70px]"
                                                                    id="cost" name="cost" disabled={customFieldsDisabled}
                                                                    onKeyUp={(e) => EnterSave(e)}
                                                                    onChange={handleChange}
                                                                    value={olData.cost}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="table-cell">
                                                            <div className="po-col-1 !w-[50px] pl-4 pr-1">
                                                                Multiplier
                                                            </div>
                                                            <div className="po-col-2">
                                                                <input type="number"
                                                                    className="inp-quantity-modal mark !w-[60px]"
                                                                    id="multiplier" name="multiplier" disabled={customFieldsDisabled}
                                                                    onKeyUp={(e) => EnterSave(e)}
                                                                    onChange={handleChange}
                                                                    value={olData.multiplier}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div>
                                            <div className="table-cell">
                                                <div className="po-col-1">
                                                    Price
                                                </div>
                                                <div className="po-col-2">
                                                    <span className="sp-cost-pad ml-[-8px] p-0">$</span>
                                                    <input type="number"
                                                        className="inp-quantity-modal mark w-[70px] pl-3 pr-1"
                                                        id="linePrice" name="linePrice"
                                                        disabled={priceDisabled || customFieldsDisabled}
                                                        onKeyUp={(e) => EnterSave(e)}
                                                        onChange={handleChange}
                                                        value={linePrice}
                                                    />
                                                    <span className="pl-1 font-bold text-primary">{propsPriceLevelStr}</span>
                                                </div>
                                            </div>

                                            <div className="table-cell">
                                                <div className="po-col-1 !w-[80px] pl-8 pr-0">
                                                    Total
                                                </div>
                                                <div className="po-col-2">
                                                    <span className="sp-cost-pad ml-[-12px] p-0">$</span>
                                                    <input type="number" className="inp-quantity-modal mark !w-[70px]"
                                                        id="total" name="total" disabled
                                                        value={formatCurrencyDecimal(lineTotal)}
                                                    />
                                                </div>
                                            </div>                                            

                                            {/** CFM 4-16-24: dont show Referral **/}
                                            {/*{IsCustomProduct &&*/}
                                            {/*    <div className="table-cell">*/}
                                            {/*        <div className="po-col-1 !w-[70px] pl-3 pr-1">*/}
                                            {/*            Referral*/}
                                            {/*        </div>*/}
                                            {/*        <div className="po-col-2">*/}
                                            {/*            <span className="sp-cost-pad ml-[-4px]">$</span>*/}
                                            {/*            <input type="number" className="inp-quantity-modal mark !w-[70px]"*/}
                                            {/*                id="referral" name="referral" disabled*/}
                                            {/*                value={formatCurrencyDecimal(referral)}*/}
                                            {/*            />*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*}*/}
                                        </div>

                                        {IsTrue(olData.isCustom) && NotEmpty(boxQtyStr) &&
                                            <div>
                                                <div className="po-col-1">
                                                    Box Qty
                                                </div>
                                                <div className="po-col-2">
                                                    <span>
                                                        {/*<span className="pl-3">{olData.boxQty}</span>*/}
                                                        <span className="text-primary">{boxQtyStr}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        }

                                        {IsTrue(showPoData) &&
                                            <div>
                                                <div>
                                                    <div className="po-col-1">
                                                        Promise Date
                                                    </div>
                                                    <div className="po-col-2">
                                                        <DatePicker id="promiseDate" name="promiseDate" className="order-date-modal"
                                                            showIcon toggleCalendarOnIconClick
                                                            dateFormat="MM/dd/yyyy"
                                                            disabled={inputsDisabled}
                                                            selected={promiseDate}
                                                            onKeyDown={(e) => KeyHandlerDatePicker(e, null, setTodaysDate, null)}
                                                            onChange={(date) => setPromiseDate(date)}
                                                        />
                                                        <span className={"text-red pl-1 " + requiredClass}>*</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="po-col-1">
                                                        Reference Number
                                                    </div>
                                                    <div className="po-col-2">
                                                        <input type="text" className="inp-quantity-modal mark"
                                                            id="poReferenceNumber" name="poReferenceNumber" disabled={inputsDisabled}
                                                            onKeyUp={(e) => EnterSave(e)}
                                                            onChange={handleChange}
                                                            value={olData.poReferenceNumber}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div>
                                            <div className="po-col-1">
                                                Comments
                                            </div>
                                            <div className="po-col-2">
                                                <input type="text" className="mark inp-text-modal"
                                                    id="comments" name="comments" maxLength="50"
                                                    onKeyDown={(e) => EnterSave(e)}
                                                    onChange={handleChange}
                                                    value={olData.comments}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <div className="po-col-1">
                                                Bin
                                            </div>
                                            <div className="po-col-2">
                                                <input type="text" className="mark inp-text-modal w-[60px]"
                                                    id="bin" name="bin" maxLength="4"
                                                    onKeyUp={(e) => EnterSave(e)}
                                                    onChange={handleChange}
                                                    value={olData.bin}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <div className="po-col-1">
                                                Ship To Location
                                            </div>
                                            <div className="po-col-2">
                                                <div>
                                                    {olData.showReceivedStore !== true &&

                                                        <select id="warehouseId" name="warehouseId" className="sp-sel mark"
                                                            disabled={locationDisabled}
                                                            onKeyDown={(e) => EnterSave(e)}
                                                            onChange={handleChange}
                                                            value={olData.warehouseId}
                                                        >
                                                            {storeList}
                                                        </select>
                                                    }
                                                    {olData.showReceivedStore === true &&
                                                        <span>
                                                            {olData.receivedStoreName}
                                                            <input type="hidden" id="hfWarehouseName"
                                                                value={olData.warehouseName}></input>
                                                        </span>
                                                    }
                                                    {/*<span className={"text-sm text-red-600 " + storeRequiredHidden}>*/}
                                                    {/*    *Store is required*/}
                                                    {/*</span>*/}
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="po-col-1">
                                                Status
                                            </div>
                                            <div className="po-col-2">
                                                <select className="sp-sel mark"
                                                    id="statusId" name="statusId"
                                                    onKeyDown={(e) => EnterSave(e)}
                                                    onChange={handleChange}
                                                    value={olData.statusId}
                                                >
                                                    {statusList}
                                                </select>
                                            </div>
                                        </div>

                                        {GreaterThan(olData.pcWeight, 0) &&
                                            <div>
                                                <div className="table-cell">
                                                    <div className="po-col-1">
                                                        Weight
                                                    </div>
                                                    <div className="po-col-2">
                                                        <span className="text-primary">
                                                            {formatCurrencyDecimal(olData.pcWeight * quantity)}
                                                            <span> lbs</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div>
                                            <div className="po-col-1">
                                                Taken By
                                            </div>
                                            <div className="po-col-2">
                                                {olData.takenBy}
                                            </div>
                                        </div>

                                        {olData.showPurchaseOrderInfo === true &&
                                        //(olData.statusId !== 10 && olData.statusId !== 0 && olData.statusId !== 1) &&
                                            <div>
                                                <div>
                                                    <div className="po-col-1">
                                                        Ordered By
                                                    </div>
                                                    <div className="po-col-2">
                                                        {olData.orderedBy}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="po-col-1">
                                                        Ordered On
                                                    </div>
                                                    <div className="po-col-2">
                                                        {FormatDate(olData.purchaseOrderDateStr)}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="po-col-1">
                                                        Promise Date (ETA)
                                                    </div>
                                                    <div className="po-col-2">
                                                        {FormatDate(olData.promiseDate)}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {olData.showReceivedStore === true &&
                                            <div>
                                                <div className="po-col-1">
                                                    Received On
                                                </div>
                                                <div className="po-col-2">
                                                    {FormatDate(olData.receivedDate)}
                                                </div>
                                            </div>
                                        }
                                        {olData.showDeliveredInfo === true &&
                                            //olData.showReceivedStoreStatuses === true && olData.statusId !== 3 &&
                                            <div>
                                                <div>
                                                    <div className="po-col-1">
                                                        Delivered On
                                                    </div>
                                                    <div className="po-col-2">
                                                        {FormatDate(olData.deliveredDate)}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="po-col-1">
                                                        Picked Up By
                                                    </div>
                                                    <div className="po-col-2">
                                                        {olData.pickupPrintedName}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {olData.showClosedInfo === true &&
                                            <div>
                                                <div className="po-col-1">
                                                    Closed On
                                                </div>
                                                <div className="po-col-2">
                                                    {FormatDate(olData.closedDate)}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </div>

                    <div className="align-bottom text-right">
                        <div className={"text-center pt-1 mx-1 min-h-[45px] " + messageClass}>
                            <div>{message}</div>
                            <div>{poQtyUpdateMsg}</div>
                        </div>
                        
                        <button type="button" className="btn-submit-modal mr-2"
                            disabled={submitDisabled || saveButtonDisabled}
                            onClick={(e) => saveOrNextOrderLine(e, true)}>
                            Save
                        </button>                        
                        <button type="button" className="btn-submit-modal mr-2"
                            disabled={skipButtonDisabled}
                            onClick={(e) => saveOrNextOrderLine(e, false)}>
                            Skip
                        </button>                        
                        <button type="button" className="btn-close-modal"
                            disabled={closeDisabled}
                            onClick={(e) => onCloseEditLineModal(e)}>
                            Close
                        </button>
                    </div>
                </div>
            }

            {/**** Delete Lines ****/}
            {propsType === typeDelete &&
                <div className="text-center pb-1">
                    <div className="text-red text-[16px] py-4">
                        {deleteConfirmMsg}
                    </div>
                    <div className="">
                        <button type="button" className="btn-submit-modal mr-2"
                            autoFocus
                            onClick={(e) => onCloseDeleteLineModal(e, true)}>
                            Delete Line(s)
                        </button>
                        <button type="button" className="btn-close-modal"
                            onClick={(e) => onCloseDeleteLineModal(e, false)}>
                            Cancel
                        </button>
                    </div>
                </div>
            }

            {/**** Min Quantity Modal ****/}
            <div className="">
                <Modal ref={qtyModal} className="px-3">
                    <Modal.Body>
                        {qtyModalContent}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
}
