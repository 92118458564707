import React, { useState, useEffect } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { Exists, GetDisplayDateStr } from '../../../js/formHelpers';

export const CheckPaymentList = ({ checkPayments, loading }) => {

    const [gridHeight, setGridHeight] = useState('400px');

    const columnDefs = [
        {
            headerName: 'Check #', field: 'checkNumber', maxWidth: 150,
        },
        {
            headerName: 'Check Date', field: 'checkDate',
            valueFormatter: params => GetDisplayDateStr(params.value)
        },
        {
            headerName: 'Payee', field: 'payee',            
        },
        {
            headerName: 'Check Amount', field: 'checkAmount',
            valueFormatter: params => "$" + params.value.toFixed(2),
        },
        { headerName: 'Notes', field: 'notes',  },
        { headerName: 'Status', field: 'status', maxWidth: 150, },
    ];

    const gridOptions = {
        defaultColDef: {
            minWidth: 100,
            flex: 1,
            sortable: true,
            resizable: true,
            filter: true,
            suppressMovable: true,
            suppressMenu: false,
            cellClass: ["no-border"],
            menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab",],
        }
    };

    useEffect(() => {
        const updateGridHeight = () => {
            const windowHeight = window.innerHeight;
            const offsetTop = document.querySelector('.ag-theme-alpine')?.offsetTop || 0;
            const newHeight = windowHeight - offsetTop - 20; // 20px for some bottom margin
            setGridHeight(`${newHeight}px`);
        };

        updateGridHeight();
        window.addEventListener('resize', updateGridHeight);
        return () => window.removeEventListener('resize', updateGridHeight);

    }, []);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen pt-[10%]">
                <span className="loading loading-spinner loading-lg"></span>
            </div>
        );
    }

    return (
        <div className="ag-theme-alpine" style={{ height: gridHeight, width: '100%' }}>
            <AgGridReact
                columnDefs={columnDefs}
                rowData={checkPayments}
                gridOptions={gridOptions}
                pagination={true}
                paginationPageSize={30}
                suppressCellSelection={true}
                suppressRowHoverHighlight={true}
            />
        </div>

        //<>
        //    {loading ? (
        //        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        //            <Spinner animation="border" />
        //        </div>
        //    ) : (
        //        <>
        //            <DataTable
        //                columns={columns}
        //                data={checkPayments}
        //                pagination
        //                highlightOnHover
        //                responsive
        //                customStyles={customStyles}
        //            />
        //        </>
        //    )}
        //</>
    );
}
