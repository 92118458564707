import React, { useState } from 'react';

import { Modal, Button } from 'react-daisyui'

export function BatchPreferencesForm({ bankAccounts, handleBatchPreferences }) {

    const [bankAccount, setBankAccount] = useState({});
    const [checkNumber, setCheckNumber] = useState(1);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'bankAccount') {
            setBankAccount(bankAccounts.find(bank => bank.bankId === parseInt(value)) ?? {});
        }
        else if (name === 'checkNumber') {
            setCheckNumber(value);
        }
    };

    function handleContinue() {
        //const handleSubmit = async (e) => {
        //e.preventDefault();

        if (bankAccount.bankId == null) {
            alert('You must select a Bank account');
        }
        else {
            handleBatchPreferences(bankAccount, checkNumber);
        }
        //};
    }

    return (
        <div>
            {/*<form onSubmit={handleSubmit}>*/}
            <div className="w-full pt-4">
                <div>
                    <label className="label table-cell label-text text-base font-bold w-[200px]">Starting Check Number:</label>
                    <div className="table-cell">
                        <input type="text" name="checkNumber"
                            className="input input-bordered border-black h-8 py-4 px-2 w-[100px] opacity-75"
                            value={checkNumber}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="pt-4">
                    <label className="label table-cell label-text text-base font-bold w-[200px]">Bank Account:</label>
                    <div className="table-cell">
                        <select name="bankAccount" className="input-bordered border rounded-lg"
                            value={bankAccount.bankId} onChange={handleChange}>
                            <option>Select...</option>
                            {bankAccounts.map(bankAccount =>                                
                                <option value={bankAccount.bankId}>{`${bankAccount.bankName} - ${bankAccount.bankAccountName} - ${bankAccount.bankAccountNumber}`}</option>)}
                        </select>
                    </div>
                </div>
            </div>
            <div className="pt-8 text-right">
                <Button className='btn-submit !mr-0' type="button" onClick={e => handleContinue()}>Continue</Button>
            </div>
            {/*</form>*/}
        </div>
    );
}