import React from 'react';
//import { useNavigate } from 'react-router-dom'

export const OrderNumberCellRenderer = (props) => {
    //const navigate = useNavigate();

    const order = props.data ? props.data : null;
    const orderId = order ? order.orderId : 0;

    const isOrder = order.isOrderType ?? false;
    const isQuote = order.isQuoteType ?? false;
    const isClaim = order.isClaimType ?? false;
    const isInventory = order.isInventoryType ?? false;

    //order url
    var linkId = "lnkOrderId-" + orderId;
    var url = "/Redirect?route=EditOrder&orderId=" + orderId;
    if (isQuote) {
        url = "/Redirect?route=EditQuote&orderId=" + orderId;
    }
    else if (isClaim) {
        url = "/Redirect?route=EditClaim&orderId=" + orderId;
    }
    else if (isInventory) {
        url = "/Redirect?route=EditInventoryOrder&orderId=" + orderId;
    }
    
    //function orderDetailsClick() {
    //    navigate('/Order', { replace: true, state: { orderId: orderId } })
    //}

    return (
        <div className="order-cell-div">
            <div>
                <a id={linkId} href={url} //target="_blank" rel="noreferrer"
                    className="text-secondary order-cell-link pointer-events-none">
                    {order.orderNumber}
                </a>
                <input type="hidden" value={orderId}></input>
            </div>
            <div className="order-cell-content hidden">
                <span className="order-cell-title">Status: </span>
                <span>{order.statusStr}</span>
            </div>
        </div>
    );
};
