import React, { useState, useEffect } from 'react';

import { defaultErrMsg, Exists, handleFieldChange, IdExists } from '../../js/formHelpers';
import { get, post } from '../../services/apiService';

import { EntityAutocomplete } from '../_reactHelpers/EntityAutocomplete';

export function DepositForm({ depositData, onClose, onUpdateDeposit, onAddDeposit }) {

    var data = Exists(depositData) && IdExists(depositData.depositRegisterId) ?
        {
            depositRegisterId: depositData.depositRegisterId,
            storeId: depositData.storeId,
            registerName: depositData.registerName,
            registerType: depositData.registerType,
            chartAccountId: depositData.chartAccountId
        } :
        {
            depositRegisterId: 0,
            storeId: 0,
            registerName: "",
            registerType: "",
            chartAccountId: 0
        };

    const [formData, setFormData] = useState(data);
    const [stores, setStores] = useState([]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchStores = async () => {
        try {
            const response = await get("/store/GetAllStores");
            //console.log('Fetched stores:', response);

            if (response && Array.isArray(response.$values)) {
                setStores(response.$values.filter(store => !store.deleted));
            } else if (Array.isArray(response)) {
                setStores(response.filter(store => !store.deleted));
            } else {
                console.error('Unexpected store data format:', response);
                setError('Unexpected store data format. Please try again.');
            }
        } catch (error) {
            console.error('Error fetching stores', error);
            setError('Failed to load stores. Please try again.');
        } finally {
        }
    };

    const handleSelectGlAccount = (selectedOption) => {
        setFormData(prevData => ({
            ...prevData,
            chartAccountId: selectedOption.chartAccountId,
        }));
    };

    const handleChange = (e) => {
        var data = handleFieldChange(e);
        if (data) {

            //let updatedValue = value;
            //if (name === 'storeId') {
            //    updatedValue = value === '' ? '' : parseInt(value, 10);
            //}

            setFormData(prev => ({
                ...prev,
                [data.name]: data.value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        try {

            if (!IdExists(formData.chartAccountId) || !IdExists(formData.storeId)) {
                setError("All fields are required.");
                return;
            }

            //console.log('Submitting form data:', formData);
            if (IdExists(formData.depositRegisterId)) {
                const response = await post(`/depositregisters/UpdateDepositRegister`, formData);
                onUpdateDeposit(response.data);
            }
            else {
                const response = await post('/depositregisters/CreateDepositRegister', formData);
                onAddDeposit(response.data);
            }
            onClose();

        } catch (err) {
            setError(defaultErrMsg());
            console.error('Error updating deposit register:', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchStores();
    }, []);

    return (
        <div>
            <h2 className="text-2xl font-bold text-center">
                {IdExists(formData.depositRegisterId) ? "Update" : "Create"} Deposit Register
            </h2>
            <div className="mt-2 h-[24px] text-red-500  text-center">{error}</div>

            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="storeId" className="label-form">Store:</label>
                    <select id="storeId" name="storeId" className="label-form-input"
                        value={formData.storeId}
                        onChange={handleChange}
                        required                        
                    >
                        <option value={0}>-- Select --</option>
                        {stores.map(store => (
                            <option key={store.storeId} value={store.storeId}>
                                #{store.storeNumber} - {store.name || store.displayName}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="registerName" className="label-form">Register Name:</label>
                    <input
                        type="text"
                        id="registerName"
                        name="registerName"
                        value={formData.registerName}
                        onChange={handleChange}
                        required
                        className="label-form-input"
                    />
                </div>
                <div>
                    <label htmlFor="registerType" className="label-form">Register Type:</label>
                    <select
                        id="registerType"
                        name="registerType"
                        value={formData.registerType}
                        onChange={handleChange}
                        required
                        className="label-form-input"
                    >
                        <option value="Cash/Check">Cash/Check</option>
                        <option value="MC/Visa">MC/Visa</option>
                        <option value="AMEX">AMEX</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="registerType" className="label-form">GL Account:</label>
                    <EntityAutocomplete name="chartAccountId" id="chartAccountId"
                        onSelect={handleSelectGlAccount} onChange={handleChange}
                        initialEntityId={formData.chartAccountId}
                        entityType="GL Account"
                        required
                    />
                </div>
                <div className="flex justify-end space-x-2 mt-4">
                    <button type="button" onClick={onClose} className="btn-cancel">Cancel</button>
                    <button type="submit" className="btn-submit">Save</button>
                </div>
            </form>
        </div>
    );
}