import axios from 'axios';
import { Exists, IsTrue } from '../js/formHelpers.js';
import authService from '../services/authService.js';

// Create new instance
const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

/**** HELPERS ****/

function GetSuccessOrFailedResponse(response) {
    if (Exists(response.data)) {
        if (Exists(response.data.value)) {
            // return response from BaseController Success/Failed Result
            // modify seprately if needed
            return response.data;
        }
        else {
            // return response for others
            // modify seprately if needed
            return response.data;
        }
    }
    else {
        return null;
    }
}

function GetSuccessOrFailedResponseData(response) {

    var data = GetSuccessOrFailedResponse(response);
    if (Exists(data)) {
        // return data from BaseController Success/Failed Result
        if (Exists(data.value)) {
            if (Exists(data.value.success)) {
                if (IsTrue(data.value.success)) {
                    return data.value.data;
                }
                else {
                    return data.value.error;
                }
            }
            else {
                return data.value;
            }
        }
        else {
            return data;
        }
    }
    else {
        return null;
    }
}

// GET request
export const get = async (url, config = {}) => {
    try {
        // causing infinite loop when no AD
        // getCurrentUser() calls getADUser() calls get()
        //const userData = authService.getCurrentUser();

        var userData = JSON.parse(localStorage.getItem('userData'));
        if (userData) {
            config.headers = {};
            const userId = userData.userId;
            const role = userData.role;
            
            config.headers['X-UserId'] = userId || 0;
            config.headers['X-Role'] = role || '';
            //console.log('Adding X-UserId = ' + userId + ' to header');  // Could be used in the future
            //console.log('Adding X-Role = ' + role + ' to header');
        } else {
            console.log('No userData');
        }

        const response = await http.get(url, config);
        if (Exists(response) && response.status >= 200 && response.status < 300) {
            //console.log('apiService GET response: ' + JSON.stringify(response));                        
            var rData = GetSuccessOrFailedResponse(response);
            return rData;
        }
        else {            
            var errorData = GetSuccessOrFailedResponse(response);
            //console.log('apiService GET error: ' + JSON.stringify(response));
            throw new Error(`apiService GET failed with response: ${JSON.stringify(response)}. Error: ${errorData}.`);
        }
    } catch (error) {
        console.log('apiService GET error: ' + error);
        throw error.response?.data || error.message;
    }
};

// POST request
export const post = async (url, data, config = {}) => {
    try {
        // causing infinite loop when no AD
        // getCurrentUser() calls getADUser() calls get()
        //const userData = authService.getCurrentUser();
        
        var userData = JSON.parse(localStorage.getItem('userData'));
        if (userData) {
            config.headers = {};

            const userId = userData.userId;
            console.log('POST userId: ' + userId);

            config.headers['X-UserId'] = userId || 0;
            console.log('Adding X-UserId = ' + userId + ' to header');
        } else {
            console.log('No userData');
        }

        const response = await http.post(url, data, config);
        if (Exists(response) && response.status >= 200 && response.status < 300) {
            //console.log('apiService POST response: ' + JSON.stringify(response));
            var rData = GetSuccessOrFailedResponse(response);
            return rData;
        }
        else {
            var errorData = GetSuccessOrFailedResponse(response);
            //console.log('apiService POST error: ' + JSON.stringify(response));
            throw new Error(`apiService POST failed with response: ${JSON.stringify(response)}. Error: ${errorData}.`);
        }
    } catch (error) {
        console.log('apiService POST error: ' + error);
        throw error.response?.data || error.message;
    }
};

export { http };