import React, { useState, useImperativeHandle, useEffect, memo } from 'react';
import { ClickById } from '../../../js/helpers';
import { get } from '../../../services/apiService';

export const OrderLinesCellRenderer = (props, ref) => {
    //export default React.forwardRef = (props, ref) => {
    //export default forwardRef = (props, ref) => {

    const order = props.data ? props.data : null;
    const orderId = order ? order.orderId : 0;
    const panelId = "orderDetailsPanel-" + orderId;
    //const orderLines = order ? order.orderLines : null;

    const [dbOrderLines, setDbOrderLines] = useState(null);
    const [rowsLoaded, setRowsLoaded] = useState(false);

    //useImperativeHandle(ref, () => {
    //    return {
    //        renderLines(run) {
    //            if (run === true) {
    //                fetchOrderLines();
    //            }

    //            //todo: if needed - performance upgrade
    //            //get all lines at once and send in for orderId
    //            //if (orderLines) {
    //            //    setDbOrderLines(orderLines);
    //            //    setRowsLoaded(true);
    //            //}
    //        }
    //    }
    //});

    function loadOrderDetails() {
        fetchOrderLines();
    }

    async function fetchOrderLines() {
        if (orderId && orderId > 0) {
            const endpoint = 'orders/GetOrder/' + orderId + '/0/0';
            try {
                const response = await get(endpoint);
                //console.log('Response: ' + JSON.stringify(response));

                if (response.orderLines && response.orderLines.length > 0) {
                    setDbOrderLines(response.orderLines);
                    setRowsLoaded(true);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    }

    useEffect(() => {
        props.node.parent.setSelected(true);
        loadOrderDetails();
    }, []);



    return (
        //<div onClick={onLoadOrderDetails} className="cursor-pointer">
        //    <div className="table-cell orders-details-title underline">
        //        Details
        //    </div>
        //</div>

        <div>
            {/*<div id={panelId} onClick={loadOrderDetails} className="cursor-pointer">*/}
            {/*<details open> for open, <details> for closed*/}
            {/*<details className="collapse cursor-pointer order-lines-collapse">*/}
            {/*    <summary className="collapse-title order-collapse-title">*/}
            {/*        <div className="table-cell orders-details-title underline">      */}
            {/*            Details*/}
            {/*        </div>*/}
            {/*        */}{/*<div id={"lineIconClosed-" + orderId} className="line-icon-expand">*/}
            {/*        */}{/*    <svg className="line-icon-expand-svg1 w-3 h-3 shrink-0" data-accordion-icon aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">*/}
            {/*        */}{/*        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />*/}
            {/*        */}{/*    </svg>*/}
            {/*        */}{/*</div>*/}
            {/*        */}{/*<div onClick={loadOrderDetails} id={"lineIconOpen-" + orderId}*/}
            {/*        */}{/*    className="line-icon-expand">*/}
            {/*        */}{/*    <svg className="line-icon-expand-svg2 w-3 h-3 rotate-180 shrink-0" data-accordion-icon aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">*/}
            {/*        */}{/*        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />*/}
            {/*        */}{/*    </svg>*/}
            {/*        */}{/*</div>*/}
            {/*    </summary>*/}
            {/*<div className="collapse-content order-collapse-content">*/}
            {/*{rowsLoaded === true && dbOrderLines &&*/}

            {/*    dbOrderLines.map(ol =>*/}
            {/*        <div className="orders-orderLine-row"*/}
            {/*            id={orderId + "-" + ol.orderLineId}*/}
            {/*            key={ol.orderLineId}>*/}

            {/*            <div className="orders-orderLine-content">*/}
            {/*                <div className="text-primary">*/}
            {/*                    {ol.lineNumberStr}*/}
            {/*                    <span>. </span>*/}
            {/*                    {ol.style}*/}
            {/*                    {ol.color !== "" &&*/}
            {/*                        <span> - {ol.color}</span>*/}
            {/*                    }*/}
            {/*                </div>                                    */}
            {/*                <div>*/}
            {/*                    <div className="status-cell">*/}
            {/*                        <span>{ol.productCategoryName}</span>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="font-normal">*/}
            {/*                    {ol.quantity !== "" &&*/}
            {/*                        <span className="pr-1">*/}
            {/*                            <span className="">Qty: </span>*/}
            {/*                            {ol.quantity}*/}
            {/*                        </span>                                            */}
            {/*                    }*/}
            {/*                    {ol.unitsStr !== "" &&*/}
            {/*                        <span>{ol.unitsStr}</span>*/}
            {/*                    }*/}
            {/*                </div>*/}
            {/*                <div className="">*/}
            {/*                    {ol.statusId !== 0 &&*/}
            {/*                        <div>*/}
            {/*                            <span className>{ol.statusStr}</span>   */}
            {/*                            {ol.statusDateStr !== "" &&*/}
            {/*                                <span>*/}
            {/*                                    {ol.statusId !== 2 &&*/}
            {/*                                        <span>: </span>*/}
            {/*                                    }*/}
            {/*                                    {ol.statusId === 2 &&*/}
            {/*                                        <span> </span>*/}
            {/*                                    }*/}
            {/*                                    <span className="date-cell">*/}
            {/*                                        <span>{ol.statusDateStr}</span>*/}
            {/*                                    </span>*/}
            {/*                                </span>*/}
            {/*                            }*/}
            {/*                        </div>*/}
            {/*                    }*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    )*/}
            {/*}*/}
            {/*        </div>*/}
            {/*    </details>*/}

            <div className="ml-[175px] mr-[200px] pb-8">
                <table className="order-search-details-table">
                    {/*<tr className="">*/}
                    {/*    <th>#</th>*/}
                    {/*    <th>Style / Color</th>*/}
                    {/*    <th>Category</th>*/}
                    {/*    <th>Qty / Units</th>*/}
                    {/*    <th>Vendor</th>*/}
                    {/*    <th>Status</th>*/}
                    {/*</tr>*/}
                    {dbOrderLines && dbOrderLines.map(ol =>
                        <tr className="cursor-pointer"
                        //onDoubleClick={e => OpenOrder(e, orderLinkId)}
                        >
                            <td className="w-[50px] hidden">
                                <div className="text-primary font-bold">
                                    {ol.lineNumberStr}
                                    <span>.</span>
                                </div>
                            </td>
                            <td className="min-w-[700px] pr-4">
                                <div className="text-primary font-bold">
                                    {ol.style}
                                    {ol.color !== "" &&
                                        <span> - {ol.color}</span>
                                    }
                                </div>
                            </td>
                            <td className="min-w-[175px]">
                                <div className="status-cell">
                                    <span>{ol.productCategoryName}</span>
                                </div>
                            </td>
                            <td className="min-w-[175px]">
                                <div className="font-normal text-primary">
                                    {ol.quantity !== "" &&
                                        <span className="pr-1">
                                            <span>Qty: </span>
                                            {ol.quantity}
                                        </span>
                                    }
                                    {ol.unitsStr !== "" &&
                                        <span>{ol.unitsStr}</span>
                                    }
                                </div>
                            </td>
                            <td className="min-w-[200px]">
                                <div>{ol.vendorName}</div>
                            </td>
                            <td className="min-w-[175px]">
                                <div>
                                    {ol.statusId !== 0 &&
                                        <div>
                                            <span>{ol.statusStr}</span>
                                            {ol.statusDateStr !== "" &&
                                                <span>
                                                    {ol.statusId !== 2 &&
                                                        <span>: </span>
                                                    }
                                                    {ol.statusId === 2 &&
                                                        <span> </span>
                                                    }
                                                    <span className="date-cell text-primary">
                                                        <span>{ol.statusDateStr}</span>
                                                    </span>
                                                </span>
                                            }
                                        </div>
                                    }
                                </div>
                            </td>
                        </tr>
                    )}
                </table>
            </div>
        </div>
    );
};
//export default React.forwardRef(OrderLinesCellRenderer);
export default memo(OrderLinesCellRenderer);