import React, { useState } from 'react';
import { Component } from 'react';
import { AccountsGrid } from './AccountsGrid';

export class AccountsSearch extends Component {
  static displayName = AccountsSearch.name;

  constructor() {
    super();
    this.state = { data: [], loading: true };
  }

  render() {
    return (
      <div>
        <AccountsGrid></AccountsGrid>
      </div>
    );
  }
}
