import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react';
import { Modal } from "flowbite-react"
import { Tabs, TabPanel } from "../_reactHelpers/Tabs";
import { ImportNameRenderer } from './cellRenderers/_importNameCellRenderer.js';
import { ImportAddressRenderer } from './cellRenderers/_importAddressCellRenderer.js';
import {
  handleFieldChange, isSelectedDropdown, isValidDate,
  GetStoreSelectList, AddInputChangeListener
} from '../../js/formHelpers.js';
import { Dropdown } from './_ediImportsHelpers.js';
import { get, post } from '../../services/apiService.js';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import '../../css/ag-theme-lumina.css'; // local ag-theme-alpine.css

import { SetPageTitle } from '../../js/helpers.js';
import { SetDocTitle } from "../_reactHelpers/ReactHelpers";
import { DecimalFormatter, DateFormatter, DollarDecimalFormatter } from '../_reactHelpers/Formatters';


export function EdiImportsGrid() {
  const navigate = useNavigate();
  const location = useLocation();

  //loading=false, use grid loading message
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isMatchdSku, setIsMatchedSku] = useState(null);
  const [importMessage, setImportMessage] = useState(getImportMessage());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('tab1');
  const [isColorModalOpen, setIsColorModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedNodeData, setSelectedNodeData] = useState(null);
  const [productCodes, setProductCodes] = useState(null);
  const [storeData, setStoreData] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedProductCode, setSelectedProductCode] = useState(null);
  const gridRef = useRef();
  const costGridRef = useRef();
  const colorGridRef = useRef();

  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const [importData, setImportData] = useState([]);
  const [costData, setCostData] = useState([]);
  const [colorData, setColorData] = useState([]);

  // PANEL 1 - IMPORTS
  const importColumnDefs = [
    {
      headerName: "Select", maxWidth: 130,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      headerCheckboxSelectionFilteredOnly: true,
    },
    {
      headerName: 'ID', field: 'id', hide: true,
    },
    {
      headerName: 'PC', field: 'productCode', width: 70, autoHeight: true, cellClass: ["text-wrap"], hide: true,
    },
    {
      headerName: "Style", field: "styleName", width: 400, autoHeight: true, cellClass: ["text-wrap"],
    },
    {
      headerName: "Style Number", field: "styleNumber", width: 200, autoHeight: true, cellClass: ["text-wrap"],
    },
    {
      headerName: "Vendor", field: "supplier", width: 200, autoHeight: true, cellClass: ["text-wrap"],
    },
    {
      headerName: "Manufacturer", field: "manufacturer", width: 200, autoHeight: true, cellClass: ["text-wrap"],
    },
    {
      headerName: "Standard Dimensions", field: "dimensions", width: 200, autoHeight: true, cellClass: ["text-wrap"],
    },
    {
      headerName: "Effective Date",
      field: "effectiveDate",
      width: 200,
      autoHeight: true,
      cellClass: ["text-wrap"],
      valueFormatter: (params) => DateFormatter(params.value),
    },
    {
      headerName: "Dropped Date",
      field: "droppedDate",
      width: 200,
      autoHeight: true,
      cellClass: ["text-wrap"],
      valueFormatter: (params) => DateFormatter(params.value),
    },
    {
      headerName: "File Date",
      field: "fileDate",
      width: 200,
      autoHeight: true,
      cellClass: ["text-wrap"],
      valueFormatter: (params) => DateFormatter(params.value),
    },
  ];

  // PANEL 2 - COST
  const costColumnDefs = [
    {
      headerName: 'ID', field: 'id', hide: true,
    },
    {
      headerName: "Code", field: "costCode", width: 10, autoHeight: true, cellClass: ["text-wrap"],
    },
    {
      headerName: "Cost", field: "cost", width: 200, autoHeight: true, cellClass: ["text-wrap"], valueFormatter: DollarDecimalFormatter,
    },
    {
      headerName: "Qty", field: "quantity", width: 200, autoHeight: true, cellClass: ["text-wrap"],
    },
    {
      headerName: "Unit Code", field: "unitCode", width: 200, autoHeight: true, cellClass: ["text-wrap"],
    },
    {
      headerName: "Effective Date", field: "effectiveDate", width: 200, autoHeight: true, cellClass: ["text-wrap"], valueFormatter: (params) => DateFormatter(params.value),
    },
    {
      headerName: "Dropped Date", field: "droppedDate", width: 200, autoHeight: true, cellClass: ["text-wrap"], valueFormatter: (params) => DateFormatter(params.value),
    },
    {
      headerName: "Start Date", field: "promoStartDate", width: 200, autoHeight: true, cellClass: ["text-wrap"], valueFormatter: (params) => DateFormatter(params.value),
    },
    {
      headerName: "End Date", field: "promoEndData", width: 200, autoHeight: true, cellClass: ["text-wrap"], valueFormatter: (params) => DateFormatter(params.value),
    },
    {
      headerName: "FOB", field: "fob", width: 200, autoHeight: true, cellClass: ["text-wrap"],
    },
  ];

  // PANEL 3 - COLOR
  const colorColumnDefs = [
    {
      headerName: 'ID', field: 'id', hide: true,
    },
    {
      headerName: "Color Name", field: "colorName", width: 200, autoHeight: true, cellClass: ["text-wrap"],
    },
    {
      headerName: "Color Number", field: "colorNumber", width: 200, autoHeight: true, cellClass: ["text-wrap"],
    },
    {
      headerName: "SKU", field: "sku", width: 200, autoHeight: true, cellClass: ["text-wrap"],
    },
    {
      headerName: "Manufacturer SKU", field: "manufacturerSKU", width: 200, autoHeight: true, cellClass: ["text-wrap"],
    },
    {
      headerName: "Effective Date", field: "effectiveDate", width: 200, autoHeight: true, cellClass: ["text-wrap"], valueFormatter: (params) => DateFormatter(params.value),
    },
    {
      headerName: "Dropped Date", field: "droppedDate", width: 200, autoHeight: true, cellClass: ["text-wrap"], valueFormatter: (params) => DateFormatter(params.value),
    },
    {
      headerName: "Selling/Buy Qty", field: "sellBuyQty", width: 200, autoHeight: true, cellClass: ["text-wrap"],
    },
  ];

  const importGridOptions = {
    getRowClass: params => {
      if (params.data.matchedSku) {
        params.node.setSelected(true);
      }
    },
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      suppressMovable: true,
      cellClass: ["no-border"]
    },
  };

  const costGridOptions = {
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      suppressMovable: true,
      cellClass: ["no-border"]
    }
  };

  const colorGridOptions = {
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      suppressMovable: true,
      cellClass: ["no-border"]
    }
  };

  // STORE DATA
async function loadStoreData() {
    var endpoint = "store";

    try {
        const response = await get(endpoint);
        console.log('Response: ' + JSON.stringify(response));

        setStoreData(response);
    }
    catch (error) {
        console.log('Store Error: ' + error)
    }
}

  // PRODUCT CODES
async function loadProductCodes() {
    var endpoint = 'product/getpccodes';
    try {
        const response = await get(endpoint);
        console.log('Response: ' + JSON.stringify(response));

        setProductCodes(response);
    }
    catch (error) {
        console.log('PC Error: ' + error)
    }
}

  // SKU
  async function matchSku() {
    loadImportData(true);
    gridRef.current.api.refreshCells();
    
  }

  // SAVE COLOR EDI
    async function saveColorDetails() {
        var endpoint = 'ediImports/saveColor';
        try {
            var response = await post(endpoint, JSON.stringify(selectedRowData));
            setSelectedRowData(response);
            updateGridRowData(response);
        } catch (error) {
            console.log('Error: ' + error);
        }
        setIsColorModalOpen(false);
    }

  // SAVE EDI
async function saveDetails() {
    const endpoint = 'ediImports/saveEdi';
    try {
        const response = await post(endpoint, JSON.stringify(selectedRowData));
        console.log('Response: ' + JSON.stringify(response));

        setSelectedRowData(response);
        updateGridRowData(response);
        gridRef.current.api.refreshCells();
    } catch (error) {
        console.log('Error: ' + error);
    }

    setIsModalOpen(false);
    setIsColorModalOpen(false);
}

  // DELETE EDI AND RELOAD (FOR TESTING)
async function deleteAndReload() {
    showLoading();
    console.log(matchSku);
    const endpoint = 'ediImports/deleteAll';
    try {
        const response = await get(endpoint);
        console.log('Response: ' + JSON.stringify(response));

        if (response && response.length === 0) {
            showNoRows();
        } else {
            hideOverlay();
        }
        setImportData(response);
    } catch (error) {
        console.log('Error: ' + error);
    }
};

  // LOAD EDI DATA
async function loadImportData(matchSku) {
    showLoading();
    console.log(matchSku);
    var endpoint = 'ediImposts';
    console.log('API URL: ' + endpoint);

    if (matchSku) {
        endpoint = endpoint + '/matchsku';
        gridRef.current.api.deselectAll();
    }

    try {
        const response = await get(endpoint);
        console.log('Response: ' + JSON.stringify(response));

        if (response && response.length === 0) {
            showNoRows();
        } else {
            hideOverlay();
        }
        setImportData(response);
    } catch (error) {
        console.log('Error: ' + error);
    }
};

  const onGridReady = useCallback((params) => {
    console.log('onGridReady Start');
    //debugger;

    loadImportData(false);
    loadProductCodes();
    loadStoreData();
  }, []);

  //const importSelected = async (event) => {
  async function importSelected() {
    showLoading();

    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    const data = selectedData;
    //console.log("Selected: " + JSON.stringify(data));

      const endpoint = 'ediImports/import';
      try {
        removeSelected();
        const response = await post(endpoint, JSON.stringify(data));
        console.log('Response: ' + JSON.stringify(response));
        hideOverlay();
        updateGridRowData(response);
    } catch (error) {
        hideOverlay();
        console.error('Failed to post data:', error);
    }
  };

  function removeSelected() {
    var selectedRowData = gridRef.current.api.getSelectedRows();
    gridRef.current.api.deselectAll();
    gridRef.current.api.applyTransaction({ remove: selectedRowData });
    //gridRef.current.api.updateRowData({ remove: selectedRowData });
  };

  function handleSearch(e) {
   // console.log('handleSearch Start');
    const value = e.target.value.toLowerCase();
    gridRef.current.api.setQuickFilter(value);
  };

  const handleProductCodeChange = (event) => {
    selectedRowData.productCode = event.target.value;
    setSelectedProductCode(event.target.value);
  };

  const handleStoreChange = (event) => {
    selectedRowData.storeNumber = event.target.value;
    console.log("store change: " + event.target.name + " - " + event.target.value);
    setSelectedStore(event.target.value);
  };

  function showLoading() {
    gridRef.current.api.showLoadingOverlay();
  }

  function showNoRows() {
    gridRef.current.api.showNoRowsOverlay();
  }

  function hideOverlay() {
    gridRef.current.api.hideOverlay();
  }

  function getImportMessage() {
    var message = "";

    if (location.state && location.state.vendorStatus) {
      var status = location.state.vendorStatus;
      if (status === "update") {
        message = "EDI Imported Successfully.";
      }
    }
    return message;
  }

  const updateGridRowData = (updatedData) => {
    hideOverlay();

    if (selectedNodeData) {
      selectedNodeData.setData(updatedData);
      gridRef.current.api.refreshCells({ rowNodes: [selectedNodeData] });
    }

    gridRef.current.api.updateRowData({ update: updatedData });
    //console.log('Updated: ' + JSON.stringify(updatedData));
  };

  const onSelectionChanged = useCallback((event) => {
    const selectedNode = gridRef.current.api.getSelectedNodes()[0];
    if (selectedNode) {
      const costsDetails = selectedNode.data.costs;
      //console.log("COSTS: " + JSON.stringify(costsDetails));
      setCostData(costsDetails);

      const colorsDetails = selectedNode.data.colors;
      //console.log("COLORS: " + JSON.stringify(colorsDetails));
      setColorData(colorsDetails);
    }
  }, []);

  const onRowDoubleClicked = (event) => {
    setSelectedNodeData(event.node);
    setSelectedRowData(event.data);
    setIsModalOpen(true);
  };

  const onColorRowDoubleClicked = (event) => {
    setSelectedNodeData(event.node);
    setSelectedRowData(event.data);
    //console.log('COLOR: ' + JSON.stringify(event.data));

    setIsColorModalOpen(true);
  };

  const deleteSelected = (event) => {
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    gridRef.current.api.applyTransaction({ remove: selectedData });
  }

  const resetAndReload = (event) => {
    setIsMatchedSku(false);
    gridRef.current.api.deselectAll();
    loadImportData(false);
  }

  const reimport = (event) => {
    setIsMatchedSku(false);
    gridRef.current.api.deselectAll();

    deleteAndReload();
  }

  const handleChange = (e) => {
    //console.log('change: ' + e.target.name)
    const { name, value } = e.target;
    setSelectedRowData(selectedRowData => ({
      ...selectedRowData,
      [name]: value,
    }));
  };

  // ON LOAD 
  useEffect(() => {
    SetDocTitle("Lumina - Product Import");
    SetPageTitle('Product Import');
  }, []);

  if (loading)
    return <div className="flex justify-center items-center h-screen text-2xl"><span className="loading loading-spinner text-blue-600 w-10 h-10 pr-5 mr-5"></span>Loading...</div>;
  if (error)
    return <div className="text-red-500 text-center mt-4 text-l">Error: {error.message}</div>;

  return (
    <div style={containerStyle} className="p-2 pt-0">
      <div className="ag-theme-alpine" style={{ height: '800px', width: '100%', }}>
        {
          importMessage !== "" &&
          <div className="text-green text-[1rem] pl-4 inline-block">{importMessage}</div>
        }
        <br/>
        <div>
          <label for="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
              </svg>
            </div>
            
            <input type="search"
              id="search"
              placeholder="Search"
              className="orders-filter-search @apply block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500; required"
              onChange={handleSearch}
            />
            
            <div id="selectedRows" />
            
          </div>
          <div className="mt-4 flex">

            <button
              onClick={() => resetAndReload()}
              className="inline-flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold xs py-1 px-1 rounded m-2">
              <svg class="text-white !important; w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.7 7.7A7.1 7.1 0 0 0 5 10.8M18 4v4h-4m-7.7 8.3A7.1 7.1 0 0 0 19 13.2M6 20v-4h4" />
              </svg>
              Refresh
            </button>

            <button
              onClick={() => reimport()}
              className="inline-flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold xs py-1 px-1 rounded m-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
              </svg>
              EDI
            </button>
            
            <button
              onClick={matchSku}
              className="inline-flex items-center justify-center text-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 rounded m-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
              Match
            </button>

            <button
              onClick={importSelected}
              className="inline-flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-boldpy-1 px-1 rounded m-2">
              <svg class="m-1 text-white text-center w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 15v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2M12 4v12m0-12 4 4m-4-4L8 8" />
              </svg>
              Import
            </button>

            <button
              onClick={deleteSelected}
              className="inline-flex items-center justify-center bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-1 rounded m-2">
              <svg class="m-1 text-white text-center w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path fill-rule="evenodd" d="M8.6 2.6A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4c0-.5.2-1 .6-1.4ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z" clip-rule="evenodd" />
              </svg>
              Remove
            </button>

          </div>
        </div>

        <div className="ag-theme-alpine grid-item">
          <div className="import-grid-container">

            <AgGridReact
              rowData={importData}
              ref={gridRef}
              gridOptions={importGridOptions}
              columnDefs={importColumnDefs}
              onGridReady={onGridReady}
              pagination={true}
              paginationPageSize={50}
              onSelectionChanged={onSelectionChanged}
              onRowDoubleClicked={onRowDoubleClicked}
              rowSelection='multiple'
              rowMultiSelectWithClick={false}
            />

            {isModalOpen && (
              <Modal
                show={isModalOpen}
                size="3xl"
                onClose={() => setIsModalOpen(false)}
              >
                <Modal.Header>
                  {
                    selectedRowData.styleName
                      ? `${selectedRowData.styleName} - ${selectedRowData.supplier}`
                      : `${selectedRowData.styleNumber} - ${selectedRowData.supplier}`
                  }
                </Modal.Header>
                <Modal.Body className="pt-0">
                  {/*PRODUCT INFO*/}
                  <Tabs>
                    <TabPanel label="Product Info" svgPath="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z">
                      <div className="container mx-auto p-4">
                        <div className="flex flex-wrap -mx-2">

                          {/* COLUMN 1 */}
                          <div className="w-full md:w-1/2 px-2 mb-4">
                            <div className="mb-4">
                              <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="store">
                                  Store
                                </label>
                                <select className="editable-text" id="storeNumber" name="storeNumber" value={selectedRowData.storeNumber} onChange={handleStoreChange}>

                                  <option value="">- Select Store - </option>
                                  {storeData.map(option => (
                                    <option key={option.storeNumber} value={option.storeNumber}>{option.storeNumber} - {option.displayName}</option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="supplier">
                                Vendor
                              </label>
                              <input className="inp-text table-cell" id="supplier" name="supplier" type="text" placeholder="supplier" value={selectedRowData.supplier} disabled />
                            </div>

                            


                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="styleName">
                                Item Name
                              </label>
                              <input className="inp-text table-cell" id="styleName" name="styleName" type="text" placeholder="Item Name" value={selectedRowData.styleName} disabled />
                            </div>

                            

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="fob">
                                FOB Point
                              </label>
                              <input className="inp-text table-cell" id="fob" name="fob" type="text" placeholder="FOB Point" value={selectedRowData.fob} disabled />
                            </div>

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="materialClass">
                                Material Class
                              </label>
                              <input className="inp-text table-cell" id="materialClass" name="materialClass" type="text" placeholder="Material Class" value={selectedRowData.materialClass} disabled />
                            </div>

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="privateSupplier">
                                Private Vendor Name
                              </label>
                              <input className="inp-text table-cell editable-text" id="privateSupplier" name="privateSupplier" type="text" placeholder="Private Vendor" value={selectedRowData.privateSupplier} onChange={handleChange} />
                            </div>

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="privateStyleName">
                                Private Item Name
                              </label>
                              <input className="inp-text table-cell editable-text" id="privateStyleName" name="privateStyleName" type="text" placeholder="Private Item Name" value={selectedRowData.privateStyleName} onChange={handleChange} />
                            </div>
                            {/* OTHER INFO */}
                          </div>


                          {/* COLUMN 2 */}
                          <div className="w-full md:w-1/2 px-2">
                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="productCode">
                                Product Code
                              </label>
                              <select className="editable-text"  id="productCode" value={selectedRowData.productCode} onChange={handleProductCodeChange}>
                                <option value="">- Select PC -</option>
                                {productCodes.map(option => (
                                  <option key={option.code} value={option.code}>{option.code} - {option.name}</option>
                                ))}
                              </select>
                            </div>

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="manufacturer">
                                Manufacturer
                              </label>
                              <input className="inp-text table-cell" id="manufacturer" name="manufacturer" type="text" placeholder="Manufacturer" value={selectedRowData.manufacturer} disabled />
                            </div>

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="collectionName">
                                Collection Name
                              </label>
                              <input className="inp-text table-cell" id="collectionName" name="collectionName" type="text" placeholder="Collection" value={selectedRowData.collectionName} disabled />
                            </div>

                            

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="itemNumber">
                                Item Number
                              </label>
                              <input className="inp-text table-cell" id="itemNumber" name="itemNumber" type="text" placeholder="Item Number" value={selectedRowData.itemNumber} disabled />
                            </div>

                            

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="compositionMaterial">
                                Composition
                              </label>
                              <input className="inp-text table-cell" id="compositionMaterial" name="compositionMaterial" type="text" placeholder="Composition" value={selectedRowData.compositionMaterial} disabled />
                            </div>

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="privateCollectionName">
                                Private Collection Name
                              </label>
                              <input className="inp-text table-cell editable-text" id="privateCollectionName" name="privateCollectionName" type="text" placeholder="Private Collection Name" value={selectedRowData.privateCollectionName} onChange={handleChange} />
                            </div>

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="privateItemNumber">
                                Private Item Number
                              </label>
                              <input className="inp-text table-cell editable-text" id="privateItemNumber" name="privateItemNumber" type="text" placeholder="Private Item Number" value={selectedRowData.privateItemNumber} onChange={handleChange} />
                            </div>
                            {/* OTHER INFO */}
                          </div>
                        </div>
                      </div>

                    </TabPanel>

                    {/*SIMILAR PRODUCTS*/}
                    <TabPanel label="Similar Products" disabled="true" svgPath="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 0 1-1.125-1.125v-3.75ZM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-8.25ZM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-2.25Z">
                      <p>Similar Products Content</p>
                    </TabPanel>

                    {/*MEASUREMENTS*/}
                    <TabPanel label="Measurements" svgPath="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0 0 12 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52 2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 0 1-2.031.352 5.988 5.988 0 0 1-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971Zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0 2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 0 1-2.031.352 5.989 5.989 0 0 1-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971Z">

                      <div className="container mx-auto p-4">
                        <div className="flex flex-wrap -mx-2">

                          {/* COLUMN 1 */}
                          <div className="w-full md:w-1/2 px-2 mb-4">
                            <div className="mb-4">

                              <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="standardLength">
                                  Standard Length
                                </label>
                                <input className="inp-text table-cell" id="standardLength" name="standardLength" type="text" placeholder="Standard Length" value={selectedRowData.standardLength} />
                              </div>
                            </div>

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="standardWidth">
                                Stanard Width
                              </label>
                              <input className="inp-text table-cell" id="standardWidth" name="standardWidth" type="text" placeholder="Stanard Width" value={selectedRowData.standardWidth} />
                            </div>

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="standardWeight">
                                Shipping Weight
                              </label>
                              <input className="inp-text table-cell" id="standardWeight" name="standardWeight" type="text" placeholder="Standard Length" value={selectedRowData.standardWeight} />
                            </div>

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="faceWeight">
                                Face Weight
                              </label>
                              <input className="inp-text table-cell" id="faceWeight" name="faceWeight" type="text" placeholder="Facce Weight" value={selectedRowData.faceWeight} />
                            </div>

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="patternRepeat">
                                Pattern Repeat
                              </label>
                              <input className="inp-text table-cell" id="patternRepeat" name="patternRepeat" type="text" placeholder="Pattern Repeat" value={selectedRowData.patternRepeat} disabled />
                            </div>

                            {/* OTHER INFO */}
                          </div>


                          {/* COLUMN 2 */}
                          <div className="w-full md:w-1/2 px-2">
                            <div className="mb-4">
                              <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="actualLength">
                                  Actual Length
                                </label>
                                <input className="inp-text table-cell" id="actuallyLength" name="actuallyLength" type="text" placeholder="Actual Length" value={selectedRowData.actuallyLength} />
                              </div>
                            </div>

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="actualWidth">
                                Actual Width
                              </label>
                              <input className="inp-text table-cell" id="actualWidth" name="actualWidth" type="text" placeholder="Actual Width" value={selectedRowData.actualWidth}  />
                            </div>

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="actualHeight">
                                Actual Height
                              </label>
                              <input className="inp-text table-cell" id="actualHeight" name="actualHeight" type="text" placeholder="Actual Height" value={selectedRowData.actualHeight} disabled />
                            </div>

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="sellingUnit">
                                Selling Unit
                              </label>
                              <input className="inp-text table-cell" id="sellingUnit" name="sellingUnit" type="text" placeholder="Selling Unit" value={selectedRowData.sellingUnit} />
                            </div>

                            {/* OTHER INFO */}
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  
                    {/*ACCOUNT/EC*/}
                    <TabPanel label="Account/EC" svgPath="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z">

                      <div className="container mx-auto p-4">
                        <div className="flex flex-wrap -mx-2">

                          <div className="w-full md:w-1/10 px-2 mb-4">
                            <div className="mb-4">

                              <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="senderId">
                                  Sender ID
                                </label>
                                <input className="inp-text table-cell" id="senderId" name="senderId" type="text" placeholder="Sender ID" value={selectedRowData.senderId} />
                              </div>
                            </div>

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="accountNumber">
                                Account Number
                              </label>
                              <input className="inp-text table-cell" id="accountNumber" name="accountNumber" type="text" placeholder="Account Number" value={selectedRowData.accountNumber} />
                            </div>

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="uniqueId">
                                Unique ID
                              </label>
                              <input className="inp-text table-cell" id="uniqueId" name="uniqueId" type="text" placeholder="Unique ID" value={selectedRowData.uniqueId} />
                            </div>

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="filename">
                                Filename
                              </label>
                              <input className="inp-text table-cell" id="filename" name="filename" type="text" placeholder="Filename" value={selectedRowData.importFile} disabled />
                            </div>

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="versionType">
                                Version Type
                              </label>
                              <input className="inp-text table-cell" id="versionType" name="versionType" type="text" placeholder="Version Type" value={selectedRowData.versionType} disabled />
                            </div>

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="currencyCode">
                                Currrency Code
                              </label>
                              <input className="inp-text table-cell" id="currencyCode" name="currencyCode" type="text" placeholder="Currency Code" value={selectedRowData.currencyCode} disabled />
                            </div>

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="fileDate">
                                Document Date
                              </label>
                              <input className="inp-text table-cell" id="fileDate" name="fileDate" type="text" placeholder="Document Date" value={selectedRowData.fileDate} disabled />
                            </div>

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="documentTime">
                                Document Time
                              </label>
                              <input className="inp-text table-cell" id="documentTime" name="documentTime" type="text" placeholder="Document Time" value={selectedRowData.fileTime} disabled />
                            </div>

                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="dataIndicator">
                                Test
                              </label>
                              <input className="inp-text table-cell" id="dataIndicator" name="dataIndicator" type="text" placeholder="Data Indicator" value={selectedRowData.dataIndicator} disabled />
                            </div>

                            {/* OTHER INFO */}
                          </div>

                        </div>
                      </div>
                    </TabPanel>
                  </Tabs>   
                  <div>
                     {/*{selectedRowData && JSON.stringify(selectedRowData)}*/}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    onClick={() => setIsModalOpen(false)}
                    className="inline-flex items-center justify-center bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2">
                    Cancel
                  </button>
                  
                  <button
                    onClick={() => saveDetails()}
                    className="inline-flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2">
                    Save
                  </button>
                </Modal.Footer>
              </Modal>
            )}


            <AgGridReact
              rowData={costData}
              ref={costGridRef}
              gridOptions={costGridOptions}
              columnDefs={costColumnDefs}
              pagination={true}
              paginationPageSize={50}
              rowSelection="multiple"
            />


            <AgGridReact
              rowData={colorData}
              ref={colorGridRef}
              gridOptions={colorGridOptions}
              columnDefs={colorColumnDefs}
              pagination={true}
              paginationPageSize={50}
              rowSelection="multiple"
              onRowDoubleClicked={onColorRowDoubleClicked}
            />

            {isColorModalOpen && (
              <Modal
                show={isColorModalOpen}
                size="3xl"
                onClose={() => setIsColorModalOpen(false)}
              >
                <Modal.Header>
                  {
                    selectedRowData.colorName
                      ? `${selectedRowData.colorName} - ${selectedRowData.colorNumber}`
                      : `${selectedRowData.colorName} - ${selectedRowData.colorNumber}`
                  }
                </Modal.Header>
                <Modal.Body>
                  <Modal.Body className="pt-0">
                    {/*PRODUCT INFO*/}
                    <Tabs>

                    {/*COLOR INFO*/}
                      <TabPanel label="Color" svgPath="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z">
                        <div className="container mx-auto p-4">
                          <div className="flex flex-wrap -mx-2">

                            <div className="container mx-auto p-4">
                              <div className="w-full  px-2 mb-4">
                                
                                <div className="mb-4">
                                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="colorName">
                                    Color Name
                                  </label>
                                  <input className="inp-text table-cell" id="colorName" type="text" placeholder="Color Name" value={selectedRowData.colorName} onChange={handleChange} disabled />
                                </div>

                                <div className="mb-4">
                                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="colorNumber">
                                    Color Number
                                  </label>
                                  <input className="inp-text table-cell" id="colorNumber" type="text" placeholder="Color Number" value={selectedRowData.colorNumber} onChange={handleChange} disabled />
                                </div>

                                <div className="mb-4">
                                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="sku">
                                    SKU
                                  </label>
                                  <input className="inp-text table-cell" id="sku" type="text" placeholder="SKU" value={selectedRowData.sku} onChange={handleChange} disabled />
                                </div>


                                <div className="mb-4">
                                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="manufacturerSKU">
                                    Manufacturer Sku
                                  </label>
                                  <input className="inp-text table-cell" id="manufacturerSKU" type="text" placeholder="Manufacturer SKU" value={selectedRowData.manufacturerSKU} onChange={handleChange} disabled />
                                </div>

                                <div className="mb-4">
                                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="manufacturerSKU">
                                    UPC
                                  </label>
                                  <input className="inp-text table-cell" id="manufacturerSKU" type="text" placeholder="UPC" value={selectedRowData.upc} onChange={handleChange} disabled />
                                </div>

                                <div className="mb-4">
                                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="privateColorName">
                                    Private Color Name
                                  </label>
                                  <input className="editable-text inp-text table-cell" id="privateColorName" name="privateColorName" type="text" placeholder="Private ColorName" value={selectedRowData.privateColorName} onChange={handleChange} />
                                </div>

                                <div className="mb-4">
                                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="privateColorNumber">
                                    Private Color Number
                                  </label>
                                  <input className="editable-text  inp-text table-cell" id="privateColorNumber" name="privateColorNumber" type="text" placeholder="Private Color Number" value={selectedRowData.privateColorNumber} onChange={handleChange} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>

                      {/*GENERAL INFO*/}
                      {/*<TabPanel label="General" svgPath="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z" >*/}
                      {/*  <p>General Info Content</p>*/}
                      {/*</TabPanel>*/}

                      {/*MEASUREMENTS*/}
                      {/*<TabPanel label="Measurements" svgPath="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0 0 12 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52 2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 0 1-2.031.352 5.988 5.988 0 0 1-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971Zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0 2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 0 1-2.031.352 5.989 5.989 0 0 1-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971Z">*/}
                      {/*  <p>Measurements Content</p>*/}
                      {/*</TabPanel>*/}

                      {/*COLOR COST*/}
                      {/*<TabPanel label="Cost" svgPath="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125">*/}
                      {/*  <p>Cost Content</p>*/}
                      {/*</TabPanel>*/}

                      {/*ASSOC PRODUCTS*/}
                      <TabPanel label="Associated" svgPath="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 0 1-1.125-1.125v-3.75ZM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-8.25ZM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-2.25Z">
                        <p>Associated Products Content</p>
                      </TabPanel>

                    </Tabs>

                  <div>
                    {/*{selectedRowData && JSON.stringify(selectedRowData)}*/}
                  </div>
                  </Modal.Body>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    onClick={() => setIsColorModalOpen(false)}
                    className="inline-flex items-center justify-center bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2">
                    Cancel
                  </button>
                 
                  <button
                    onClick={() => saveColorDetails()}
                    className="inline-flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2">
                    Save
                  </button>
                </Modal.Footer>
              </Modal>
            )}

          </div>
        </div>


      </div>
    </div>
  );
};
