import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Modal } from 'react-daisyui';
import '../../../css/custom.css';
import {
    formatCurrencyDecimal, IsFalse, IsTrue, LengthGreaterEqual, PreventDefault
} from '../../../js/formHelpers.js';
import { get } from '../../../services/apiService.js';
import authService from '../../../services/authService.js';
import {
    getCardToken,
    getPaymentsByLastFour, makeAchPayment, makePayment, makePaymentByToken, postPaymentToDatabase, postTransactionToDatabase
} from '../../../services/paymentService.js';

export function PaymentModal({ orderId, onClose, submitClose}) {

    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('newCard');
    const [isAccountsReceivablePayment, setIsAccountsReceivablePayment] = useState(false);

    const [order, setOrder] = useState('');
    const [orderInfo, setOrderInfo] = useState('');
    const [storeInfo, setStoreInfo] = useState('');
    const [payments, setPayments] = useState([]);

    const [paymentType, setPaymentType] = useState('');

    const [fullName, setFullName] = useState('');
    const [description, setDescription] = useState('');
    const [cardNumber, setCardNumber] = useState('4111111111111111');   // TODO: remove - temp for testing  // useState('');
    const [cardExpiry, setCardExpiry] = useState('');
    const [cardZip, setCardZip] = useState('');
    const [cardCvv, setCardCvv] = useState('');
    const [saveCard, setSaveCard] = useState('');

    const [accountName, setAccountName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [accountNumberConfirm, setAccountNumberConfirm] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');

    const [bankName, setBankName] = useState('');
    const [bankAddress, setBankAddress] = useState('');
    const [bankCity, setBankCity] = useState('');
    const [bankState, setBankState] = useState('');
    const [bankZip, setBankZip] = useState('');
    const [bankPhone, setBankPhone] = useState('');

    const [checkNumber, setCheckNumber] = useState('');

    const [amount, setAmount] = useState('');
    const [totalDue, setTotalDue] = useState(0);
    const [cash, setCash] = useState('');
    const [balance, setBalance] = useState(0);
    const [changeDue, setChangeDue] = useState(0);

    const [userId, setUserId] = useState('');

    const [orderNumber, setOrderNumber] = useState('');
    const [depositRegister, setDepositRegister] = useState('');
    const [depositRegisters, setDepositRegisters] = useState([]);

    const [today, setToday] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    const [billingGroupId, setBillingGroupIp] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);

    const [showGrid, setShowGrid] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [rowData, setRowData] = useState([]);

    const [formattedDate, setFormattedDate] = useState([]);
    const [accountType, setAccountType] = useState('');

    const gridRef = useRef();
    const inputRef = useRef();
    const formRef = useRef(null);

    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);

    // Data
    //const { orderId } = useParams();
    //const userData = authService.getCurrentUser();

    // Response Codes
    const responseCodeMessages = {
        "00": "Transaction approved",
        "01": "Refer to card issuer",
        "02": "Refer to card issuer, special condition",
        "03": "Invalid merchant",
        "04": "Pick up card",
        "05": "Do not honor",
        "08": "Honor with ID",
        "10": "Partial Approval",
        "12": "Invalid Transaction",
        "13": "Invalid Amount",
        "14": "Invalid Card Number",
        "15": "Invalid Issuer",
        "30": "Format Error",
        "41": "Lost Card",
        "43": "Stolen Card",
        "51": "Insufficient funds/over credit limit",
        "54": "Card Expired",
        "55": "Invalid PIN",
        "57": "Transaction not permitted to issuer/cardholder",
        "58": "Transaction not permitted to acquirer/terminal",
        "61": "Exceeds withdrawal amount limit",
        "62": "Restricted card",
        "63": "Security Violation",
        "65": "Exceeds withdrawal count limit",
        "70": "Contact Card Issuer",
        "71": "PIN Not Changed",
        "75": "Allowable number of PIN tries exceeded",
        "76": "Invalid/nonexistent �To Account� specified",
        "77": "Invalid/nonexistent �From Account� specified",
        "78": "Invalid/nonexistent account specified (general)",
        "79": "Life cycle (Mastercard use only)",
        "80": "System not available",
        "81": "Domestic Debit Transaction Not Allowed (Regional use only)",
        "82": "Policy (Mastercard use only)",
        "83": "Fraud/Security (Mastercard use only)",
        "84": "Invalid Authorization Life Cycle",
        "85": "Not declined",
        "86": "PIN Validation not possible",
        "87": "Purchase Amount Only, No Cash Back Allowed",
        "88": "Cryptographic failure",
        "89": "Unacceptable PIN�Transaction Declined�Retry",
        "90": "Cutoff is in progress",
        "91": "Authorization System or issuer system inoperative",
        "92": "Unable to route transaction",
        "94": "Duplication transaction detected",
        // More?
    };

    // Grid options
    const gridOptions = {
        defaultColDef: {
            sortable: true,
            resizable: true,
            filter: true,
            suppressMovable: true,
            cellClass: ["no-border"]
        }
    };

    const [columnDefs] = useState([
        { headerName: "Id", field: "id", hide: true },
        { headerName: 'Card Token', field: 'cardToken', width: 300, hide: true },
        {
            headerName: 'Name', field: 'fullName',
        },
        {
            headerName: 'Description', field: 'description', width: 300,
        },
        {
            headerName: 'Amount', field: 'amount', width: 300,
        },
        {
            headerName: 'Card Last 4', field: 'identifier', width: 125, cellClass: ['text-secondary'], filter: true,
        },
        {
            headerName: 'Last Used', field: 'lastUsed', width: 350, filter: true,
        },
    ]);

    const fetchLastFour = useCallback(async () => {
        setError(null);
        setLoading(true);

        try {
            if (LengthGreaterEqual(searchTerm, 4)) {
                const response = await getPaymentsByLastFour(searchTerm);
                console.log('Payments?: ' + JSON.stringify(response));
                setRowData(response);
                console.log('Row Data: ' + JSON.stringify(rowData));

                setShowGrid(true);
                if (gridRef.current && gridRef.current.api) {
                    gridRef.current.api.setQuickFilter(searchTerm);
                }
                console.log('OrderID: ' + orderInfo.orderId);
                setLoading(false);
            }
        } catch (err) {
            setError('Failed to fetch payments. Please try again.');
            setLoading(false);
            console.error('Error fetching data:', err);
        }
    }, [searchTerm]);


    const setUserFields = () => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        let cUserId = 0;
        if (userData) {
            cUserId = userData.userId;
            setUserId(userData.userId);
            console.log('UserId: ' + userData.userId);
        }
        return cUserId;
    };

    const getCurrentDate = () => {
        const date = new Date();
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}${day}${year}`;
    };

    const fetchOrderInfo = async (orderId, userId) => {
        const endpoint = `orders/GetOrder/${orderId}/${userId}/0`;
        try {
            const data = await get(endpoint);
            //console.log('Order: ' + JSON.stringify(data));

            setOrderNumber(data.orderNumber);
            setStoreInfo(data.store.name);
            setOrderInfo(data.balance.toFixed(2));
            console.log('OrderId: ' + orderId);

            setTotalDue(formatCurrencyDecimal(data.balance));
            setAmount(formatCurrencyDecimal(data.balance));

        } catch (error) {
            console.error('Error fetching order info:', error);
        }
    };

    const handleCashTenderedChange = (cash) => {
        setCash(cash);
        calculateChange(amount, cash);
    };

    const calculateChange = (total, cash) => {
        const totalValue = parseFloat(total);
        const cashValue = parseFloat(cash);

        if (isNaN(totalValue) || isNaN(cashValue)) {
            setChangeDue('');
            setError('');
            return;
        }

        //if (cashValue > totalValue) {
        //    setChangeDue('');
        //} else {
        const newBalance = (totalValue - cashValue).toFixed(2);

        if (newBalance < 0) {
            setBalance(0);
            var cd = (totalValue - cashValue).toFixed(2);
            cd = cd * -1;
            setChangeDue(cd)
        } else {
            var bal = (totalValue - cashValue).toFixed(2);
            setBalance(bal);
            setChangeDue(0);
            setError('');
        }
    };

    async function initializeData() {
        const cUserId = setUserFields();
        const formattedDate = getCurrentDate();

        setFormattedDate(formattedDate);
        setToday(formattedDate);

        // Session Data from PaymentModal
        const sessionData = sessionStorage.getItem('paymentData');
        if (sessionData) {
            initializeAccountsReceivablesPayment(sessionData);
        }
        else {
            /*if (orderInfo === null) { }*/
            await fetchOrderInfo(orderId, cUserId);
            console.log('OrderId: ' + orderId);

            if (orderInfo === null) {
                await fetchLastFour();
            }
        }
    };

    function initializeAccountsReceivablesPayment(sessionData) {
        //const sessionData = invoices;
        //console.log('Invoices: ' + JSON.stringify(sessionData));

        if (sessionData) {
            setIsAccountsReceivablePayment(true);

            try {
                const parsedData = JSON.parse(sessionData);
                console.log('session data: ', sessionData)
                //setReceivedData(parsedData);
                sessionStorage.removeItem('paymentData');
                //console.log('parsedData: ' + JSON.stringify(parsedData));

                setPayments(JSON.parse(parsedData.payments));
                setTotalDue(parsedData.totalDue);
                //setTotalPaymentAmount(parsedData.totalPaymentAmount);
                calculateChange(parsedData.totalDue, parsedData.totalPaymentAmount);

                setAmount(parsedData.totalPaymentAmount);
                setCash(parsedData.totalPaymentAmount);
                //setCheckAmount(parsedData.totalPaymentAmount);

                setDepositRegister(parsedData.selectedDepositRegister);
                setPaymentType(parsedData.paymentType);

                setOrderInfo(parsedData.orderInfo);

                // Payment Type
                switch (parsedData.paymentType) {
                    case "2":
                        console.log('check');
                        setActiveTab('check');
                        setPaymentType('check');
                        break;
                    case "3":
                        console.log('ach');
                        setActiveTab('ach');
                        setPaymentType('ach');
                        break;
                    case "1":
                        console.log('cash');
                        setActiveTab('cash');
                        setPaymentType('cash');
                        break;
                    case "4":
                        console.log('swipe');
                        setActiveTab('swipe');
                        setPaymentType('swipe');
                        break;
                    default:
                        console.log('newCard ', parsedData.paymentType);
                        setActiveTab('newCard');
                        setPaymentType('card');
                        break;
                }
                // Extract order IDs for Description
                console.log('Parsed: ', parsedData);
                var formattedDescription = JSON.parse(parsedData.payments)
                    .map(payment => `#${payment.OrderNumber}`)
                    .join(', ') + ' : ';
                setDescription(formattedDescription);

                // Remove session data
                sessionStorage.removeItem('paymentData');
            } catch (error) {
                console.error('Error parsing session:', error);
            }
        }
    }

    // Response Message
    const getResponseMessage = (code) => {
        return responseCodeMessages[code] || "Unknown error";
    };

    const handleDepositRegisterChange = (e) => {
        setDepositRegister(e.target.value);
    }

    // NEW CARD
    const handleNewCardSubmit = async (e) => {
        e.preventDefault();
        console.log('New Card:', saveCard);
        setLoading(true);
        setSuccess('');
        setError('');

        try {
            let token = '';
            if (saveCard) {
                const tokenRequest = {
                    TransType: "CreateCardToken",
                    Medium: "Credit",
                    EntryMode: "Manual",
                    AccountNum: cleanCardNumber(cardNumber),
                    ExpDate: cleanCardDate(cardExpiry),
                    // TODO: Need to pull this from Store
                    // GMID: orderInfo.store.gmid,
                    // GTIF: orderInfo.store.gtid,
                    // GMPW: orderInfo.store.gmpw
                };
                console.log('Token Request:', tokenRequest);
                const newToken = await getCardToken(tokenRequest);
                token = newToken.CardToken;
                console.log('Token Response:', newToken);
            }
            console.log('Token:', token);

            let amountMoney = Number(amount);
            amountMoney = 1.00; // TODO: Remove this line in production. Use config instead.

            const paymentRequest = {
                TransType: "Sale",
                Medium: "Credit",
                EntryMode: "Manual",
                AccountNum: cleanCardNumber(cardNumber),
                ExpDate: cleanCardDate(cardExpiry),
                MainAmt: amountMoney,
                InvoiceNum: orderNumber,
            };
            console.log("Request:", JSON.stringify(paymentRequest));

            const userData = authService.getCurrentUser();
            const userId = userData.userId;

            const paymentResponse = await makePayment(paymentRequest);
            console.log('Payment Response:', JSON.stringify(paymentResponse));

            const paymentInfo = {
                description: description || '',
                paymentType: paymentType || "Card",
                fullName: fullName || '',
                amount: formatCurrencyDecimal(amount) || 0,
                identifier: cardNumber.slice(-4) || '',
                paymentRegister: depositRegister || '',
                orderId: orderId || 0,
                orderNumber: orderNumber,
                //billingGroupId: billingGroupId || 0, 
                userId: userId || 0,
                token: token || '',
                cardZip: cardZip || '',
                depositRegister: depositRegister || '',
                invoices: payments.map(payment => ({
                    invoiceId: payment.InvoiceId,
                    amountPaid: payment.PaymentAmount
                }))
            };
            console.log('Payment Info:', JSON.stringify(paymentInfo));

            const newPayment = await postPaymentToDatabase(paymentInfo);
            console.log('New Payment:', newPayment);

            const transactionInfo = {
                transactionIdentifier: paymentResponse.TransactionIdentifier,
                transactionResponse: JSON.stringify(paymentResponse),
                responseMessage: paymentResponse.ErrorText,
                cardToken: token,
                status: paymentResponse.ResultText,
                paymentId: newPayment,
                userId: userId,
                transactionDate: new Date()
            };

            const newTransaction = await postTransactionToDatabase(transactionInfo);
            console.log('New Transaction:', newTransaction);

            setError('');
            setSuccess('Payment and transaction posted');

            if (IsTrue(isAccountsReceivablePayment)) {
                submitClose("Card Payment successful.");
            }
            else {
                onClose({ success: true, message: 'Payment and Transaction Posted' });
            }
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
            setSuccess('');
            setError('Payment failed: ' + (error.response?.data?.message || error.message || 'An unknown error occurred'));
        } finally {
            setLoading(false);
        }
    };

    // ACH
    const handleAchSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        // Form Validation
        if (accountNumber !== accountNumberConfirm) {
            setSuccess('');
            setError("Routing number don't match");
            return;
        }
        else {
            // ACH Token
            //const achTokenRequest = {
            //    "accountNumber": accountNumber, // 1234567890
            //    "routingNumber": routingNumber, // 021000021
            //    "accountType": accountType
            //}

            //console.log('Getting ACH Token: ' + JSON.stringify(achTokenRequest));

            //const achTokenResponse = await getAchToken(achTokenRequest);
            //console.log('ACH Token response: ' + JSON.stringify(achTokenResponse));
            //const token = achTokenResponse.CardToken;

            var amountMoney = Number(amount);
            //amountMoney = 1.00; // Comment this out on production deploy. Use config

            // ACH Payment
            const achPaymentRequest = {
                //"CardToken": token,
                "EleCheckRoutingNumber": routingNumber,
                "EleCheckAccountNumber": accountNumber,
                "EleCheckServiceProvider": "ElecCheckWEB",
                "EleCheckTransactionType": "EleCheckConversion",
                "InvoiceNumber": orderNumber,
                "MainAmt": amountMoney,
            }
            //console.log('ACH Payment request: ' + JSON.stringify(achPaymentRequest));

            const achPaymentResponse = await makeAchPayment(achPaymentRequest);
            //console.log('ACH Payment Response: ' + JSON.stringify(achPaymentResponse));

            // TODO: Find test Bank Info to test ACH
            if (IsTrue(isAccountsReceivablePayment)) {
                submitClose('ACH Payment successful.');
            }
            else {
                setSuccess('ACH Submitted successfully');
                onClose({ success: true, message: 'ACH Payment and transaction posted' })
            }
        }
    }

    // CHECK
    const handleCheckSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        try {
            // Current User
            const userData = authService.getCurrentUser();
            const userId = userData.userId;

            // Payment info for DB
            const paymentInfo = {
                description: description || '',
                paymentType: paymentType || "Check",
                fullName: fullName || '',
                amount: formatCurrencyDecimal(amount) || 0,
                identifier: cardNumber.slice(-4) || '',
                paymentRegister: depositRegister,
                checkNumber: checkNumber || '',
                orderId: orderId || 0,
                orderNumber: orderNumber,
                billingGroupId: billingGroupId || 0,
                userId: userId || 6, // TODO: Why is userID null here?
                token: '',
                depositRegister: depositRegister,
                invoices: payments.map(payment => ({
                    invoiceId: payment.InvoiceId,
                    amountPaid: payment.PaymentAmount
                }))
            };

            console.log('Payment Info: ' + JSON.stringify(paymentInfo));

            // Post Payment to DB
            try {
                const newPayment = await postPaymentToDatabase(paymentInfo);
                console.log('New Payment: ' + JSON.stringify(newPayment));

                // Transaction info for DB
                const transactionInfo = {
                    userId: userId,
                    paymentId: newPayment.paymentId,
                    responseMessage: JSON.stringify(newPayment),
                    status: 'Success',
                    transactionDate: '',
                    transactionResponse: newPayment.status,
                    transactionIdentifier: newPayment.transactionIdentifier,
                    cardToken: '',
                }

                // Post Transaction to DB
                //const newTransaction = await postTransactionToDatabase(transactionInfo);
                //console.log('New Check Transaction: ' + JSON.stringify(newTransaction));
            } catch (error) {
                setError('Error: ' + error.response.data?.message || 'An error occured saving the transaction');
                console.log('Error in posting payment to DB: ' + error);
            }

            setError('');
            setSuccess('Payment and transaction posted');

            if (IsTrue(isAccountsReceivablePayment)) {
                submitClose("Check Payment successful.");
            }
            else {
                onClose({ success: true, message: 'Payment and transaction posted' });
            }
        } catch (error) {
            setSuccess('');
            setError('Payment failed: ' + error.message);
            console.log('Payment failed: ' + JSON.stringify(error));
        }
    }

    // CASH
    const handleCashSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        try {
            // Current User
            const userData = authService.getCurrentUser();
            const userId = userData.userId;

            // Payment info for DB
            const paymentInfo = {
                description: description || '',
                paymentType: paymentType || "Cash",
                fullName: fullName || '',
                amount: formatCurrencyDecimal(cash) || 0,
                identifier: cardNumber.slice(-4) || '',
                paymentRegister: depositRegister,
                orderId: orderId || 0,
                orderNumber: orderNumber,
                billingGroupId: billingGroupId || 0,
                userId: userId || 0,
                depositRegister: depositRegister || '',
                token: '',
                invoices: payments.map(payment => ({
                    invoiceId: payment.InvoiceId,
                    amountPaid: payment.PaymentAmount
                }))
            };
            console.log('Payment Info: ' + JSON.stringify(paymentInfo));

            // Post Payment to DB
            try {
                const newPayment = await postPaymentToDatabase(paymentInfo);
                console.log('New Payment: ' + JSON.stringify(newPayment));

                // Transaction info for DB
                const transactionInfo = {
                    transactionIdentifier: '',
                    transactionResponse: '',
                    responseMessage: '',
                    cardToken: '',
                    status: 'Success',
                    paymentId: newPayment.paymentId
                }

                // Post Transaction to DB
                //const newTransaction = await postTransactionToDatabase(transactionInfo);
                //console.log('New Cash Transaction: ' + JSON.stringify(newTransaction));
            } catch (error) {
                setError('Error: ' + error.response.data?.message || 'An error occured saving the transaction');
            }

            setError('');
            setSuccess('Payment and transaction posted');

            if (IsTrue(isAccountsReceivablePayment)) {
                submitClose("Cash Payment successful.");
            }
            else {
                onClose({ success: true, message: 'Payment and transaction posted' });
            }

        } catch (error) {
            setSuccess('');
            setError('Payment failed: ' + error.message);
            console.log('Payment failed: ' + JSON.stringify(error));
        }

    }

    // EXISTING CARD
    const handleExistingCardSubmit = async (e) => {
        e.preventDefault();
        //debugger;
        setError('');
        setSuccess('');

        const response = await makePaymentByToken(selectedRow.token, amount);
        console.log('Token payment response: ' + JSON.stringify(response));

        // TODO: SET FIELDS IN FORM
        //setFullName(response.)
        setSuccess('"Existing Card Payment submitted successfully');

        if (IsTrue(isAccountsReceivablePayment)) {
            submitClose("Existing Card Payment successful.");
        }
        else {
            onClose({ success: true, message: 'Payment and transaction posted' });
        }

    }

    // SWIPE
    const handleCardSwipeSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        setSuccess('Physical card payment recorded successfully');
        if (IsTrue(isAccountsReceivablePayment)) {
            submitClose("Manual Card Payment successful.");
        }
        else {
            onClose({ success: true, message: 'Payment and transaction posted' });
        }
    }

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        setSelectedRow(selectedRows.length > 0 ? selectedRows[0] : null);
        setFullName(selectedRows[0].fullName);

        setShowGrid(false);
        //console.log('Billing Name' + selectedRows[0].fullName);
    }

    const handleSearch = (e) => {
        e.preventDefault();

        if (searchTerm.trim()) {
            fetchLastFour();
        }
    };

    // Handle enter on search box - Not working
    const handleKeyPress = (e) => {
        //e.preventDefault();

        console.log('keypress: ' + JSON.stringify(e.target.value));
        if (e.key === 'Enter') {
            handleSearch(e);
        } else {
            setSearchTerm(e.target.value)
        }
    };

    const cleanCardNumber = (card) => {
        var cleanedNumber = card.replace(/\D/g, '');
        return cleanedNumber;
    };

    const cleanCardDate = (card) => {
        var cleanedNumber = card.replace(/\D/g, '');
        return cleanedNumber;
    };

    //const handleAccountTypeChange = (type) => {
    //    setAccountType(type);
    //}

    //const isValidDate = (dateString) => {
    //    if (dateString.length !== 6) return false;

    //    const month = parseInt(dateString.substr(0, 2), 10);
    //    const year = parseInt(dateString.substr(4), 10);

    //    if (month < 1 || month > 12) return false;
    //    if (year < 1900 || year > 2099) return false;

    //    return true;
    //};

    const changeTabs = (tab) => {
        setError('');
        setSuccess('');
        setActiveTab(tab);
    }

    function onCloseModal(e) {
        PreventDefault(e);
        setIsOpen(false);

        if (IsTrue(isAccountsReceivablePayment)) {
            onClose();
        }
        else {
            onClose();
        }
    }

    const fetchDepositRegisters = useCallback(async () => {
        try {
            const response = await get('depositregisters/GetDepositRegisters');
            setDepositRegisters(Array.isArray(response) ? response : response?.$values || []);
        } catch (error) {
            console.error('Error fetching deposit registers:', error);
        }
    }, []);

    //const onGridReady = useCallback((params) => {
    //    setError(null);
    //}, []);

    useEffect(() => {
        setIsOpen(true);
        fetchDepositRegisters();
        initializeData();
    }, []);

    if (loading) {
        return <div className="text-center text-xl mt-[10%] pb-8">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }

    return (
        <div>
            <div className="mx-auto w-[500px] text-lg pt-4">
                {IsFalse(isAccountsReceivablePayment) &&
                    <div>
                        <div className="font-semibold table-cell pr-2">Order #</div>
                        <div className="font-bold table-cell text-secondary pr-8">{orderNumber}</div>

                        <div className="font-semibold table-cell pr-2">Balance Due:</div>
                        <div className="text-green-600 table-cell pr-8">${orderInfo}</div>

                        {/*<div className="flex flex-col">*/}
                        {/*    <span className="font-semibold">Store #:</span>*/}
                        {/*    <span>{storeInfo}</span>*/}
                        {/*</div>*/}
                    </div>
                }
            </div>

            <div className="py-2 text-center">
                {error && <div className="text-red">{error}</div>}
                {/*{success && <div className="text-green">{success}</div>}*/}
                {/*{loading && <div className="loading loading-spinner loading-lg"></div>}*/}
            </div>

            <div className="flex border-b">
                <div style={containerStyle} className="pt-0 ag-grid-center">
                    <div className="flex border-b align-top">
                        <button
                            className={`flex-1 py-4 text-center ${activeTab === 'newCard' ? 'border-b-4 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                            onClick={() => changeTabs('newCard')}
                        >
                            Manual Card
                        </button>
                        {IsFalse(isAccountsReceivablePayment) &&
                            <button
                                className={`flex-1 py-4 text-center ${activeTab === 'swipe' ? 'border-b-4 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                                onClick={() => changeTabs('swipe')}
                            >
                                Physical Card
                            </button>
                        }
                        {IsFalse(isAccountsReceivablePayment) &&
                            <button
                                className={`flex-1 py-4 text-center ${activeTab === 'existingCard' ? 'border-b-4 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                                onClick={() => changeTabs('existingCard')}
                            >
                                Existing Card
                            </button>
                        }
                        <button
                            className={`flex-1 py-4 text-center ${activeTab === 'cash' ? 'border-b-4 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                            onClick={() => changeTabs('cash')}
                        >
                            Cash
                        </button>
                        <button
                            className={`flex-1 py-4 text-center ${activeTab === 'check' ? 'border-b-4 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                            onClick={() => changeTabs('check')}
                        >
                            Check
                        </button>
                        {IsFalse(isAccountsReceivablePayment) &&
                            <button
                                className={`flex-1 py-4 text-center ${activeTab === 'ach' ? 'border-b-4 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                                onClick={() => changeTabs('ach')}
                            >
                                ACH Payment
                            </button>
                        }
                    </div>

                    <div className="pt-4 px-4 flex-grow">

                        {/* NEW CARD */}
                        {activeTab === 'newCard' && (
                            <div>
                                <form ref={formRef} className="payment-modal-form" action="#">
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Billing Name</span>
                                        </label>
                                        <input type="text" placeholder="" className="input input-bordered w-full" value={fullName} onChange={(e) => setFullName(e.target.value)} required />
                                    </div>
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Card Number</span>
                                        </label>
                                        <input type="number" placeholder="" className="input input-bordered w-full"
                                            value={cardNumber} onChange={(e) => setCardNumber(e.target.value)} required />
                                    </div>
                                    <div className="flex gap-4 pt-1">
                                        <div className="form-control flex-1">
                                            <label className="label">
                                                <span className="label-text">Expiry (MMYYYY)</span>
                                            </label>
                                            <input type="number" placeholder="" className="input input-bordered w-full" value={cardExpiry} onChange={(e) => setCardExpiry(e.target.value)} required />
                                        </div>
                                        <div className="form-control flex-1">
                                            <label className="label">
                                                <span className="label-text">CVV</span>
                                            </label>
                                            <input type="number" placeholder="" className="input input-bordered w-full" value={cardCvv} onChange={(e) => setCardCvv(e.target.value)} required />
                                        </div>
                                        <div className="form-control flex-1">
                                            <label className="label">
                                                <span className="label-text">Zip</span>
                                            </label>
                                            <input type="number" placeholder="" className="input input-bordered w-full" value={cardZip} onChange={(e) => setCardZip(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Description</span>
                                        </label>
                                        <input type="text" placeholder="" className="input input-bordered w-full" value={description} onChange={(e) => setDescription(e.target.value)} />
                                    </div>
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Deposit Register</span>
                                        </label>
                                        <select className="select select-bordered w-full font-normal"
                                            value={depositRegister}
                                            onChange={handleDepositRegisterChange}
                                        >
                                            <option value="">-- Select --</option>
                                            {depositRegisters.map((register) => (
                                                <option key={register.depositRegisterId} value={register.depositRegisterId}>
                                                    {register.registerName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Amount</span>
                                        </label>
                                        <input
                                            type="number"
                                            placeholder=""
                                            className="input input-bordered w-full"
                                            defaultValue={amount}
                                            onChange={(e) => setAmount(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="form-control py-2">
                                        <label className="label cursor-pointer justify-start">
                                            <input type="checkbox" className="checkbox mr-2" checked={saveCard} onChange={(e) => setSaveCard(e.target.checked)} />
                                            <span className="label-text">Save Card</span>
                                        </label>
                                    </div>
                                    <div className="pt-4 pb-4">
                                        <Button className="btn-submit" onClick={handleNewCardSubmit}>Submit Payment</Button>
                                        <Button className="btn-cancel" onClick={onCloseModal}>Cancel</Button>
                                    </div>
                                </form>
                            </div>
                        )}

                        {/* EXISTING */}
                        {activeTab === 'existingCard' && (
                            <div className="ag-theme-alpine">
                                <div className="mb-4 flex gap-2">
                                    <input type="search" placeholder="Search last 4 digits"
                                        className="input input-bordered w-1/4 h-8" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} onKeyDown={handleKeyPress} maxLength="4" />
                                    <Button onClick={handleSearch} className="btn-submit-modal">Search</Button>
                                </div>
                                {showGrid && (
                                    <div className="h-64 w-full mb-4">
                                        <AgGridReact
                                            ref={gridRef}
                                            rowData={rowData}
                                            columnDefs={columnDefs}
                                            rowSelection={'single'}
                                            onSelectionChanged={onSelectionChanged}
                                            gridOptions={gridOptions}
                                            onGridReady={(params) => params.api.sizeColumnsToFit()}
                                        />
                                    </div>
                                )}
                                {selectedRow && (
                                    <form ref={formRef} className="payment-modal-form" action="#">
                                        <div className="form-control">
                                            <label className="label">
                                                <span className="label-text">Billing Name</span>
                                            </label>
                                            <input type="text" value={fullName} className="input input-bordered w-full" readOnly />
                                        </div>
                                        <div className="form-control">
                                            <label className="label">
                                                <span className="label-text">Description</span>
                                            </label>
                                            <input type="text" placeholder="" className="input input-bordered w-full"
                                                value={description} onChange={(e) => setDescription(e.target.value)} />
                                        </div>
                                        <div className="form-control">
                                            <label className="label">
                                                <span className="label-text">Deposit Register</span>
                                            </label>
                                            <select className="select select-bordered w-full font-normal"
                                                value={depositRegister}
                                                onChange={handleDepositRegisterChange}
                                            >
                                                <option value="">-- Select --</option>
                                                {depositRegisters.map((register) => (
                                                    <option key={register.depositRegisterId} value={register.depositRegisterId}>
                                                        {register.registerName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-control">
                                            <label className="label">
                                                <span className="label-text">Amount</span>
                                            </label>
                                            <input type="number" placeholder="" className="input input-bordered w-full"
                                                value={amount} onChange={(e) => setAmount(e.target.value)} required />
                                        </div>

                                        <div className="pt-8 pb-4">
                                            <Button className="btn-submit" onClick={handleExistingCardSubmit}>Submit Payment</Button>
                                            <Button className="btn-cancel" onClick={onCloseModal}>Cancel</Button>
                                        </div>
                                    </form>
                                )}
                            </div>
                        )}

                        {/* ACH */}
                        {activeTab === 'ach' && (
                            <div>
                                <form ref={formRef} className="payment-modal-form" action="#">
                                    <div>
                                        <label for="accountName" class="label-text">Account Name</label>
                                        <input type="text" name="accountName" id="accountName"
                                            className="input input-bordered w-full"
                                            value={accountName}
                                            onChange={(e) => setAccountName(e.target.value)} />
                                    </div>
                                    <div>
                                        <label for="accountNumber" class="label-text">Account Number</label>
                                        <input type="number" name="accountNumber" id="accountNumber"
                                            className="input input-bordered w-full"
                                            value={accountNumber}
                                            onChange={(e) => setAccountNumber(e.target.value)} />
                                    </div>
                                    <div>
                                        <label for="accountNumberConfirm" class="label-text">Confirm Account Number</label>
                                        <input type="number" name="accountNumberConfirm" id="accountNumberConfirm"
                                            className="input input-bordered w-full"
                                            value={accountNumberConfirm}
                                            onChange={(e) => setAccountNumberConfirm(e.target.value)} />
                                    </div>
                                    <div>
                                        <label for="routingNumber" class="label-text">Routing Number</label>
                                        <input type="number" name="routingNumber" id="routingNumber" className="input input-bordered w-full"
                                            value={routingNumber}
                                            onChange={(e) => setRoutingNumber(e.target.value)} />
                                    </div>
                                    <div>
                                        <label for="accountType" className="label-text">Account Type</label>
                                        <select
                                            id="accountType"
                                            name="accountType"
                                            className="select select-bordered w-full font-normal"
                                            onChange={(e) => setAccountType(e.target.value)}
                                            value={accountType}
                                        >
                                            <option disabled value="">-- Select --</option>
                                            <option value="Savings">Savings</option>
                                            <option value="Checking">Checking</option>
                                        </select>
                                    </div>
                                    <div className="flex gap-4 pt-1">
                                        <div className="form-control flex-1">
                                            <label for="bankName" class="label-text">Bank Name</label>
                                            <input type="text" name="bankName" id="bankName"
                                                className="input input-bordered w-full"
                                                value={bankName}
                                                onChange={(e) => setBankName(e.target.value)} />
                                        </div>
                                        <div className="form-control flex-1">
                                            <label for="bankAddress" class="label-text">Bank Address</label>
                                            <input type="text" name="bankAddress" id="bankAddress"
                                                className="input input-bordered w-full"
                                                value={bankAddress}
                                                onChange={(e) => setBankAddress(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="flex gap-4 pt-1">
                                        <div className="form-control flex-1">
                                            <label for="bankCity" class="label-text">Bank City</label>
                                            <input type="text" name="bankCity" id="bankCity"
                                                className="input input-bordered w-full"
                                                value={bankCity}
                                                onChange={(e) => setBankCity(e.target.value)} />
                                        </div>
                                        <div className="form-control flex-1">
                                            <label for="bankState" class="label-text">Bank State</label>
                                            <input type="text" name="bankState" id="bankState"
                                                className="input input-bordered w-full"
                                                value={bankState}
                                                onChange={(e) => setBankState(e.target.value)} />
                                        </div>
                                        <div className="form-control flex-1">
                                            <label for="bankZip" class="label-text">Bank Zip</label>
                                            <input type="number" name="bankZip" id="bankZip"
                                                className="input input-bordered w-full"
                                                value={bankZip}
                                                onChange={(e) => setBankZip(e.target.value)} />
                                        </div>
                                        <div className="form-control flex-1">
                                            <label for="bankPhone" class="label-text">Bank Phone</label>
                                            <input type="phone" name="bankPhone" id="bankPhone"
                                                className="input input-bordered w-full"
                                                value={bankPhone}
                                                onChange={(e) => setBankPhone(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Deposit Register</span>
                                        </label>
                                        <select className="select select-bordered w-full font-normal"
                                            value={depositRegister}
                                            onChange={handleDepositRegisterChange}
                                        >
                                            <option value="">-- Select --</option>
                                            {depositRegisters.map((register) => (
                                                <option key={register.depositRegisterId} value={register.depositRegisterId}>
                                                    {register.registerName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div>
                                        <label for="amount" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Amount</label>
                                        <input type="number" name="amount" id="amount"
                                            className="input input-bordered w-full"
                                            value={amount}
                                            onChange={(e) => setAmount(e.target.value)} />
                                    </div>
                                    <div class="flex items-center justify-between">
                                        <div class="flex items-start"></div>
                                    </div>
                                    <div className="pt-8 pb-4">
                                        <Button className="btn-submit" onClick={handleAchSubmit}>Submit Payment</Button>
                                        <Button className="btn-cancel" onClick={onCloseModal}>Cancel</Button>
                                    </div>
                                </form>
                            </div>
                        )}

                        {/* CASH */}
                        {activeTab === 'cash' && (
                            <div>
                                <form ref={formRef} className="payment-modal-form" action="#">
                                    <div>
                                        <label for="totalDue" class="label-text">Total Due</label>
                                        <input type="number" name="totalDue" id="totalDue"
                                            className="input input-bordered w-full"
                                            value={totalDue}
                                            readOnly />
                                    </div>
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Description</span>
                                        </label>
                                        <input type="text" className="input input-bordered w-full"
                                            value={description} onChange={(e) => setDescription(e.target.value)} />
                                    </div>
                                    <div>
                                        <label for="cash" class="label-text">Cash Tendered</label>
                                        <input type="number" name="cash" id="cash"
                                            className="input input-bordered w-full"
                                            value={cash}
                                            onChange={(e) => handleCashTenderedChange(e.target.value)} />
                                    </div>
                                    {/* ADD BALANCE */}
                                    <div>
                                        <label for="balance" class="label-text">Balance</label>
                                        <input type="number" name="balance" id="balance"
                                            className="input input-bordered w-full"
                                            value={balance}
                                            readOnly />
                                    </div>
                                    <div>
                                        <label for="changeDue" class="label-text">Change Due</label>
                                        <input type="number" name="changeDue" id="changeDue"
                                            className="input input-bordered w-full"
                                            value={changeDue}
                                            readOnly />
                                    </div>
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Deposit Register</span>
                                        </label>
                                        <select className="select select-bordered w-full font-normal"
                                            value={depositRegister}
                                            onChange={handleDepositRegisterChange}
                                        >
                                            <option value="">-- Select --</option>
                                            {depositRegisters.map((register) => (
                                                <option key={register.depositRegisterId} value={register.depositRegisterId}>
                                                    {register.registerName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="pt-4 pb-4">
                                        <Button className="btn-submit" onClick={handleCashSubmit}>Submit Payment</Button>
                                        <Button className="btn-cancel" onClick={onCloseModal}>Cancel</Button>
                                    </div>
                                </form>
                            </div>
                        )}

                        {/* CHECK */}
                        {activeTab === 'check' && (
                            <div>
                                <form ref={formRef} className="payment-modal-form" action="#">
                                    <div>
                                        <label for="totalDue" class="label-text">Total Due</label>
                                        <input type="number" name="totalDue" id="totalDue"
                                            className="input input-bordered w-full"
                                            value={totalDue}
                                            readOnly />
                                    </div>
                                    <div>
                                        <label for="amount" class="label-text">Check Amount</label>
                                        <input type="number" name="amount" id="amount"
                                            className="input input-bordered w-full"
                                            value={amount}
                                            onChange={(e) => setAmount(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <label for="checkNumber" class="label-text">Check Number</label>
                                        <input type="number" name="checkNumber" id="checkNumber"
                                            className="input input-bordered w-full"
                                            value={checkNumber}
                                            onChange={(e) => setCheckNumber(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Deposit Register</span>
                                        </label>
                                        <select className="select select-bordered w-full font-normal"
                                            value={depositRegister}
                                            onChange={handleDepositRegisterChange}
                                        >
                                            <option value="">-- Select --</option>
                                            {depositRegisters.map((register) => (
                                                <option key={register.depositRegisterId} value={register.depositRegisterId}>
                                                    {register.registerName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="pt-8 pb-4">
                                        <Button className="btn-submit" onClick={handleCheckSubmit}>Submit Payment</Button>
                                        <Button className="btn-cancel" onClick={onCloseModal}>Cancel</Button>
                                    </div>
                                </form>
                            </div>
                        )}

                        {/* SWIPE */}
                        {activeTab === 'swipe' && (
                            <div>
                                <form ref={formRef} className="payment-modal-form" action="#">
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Description</span>
                                        </label>
                                        <input type="text" className="input input-bordered w-full" value={description} onChange={(e) => setDescription(e.target.value)} />
                                    </div>
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Deposit Register</span>
                                        </label>
                                        <select className="select select-bordered w-full font-normal"
                                            value={depositRegister}
                                            nChange={handleDepositRegisterChange}
                                        >
                                            <option value="">-- Select --</option>
                                            {depositRegisters.map((register) => (
                                                <option key={register.depositRegisterId} value={register.depositRegisterId}>
                                                    {register.registerName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Amount</span>
                                        </label>
                                        <input type="number" className="input input-bordered w-full" value={amount}
                                            onChange={(e) => setAmount(e.target.value)} required />
                                    </div>
                                    <div className="pt-8 pb-4">
                                        <Button className="btn-submit" onClick={handleCardSwipeSubmit}>Submit Payment</Button>
                                        <Button className="btn-cancel" onClick={onCloseModal}>Cancel</Button>
                                    </div>
                                </form>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}