import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-daisyui';
//import { initFlowbite } from "flowbite";
//import { Datepicker } from 'flowbite-react';

import { GetStoreSelectList } from '../../../js/dataHelpers.js';
import { GetSelectItemTextByValue, FormatDateLongInput } from '../../../js/helpers.js';
import {
    handleFieldChange, SetFocusById, formatCurrencyDecimal, ParseIntSafe,
    IdExists, IsTrue, IsFalse, IsEmpty, Exists, GetDateNow, KeyHandlerDatePicker,
    NotExists, isValidDate, NotIsValidDate, PreventDefault, NotStringEmpty,
}
from '../../../js/formHelpers.js';
import { DoMinQtyCheck, GetBoxQtyStr, createPoModel } from '../_js/_orderHelpers.js';

import {
    Equals, GetLengthFromWidthQuantity, GetMultDec,
    GetNewQuantityForMin, GetQuantityFromWidthLength
}
from '../../../js/calculationHelpers.js';
import { post } from '../../../services/apiService.js';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { MinQtyModal } from './MinQtyModal.js';

export const PurchaseOrderModal = (props) => {
    const qtyModal = useRef();

    const onCloseModal = props.onCloseModal;
    const propsOrderData = props.orderData ?? null;
    const checkedOrderLineIds = props.checkedOrderLineIds ?? [];
    const propsOrderLines = props.orderLines ?? [];
    const propsIsSamplesOrder = props.isSamplesOrder ?? false;

    //TODO: remove when use token
    const [user, setUser] = useState(null);
    const [userId, setUserId] = useState(null);

    const [modalTitle, setModalTitle] = useState(false);
    const [storeList, setStoreList] = useState([]);
    const VendorWcStoreId =  "21";

    const [orderLineIds, setOrderLineIds] = useState([]);
    const [orderLineId, setOrderLineId] = useState(0);
    const [purOrderLine, setPurOrderLine] = useState(null);
    const [posUpdated, setPosUpdated] = useState(false);
    const [poData, setPoData] = useState(createPoModel());

    const [datePickerAutoFocus, setDatePickerAutoFocus] = useState(true);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [promiseDate, setPromiseDate] = useState("");    
    const [poTotal, setPoTotal] = useState(0);

    const [boxQtyStr, setBoxQtyStr] = useState(false);
    const [stdLength, setStdLength] = useState();
    const [units, setUnits] = useState("");
    const [width, setWidth] = useState();
    const [length, setLength] = useState();
    const [quantity, setQuantity] = useState();
    
    const [lineTotal, setLineTotal] = useState(0);
    const [linePrice, setLinePrice] = useState();

    const [showDeliverWillCall, setShowDeliverWillCall] = useState(false);    
    const [deliverWillCall, setDeliverWillCall] = useState(false);

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [stockMessage, setStockMessage] = useState("");
    
    const [viewButtonText, setViewButtonText] = useState("Next");
    const [nextButtonText, setNextButtonText] = useState("");
    const [showNextButton, setShowNextButton] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [inputsDisabled, setInputsDisabled] = useState(false);
    const [quantityDisabled, setQuantityDisabled] = useState(false);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);

    //used for min qty adjustment
    const [qtyModalContent, setQtyModalContent] = useState(null);
    const [allowQtyOverride, setAllowQtyOverride] = useState(false);

    const createMsg = "PO created successfully.";
    const updateMsg = "PO updated successfully.";
    const createErrMsg = "There was an issue creating the PO. Please try again or contact support.";
    const updateErrMsg = "There was an issue updating the PO. Please try again or contact support.";
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    //const [lastKeypressTime, setLastKeypressTime] = useState(null);

    //Methods

    function updateMessage(msg, cssClass) {
        msg = msg ?? "";
        cssClass = cssClass ?? "";
        setMessage(msg);
        setMessageClass(cssClass);
    }
    async function createStoreList() {
        var list = [];
        //list.push(
        //    <option key="0" value="0">None</option>
        //);

        var dataList = await GetStoreSelectList(2);
        dataList.forEach((item, index) => {
            list.push(
                <option key={index + 1} value={item.value}>{item.text}</option>
            );
        });
        setStoreList(list);
    }

    function onClosePoModal(e) {
        e.preventDefault();
        onCloseModal(e, posUpdated);
    }

    function createPurOrderModel(orderLineData, showNext) {
        updateMessage();
        setShowNextButton(showNext);
        
        if (orderLineData) {
            setPurOrderLine(orderLineData);

            var model = {};
            //None, To Be Ordered, On Order, Stock Item
            if (orderLineData.statusId === 0 || orderLineData.statusId === 1 ||
                orderLineData.statusId === 10 || orderLineData.statusId === 15) {

                var orderData = propsOrderData;
                var sp1Name = GetSelectItemTextByValue("salesperson1Id", orderData.salesperson1Id.toString());
                orderData.orderedBy = sp1Name;

                model = createPoModel(orderData, orderLineData, null, false, "create");
                
                // retain referenceNumber for next PO
                model.referenceNumber = poData.referenceNumber;
                setPoData(model);

                // set Page Fields
                setPageFields(model);
                
                // retain promiseDate for next PO
                if (promiseDate === "") {
                    setTodaysDate();
                }
                setStartDate(GetDateNow());
                setEndDate(GetDateNow(5));
                
                //show 'Deliver Will Call' for for 'Vendor WC' selected
                updateShowDeliverWillCall(model.warehouseId);

                setDatePickerAutoFocus(true);

                var stockMsg = "";
                if (IsTrue(orderLineData.isStock)) {                    
                    //stockMsg = "This is a Stock Item. Are you sure you want to create a PO?";
                    setDatePickerAutoFocus(false);
                }
                setStockMessage(stockMsg);

                setModalTitle("Create Purchase Order");
                setInputsDisabled(false);
                setQuantityDisabled(false);
                setButtonsDisabled(false);

                setShowSaveButton(true);
                if (showNext === true) {
                    setNextButtonText("Create & Next");
                }
                else {
                    setNextButtonText("Create");
                }
            }
            /** NOTE:
            *** dont allow editing PO here once created
            *** must be done at OrderLine level to keep track of receiving
            **/
            //else if (orderLineData.statusId === 2) {
            //    model = createPoModel(null, orderLineData, null, false, "edit");
            //    setPromiseDate(model.promDate);
            //    setPoData(model);
            //    setPoTotal(model.totalCost);

            //    setModalTitle("Edit Purchase Order");                
            //    setInputsDisabled(false);

            //    setShowSaveButton(true);
            //    if (showNext === true) {
            //        setNextButtonText("Update & Next");
            //    }
            //    else {
            //        setNextButtonText("Update");
            //    }
            //}
            else {
                // Non-editable PO view
                model = createPoModel(null, orderLineData, null, false, "view");

                // set Page Fields
                setPageFields(model);

                setPromiseDate(model.promDate);
                setStartDate(GetDateNow());
                setEndDate(GetDateNow(5));

                setPoData(model);

                setModalTitle("Purchase Order Info");
                setInputsDisabled(true);
                setQuantityDisabled(true);
                setShowSaveButton(false);

                updateMessage("Update PO in Edit Order Line", "text-secondary");
            }

            if (IsTrue(propsIsSamplesOrder)) {
                setModalTitle("Edit Samples Line");
                setQuantityDisabled(true);
                updateMessage();
            }
        }
        else {
            setPoData(null);
            setPoTotal(0);
            setPurOrderLine(null);
        }
    }

    function setPageFields(model) {
        setAllowQtyOverride(true);

        setPoTotal(model.totalCost);
        setQuantity(model.poQuantity);
        setWidth(model.width);
        setLength(model.length);
        setStdLength(model.length);

        var bxQtyStr = GetBoxQtyStr(model.sellQuantity, model.sellUnit, model.units);
        setBoxQtyStr(bxQtyStr);
    }

    function setupPurOrderModal() {
        var olIds = getCheckedOrderLineIds();
        var olId = getNextOrderLineId(olIds);
        olIds = moveNextOrderLineId(olIds);

        getPoLineData(olId, olIds);
    }

    async function getNextPurOrder(e, save) {
        PreventDefault(e);
        var success = false;
        setMessage("");

        var moveNext = true;
        //validate data if saving
        if (save === true) {
            if (NotIsValidDate(promiseDate)) {
                save = false;
                moveNext = false;
                setMessageClass("text-red");
                setMessage("Valid Promise Date is required.");
            }
        }

        //save if valid
        if (save === true) {
            success = await savePurOrder();
        }

        //if valid or no save continue
        if (moveNext === true) {

            var olId = getNextOrderLineId();
            var olIds = moveNextOrderLineId();

            if (olId && olId !== 0) {
                setShowSaveButton(true);

                getPoLineData(olId, olIds);
                SetFocusById("promiseDate");
            } else {
                setShowSaveButton(false);
                onClosePoModal(e);
            }

            if (!olIds || olIds.length === 0) {
                setShowNextButton(false);
            }
        }
    }

    function getCheckedOrderLineIds() {
        setOrderLineIds(checkedOrderLineIds);
        return checkedOrderLineIds;
    }

    function getNextOrderLineId(olIds) {
        if (!olIds) {
            //get from state if not passed in
            olIds = orderLineIds;
        }

        var olId = 0
        if (olIds && olIds.length > 0) {
            //get next ID from array
            olId = ParseIntSafe(olIds.slice(0, 1) ?? "0");
        }

        setOrderLineId(olId);
        return olId;
    }

    function moveNextOrderLineId(olIds) {
        if (!olIds) {
            //get from state if not passed in
            olIds = orderLineIds;
        }

        if (olIds && olIds.length > 0) {
            //remove first ID from array
            olIds = olIds.slice(1);
        } else {
            olIds = [];
        }
        setOrderLineIds(olIds);
        return olIds;
    }

    function updateShowDeliverWillCall(val) {
        //if (!Exists(locName)) {
        //    locName = GetSelectItemTextByValue("warehouseId", val);
        //}
        //if (locName === "Vendor WC" || val === 21) {

        if (Equals(val, VendorWcStoreId)) {
            setShowDeliverWillCall(true);
        }
        else {
            setShowDeliverWillCall(false);
        }
    }

    const handleChange = (e) => {
        setMessage("");

        var data = handleFieldChange(e);
        if (data) {

            //page fields
            if (data.name === "deliverWillCall") {
                setDeliverWillCall(data.value);
            }
            else if (data.name === "width") {
                UpdateForWidthLengthQuantity(data.value, null, null);
            }
            else if (data.name === "length") {
                UpdateForWidthLengthQuantity(null, data.value, null);
            }
            else if (data.name === "poQuantity") {
                DisableSubmit(e, data.value, false);

                // reset allow override for submit on qty change
                setAllowQtyOverride(false);
                UpdateForWidthLengthQuantity(null, null, data.value);                
            }
            else if (data.name === "quantity") {
                DisableSubmit(e, data.value, false);

                // reset allow override for submit on qty change
                setAllowQtyOverride(false);
                UpdateForWidthLengthQuantity(null, null, data.value);                
            }
            //PO fields
            else {
                if (data.name === "warehouseId") {
                    updateShowDeliverWillCall(data.value);
                }
                
                //updateMessage("", "");
                setPoData((poData) => ({
                    ...poData,
                    [data.name]: data.value
                }));
            }
        }
        else {
            //updateMessage(defaultErrMsg, "text-red", "null handleChange data");
        }
    };

    function UpdateForWidthLengthQuantity(cWidth, cLength, cQuantity) {

        //update roll fields based on changed
        if (IsTrue(poData.isRoll)) {
            //quantity always set here for rolls

            if (cWidth !== null) {
                //set changed value
                setWidth(cWidth);
                //set calc values for non-changed fields
                cLength = length ?? 0;

                cQuantity = GetLengthFromWidthQuantity(cWidth, cLength, "SY")
                cQuantity = formatCurrencyDecimal(cQuantity);
            }
            else if (cLength !== null) {
                //set changed value
                setLength(cLength);
                //set calc values for non-changed fields
                cWidth = width ?? 0;

                cQuantity = GetQuantityFromWidthLength(cWidth, cLength, "SY")
                cQuantity = formatCurrencyDecimal(cQuantity);
            }
            else if (cQuantity !== null) {
                //set calc values for non-changed fields
                cWidth = width ?? 0;

                cLength = GetLengthFromWidthQuantity(cWidth, cQuantity, "SY")
                setLength(formatCurrencyDecimal(cLength));
            }
        }

        // Quantity always updated        
        setQuantity(cQuantity);

        // PoTotal always updated for Quantity
        const total = GetMultDec(cQuantity, poData.cost);
        setPoTotal(total);
    }

    function returnMinQtyModalComponent(minQuantity, newQuantity, onCloseQtyModal) {
        return <MinQtyModal minQuantity={minQuantity} newQuantity={newQuantity}
            onCloseModal={onCloseQtyModal}></MinQtyModal>;
    }

    function onOpenQtyModal(minQuantity, newQuantity) {
        //updateMessage("", "");

        setQtyModalContent(returnMinQtyModalComponent(minQuantity, newQuantity, onCloseQtyModal));
        handleQtyModalShow();
    }

    function onCloseQtyModal(e, allowAdj, newQuantity) {
        PreventDefault(e);

        if (allowAdj) {
            //set adjusted qty for min sell qty
            setQuantity(newQuantity);
            // PoTotal updated for Quantity
            const total = GetMultDec(newQuantity, poData.cost);
            setPoTotal(total);
            updateMessage("Quantity and Totals have been adjusted.", "text-red");
        }
        else {
            //set allow qty override for submit
            setAllowQtyOverride(true);
        }
        handleQtyModalHide();
    }

    // Min Qty Modal
    const handleQtyModalShow = useCallback(() => {
        qtyModal.current?.showModal();
    }, [qtyModal]);

    const handleQtyModalHide = useCallback(() => {
        qtyModal.current?.close();
        SetFocusById("linePrice");
    }, [qtyModal]);

    function DisableSubmit(e, cQuantity, cQuantityOverride) {
        PreventDefault(e);

        var minQtyRem = DoMinQtyCheck(cQuantity, cQuantityOverride, quantity, poData.sellQuantity, poData.sellUnit, poData.isRoll);
        if (minQtyRem !== 0) {
            setButtonsDisabled(true);
        }
        else {
            setButtonsDisabled(false);
        }
    }

    function CheckMinQuantity(e) {
        PreventDefault(e);
        setButtonsDisabled(false);
                
        var minQtyRem = DoMinQtyCheck(null, null, quantity, poData.sellQuantity, poData.sellUnit, poData.isRoll);
        if (minQtyRem !== 0) {
            var newQuantity = GetNewQuantityForMin(poData.sellQuantity, quantity);
            // open min qty notification modal
            onOpenQtyModal(poData.sellQuantity, newQuantity);
        }
    }

    async function getPoLineData(olId, olIds) {
        if (!olId) {
            //get from state if not passed in
            olId = orderLineId;
        }
        if (!olIds) {
            //get from state if not passed in
            olIds = orderLineIds;
        }
        var showNext = olIds && olIds.length > 0;

        if (olId && olId !== 0) {
            //TODO: remove when pull from db
            setTimeout(() => {

                var ol = propsOrderLines.filter((element) => {
                    return element.orderLineId === olId;
                });

                if (ol && ol.length === 1) {
                    createPurOrderModel(ol[0], showNext);
                }
                else {
                    createPurOrderModel(null, true);
                    console.error("duplicate PO OrderLine for ID");
                }
            }, 100);

            //TODO: enable when pull from db
            //if (false) {
            //    var apiUrl = process.env.REACT_APP_API_URL;
            //    apiUrl += "order/getOrderLine/" + olId;
            //    console.log("apiUrl: " + apiUrl);

            //    const response = await fetch(apiUrl)
            //        .then(function (response) {
            //            return response.json()
            //        })
            //        .then(function (respData) {
            //            if (respData) {
            //                //console.log('PO Lines data: ' + JSON.stringify(respData));
            //                createPurOrderModel(respData, showNext);
            //            }
            //            else {
            //                console.error("no PO OrderLine data");
            //            }
            //        })
            //        .catch((error) => {
            //            console.error('Error fetching PO OrderLine data:', error);
            //        });
            //}
        }
    }

    async function savePurOrder() {
        var success = false;

        if (poData.orderLineId !== 0) {

            //set post data
            if (showDeliverWillCall === true) {
                if (deliverWillCall === true) {
                    poData.statusId = 4;
                }
            }

            poData.promDate = promiseDate;
            poData.userId = userId;
            poData.quantity = quantity;
            setPoData(poData);
            var model = JSON.stringify(poData);

            const endpoint = 'orders/SavePurOrderLine';
            try {
                const response = await post(endpoint, model);
                //console.log('Response: ' + JSON.stringify(response));

                setPosUpdated(true);
                setMessageClass("text-green");

                if (poData.statusId === 0 || poData.statusId === 1) {
                    setMessage(createMsg);
                } else {
                    setMessage(updateMsg);
                }
                success = true;

            } catch (error) {
                console.log('Error: ' + error);
            }
        }
        return success;
    }

    function EnterSave(e) {
        if (e.key === "Enter") {
            getNextPurOrder(e, true);
        }
    }

    function setTodaysDate() {
        var dateInp = GetDateNow();
        setPromiseDate(dateInp);
    };

    function setUserFields() {        
        const userData = JSON.parse(localStorage.getItem('userData'));
        var cUserId = 0;
        if (userData) {
            cUserId = userData.userId;
            setUserId(userData.userId);
            setUser(userData);
        }
    }

    // ON LOAD
    useEffect(() => {
        setUserFields();
        createStoreList();
        setupPurOrderModal();

        //initFlowbite();
    }, []);

    return (
        <div className="">
            <div className="min-h-[300px] p-1">
                {purOrderLine &&
                    <div>
                        {/*Supplier & Line Info*/}
                        <div className="text-center">
                            <div className="font-bold mb-1 text-xl">
                                {modalTitle}
                                <span className="pl-[8px] hidden">
                                    - {purOrderLine.orderLineId}
                                </span>
                            </div>
                            <div>
                                {
                                    purOrderLine.vendor && purOrderLine.vendor.name !== "" &&
                                    <div>{purOrderLine.vendor.name}</div>
                                }
                                <div>
                                    <div className="hidden">
                                        {"#" + purOrderLine.lineNumberStr}                                    
                                        <span className="px-1">-</span>
                                    </div>
                                    {purOrderLine.style}
                                </div>                                
                                <div>{purOrderLine.color !== "" && purOrderLine.color}</div>                                
                            </div>
                        </div>

                        {/*PO fields*/}
                        <div className="table pt-4">
                            {poData.isRoll && //((width && width > 0) || (length && length > 0)) &&
                                <div>
                                    <div className="table-cell">
                                        <div className="po-col-1">
                                            Width
                                        </div>
                                        <div className="po-col-2">
                                            <input type="number"
                                                className="inp-quantity-modal w-[70px] pl-3 pr-1"
                                                id="width" name="width"
                                                onKeyUp={(e) => EnterSave(e)}
                                                disabled={true}
                                                onChange={handleChange}
                                                value={width}
                                            />
                                        </div>
                                    </div>
                                    <div className="table-cell">
                                        <div className="po-col-1 !w-[70px] pl-4 pr-1">
                                            Length
                                        </div>
                                        <div className="po-col-2">
                                            <input type="number"
                                                className="inp-quantity-modal !w-[70px]"
                                                id="length" name="length"
                                                disabled={inputsDisabled}
                                                onKeyUp={(e) => EnterSave(e)}
                                                onChange={handleChange}
                                                value={length}
                                            />

                                            {NotStringEmpty(stdLength) &&
                                                <span className="pl-2 text-primary">
                                                    Std Length: {formatCurrencyDecimal(stdLength)} LF
                                                </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            <div>
                                <div className="po-col-1">
                                    PO Quantity
                                </div>
                                <div className="po-col-2">
                                    <input type="number" className="inp-quantity-modal mark mr-2"
                                        id="poQuantity" name="poQuantity"
                                        disabled={quantityDisabled} //required
                                        onKeyUp={(e) => EnterSave(e)}
                                        onChange={handleChange}
                                        value={quantity}
                                    />
                                    {poData.units && poData.units !== "" &&
                                        <span className="pr-4 text-primary font-bold">{poData.units}</span>
                                    }
                                    {/*<span className="pl-2">{poData.serialNo}</span>*/}

                                    {IsTrue(poData.isStock) && Equals(poData.statusId, 15) &&
                                        <span className="text-primary pb-[3px]">
                                            <span>Stock Qty: </span>
                                            {poData.stockQtyAvailable}
                                        </span>
                                    }                                    
                                </div>
                            </div>

                            {poData.type !== "create" &&
                                <div>
                                    {/* for partially received lines, show Quantity Received for all PO lines */}
                                    {quantity !== poData.poQuantity &&
                                        <div>
                                            {
                                                //(poData.statusId === 1 || poData.statusId === 2 ||
                                                //    poData.statusId === 3 || poData.statusId === 4) &&
                                                <div>
                                                    <div className="po-col-1">
                                                        PO Qty Received
                                                    </div>
                                                    <div className="po-col-2">
                                                        <input type="number" className="inp-quantity-modal mark mr-2"
                                                            id="poQuantityReceived" name="poQuantityReceived" disabled
                                                            //onChange={handleChange}
                                                            value={poData.poQuantityReceived}
                                                        />
                                                    </div>
                                                </div>
                                            }

                                            <div>
                                                <div className="po-col-1">
                                                    Line Qty
                                                </div>
                                                <div className="po-col-2">
                                                    <input type="number" className="inp-quantity-modal mark mr-2"
                                                        id="quantity" name="quantity" disabled={inputsDisabled}                                                    
                                                        onBlur={(e) => CheckMinQuantity(e, false, false)}
                                                        onChange={handleChange}
                                                        value={quantity}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {//poData.type !== "create" &&                                
                                        //(poData.statusId === 1 || poData.statusId === 2 ||
                                        //    poData.statusId === 3 || poData.statusId === 4) &&
                                        <div>
                                            <div className="po-col-1">
                                                Line Qty Received
                                            </div>
                                            <div className="po-col-2">
                                                <input type="number" className="inp-quantity-modal mark mr-2"
                                                    id="quantityReceived" name="quantityReceived" disabled
                                                    //onChange={handleChange}
                                                    value={poData.quantityReceived}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            <div>
                                <div className="po-col-1">
                                    CFM Cost
                                </div>
                                <div className="po-col-2">
                                    <span className="sp-cost-pad ml-[-12px]">$</span>
                                    <input type="number" className="inp-quantity-modal mark"
                                        id="cost" name="cost" disabled
                                        //onChange={handleChange}
                                        value={formatCurrencyDecimal(poData.cost)}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="po-col-1">
                                    Total
                                </div>
                                <div className="po-col-2">
                                    <span className="sp-cost-pad ml-[-12px]">$</span>
                                    <input type="number" className="inp-quantity-modal mark"
                                        id="poTotal" name="poTotal" disabled
                                        //onChange={handleChange}
                                        value={formatCurrencyDecimal(poTotal)}
                                    />
                                </div>
                            </div>

                            <div>
                                <div className="po-col-1">
                                    Promise Date
                                </div>
                                <div className="po-col-2">
                                    <DatePicker id="promiseDate" name="promiseDate" className="order-date-modal"
                                        showIcon toggleCalendarOnIconClick
                                        autoFocus
                                        //autoFocus={datePickerAutoFocus} // TODO: Dont auto-pop picker for Stock
                                        dateFormat="MM/dd/yyyy"
                                        disabled={inputsDisabled}
                                        //startDate={startDate}
                                        //endDate={endDate}
                                        selected={promiseDate}
                                        onKeyDown={(e) => KeyHandlerDatePicker(e, null, setTodaysDate, null)}
                                        onChange={(date) => setPromiseDate(date)}
                                    />                                                                       
                                </div>
                            </div>

                            <div>
                                <div className="po-col-1">
                                    Reference Number
                                </div>
                                <div className="po-col-2">
                                    <input type="text" className="inp-quantity-modal mark"
                                        id="referenceNumber" name="referenceNumber" disabled={inputsDisabled}
                                        onKeyUp={(e) => EnterSave(e)}
                                        onChange={handleChange}
                                        value={poData.referenceNumber}
                                    />
                                </div>
                            </div>

                            <div>
                                <div className="po-col-1">
                                    Ship To Location
                                </div>
                                <div className="po-col-2">
                                    {poData.showReceivedStore !== true &&

                                        <select id="warehouseId" name="warehouseId" className="sp-sel mark" 
                                            disabled={inputsDisabled}
                                            value={poData.warehouseId}
                                            onChange={handleChange}
                                        >
                                            {storeList}
                                        </select>
                                    }
                                    {poData.showReceivedStore === true &&
                                        <span>
                                            {poData.receivedStoreName}
                                            <input type="hidden" id="hfWarehouseName" value={poData.warehouseName}></input>
                                        </span>
                                    }
                                    {/*<span className={"text-sm text-red-600 " + storeRequiredHidden}>*/}
                                    {/*    *Store is required*/}
                                    {/*</span>*/}
                                </div>
                            </div>

                            {IsTrue(showDeliverWillCall) && IsFalse(inputsDisabled) &&
                                <div>
                                    <div className="po-col-1 text-red">
                                        Deliver Material
                                    </div>
                                    <div className="po-col-2">
                                        <input id="deliverWillCall" name="deliverWillCall" type="checkbox"
                                            className="inp-cb" 
                                            onChange={handleChange}
                                            checked={deliverWillCall}
                                        />
                                    </div>
                                </div>
                            }

                            {/*{*/}
                            {/*    updateStatus &&*/}
                            {/*    <div>*/}
                            {/*        <div className="po-col-1">*/}
                            {/*            Status*/}
                            {/*        </div>*/}
                            {/*        <div className="po-col-2">*/}
                            {/*            <select className="sp-sel mark"*/}
                            {/*                id="statusId" name="statusId"*/}
                            {/*                onChange={handleChange}*/}
                            {/*                value={poData.statusId}*/}
                            {/*            >                                            */}
                            {/*                <option value="2">On Order</option>*/}
                            {/*                <option value="3">Received</option>*/}
                            {/*            </select>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*}*/}

                            {/*{poData.statusId === 3 &&*/}
                            {/*    <div>*/}
                            {/*        <div className="po-col-1">*/}
                            {/*            Received Date*/}
                            {/*        </div>*/}
                            {/*        <div className="po-col-2">*/}
                            {/*            {poData.receivedDate}*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*}*/}

                            {poData.statusId !== 1 &&
                                <div>
                                    <div className="po-col-1">
                                        Ordered By
                                    </div>
                                    <div className="po-col-2">
                                        {poData.orderedBy}
                                    </div>
                                </div>
                            }
                            {poData.statusId === 4 &&
                                <div>
                                    {/*<div>*/}
                                    {/*    <div className="po-col-1">*/}
                                    {/*        Delivered Date*/}
                                    {/*    </div>*/}
                                    {/*    <div className="po-col-2">*/}
                                    {/*        {poData.deliveredDate}*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div>
                                        <div className="po-col-1">
                                            Picked Up By
                                        </div>
                                        <div className="po-col-2">
                                            {poData.pickupPrintedName}
                                        </div>
                                    </div>
                                </div>
                            }                            
                        </div>                        
                    </div>
                }
            </div>
            <div className="align-bottom text-center pt-[10px] pb-2 text-red">
                {stockMessage}
            </div>
            <div className="align-bottom text-right pt-[10px]">
                <div className={"pb-2 text-center " + messageClass}>
                    {message}
                </div>
                {showSaveButton === true &&
                    <button type="button" className="btn-submit-modal mr-2"
                        disabled={buttonsDisabled}
                        onClick={(e) => getNextPurOrder(e, true)}>
                        {nextButtonText}
                    </button>
                }
                {showNextButton === true &&
                    <button type="button" className="btn-submit-modal mr-2"
                        disabled={buttonsDisabled}
                        onClick={(e) => getNextPurOrder(e, false)}>
                        {viewButtonText}
                    </button>
                }
                <button type="button" className="btn-close-modal"
                    onClick={(e) => onClosePoModal(e)}>
                    Close
                </button>
            </div>

            {/**** Min Quantity Modal ****/}
            <div className="">
                <Modal ref={qtyModal} className="px-3">
                    <Modal.Body>
                        {qtyModalContent}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
}
