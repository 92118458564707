import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { get } from '../../services/apiService';
import { SetDocumentTitle } from '../_reactHelpers/ReactHelpers';
import { SetPageTitle } from '../../js/helpers';
import { GreaterThan, LessThan } from '../../js/calculationHelpers';

export const PayablesAgingReport = () => {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Payables Aging Report");

    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [gridApi, setGridApi] = useState(null);

    const fetchDueBills = async () => {
        setLoading(true);
        try {
            const response = await get('bills/GetDueBills');
            const data = response;
            console.log('API Response:', data);

            const formattedData = [
                { category: 'Currently', total: data.currentlyDue?.total || 0, details: data.currentlyDue?.bills || [] },
                { category: 'Less than 30 Days Overdue', total: data.lessThan30Days?.total || 0, details: data.lessThan30Days?.bills || [] },
                { category: '31-60 Days Overdue', total: data.days31To60?.total || 0, details: data.days31To60?.bills || [] },
                { category: '61-90 Days Overdue', total: data.days61To90?.total || 0, details: data.days61To90?.bills || [] },
                { category: 'Over 91 Days Overdue', total: data.over91Days?.total || 0, details: data.over91Days?.bills || [] },
                { category: 'Grand Total', total: data.grandTotal || 0, details: [], isGrandTotal: true }
            ];
            console.log('Formatted Data:', formattedData);
            setRowData(formattedData);
        } catch (error) {
            console.error('Error fetching due bills:', error);
        } finally {
            setLoading(false);
        }
    };

    const columnDefs = useMemo(() => [
        {
            field: 'category',
            headerName: 'Category',
            cellRenderer: 'agGroupCellRenderer',
            flex: 2,
            cellStyle: params => {
                if (params.data.isGrandTotal) {
                    return { fontWeight: 'bold' };
                }
            },
        },
        {
            field: 'total',
            headerName: 'Total',
            valueFormatter: params => `$${Number(params.value).toFixed(2)}`,
            flex: 1,
            cellStyle: params => {
                if (params.data.isGrandTotal) {
                    return { fontWeight: 'bold' };
                }
            },
        }
    ], []);

    const detailColumnDefs = useMemo(() => [
        { field: 'billNumber', headerName: 'Bill Number', flex: 1 },
        { field: 'payee', headerName: 'Payee', flex: 2 },
        {
            field: 'dueDate',
            headerName: 'Due Date',
            valueFormatter: params => params.value ? new Date(params.value).toLocaleDateString() : 'N/A',
            flex: 1
        },
        {
            field: 'billTotal',
            headerName: 'Total',
            valueFormatter: params => `$${Number(params.value).toFixed(2)}`,
            flex: 1
        }
    ], []);

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    }, []);

    const expandAll = useCallback(() => {
        if (gridApi) {
            gridApi.forEachNode(node => node.setExpanded(true));
        }
    }, [gridApi]);

    const collapseAll = useCallback(() => {
        if (gridApi) {
            gridApi.forEachNode(node => node.setExpanded(false));
        }
    }, [gridApi]);

    const detailCellRenderer = params => {
        console.log('Detail Cell Renderer Params:', params);
        const detailData = params.data.details || [];
        console.log('Detail Data for category:', params.data.category, detailData);

        return (
            <div style={{ height: '100%', width: '100%' }}>
                <AgGridReact
                    columnDefs={detailColumnDefs}
                    rowData={detailData}
                    defaultColDef={{
                        flex: 1,
                        sortable: true,
                        filter: true
                    }}
                    //domLayout='autoHeight'
                />
            </div>
        );
    };

    // dynamically assigning detail row height
    const getRowHeight = params => {
        const isDetailRow = params.node.detail;
        // for all rows that are not detail rows, return nothing
        if (!isDetailRow) { return undefined; }

        // otherwise return height based on number of rows in detail grid
        var detailPanelHeight = 0;
        if (params.data.details.length > 0) {
            detailPanelHeight = 50 + (params.data.details.length * 43);
            if (GreaterThan(detailPanelHeight, 400)) {
                detailPanelHeight = 400;
            }
        }
        return detailPanelHeight;
    };


    useEffect(() => {
        SetPageTitle("Payables Aging Report");
        fetchDueBills();
    }, []);

    if (loading) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }

    return (
        <div className="p-4">
            <div className="mb-4">
                <button onClick={collapseAll} className="btn-small !bg-gray-500">
                    Collapse All
                </button>
                <button onClick={expandAll} className="btn-small ml-2">
                    Expand All
                </button>                
            </div>
            <div className="ag-theme-alpine" style={{ height: 700, width: '100%' }}>
                <AgGridReact
                    columnDefs={columnDefs}
                    rowData={rowData}
                    defaultColDef={{
                        flex: 1,
                        sortable: true,
                        filter: true
                    }}
                    masterDetail={true}
                    detailCellRenderer={detailCellRenderer}
                    //detailRowAutoHeight={true}
                    getRowHeight={getRowHeight}
                    onGridReady={onGridReady}
                    isRowMaster={(dataItem) => dataItem.category !== 'Grand Total'}
                />
            </div>
        </div>
    );
}