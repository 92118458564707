import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-daisyui';

import { TaxForm } from './_forms/TaxForm.js';
import { EditTaxForm } from './_forms/EditTaxForm.js';
import { TaxList } from './TaxList.js';

import { get, post } from '../../services/apiService.js';
import { SetDocumentTitle } from '../_reactHelpers/ReactHelpers.js';

import '../../css/custom.css';
import { SetPageTitle } from '../../js/helpers.js';

export const Tax = () => {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - City Sales Tax");

    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [taxes, setTaxes] = useState([]);
    const [isAddModalOpen, setAddModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [currentTax, setCurrentTax] = useState(null);

    const fetchTaxes = async () => {
        try {
            const response = await get('/citysalestax/GetCitySalesTaxes');
            setTaxes(response);
        } catch (error) {
            setError('Error fetching taxes: ' + error)
            console.error('Error fetching taxes:', error);
            setTaxes([]);
        }
    };

    const openAddModal = () => setAddModalOpen(true);
    const closeAddModal = () => setAddModalOpen(false);

    const openEditModal = (tax) => {
        setCurrentTax(tax);
        setEditModalOpen(true);
    };
    const closeEditModal = () => setEditModalOpen(false);

    const handleAddTax = () => {
        closeAddModal();
        fetchTaxes();
    };

    const handleEditTax = () => {
        closeEditModal();
        fetchTaxes();
    };

    const handleDeleteTax = async (tax) => {
        if (window.confirm('Are you sure you want to delete this tax?')) {
            try {
                await post(`/citysalestax/DeleteCitySalesTax/${tax.salesTaxId}`);
                fetchTaxes();
            } catch (error) {
                console.error('Error deleting tax:', error);
            }
        }
    };

    useEffect(() => {
        SetPageTitle("City Sales Tax");
        fetchTaxes();
    }, []);

    return (
        <div className="container mx-auto pt-4 px-2">
            <div className="float-right">
                {error &&
                    <span className="text-red px-4">{error}</span>
                }
                {success &&
                    <span className="text-green text-bold px-4">{success}</span>
                }
                <Button className="btn-search !mr-0" onClick={openAddModal}>Add New Tax</Button>
            </div>
            <div className="">
                <TaxList taxes={taxes} onEdit={openEditModal} handleDeleteTax={handleDeleteTax} />

                <Modal open={isAddModalOpen} onClickBackdrop={closeAddModal} className="w-1/2 max-w-[1600px]">
                    <Modal.Header className="font-bold">Add New Tax</Modal.Header>
                    <Modal.Body>
                        <TaxForm onTaxAdded={handleAddTax} closeAddModal={closeAddModal} taxes={taxes} isOpen={isAddModalOpen} />
                    </Modal.Body>
                </Modal>
                <Modal open={isEditModalOpen} onClickBackdrop={closeEditModal} className="w-1/2 max-w-[1600px]">
                    <Modal.Header className="font-bold">Edit Tax</Modal.Header>
                    <Modal.Body>
                        <EditTaxForm tax={currentTax} onTaxUpdated={handleEditTax} closeEditModal={closeEditModal} taxes={taxes} />
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
}