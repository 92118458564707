import React, { useState } from 'react';
import { Component } from 'react';
import { initFlowbite } from "flowbite";

import { ProductPricingGrid } from './ProductPricingGrid';

export class ProductPricingExportSearch extends Component {
    static displayName = ProductPricingExportSearch.name;

    constructor() {
        super();
        this.state = { data: [], loading: true };
    }

    componentDidMount() {
        initFlowbite();
    }

    render() {
        return (
            <div className="p-2">
                <ProductPricingGrid importExport={true}></ProductPricingGrid>
            </div>
        );
    }
}
